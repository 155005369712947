import React, { useEffect, useState } from 'react';
import { Form, Space, notification } from 'antd';
import {
  QuestionType,
  SurveyAnswersResponse,
  generateSurveyAnswersValidatorSchema,
  SurveyInstanceEntity,
  SurveyFormEntity,
  SurveyInputMode,
  StructuredQuestionSingleFormEntity,
  QuestionFormEntity,
  UserRole,
  InvitationMode,
  waitingListsKeys,
  WaitingListEntity,
} from '@seaters-app/constants';
import { renderQuestion } from './helpers/renderItems';
import { Button, baseColor300 } from '@seaters-app/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { getInitialValues } from './helpers/getInitialValues';
import { useFetchAllQuestionsChoices } from './hooks/useFetchAllQuestionsChoices';
import {
  getSingleTranslation,
  useAppUserStore,
  useSendAnswers,
  useSendFanAnswersAsFGO,
  useSendGuestAnswersAsHost,
  useSendGuestAnswers,
  getSlugFromUrl,
  queryClient,
  getSessionToken,
  useSendAnswersAfterEvent,
} from '@seaters-app/data-access';
import './survey.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const formItemStyle = {
  border: `1px solid ${baseColor300}`,
  borderRadius: '8px',
  padding: '20px',
};
export interface SurveyProps {
  surveyInstance: SurveyInstanceEntity;
  cancel: () => void;
  surveyAnswers?: SurveyAnswersResponse[];
  previewMode?: boolean;
  //for BEFORE_JOIN_WISH_LIST survey type
  joinWL?: () => void;
  isJoined?: boolean;
  onSuccess?: () => void;
  ticketsAmount?: number;
  code?: string;
  selectedFanId?: string;
}

const singleAnswersType = [
  QuestionType.FREE_TEXT,
  QuestionType.CHECKBOX,
  QuestionType.MULTIPLE_CHOICE,
  QuestionType.MULTIPLE_CHOICE_RANGE,
  QuestionType.MULTIPLE_CHOICE_AUTO_COMPLETE,
  QuestionType.MULTIPLE_CHOICE_DROPDOWN,
  QuestionType.MULTIPLE_CHOICE_WITH_OTHER,
  QuestionType.MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE,
  QuestionType.MULTIPLE_CHOICE_WITH_OTHER_DROPDOWN,
];

const multipleAnswersType = [
  QuestionType.MULTIPLE_CHOICE_AUTO_COMPLETE_MULTIPLEANSWER,
  QuestionType.MULTIPLE_CHOICE_MULTIPLEANSWER,
  QuestionType.MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE_MULTIPLEANSWER,
];

export function Survey({
  surveyInstance,
  cancel,
  surveyAnswers,
  joinWL,
  isJoined,
  onSuccess,
  ticketsAmount,
  previewMode,
  code,
  selectedFanId,
}: SurveyProps) {
  const { waitingListId = '' } = useParams();

  const structuredFields =
    surveyInstance.survey?.surveyQuestions
      ?.find((q) => q.question.type === QuestionType.STRUCTURED)
      ?.question?.structure?.map((str) => {
        return { name: str.name, isMandatory: str.mandatory, type: str.type };
      }) ?? [];

  const schema = generateSurveyAnswersValidatorSchema(structuredFields);

  const structuredQuestionId = surveyInstance.survey?.surveyQuestions?.find(
    (q) => q.question.type === QuestionType.STRUCTURED
  )?.question.id;

  const { t, i18n } = useTranslation();

  const { language: lang } = i18n;

  const methods = useForm<any>({
    mode: 'onBlur',
    defaultValues: getInitialValues(
      surveyInstance?.survey,
      surveyAnswers,
      ticketsAmount
    ),
    values: getInitialValues(
      surveyInstance?.survey,
      surveyAnswers,
      ticketsAmount
    ),
  });

  const searchParams = new URLSearchParams(window.location.search);

  const token = searchParams.get('token');

  const waitingList = queryClient.getQueryData<WaitingListEntity>(
    waitingListsKeys.detail(waitingListId)
  );

  const { handleSubmit, control, setValue } = methods;

  const [importedData, setImportedData] = useState<{
    data: any[];
    questionId: string;
  }>({ data: [], questionId: '' });
  const questionIds = surveyInstance.survey.surveyQuestions.map(
    (question) => question.question.id
  );

  const { mutate: sendAnswers, isLoading: isFanAnswersSending } =
    useSendAnswers(surveyInstance.id, waitingListId);

  const { mutate: sendAfterEventAnswers } = useSendAnswersAfterEvent(
    surveyInstance.id,
    token ?? ''
  );

  const { mutate: sendFanAnswersAsFGO } = useSendFanAnswersAsFGO(
    surveyInstance.id,
    selectedFanId ?? '',
    waitingListId
  );

  const { mutate: sendGuestAnswersAsHost } = useSendGuestAnswersAsHost(
    surveyInstance.id,
    selectedFanId ?? '',
    waitingListId
  );

  const { user } = useAppUserStore();

  const { mutate: sendGuestAnswers, isLoading: isGuestAnswersSending } =
    useSendGuestAnswers(surveyInstance.id, { code });

  const isFGO = user?.roles.includes(UserRole.FAN_GROUP_OWNER);
  const isHost = user?.roles.includes(UserRole.HOST);

  const isAnswersSending = isFanAnswersSending || isGuestAnswersSending;

  const isGuest = !!code && !isFGO;

  const isEditable = surveyInstance?.inputMode === SurveyInputMode.EDITABLE;

  const [formDisabled, setFormDisabled] = useState(
    isAnswersSending || !!surveyAnswers || previewMode
  );

  useFetchAllQuestionsChoices(questionIds ?? [], isGuest);

  // This form appears in Checkout modal for Fan, Fan Group Owner, Guest and  Host.
  // For each role specific mutations should be executed.

  const slug = getSlugFromUrl();
  const isCustom = waitingList?.invitationMode === InvitationMode.ENABLED;
  const auth = getSessionToken();

  const sendAnswersMutation =
    (!auth && !isGuest) || token
      ? sendAfterEventAnswers
      : isGuest
      ? sendGuestAnswers
      : isFGO && selectedFanId && !slug // FGO selects a Fan in rsvp table
      ? sendFanAnswersAsFGO
      : isHost && isCustom
      ? sendGuestAnswersAsHost
      : sendAnswers;

  const joinAgain = () => {
    joinWL && joinWL();
    cancel();
  };

  const onSubmit = (values: SurveyFormEntity) => {
    const answers = Object.keys(values).map((key) => {
      const questionType = surveyInstance.survey.surveyQuestions.filter(
        (question) => question.question.id === key
      )[0].question.type;

      if (questionType === QuestionType.STRUCTURED) {
        const structuredSets = values[key];
        const structuredAnswers = Object.values(structuredSets).map(
          (set: StructuredQuestionSingleFormEntity) => {
            return {
              answerText: null,
              structuredAnswer: set,
            };
          }
        );

        const answer = {
          questionId: key,
          answers: structuredAnswers,
        };

        return answer;
      } else if (singleAnswersType.includes(questionType)) {
        const answer = {
          questionId: key,
          answers: [
            values[key].answerText
              ? { answerText: (values[key] as QuestionFormEntity).answerText }
              : {
                  offeredAnswerId: (values[key] as QuestionFormEntity)
                    .offeredAnswerId,
                  translatedOfferedAnswer: (values[key] as QuestionFormEntity)
                    .translatedOfferedAnswer,
                },
          ],
        };
        return answer;
      } else if (multipleAnswersType.includes(questionType)) {
        const answer = {
          questionId: key,
          answers: values[key].answerText
            ? [{ answerText: (values[key] as QuestionFormEntity).answerText }]
            : values[key].offeredAnswerId?.map((item) => {
                const res = {
                  answerText: null,
                  offeredAnswerId: item,
                  structuredAnswer: [],
                  translatedOfferedAnswer: values[key][item].label,
                };
                return res;
              }) ?? [],
        };
        return answer;
      }
    });

    const parsed = schema.safeParse({ answers });

    if (!parsed.success) {
      console.log(parsed.error?.errors);
      notification.error({
        message: t('survey_answers_validation_error', {
          fields: parsed.error?.errors
            ?.map((e: any) => e.path[e.path.length - 1])
            .join(', '),
        }),
      });
      return;
    }
    setFormDisabled(true);

    const regularQs = answers?.filter(
      (ans) => ans?.questionId !== structuredQuestionId
    );

    const structuredQ = answers?.find(
      (ans) => ans?.questionId === structuredQuestionId
    );

    const structureAnswersToSend = {
      ...structuredQ,
      answers: structuredQ?.answers.map((ans) => {
        return {
          ...ans,
          structuredAnswer: Object.keys(ans.structuredAnswer).map((key) => {
            return { name: key, value: ans.structuredAnswer[key] };
          }),
        };
      }),
    };

    const answersToSend = structureAnswersToSend.questionId
      ? [...regularQs, structureAnswersToSend]
      : regularQs;

    sendAnswersMutation(
      { answers: answersToSend },
      {
        onSuccess: async () => {
          notification.success({
            message: t('survey_answers_sent_notification_success'),
          });
          !isGuest && !selectedFanId && cancel();
          if (joinWL && !isJoined) {
            joinWL();
          }
          setFormDisabled(true);

          if (onSuccess) {
            onSuccess();
          }
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('survey_answers_sent_notification_error'),
          });
          setFormDisabled(false);
          if (joinWL && !isJoined && !!surveyAnswers?.length) {
            joinWL();
            cancel();
          }
        },
      }
    );
  };

  useEffect(() => {
    importedData.data.forEach((dataItem, i) => {
      Object.keys(dataItem).forEach((key) => {
        if (key !== 'key')
          setValue(
            `${importedData.questionId}.Instance ${i + 1}.${key}`,
            dataItem[key].toString() == 'true'
              ? true
              : dataItem[key].toString() == 'false'
              ? false
              : dataItem[key].toString()
          );
      });
    });
  }, [importedData]);

  return (
    <FormProvider {...methods}>
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        autoComplete="off"
        disabled={formDisabled}
      >
        {surveyInstance?.survey?.surveyQuestions.map((questionItem, i) => (
          <Form.Item
            style={formItemStyle}
            name={questionItem.question.id}
            required={questionItem.mandatory}
            label={
              <>
                {surveyInstance?.survey?.surveyQuestions.length > 1 &&
                  `${i + 1}. `}
                {getSingleTranslation(questionItem.question.text, lang)}
              </>
            }
          >
            {renderQuestion(
              questionItem,
              setValue,
              control,
              surveyInstance?.extensionPoint,
              setImportedData,
              ticketsAmount,
              lang,
              formDisabled
            )}
          </Form.Item>
        ))}
        {(!surveyAnswers || !formDisabled || isAnswersSending) && (
          <Space>
            <Button onClick={cancel} data-testid="closeSurveyButton">
              {t('button_text_cancel')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              data-testid="submitSurveyButton"
              loading={isAnswersSending}
            >
              {t('button_survey_answers_send')}
            </Button>
          </Space>
        )}
      </Form>
      {surveyAnswers &&
        !isAnswersSending &&
        formDisabled &&
        (isEditable ? (
          <Space size={24}>
            <Button onClick={() => setFormDisabled(false)}>
              {t('button_survey_answers_edit')}
            </Button>
            {joinWL && (
              <Button type="primary" onClick={joinAgain}>
                {t('queue_header_join-btn')}
              </Button>
            )}
          </Space>
        ) : (
          <Space size={24}>
            <Button onClick={cancel}>{t('button_text_close')}</Button>
            {joinWL && (
              <Button onClick={joinAgain}>{t('queue_header_join-btn')}</Button>
            )}
          </Space>
        ))}
    </FormProvider>
  );
}

export default Survey;

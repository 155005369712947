import {
  fetchFanGroupOwnerWaitingListsWithRequests,
  getSlugFromUrl,
  useFetchLook,
} from '@seaters-app/data-access';
import {
  FGOWaitingListWithRequestsEntity,
  ListEntityV2,
  fanGroupOwnerWaitingListsKeys,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

type ParamsType = {
  pageSize: number;
  current: number;
};

const defaultParams: ParamsType = {
  pageSize: 10,
  current: 1,
};

export const useFGOWishListsDistribution = () => {
  const slug = getSlugFromUrl();

  const { data: lookData } = useFetchLook(slug ?? '');

  const fanGroupId = lookData?.fanGroupId ?? '';

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<ListEntityV2<FGOWaitingListWithRequestsEntity>, Error>({
    queryKey: fanGroupOwnerWaitingListsKeys.allPerFanGroup(fanGroupId),
    queryFn: ({
      pageParam = {
        page: 0,
        size: defaultParams.pageSize,
      },
    }) => fetchFanGroupOwnerWaitingListsWithRequests(fanGroupId, pageParam),
    getNextPageParam: (lastPage, allPages) => {
      return !lastPage.last
        ? {
            page: lastPage.number + 1,
            size: defaultParams.pageSize,
          }
        : undefined;
    },
    enabled: !!fanGroupId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const allItems = data?.pages.flatMap((page) => page.content) || [];

  const handleFetchNextPage = () => {
    if (fanGroupId) {
      fetchNextPage();
    }
  };

  return {
    allItems,
    loadMoreData: handleFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  };
};

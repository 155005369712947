import {
  SESSION_STORAGE_ALLOWED_GROUPS,
  LOCAL_STORAGE_STRIPE_DEEP_LINK,
  SESSION_STORAGE_EMAIL_REMINDER,
  SESSION_STORAGE_JOB_ID,
  SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED,
  SESSION_STORAGE_SURVEY_SHOWN,
  SESSION_STORAGE_TARGET_APP,
  SESSION_STORAGE_VERIFICATION,
  routes,
} from '@seaters-app/constants';
import {
  getSlugFromUrl,
  queryClient,
  useAppUserStore,
  useEmailConfirmationStore,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
  const slug = getSlugFromUrl();
  const navigate = useNavigate();
  const { setFanGroup } = useFanGroupStore();
  const { setUser } = useAppUserStore();
  const { setEmailReminder } = useEmailConfirmationStore();

  const logout = () => {
    sessionStorage.removeItem(SESSION_STORAGE_VERIFICATION);
    sessionStorage.removeItem(SESSION_STORAGE_EMAIL_REMINDER);
    sessionStorage.removeItem(SESSION_STORAGE_SURVEY_SHOWN);
    sessionStorage.removeItem(SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED);
    sessionStorage.removeItem(SESSION_STORAGE_JOB_ID);
    sessionStorage.removeItem(SESSION_STORAGE_TARGET_APP);
    sessionStorage.removeItem(SESSION_STORAGE_ALLOWED_GROUPS);
    localStorage.removeItem(LOCAL_STORAGE_STRIPE_DEEP_LINK);

    setEmailReminder(false);
    setFanGroup(undefined);
    setUser(undefined);
    queryClient.clear();
    navigate(slug ? `/${slug}` : routes.login);
  };

  return { logout };
};

import type { DragEndEvent } from '@dnd-kit/core';
import { useTranslation } from 'react-i18next';

import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Table,
  Space,
  Collapse,
  InputNumber,
  Tooltip,
  Alert,
  Popconfirm,
  theme,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import {
  getInvitationStatus,
  GuestItem,
  invitationStatusTranslation,
  useDeleteWaitingListInvitation,
  useFetchWaitingList,
  useFetchWaitingListInvitations,
  useReserveGuestListStore,
} from '@seaters-app/data-access';
import Column from 'antd/es/table/Column';

import dayjs from 'dayjs';
import {
  GuestCustomInfo,
  InvitationEntity,
  InvitationStatusEnum,
  SemanticNameEnum,
} from '@seaters-app/constants';
import { Button, CollapseButton, statusToBadge } from '@seaters-app/ui';

import relativeTime from 'dayjs/plugin/relativeTime';

import './styles.css';
import React, { useEffect } from 'react';
import {
  statusesToAccept,
  statusesToCancel,
  statusesToTickets,
} from '../InvitationList/helpers/statusesToActions';
import { ParkingTicketButton } from '../InvitationList/ActionsButtons/ParkingTicketsButton';
import { CancelButton } from '../InvitationList/ActionsButtons/CancelButton';
import { TicketsButton } from '../InvitationList/ActionsButtons/TicketsButton';
import {
  useAcceptInvitation,
  useDeclineInvitation,
} from '../InvitationList/hooks';
import { EditContactModal } from '../ContactList/EditContactModal';
import { useEditContact } from '../../utils/useEditContact';
import { useGuestInvitation } from '../InvitationList/helpers/guestInvitation';

dayjs.extend(relativeTime);

const { Panel } = Collapse;

const getContactInfo = (
  customInfos: GuestCustomInfo[],
  semanticName: SemanticNameEnum
) => {
  return (
    customInfos.find((customInfo) => customInfo.semanticName === semanticName)
      ?.informationValue || '–'
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                rev={undefined}
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export function ReserveGuestList({ waitingListId }: { waitingListId: string }) {
  const { t } = useTranslation();

  const { token } = theme.useToken();
  const guestInvitation = useGuestInvitation();

  const { data: waitingList } = useFetchWaitingList(waitingListId);

  const { data: invitations } = useFetchWaitingListInvitations(waitingListId);

  const setTickets = useReserveGuestListStore((state) => state.setTickets);

  const removeReserveGuest = useReserveGuestListStore((state) => state.remove);
  const setReserveList = useReserveGuestListStore((state) => state.addAll);

  const { mutateAsync: deleteWaitingListInvitation } =
    useDeleteWaitingListInvitation(waitingListId);

  const handleDeclineInvitation = useDeclineInvitation(waitingListId);
  const handleAcceptInvitation = useAcceptInvitation(waitingListId);

  const reserveGroupGuests = useReserveGuestListStore((state) =>
    Object.values(state.guests)
  );

  const {
    isEditContactModal,
    setIsEditContactModal,
    showEditModal,
    guestToEdit,
    setGuestToEdit,
  } = useEditContact();

  if (!reserveGroupGuests.length) {
    return null;
  }

  const handleRemoveReserveGuest = (guestId: string) => {
    removeReserveGuest(guestId);

    if (invitations) {
      const invitation = invitations.content.find(
        (el) => el.guest.guestId === guestId
      );

      if (invitation) {
        deleteWaitingListInvitation({
          invitationId: invitation.id,
        });
      }
    }
  };

  if (!waitingList) {
    return null;
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = reserveGroupGuests.findIndex(
        (i) => i.guest.guestId === active.id
      );
      const overIndex = reserveGroupGuests.findIndex(
        (i) => i.guest.guestId === over?.id
      );
      const dataToMove: { [guestId: string]: GuestItem } = {};

      arrayMove(reserveGroupGuests, activeIndex, overIndex).forEach((el) => {
        dataToMove[el.guest.guestId] = el;
      });

      setReserveList(dataToMove);
    }
  };

  const groupId = waitingList?.groupId ?? '';
  return (
    <Collapse
      size="large"
      expandIcon={({ isActive }) => {
        return <CollapseButton isActive={isActive} />;
      }}
      expandIconPosition="end"
      defaultActiveKey={['1']}
      items={[
        {
          key: '2',
          label: `${t('guestlist_reservelist_table_title')} (${
            reserveGroupGuests.length
          })`,
          children: (
            <Space direction="vertical" style={{ width: '100%' }}>
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  // rowKey array
                  items={reserveGroupGuests.map((i) => i.guest?.guestId)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    dataSource={reserveGroupGuests}
                    pagination={false}
                    components={{
                      body: {
                        row: Row,
                      },
                    }}
                    rowKey={(record) => record.guest?.guestId}
                  >
                    <Column key="sort" align="center" />
                    {/*  HELPER COLUMN TO BETTER DEBUG: /> */}
                    {/* <Column
                    title={t('order_column_title')}
                    key="order"
                    dataIndex="order"
                    width="5%"
                  /> */}
                    <Column
                      title={t('guestlist_status')}
                      key="status"
                      render={(_, { guest }: GuestItem) => {
                        const invitation = guestInvitation(guest.guestId);
                        const status = getInvitationStatus(invitation);
                        const translatedStatus = status
                          ? invitationStatusTranslation[status]
                          : null;
                        return status ? (
                          <Alert
                            style={{
                              width: 'fit-content',
                            }}
                            message={
                              translatedStatus
                                ? t(translatedStatus)
                                : status.toString().replaceAll('_', ' ')
                            }
                            showIcon
                            type={statusToBadge[status].status}
                            icon={statusToBadge[status].icon}
                          />
                        ) : (
                          <Button
                            type="text"
                            onClick={() =>
                              handleRemoveReserveGuest(guest.guestId)
                            }
                            icon={<MinusCircleOutlined rev={undefined} />}
                          />
                        );
                      }}
                    />
                    <Column
                      title={t('guestlist_name')}
                      dataIndex="name"
                      key="name"
                      render={(_, { guest }: GuestItem) => {
                        const isPublicOfficial =
                          guest.customInfos.find(
                            (customInfo) =>
                              customInfo.semanticName ===
                              SemanticNameEnum.PUBLIC_OFFICIAL
                          )?.informationValue === 'true';
                        return (
                          <span>
                            {guest.firstName} {guest.lastName}{' '}
                            {isPublicOfficial && (
                              <Tooltip title={t('public_official')}>
                                <SolutionOutlined rev={undefined} />
                              </Tooltip>
                            )}{' '}
                            <EditOutlined
                              onClick={() => showEditModal(guest)}
                              rev={undefined}
                            />
                          </span>
                        );
                      }}
                    />
                    <Column
                      title={t('guestlist_company')}
                      dataIndex="company"
                      key="company"
                      render={(_, { guest }: GuestItem) => {
                        return (
                          <span>
                            {getContactInfo(
                              guest.customInfos,
                              SemanticNameEnum.COMPANY
                            )}
                          </span>
                        );
                      }}
                    />
                    <Column
                      title={t('guestlist_seats')}
                      dataIndex="tickets"
                      key="tickets"
                      render={(_, { guest, tickets }: GuestItem) => {
                        const invitation = guestInvitation(guest.guestId);
                        const status = getInvitationStatus(invitation);

                        return (
                          <InputNumber
                            min={1}
                            max={waitingList.maxNumberOfSeatsPerPosition}
                            defaultValue={1}
                            onChange={(value) =>
                              setTickets(guest.guestId, Number(value))
                            }
                            value={tickets}
                            disabled={
                              invitation &&
                              status !== InvitationStatusEnum.BEING_REVIEWED
                            }
                          />
                        );
                      }}
                    />
                    <Column
                      title={t('guestlist_last_rsvp')}
                      dataIndex="lastInvitation"
                      key="lastInvitation"
                      render={(_, { guest }: GuestItem) => {
                        const date = guest.customInfos?.find(
                          (customInfo) =>
                            customInfo.semanticName ===
                            SemanticNameEnum.LAST_RSVP
                        )?.informationValue;

                        return date ? (
                          <span>{dayjs(date).fromNow()}</span>
                        ) : null;
                      }}
                    />
                    <Column
                      title={t('guestlist_attendance')}
                      dataIndex="lastInvitation"
                      key="lastInvitation"
                      render={(_, { guest }: GuestItem) => {
                        return (
                          <span>
                            {`${
                              guest.customInfos?.find(
                                (customInfo) =>
                                  customInfo.semanticName ===
                                  SemanticNameEnum.ATTENDANCE
                              )?.informationValue || 0
                            }/${
                              guest.customInfos?.find(
                                (customInfo) =>
                                  customInfo.semanticName ===
                                  SemanticNameEnum.RSVPS
                              )?.informationValue || 0
                            }`}
                          </span>
                        );
                      }}
                    />
                    <Column
                      title={t('guestlist_cost_contact')}
                      dataIndex="lastInvitation"
                      key="lastInvitation"
                      render={(_, { guest }: GuestItem) => {
                        const cost =
                          guest.customInfos?.find(
                            (customInfo) =>
                              customInfo.semanticName ===
                              SemanticNameEnum.CONTACT_COST
                          )?.informationValue || 0;
                        return cost ? (
                          <span>{Number(cost).toFixed(0)} €</span>
                        ) : (
                          '–'
                        );
                      }}
                    />
                    <Column
                      key="actions"
                      align="right"
                      render={(
                        _,
                        { guest, tickets, isToAllocated }: GuestItem
                      ) => {
                        const invitation = guestInvitation(guest.guestId);
                        const status = getInvitationStatus(invitation);
                        if (invitation && status) {
                          if (statusesToCancel.includes(status)) {
                            return (
                              <>
                                <CancelButton
                                  waitingListId={waitingListId}
                                  invitation={invitation}
                                />
                              </>
                            );
                          }
                          if (statusesToAccept.includes(status)) {
                            return (
                              <Space size="small">
                                <Popconfirm
                                  title={t(
                                    'popconfirm_invitation_accept-title'
                                  )}
                                  description={t(
                                    'popconfirm_invitation_accept-description'
                                  )}
                                  cancelText={t('general_no')}
                                  onConfirm={() =>
                                    handleAcceptInvitation(invitation.id)
                                  }
                                >
                                  <Button
                                    type="link"
                                    icon={<CheckOutlined rev={undefined} />}
                                    color={token.colorSuccess}
                                  >
                                    {t('guestlist_comm_accept')}
                                  </Button>
                                </Popconfirm>
                                <Popconfirm
                                  title={t(
                                    'popconfirm_invitation_decline-title'
                                  )}
                                  description={t(
                                    'popconfirm_invitation_decline-description'
                                  )}
                                  cancelText={t('general_no')}
                                  onConfirm={() =>
                                    handleDeclineInvitation(invitation.id)
                                  }
                                >
                                  <Button
                                    type="link"
                                    icon={<CloseOutlined rev={undefined} />}
                                    color={token.colorError}
                                  >
                                    {t('guestlist_comm_decline')}
                                  </Button>
                                </Popconfirm>
                              </Space>
                            );
                          }
                          if (statusesToTickets.includes(status)) {
                            return (
                              <Space size="small">
                                <TicketsButton
                                  waitingListId={waitingListId}
                                  invitation={invitation}
                                />
                                <ParkingTicketButton
                                  waitingListId={waitingListId}
                                  invitation={invitation}
                                />
                              </Space>
                            );
                          }
                        }
                      }}
                    />
                  </Table>
                  {isEditContactModal && (
                    <EditContactModal
                      groupId={groupId}
                      isEditContactModal={isEditContactModal}
                      setIsEditContactModal={setIsEditContactModal}
                      guestToEdit={guestToEdit}
                      setGuestToEdit={setGuestToEdit}
                      isInvitationExists
                    />
                  )}
                </SortableContext>
              </DndContext>
            </Space>
          ),
        },
      ]}
    />
  );
}

import { Editor as TinyEditor, IAllProps } from '@tinymce/tinymce-react';
import { Language, tinyApiKey } from '@seaters-app/constants';
import { useAppThemeStore } from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { InitOptions } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';

interface EditorProps extends IAllProps {
  width?: number | string;
  height?: number | string;
  overrideInitOptions?: InitOptions;
  initialValue?: string;
}

export function Editor({
  width,
  height,
  overrideInitOptions,
  initialValue,
  ...props
}: EditorProps) {
  const { mode } = useAppThemeStore();
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const getLang = (lang: string) => {
    switch (lang) {
      case Language.FR: {
        return 'fr_FR';
      }
      case Language.NL: {
        return 'nl';
      }

      default: {
        return 'en';
      }
    }
  };

  const editorInitOptions = {
    direction: 'ltr',
    height: height ?? props.value ? 'fitContent' : '250px',
    width: width ?? '100%',
    skin: mode === 'dark' ? 'oxide-dark' : 'oxide',
    content_css: mode === 'dark' ? 'dark' : 'default',
    plugins: [
      'link',
      'image',
      'code',
      'i18n',
      'lists',
      'preview',
      'fullscreen',
    ],
    language: getLang(lang),
    relative_urls: false,
    remove_script_host: false,
    toolbar:
      'undo redo style image select bold italic underline lineheight fontsizeinput fontfamily alignleft aligncenter alignright alignjustify  | bullist numlist outdent indent link ',
    font_family_formats:
      'Inter=Inter, sans-serif; System Font=system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    font_size_input_default_unit: 'px',
    line_height_formats: '1 1.2 1.4 1.6 2',
    content_style: 'p { margin: 0; }',
  };

  return (
    <TinyEditor
      init={overrideInitOptions ?? editorInitOptions}
      apiKey={tinyApiKey}
      initialValue={initialValue}
      {...props}
    ></TinyEditor>
  );
}

export default Editor;

import {
  FormTranslationType,
  Language,
  TranslationEntity,
} from '@seaters-app/constants';

export const convertTranslation = (
  translation: FormTranslationType | undefined
): TranslationEntity[] => {
  if (translation) {
    return Object.keys(translation).reduce<{ lang: Language; text: string }[]>(
      (acc, locale) => {
        acc.push({
          lang: locale as Language,
          text: translation[locale as Language],
        });

        return acc;
      },
      []
    );
  } else {
    return Object.values(Language).map((lang) => ({
      lang: lang,
      text: '',
    }));
  }
};

export const convertKeywordsTranslation = (
  translation:
    | {
        [lang: string]: string[];
      }
    | undefined
): {
  text: string[];
  lang: Language | string;
}[] => {
  if (translation) {
    return Object.keys(translation).reduce<
      { lang: Language; text: string[] }[]
    >((acc, locale) => {
      acc.push({
        lang: locale as Language,
        text: translation[locale as Language],
      });

      return acc;
    }, []);
  } else {
    return Object.values(Language).map((lang) => ({
      lang: lang,
      text: [],
    }));
  }
};

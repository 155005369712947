import {
  AxiosAPIError,
  ListEntity,
  FanGroupsQuery,
  FanGroupEntity,
  fanGroupsKeys,
  FanGroupValidator,
  UploadFileResponseType,
  OneTimeUploadQuery,
  joinedWaitingListsKeys,
  RequestAccessResult,
  RequestAccessValidator,
  usersKeys,
  SortedPaginationQueryV2,
  FanGroupMembersValidator,
  CreateFGOGroupMemberValidator,
  ListEntityV2,
  FanGroupMember,
  RequestAccessForFanValidator,
  waitingListsKeys,
  ThemeEntity,
  PaginationQuery,
  FanAccessRequest,
} from '@seaters-app/constants';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createFanGroup,
  createFGOGroupMembers,
  deleteFanGroup,
  deleteRequestToJoinFG,
  fetchFGOFanGroup,
  fetchFGOGroupMembers,
  fetchJoinedFanGroups,
  joinPublicFG,
  requestFanAccessToFG,
  requestFanAccessToProtectedFG,
  requestOneTimeUpload,
  updateFanGroup,
  updateFanGroupBackgroundImage,
  updateFanGroupClientLogo,
  updateFanGroupCoverImage,
  updateFanGroupProfileImage,
  updateRequestFanAccessToProtectedFG,
  addFanGroupMember,
  addFanToProtectedFG,
  fetchAdminFanGroups,
  updateUserRequestToJoinProtectedFG,
  deleteFanGroupMember,
  fetchFanFGSeats,
  updateAdminFanGroup,
  fetchAdminFanGroup,
  fetchFanGroupThemes,
  updateFanGroupProperties,
  updateFanGroupLoginConfig,
  LoginConfigBody,
  SignupConfigBody,
  updateFanGroupSignupConfig,
  fetchFanGroupAccessRequests,
} from '../api/fanGroups';

type UpdateImageBodyType = { id: string; fileId: string };

export const useFetchAdminFanGroups = (params: FanGroupsQuery) =>
  useQuery<ListEntity<FanGroupEntity>, Error>(
    fanGroupsKeys.list(params),
    () => fetchAdminFanGroups(params),
    {
      keepPreviousData: true,
    }
  );

export const useFetchAdminThemes = (params: PaginationQuery) =>
  useQuery<ListEntity<ThemeEntity>, Error>(
    fanGroupsKeys.themes(),
    () => fetchFanGroupThemes(params),
    {
      keepPreviousData: true,
    }
  );

export const useFetchJoinedFanGroups = (
  params: FanGroupsQuery,
  enabled = true
) =>
  useQuery<ListEntity<FanGroupEntity>, Error>(
    joinedWaitingListsKeys.list(params),
    () => fetchJoinedFanGroups(params),
    {
      enabled: enabled,
      keepPreviousData: true,
    }
  );

export const useRequestOneTimeUpload = (
  id: string,
  params: OneTimeUploadQuery
) =>
  useQuery<Promise<UploadFileResponseType>, Error>(
    fanGroupsKeys.detail(id),
    () => requestOneTimeUpload(params)
  );

export const useFetchAdminFanGroup = (fanGroupId: string) =>
  useQuery<FanGroupEntity, Error>(
    fanGroupsKeys.detail(fanGroupId),
    () => fetchAdminFanGroup(fanGroupId),
    {
      enabled: !!fanGroupId,
    }
  );

export const useFetchFGOFanGroup = (fanGroupId: string) =>
  useQuery<FanGroupEntity, Error>(
    fanGroupsKeys.detail(fanGroupId),
    () => fetchFGOFanGroup(fanGroupId),
    {
      enabled: !!fanGroupId,
    }
  );

export const useFetchFanFGSeats = (fanGroupId: string) =>
  useQuery<
    { numberOfSeats: number; directSaleModeTicketsLimit: number | null },
    Error
  >(fanGroupsKeys.seats(fanGroupId), () => fetchFanFGSeats(fanGroupId), {
    enabled: !!fanGroupId,
  });

export const useCreateFanGroup = (): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<FanGroupValidator>,
  FanGroupValidator,
  unknown
> => {
  return useMutation(fanGroupsKeys.mutation(), (body: FanGroupValidator) =>
    createFanGroup(body)
  );
};

export const useUpdateAdminFanGroup = (
  id: string
): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<FanGroupValidator>,
  FanGroupValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    fanGroupsKeys.mutation(),
    (body: FanGroupValidator) => updateAdminFanGroup(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.detail(id));
      },
    }
  );
};

export const useUpdateFanGroup = (
  id: string
): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<FanGroupValidator>,
  FanGroupValidator,
  unknown
> => {
  return useMutation(fanGroupsKeys.mutation(), (body: FanGroupValidator) =>
    updateFanGroup(id, body)
  );
};

export const useUpdateFanGroupLoginConfig = (
  id: string
): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<LoginConfigBody>,
  LoginConfigBody,
  unknown
> => {
  return useMutation(fanGroupsKeys.mutation(), (body: LoginConfigBody) =>
    updateFanGroupLoginConfig(id, body)
  );
};

export const useUpdateFanGroupSignupConfig = (
  id: string
): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<SignupConfigBody>,
  SignupConfigBody,
  unknown
> => {
  return useMutation(fanGroupsKeys.mutation(), (body: SignupConfigBody) =>
    updateFanGroupSignupConfig(id, body)
  );
};

export const useUpdateFanGroupProfileImage = (): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<File>,
  UpdateImageBodyType,
  unknown
> => {
  return useMutation(fanGroupsKeys.mutation(), (body: UpdateImageBodyType) =>
    updateFanGroupProfileImage(body.id, { fileId: body.fileId })
  );
};

export const useUpdateFanGroupClientLogo = (): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<File>,
  UpdateImageBodyType,
  unknown
> => {
  return useMutation(fanGroupsKeys.mutation(), (body: UpdateImageBodyType) =>
    updateFanGroupClientLogo(body.id, { fileId: body.fileId })
  );
};

export const useUpdateFanGroupBackgroundImage = (): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<File>,
  UpdateImageBodyType,
  unknown
> => {
  return useMutation(fanGroupsKeys.mutation(), (body: UpdateImageBodyType) =>
    updateFanGroupBackgroundImage(body.id, { fileId: body.fileId })
  );
};

export const useUpdateFanGroupCoverImage = (): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<File>,
  UpdateImageBodyType,
  unknown
> => {
  return useMutation(fanGroupsKeys.mutation(), (body: UpdateImageBodyType) =>
    updateFanGroupCoverImage(body.id, { fileId: body.fileId })
  );
};

export const useDeleteFanGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ fanGroupId }: { fanGroupId: string }) => deleteFanGroup(fanGroupId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.all());
      },
    }
  );
};

export const useRequestFanAccessToProtectedFG = (
  groupId: string,
  slug: string
): UseMutationResult<
  RequestAccessResult,
  AxiosAPIError<RequestAccessValidator>,
  RequestAccessValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    fanGroupsKeys.mutation(),
    (body: RequestAccessValidator) =>
      requestFanAccessToProtectedFG(groupId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersKeys.fanGroupBySlug(slug));
      },
    }
  );
};

export const useJoinPublicFG = (
  slug: string
): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<{ groupId: string }>,
  string,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation((groupId) => joinPublicFG(groupId), {
    onSuccess: () => {
      queryClient.invalidateQueries(fanGroupsKeys.joinedLists());
      queryClient.invalidateQueries(usersKeys.fanGroupBySlug(slug));
    },
  });
};

export const useRequestFanAccessToFG = (
  groupId: string,
  slug: string
): UseMutationResult<
  RequestAccessResult,
  AxiosAPIError<RequestAccessValidator>,
  RequestAccessValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    fanGroupsKeys.mutation(),
    (body: RequestAccessValidator) => requestFanAccessToFG(groupId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersKeys.fanGroupBySlug(slug));
      },
    }
  );
};

export const useUpdateRequestFanAccessToProtectedFG = (
  groupId: string,
  slug: string
): UseMutationResult<
  RequestAccessResult,
  AxiosAPIError<RequestAccessValidator>,
  RequestAccessValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    fanGroupsKeys.mutation(),
    (body: RequestAccessValidator) =>
      updateRequestFanAccessToProtectedFG(groupId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersKeys.fanGroupBySlug(slug));
      },
    }
  );
};

export const useDeleteRequestToJoinFG = (groupId: string, slug: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteRequestToJoinFG(groupId), {
    onSuccess: () => {
      queryClient.invalidateQueries(fanGroupsKeys.joinedLists());
      queryClient.invalidateQueries(usersKeys.fanGroupBySlug(slug));
    },
  });
};

export const useFetchFGOFanGroupMembers = (
  fanGroupId: string,
  params: SortedPaginationQueryV2,
  body: FanGroupMembersValidator
) =>
  useQuery<ListEntityV2<FanGroupMember>, Error>(
    fanGroupsKeys.membersList(fanGroupId, params, body.query),
    () => fetchFGOGroupMembers(fanGroupId, params, body),
    {
      enabled: !!fanGroupId,
    }
  );

export const useCreateFGOFanGroupMembers = (
  fanGroupId: string,
  waitingListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ fanId: string; body: CreateFGOGroupMemberValidator }>,
  { fanId: string; body: CreateFGOGroupMemberValidator },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ fanId, body }: { fanId: string; body: CreateFGOGroupMemberValidator }) =>
      createFGOGroupMembers(waitingListId, fanId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.members(fanGroupId));
        queryClient.invalidateQueries(
          waitingListsKeys.positions(waitingListId)
        );
      },
    }
  );
};

export const useAddFanGroupMember = (
  groupId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ userId: string }>,
  { userId: string },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ userId }: { userId: string }) => addFanGroupMember(userId, groupId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.members(groupId));
      },
    }
  );
};

export const useAddFanToProtectedFG = (
  groupId: string
): UseMutationResult<
  RequestAccessResult,
  AxiosAPIError<RequestAccessForFanValidator>,
  RequestAccessForFanValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    fanGroupsKeys.mutation(),
    ({ userId, body }: RequestAccessForFanValidator) =>
      addFanToProtectedFG(userId, groupId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.members(groupId));
        queryClient.invalidateQueries(fanGroupsKeys.codes(groupId));
      },
    }
  );
};

export const useUpdateUserRequestToJoinProtectedFG = (
  groupId: string
): UseMutationResult<
  RequestAccessResult,
  AxiosAPIError<RequestAccessForFanValidator>,
  RequestAccessForFanValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    fanGroupsKeys.mutation(),
    ({ userId, body }: RequestAccessForFanValidator) =>
      updateUserRequestToJoinProtectedFG(userId, groupId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.members(groupId));
        queryClient.invalidateQueries(fanGroupsKeys.codes(groupId));
      },
    }
  );
};

// deleteFanGroupMember

export const useDeleteFanGroupMember = (fanGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId }: { userId: string }) =>
      deleteFanGroupMember(fanGroupId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.members(fanGroupId));
      },
    }
  );
};

// updateFanGroupProperties
export const useUpdateFanGroupProperties = (
  fanGroupId: string
): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<FanGroupEntity['properties']>,
  { properties: FanGroupEntity['properties'] },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ properties }: { properties: FanGroupEntity['properties'] }) =>
      updateFanGroupProperties(fanGroupId, { properties }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.detail(fanGroupId));
      },
    }
  );
};

export const useFetchFanGroupAccessRequests = (
  fanGroupId: string,
  params: PaginationQuery
) =>
  useQuery<ListEntity<FanAccessRequest>, Error>(
    fanGroupsKeys.accessRequests(fanGroupId, params),
    () => fetchFanGroupAccessRequests(fanGroupId, params),
    {
      enabled: !!fanGroupId,
      keepPreviousData: true,
    }
  );

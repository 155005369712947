import {
  FormTranslationType,
  TranslationEntity,
  VenueEntity,
  VenueFormValues,
} from '@seaters-app/constants';

import { VenueFormType } from './getInitialValues';

const DEFAULT_VENUE_BARCODE = 'code128';

const convertTranslation = (
  translation: FormTranslationType
): TranslationEntity[] => {
  return Object.keys(translation).map((locale) => ({
    lang: locale,
    text: translation[locale],
  }));
};

export const prepareValues = (
  values: VenueFormType,
  venue?: VenueEntity
): VenueFormValues => {
  const shortName = convertTranslation(values.shortName);
  const name = convertTranslation(values.name);
  const city = convertTranslation(values.city);
  const country = convertTranslation(values.country);
  const conditions = convertTranslation(values.conditions);
  const latitude = Number(values.latitude);
  const longitude = Number(values.longitude);
  const barcodeType = venue
    ? venue?.barcodeType ?? DEFAULT_VENUE_BARCODE
    : DEFAULT_VENUE_BARCODE;

  return {
    ...values,
    country,
    shortName,
    name,
    city,
    conditions,
    latitude,
    longitude,
    barcodeType,
  };
};

import {
  fetchFGOGroupMembers,
  useFanGroupStore,
} from '@seaters-app/data-access';
import {
  FanGroupMember,
  ListEntityV2,
  fanGroupsKeys,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useFGMembers = (defaultParams: {
  page: number;
  size: number;
  query: string;
}) => {
  const { fanGroup } = useFanGroupStore();

  const { data, fetchNextPage, isFetching } = useInfiniteQuery<
    ListEntityV2<FanGroupMember>
  >({
    queryKey: fanGroupsKeys.membersList(
      fanGroup?.id ?? '',
      defaultParams,
      defaultParams.query
    ),
    queryFn: ({ pageParam = defaultParams }) => {
      return fetchFGOGroupMembers(fanGroup?.id ?? '', pageParam, {
        query: defaultParams.query,
      });
    },
    getNextPageParam: (lastPage) => {
      return !lastPage.last
        ? {
            page: lastPage.number + 1,
            size: defaultParams.size,
            query: defaultParams.query,
          }
        : undefined;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const allItems = data?.pages.flatMap((page) => page.content) || [];

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  return {
    fgMembersOptions: allItems,
    loadMoreData: handleFetchNextPage,
    fgMembersFetching: isFetching,
  };
};

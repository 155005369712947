import { Result } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '../Button/Button';
import { routes } from '@seaters-app/constants';
import { useTranslation } from 'react-i18next';
import { getSlugFromUrl } from '@seaters-app/data-access';

export const AccessDenied = () => {
  const { t } = useTranslation();
  const slug = getSlugFromUrl();

  // TODO: Rework roles check
  return null;
  // return (
  //   <Result
  //     status="warning"
  //     title="Access denied"
  //     extra={
  //       <NavLink to={`${routes.root}${slug}`}>
  //         <Button type="primary">{t('back_to_homePage')}</Button>
  //       </NavLink>
  //     }
  //   />
  // );
};

export default AccessDenied;

import { zodResolver } from '@hookform/resolvers/zod';
import {
  FGRegisterConfigValidatorSchema,
  LoginTypeEnum,
  RegistrationTypeEnum,
} from '@seaters-app/constants';
import {
  useFanGroupStore,
  useFetchLook,
  useUpdateFanGroupLoginConfig,
  useUpdateFanGroupSignupConfig,
} from '@seaters-app/data-access';
import { Button, Col, Form, Row, Select, Space } from 'antd';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const signupMethodsOptions = [
  {
    label: RegistrationTypeEnum.CLASSIC,
    value: RegistrationTypeEnum.CLASSIC,
  },
  {
    label: RegistrationTypeEnum.FACEBOOK,
    value: RegistrationTypeEnum.FACEBOOK,
  },
  {
    label: RegistrationTypeEnum.CUSTOM,
    value: RegistrationTypeEnum.CUSTOM,
  },
  {
    label: RegistrationTypeEnum.NONE,
    value: RegistrationTypeEnum.NONE,
  },
];

export const SSOLoginTypes = [
  LoginTypeEnum.PIM,
  LoginTypeEnum.SAML,
  LoginTypeEnum.OAUTH,
];

const loginMethodsOptions = [
  {
    label: LoginTypeEnum.CLASSIC,
    value: LoginTypeEnum.CLASSIC,
  },
  {
    label: LoginTypeEnum.FACEBOOK,
    value: LoginTypeEnum.FACEBOOK,
  },
  {
    label: LoginTypeEnum.PIM,
    value: LoginTypeEnum.PIM,
  },
  {
    label: LoginTypeEnum.OAUTH,
    value: LoginTypeEnum.OAUTH,
  },
  {
    label: LoginTypeEnum.SAML,
    value: LoginTypeEnum.SAML,
  },
];

const loginConfigurations = [
  {
    type: LoginTypeEnum.CLASSIC,
    providerId: 'seaters',
    providerName: {
      de: 'Seaters',
      ru: 'Seaters',
      ja: 'Seaters',
      en: 'Seaters',
      it: 'Seaters',
      cn: 'Seaters',
      fr: 'Seaters',
      es: 'Seaters',
      nl: 'Seaters',
    },
  },
  {
    type: LoginTypeEnum.FACEBOOK,
    providerId: 'facebook',
    providerName: {
      de: 'Facebook',
      ru: 'Facebook',
      ja: 'Facebook',
      en: 'Facebook',
      it: 'Facebook',
      cn: 'Facebook',
      fr: 'Facebook',
      es: 'Facebook',
      nl: 'Facebook',
    },
  },
];

type FGRegisterConfigForm = {
  login: LoginTypeEnum[];
  signup: RegistrationTypeEnum[];
};

function FGRegistrationConfig() {
  const { fanGroup: storedFanGroup } = useFanGroupStore();

  const { data: lookEntity } = useFetchLook(storedFanGroup?.slug ?? '');

  const initialValues = {
    login: lookEntity?.login.map((loginItem) => loginItem.type),
    signup: lookEntity?.registration,
  };

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const { mutate: updateFGLoginConfig, isLoading: isLoginConfigUpdating } =
    useUpdateFanGroupLoginConfig(storedFanGroup?.id ?? '');

  const { mutate: updateFGSignupConfig, isLoading: isSignupConfigUpdating } =
    useUpdateFanGroupSignupConfig(storedFanGroup?.id ?? '');

  const methods = useForm<FGRegisterConfigForm>({
    mode: 'onBlur',
    resolver: zodResolver(FGRegisterConfigValidatorSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const { handleSubmit, control } = methods;

  const handleUpdateConfig = (values: FGRegisterConfigForm) => {
    let configs = loginConfigurations.filter((config) =>
      values.login.includes(config.type)
    );

    // Check if the FAN GROUP has SSO login types
    const ssoLogins = lookEntity?.login?.filter((login) =>
      SSOLoginTypes.includes(login.type)
    );

    const allConfigs = !!ssoLogins?.length
      ? configs.concat(ssoLogins)
      : configs;

    updateFGSignupConfig({ registrationTypes: values.signup });
    updateFGLoginConfig({
      loginConfigurations: {
        configs: allConfigs,
      },
    });
  };

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit(handleUpdateConfig)}
      autoComplete="off"
      disabled={isLoginConfigUpdating || isSignupConfigUpdating}
    >
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item label={t('login_config_label')} name="login">
            <Controller
              control={control}
              name="login"
              render={({ field }) => (
                <Select
                  mode="multiple"
                  options={loginMethodsOptions.map((option) => {
                    return {
                      ...option,
                      disabled: SSOLoginTypes.includes(option.value),
                    };
                  })}
                  {...field}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item label={t('signup_config_label')} name="signup">
            <Controller
              control={control}
              name="signup"
              render={({ field }) => (
                <Select
                  mode="multiple"
                  options={signupMethodsOptions}
                  {...field}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Space>
        <Button size="middle" onClick={() => form.resetFields()}>
          {t('button_text_cancel')}
        </Button>
        <Button
          size="middle"
          type="primary"
          htmlType="submit"
          loading={isLoginConfigUpdating || isSignupConfigUpdating}
        >
          {t('save_config_button')}
        </Button>
      </Space>
    </Form>
  );
}

export default FGRegistrationConfig;

export enum InvitationStatusEnum {
  BEING_REVIEWED = 'BEING_REVIEWED',
  DEMAND_REFUSED = 'DEMAND_REFUSED',
  DEMAND_ACCEPTED = 'DEMAND_ACCEPTED',
  RSVP_TO_ACCEPT = 'RSVP_TO_ACCEPT',
  RSVP_CONFIRMED = 'RSVP_CONFIRMED',
  RSVP_DECLINED = 'RSVP_DECLINED',
  CANCELLED = 'CANCELLED',
}

export enum InvitationForApprove {
  PENDING = 'PENDING',
  IGNORED = 'IGNORED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

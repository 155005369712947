import {
  ListEntity,
  SurveyEntity,
  SurveyPaginationQuery,
  surveysKeys,
} from '@seaters-app/constants';

import { useInfiniteQuery } from '@tanstack/react-query';
import { paginationFormat } from '../../../../utils';

type ParamsType = {
  pageSize: number;
  current: number;
};

const defaultParams: ParamsType = {
  pageSize: 999,
  current: 1,
};

export const useSurveysOptions = (
  fetchFunction: (
    params: SurveyPaginationQuery
  ) => Promise<ListEntity<SurveyEntity>>
) => {
  const pageParam = {
    ...paginationFormat(defaultParams),
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<ListEntity<SurveyEntity>, Error>({
    queryKey: surveysKeys.list(pageParam),
    queryFn: ({
      pageParam = {
        ...paginationFormat(defaultParams),
      },
    }) => fetchFunction(pageParam),
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.reduce(
        (prev, next) => prev + next.items.length,
        0
      );

      return lastPage.totalSize - lastPage.itemOffset >= lastPage.maxPageSize
        ? {
            itemOffset: nextOffset,
            maxPageSize: lastPage.maxPageSize,
          }
        : undefined;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  const allItems = data?.pages?.flatMap((page) => page.items) || [];
  const surveysOptions = allItems?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  return {
    surveysOptions,
    loadMoreData: handleFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  };
};

import React from 'react';
import { Button } from './Button';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const CollapseButton = ({ isActive }: { isActive?: boolean }) => {
  const { t } = useTranslation();
  return (
    <Button type="text">
      {isActive ? (
        <>
          {t('general_button_hide')} <UpOutlined rev={undefined} />
        </>
      ) : (
        <>
          {t('general_button_show')} <DownOutlined rev={undefined} />
        </>
      )}
    </Button>
  );
};

import {
  Typography,
  notification,
  Skeleton,
  Popconfirm,
  theme,
  Spin,
  Flex,
  Breadcrumb,
  Table,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getSingleTranslation,
  requestAdminOneTimeImageUpload,
  uploadFile,
  useDeleteVenue,
  useDeleteVenueConfig,
  useFetchVenue,
  useFetchVenueCategories,
  useFetchVenueConfigs,
  useUpdateVenue,
} from '@seaters-app/data-access';

import {
  FormattedValuesWithTranslations,
  VenueConfigEntity,
  VenueEntity,
  VenueValidatorSchema,
} from '@seaters-app/constants';
import { getTranslations } from '../../../utils/helpers';
import { VenueFormType, getInitialValues } from '../helpers/getInitialValues';
import { prepareValues } from '../helpers/prepareValues';
import { VenueFormAsAdmin } from '../VenueForm';
import styles from './venue.module.css';
import { Button } from '@seaters-app/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Column from 'antd/es/table/Column';

const { Title } = Typography;

export function Venue() {
  const navigate = useNavigate();

  const { state = '' } = useLocation();

  const { t } = useTranslation();
  const { token } = theme.useToken();

  const { venueId = '' } = useParams();

  const { data: venueConfigs } = useFetchVenueConfigs(venueId, true, {
    itemOffset: 0,
    maxPageSize: 10,
  });

  const { data: venueCategories } = useFetchVenueCategories(venueId, {
    itemOffset: 0,
    maxPageSize: 10,
  });

  const { data: venue } = useFetchVenue(venueId);

  const [spinning, setSpinning] = useState<boolean>(false);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);

  const valuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<VenueEntity>(
      ['name', 'shortName', 'conditions', 'country', 'city'],
      venue
    );
  const { mutate: updateVenue } = useUpdateVenue(venueId);
  const { mutateAsync: deleteVenue } = useDeleteVenue();

  const initialValues = getInitialValues(venue, valuesWithTranslations);

  const onSubmit = async (values: VenueFormType) => {
    const preparedValues = prepareValues(values, venue);
    const imageUrlOrCanvas = values?.imageUrl as string | HTMLCanvasElement;
    const parsed = VenueValidatorSchema.safeParse(preparedValues);
    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    const dataToSend = {
      ...venue,
      ...parsed.data,
    };

    setSpinning(true);

    updateVenue(dataToSend, {
      onSuccess: async (response) => {
        if (imageUrlOrCanvas && typeof imageUrlOrCanvas !== 'string') {
          setUploadingImage(true);

          await requestAdminOneTimeImageUpload({
            id: response.id,
            fileName: 'file.png',
            entityType: 'venues',
          }).then(async (requestOneTimeUploadResponse) => {
            if (requestOneTimeUploadResponse.token) {
              const formFile = new FormData();
              const blob = (await new Promise((resolve) =>
                imageUrlOrCanvas.toBlob(resolve)
              )) as Blob;
              formFile.append('file', blob);
              await uploadFile(
                formFile,
                requestOneTimeUploadResponse.token
              ).then(() => setUploadingImage(false));
            }
          });
        }

        notification.success({
          message: t('notification_success_venue_updated'),
        });

        navigate(`../${state ?? ''}`);
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: t('notification_error_venue_updated'),
        });
      },
      onSettled: () => {
        setSpinning(false);
      },
    });
  };

  const { mutateAsync: deleteVenueConfig } = useDeleteVenueConfig(venueId);

  const handleDeleteVenueConfig = (
    venueConfigId: string,
    venueRemove?: boolean
  ) => {
    const isSingleConfig = venueConfigs?.items.length === 1;
    const isDefaultConfig =
      venueConfigs?.items.find((item) => item.name === 'DEFAULT')?.id ===
      venueConfigId;

    deleteVenueConfig(
      { venueConfigId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_venue_config_removed'),
          });

          if (isSingleConfig && isDefaultConfig && venueRemove) {
            handleDeleteVenue(venueId);
          }
        },
        onError: (error) => {
          notification.error({
            message: t('notification_error_venue_config_removed'),
            description: error.response.data.message,
          });
        },
      }
    );
  };

  const handleDeleteVenue = (venueId: string) =>
    deleteVenue(
      { venueId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_venue_removed'),
          });
          navigate(`../${state ?? ''}`);
        },
        onError: (error) => {
          notification.error({
            message: t('notification_error_venue_removed'),
            description: error.response.data.message,
          });
        },
      }
    );

  const deleteFullVenue = async () => {
    if (
      venueConfigs?.items.length === 1 &&
      venueConfigs?.items[0].name === 'DEFAULT'
    ) {
      handleDeleteVenueConfig(venueConfigs?.items[0].id, true);
    } else {
      handleDeleteVenue(venueId);
    }
  };

  const loading = spinning || uploadingImage;

  return venue ? (
    <>
      <Spin tip={t('fg_creation_loader_tip')} fullscreen spinning={loading} />
      <Flex
        vertical
        style={{
          padding: '24px 24px 80px',
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`../${state ?? ''}`}>{t('admin_venues')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('admin_tabs_details')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.subheader}>
          <Title level={3}>{getSingleTranslation(venue?.name)}</Title>
          {venue?.id && (
            <Popconfirm
              title={t('remove_venue_button_text')}
              description={t('delete_venue_confirmation_text')}
              cancelText={t('general_no')}
              onConfirm={deleteFullVenue}
              onOpenChange={() => console.log('open change')}
            >
              <Button
                size="large"
                type="text"
                icon={<DeleteOutlined rev={undefined} />}
                color={token.colorError}
              >
                {t('remove_venue_button_text')}
              </Button>
            </Popconfirm>
          )}
        </div>
        <VenueFormAsAdmin
          saveChanges={onSubmit}
          initialValues={initialValues}
          isDetail
        />
        <Title style={{ padding: 0 }} level={5}>
          {t('admin_event_venueconfig')}
        </Title>
        <Table
          rowKey={(config: VenueConfigEntity) => config.id}
          dataSource={venueConfigs?.items}
          tableLayout="fixed"
          pagination={{
            showSizeChanger: true,
          }}
        >
          <Column
            title={t('admin_name')}
            dataIndex="name"
            key="name"
            width="20%"
          />
          <Column
            title={t('profiling_categories_title')}
            dataIndex="categories"
            key="categories"
            width="40%"
            render={(categories) => {
              const cats = categories?.reduce((acc: string[], curr: string) => {
                const name = venueCategories?.items.find(
                  (category) => category.id === curr
                )?.name;
                if (name) acc.push(name);
                return acc;
              }, []);
              return <span>{cats.join(', ')}</span>;
            }}
          />
          <Column
            key="actions"
            align="right"
            render={(_, venueConfig: VenueConfigEntity) => {
              return (
                <Popconfirm
                  title={t('delete_venue_config_title')}
                  description={t('delete_venue_confirm_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() =>
                    handleDeleteVenueConfig(venueConfig.id, false)
                  }
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    type="link"
                    color={token.colorError}
                    icon={<DeleteOutlined rev={undefined} />}
                  >
                    {t('wl_delete-btn')}
                  </Button>
                </Popconfirm>
              );
            }}
          />
        </Table>
      </Flex>
    </>
  ) : (
    <Skeleton />
  );
}

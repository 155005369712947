import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createFGOTransaction,
  createTransaction,
  deleteTransaction,
  fetchTransaction,
} from '../api/transaction';
import {
  AxiosAPIError,
  TransactionEntity,
  waitingListsKeys,
  WLPosition,
} from '@seaters-app/constants';

export const useCreateTransaction = (
  waitingListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{
    body: { [key: string]: { [key: string]: string } };
  }>,
  { [key: string]: { [key: string]: string } },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: { [key: string]: { [key: string]: string } }) =>
      createTransaction(waitingListId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};

export const useCreateFGOTransaction = (
  waitingListId: string,
  fanId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{
    body: { [key: string]: { [key: string]: string } };
  }>,
  { [key: string]: { [key: string]: string } },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: { [key: string]: { [key: string]: string } }) =>
      createFGOTransaction(waitingListId, fanId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};
export const useFetchTransaction = (
  wlId: string,
  wlPosition: WLPosition | null
) =>
  useQuery<TransactionEntity, Error>(
    waitingListsKeys.transaction(wlId),
    () => fetchTransaction(wlId),
    {
      enabled: !!wlId && !!wlPosition,
    }
  );

export const useDeleteTransaction = () => {
  return useMutation(({ wlId }: { wlId: string }) => deleteTransaction(wlId));
};

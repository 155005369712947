import {
  ListEntity,
  usersKeys,
  UserListEntity,
  UsersQuery,
  AxiosAPIError,
  UserValidator,
  HostEntity,
  profileKeys,
  LookEntity,
  UserOwnershipValidator,
  UserOwnershipEntity,
  PaginationQuery,
  ownershipsKeys,
  userHostRolesKeys,
  PaginationQueryV2,
  ListEntityV2,
  UpdatePasswordValidator,
  VerificationType,
  FanGroupBySlug,
  ResetPasswordValidator,
  UserRole,
  RequestAccessStatus,
  approverKeys,
  fansKeys,
  FanCustomInfo,
  routes,
  FanGroupCustomInformation,
  fanGroupsKeys,
} from '@seaters-app/constants';

import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchFGBySlug,
  fetchHost,
  fetchLook,
  resetUserPassword,
  updatePassword,
} from '../api';
import {
  fetchAdminUsers,
  fetchUser,
  deleteUser,
  updateUser,
  createUser,
  createUserOwnership,
  fetchUserOwnerships,
  fetchUserHostRoles,
  createUserHostRole,
  unlockUser,
  fetchUserRolesForCurrentFG,
  fetchUserGroups,
  fetchApproverGroups,
  fetchUserCustomInfo,
  addFanCustomInfo,
  FanCustomInfoBody,
  deleteUserOwnership,
  deleteUserHostRole,
} from '../api/users';
import { getSessionToken } from '../storage';
import { notification } from 'antd';
import { t } from 'i18next';

export const useFetchAdminUsers = (params: UsersQuery) =>
  useQuery<ListEntity<UserListEntity>, Error>(
    usersKeys.list(params),
    () => fetchAdminUsers(params),
    {
      keepPreviousData: true,
    }
  );

export const useFetchHost = () =>
  useQuery<HostEntity, Error>(usersKeys.host(), () => fetchHost(), {
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

export const useFetchLook = (slug: string) => {
  const isEnabled = !!slug && !Object.values(routes).includes(slug);
  return useQuery<
    LookEntity,
    Error & { response: { data: { message: string } } }
  >(usersKeys.look(), () => fetchLook(slug), {
    // refetchOnMount: false,
    // refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    // staleTime: 1000 * 60 * 5,
    retry: 0,
  });
};

export const useFetchFGBySlug = (slug: string, shouldRefetch?: boolean) => {
  const auth = getSessionToken();

  return useQuery<
    FanGroupBySlug,
    Error & { response: { data: { message: string } } }
  >(usersKeys.fanGroupBySlug(slug), () => fetchFGBySlug(slug), {
    refetchOnWindowFocus: false,
    enabled: !!slug && !!auth,
    refetchInterval: !shouldRefetch
      ? false
      : (data) => {
          if (
            data?.membership?.request?.status !== RequestAccessStatus.PENDING
          ) {
            return false;
          }
          return 1000;
        },
    retry: 0,
  });
};

export const useResetPassword = (): UseMutationResult<
  VerificationType,
  AxiosAPIError<ResetPasswordValidator>,
  ResetPasswordValidator,
  unknown
> => {
  return useMutation((body: ResetPasswordValidator) => resetUserPassword(body));
};

export const useUpdatePassword = (): UseMutationResult<
  VerificationType,
  AxiosAPIError<UpdatePasswordValidator>,
  UpdatePasswordValidator,
  unknown
> => {
  return useMutation((body: UpdatePasswordValidator) => updatePassword(body));
};

export const useFetchUser = (userId: string) =>
  useQuery<UserListEntity, Error>(
    usersKeys.detail(userId),
    () => fetchUser(userId),
    {
      enabled: !!userId,
    }
  );

export const useFetchUserFanGroups = (userId: string) =>
  useQuery<ListEntityV2<FanGroupBySlug>, Error>(
    usersKeys.fanGroups(),
    () => fetchUserGroups(userId),
    {
      enabled: !!userId,
    }
  );

export const useFetchApproverUserFanGroups = (userId: string) =>
  useQuery<ListEntityV2<UserOwnershipEntity>, Error>(
    approverKeys.fanGroups(),
    () => fetchApproverGroups(userId),
    {
      enabled: !!userId,
    }
  );

export const useFetchUserRolesForCurrentFG = (fanGroupId: string) =>
  useQuery<{ roles: UserRole[] }, Error>(
    profileKeys.fanGroupRoles(fanGroupId),
    () => fetchUserRolesForCurrentFG(fanGroupId),
    {
      enabled: !!fanGroupId,
    }
  );

export const useFetchUserOwnerships = (
  userId: string,
  params: PaginationQuery
) =>
  useQuery<ListEntity<UserOwnershipEntity>, Error>(
    ownershipsKeys.forUser(userId, params),
    () => fetchUserOwnerships(userId, params),
    { enabled: !!userId }
  );

export const useFetchUserHostRoles = (
  userId: string,
  params: PaginationQueryV2
) =>
  useQuery<ListEntityV2<UserOwnershipEntity>, Error>(
    userHostRolesKeys.forUser(userId, params),
    () => fetchUserHostRoles(userId, params),
    { enabled: !!userId }
  );

export const useFetchProfile = (userId: string) =>
  useQuery<UserListEntity, Error>(profileKeys.all(), () => fetchUser(userId), {
    enabled: !!userId,
  });

export const useCreateUser = (): UseMutationResult<
  UserListEntity,
  AxiosAPIError<UserValidator>,
  UserValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation((body: UserValidator) => createUser(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(usersKeys.all());
    },
  });
};

export const useCreateUserOwnership = (): UseMutationResult<
  ResponseType,
  AxiosAPIError<UserOwnershipValidator>,
  UserOwnershipValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ownershipsKeys.mutation(),
    (body: UserOwnershipValidator) => createUserOwnership(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ownershipsKeys.all());
        queryClient.invalidateQueries(fanGroupsKeys.fanGroupOwner());
      },
    }
  );
};

export const useDeleteUserOwnership = (): UseMutationResult<
  ResponseType,
  AxiosAPIError<UserOwnershipValidator>,
  UserOwnershipValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ownershipsKeys.mutation(),
    (body: UserOwnershipValidator) => deleteUserOwnership(body),
    {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_ownership_deleted'),
        });
        queryClient.invalidateQueries(ownershipsKeys.all());
        queryClient.invalidateQueries(fanGroupsKeys.fanGroupOwner());
      },
    }
  );
};

export const useCreateUserHostRole = (): UseMutationResult<
  ResponseType,
  AxiosAPIError<UserOwnershipValidator>,
  UserOwnershipValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    userHostRolesKeys.mutation(),
    (body: UserOwnershipValidator) => createUserHostRole(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ownershipsKeys.all());
      },
    }
  );
};

export const useDeleteUserHostRole = (): UseMutationResult<
  ResponseType,
  AxiosAPIError<UserOwnershipValidator>,
  UserOwnershipValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    userHostRolesKeys.mutation(),
    (body: UserOwnershipValidator) => deleteUserHostRole(body),
    {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_host_role_deleted'),
        });
        queryClient.invalidateQueries(userHostRolesKeys.all());
        queryClient.invalidateQueries(fanGroupsKeys.host());
      },
    }
  );
};

export const useUpdateUser = (
  userId: string
): UseMutationResult<
  UserListEntity,
  AxiosAPIError<UserValidator>,
  UserValidator,
  unknown
> => {
  return useMutation(usersKeys.mutation(), (body: UserValidator) =>
    updateUser(userId, body)
  );
};

export const useUnlockUser = (
  userId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ userId: string }>,
  string,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(usersKeys.mutation(), () => unlockUser(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries(usersKeys.detail(userId));
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation(({ userId }: { userId: string }) => deleteUser(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries(usersKeys.all());
    },
  });
};

export const useUserCustomInfo = (fanGroupId: string, userId: string) => {
  return useQuery<ListEntityV2<FanCustomInfo>, Error>(
    fansKeys.customInfo(userId),
    () => fetchUserCustomInfo(fanGroupId, userId)
  );
};

export const useAddFanCustomInfo = (
  fanGroupId: string,
  userId: string
): UseMutationResult<
  FanGroupCustomInformation,
  AxiosAPIError<{
    body: FanCustomInfoBody;
  }>,
  {
    body: FanCustomInfoBody;
  },
  string
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ body }: { body: FanCustomInfoBody }) =>
      addFanCustomInfo(fanGroupId, userId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fansKeys.customInfo(userId));
      },
    }
  );
};

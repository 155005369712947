import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Modal, ModalProps, Spin, Steps, notification } from 'antd';
import { useParams } from 'react-router-dom';
import {
  queryClient,
  useFetchWaitingListSurveyInstances,
  useUpdateFanAttendeesInfoAsFGO,
} from '@seaters-app/data-access';
import {
  AttendeesInfoValidator,
  BillingMode,
  FanAnswers,
  ListEntityV2,
  SeatEntity,
  SurveyExtensionPoint,
  WaitingListEntity,
  surveyFansAnswers,
  waitingListsKeys,
} from '@seaters-app/constants';
import {
  StepContentProps,
  FGOStepItemIdEnum,
  StepType,
  StepItemIdEnum,
} from './types';
import { SurveyForm } from './SurveyForm';
import { useFormSteps } from './hooks/useFormSteps';
import { useTranslation } from 'react-i18next';
import { AttendeeInfoStep } from './AttendeeInfoStep';
import { PaymentStep } from './PaymentStep';

const StepComponent = (props: StepContentProps) => {
  const { filteredSteps, currentStep, ...rest } = props;
  return filteredSteps[currentStep]?.content({ ...rest, currentStep });
};

export interface CheckoutModalFGOProps extends ModalProps {
  children?: ReactElement;
  isDisabled?: boolean;
  isLoading?: boolean;
  selectedFan: SeatEntity;
  closeModal: () => void;
}

export function CheckoutModalFGO({ open, ...props }: CheckoutModalFGOProps) {
  const selectedFan = props.selectedFan;
  const closeModal = props.closeModal;
  const { t } = useTranslation();
  const { wishListId = '' } = useParams();

  const { data: surveyInstancesAtCheckout, isLoading: isACSurveysLoading } =
    useFetchWaitingListSurveyInstances({
      waitinglist_id: wishListId,
      extension_point: SurveyExtensionPoint.AT_CHECKOUT,
    });

  const { data: surveyInstancesBeforePayment, isLoading: isBPSurveysLoading } =
    useFetchWaitingListSurveyInstances({
      waitinglist_id: wishListId,
      extension_point: SurveyExtensionPoint.BEFORE_PAYMENT,
    });

  const isSurveysLoading = isACSurveysLoading || isBPSurveysLoading;

  const surveyInstances = surveyInstancesAtCheckout?.content.length
    ? surveyInstancesAtCheckout
    : surveyInstancesBeforePayment;

  const waitingList = queryClient.getQueryData<WaitingListEntity>(
    waitingListsKeys.detail(wishListId)
  );

  const answers = queryClient.getQueryData<
    ListEntityV2<FanAnswers> | undefined
  >(
    surveyFansAnswers.listPerInstance(
      wishListId,
      surveyInstances?.content[0]?.id ?? '',
      { page: 0, size: 10, user_id: selectedFan?.fanId }
    )
  );

  const surveyStep: StepType<FGOStepItemIdEnum> = {
    key: FGOStepItemIdEnum.SURVEY_FORM,
    title: t('survey_title_text'),
    content: (props) => (
      <SurveyForm
        {...props}
        ticketsAmount={selectedFan?.requestedSeats}
        surveyInstances={surveyInstances}
        selectedFanId={selectedFan?.fanId}
      />
    ),
    submitText: t('finish_button_text'),
    orderIndex: 2,
  };

  const paymentStep: StepType<FGOStepItemIdEnum> = {
    key: FGOStepItemIdEnum.PAYMENT_FORM,
    title: t('payment_modal_title'),
    content: (props) => {
      return <PaymentStep selectedFanId={selectedFan?.fanId} {...props} />;
    },
    submitText: t('purchase_button_text'),
    orderIndex: 4,
  };

  const attendeeInfoStep: StepType<FGOStepItemIdEnum> = {
    key: FGOStepItemIdEnum.ATTENDEE_INFO,
    title: t('checkout_requiredinfo_steptitle'),
    content: (props) => {
      return (
        <AttendeeInfoStep
          {...props}
          selectedFan={selectedFan}
          selectedFanId={selectedFan?.fanId}
          requestedSeats={selectedFan?.requestedSeats}
          closeCheckout={closeModal}
        />
      );
    },
    submitText: t('button_text_next'),
    orderIndex: 3,
  };

  let steps = [surveyStep, attendeeInfoStep];
  if (!waitingList?.freeWaitingList) {
    steps = [...steps, paymentStep];
  }

  const [stepsVisibility, setStepsVisibility] = useState<{
    [key in FGOStepItemIdEnum]: boolean;
  }>({
    attendeeInfo: false,
    surveyStep: false,
    paymentForm: false,
  });

  const { mutate: updateFanAttendeesInfoAsFGO } =
    useUpdateFanAttendeesInfoAsFGO(wishListId, selectedFan?.fanId);

  const filteredSteps = steps.filter((el) => stepsVisibility[el.key]);

  const { nextStep, prevStep, currentStep, isLastStep, isLoading } =
    useFormSteps(filteredSteps, selectedFan?.fanId, closeModal);

  const handleAttendeeForm = (
    attendeeValues: AttendeesInfoValidator['attendees']
  ) => {
    updateFanAttendeesInfoAsFGO(
      { body: { attendees: attendeeValues } },
      {
        onSuccess: () => {
          nextStep();
        },
        onError: (error) => {
          notification.error({
            message: t('notification_error_attendees_info_send'),
            description:
              `${error?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorDescription}` ??
              error.message,
          });
        },
      }
    );
  };

  const contentStyle: React.CSSProperties = {
    marginTop: 32,
  };

  useEffect(() => {
    if (waitingList) {
      setStepsVisibility({
        ...stepsVisibility,
        [FGOStepItemIdEnum.ATTENDEE_INFO]:
          !!waitingList.eventRequiredAttendeeInfo?.length,
        [StepItemIdEnum.PAYMENT_FORM]: !waitingList.freeWaitingList,
        [FGOStepItemIdEnum.SURVEY_FORM]:
          !!surveyInstances?.content.length && !answers?.content.length,
      });
    }
  }, [
    waitingList,
    surveyInstancesAtCheckout,
    surveyInstancesBeforePayment,
    answers,
  ]);

  return (
    <Modal
      open={open}
      style={{ maxWidth: 1284 }}
      title={t('checkout_title')}
      closeIcon={null}
      footer={false}
      width={'100%'}
      maskClosable
      onCancel={props.onCancel}
    >
      <Spin spinning={isLoading || isSurveysLoading}>
        {!waitingList?.freeWaitingList && (
          <Alert
            style={{ margin: '12px 0' }}
            message={t('paid_wl_checkout_alert_message')}
            type="warning"
          />
        )}

        {filteredSteps.length > 1 ? (
          <Steps
            current={currentStep}
            items={filteredSteps.sort((a, b) => a.orderIndex - b.orderIndex)}
          />
        ) : null}
        <div style={contentStyle}>
          <StepComponent
            next={nextStep}
            prev={prevStep}
            isLastStep={isLastStep}
            waitingList={waitingList}
            handleAttendeeForm={handleAttendeeForm}
            filteredSteps={filteredSteps}
            currentStep={currentStep}
          />
        </div>
      </Spin>
    </Modal>
  );
}

export enum FanGroupStatus {
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
  PUBLISHED = 'PUBLISHED',
}

export enum AccessMode {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  CODE_PROTECTED = 'CODE_PROTECTED',
  PUBLIC_OPTIONAL_CODE = 'PUBLIC_OPTIONAL_CODE',
}

export enum FanGroupVisibility {
  VISIBLE = 'VISIBLE',
  INVISIBLE = 'INVISIBLE',
}

export enum FanGroupBillingMode {
  FREE = 'FREE',
  FAIR_PRICE = 'FAIR_PRICE',
}

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  MASTERPASS = 'MASTERPASS',
  IDEAL = 'IDEAL',
  VIRTUAL = 'VIRTUAL',
}

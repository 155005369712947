import {
  AttendeeRequiredFieldsType,
  FanGroupOwnerEventEntity,
  Option,
  attendeeFields,
} from '@seaters-app/constants';
import { DatePicker, PhoneInputWithCountryCode } from '@seaters-app/ui';
import { Form, Input, Select } from 'antd';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type AttendeeInfoFormType = {
  company?: string;
  title?: string;
  city?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  language?: string;
  country?: string;
  idNumber?: string;
  birthDate?: Dayjs;
  countryCode?: string;
  citizenshipCountryCode?: string;
  phoneNumber?: { localNumber: string; countryCallingCode: string };
  zipCode?: string;
  address?: {
    line1?: string;
    line2?: string;
    line3?: string;
    zipCode?: string;
    city?: string;
    state?: string;
    countryCode?: string;
  };
  state?: string;
};

export const attendeeInfoLabel: {
  [key in AttendeeRequiredFieldsType]: string;
} = {
  company: 'checkout_att_field_company',
  title: 'checkout_att_field_title',
  city: 'checkout_att_field_city',
  email: 'checkout_att_field_email',
  firstName: 'checkout_att_field_firstname',
  lastName: 'checkout_att_field_lastname',
  language: 'checkout_att_field_language',
  country: 'checkout_att_field_country',
  idNumber: 'checkout_att_field_passportid',
  birthDate: 'checkout_att_field_dateofbirth',
  citizenshipCountryCode: 'checkout_att_field_citizenship',
  phoneNumber: 'checkout_att_field_phone',
  zipCode: 'checkout_att_field_zip',
  address: 'checkout_att_field_address',
  state: 'checkout_att_field_state',
};

export type AttendeeInfoFormProps = {
  fields: FanGroupOwnerEventEntity['attendeeRequiredFields'] | undefined;
  languagesOptions?: Option[];
  countriesOptions?: Option[];
  onFormSubmit: (formData: AttendeeInfoFormType) => void;
  defaultValues: AttendeeInfoFormType;
};

export const titleOptions = [
  {
    label: 'settings_personal-information_title-mr',
    value: 'MR',
  },
  {
    label: 'settings_personal-information_title-ms',
    value: 'MS',
  },
];

export const LanguageField = ({
  formItemStyle,
  errors,
  control,
  languagesOptions,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
  languagesOptions?: Option[];
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      style={formItemStyle}
      name="language"
      label={t('settings_personal-section_form_lang-label')}
      validateStatus={errors && errors.language && errors.language && 'error'}
      help={<>{errors?.language?.message}</>}
    >
      <Controller
        control={control}
        rules={{
          required: t('general_label_required')
            ? (t('general_label_required') as string)
            : 'Required',
        }}
        name={`language`}
        render={({ field }) => (
          <Select options={languagesOptions} {...field} size="middle" />
        )}
      />
    </Form.Item>
  );
};

export const AddressFields = ({
  formItemStyle,
  control,
  errors,
}: {
  formItemStyle: {
    width: string;
  };
  control: Control<AttendeeInfoFormType, any>;
  errors: FieldErrors<AttendeeInfoFormType>;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        style={formItemStyle}
        name={`address.line1`}
        label={t('settings_personal-information_address-label-line1')}
        required
        validateStatus={
          errors && errors.address && errors.address?.line1 && 'error'
        }
        help={errors.address?.line1?.message}
      >
        <Controller
          control={control}
          rules={{
            required: t('general_label_required')
              ? (t('general_label_required') as string)
              : 'Required',
          }}
          name={`address.line1`}
          render={({ field }) => <Input {...field} size="middle" />}
        />
      </Form.Item>
      <Form.Item
        style={formItemStyle}
        name={`address.line2`}
        label={t('settings_personal-information_address-label-line2')}
      >
        <Controller
          control={control}
          name={`address.line2`}
          render={({ field }) => <Input {...field} size="middle" />}
        />
      </Form.Item>
      <Form.Item
        style={formItemStyle}
        name={`address.line3`}
        label={t('settings_personal-information_address-label-line3')}
      >
        <Controller
          control={control}
          name={`address.line3`}
          render={({ field }) => <Input {...field} size="middle" />}
        />
      </Form.Item>
    </>
  );
};

export const ZipCodeField = ({
  formItemStyle,
  errors,
  control,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      style={formItemStyle}
      name={`address.zipCode`}
      label={t(attendeeInfoLabel.zipCode)}
      required
      validateStatus={
        errors && errors.address && errors.address.zipCode && 'error'
      }
      help={errors?.address?.zipCode?.message}
    >
      <Controller
        control={control}
        rules={{
          required: t('general_label_required')
            ? (t('general_label_required') as string)
            : 'Required',
        }}
        name={`address.zipCode`}
        render={({ field }) => <Input {...field} size="middle" />}
      />
    </Form.Item>
  );
};

export const TitleField = ({
  formItemStyle,
  errors,
  control,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      style={formItemStyle}
      name={`title`}
      label={t(attendeeInfoLabel.title)}
      required
      validateStatus={errors && errors.title && 'error'}
      help={errors?.title?.message}
    >
      <Controller
        control={control}
        rules={{
          required: t('general_label_required')
            ? (t('general_label_required') as string)
            : 'Required',
        }}
        name={`title`}
        render={({ field }) => (
          <Select
            {...field}
            size="middle"
            options={titleOptions.map((el) => ({
              ...el,
              label: t(el.label),
            }))}
          />
        )}
      />
    </Form.Item>
  );
};

export const CityField = ({
  formItemStyle,
  errors,
  control,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      style={formItemStyle}
      name={`address.city`}
      label={t(attendeeInfoLabel.city)}
      required
      validateStatus={
        errors && errors.address && errors.address.city && 'error'
      }
      help={errors?.address?.city?.message}
    >
      <Controller
        control={control}
        rules={{
          required: t('general_label_required')
            ? (t('general_label_required') as string)
            : 'Required',
        }}
        name={`address.city`}
        render={({ field }) => <Input {...field} size="middle" />}
      />
    </Form.Item>
  );
};

export const CountryField = ({
  formItemStyle,
  errors,
  control,
  countriesOptions,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
  countriesOptions?: Option[];
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      style={formItemStyle}
      name={`country`}
      label={t(attendeeInfoLabel.country)}
      required
      validateStatus={errors && errors.country && 'error'}
      help={<>{errors?.country?.message}</>}
    >
      <Controller
        control={control}
        name={'country'}
        render={({ field }) => (
          <Select
            {...field}
            filterOption
            optionFilterProp="label"
            showSearch
            options={countriesOptions}
          />
        )}
      />
    </Form.Item>
  );
};

export const CitizenshipField = ({
  formItemStyle,
  errors,
  control,
  countriesOptions,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
  countriesOptions?: Option[];
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      style={formItemStyle}
      name={`citizenshipCountryCode`}
      label={t(attendeeInfoLabel.citizenshipCountryCode)}
      validateStatus={errors && errors.citizenshipCountryCode && 'error'}
      help={<>{errors?.citizenshipCountryCode?.message}</>}
      required={
        attendeeFields.find((field) => field.name === 'citizenshipCountryCode')
          ?.required
      }
    >
      <Controller
        control={control}
        name={`citizenshipCountryCode`}
        render={({ field }) => (
          <Select
            {...field}
            filterOption
            optionFilterProp="label"
            showSearch
            options={countriesOptions}
          />
        )}
      />
    </Form.Item>
  );
};

export const StateField = ({
  formItemStyle,
  errors,
  control,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      style={formItemStyle}
      name={`address.state`}
      label={t(attendeeInfoLabel.state)}
      required
      validateStatus={
        errors && errors.address && errors.address.state && 'error'
      }
      help={errors?.address?.state?.message}
    >
      <Controller
        control={control}
        name={`address.state`}
        render={({ field }) => <Input {...field} size="middle" />}
      />
    </Form.Item>
  );
};

export const BirthDateField = ({
  formItemStyle,
  errors,
  control,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      style={formItemStyle}
      name={`birthDate`}
      label={t(attendeeInfoLabel.birthDate)}
      required={
        attendeeFields.find((field) => field.name === 'birthDate')?.required
      }
      validateStatus={errors && errors.birthDate && 'error'}
      help={errors && errors.birthDate && errors.birthDate.message}
    >
      <Controller
        control={control}
        name={`birthDate`}
        render={({ field }) => (
          <DatePicker
            size="middle"
            style={{ width: '100%' }}
            format="MM-DD-YYYY"
            {...field}
          />
        )}
      />
    </Form.Item>
  );
};

export const MobileField = ({
  formItemStyle,
  control,
  defaultValues,
  setValue,
  errors,
}: {
  formItemStyle: {
    width: string;
  };
  errors: FieldErrors<AttendeeInfoFormType>;
  control: Control<AttendeeInfoFormType, any>;
  defaultValues: any;
  setValue: UseFormSetValue<any>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState(
    defaultValues?.phoneNumber?.countryCallingCode &&
      defaultValues?.phoneNumber?.localNumber
      ? defaultValues?.phoneNumber?.countryCallingCode +
          defaultValues?.phoneNumber?.localNumber
      : ''
  );

  const [countryCode, setCountryCode] = useState(
    defaultValues?.phoneNumber?.countryCallingCode ?? ''
  );

  useEffect(() => {
    if (phoneNumber) {
      setValue(
        'phoneNumber.localNumber',
        phoneNumber?.slice(countryCode?.length)
      );
    }
    if (countryCode) {
      setValue('phoneNumber.countryCallingCode', countryCode);
    }
  }, [phoneNumber, countryCode]);

  return (
    <Form.Item
      style={formItemStyle}
      label={t('phoneNumber')}
      name={`phoneNumber.localNumber`}
      validateStatus={errors && errors.phoneNumber && 'error'}
      help={
        errors && errors.phoneNumber && errors.phoneNumber.localNumber?.message
      }
      required={
        attendeeFields.find((field) => field.name === 'phoneNumber')?.required
      }
    >
      <Controller
        control={control}
        name={`phoneNumber.localNumber`}
        render={({ field }) => {
          return (
            <PhoneInputWithCountryCode
              {...field}
              isError={!!errors.phoneNumber}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
            />
          );
        }}
      />
    </Form.Item>
  );
};

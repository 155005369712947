import { z } from 'zod';
import {
  CustomProfileScopeEnum,
  CustomProfileTypeEnum,
} from '../../enums/customProfile';

export const CustomProfileFormValidatorSchema = z.object({
  customInfos: z.array(
    z.object({
      scope: z.nativeEnum(CustomProfileScopeEnum),
      semanticName: z.string(),
      type: z.nativeEnum(CustomProfileTypeEnum),
      mandatory: z.boolean(),
    })
  ),
});

export type CustomProfileFormValidator = z.infer<
  typeof CustomProfileFormValidatorSchema
>;

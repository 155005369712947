import { Typography } from 'antd';
import { Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export function FacebookLogin() {
  const { t } = useTranslation();
  return (
    <>
      <Button
        color="blue"
        size="large"
        type="primary"
        style={{ width: '100%' }}
      >
        {t('button_text_facebook_connect')}
      </Button>
      <div style={{ textAlign: 'center' }}>
        <Text color="grey">{t('public_classic_auth_login')}</Text>
      </div>
    </>
  );
}

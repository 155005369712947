import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from 'antd';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWLCodes } from './../hooks/useWLCodes';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { ProtectionCodeEntity } from '@seaters-app/constants';
import {
  useCreateWLProtectionCode,
  useDeleteWLProtectionCode,
} from '@seaters-app/data-access';

export function WLProtectionCodes() {
  const [code, setCode] = useState<string>('');
  const [maxTimesUse, setMaxTimesUse] = useState<number>(100);
  const { wishListId = '' } = useParams();
  const { t } = useTranslation();

  const {
    data: codesData,
    isLoading,
    handleTableChange,
    pagination,
  } = useWLCodes(wishListId);

  const { mutate: createCode, isLoading: isCodeCreating } =
    useCreateWLProtectionCode(wishListId);

  const { mutate: deleteCode } = useDeleteWLProtectionCode(wishListId);

  const copyCode = useCallback(
    (i: number) => {
      if (codesData) {
        navigator.clipboard.writeText(codesData?.items[i].code);
        notification.success({
          message: t('notification_success_code_copied'),
        });
      }
    },
    [codesData]
  );

  const generateCode = (code: string, maxTimesUse: number) => {
    createCode(
      { code: code, maxTimesUsed: maxTimesUse, timesUsed: 0 },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_code_generated'),
          });
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_code_generated'),
          });
        },
      }
    );
  };

  const handleDeleteCode = (code: string) =>
    deleteCode(code, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_code_removed'),
        });
      },
      onError: () => {
        notification.error({
          message: t('notification_error_code_removed'),
        });
      },
    });

  return (
    <div>
      <Form layout="vertical">
        <Row gutter={24}>
          <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
            <Form.Item label={t('admin_currency_code')}>
              <Input
                onChange={(e) => setCode(e.target.value)}
                placeholder={t('type_here_placeholder_text')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
            <Form.Item
              label={t('admin_fan-group-protection-codes_max-times-used')}
            >
              <Input
                type="number"
                onChange={(e) => setMaxTimesUse(Number(e.target.value))}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="hidden">
              <Button
                onClick={() => generateCode(code, maxTimesUse)}
                loading={isCodeCreating}
              >
                {t('generate_button_text')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        rowKey={(code) => {
          return code.code;
        }}
        dataSource={codesData?.items}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
      >
        <Column
          title={t('admin_fan-group-protection-codes_code')}
          dataIndex="code"
          key="code"
          width="12%"
          render={(code, _, i) => {
            return (
              <Space size={8}>
                <span>{code}</span>
                <CopyOutlined onClick={() => copyCode(i)} rev={undefined} />
              </Space>
            );
          }}
        />
        <Column
          title={t('admin_fan-group-protection-codes_max-times-used')}
          dataIndex="maxTimesUsed"
          key="maxTimesUsed"
          width="15%"
          render={(maxTimesUsed = '–') => {
            return <span>{maxTimesUsed}</span>;
          }}
        />
        <Column
          title={t('admin_fan-group-protection-codes_times-used')}
          dataIndex="timesUsed"
          width="10%"
          key="timesUsed"
          render={(timesUsed) => {
            return <span>{timesUsed ?? '-'}</span>;
          }}
        />
        <Column
          key="actions"
          align="right"
          width="30%"
          render={(_, code: ProtectionCodeEntity, i) => {
            return (
              <>
                <Popconfirm
                  title={t('delete_code_button_text')}
                  description={t('delete_code_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() => {
                    handleDeleteCode(code.code);
                  }}
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined rev={undefined} />}
                  >
                    {t('wl_delete-btn')}
                  </Button>
                </Popconfirm>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
}

import {
  ApproverEntity,
  AxiosAPIError,
  FGOReportEntity,
  FanGroupEntity,
  ListEntity,
  ListEntityV2,
  PaginationQuery,
  PaginationQueryV2,
  fanGroupOwnerKeys,
  fanGroupOwnerWaitingListsKeys,
  fanGroupsKeys,
  ReportType,
  FanGroupOwnerFanGroupEntity,
  FanGroupValidator,
  AnswersReportEntity,
  AnswersReportBody,
  FanGroupMember,
  VerificationTypeV2,
  UserRole,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  exportFGOReport,
  fetchFGOWaitingListApprovers,
  fetchFanGroupOwnerGroup,
  fetchFanGroupOwnerGroups,
  generateSeatsList,
  fetchFGOWaitingListAvailableSeats,
  fetchFGOWaitingListAvailableSecondarySeats,
  updateFGOFanGroup,
  generateSurveyAnswersReport,
  fetchFGOWaitingListExpiredPositions,
} from '../api';
import { getSessionData } from '../storage/sessionStorage';

export const useFetchFanGroupOwnerGroups = (params: PaginationQuery) => {
  const sessionData = getSessionData();

  const enabled = sessionData?.userData?.roles.includes(
    UserRole.FAN_GROUP_OWNER
  );

  return useQuery<ListEntity<FanGroupOwnerFanGroupEntity>, Error>(
    fanGroupsKeys.fanGroupOwner(),
    () => fetchFanGroupOwnerGroups(params),
    {
      enabled,
      keepPreviousData: true,
    }
  );
};

export const useUpdateFGOFanGroup = (
  id: string
): UseMutationResult<
  FanGroupEntity,
  AxiosAPIError<FanGroupValidator>,
  FanGroupValidator,
  unknown
> => {
  return useMutation(fanGroupOwnerKeys.detail(id), (body: FanGroupValidator) =>
    updateFGOFanGroup(id, body)
  );
};
export const useFetchFanGroupOwnerGroup = (id: string) =>
  useQuery<FanGroupEntity, Error>(fanGroupsKeys.detail(id), () =>
    fetchFanGroupOwnerGroup(id)
  );

// fetchFGOWaitingListApprovers
export const useFetchFGOWaitingListApprovers = (
  waitingListId: string,
  params: PaginationQueryV2
) =>
  useQuery<ListEntityV2<ApproverEntity>, Error>(
    fanGroupOwnerWaitingListsKeys.approvers(waitingListId),
    () => fetchFGOWaitingListApprovers(waitingListId, params),
    {
      keepPreviousData: true,
    }
  );

export const useGenerateFGOReport = (
  fgId: string
): UseMutationResult<
  FGOReportEntity,
  AxiosAPIError<{ reportName: ReportType }>,
  { reportName: ReportType },
  unknown
> => {
  return useMutation(({ reportName }: { reportName: ReportType }) =>
    exportFGOReport(fgId, reportName)
  );
};

export const useExportFGOSeats = (
  wishListId: string
): UseMutationResult<
  FGOReportEntity,
  AxiosAPIError<FGOReportEntity>,
  unknown
> => {
  return useMutation(() => generateSeatsList(wishListId));
};

export const useExportSurveyAnswers = (
  wishListId: string
): UseMutationResult<
  AnswersReportEntity,
  AxiosAPIError<AnswersReportEntity>,
  AnswersReportBody,
  unknown
> => {
  return useMutation((body: AnswersReportBody) =>
    generateSurveyAnswersReport(wishListId, body)
  );
};

export const useFetchFGOWaitingListAvailableSeats = (waitingListId: string) =>
  useQuery<{ numberOfAvailableSeats: number }, Error>(
    fanGroupOwnerWaitingListsKeys.availableSeats(waitingListId),
    () => fetchFGOWaitingListAvailableSeats(waitingListId),
    {
      keepPreviousData: true,
    }
  );

export const useFetchFGOWaitingListAvailableSecondarySeats = (
  waitingListId: string
) =>
  useQuery<{ numberOfAvailableSeats: number }, Error>(
    fanGroupOwnerWaitingListsKeys.availableSecondarySeats(waitingListId),
    () => fetchFGOWaitingListAvailableSecondarySeats(waitingListId),
    {
      keepPreviousData: true,
    }
  );

export const useFetchFGOWaitingListExpiredPositions = (waitingListId: string) =>
  useQuery<ListEntityV2<FanGroupMember>, Error>(
    fanGroupOwnerWaitingListsKeys.expiredPositions(waitingListId),
    () => fetchFGOWaitingListExpiredPositions(waitingListId),
    {
      keepPreviousData: true,
    }
  );

import { routes } from '@seaters-app/constants';
import { useUpdatePassword } from '@seaters-app/data-access';
import { Button, PasswordValidation } from '@seaters-app/ui';
import { Card, Col, Modal, Row, Typography, notification } from 'antd';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const { Title, Text } = Typography;

function UpdatePassword({ token }: { token: string }) {
  const [password, setPassword] = useState('');
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate: updatePassword } = useUpdatePassword();
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };
  const onFinish = async (values: { password: string }) => {
    updatePassword(
      { password: values.password, token },
      {
        onSuccess: () => {
          showInfo();
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: err.message,
            description:
              t(
                `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
              ) ?? err.message,
          });
        },
      }
    );
  };
  const showInfo = () => {
    Modal.warning({
      title: t('info_message_modal_title'),
      width: 500,
      onOk: () => navigate(`../${routes.login}`),
      content: <div>{t('resetpassword_processing_validated')}</div>,
    });
  };
  return (
    <Card className="publicForm" bordered={false}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            autoComplete="off"
            onFieldsChange={handleFormChange}
            initialValues={{ password: '' }}
          >
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <Title level={3}>{t('resetpassword_verify_title')}</Title>
              <Text>{t('resetpassword_newpassword_form_notice')}</Text>
            </div>

            <Form.Item
              label={t('resetpassword_newpassword_form_password-label')}
              name="password"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value.length ||
                      (value.length >= 15 &&
                        /[A-Z]/.test(password) &&
                        /[a-z]/.test(password) &&
                        /\d/.test(password) &&
                        /[^a-zA-Z0-9]/.test(password))
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        t('profile_password_requirements_validation')
                      );
                    }
                  },
                }),
              ]}
            >
              <Input.Password onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>

            <Form.Item
              label={t('resetpassword_newpassword_form_password-confirm-label')}
              name="confirmNewPassword"
              dependencies={['password']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('password') === value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        t('general_new_password_validation')
                      );
                    }
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            {!!form.getFieldValue('password')?.length && (
              <PasswordValidation password={password} />
            )}
            <Form.Item>
              <Button
                style={{ width: '100%' }}
                size="large"
                type="primary"
                htmlType="submit"
                disabled={disabledSave}
              >
                {t('resetpassword_newpassword_form_submit-btn')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}

export default UpdatePassword;

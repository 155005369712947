import { DeleteOutlined } from '@ant-design/icons';
import { Table, Space, Popconfirm, theme, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  MailcampaignEntity,
  MailcampaignState,
  PartialMailcampaignEntity,
} from '@seaters-app/constants';
import { Typography } from 'antd';
import { Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  useDeleteMailcampaign,
  useFanGroupStore,
  useFetchMailcampaigns,
} from '@seaters-app/data-access';
import { TablePaginationConfig } from 'antd';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { usePagination } from '../../../utils';
import dayjs from 'dayjs';
import { recipientToLabel } from '../utils/recipientToLabel';

const { Text } = Typography;
function MailcampaignDraft() {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const { fanGroup } = useFanGroupStore();

  const fanGroupId = fanGroup?.id ?? '';

  const [query, setQuery] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    current: withDefault(NumberParam, 1),
  });
  const { mutate: deleteMailCampaign } = useDeleteMailcampaign();
  const { data: mailcampaign } = useFetchMailcampaigns({
    fangroup: fanGroupId,
    state: MailcampaignState.DRAFT,
    offset: (query.current - 1) * query.pageSize,
    limit: query.pageSize,
  });

  const pagination = usePagination({
    ...query,
    totalSize: mailcampaign?.count,
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      pageSize,
      current,
    });
  };

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        size="small"
        pagination={pagination}
        onChange={handleTableChange}
        dataSource={mailcampaign?.items}
        style={{ overflowX: 'auto' }}
        columns={[
          {
            title: t('mailcampain_table-name'),
            render: (mailcampaign: MailcampaignEntity) => {
              return (
                <Space size={0} direction="vertical">
                  <NavLink to={`../detail/${mailcampaign.id}`}>
                    <Text
                      style={{
                        padding: 0,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        height: 'auto',
                        textAlign: 'left',
                      }}
                    >
                      {mailcampaign.name}
                    </Text>
                  </NavLink>
                </Space>
              );
            },
          },
          {
            title: t('mailcampain_table-update_date'),
            dataIndex: 'updatedAt',
            render: (updatedAt: Date) => {
              return (
                <Space size={0} direction="vertical">
                  <Text
                    style={{
                      padding: 0,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      height: 'auto',
                      textAlign: 'left',
                    }}
                  >
                    {dayjs(updatedAt).format('DD/MM/YY, HH:mm')}
                  </Text>
                </Space>
              );
            },
          },
          {
            title: t('mailcampaign_table-recipient'),
            render: (mailCampaign: PartialMailcampaignEntity) => (
              <Text
                style={{
                  padding: 0,
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  height: 'auto',
                  textAlign: 'left',
                }}
              >
                {recipientToLabel(mailCampaign?.recipient) || ''}
                {!!mailCampaign?.recipient &&
                mailCampaign.recipient !== 'fangroup'
                  ? ` (${mailCampaign?.wishlist?.name})`
                  : ''}
              </Text>
            ),
          },
          {
            render: (mailcampaign: MailcampaignEntity) => {
              return (
                <Space size="small">
                  <NavLink to={`../edit/${mailcampaign.id}`}>
                    <Button type="link">{t('edit_button')}</Button>
                  </NavLink>
                  <Popconfirm
                    title={t('remove_mailcampaign_text')}
                    description={t('remove_mailcampaign_confirmation_text')}
                    cancelText={t('general_no')}
                    onConfirm={() =>
                      deleteMailCampaign({ id: mailcampaign.id })
                    }
                    onOpenChange={() => console.log('open change')}
                  >
                    <Button
                      type="link"
                      color={token.colorError}
                      icon={<DeleteOutlined rev={undefined} />}
                    >
                      {t('general_delete')}
                    </Button>
                  </Popconfirm>
                </Space>
              );
            },
          },
        ]}
      />
    </>
  );
}

export default MailcampaignDraft;

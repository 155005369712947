import {
  InvitationEntity,
  InvitationForApproverEntity,
  InvitationStatusEnum,
  WLPositionStatus,
} from '@seaters-app/constants';

export const invitationStatusTranslation = {
  [InvitationStatusEnum.BEING_REVIEWED]: 'guestlist_status_being_reviewed',
  [InvitationStatusEnum.DEMAND_REFUSED]: 'guestlist_status_demand_refused',
  [InvitationStatusEnum.CANCELLED]: 'guestlist_status_rsvp_declinded',
  [InvitationStatusEnum.DEMAND_ACCEPTED]: 'guestlist_status_demand_accepted',
  [InvitationStatusEnum.RSVP_DECLINED]: 'guestlist_status_rsvp_declinded',
  [InvitationStatusEnum.RSVP_TO_ACCEPT]: 'guestlist_status_rsvp_to_accept',
  [InvitationStatusEnum.RSVP_CONFIRMED]: 'guestlist_status_rsvp_confirmed',
};

export function getInvitationStatus(
  invitation: InvitationEntity | InvitationForApproverEntity | undefined
) {
  if (!invitation) return null;

  if (invitation.status === 'PENDING') {
    return InvitationStatusEnum.BEING_REVIEWED;
  } else if (
    invitation.status === 'REJECTED' ||
    invitation.position?.seatsRequestStatus === 'DECLINED'
  ) {
    return InvitationStatusEnum.DEMAND_REFUSED;
  } else if (
    invitation.status === 'APPROVED' &&
    invitation.position?.status === WLPositionStatus.WAITING_SEAT
  ) {
    return InvitationStatusEnum.DEMAND_ACCEPTED;
  } else if (
    invitation.position?.status === WLPositionStatus.HAS_SEAT &&
    (invitation.position?.seatStatus === 'ASSIGNED' ||
      invitation.position?.seatStatus === 'ASSIGNED_WITHOUT_SEATS')
  ) {
    return InvitationStatusEnum.RSVP_TO_ACCEPT;
  } else if (
    invitation.status === 'APPROVED' &&
    invitation.position?.status === WLPositionStatus.HAS_SEAT &&
    (invitation.position?.seatStatus === 'ACCEPTED' ||
      invitation.position?.seatStatus === 'RSVP_ACCEPTED')
  ) {
    return InvitationStatusEnum.RSVP_CONFIRMED;
  } else if (invitation.status === 'APPROVED' && invitation.position === null) {
    // TO DO: WHY SO ?
    return InvitationStatusEnum.RSVP_DECLINED;
  } else if (invitation.status === 'CANCELLED') {
    return InvitationStatusEnum.CANCELLED;
  }

  return null;
}

import {
  ConfigProvider,
  Form,
  Input,
  Space,
  Tooltip,
  Typography,
  notification,
  theme,
} from 'antd';
import { Button, baseColor600 } from '@seaters-app/ui';
import {
  LoginValidator,
  routes,
  LoginTypeEnum,
  UserRole,
  SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED,
  LOCATION_STATES,
  PrivacyPolicyAcceptStatusEnum,
  BNPHospitalitySlug,
  SESSION_STORAGE_TARGET_APP,
  TranslationsTarget,
  AccessMode,
  LookEntity,
} from '@seaters-app/constants';
import {
  getObjectTranslation,
  getSlugFromUrl,
  queryClient,
  useIsCustomFlow,
  useRequestFanAccessToProtectedFG,
  useSignIn,
} from '@seaters-app/data-access';

import {
  useNavigate,
  Link,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { FacebookLogin } from './FacebookLogin';
import { useGetAuthOption } from '../hooks/useGetAuthOption';
import { useTranslation } from 'react-i18next';
import { ComplianceModal } from '@seaters-app/ui-shared';
import { useAppTranslationsChange } from '../../../../utils';
import { InfoCircleOutlined } from '@ant-design/icons';

interface ClassicLoginForm {
  email: string;
  password: string;
  accessCode?: string;
}
const { Text, Title } = Typography;

export function ClassicLogin() {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const { pathname } = useLocation();

  const isAdminLogin = pathname.includes(routes.admin);

  const [searchParams] = useSearchParams();

  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);
  const accessCode = searchParams.get('accessCode') ?? '';
  const email = searchParams.get('email') ?? '';

  const initialValues: ClassicLoginForm = {
    email: email,
    password: '',
  };

  if (accessCode) initialValues.accessCode = accessCode;

  const { state: locationState } = useLocation();
  const isCustom = useIsCustomFlow();

  const slug = getSlugFromUrl();
  const { token } = theme.useToken();

  const { mutate: signIn, isLoading: isSignInLoading } = useSignIn();

  const { mutate: requestAccess } = useRequestFanAccessToProtectedFG(
    fanGroupData?.fanGroupId ?? ''
  );
  const navigate = useNavigate();
  const PROD = import.meta.env.MODE === 'production';
  const { changeTranslations } = useAppTranslationsChange();

  const registrationIsHidden =
    isAdminLogin ||
    !fanGroupData?.registration.length ||
    fanGroupData?.registration.find((type) => type === 'NONE');

  const routeToRedirect =
    locationState?.[LOCATION_STATES.REDIRECT_PATH]?.pathname ||
    `${routes.root}${slug ?? routes.admin}`;

  const onFinish = async (values: LoginValidator) => {
    const dataToSend = {
      email: values.email.trim(),
      password: values?.password,
    };

    signIn(dataToSend, {
      onSuccess: async (res) => {
        if (initialValues.accessCode) {
          await requestAccess({
            code: initialValues.accessCode,
          });
        }

        if (
          !isAdminLogin &&
          fanGroupData &&
          ((isCustom && res.userData?.roles.includes(UserRole.HOST)) ||
            res.userData?.roles.includes(UserRole.APPROVER)) &&
          !!fanGroupData?.termsAndConditionsUrls &&
          slug === BNPHospitalitySlug
        ) {
          sessionStorage.setItem(
            SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED,
            PrivacyPolicyAcceptStatusEnum.NOT_ACCEPTED
          );
        } else {
          navigate(routeToRedirect);
        }

        if (isAdminLogin) {
          changeTranslations(
            SESSION_STORAGE_TARGET_APP,
            TranslationsTarget.COCKPIT
          );
        }
      },

      onError: () => {
        notification.error({
          message: t('validation_error_invalid_credentials'),
        });
      },
    });
  };

  const facebookLogin = useGetAuthOption(LoginTypeEnum.FACEBOOK).login;

  const tooltipContent = getObjectTranslation(
    fanGroupData?.protectionCodeExplanation,
    lang,
    t('unlock_text_fangroup_reserved', {
      fangroup: fanGroupData?.translatedName,
    })
  );

  return (
    <>
      <Form
        name="basic"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initialValues}
        data-testid="signInForm"
      >
        <Space
          direction="vertical"
          size="large"
          style={{ paddingBottom: 24, width: '100%' }}
        >
          <Title level={3}>{t('login_text_title')}</Title>
          {facebookLogin && !isAdminLogin && !PROD ? <FacebookLogin /> : null}
        </Space>
        {!isAdminLogin &&
          fanGroupData?.accessMode === AccessMode.CODE_PROTECTED &&
          !!initialValues.accessCode && (
            <Form.Item
              label={
                <Text strong style={{ color: baseColor600 }}>
                  {t('signup_text_enter_access_code')}{' '}
                  <Tooltip title={tooltipContent}>
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </Text>
              }
              name="accessCode"
            >
              <Input disabled={!!initialValues?.accessCode} />
            </Form.Item>
          )}

        <Form.Item
          label={t('signup_form_email-label')}
          name="email"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              type: 'email',
              message: t('checkout_missingfield_email'),
            },
          ]}
        >
          <Input data-testid="signInEmailField" />
        </Form.Item>
        <Form.Item
          label={
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {t('signin_form_password-label')}
              <ConfigProvider
                theme={{
                  token: {
                    colorLink: token.colorPrimary,
                    colorLinkHover: token.colorPrimary,
                    colorLinkActive: token.colorPrimary,
                  },
                }}
              >
                <Link
                  tabIndex={-1}
                  to={`../${routes.authenticate}/${routes.resetPassword}`}
                >
                  <Button size="small" type="link">
                    {t('signin_form_reset-password-btn')}
                  </Button>
                </Link>
              </ConfigProvider>
            </div>
          }
          name="password"
          rules={[
            { required: true, message: t('profile_password_validation') },
          ]}
        >
          <Input.Password data-testid="signInPasswordField" />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            data-testid="signInSubmitButton"
          >
            {t('button_text_login')}
          </Button>
        </Form.Item>
        {!registrationIsHidden && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text>{t('signin_form_notregistred-question')}</Text>
            <ConfigProvider
              theme={{
                token: {
                  colorLink: token.colorPrimary,
                  colorLinkHover: token.colorPrimary,
                  colorLinkActive: token.colorPrimary,
                },
              }}
            >
              <Link to={`../${routes.signup}`}>
                <Button type="link" data-testid="signUpLink">
                  {t('button_text_create_account')}
                </Button>
              </Link>
            </ConfigProvider>
          </div>
        )}
      </Form>
      <ComplianceModal
        fanGroupData={fanGroupData}
        routeToRedirect={routeToRedirect}
      />
    </>
  );
}

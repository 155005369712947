import { useFanGroupStore, useFetchLook } from '@seaters-app/data-access';
import React from 'react';
import FilterConfig from '../[id]/filterConfig/FilterConfig';

const FilterConfigPage: React.FC = () => {
  const { fanGroup: storedFanGroup } = useFanGroupStore();

  const { data: fanGroup } = useFetchLook(storedFanGroup?.slug ?? '');

  return fanGroup && storedFanGroup ? (
    <FilterConfig
      fanGroupId={storedFanGroup.id}
      properties={fanGroup.properties}
    />
  ) : null;
};

export default FilterConfigPage;

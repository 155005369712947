import { FanAccessRequest, LOCAL_STORAGE_SLUG } from '@seaters-app/constants';
import {
  useFetchFanAccessRequests,
  useFetchLook,
} from '@seaters-app/data-access';
import { Table, Tag } from 'antd';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const accessRequestStatusToColor = {
  PENDING: {
    color: 'warning',
    label: 'pending_status_text',
  },
  REJECTED: {
    color: 'error',
    label: 'rejected_status_text',
  },
  ACCEPTED: {
    color: 'success',
    label: 'accepted_status_text',
  },
};
export const FanAccessRequests = ({ fanId }: { fanId: string }) => {
  const { t } = useTranslation();

  const slug = localStorage.getItem(LOCAL_STORAGE_SLUG);

  const { data: lookData } = useFetchLook(slug ?? '');

  const fanGroupId = lookData?.fanGroupId ?? '';
  const { data: fanAccessRequests, isFetching } = useFetchFanAccessRequests(
    fanId,
    fanGroupId
  );

  return (
    <Table
      rowKey={(accessRequest: FanAccessRequest, i) => i + accessRequest.code}
      loading={isFetching}
      dataSource={fanAccessRequests}
      pagination={{
        showSizeChanger: true,
      }}
      tableLayout="fixed"
    >
      <Column
        title={t('admin_fan-group-protection-codes_code')}
        dataIndex="code"
        key="code"
        width="30%"
      />

      <Column
        title={t('wl_creation-date-label')}
        dataIndex="createdDate"
        key="createdDate"
        render={(createdDate) => {
          return (
            <span>{dayjs(createdDate).format('DD MMM YYYY').toString()}</span>
          );
        }}
      />

      <Column
        title={t('status_label')}
        dataIndex="status"
        key="status"
        render={(status: 'PENDING' | 'REJECTED' | 'ACCEPTED') => {
          return (
            <Tag color={accessRequestStatusToColor[status].color}>
              {t(accessRequestStatusToColor[status]?.label)}
            </Tag>
          );
        }}
      />
    </Table>
  );
};

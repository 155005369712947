import React, { useState } from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Form, message, Space, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';
const { Dragger } = Upload;

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? 'is-dragging' : ''}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === 'error' && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  );
};

interface XlsxUploadProps {
  messageApi: MessageInstance;
  onUpload: (file: File) => Promise<void>;
}

const MultipleFilesUpload: React.FC<XlsxUploadProps> = ({
  messageApi,
  onUpload,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(
      newFileList.map((file) => {
        if (file.status === 'done' || file.status === 'error') {
          return file;
        }
        return {
          ...file,
          status: 'done',
        };
      })
    );
  };

  const { t } = useTranslation();

  const uploadPDF = async () => {
    onUpload(fileList[0].originFileObj as File);
  };

  const createZip = async () => {
    const zip = new JSZip();

    // Add files to the zip and rename them
    fileList.forEach((file, index) => {
      const newFileName = `${index + 1}`;
      zip.file(newFileName, file.originFileObj);
    });

    // Generate zip file as a Blob
    const content = await zip.generateAsync({ type: 'blob' });

    // Create a new File object from the Blob
    const zipFile = new File([content], 'files.zip', {
      type: 'application/zip',
    });

    // Add the generated zip file to the file list
    const newUploadFile: UploadFile = {
      uid: `${Date.now()}`,
      name: zipFile.name,
      status: 'done',
      originFileObj: zipFile,
    };

    // Update the fileList to include the new zip file
    setFileList([newUploadFile]);

    onUpload(zipFile);
  };

  const props: UploadProps = {
    style: {
      position: 'relative',
    },
    name: 'dragger',
    multiple: true,
    onChange: onChange,
    fileList: fileList,
    customRequest: (info: any) => {
      const { onSuccess } = info;
      onSuccess();
    },
    itemRender: (originNode, file) => (
      <DraggableUploadListItem originNode={originNode} file={file} />
    ),
  };

  return (
    <Form.Item
      label={
        <Space>
          {t('upload_pdf_tickets')}{' '}
          {fileList.length > 1 && (
            <Button
              icon={<UploadOutlined rev={undefined} />}
              size="small"
              type="primary"
              onClick={createZip}
            >
              {t('create_zip_button')}
            </Button>
          )}
          {fileList.length === 1 && fileList[0].type === 'application/pdf' && (
            <Button
              size="small"
              type="primary"
              icon={<UploadOutlined rev={undefined} />}
              onClick={uploadPDF}
            >
              {t('upload_pdf_button')}
            </Button>
          )}
        </Space>
      }
      name="dragger"
      rules={[
        {
          required: true,
          message: t('admin_support_bulk_requests_file_upload'),
        },
      ]}
    >
      <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
        <SortableContext
          items={fileList.map((i) => i.uid)}
          strategy={verticalListSortingStrategy}
        >
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('upload_file_instruction-1')}</p>
            <p className="ant-upload-hint">{t('upload_file_instruction-2')}</p>
          </Dragger>
        </SortableContext>
      </DndContext>
    </Form.Item>
  );
};

export default MultipleFilesUpload;

import { RouterProvider } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  Loader,
  baseColor200,
  baseColor400,
  getColorContrast,
  hexToRgb,
  layoutDarkColor,
  primaryColor,
} from '@seaters-app/ui';
import { router } from './router';
import { useFanGroupSlug, useRemoveOldAppCookies } from './hooks';

import {
  getSessionToken,
  getSlugFromUrl,
  useAppThemeStore,
  useFanGroupStore,
  useFetchLook,
} from '@seaters-app/data-access';
import * as Sentry from '@sentry/react';
import { sentryInit, useAppTranslationsChange, useIdle } from '../utils';
import '../i18n/config';
import { useDayjsLocale } from '../utils/hooks/useDayjsLocale';
import { useEffect } from 'react';
import {
  OLD_LOCAL_STORAGE_VERIFICATION,
  SESSION_STORAGE_TARGET_APP,
  SESSION_STORAGE_VERIFICATION,
  TranslationsTarget,
} from '@seaters-app/constants';
import Color from 'color';
import { useRefreshToken } from '../utils/hooks/useRefreshToken';

sentryInit();

export function App() {
  const { mode } = useAppThemeStore();

  const oldAppVerification = localStorage.getItem(
    OLD_LOCAL_STORAGE_VERIFICATION
  );

  const slug = getSlugFromUrl();
  const { data: lookData } = useFetchLook(slug ?? '');

  const { fanGroup: storedFanGroup } = useFanGroupStore();

  const fgColorToUse = slug ? lookData?.color : storedFanGroup?.color1;

  const customColor = fgColorToUse
    ? Color(hexToRgb(`${fgColorToUse}`) ?? 'rgb(0, 0, 0)')
    : Color(hexToRgb(primaryColor) ?? 'rgb(0, 0, 0)');

  const customColorDarken = customColor.darken(0.3).toString();
  const userData = sessionStorage.getItem(SESSION_STORAGE_VERIFICATION);

  const { colorContrast } = getColorContrast(customColor.color);

  const { customLightTheme, customDarkTheme, detectedSlug } = useFanGroupSlug();

  const { ready, i18n } = useTranslation();

  const auth = getSessionToken();

  const tabsColor =
    colorContrast === 'black' ? customColorDarken : customColor.toString();

  useDayjsLocale();

  useEffect(() => {
    if (oldAppVerification) {
      localStorage.removeItem(OLD_LOCAL_STORAGE_VERIFICATION);
    }
  }, [oldAppVerification]);

  useEffect(() => {
    const disableTouchZoom = (event: TouchEvent) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    window.addEventListener('touchmove', disableTouchZoom, { passive: false });

    return () => {
      window.removeEventListener('touchmove', disableTouchZoom);
    };
  }, []);

  const { changeTranslations } = useAppTranslationsChange();
  useEffect(() => {
    if (auth) {
      if (slug) {
        // localStorage.removeItem(LOCAL_STORAGE_SLUG);
        // if (storeFanGroup) setFanGroup(undefined);
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_TARGET_APP);
        changeTranslations(
          SESSION_STORAGE_TARGET_APP,
          TranslationsTarget.COCKPIT
        );
      }
    }
  }, [slug, auth]);

  useEffect(() => {
    if (!ready) {
      i18n.init();
    }
  }, [ready]);

  useRefreshToken();

  useIdle();

  const isLoading =
    !ready ||
    (!!detectedSlug && customLightTheme.token.colorPrimary === primaryColor);

  useRemoveOldAppCookies();

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => <div>Error: {error.message}</div>}
    >
      <ConfigProvider
        csp={{
          nonce: `nonce-${Math.floor(Math.random() * Date.now()).toString(36)}`,
        }}
        theme={{
          token:
            mode === 'light' ? customLightTheme.token : customDarkTheme.token,
          components: {
            Rate: {
              colorFillContent: baseColor400,
            },
            Segmented: {
              colorBgLayout: mode === 'light' ? baseColor200 : layoutDarkColor,
            },
            Table: {
              headerBg: mode === 'light' ? baseColor200 : layoutDarkColor,
              headerBorderRadius: 0,
            },
            Tabs: {
              itemActiveColor: tabsColor,
              itemHoverColor: tabsColor,
              itemSelectedColor: tabsColor,
              inkBarColor: tabsColor,
            },
          },
        }}
      >
        <Loader show={isLoading} />
        <RouterProvider
          router={router(userData ? JSON.parse(userData)?.userData?.roles : [])}
        />
        <ReactQueryDevtools initialIsOpen={false} />
      </ConfigProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;

import {
  ListEntity,
  PaginationQuery,
  ticketingKeys,
  TicketingSystem,
  TicketPool,
  TicketPoolValidator,
} from '@seaters-app/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createTicketPool,
  createTicketingSystem,
  deleteTicketingSystem,
  fetchTicketingSystem,
  fetchTicketingSystems,
  fetchTicketPools,
  fetchTicketPool,
  editTicketPool,
} from '../api/ticketing';

// Ticketing systems
export const useFetchTicketingSystems = (
  params: PaginationQuery,
  enabled = true
) =>
  useQuery<ListEntity<TicketingSystem>, Error>(
    ticketingKeys.systemList(params),
    () => fetchTicketingSystems(params),
    {
      keepPreviousData: true,
      enabled: enabled,
    }
  );

export const useFetchTicketingSystem = (systemId: string) =>
  useQuery<TicketingSystem, Error>(
    ticketingKeys.detail(systemId),
    () => fetchTicketingSystem(systemId),
    {
      enabled: !!systemId,
    }
  );

export const useDeleteTicketingSystem = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ systemId }: { systemId: string }) => deleteTicketingSystem(systemId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ticketingKeys.all());
      },
    }
  );
};

// Ticket pools
export const useFetchTicketPools = (
  systemId: string,
  params: PaginationQuery
) =>
  useQuery<ListEntity<TicketPool>, Error>(
    ticketingKeys.ticketPoolList(systemId, params),
    () => fetchTicketPools(systemId, params),
    {
      enabled: !!systemId,
      keepPreviousData: true,
    }
  );
// Ticket pool
export const useFetchTicketPool = (ticketPoolId: string) =>
  useQuery<TicketPool, Error>(
    ticketingKeys.ticketPoolDetail(ticketPoolId),
    () => fetchTicketPool(ticketPoolId),
    {
      enabled: !!ticketPoolId,
    }
  );

export const useCreateTicketPool = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      systemId,
      ticketPool,
    }: {
      systemId: string;
      ticketPool: TicketPoolValidator;
    }) => createTicketPool(systemId, ticketPool),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ticketingKeys.all());
      },
    }
  );
};

export const useEditTicketPool = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      ticketPoolId,
      ticketPool,
    }: {
      ticketPoolId: string;
      ticketPool: TicketPoolValidator;
    }) => editTicketPool(ticketPoolId, ticketPool),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ticketingKeys.all());
      },
    }
  );
};

export const useCreateTicketingSystem = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (system: TicketingSystem) => createTicketingSystem(system),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ticketingKeys.all());
      },
    }
  );
};

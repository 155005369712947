import React from 'react';
import { FanGroupGeneralForm } from '../../fanGroupForm/FanGroupGeneralForm';
import { FanGroupGeneralFormType } from '@seaters-app/constants';

interface GeneralStepProps {
  values: FanGroupGeneralFormType;
  setValues: (val: FanGroupGeneralFormType) => void;
  isLoading: boolean;
  createFG: (values: FanGroupGeneralFormType) => Promise<void>;
}

export function GeneralStep({
  values,
  setValues,
  isLoading,
  createFG,
}: GeneralStepProps) {
  const onSubmit = (values: FanGroupGeneralFormType) => {
    createFG(values);
  };
  return (
    <FanGroupGeneralForm
      initialValues={values}
      onSubmit={onSubmit}
      isLoading={isLoading}
      setGeneralStepValues={setValues}
    />
  );
}

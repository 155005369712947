import {
  AxiosAPIError,
  SortedPaginationQueryV2,
  FanGroupCustomInformation,
  fanGroupCustomInfoKeys,
  ListEntityV2,
  CustomProfileScopeEnum,
} from '@seaters-app/constants';

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createFanGroupCustomInfo,
  fetchFanGroupCustomInfo,
  fetchHostFanGroupCustomInfo,
  FGCustomInfoBody,
  updateFanGroupCustomInfo,
} from '../api/customInfo';

export const useFetchFanGroupCustomInfo = (
  fanGroupId: string,
  params: SortedPaginationQueryV2
) =>
  useQuery<ListEntityV2<FanGroupCustomInformation>, Error>(
    fanGroupCustomInfoKeys.list(fanGroupId, params),
    () => fetchFanGroupCustomInfo(fanGroupId, params),
    {
      keepPreviousData: true,
      enabled: !!fanGroupId,
    }
  );

export const useFetchHostFanGroupCustomInfo = (fanGroupId: string) =>
  useQuery<FanGroupCustomInformation[], Error>(
    fanGroupCustomInfoKeys.host(fanGroupId),
    () => fetchHostFanGroupCustomInfo(fanGroupId)
  );

export const useCreateFanGroupCustomInfo = (
  fanGroupId: string
): UseMutationResult<
  FanGroupCustomInformation,
  AxiosAPIError<FanGroupCustomInformation>,
  FGCustomInfoBody,
  string
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: FGCustomInfoBody) => createFanGroupCustomInfo(fanGroupId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupCustomInfoKeys.all(fanGroupId));
      },
    }
  );
};

export const useUpdateFanGroupCustomInfo = (
  fanGroupId: string
): UseMutationResult<
  FanGroupCustomInformation,
  AxiosAPIError<FanGroupCustomInformation>,
  {
    fgCustomInfoId: string;
    scope: CustomProfileScopeEnum;
    mandatory: boolean;
  },
  string
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      fgCustomInfoId,
      scope,
      mandatory,
    }: {
      fgCustomInfoId: string;
      scope: CustomProfileScopeEnum;
      mandatory: boolean;
    }) =>
      updateFanGroupCustomInfo(fanGroupId, fgCustomInfoId, scope, mandatory),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(fanGroupCustomInfoKeys.all(fanGroupId));
      },
    }
  );
};

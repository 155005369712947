import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { GuestEntity } from '@seaters-app/constants';
import { GuestItem } from './guestlist-store';

type State = {
  guests: {
    [guestId: string]: GuestItem;
  };
};
interface GuestListState {
  guests: {
    [guestId: string]: GuestItem;
  };
  add: (guest: GuestEntity, tickets: number) => void;
  addAll: (guests: { [guestId: string]: GuestItem }) => void;
  setTickets: (guestId: string, tickets: number) => void;
  // addList: (guests: GuestEntity[]) => void;
  remove: (guestId: string) => void;
  reset: () => void;
}

const initialState: State = {
  guests: {},
};
export const useReserveGuestListStore = create<GuestListState>()(
  devtools((set) => ({
    ...initialState,
    add: (guest, tickets) =>
      set((state) => ({
        guests: { ...state.guests, [guest.guestId]: { tickets, guest } },
      })),
    addAll: (guests) =>
      set(() => ({
        guests,
      })),
    setTickets: (guestId, tickets) =>
      set((state) => ({
        guests: {
          ...state.guests,
          [guestId]: { ...state.guests[guestId], tickets },
        },
      })),
    remove: (guestId) =>
      set((state) => {
        const guestsCopy = state.guests;
        delete guestsCopy[guestId];

        return {
          guests: guestsCopy,
        };
      }),

    reset: () => {
      set(initialState);
    },
  }))
);

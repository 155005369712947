export const primaryColor = '#5AC6CC';
export const primaryColor900 = '#1C939E';
export const primaryColor200 = '#F0FFFD';
export const colorBgBaseDark = '#142E4D';
export const colorBgBaseLight = '#FFF';
export const colorTextBaseDark = '#FFF';
export const colorTextBaseLight = '#000';
export const layoutDarkColor = '#0F273F';
export const baseColor900 = '#222222';
export const baseColor800 = '#383838';
export const baseColor700 = '#777777';
export const baseColor600 = '#6F6F6F';
export const baseColor400 = '#D9D9D9';
export const baseColor300 = '#F0F0F0';
export const baseColor200 = '#F5F5F5';
export const baseColor100 = '#FFF';
export const errorColor = '#F44336';
export const errorColor400 = 'rgba(244,	67,	54, 0.3)';
export const errorColor200 = 'rgba(244,	67,	54, 0.1)';
export const alertColor = '#FFA000';
export const successColor = '#00C853';
export const successColor400 = 'rgba(0, 200, 83, 0.3)';
export const successColor200 = 'rgba(0, 200, 83, 0.1)';
export const tertiaryColor700 = '#1FAD87';

import {
  BillingMode,
  DisplayMode,
  PositionsDistributionMode,
  WaitingListStatusEnum,
  WLAccessModeEnum,
} from '@seaters-app/constants';
import { useTranslation } from 'react-i18next';

export type BillingModeOptionType = { label: string; value: BillingMode };

export const billingModeOptions: BillingModeOptionType[] = [
  {
    label: 'wl_set_pricing-free-title',
    value: BillingMode.FREE,
  },
  {
    label: 'wl_set_pricing-paid',
    value: BillingMode.FAIR_PRICE,
  },
];
export const usePositionDistributionOptions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('wl_positions_distribution_mode_fifs'),
      value: PositionsDistributionMode.FIFS,
    },
    {
      label: t('wl_positions_distribution_mode_random'),
      value: PositionsDistributionMode.RANDOM,
    },
    {
      label: t('wl_positions_distribution_mode_with_parking'),
      value: PositionsDistributionMode.WITH_PARKING,
    },
  ];
};

export const useAccessModeOptions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('wl_access_mode_public'),
      value: WLAccessModeEnum.PUBLIC,
    },
    {
      label: t('wl_access_mode_code_protected'),
      value: WLAccessModeEnum.CODE_PROTECTED,
    },
  ];
};

export const timeUnitOptions = {
  days: {
    value: 1000 * 60 * 60 * 24,
    name: 'general_duration-day',
  },
  hours: {
    value: 1000 * 60 * 60,
    name: 'general_duration-hrs',
  },
  minutes: {
    value: 1000 * 60,
    name: 'general_duration-min',
  },
};

export const useDisplayModeOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t('display_mode_classic'), value: DisplayMode.CLASSIC },
    { label: t('display_mode_contest'), value: DisplayMode.CONTEST },
    { label: t('display_mode_detailed'), value: DisplayMode.DETAILED },

    { label: t('display_mode_informative'), value: DisplayMode.INFORMATIVE },
    { label: t('display_mode_merchandise'), value: DisplayMode.MERCHANDISE },
  ];
};

export const SCHEDULED = 'SCHEDULED';

export const publishWLStatusOptions = [
  {
    label: 'wl_takeoff_open-now',
    value: WaitingListStatusEnum.OPEN,
    text: 'wl_takeoff_open-now-info',
  },

  {
    label: 'wl_takeoff_schedule',
    value: SCHEDULED,
    text: 'wl_takeoff_schedule-info',
  },

  {
    label: 'wl_takeoff_publish',
    value: WaitingListStatusEnum.PUBLISHED,
    text: 'wl_takeoff_publish-info',
  },
];

// TODO rethink WL status implementation (edit)
export const WLStatusOptions = [
  {
    label: 'Closed',
    value: WaitingListStatusEnum.CLOSED,
    text: 'Your Wish list will be closed',
  },

  {
    label: 'Open now',
    value: WaitingListStatusEnum.OPEN,
    text: 'Your Wish List will be opened to fans immediately',
  },

  {
    label: 'Schedule opening',
    value: SCHEDULED,
    text: 'Your Wish List will automatically be opened on the desired date and time',
  },

  {
    label: 'Publish as “coming soon”',
    value: WaitingListStatusEnum.PUBLISHED,
    text: 'Your Wish List will be visible but not yet opened to the fans.',
  },
];

import {
  LookEntity,
  privacyPolicyUrl,
  userAgreementUrl,
} from '@seaters-app/constants';
import {
  getSlugFromUrl,
  queryClient,
  useCookiesStore,
} from '@seaters-app/data-access';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTranslatedProperty } from '../../../public/helpers';
import { StyledText } from '@seaters-app/ui-shared';
import { useState } from 'react';
import { isWebView } from 'apps/seaters/src/utils';

export const TermsAndConditionsModal = ({
  showAcceptTermsAndConditions,
}: {
  showAcceptTermsAndConditions: boolean;
}) => {
  const { t } = useTranslation();
  const { cookies } = useCookiesStore();
  const webView = isWebView();
  const slug = getSlugFromUrl();
  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const termsAndCondsAcceptedData = document.cookie.includes(
    `termsAndConditions-${slug}=true`
  );

  const [termsAndCondsAccepted, setTermsAndCondsAccepted] = useState(
    termsAndCondsAcceptedData
  );

  const termsAndConditionsText = useTranslatedProperty(
    fanGroupData?.optInTermsTexts
  );

  const fgTermsAndConditionsUrl = useTranslatedProperty(
    fanGroupData?.termsAndConditionsUrls
  );

  const fgPrivacyPolicyUrl = useTranslatedProperty(
    fanGroupData?.privacyPolicyUrls
  );

  const handleAccept = () => {
    setTermsAndCondsAccepted(true);
    document.cookie = `termsAndConditions-${slug}=true; max-age=31536000; path=/`;
  };

  return (
    <Modal
      centered
      open={showAcceptTermsAndConditions && cookies && !termsAndCondsAccepted} // do not show modal if cookies are not accepted
      closeIcon={null}
      title={t('waitinglist_terms_and_conditions')}
      footer={
        <Button type="primary" onClick={handleAccept}>
          {t('cookies_accept_modal-accept')}
        </Button>
      }
      className="termsAndConds"
    >
      {termsAndConditionsText ? (
        <StyledText text={termsAndConditionsText} />
      ) : (
        <>
          {t('signup_text_terms')}
          <a
            href={fgTermsAndConditionsUrl ?? userAgreementUrl}
            target="_blank"
            rel="noreferrer"
          >
            {t('terms_amper_conditions')}
          </a>{' '}
          {t('and_text')}{' '}
          <a
            href={fgPrivacyPolicyUrl ?? privacyPolicyUrl}
            target="_blank"
            rel="noreferrer"
          >
            {t('privacy_policy')}
          </a>
        </>
      )}
    </Modal>
  );
};

import {
  Avatar,
  Checkbox,
  Col,
  Form,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useWLOptions } from '../../surveys/[id]/hooks/useWLOptions';
import {
  fetchWishListBadges,
  getSingleTranslation,
  useAddBadgeToWishList,
  useDeleteBadgeFromWishList,
  useFanGroupStore,
  useScheduleBadgeActivation,
  useScheduleBadgeArchiving,
} from '@seaters-app/data-access';
import { useFGBadges } from '../../fan-groups/[id]/badges/useFGBadges';
import { Button, DatePicker } from '@seaters-app/ui';
import dayjs from 'dayjs';
import { BadgeInstance, BadgeStatusEnum } from '@seaters-app/constants';

const { Text } = Typography;

function ManageWLBadgesModal({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { fanGroup } = useFanGroupStore();
  const { waitingListsOptions, loadMoreData: loadMoreWLs } = useWLOptions(
    fanGroup?.id ?? ''
  );

  const handleScrollWLs = (e: any) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      loadMoreWLs();
    }
  };

  const { mutate: scheduleBadgeActivation, isLoading: isActivationScheduling } =
    useScheduleBadgeActivation();
  const { mutate: scheduleBadgeArchiving, isLoading: isAcrchivingScheduling } =
    useScheduleBadgeArchiving();

  const { badges: fanGroupBadges } = useFGBadges();
  const { mutate: addBadge } = useAddBadgeToWishList();
  const { mutate: deleteBadge } = useDeleteBadgeFromWishList();

  const badgesOptions = fanGroupBadges?.map((badge) => ({
    label: getSingleTranslation(badge.name),
    value: badge.id,
    avatar: badge.displayedLogoUrl,
  }));

  const handleClose = () => {
    closeModal();
    form.resetFields();
  };

  const onFinish = (values: {
    wlIds: string[];
    badgeId: string;
    action: 'activate' | 'archive';
    attribute: Array<'JOINABLE' | 'VISIBLE'>;
    date: string;
  }) => {
    const body = {
      date: dayjs(values.date).toISOString(),
    };

    values.wlIds.forEach(async (wlId) => {
      const response = await fetchWishListBadges(wlId, {
        page: 1,
        size: 20,
      });
      if (values.action === 'archive') {
        const selectedBadges = response.content.filter(
          (badge: BadgeInstance) => {
            return (
              badge.badge.id === values.badgeId &&
              values.attribute.includes(badge.attribute)
            );
          }
        );

        selectedBadges.forEach((badge) => {
          scheduleBadgeArchiving(
            {
              wishListId: wlId,
              badgeId: badge.id,
              body,
            },
            {
              onSuccess: (res) => {
                notification.success({
                  message: t('notification_success_schedule_badge_archiving'),
                });
              },
              onError: (error) => {
                notification.error({
                  message: t('notification_error_schedule_badge_archiving'),
                });
              },
            }
          );
        });
      } else {
        const badge = response.content.find((badge: BadgeInstance) => {
          return badge.badge.id === values.badgeId;
        });

        if (badge) {
          scheduleBadgeActivation(
            {
              wishListId: wlId,
              badgeId: badge.id,
              body,
            },
            {
              onSuccess: (res) => {
                notification.success({
                  message: t('notification_success_schedule_badge_activation'),
                });
              },
              onError: (error) => {
                notification.error({
                  message: t('notification_error_schedule_badge_activation'),
                });
              },
            }
          );
        } else {
          values.attribute.forEach((attribute) => {
            addBadge(
              {
                wishListId: wlId,
                body: {
                  badgeAttribute: attribute,
                  badgeId: values.badgeId,
                  status: BadgeStatusEnum.ACTIVE,
                },
              },
              {
                onSuccess: async (res) => {
                  await deleteBadge(
                    { wishListId: wlId, badgeId: res.id },
                    {
                      onSuccess: (res) => {
                        scheduleBadgeActivation(
                          {
                            wishListId: wlId,
                            badgeId: res.id,
                            body,
                          },
                          {
                            onSuccess: (res) => {
                              notification.success({
                                message: t(
                                  'notification_success_schedule_badge_activation'
                                ),
                              });
                            },
                            onError: (error) => {
                              notification.error({
                                message: t(
                                  'notification_error_schedule_badge_activation'
                                ),
                              });
                            },
                          }
                        );
                      },
                    }
                  );
                },
              }
            );
          });
        }
      }
    });
    handleClose();
  };

  return (
    <Modal
      width={800}
      title={t('manage_wl_badges_title')}
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        size="middle"
        initialValues={{
          wlIds: [],
          badgeId: '',
          date: '',
          action: 'activate',
          attribute: [],
        }}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              label={<Text strong>{t('mwl_tab-wl')}</Text>}
              name="wlIds"
            >
              <Select
                allowClear
                mode="multiple"
                showSearch
                optionFilterProp="label"
                options={waitingListsOptions}
                onPopupScroll={handleScrollWLs}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              label={<Text strong>{t('general_actions_text')}</Text>}
              name="action"
            >
              <Radio.Group>
                <Radio value={'activate'}>{t('activate_option_label')}</Radio>
                <Radio value={'archive'}>{t('comm_list_archive-btn')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              label={<Text strong>{t('badge_title')}</Text>}
              name="badgeId"
            >
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
                size="large"
                placeholder={t('search_badge_placeholder')}
                options={badgesOptions}
                optionRender={(option) => (
                  <Space>
                    <Avatar src={option?.data.avatar} size="small" />
                    {option?.data.label}
                  </Space>
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              label={<Text strong>{t('attribute_title')}</Text>}
              name="attribute"
            >
              <Checkbox.Group>
                <Checkbox value={'JOINABLE'}>{t('badge_joinable')}</Checkbox>
                <Checkbox value={'VISIBLE'}>
                  {t('badge_attribute_visible')}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              label={<Text strong>{t('matrix_header_date')}</Text>}
              name="date"
            >
              <DatePicker
                format="DD/MM/YYYY HH:mm"
                style={{ width: '100%' }}
                showTime={{
                  defaultValue: dayjs('00:00', 'HH:mm'),
                  format: 'HH:mm',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button
          style={{ marginRight: 0, marginLeft: 'auto' }}
          size="large"
          type="primary"
          htmlType="submit"
          loading={isActivationScheduling || isAcrchivingScheduling}
        >
          {t('general_save-btn')}
        </Button>
      </Form>
    </Modal>
  );
}

export default ManageWLBadgesModal;

import { zodResolver } from '@hookform/resolvers/zod';
import {
  TicketingSystem,
  TicketingSystemValidatorSchema,
  VeritixConfiguration,
} from '@seaters-app/constants';
import { useCreateTicketingSystem } from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import { useIsMutating } from '@tanstack/react-query';
import {
  Drawer,
  Space,
  Form,
  Row,
  Col,
  Input,
  Select,
  DrawerProps,
  Typography,
  Flex,
  Checkbox,
} from 'antd';
import React from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

type CreateDrawerProps = DrawerProps;
interface VeritixForm extends VeritixConfiguration {
  name: string;
  type: 'VERITIX';
}

interface UploadForm {
  name: string;
  type: 'UPLOAD';
}

type TicketingSystemForm = VeritixForm | UploadForm;

function isVeritix(form: TicketingSystemForm): form is VeritixForm {
  return form.type === 'VERITIX';
}

const types: TicketingSystem['type'][] = ['VERITIX', 'UPLOAD'];

const options = types.map((type) => ({ label: type, value: type }));

const VeritixForm: React.FC = () => {
  const { control } = useFormContext<VeritixForm>();
  const { t } = useTranslation();

  return (
    <Flex vertical gap="middle">
      <Text strong>Veritix</Text>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="veritixConfiguration.login"
            label={t('admin_users_login')}
            rules={[{ required: true }]}
          >
            <Controller
              control={control}
              name={'veritixConfiguration.login'}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="veritixConfiguration.password"
            label={'admin_user_password'}
            rules={[{ required: true }]}
          >
            <Controller
              control={control}
              name={'veritixConfiguration.password'}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="bottom">
        <Col span={12}>
          <Form.Item
            name="veritixConfiguration.endpointURL"
            label={'endpoint_url_label'}
            rules={[{ required: true }]}
          >
            <Controller
              control={control}
              name={'veritixConfiguration.endpointURL'}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="veritixConfiguration.directSettlementPaymentTypeId"
            label={t('direct_settlement_payment_type_id_label')}
            rules={[{ required: true }]}
          >
            <Controller
              control={control}
              name={'veritixConfiguration.directSettlementPaymentTypeId'}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item name="veritixConfiguration.usingMock">
            <Controller
              control={control}
              name={'veritixConfiguration.usingMock'}
              render={({ field }) => (
                <Checkbox {...field}>{t('using_mock_checkbox_label')}</Checkbox>
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    </Flex>
  );
};

const SubForm: React.FC<{ form: TicketingSystemForm }> = ({ form }) => {
  if (isVeritix(form)) {
    return <VeritixForm />;
  }
  return null;
};

const CreateDrawer: React.FC<CreateDrawerProps> = ({ onClose, ...props }) => {
  const { t } = useTranslation();
  const { mutate: createTicketingSystem } = useCreateTicketingSystem();

  const isMutating = !!useIsMutating();

  const navigate = useNavigate();

  const methods = useForm<TicketingSystemForm>({
    mode: 'onBlur',
    resolver: zodResolver(TicketingSystemValidatorSchema),
    defaultValues: {
      type: 'UPLOAD',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = (data: TicketingSystemForm) => {
    createTicketingSystem(data as TicketingSystem, {
      onSuccess: (response) => {
        navigate(response.id);
      },
    });
  };

  const isSubmittingForm = isSubmitting || isMutating;

  return (
    <Drawer
      title={t('admin_ticketing_system_create')}
      width={720}
      onClose={onClose}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={onClose} disabled={isSubmittingForm}>
            {t('general_cancel')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            type="primary"
            loading={isSubmittingForm}
          >
            {t('general_submit')}
          </Button>
        </Space>
      }
      {...props}
    >
      <FormProvider {...methods}>
        <Form
          name="basic"
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          disabled={isSubmittingForm}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t('general_name')}
                help={errors.name?.message}
                validateStatus={errors.name?.message ? 'error' : undefined}
              >
                <Controller
                  control={control}
                  name={'name'}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label={t('general_type')}
                rules={[{ required: true }]}
              >
                <Controller
                  control={control}
                  name={'type'}
                  render={({ field }) => (
                    <Select options={options} {...field} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <SubForm form={methods.watch()} />
        </Form>
      </FormProvider>
    </Drawer>
  );
};

export default CreateDrawer;

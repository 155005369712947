import React from 'react';
import {
  Typography,
  notification,
  Popconfirm,
  Skeleton,
  Tabs,
  TabsProps,
  theme,
  Flex,
  Breadcrumb,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './fanGroupLayout.module.css';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '@seaters-app/constants';
import {
  getSingleTranslation,
  useDeleteFanGroup,
  useFetchAdminFanGroup,
} from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';

const { Title } = Typography;

export function FanGroupLayout() {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { fanGroupId = '' } = useParams();
  const { data: fanGroup } = useFetchAdminFanGroup(fanGroupId);

  const { mutateAsync: deleteFanGroup } = useDeleteFanGroup();
  const onChange = (key: string) => {
    navigate(key);
  };

  const items: TabsProps['items'] = [
    {
      key: routes.general,
      label: t('admin_tabs_general'),
    },
    {
      key: routes.personalization,
      label: t('wl_personalization_tab'),
    },
    {
      key: routes.access,
      label: t('fgc_tab-access'),
    },
    {
      key: routes.badges,
      label: t('admin_badges'),
    },
    { key: routes.filterConfig, label: t('admin_tabs_filter_config') },
    {
      key: routes.customInfo,
      label: t('custom_profile_tab_title'),
    },
    {
      key: routes.notifications,
      label: t('admin_notifications'),
    },
  ];
  // Handlers

  const handleDeleteFanGroup = (fanGroupId: string) =>
    deleteFanGroup(
      { fanGroupId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_fg_removed'),
          });
          navigate('..');
        },
        onError: () => {
          notification.error({
            message: t('notification_error_fg_removed'),
          });
        },
      }
    );
  const activeTab = items.find((item) => pathname.includes(item.key));

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="..">{t('admin_user_tab-groups')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('admin_tabs_details')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t(activeTab?.label)}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.subheader}>
          <Title level={3}>
            {getSingleTranslation(fanGroup?.name || []) ||
              t('fg_details_title')}
          </Title>
          {fanGroup?.id && (
            <Popconfirm
              title={t('delete_fg_confirm_label')}
              description={t('delete_fg_confirm_description')}
              cancelText={t('general_no')}
              onConfirm={() => handleDeleteFanGroup(fanGroupId)}
              onOpenChange={() => console.log('open change')}
            >
              <Button
                size="large"
                type="text"
                icon={<DeleteOutlined rev={undefined} />}
                color={token.colorError}
              >
                {t('remove_fg_button_text')}
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
      <Tabs
        defaultActiveKey={activeTab?.key}
        items={items}
        onChange={onChange}
      />
      {fanGroup ? <Outlet /> : <Skeleton />}
    </Flex>
  );
}

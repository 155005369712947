import {
  Answer,
  FanAnswers,
  ListEntityV2,
  SeatEntity,
  SurveyExtensionPoint,
  SurveyInstanceEntity,
  WLPositionEntity,
} from '@seaters-app/constants';
import {
  getSingleTranslation,
  useFetchSurveysFansAnswers,
} from '@seaters-app/data-access';
import { EmptyState } from '@seaters-app/ui';
import { StyledText } from '@seaters-app/ui-shared';
import { Descriptions, Flex, Tabs, TabsProps } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { useParams } from 'react-router-dom';

interface PanelProps {
  surveyInstance: SurveyInstanceEntity;
  rowData: SeatEntity | WLPositionEntity;
}

const Panel: React.FC<PanelProps> = ({ surveyInstance, rowData }) => {
  const { wishListId = '' } = useParams();
  const { data: answers } = useFetchSurveysFansAnswers(
    wishListId,
    surveyInstance.id,
    { page: 0, size: 10, user_id: rowData.fanId }
  );

  const getQuestionAnswer = (questionId: string): FanAnswers | undefined => {
    if (!answers?.content) {
      return undefined;
    } else
      return answers?.content.find(
        (answerEntity: FanAnswers) => answerEntity.questionId === questionId
      );
  };

  const getAnswerText = (answer: Answer, index: number): string => {
    if (answer.structuredAnswer && answer.structuredAnswer.length > 0) {
      return (
        'Attendee ' +
        (index + 1) +
        ': ' +
        answer.structuredAnswer
          .map(function (label) {
            return label.value;
          })
          .join(' - ')
      );
    }
    return answer.answerText ?? answer.translatedOfferedAnswer;
  };

  return surveyInstance?.survey.title?.length ? (
    <Flex>
      <Descriptions
        bordered
        size="small"
        title={t('survey_creation_questions_step')}
        items={surveyInstance?.survey.surveyQuestions.map(
          ({ question }, index) => {
            const answerEntity = getQuestionAnswer(question.id);

            return {
              key: question.id,
              label: (
                <StyledText
                  text={`${index + 1}. ${getSingleTranslation(
                    question.answerLabel
                  )}`}
                />
              ),
              children: answerEntity?.answers
                .map((answer, index) => getAnswerText(answer, index))
                .join(' | '),
            };
          }
        )}
      />
    </Flex>
  ) : (
    <EmptyState />
  );
};

interface ExpandedRowProps {
  rowData: SeatEntity | WLPositionEntity;
  surveys: ListEntityV2<SurveyInstanceEntity>;
  extensionPoint?: SurveyExtensionPoint;
}

const ExpandedRow: React.FC<ExpandedRowProps> = ({
  rowData,
  surveys,
  extensionPoint,
}) => {
  const beforeJoinSurvey = surveys.content?.find(
    (survey) =>
      survey.extensionPoint === SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST
  );

  const atCheckoutSurvey = surveys.content?.find(
    (survey) => survey.extensionPoint === SurveyExtensionPoint.AT_CHECKOUT
  );

  const afterEventSurvey = surveys.content?.find(
    (survey) => survey.extensionPoint === SurveyExtensionPoint.AFTER_EVENT
  );

  const beforePaymentSurvey = surveys.content?.find(
    (survey) => survey.extensionPoint === SurveyExtensionPoint.BEFORE_PAYMENT
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('wl_survey_before_join'),
      children: beforeJoinSurvey ? (
        <Panel surveyInstance={beforeJoinSurvey} rowData={rowData} />
      ) : null,
    },
    {
      key: '2',
      label: t('survey_management_extension_point_at_checkout'),
      children: atCheckoutSurvey ? (
        <Panel surveyInstance={atCheckoutSurvey} rowData={rowData} />
      ) : null,
    },
    {
      key: '3',
      label: t('wl_survey_before_pay'),
      children: beforePaymentSurvey ? (
        <Panel surveyInstance={beforePaymentSurvey} rowData={rowData} />
      ) : null,
    },
    {
      key: '4',
      label: t('wl_survey_after_event'),
      children: afterEventSurvey ? (
        <Panel surveyInstance={afterEventSurvey} rowData={rowData} />
      ) : null,
    },
  ];
  const wlTabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('wl_survey_before_join'),
      children: beforeJoinSurvey ? (
        <Panel surveyInstance={beforeJoinSurvey} rowData={rowData} />
      ) : null,
    },
  ];

  return (
    <Tabs
      centered
      defaultActiveKey="1"
      items={
        extensionPoint === SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST
          ? wlTabItems
          : items
      }
      size="small"
      style={{ width: '100%' }}
    />
  );
};

export default ExpandedRow;

import { Breakpoint } from 'antd';

export const calculateVisibleItems = (
  screens: Partial<Record<Breakpoint, boolean>>
): number => {
  if (screens.xxl) {
    return 14;
  } else if (screens.xl) {
    return 14;
  } else if (screens.lg) {
    return 12;
  } else if (screens.md) {
    return 9;
  } else if (screens.sm) {
    return 6;
  } else {
    return 4;
  }
};

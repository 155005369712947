import { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, CollapseButton, Container } from '@seaters-app/ui';
import {
  Alert,
  Collapse,
  ConfigProvider,
  Divider,
  Form,
  Grid,
  InputNumber,
  Modal,
  Space,
  Table,
  Typography,
  notification,
  theme,
} from 'antd';
import {
  getSingleTranslation,
  useApproveInvitationsToApprove,
  useCreateWaitingListsApproverRequest,
  useFetchWaitingListWithApproval,
  useFetchWaitingListsApproverRequest,
  useFetchWaitingListsInvitationsToApprove,
  useRejectInvitationsToApprove,
  useUpdateWaitingListsApproverRequest,
  useUpdateWaitingListsInvitationsToApprove,
} from '@seaters-app/data-access';
import AntLayout from 'antd/es/layout';
import { LeftOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import {
  ApproverRequestsStatus,
  InvitationForApprove,
  InvitationForApproverEntity,
} from '@seaters-app/constants';
import { Link, useParams } from 'react-router-dom';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';
import { requestStatusToBadge } from '../../approvals/[id]/components/InvitationsTable';
import { WishListSummary } from '@seaters-app/ui-shared';
import { useWishListSummaryData } from '../hooks/useWishListSummaryData';
import TextArea from 'antd/es/input/TextArea';
const { Title, Text } = Typography;
const { Footer: AntFooter } = AntLayout;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

type HostsTickets = {
  [id: string]: number;
};

function isInViewport(element: HTMLElement | null) {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0;
}

export function Request() {
  const footerApp = document.getElementById('appFooter');
  const isVisible = isInViewport(footerApp);

  const rect = footerApp?.getBoundingClientRect();
  const footerPosition = window.innerHeight - rect?.bottom + 176;

  // const footerPosition = getFooterPosition();
  const [fPos, setFPos] = useState(footerPosition);
  const { waitingListId = '' } = useParams();
  const { token } = theme.useToken();
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const { data: waitingListWithApproval } = useFetchWaitingListWithApproval(
    waitingListId,
    { toallocate: true }
  );
  const { data: waitingListApproverRequest } =
    useFetchWaitingListsApproverRequest(
      waitingListId,
      !!waitingListWithApproval &&
        !!waitingListWithApproval?.totalRequestedToFgo
    );

  const [requestReasonModal, setRequestReasonModal] = useState(false);

  const [form] = Form.useForm();

  const requestReason = Form.useWatch(['requestReason'], form);

  const showRequestReasonModal = () => {
    setRequestReasonModal(true);
  };

  const hideRequestReasonModal = () => {
    setRequestReasonModal(false);
  };

  const [tickets, setTickets] = useState<HostsTickets>({});
  const [totalNonAllocated, setTotalNonAllocated] = useState<number>(0);
  const { getData } = useWishListSummaryData();
  const { mutate: updateInvitation } =
    useUpdateWaitingListsInvitationsToApprove({
      waitingListId: waitingListWithApproval?.waitinglist?.waitingListId,
    });

  const { mutate: createRequest, isLoading: isRequestCreating } =
    useCreateWaitingListsApproverRequest(waitingListId);

  const { mutate: updateRequest, isLoading: isRequestUpdating } =
    useUpdateWaitingListsApproverRequest(
      waitingListId,
      waitingListApproverRequest?.id ?? ''
    );

  const { mutateAsync: distributeNonAllocated } =
    useApproveInvitationsToApprove(waitingListId, {
      toallocate: true,
    });

  const { mutateAsync: rejectApproverRequest } =
    useRejectInvitationsToApprove();

  const { data: invitations } = useFetchWaitingListsInvitationsToApprove(
    waitingListId,
    { toallocate: true }
  );

  useEffect(() => {
    if (invitations?.content) {
      const newVal = invitations?.content.reduce((acc: HostsTickets, curr) => {
        acc[curr.host.hostId] =
          curr?.status !== InvitationForApprove.REJECTED &&
          curr?.status !== InvitationForApprove.CANCELLED
            ? curr.numberOfNonAllocatedSeats
            : 0;
        return acc;
      }, {});
      setTickets(newVal);
      const newTotalOfNonAllocated = invitations?.content.reduce(
        (acc: number, curr) => {
          if (curr.status === InvitationForApprove.APPROVED) {
            acc += curr.numberOfNonAllocatedSeats;
          }

          return acc;
        },
        0
      );
      setTotalNonAllocated(newTotalOfNonAllocated);
    }
  }, [invitations?.content.length]);

  useEffect(() => {
    setFPos(footerPosition);
  }, [footerPosition, isVisible]);

  useEffect(() => {
    setTotalNonAllocated(waitingListWithApproval?.totalAllocatedSeats ?? 0);
  }, [waitingListWithApproval?.totalAllocatedSeats]);

  const seatsToRequest = useMemo(() => {
    const total = Object.values(tickets).reduce((acc, curr) => {
      acc = acc + curr;
      return acc;
    }, 0);
    return total;
  }, [tickets]);

  const [disabledAllocateButton, setDisabledAllocateButton] = useState(false);

  const updateHostInvitation = (id: string, value: number) => {
    updateInvitation(
      {
        id,
        body: {
          numberOfToAllocateSeats: value,
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('approver_invitation_toallocate_seats_update_success'),
          });
        },
        onError: () => {
          notification.error({
            message: t('approver_invitation_toallocate_seats_update_error'),
          });
        },
      }
    );
  };

  const createApproverRequest = () => {
    createRequest(
      {
        nbrOfSeats: seatsToRequest,
        requestReason: requestReason,
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('approver_request_create_success'),
          });
          hideRequestReasonModal();
        },
        onError: () => {
          notification.error({
            message: t('approver_request_create_error'),
          });
        },
      }
    );
  };

  const updateApproverRequest = () => {
    updateRequest(
      {
        body: { nbrOfSeats: seatsToRequest, requestReason: requestReason },
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('approver_request_update_success'),
          });
          hideRequestReasonModal();
        },
        onError: () => {
          notification.error({
            message: t('approver_request_update_error'),
          });
        },
      }
    );
  };

  const handleRequest = () => {
    !waitingListApproverRequest
      ? createApproverRequest()
      : updateApproverRequest();
  };

  const distribute = () => {
    if (invitations) {
      const pendingInvitations = [...invitations.content].filter(
        (inv) => inv.status === InvitationForApprove.PENDING
      );

      const approvedInvitations = [...invitations.content].filter(
        (inv) => inv.status === InvitationForApprove.APPROVED
      );
      pendingInvitations.forEach((invitation: InvitationForApproverEntity) => {
        distributeNonAllocated({ invitationId: invitation?.id });
      });

      approvedInvitations.forEach((invitation: InvitationForApproverEntity) => {
        updateHostInvitation(invitation?.id, tickets[invitation?.host?.hostId]);
      });

      const newTotalOfNonAllocated = invitations?.content.reduce(
        (acc: number, curr) => {
          acc += curr.numberOfNonAllocatedSeats;
          return acc;
        },
        0
      );

      setTotalNonAllocated(newTotalOfNonAllocated);
    }
  };
  const isNotEnoughSeatsToAllocateToHost =
    waitingListApproverRequest?.status === ApproverRequestsStatus.APPROVED &&
    seatsToRequest >
      totalNonAllocated + (waitingListWithApproval?.totalAvailableSeats ?? 0);

  const isRequestRejectedByFGO =
    waitingListApproverRequest?.status === ApproverRequestsStatus.REJECTED;

  const isRequestButtonDisabled =
    waitingListApproverRequest &&
    (waitingListApproverRequest.status !== ApproverRequestsStatus.PENDING ||
      waitingListApproverRequest.nbrOfSeats === seatsToRequest);

  const isAllocateButtonDisabled =
    disabledAllocateButton ||
    !!(
      waitingListWithApproval?.totalAvailableSeats &&
      seatsToRequest >
        waitingListWithApproval?.totalAvailableSeats + totalNonAllocated
    );

  useEffect(() => {
    if (!waitingListApproverRequest) {
      setDisabledAllocateButton(true);
    } else if (
      waitingListApproverRequest?.status !== ApproverRequestsStatus.APPROVED
    ) {
      setDisabledAllocateButton(true);
    } else {
      setDisabledAllocateButton(false);
    }
  }, [invitations, waitingListApproverRequest?.status]);

  if (!waitingListWithApproval?.waitinglist) {
    return null;
  }

  return (
    <div className={styles.request}>
      <Container>
        <Space style={{ width: '100%' }} direction="vertical" size={12}>
          <ConfigProvider
            theme={{
              token: {
                colorLink: token.colorPrimary,
                colorLinkHover: token.colorPrimary,
                colorLinkActive: token.colorPrimary,
              },
            }}
          >
            <Link to="..">
              <Button
                type="link"
                padding={0}
                icon={<LeftOutlined rev={undefined} />}
              >
                {t('waitinglist_back_to_list')}
              </Button>
            </Link>
          </ConfigProvider>

          {!screens.xs && (
            <Title level={3}>
              {getSingleTranslation(
                waitingListWithApproval?.waitinglist.eventName,
                lang
              )}
            </Title>
          )}
          <WishListSummary data={getData(waitingListWithApproval)} isDetails />
          {isNotEnoughSeatsToAllocateToHost && (
            <Space style={{ color: token.colorError }}>
              {t('not_enough_seats_to_allocate_to_hosts_warning', {
                seatsToRequest: seatsToRequest,
                availableSeats:
                  waitingListWithApproval?.totalAvailableSeats +
                  totalNonAllocated,
              })}
            </Space>
          )}

          {isRequestRejectedByFGO && (
            <Space style={{ color: token.colorError }}>
              {t('rejected_invitations_warning')}
            </Space>
          )}

          <Collapse
            size="large"
            expandIcon={({ isActive }) => {
              return <CollapseButton isActive={isActive} />;
            }}
            expandIconPosition="end"
            defaultActiveKey={['1']}
          >
            <Panel
              header={`${t('general_host_plural')} (${
                invitations?.content?.length
              })`}
              key="1"
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                <Table
                  size="small"
                  dataSource={invitations?.content}
                  pagination={false}
                  scroll={{
                    x: true,
                  }}
                >
                  <Column
                    title={t('general_invitation_status')}
                    dataIndex="status"
                    key="status"
                    render={(status: ApproverRequestsStatus) => {
                      return (
                        <Alert
                          style={{
                            width: 'fit-content',
                            padding: 5,
                            fontSize: 12,
                          }}
                          message={status}
                          type={requestStatusToBadge[status]}
                        />
                      );
                    }}
                  />
                  <Column
                    title={t('guestlist_name')}
                    dataIndex="host"
                    key="host"
                    render={(host) => {
                      return (
                        <span>
                          {host?.firstName} {host?.lastName}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title={t('general_seats')}
                    dataIndex="originalNumberOfNonAllocatedSeats"
                    key="originalNumberOfNonAllocatedSeats"
                    render={(
                      originalNumberOfNonAllocatedSeats: number,
                      invitation: InvitationForApproverEntity
                    ) => {
                      const alreadyAllocated =
                        invitation.numberOfNonAllocatedSeats;

                      const isError =
                        invitation.status === InvitationForApprove.APPROVED &&
                        seatsToRequest >
                          waitingListWithApproval?.totalAvailableSeats +
                            totalNonAllocated;

                      const minValue =
                        invitation?.status === InvitationForApprove.APPROVED
                          ? alreadyAllocated
                          : 0;

                      const maxValue =
                        waitingListApproverRequest?.status !==
                        ApproverRequestsStatus.APPROVED
                          ? originalNumberOfNonAllocatedSeats ??
                            invitation?.numberOfNonAllocatedSeats
                          : waitingListApproverRequest?.nbrOfSeats;

                      const isDisabled =
                        invitation?.status === InvitationForApprove.REJECTED;

                      return (
                        <Space>
                          <InputNumber
                            status={isError ? 'error' : ''}
                            min={minValue}
                            max={maxValue}
                            disabled={isDisabled}
                            defaultValue={invitation?.numberOfNonAllocatedSeats}
                            onChange={(value) => {
                              const newTickets = { ...tickets };

                              newTickets[invitation?.host?.hostId] =
                                Number(value);
                              setTickets(newTickets);
                            }}
                            onBlur={() => {
                              if (
                                invitation.status ===
                                InvitationForApprove.PENDING
                              ) {
                                updateHostInvitation(
                                  invitation?.id,
                                  tickets[invitation?.host?.hostId]
                                );
                              }
                            }}
                            value={tickets[invitation?.host?.hostId]}
                          />
                          {'/'}
                          <span>{originalNumberOfNonAllocatedSeats}</span>
                        </Space>
                      );
                    }}
                  />
                  <Column
                    title={t('general_already_allocated_seats')}
                    dataIndex="host"
                    key="host"
                    render={(host) => {
                      return (
                        <span>
                          {invitations?.content.filter(
                            (invitation) =>
                              invitation.host.hostId === host.hostId &&
                              invitation.status ===
                                InvitationForApprove.APPROVED
                          )[0]?.numberOfNonAllocatedSeats ?? 0}
                        </span>
                      );
                    }}
                  />
                  <Column
                    key="actions"
                    align="right"
                    render={(invitation: InvitationForApproverEntity) => {
                      return (
                        <Button
                          disabled={
                            !(
                              invitation.status === InvitationForApprove.PENDING
                            )
                          }
                          danger
                          icon={<CloseOutlined rev={undefined} />}
                          type="link"
                          onClick={() => {
                            rejectApproverRequest({
                              invitationId: invitation.id,
                              body: {
                                rejectionReason: '/',
                              },
                            });
                          }}
                        >
                          {t('general_reject')}
                        </Button>
                      );
                    }}
                  />
                </Table>
              </Space>
            </Panel>
          </Collapse>
          {waitingListApproverRequest?.requestReason && (
            <>
              <Text strong>
                <EditOutlined
                  onClick={showRequestReasonModal}
                  rev={undefined}
                />{' '}
                {t('request_reason')}:
              </Text>
              <Text> {waitingListApproverRequest?.requestReason}</Text>
            </>
          )}
          {ReactDOM.createPortal(
            <AntFooter
              id="controlFooter"
              style={{
                backgroundColor: token.colorBgBase,
                // position: 'fixed',
                // bottom: `${fPos}px`,
                // right: '0',
                width: '100%',
                padding: '16px 5%',
                display: 'flex',
                justifyContent: 'space-between',
                gap: '20px',
                zIndex: 5,
                borderTop: `1px solid ${token.colorBorder}`,
              }}
            >
              <Container>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: screens.xs ? 'column' : 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: '10px',
                  }}
                >
                  <Space>
                    {(!waitingListApproverRequest ||
                      !disabledAllocateButton ||
                      waitingListApproverRequest.nbrOfSeats !==
                        seatsToRequest) && (
                      <Space size={4} align="center" wrap>
                        <Text
                          style={{ fontSize: screens.xs ? '14px' : '20px' }}
                          strong
                        >
                          {seatsToRequest}
                        </Text>
                        <Text style={{ fontSize: '12px' }}>
                          {waitingListApproverRequest?.status !==
                          ApproverRequestsStatus.APPROVED
                            ? t('approver_request_seats_to_demand')
                            : t('general_seats_to_allocate')}
                        </Text>
                      </Space>
                    )}

                    <Space
                      size={4}
                      align="center"
                      wrap
                      // direction={screens.xs ? 'vertical' : 'horizontal'}
                    >
                      <Text
                        style={{ fontSize: screens.xs ? '14px' : '20px' }}
                        strong
                      >
                        {waitingListWithApproval.totalRequestedToFgo}
                      </Text>
                      <Text style={{ fontSize: '12px' }}>
                        {t('approver_request_demanded_seats')}
                      </Text>
                    </Space>
                    <Divider type="vertical" />
                    <Space size={4} align="center" wrap>
                      <Text
                        style={{ fontSize: screens.xs ? '14px' : '20px' }}
                        strong
                      >
                        {waitingListWithApproval?.totalAvailableSeats}
                      </Text>{' '}
                      <Text style={{ fontSize: '12px' }}>
                        {t('request_card_available_seats')}
                      </Text>
                    </Space>
                  </Space>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: screens.xs ? '100%' : 'auto',
                      gap: '15px',
                    }}
                  >
                    {(!isRequestButtonDisabled || !screens.xs) && (
                      <Button
                        style={{ width: screens.xs ? '100%' : 'auto' }}
                        size="large"
                        type="primary"
                        disabled={isRequestButtonDisabled}
                        onClick={showRequestReasonModal}
                      >
                        {t('guestlist_confirm_selection')}
                      </Button>
                    )}
                    {(!isAllocateButtonDisabled || !screens.xs) && (
                      <Button
                        style={{ width: screens.xs ? '100%' : 'auto' }}
                        size="large"
                        type="primary"
                        disabled={isAllocateButtonDisabled}
                        onClick={distribute}
                      >
                        {t('general_allocate_action')}
                      </Button>
                    )}
                  </div>
                </div>
              </Container>
            </AntFooter>,
            document.getElementById('portalForFooter') as HTMLElement
          )}
        </Space>
        <Modal
          title={t('request_reason')}
          open={requestReasonModal}
          onOk={handleRequest}
          confirmLoading={isRequestCreating || isRequestUpdating}
          onCancel={hideRequestReasonModal}
          okText={t('guestlist_confirm_selection')}
          cancelText={t('general_cancel-btn')}
        >
          <Form
            initialValues={{
              requestReason: waitingListApproverRequest?.requestReason,
            }}
            form={form}
            layout="vertical"
          >
            <Form.Item name="requestReason">
              <TextArea
                showCount
                maxLength={300}
                style={{ height: 150, resize: 'none' }}
                placeholder={t('request_reason_placeholder')}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Container>
    </div>
  );
}

import { z } from 'zod';

export const JoinWaitingListValidatorSchema = z.object({
  numberOfSeats: z.number(),
  numberOfParkingTickets: z.number(),
  pickedSeatsId: z.array(z.string()).optional(),
});

export type JoinWaitingListValidator = z.infer<
  typeof JoinWaitingListValidatorSchema
>;

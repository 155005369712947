import { InvitationEntity } from '@seaters-app/constants';
import { useDeclineWaitingListInvitation } from '@seaters-app/data-access';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

export const useDeclineInvitation = (
  waitingListId: string
): ((invitationId: string) => Promise<InvitationEntity>) => {
  const { t } = useTranslation();

  const { mutateAsync: declineWaitingListInvitation } =
    useDeclineWaitingListInvitation(waitingListId);

  const handleDeclineInvitation = (invitationId: string) =>
    declineWaitingListInvitation(
      { invitationId },
      {
        onSuccess: () => {
          notification.success({
            message: t('invitation_decline_notification_success'),
          });
        },
        onError: () => {
          notification.error({
            message: t('invitation_decline_notification_error'),
          });
        },
      }
    );

  return handleDeclineInvitation;
};

import { DeleteOutlined } from '@ant-design/icons';
import { Table, Space, Popconfirm, theme, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { MailcampaignEntity, MailcampaignState } from '@seaters-app/constants';
import { Typography } from 'antd';
import { Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  useFanGroupStore,
  useFetchMailcampaigns,
} from '@seaters-app/data-access';
import { TablePaginationConfig } from 'antd';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { usePagination } from '../../../utils';

const { Text } = Typography;
function MailcampaignScheduled() {
  const { token } = theme.useToken();
  const [columnsHidden] = useState(false);
  const { t } = useTranslation();

  const { fanGroup } = useFanGroupStore();

  const fanGroupId = fanGroup?.id ?? '';

  const [query, setQuery] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    current: withDefault(NumberParam, 1),
  });

  const { data: mailcampaign } = useFetchMailcampaigns({
    fangroup: fanGroupId,
    state: MailcampaignState.SCHEDULED,
    offset: (query.current - 1) * query.pageSize,
    limit: query.pageSize,
  });

  const pagination = usePagination({
    ...query,
    totalSize: mailcampaign?.count,
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      pageSize,
      current,
    });
  };

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        size="small"
        dataSource={mailcampaign?.items}
        pagination={pagination}
        onChange={handleTableChange}
        style={{ overflowX: 'auto' }}
        columns={[
          {
            title: t('mailcampain_table-name'),
            dataIndex: 'name',
            align: 'left',
            width: !columnsHidden ? 300 : '60%',
            render: (name) => {
              return (
                <Space size={0} direction="vertical">
                  <Text
                    style={{
                      padding: 0,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      height: 'auto',
                      textAlign: 'left',
                    }}
                  >
                    {name}
                  </Text>
                </Space>
              );
            },
            sorter: true,
            sortOrder: 'ascend',
            onHeaderCell: () => ({
              onClick: () => {},
            }),
          },
          {
            title: t('mailcampain_table-send_date'),
            dataIndex: 'updatedAt',
            align: 'right',
            width: !columnsHidden ? 100 : '20%',
            render: (updatedAt) => {
              return (
                <Space size={0} direction="vertical">
                  <Text
                    style={{
                      padding: 0,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      height: 'auto',
                      textAlign: 'left',
                    }}
                  >
                    {updatedAt}
                  </Text>
                </Space>
              );
            },
            sorter: true,
            sortOrder: 'ascend',
            onHeaderCell: () => ({
              onClick: () => {},
            }),
          },
          {
            title: t('mailcampaign_table-recipient'),
            dataIndex: 'recipient',
            fixed: 'right',
            width: !columnsHidden ? 100 : '20%',
            render: (recipient) => (
              <Tooltip placement="topLeft" title={recipient}>
                <Text
                  style={{
                    padding: 0,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    height: 'auto',
                    textAlign: 'left',
                  }}
                >
                  {recipient}
                </Text>
              </Tooltip>
            ),
          },
        ]}
      />
    </>
  );
}
export default MailcampaignScheduled;

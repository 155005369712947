import { BadgeMoodEnum, WaitingListStatusEnum } from '../enums';
import { FanGroupStatus } from './../enums/fanGroup';

export const FangroupStatusOptions = [
  { label: 'Draft', value: FanGroupStatus.DRAFT },
  { label: 'Archived', value: FanGroupStatus.ARCHIVED },
  { label: 'Published', value: FanGroupStatus.PUBLISHED },
];

export const WishListStatusOptions = [
  { label: 'Draft', value: WaitingListStatusEnum.DRAFT },
  { label: 'Setup', value: WaitingListStatusEnum.SETUP },
  { label: 'Archived', value: WaitingListStatusEnum.ARCHIVED },
  { label: 'Published', value: WaitingListStatusEnum.PUBLISHED },
  { label: 'Open', value: WaitingListStatusEnum.OPEN },
  { label: 'Closed', value: WaitingListStatusEnum.CLOSED },
];

export const BadgeMoodOptions = [
  { label: 'Good', value: BadgeMoodEnum.GOOD },
  { label: 'Neutral', value: BadgeMoodEnum.NEUTRAL },
  { label: 'Bad', value: BadgeMoodEnum.BAD },
];

import { FanGroupOwnerFanGroupEntity } from '@seaters-app/constants';
import { create } from 'zustand';

interface FanGroupState {
  fanGroup: FanGroupOwnerFanGroupEntity | undefined;
  setFanGroup: (fanGroup: FanGroupOwnerFanGroupEntity | undefined) => void;
}

export const useFanGroupStore = create<FanGroupState>((set) => ({
  fanGroup: undefined,
  setFanGroup: (fanGroup: FanGroupOwnerFanGroupEntity | undefined) =>
    set(() => ({ fanGroup: fanGroup })),
}));

import {
  SurveyExtensionPoint,
  SurveyInputMode,
  SurveyInstanceBody,
  SurveyInstanceEntity,
} from '@seaters-app/constants';
export const getInitialValues = (
  surveyInstance?: SurveyInstanceEntity
): SurveyInstanceBody => {
  return surveyInstance
    ? {
        surveyId: surveyInstance.survey.id ?? '',
        fangroupId: surveyInstance?.fangroupId ?? '',
        waitinglistId: surveyInstance?.waitinglistId ?? '',
        extensionPoint: surveyInstance.extensionPoint,
        inputMode: SurveyInputMode.EDITABLE,
      }
    : {
        surveyId: '',
        fangroupId: null,
        waitinglistId: '',
        inputMode: SurveyInputMode.EDITABLE,
      };
};

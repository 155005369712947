import {
  useFetchWaitingList,
  useFetchWaitingListTickets,
} from '@seaters-app/data-access';
import { Card, theme, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

export interface PurchaseSummaryProps {
  waitingListId: string;
  tickets: number;
}

export function PurchaseSummary() {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { waitingListId = '', wishListId = '' } = useParams();
  const wlId = waitingListId || wishListId;
  const { data: waitingList } = useFetchWaitingList(wlId);

  const { data: price } = useFetchWaitingListTickets(
    wlId,
    waitingList?.price?.numberOfSeats || 0
  );

  if (!waitingList || waitingList?.freeWaitingList) return null;

  return (
    <Card
      title={t('purchase_summary_title')}
      style={{
        width: 300,
        backgroundColor: token.colorPrimaryBg,
        marginBottom: 24,
        marginTop: 20,
      }}
    >
      <div className={style.priceItem}>
        <Text>
          {price?.formattedFacialPrice} x {waitingList?.price?.numberOfSeats}{' '}
          {t('guests_text')}
        </Text>
        <Text>{price?.formattedTotalFacialPrice}</Text>
      </div>
      <div className={style.priceItem}>
        <Text>{t('waitinglist_you_save')}</Text>
        <Text>{price?.formattedDiscountAmount}</Text>
      </div>
      <div className={style.priceItem}>
        <Text>{t('waitinglist_fees')}</Text>
        <Text>{price?.formattedFee}</Text>
      </div>
      <div className={style.priceItem}>
        <Text strong>{t('total')}</Text>
        <Text strong>{price?.formattedTotal}</Text>
      </div>
    </Card>
  );
}

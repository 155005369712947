import {
  InvitationMode,
  PositionsDistributionMode,
  WaitingListEntity,
} from '@seaters-app/constants';

export const getCounterValue = (
  waitingList?: WaitingListEntity,
  isHost?: boolean,
  userSeatsLeft?: number
) => {
  let defaultValue = 0;
  let minValue = 0;
  let maxValue = 0;
  if (waitingList) {
    const {
      invitationMode,
      positionsDistributionMode,
      minNumberOfSeatsPerPosition,
      minNumberOfSeatsPerHost,
      maxNumberOfSeatsPerHost,
      fixedNumberOfSeatsPerPosition,
      maxNumberOfSeatsPerPosition,
      directSalesEnabled,
    } = waitingList;

    if (invitationMode === InvitationMode.ENABLED && isHost) {
      minValue = minNumberOfSeatsPerHost;
      defaultValue = minNumberOfSeatsPerHost;
      maxValue = maxNumberOfSeatsPerHost;
    } else {
      if (positionsDistributionMode === PositionsDistributionMode.RANDOM) {
        minValue = fixedNumberOfSeatsPerPosition;
        defaultValue = fixedNumberOfSeatsPerPosition;
        maxValue = fixedNumberOfSeatsPerPosition;
      } else {
        minValue = minNumberOfSeatsPerPosition;
        defaultValue = minNumberOfSeatsPerPosition;
        maxValue = maxNumberOfSeatsPerPosition;
      }
    }

    // May be used for further validation:

    // if (directSalesEnabled && userSeatsLeft && userSeatsLeft < maxValue) {
    //   maxValue = userSeatsLeft;
    // }

    // if guest management mode is enable, then it should be between 1 & maxNumberOfSeatsPerHost
    // if guest management is disable :
    // if it's random distribution mode  it's just fixedNumberOfSeatsPerPosition
    // other distribution mode , between minNumberOfSeatsPerPosition  & maxNumberOfSeatsPerPosition

    // Then in guest management, when you create invitation for guest,
    // the number of tickets per invitation should be between
    // minNumberOfSeatsPerPosition  & maxNumberOfSeatsPerPosition
    //(except for to allocate invitation between 1 & maxNumberOfSeatsPerHost)
  }
  return { defaultValue, minValue, maxValue };
};

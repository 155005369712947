import {
  ListEntity,
  TimeZoneEntity,
  timeZonesKeys,
} from '@seaters-app/constants';
import { useQuery } from '@tanstack/react-query';
import { fetchTimeZones } from '../api';

export const useFetchTimeZones = () => {
  const params = {
    itemOffset: 0,
    maxPageSize: 700,
  };

  const { data: timeZones } = useQuery<ListEntity<TimeZoneEntity>, Error>(
    timeZonesKeys.list(params),
    () => fetchTimeZones(params),
    {
      keepPreviousData: true,
    }
  );

  const timeZonesOptions = timeZones?.items.map((timeZoneItem) => ({
    id: timeZoneItem.id,
    name: timeZoneItem.id,
  }));

  return {
    timeZonesOptions,
  };
};

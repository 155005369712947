import {
  GuestEntity,
  GuestWaitingList,
  ListEntityV2,
  SeatEntity,
  SurveyInstanceEntity,
  WaitingListEntity,
} from '@seaters-app/constants';

export enum StepItemIdEnum {
  ATTENDEE_INFO = 'attendeeInfo',
  PERSONAL_INFO_FORM = 'personalInfoForm',
  PAYMENT_FORM = 'paymentForm',
  SURVEY_FORM = 'surveyStep',
}

export enum FGOStepItemIdEnum {
  ATTENDEE_INFO = 'attendeeInfo',
  SURVEY_FORM = 'surveyStep',
  PAYMENT_FORM = 'paymentForm',
}

export enum HostStepItemIdEnum {
  ATTENDEE_INFO = 'attendeeInfo',
  SURVEY_FORM = 'surveyStep',
}

export type StepItemContentProps = {
  next?: () => void;
  prev?: () => void;
  isLastStep?: boolean;
  ticketsAmount?: number;
  surveyInstances?: ListEntityV2<SurveyInstanceEntity>;
  code?: string;
  waitingList?: WaitingListEntity | GuestWaitingList;
  currentStep?: number;
  handleAttendeeForm?: (attendeeValues: any) => void;
  selectedFanId?: string;
  requestedSeats?: number;
  closeCheckout?: () => void;
  submitForm?: string;
  selectedFan?: SeatEntity | GuestEntity;
};

export type StepContentProps = StepItemContentProps & {
  filteredSteps: StepType<FGOStepItemIdEnum | HostStepItemIdEnum>[];
  currentStep: number;
};

export type StepType<T> = {
  key: T;
  title: string;
  content: (props: StepItemContentProps) => JSX.Element;
  submitText: string;
  orderIndex: number;
};

import {
  ApproverRequestsEntity,
  ApproverRequestsStatus,
  FGOWaitingListWithRequestsEntity,
} from '@seaters-app/constants';
import {
  TagsOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import ReactDOM from 'react-dom';
import {
  useApproveApproverRequest,
  useFetchFGOWaitingListApproverRequests,
  useRejectApproverRequest,
  useUpdateApproverRequest,
} from '@seaters-app/data-access';
import { Button, CustomDivider, baseColor100 } from '@seaters-app/ui';
import {
  Alert,
  AlertProps,
  ConfigProvider,
  Grid,
  InputNumber,
  Popover,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
  theme,
} from 'antd';
import { Key, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';
import { usePagination } from '../../utils/hooks';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './distribute.module.css';
import AntLayout from 'antd/es/layout';

const { useBreakpoint } = Grid;
const { Text, Title } = Typography;
const { Footer: AntFooter } = AntLayout;

export const statusToBadge: {
  [status in ApproverRequestsStatus]: AlertProps['type'];
} = {
  [ApproverRequestsStatus.APPROVED]: 'success',
  [ApproverRequestsStatus.PENDING]: 'warning',
  [ApproverRequestsStatus.REJECTED]: 'error',
  [ApproverRequestsStatus.CANCELLED]: 'error',
};

export function ApproversTable({
  waitingListData,
}: {
  waitingListData: FGOWaitingListWithRequestsEntity;
}) {
  const { token } = theme.useToken();

  const { waitingListId = '' } = useParams();

  const [query, setQuery] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    current: withDefault(NumberParam, 1),
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      pageSize,
      current,
    });
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [seats, setSeats] = useState<{
    [approverRequestId: string | Key]: number;
  }>({});

  const { t } = useTranslation();
  const { xs } = useBreakpoint();
  const { data: approvers, isLoading } = useFetchFGOWaitingListApproverRequests(
    waitingListId,
    {
      size: 20,
      page: 0,
    }
  );

  const pagination = usePagination({
    ...query,
    totalSize: approvers?.totalElements,
  });

  const { mutateAsync: updateRequestedSeats } = useUpdateApproverRequest();
  const { mutateAsync: approveApproverRequest } = useApproveApproverRequest();
  const { mutateAsync: rejectApproverRequest } = useRejectApproverRequest();

  useEffect(() => {
    if (approvers) {
      const currentAllocatedForAllApprovers = approvers?.content.reduce(
        (
          acc: {
            [approverRequestId: string | Key]: number;
          },
          curr: ApproverRequestsEntity
        ) => {
          acc[curr?.id] = curr.nbrOfSeats;
          return acc;
        },
        {}
      );
      setSeats(currentAllocatedForAllApprovers ?? 0);
    }
  }, [approvers?.content.length]);

  if (!approvers) return null;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const getRemoveDisabled = ({ status }: ApproverRequestsEntity) => {
    return (
      status === ApproverRequestsStatus.APPROVED ||
      status === ApproverRequestsStatus.REJECTED
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: ApproverRequestsEntity) => ({
      disabled: getDistributionDisabled(record) && getRemoveDisabled(record), // Column configuration not to be checked
    }),
  };

  const tableData = approvers.content;

  const handleApproveRequestUpdate = async (
    approverRequest: ApproverRequestsEntity
  ) => {
    if (
      seats[approverRequest.id] &&
      seats[approverRequest.id] !== approverRequest.nbrOfRequestedSeats
    ) {
      await updateRequestedSeats({
        waitingListId,
        requestId: approverRequest.id,
        body: {
          nbrOfSeats: seats[approverRequest.id],
        },
      });
    }
  };

  const handleApproverRequestDistribute = async (
    approverRequest: ApproverRequestsEntity
  ) => {
    await handleApproveRequestUpdate(approverRequest);

    await approveApproverRequest({
      waitingListId,
      requestId: approverRequest.id,
    });
  };

  const handleApproverRequestReject = async (
    approverRequest: ApproverRequestsEntity
  ) => {
    await handleApproveRequestUpdate(approverRequest);

    await rejectApproverRequest({
      waitingListId,
      requestId: approverRequest.id,
    });
  };

  const onChange = (approverRequestId: string, value: number | null) => {
    if (value !== null)
      setSeats({
        ...seats,
        [approverRequestId]: value,
      });
  };

  const getRequestById = (id: Key) =>
    approvers.content.find((el) => el.id === id);

  const selectedSeats = selectedRowKeys.reduce(
    (accumulator: number, currentValue) => {
      return (
        accumulator +
        (getRequestById(currentValue)?.status === ApproverRequestsStatus.PENDING
          ? (seats[currentValue] ||
              getRequestById(currentValue)?.nbrOfRequestedSeats) ??
            0
          : seats[currentValue] -
            (getRequestById(currentValue)?.nbrOfRequestedSeats ?? 0))
      );
    },
    0
  );

  const getRequestValue = (approverRequest: ApproverRequestsEntity) =>
    seats[approverRequest.id] || approverRequest.nbrOfRequestedSeats;

  const getDistributionDisabled = (approverRequest: ApproverRequestsEntity) => {
    return (
      approverRequest.status === ApproverRequestsStatus.REJECTED ||
      (approverRequest.status === ApproverRequestsStatus.APPROVED &&
        approverRequest.nbrOfSeats >= getRequestValue(approverRequest)) ||
      (approverRequest.status === ApproverRequestsStatus.PENDING &&
        getRequestValue(approverRequest) > waitingListData.totalAvailableSeats)
    );
  };

  const distributeSelected = async () => {
    selectedRowKeys.forEach((selectedRowKey) => {
      const request = getRequestById(selectedRowKey);

      if (request && !getDistributionDisabled(request)) {
        handleApproverRequestDistribute(request);
      }
    });
    setSelectedRowKeys([]);
  };

  const removeSelected = () => {
    selectedRowKeys.forEach((selectedRowKey) => {
      const request = getRequestById(selectedRowKey);

      if (request && !getRemoveDisabled(request)) {
        handleApproverRequestReject(request);
      }
    });
    setSelectedRowKeys([]);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={0}>
      {selectedSeats > waitingListData.totalAvailableSeats && (
        <Space style={{ color: token.colorError, marginBottom: 16 }}>
          {t('not_enough_seats_to_allocate_to_approvers_warning')}
        </Space>
      )}
      {selectedSeats > 0 && !xs && (
        <ConfigProvider
          theme={{
            token: {
              colorText: baseColor100,
            },
          }}
        >
          <Space size={24} className={styles.distributeBlock}>
            <Space size={24}>
              <Space>
                <Text style={{ wordBreak: 'unset', fontSize: '12px' }}>
                  {t('distribution_selected_seats')}
                </Text>
                <Title level={4} ellipsis={{ rows: 1 }} style={{ margin: 0 }}>
                  {selectedSeats}
                </Title>
              </Space>
              <Button danger type="text" onClick={removeSelected}>
                {t('distribution_reject_requests', {
                  'selectedRowKeys.length': selectedRowKeys.length,
                })}
              </Button>
              <Button
                type="primary"
                onClick={distributeSelected}
                style={{
                  color:
                    selectedSeats > waitingListData.totalAvailableSeats
                      ? 'grey'
                      : 'white',
                }}
                disabled={selectedSeats > waitingListData.totalAvailableSeats}
              >
                {t('distribution_distribute_requests', {
                  selectedSeats: selectedSeats,
                })}
              </Button>
            </Space>
          </Space>
        </ConfigProvider>
      )}

      <Table
        rowSelection={rowSelection}
        dataSource={tableData}
        loading={isLoading}
        size="small"
        scroll={{
          x: true,
        }}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey={(record) => record.id}
      >
        <Column
          title={t('queue_menu_status')}
          dataIndex="status"
          render={(value: ApproverRequestsStatus) => {
            return (
              <Alert
                style={{
                  width: 'fit-content',
                  fontSize: xs ? '12px' : 'auto',
                  padding: xs ? 5 : 'auto',
                }}
                message={value}
                type={statusToBadge[value]}
              />
            );
          }}
        />
        {xs && (
          <Column
            title={t('general_seats')}
            dataIndex={['toAll', 'email']}
            render={(_, approverRequest: ApproverRequestsEntity) => {
              const isDisabled =
                selectedRowKeys.includes(approverRequest.id) ||
                approverRequest.status === ApproverRequestsStatus.REJECTED ||
                !waitingListData.totalAvailableSeats;

              const minVal = isDisabled
                ? undefined
                : approverRequest.status === ApproverRequestsStatus.APPROVED
                ? approverRequest.nbrOfRequestedSeats
                : 0;

              const maxVal = isDisabled
                ? undefined
                : approverRequest.nbrOfRequestedSeats +
                  waitingListData.totalAvailableSeats;

              return (
                <Space size={4}>
                  <InputNumber
                    status={
                      approverRequest.status ===
                        ApproverRequestsStatus.PENDING &&
                      getRequestValue(approverRequest) >
                        waitingListData.totalAvailableSeats
                        ? 'error'
                        : ''
                    }
                    style={{ width: '68px' }}
                    min={minVal}
                    max={maxVal}
                    defaultValue={approverRequest.nbrOfRequestedSeats}
                    onChange={(v) => onChange(approverRequest.id, v)}
                    value={seats[approverRequest.id]}
                    disabled={isDisabled}
                  />
                  / {approverRequest.nbrOfRequestedSeats}
                </Space>
              );
            }}
          />
        )}
        <Column
          title={t('guestlist_name')}
          dataIndex={['approver', 'firstName']}
          render={(_, record: ApproverRequestsEntity) => {
            return (
              <>
                {record.approver?.firstName} {record.approver?.lastName}
              </>
            );
          }}
        />

        <Column
          title={t('input_label_email')}
          dataIndex={['approver', 'email']}
        />
        {!xs && (
          <Column
            title={t('general_seats')}
            dataIndex={['toAll', 'email']}
            render={(_, approverRequest: ApproverRequestsEntity) => {
              const isDisabled =
                selectedRowKeys.includes(approverRequest.id) ||
                approverRequest.status === ApproverRequestsStatus.REJECTED ||
                !waitingListData.totalAvailableSeats;

              const minVal = isDisabled
                ? undefined
                : approverRequest.status === ApproverRequestsStatus.APPROVED
                ? approverRequest.nbrOfRequestedSeats
                : 0;

              const maxVal = isDisabled
                ? undefined
                : approverRequest.nbrOfRequestedSeats +
                  waitingListData.totalAvailableSeats;

              return (
                <Space size={4}>
                  <InputNumber
                    status={
                      approverRequest.status ===
                        ApproverRequestsStatus.PENDING &&
                      getRequestValue(approverRequest) >
                        waitingListData.totalAvailableSeats
                        ? 'error'
                        : ''
                    }
                    style={{ width: '68px' }}
                    min={minVal}
                    max={maxVal}
                    defaultValue={approverRequest.nbrOfSeats}
                    onChange={(v) => onChange(approverRequest.id, v)}
                    value={seats[approverRequest.id]}
                    disabled={isDisabled}
                  />
                  / {approverRequest.nbrOfRequestedSeats}
                </Space>
              );
            }}
          />
        )}
        <Column
          title={t('general_already_allocated_seats')}
          render={(approver: ApproverRequestsEntity) => {
            return (
              <span>
                {approver.status === ApproverRequestsStatus.APPROVED
                  ? approver.nbrOfSeats
                  : 0}
              </span>
            );
          }}
        />
        <Column
          key="actions"
          align="right"
          render={(_, approverRequest: ApproverRequestsEntity) => {
            return (
              <Space size={0}>
                {approverRequest.requestReason && (
                  <Popover
                    trigger="click"
                    content={
                      <Text className={styles.popover}>
                        {approverRequest.requestReason}
                      </Text>
                    }
                  >
                    <Button
                      icon={<InfoCircleOutlined rev={undefined} />}
                      type="link"
                    >
                      {t('request_reason')}
                    </Button>
                  </Popover>
                )}

                <Button
                  disabled={getRemoveDisabled(approverRequest)}
                  danger
                  icon={<CloseOutlined rev={undefined} />}
                  type="link"
                  onClick={() => {
                    handleApproverRequestReject(approverRequest);
                  }}
                >
                  {t('general_reject')}
                </Button>
                <Button
                  disabled={getDistributionDisabled(approverRequest)}
                  type="link"
                  icon={<TagsOutlined rev={undefined} />}
                  color={token.colorPrimary}
                  onClick={() => {
                    handleApproverRequestDistribute(approverRequest);
                  }}
                >
                  {t('general_distribute')}
                </Button>
              </Space>
            );
          }}
        />
      </Table>
      {selectedSeats > 0 &&
        xs &&
        ReactDOM.createPortal(
          <AntFooter
            style={{
              backgroundColor: token.colorTextBase,
              position: 'fixed',
              bottom: '0',
              right: '0',
              width: '100%',
              padding: '16px 5%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              zIndex: 10,
              borderTop: `1px solid ${token.colorBorder}`,
            }}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorText: baseColor100,
                },
              }}
            >
              <Space>
                <Text style={{ wordBreak: 'unset', fontSize: '14px' }}>
                  {t('distribution_selected_seats')}
                </Text>
                <Title level={4} ellipsis={{ rows: 1 }} style={{ margin: 0 }}>
                  {selectedSeats}
                </Title>
              </Space>
              <CustomDivider color={token.colorBorder} />
              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                <Button danger type="text" onClick={removeSelected}>
                  {t('distribution_reject_requests', {
                    'selectedRowKeys.length': selectedRowKeys.length,
                  })}
                </Button>
                <Button
                  type="primary"
                  onClick={distributeSelected}
                  style={{
                    color:
                      selectedSeats > waitingListData.totalAvailableSeats
                        ? 'grey'
                        : 'white',
                  }}
                  disabled={selectedSeats > waitingListData.totalAvailableSeats}
                >
                  {t('distribution_distribute_requests', {
                    selectedSeats: selectedSeats,
                  })}
                </Button>
              </Space>
            </ConfigProvider>
          </AntFooter>,
          document.getElementById('portalForFooter') as HTMLElement
        )}
    </Space>
  );
}

import {
  fetchAdminSearchedBadges,
  getSingleTranslation,
} from '@seaters-app/data-access';

import { BadgeEntity, ListEntityV2, badgesKeys } from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useSearchedBadgesOptions = (defaultParams: {
  page: number;
  size: number;
  query: string;
}) => {
  const { data, fetchNextPage, isFetching } = useInfiniteQuery<
    ListEntityV2<BadgeEntity>
  >({
    queryKey: badgesKeys.list(defaultParams),
    queryFn: ({ pageParam = defaultParams }) => {
      return fetchAdminSearchedBadges({
        page: pageParam?.page,
        size: pageParam?.size,
        query: pageParam?.query,
      });
    },
    getNextPageParam: (lastPage) => {
      return !lastPage.last
        ? {
            page: lastPage.number + 1,
            size: defaultParams.size,
            query: defaultParams.query,
          }
        : undefined;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const allItems = data?.pages.flatMap((page) => page.content) || [];
  const options = allItems?.map((item) => {
    return {
      value: item?.id,
      label: getSingleTranslation(item?.name),
      key: item?.id,
      avatar: item.displayedLogoUrl,
    };
  });

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  return {
    badgesOptions: options,
    loadMoreData: handleFetchNextPage,
    badgesFetching: isFetching,
  };
};

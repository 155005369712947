import { Space, Row, Col, Card, Upload, UploadProps } from 'antd';
import { CSVLink } from 'react-csv';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from '@seaters-app/ui';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';

interface ImportDataModalType {
  open: boolean;
  onClose: () => void;
  templateFields: any[];
  setImportedData: (v: { data: any[]; questionId: string }) => void;
  questionId: string;
  ticketsAmount: number;
}

export function ImportDataModal({ ...props }: ImportDataModalType) {
  const { t } = useTranslation();
  const uploadProps: UploadProps = {
    accept: '.csv',
    showUploadList: false,

    onChange(info) {
      if (info.file.status !== 'uploading') {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csvRows = (e.target?.result as string)
            .split('\n')
            .filter((row) => !!row);
          const csvHeader = csvRows.shift();
          const fieldNames = (csvHeader?.split(';') as string[]).map(
            (field: string, i: number) => {
              return i === (csvHeader?.split(';') as string[]).length - 1
                ? field.replace('\r', '')
                : field;
            }
          );

          const filteredSliced = csvRows
            .filter((row) => row !== '\r')
            .slice(0, props.ticketsAmount);
          const data: any[] = filteredSliced.map((row, rowIndex) => {
            const splittedRow = row.split(';');
            const mappedObject: any = {};

            splittedRow.forEach((value, index) => {
              mappedObject[fieldNames[index]] =
                index !== splittedRow.length - 1
                  ? value
                  : value.replace('\r', '');
              mappedObject.key = rowIndex + 1;
            });
            return mappedObject;
          });
          props.setImportedData({ data, questionId: props.questionId });

          props.onClose();
        };
        reader.readAsText(info.file.originFileObj as Blob);
      }
    },
  };

  return (
    <>
      <Modal
        {...props}
        onCancel={props.onClose}
        title={t('import_data_title')}
        footer={null}
        centered
      >
        <Space direction="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Card style={{ height: '100%' }}>
                <Space
                  direction="vertical"
                  align="center"
                  style={{ textAlign: 'center' }}
                >
                  <CSVLink
                    data={props.templateFields}
                    separator={';'}
                    enclosingCharacter={``}
                    filename={'template.csv'}
                  >
                    <Button icon={<DownloadOutlined rev={undefined} />}>
                      {t('download_template_button_text')}
                    </Button>
                  </CSVLink>
                  {t('guestlist_get_template')}
                </Space>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ height: '100%' }}>
                <Space
                  direction="vertical"
                  align="center"
                  style={{ textAlign: 'center' }}
                >
                  <Upload {...uploadProps}>
                    <Button
                      type="primary"
                      icon={<UploadOutlined rev={undefined} />}
                      size="large"
                    />
                  </Upload>
                  Import the completed CSV file containing your data
                </Space>
              </Card>
            </Col>
          </Row>
        </Space>
      </Modal>

      {/* <Modal
        title="Please review that all the informations have been correctly set"
        open={tableModalIsOpen}
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{
          htmlType: 'submit',
        }}
        onCancel={async () => {
          props.onClose();
          setTableModalIsOpen(false);
        }}
        width="100%"
      >
        <Form component={false} initialValues={dataSource}>
          <Table
            columns={columnsBuffer}
            dataSource={dataSource}
            pagination={false}
            scroll={{
              x: true,
            }}
          />
        </Form>
      </Modal> */}
    </>
  );
}

import { InvitationEntity } from '@seaters-app/constants';
import { useFetchWaitingListInvitations } from '@seaters-app/data-access';
import { useParams } from 'react-router-dom';

export const useGuestInvitation = (): ((
  guestId: string
) => InvitationEntity | undefined) => {
  const { waitingListId = '' } = useParams();
  const { data: invitations } = useFetchWaitingListInvitations(waitingListId);

  const guestInvitation = (guestId: string) =>
    invitations?.content.find(
      (invitation) => invitation.guest.guestId === guestId
    );
  return guestInvitation;
};

import React, { useEffect, useRef, useState } from 'react';
import { defineText } from './helpers';
import { ConfigProvider, theme } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { baseColor400, errorColor } from '@seaters-app/ui';
interface StyledTextProps {
  text: string;
  isExpanded?: boolean;
  onClick?: () => void;
  isError?: boolean;
}

export function StyledText({
  text,
  isExpanded = true,
  onClick,
  isError = false,
}: StyledTextProps) {
  const { token } = theme.useToken();

  const explanation = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();
  const { language: lang } = i18n;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const borderStyle = {
    border: `1px solid ${
      isError ? errorColor : isHovered ? token.colorPrimary : baseColor400
    }`,
  };

  useEffect(() => {
    if (explanation.current) {
      explanation.current.innerHTML = !isExpanded ? defineText(text, 0) : text;
    }
  }, [lang, isExpanded]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorLink: token.colorPrimary,
            colorLinkHover: token.colorPrimaryActive,
            colorLinkActive: token.colorPrimaryActive,
          },
        },
      }}
    >
      <div
        onMouseEnter={onClick && handleMouseEnter}
        onMouseLeave={onClick && handleMouseLeave}
        onClick={onClick}
        style={onClick && borderStyle}
        className={onClick ? 'styledPreview' : 'styledText'}
        ref={explanation}
      />
    </ConfigProvider>
  );
}

export default StyledText;

import {
  endpoints,
  V2Prefix,
  SortedPaginationQueryV2,
  FanGroupCustomInformation,
  ListEntityV2,
  CustomProfileScopeEnum,
  CustomProfileTypeEnum,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export interface FGCustomInfoBody {
  type: CustomProfileTypeEnum;
  scope: CustomProfileScopeEnum;
  semanticId: string;
  mandatory: boolean;
}

export const fetchFanGroupCustomInfo = (
  fanGroupId: string,
  params: SortedPaginationQueryV2
): Promise<ListEntityV2<FanGroupCustomInformation>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.customInfo}/`,
    { params }
  );

// GET https://api.qa-seaters.com/api/v2/host/groups/groupId/custom-info/

export const fetchHostFanGroupCustomInfo = (
  fanGroupId: string
): Promise<FanGroupCustomInformation[]> =>
  api.get(
    `${V2Prefix}/${endpoints.host}/${endpoints.groups}/${fanGroupId}/${endpoints.customInfo}/`
  );

export const createFanGroupCustomInfo = (
  fanGroupId: string,
  body: FGCustomInfoBody
): Promise<FanGroupCustomInformation> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.customInfo}`,
    body
  );

export const updateFanGroupCustomInfo = (
  fanGroupId: string,
  fgCustomInfoId: string,
  scope: CustomProfileScopeEnum,
  mandatory: boolean
): Promise<FanGroupCustomInformation> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.customInfo}/${fgCustomInfoId}`,
    { scope, mandatory }
  );

// DELETE /fan-group-owner/groups/{groupId}/code/{code}/custom-info/{semanticName}/

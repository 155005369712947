import { MailcampaignEntity } from "@seaters-app/constants";
import i18n from '../../../i18n/config';


export function recipientToLabel(recipient: MailcampaignEntity["recipient"]): string | undefined {
    switch (recipient) {
        case "fangroup":
        return i18n.t('mailcampaign_recipient_fangroup');
        case "waitinglist":
        return i18n.t('mailcampaign_recipient_waitinglist');
        case "waitinglist_not_distributed":
        return i18n.t('mailcampaign_recipient_waitinglist_not_distributed');
        case "waitinglist_distributed_not_accepted":
        return i18n.t('mailcampaign_recipient_waitinglist_distributed_not_accepted');
        case "waitinglist_distributed_accepted":
        return i18n.t('mailcampaign_recipient_waitinglist_distributed_accepted');
        default:
        return '';
    }
}
import React, { useEffect, useState } from 'react';
import {
  CreateSurveyQuestionFormValidator,
  CreateSurveyQuestionFormValidatorSchema,
  FormTranslationType,
  FormattedValuesWithTranslations,
  Language,
  Question,
  QuestionAnswers,
  QuestionItem,
  QuestionStructureType,
  QuestionType,
  QuestionTypeOptions,
  StructuredQuestionTypeOptions,
} from '@seaters-app/constants';
import {
  Controller,
  FormProvider,
  UseFieldArrayReturn,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Space,
  theme,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useFetchAdminAnswerSemantics,
  useFetchAdminSurvey,
  useFetchLanguages,
  useFetchQuestionChoices,
} from '@seaters-app/data-access';
import { Button, baseColor400, errorColor } from '@seaters-app/ui';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { getInitialValues } from './helpers/getInitialQuestionValues';
import useSubmitForm from './hooks/useSubmitForm';
import { getTranslations } from '../../../../../utils';
import { updateForm } from './helpers/updateForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { SegmentedValue } from 'antd/es/segmented';
import { useParams } from 'react-router-dom';

interface QuestionFormProps {
  question?: QuestionItem;
  answers?: QuestionAnswers[];
  isPreview?: boolean;
  creation?: boolean;
  isCreateFromScratch: boolean;
  setCreateFromScratch?: (v: boolean) => void;
  removeQuestion?: (questionId: string) => void;
  addToSurvey?: (v: QuestionItem) => void;
  isQuestionModalOpen?: boolean;
  setQuestionModalOpen?: (v: boolean) => void;
  setQuestionToEdit?: (v: QuestionItem | null) => void;
  hideMandatory?: () => void;
}

export function QuestionForm({
  question,
  isPreview = false,
  creation = false,
  isCreateFromScratch,
  setCreateFromScratch,
  removeQuestion,
  addToSurvey,
  isQuestionModalOpen,
  setQuestionModalOpen,
  setQuestionToEdit,
}: QuestionFormProps) {
  const { t } = useTranslation();
  const { questionnaireId = '' } = useParams();
  const { data: survey } = useFetchAdminSurvey(questionnaireId);

  const questionTypeOptionWithTranslations = QuestionTypeOptions.map((type) => {
    return {
      label: t(type.label),
      value: type.value,
      disabled:
        survey?.surveyQuestions.find(
          (item) => item.question.type === QuestionType.STRUCTURED
        ) &&
        isCreateFromScratch &&
        type.value === QuestionType.STRUCTURED,
    };
  });

  const structuredQuestionTypeOptionsWithTranslation =
    StructuredQuestionTypeOptions.map((type) => {
      return {
        label: t(type.label),
        value: type.value,
      };
    });

  const { data: questionChoices } = useFetchQuestionChoices(
    question?.question.id ?? '',
    {
      size: 999,
      page: 0,
    }
  );

  const answers = questionChoices?.content.map((option, i) => {
    return {
      offeredAnswerTranslation: option.offeredAnswerTranslation,
      id: option.id,
      order: i + 1,
    };
  });

  const [newQuestionProperties, setNewQuestionProperties] = useState({
    mandatory: false,
    enabled: true,
  });

  const { token } = theme.useToken();

  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);

  const { data: languageData } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const languages = languageData
    ? languageData?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  const { data: answerSemantics } = useFetchAdminAnswerSemantics({
    maxPageSize: 200,
    itemOffset: 0,
  });

  const valuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<Question>(['text', 'answerLabel'], question?.question);

  const { isLoading, onSubmit } = useSubmitForm(
    question,
    creation,
    setCurrentLanguage,
    setQuestionModalOpen,
    addToSurvey,
    setCreateFromScratch,
    newQuestionProperties
  );

  const answerSemanticsOptions = answerSemantics?.items.map((semantic, i) => ({
    label: semantic.name,
    value: semantic.id,
  }));

  const initialValues = getInitialValues(
    question,
    answers,
    valuesWithTranslations
  );

  const methods = useForm<CreateSurveyQuestionFormValidator>({
    resolver: zodResolver(CreateSurveyQuestionFormValidatorSchema),
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  const type = useWatch({
    control,
    name: 'type',
  });

  const {
    fields,
    append,
    remove,
  }: UseFieldArrayReturn<
    {
      questionChoices: {
        offeredAnswerTranslation: FormTranslationType;
        id: string;
      }[];
    },
    'questionChoices',
    keyof { offeredAnswerTranslation: FormTranslationType; id: string }
  > = useFieldArray({
    control,
    name: 'questionChoices',
  });

  const {
    fields: structure,
    append: appendStructureField,
    remove: removeStructureField,
  }: UseFieldArrayReturn<
    any,
    'structure',
    keyof { name: string; type: QuestionStructureType }
  > = useFieldArray({
    control,
    name: 'structure',
  });

  useEffect(() => {
    updateForm(
      setValue,
      getInitialValues(question, answers, valuesWithTranslations)
    );
  }, [questionChoices?.content?.length, questionChoices?.content]);

  useEffect(() => {
    updateForm(setValue, initialValues);
  }, [
    question?.question.id,
    question?.question?.structure?.length,
    question?.question,
  ]);

  useEffect(() => {
    if (errors && currentLanguage !== Language.EN) {
      setCurrentLanguage(Language.EN);
    }
  }, [errors]);

  return isCreateFromScratch ||
    questionChoices ||
    question?.question.type === QuestionType.FREE_TEXT ||
    question?.question.type === QuestionType.STRUCTURED ? (
    <div style={{ padding: 12 }}>
      {!isPreview && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {addToSurvey && (
            <Space>
              <Checkbox
                defaultChecked={
                  question?.enabled ?? newQuestionProperties.enabled
                }
                value={question?.enabled ?? newQuestionProperties.enabled}
                onChange={() => {
                  if (question?.question.id) {
                    addToSurvey({
                      question: question?.question,
                      mandatory: question?.mandatory,
                      enabled: !question?.enabled,
                    });
                  } else {
                    const updatedNewQuestionProperties = {
                      ...newQuestionProperties,
                      enabled: !newQuestionProperties.enabled,
                    };
                    setNewQuestionProperties(updatedNewQuestionProperties);
                  }
                }}
              >
                {t('survey_management_question_enabled')}
              </Checkbox>
              {type !== QuestionType.STRUCTURED && (
                <Checkbox
                  defaultChecked={
                    question?.mandatory ?? newQuestionProperties.mandatory
                  }
                  value={question?.mandatory ?? newQuestionProperties.mandatory}
                  onChange={() => {
                    if (question?.question.id) {
                      addToSurvey({
                        question: question?.question,
                        mandatory: !question?.mandatory,
                        enabled: question?.enabled,
                      });
                    } else {
                      const updatedNewQuestionProperties = {
                        ...newQuestionProperties,
                        mandatory: !newQuestionProperties.mandatory,
                      };
                      setNewQuestionProperties(updatedNewQuestionProperties);
                    }
                  }}
                >
                  {t('survey_management_question_mandatory')}
                </Checkbox>
              )}
            </Space>
          )}
          <Space>
            {setQuestionModalOpen &&
              setQuestionToEdit &&
              question &&
              !isQuestionModalOpen && (
                <Button
                  disabled={isCreateFromScratch}
                  size="middle"
                  type="link"
                  icon={<EditOutlined rev={undefined} />}
                  onClick={() => {
                    setQuestionModalOpen(true);
                    setQuestionToEdit(question);
                  }}
                >
                  {t('admin_item_edit')}
                </Button>
              )}
            {removeQuestion && !isCreateFromScratch && (
              <Popconfirm
                title={t('remove_question_title')}
                description={t('remove_question_confirmation_text')}
                cancelText={t('general_no')}
                onConfirm={() => {
                  if (setQuestionModalOpen) {
                    setQuestionModalOpen(false);
                  }
                  removeQuestion(`${question?.question.id}`);
                }}
                onOpenChange={() => console.log('open change')}
              >
                <Button
                  disabled={isCreateFromScratch}
                  color={errorColor}
                  size="middle"
                  type="link"
                  icon={<DeleteOutlined rev={undefined} />}
                >
                  {t('wl_delete-btn')}
                </Button>
              </Popconfirm>
            )}
          </Space>
        </div>
      )}
      <div
        style={{
          width: '100%',
          textAlign: 'end',
          marginTop: 12,
          marginBottom: 12,
        }}
      >
        <Segmented
          size="middle"
          options={languages}
          value={currentLanguage}
          onChange={setCurrentLanguage as (value: SegmentedValue) => void}
        />
      </div>
      <FormProvider {...methods}>
        <Form
          style={{ maxWidth: 630, width: '100%' }}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          disabled={
            // !!useIsFetching() ||
            isPreview || (!isQuestionModalOpen && !isCreateFromScratch)
          }
        >
          <Row gutter={24}>
            <Col xs={24}>
              <Form.Item
                name={`text.${currentLanguage}`}
                label={t('survey_management_title_label')}
                required={currentLanguage === Language.EN}
                validateStatus={errors.text && 'error'}
                help={
                  errors?.text &&
                  errors?.text[currentLanguage] &&
                  errors?.text[currentLanguage]?.message
                }
              >
                <Controller
                  rules={{ required: true, min: 1 }}
                  control={control}
                  name={`text.${currentLanguage}`}
                  render={({ field }) => (
                    <Input
                      disabled={!isQuestionModalOpen}
                      {...field}
                      size="middle"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <Form.Item
                name={`answerLabel.${currentLanguage}`}
                label={t('survey_management_answer_label_label')}
                required={currentLanguage === Language.EN}
                validateStatus={errors.answerLabel && 'error'}
                help={
                  errors?.answerLabel &&
                  errors?.answerLabel[currentLanguage] &&
                  errors?.answerLabel[currentLanguage]?.message
                }
              >
                <Controller
                  rules={{ required: true, min: 1 }}
                  control={control}
                  name={`answerLabel.${currentLanguage}`}
                  render={({ field }) => (
                    <Input
                      disabled={!isQuestionModalOpen}
                      {...field}
                      size="middle"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <Form.Item
                name="answerSemanticId"
                label={t('survey_management_answer_semantic_label')}
                required
              >
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="answerSemanticId"
                  render={({ field }) => (
                    <Select
                      showSearch
                      optionFilterProp="label"
                      defaultValue={field.value}
                      disabled={!isQuestionModalOpen}
                      options={answerSemanticsOptions}
                      {...field}
                      size="middle"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} md={24}>
              <Form.Item
                name="type"
                label={t('admin_payment-system_type')}
                required
              >
                <Controller
                  control={control}
                  name={`type`}
                  render={({ field }) => (
                    <Select
                      disabled={!isQuestionModalOpen}
                      options={questionTypeOptionWithTranslations}
                      {...field}
                      size="middle"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          {type !== QuestionType.FREE_TEXT &&
            type !== QuestionType.STRUCTURED &&
            type !== QuestionType.MULTIPLE_CHOICE_RANGE && (
              <Space
                direction="vertical"
                style={{ width: '100%', marginBottom: '24px' }}
              >
                {fields.map((field, index) => {
                  return (
                    <Row gutter={24}>
                      <Col xs={24}>
                        <Form.Item
                          name={`questionChoices.${[
                            field.offeredAnswerTranslation,
                          ]}.${currentLanguage}`}
                          label={t('question_answer_choice_label', {
                            number: index + 1,
                          })}
                          required={currentLanguage === Language.EN}
                          validateStatus={errors.questionChoices && 'error'}
                          help={
                            errors?.questionChoices &&
                            errors?.questionChoices[index] &&
                            errors?.questionChoices[index]
                              .offeredAnswerTranslation &&
                            errors?.questionChoices[index]
                              .offeredAnswerTranslation[currentLanguage] &&
                            errors?.questionChoices[index]
                              .offeredAnswerTranslation[currentLanguage].message
                          }
                        >
                          <Controller
                            rules={{ required: true, min: 1 }}
                            key={field.id}
                            name={`questionChoices[${index}].offeredAnswerTranslation.${currentLanguage}`}
                            control={control}
                            defaultValue={
                              field.offeredAnswerTranslation[currentLanguage] ??
                              ''
                            }
                            render={({ field }) => (
                              <Input
                                disabled={!isQuestionModalOpen}
                                suffix={
                                  isQuestionModalOpen ? (
                                    <CloseOutlined
                                      disabled={!isQuestionModalOpen}
                                      style={{
                                        color: errorColor,
                                      }}
                                      rev={undefined}
                                      onClick={() =>
                                        isQuestionModalOpen && remove(index)
                                      }
                                    />
                                  ) : null
                                }
                                {...field}
                              />
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
                {isQuestionModalOpen && (
                  <Button
                    color={token.colorPrimary}
                    size="middle"
                    type="link"
                    icon={<PlusOutlined rev={undefined} />}
                    onClick={() => {
                      append({
                        offeredAnswerTranslation: {
                          [currentLanguage]: '',
                        },
                        id: '',
                      });
                    }}
                  >
                    {t('add_answer_button_text')}
                  </Button>
                )}
              </Space>
            )}
          {type === QuestionType.FREE_TEXT && (
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  name={`answer.${currentLanguage}`}
                  label={t('survey_management_answer_label')}
                >
                  <Controller
                    disabled
                    name={`answer.${currentLanguage}`}
                    control={control}
                    render={({ field }) => <Input disabled {...field} />}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {type === QuestionType.MULTIPLE_CHOICE_RANGE && (
            <>
              <Row gutter={24}>
                <Col xs={8}>
                  <Form.Item name="rangeAnswerMin" label={t('MIN')}>
                    <Controller
                      name="rangeAnswerMin"
                      control={control}
                      render={({ field }) => (
                        <InputNumber {...field} min={0} max={1} />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item name="rangeAnswerMax" label={t('MAX')}>
                    <Controller
                      name="rangeAnswerMax"
                      control={control}
                      render={({ field }) => <InputNumber {...field} min={1} />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={8}>
                  <Form.Item
                    name={`rangeAnswerMinText.${currentLanguage}`}
                    label={t('min-rate-description')}
                  >
                    <Controller
                      name={`rangeAnswerMinText.${currentLanguage}`}
                      control={control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    name={`rangeAnswerMaxText.${currentLanguage}`}
                    label={t('max-rate-description')}
                  >
                    <Controller
                      name={`rangeAnswerMaxText.${currentLanguage}`}
                      control={control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {type === QuestionType.STRUCTURED && (
            <div>
              {structure.map(
                (field: Record<'type' | 'name', string>, index) => {
                  return (
                    <Row gutter={24}>
                      <Col xs={24}>
                        <Form.Item
                          name={`structure.${[field.name]}`}
                          label={t('survey_management_answer_multiple_label', {
                            number: index + 1,
                          })}
                          required
                        >
                          <Space>
                            <Controller
                              name={`structure[${index}].name`}
                              control={control}
                              defaultValue={field.name}
                              render={({ field }) => (
                                <Input
                                  suffix={
                                    isQuestionModalOpen ? (
                                      <CloseOutlined
                                        disabled={!isQuestionModalOpen}
                                        style={{
                                          color: isQuestionModalOpen
                                            ? errorColor
                                            : baseColor400,
                                        }}
                                        rev={undefined}
                                        onClick={() =>
                                          isQuestionModalOpen &&
                                          removeStructureField(index)
                                        }
                                      />
                                    ) : null
                                  }
                                  disabled={!isQuestionModalOpen}
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name={`structure[${index}].type`}
                              control={control}
                              defaultValue={field.type}
                              render={({ field }) => {
                                return (
                                  <Select
                                    disabled={!isQuestionModalOpen}
                                    options={
                                      structuredQuestionTypeOptionsWithTranslation
                                    }
                                    {...field}
                                    size="middle"
                                  />
                                );
                              }}
                            />
                            <Controller
                              name={`structure[${index}].mandatory`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Checkbox
                                    defaultChecked={field.value}
                                    disabled={!isQuestionModalOpen}
                                    {...field}
                                  >
                                    {t('survey_management_question_mandatory')}
                                  </Checkbox>
                                );
                              }}
                            />
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
              )}
              {isQuestionModalOpen && (
                <Button
                  color={token.colorPrimary}
                  style={{ marginBottom: 16 }}
                  size="middle"
                  type="link"
                  icon={<PlusOutlined rev={undefined} />}
                  onClick={() =>
                    appendStructureField({
                      name: '',
                      type: QuestionType.FREE_TEXT,
                      mandatory: false,
                    })
                  }
                >
                  {t('add_field_button_text')}
                </Button>
              )}
            </div>
          )}
          {isQuestionModalOpen && setQuestionToEdit && setQuestionModalOpen && (
            <Flex justify="flex-end" gap="small">
              <Button
                size="middle"
                onClick={() => {
                  setValue(
                    'structure',
                    getInitialValues(question, answers, valuesWithTranslations)
                      .structure
                  );
                  setValue(
                    'questionChoices',
                    getInitialValues(question, answers, valuesWithTranslations)
                      .questionChoices
                  );

                  setCreateFromScratch
                    ? setCreateFromScratch(false)
                    : setQuestionModalOpen(false);

                  setQuestionToEdit(null);
                  setQuestionModalOpen(false);
                }}
              >
                {t('general_cancel-btn')}
              </Button>
              <Button
                size="middle"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {t('general_save-btn')}
              </Button>
            </Flex>
          )}
        </Form>
      </FormProvider>
    </div>
  ) : null;
}

export default QuestionForm;

import {
  Breadcrumb,
  Flex,
  Spin,
  Typography,
  UploadFile,
  notification,
} from 'antd';
import { Dayjs } from 'dayjs';

import { Link, useNavigate } from 'react-router-dom';
import {
  requestAdminOneTimeImageUpload,
  uploadFile,
  useCreateEvent,
} from '@seaters-app/data-access';

import {
  EventStatus,
  EventValidatorSchema,
  FormTranslationType,
  VenueConfigEntity,
  VenueEntity,
} from '@seaters-app/constants';
import { EventForm } from '../EventForm';
import { prepareValues } from '../EventForm/helpers/prepareValues';
import { initialTranslations } from '../../../utils/helpers/getTranslations';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AttendeeRequiredFieldsType } from 'libs/constants/types';

const { Title } = Typography;

export interface EventCreateFormType {
  name: FormTranslationType;
  shortName: FormTranslationType;
  description: FormTranslationType;
  slug: string;
  status: EventStatus;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  venueConfigId: string;
  imageUrl: string;
  attendeeRequiredFields?: AttendeeRequiredFieldsType[];
}

export type VenueFieldType = {
  venue: VenueEntity;
  config: VenueConfigEntity;
};

const initialValues: EventCreateFormType = {
  startDate: null,
  endDate: null,
  name: initialTranslations,
  shortName: initialTranslations,
  description: initialTranslations,
  slug: '',
  status: EventStatus.PUBLISHED,
  venueConfigId: '',
  imageUrl: '',
};

export function CreateEvent() {
  const navigation = useNavigate();

  const [spinning, setSpinning] = useState<boolean>(false);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);

  const { t } = useTranslation();
  const { mutate: createEvent } = useCreateEvent();

  const onSubmit = (values: EventCreateFormType) => {
    setSpinning(true);

    const preparedValues = prepareValues(values);
    const imageUrlOrCanvas = values?.imageUrl as
      | string
      | HTMLCanvasElement
      | undefined;

    const parsed = EventValidatorSchema.safeParse(preparedValues);
    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    createEvent(parsed.data, {
      onSuccess: async (response) => {
        if (imageUrlOrCanvas && typeof imageUrlOrCanvas !== 'string') {
          setUploadingImage(true);

          await requestAdminOneTimeImageUpload({
            id: response.id,
            fileName: 'file.png',
            entityType: 'events',
          }).then(async (requestOneTimeUploadResponse) => {
            if (requestOneTimeUploadResponse.token) {
              const formFile = new FormData();
              const blob = (await new Promise((resolve) =>
                imageUrlOrCanvas?.toBlob(resolve)
              )) as Blob;
              formFile.append('file', blob);
              await uploadFile(
                formFile,
                requestOneTimeUploadResponse.token
              ).then(() => setUploadingImage(false));
            }
          });
        }
        notification.success({
          message: t('notification_success_event_created'),
        });
        navigation(`..`);
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: t('notification_error_event_created'),
        });
      },
      onSettled: () => {
        setSpinning(false);
      },
    });
  };

  const loading = spinning || uploadingImage;
  return (
    <>
      <Spin tip={t('please_wait_message_text')} fullscreen spinning={loading} />
      <Flex
        vertical
        style={{
          padding: '24px 24px 80px',
        }}
        gap={12}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="..">{t('admin_events')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('cwl_event_create-btn')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Title level={3}>{t('cwl_event_create-btn')}</Title>
        <EventForm initialValues={initialValues} saveChanges={onSubmit} />
      </Flex>
    </>
  );
}

export const formatLocation = (locationArray: string[]): string => {
  const fullLocation: string[] = [];

  locationArray.forEach((location) => {
    if (location) {
      fullLocation.push(location);
    }
  });
  return fullLocation.join(', ');
};

import {
  CustomInfoValidator,
  endpoints,
  GroupGuestsListEntity,
  GroupGuestsQuery,
  GuestEntity,
  GuestValidator,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchGroupGuests = (
  groupId?: string,
  params?: GroupGuestsQuery
): Promise<GroupGuestsListEntity> =>
  api.put(
    `v2/host/${endpoints.groups}/${groupId}/${endpoints.guestsMy}?size=${params?.size}&page=${params?.page}&sort=${params?.sort}`,
    { query: params?.search }
  );

export const createGuest = (
  groupId?: string,
  body?: GuestValidator
): Promise<GuestEntity> =>
  api.post(
    `v2/host/${endpoints.groups}/${groupId}/${endpoints.guestsMy}`,
    body
  );

export const updateGuest = (
  groupId: string,
  guestId: string,
  body: GuestValidator
): Promise<GuestEntity> =>
  api.put(
    `v2/host/${endpoints.groups}/${groupId}/${endpoints.guests}/${guestId}`,
    body
  );

export const createGuestCustomInfo = (
  groupId: string,
  guestId: string,
  body?: CustomInfoValidator
): Promise<GuestEntity> =>
  api.post(
    `v2/host/${endpoints.groups}/${groupId}/${endpoints.guests}/${guestId}/${endpoints.customInfo}`,
    body
  );

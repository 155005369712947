import { useEffect, useState } from 'react';
import { CopyOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import {
  Avatar,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  TablePaginationConfig,
  Tooltip,
  notification,
  theme,
} from 'antd';
import {
  BadgeEntity,
  ProtectionCodeCustomInfo,
  ProtectionCodeEnhancedEntity,
} from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import {
  getSingleTranslation,
  useLinkFanGroupProtectionCodeToCustomInfo,
  useLinkFanGroupProtectionCodeToBadge,
  useUnlinkFanGroupProtectionCodeBadge,
  useFetchFanGroupCustomInfo,
  useUnlinkFanGroupProtectionCodeCustomInfo,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { useParams } from 'react-router-dom';
import { useFGBadges } from '../../[id]/badges/useFGBadges';
import ManageBadgesModal from './ManageBadgesModal';
import ManageCustomInfoModal, {
  AccessCodeCustomInfoForm,
} from './ManageCustomInfoModal';
import Search from 'antd/es/input/Search';

type CodesTableProps = {
  codesData?: ProtectionCodeEnhancedEntity[];
  pagination: false | TablePaginationConfig | undefined;
  handleTableChange: ({ pageSize, current }: TablePaginationConfig) => void;
  isLoading: boolean;
  copyCode: (id: number) => void;
  handleDeleteCode: (id: string) => void;
  isRemoving: boolean;
  query: any;
  setQuery: (v: any) => void;
};
const { Column } = Table;

export const CodesTable = ({
  codesData,
  pagination,
  handleTableChange,
  isLoading,
  copyCode,
  handleDeleteCode,
  isRemoving,
  query,
  setQuery,
}: CodesTableProps) => {
  const [linkCustomInfoModalOpen, setLinkCustomInfoModalOpen] = useState(false);
  const [linkBadgeModalOpen, setLinkBadgeModalOpen] = useState(false);
  const [codeToLink, setCodeToLink] =
    useState<ProtectionCodeEnhancedEntity | null>(null);
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { fanGroupId = '' } = useParams();
  const { fanGroup: FGOgroupData } = useFanGroupStore();

  const fgID = fanGroupId ? fanGroupId : FGOgroupData?.id ?? '';

  const { mutate: linkCustomInfoToCode } =
    useLinkFanGroupProtectionCodeToCustomInfo(fgID);

  const { mutate: unlinkCustomInfoCode } =
    useUnlinkFanGroupProtectionCodeCustomInfo(fgID);

  const { mutate: linkBadgeToCode, isLoading: isBadgeLinking } =
    useLinkFanGroupProtectionCodeToBadge(fgID);

  const { mutate: unlinkBadgeCode, isLoading: isBadgeUnLinking } =
    useUnlinkFanGroupProtectionCodeBadge(fgID);

  const { badges: fanGroupBadges } = useFGBadges(fgID);

  const badgesOptions = fanGroupBadges?.map((badge) => ({
    label: getSingleTranslation(badge.name),
    value: badge.id,
    avatar: badge.displayedLogoUrl,
  }));

  const { data: fgCustomInfo } = useFetchFanGroupCustomInfo(fgID, {
    size: 100,
    page: 0,
  });

  const fgCustomInfosOptions = fgCustomInfo?.content.map((customInfo) => ({
    label: customInfo.semanticName,
    value: customInfo.id,
  }));

  const handleLinkCustomInfoToCode = (values: AccessCodeCustomInfoForm) => {
    const { accessCodeCustomInfos } = values;
    const accessCodeCustomInfosSemanticNames = accessCodeCustomInfos
      ? accessCodeCustomInfos?.map((customInfo) => customInfo.semanticName)
      : [];

    const customInfoToUnlink = codeToLink?.customInfos.filter((customInfo) => {
      return !accessCodeCustomInfosSemanticNames.includes(
        customInfo.semanticName
      );
    });

    const customInfoToLink = accessCodeCustomInfos?.filter((customInfo) => {
      return !codeToLink?.customInfos.find(
        (existedCustomInfo) =>
          existedCustomInfo.semanticName === customInfo.semanticName
      );
    });

    const customInfoToUpdate = accessCodeCustomInfos?.filter((customInfo) => {
      return codeToLink?.customInfos.find(
        (existedCustomInfo) =>
          existedCustomInfo.semanticName === customInfo.semanticName &&
          existedCustomInfo.informationValue !== customInfo.informationValue
      );
    });
    if (!codeToLink) return;
    if (customInfoToLink) {
      customInfoToLink.forEach((customInfo) => {
        linkCustomInfoToCode(
          {
            code: codeToLink?.code,
            body: {
              fgCustomInformationId: customInfo.fgCustomInformationId,
              informationValue: customInfo.informationValue,
              semanticName: customInfo.semanticName,
            },
          },
          {
            onSuccess: () => {
              setLinkCustomInfoModalOpen(false);
              notification.success({
                message: t('notification_success_custom_info_linked'),
              });
            },
            onError: (error) => {
              notification.error({
                message: t('notification_error_custom_info_linked'),
                description: error.message,
              });
            },
          }
        );
      });
    }

    if (customInfoToUnlink) {
      customInfoToUnlink?.forEach((customInfo) => {
        unlinkCustomInfoCode(
          {
            code: codeToLink?.code,
            semanticName: customInfo.semanticName,
          },
          {
            onSuccess: () => {
              notification.success({
                message: t('notification_success_custom_info_unlinked'),
              });
            },
            onError: (error) => {
              notification.error({
                message: t('notification_error_custom_info_unlinked'),
              });
            },
          }
        );
      });
    }

    if (customInfoToUpdate) {
      customInfoToUpdate?.forEach((customInfo) => {
        unlinkCustomInfoCode(
          {
            code: codeToLink?.code,
            semanticName: customInfo.semanticName,
          },
          {
            onSuccess: () => {
              setLinkCustomInfoModalOpen(false);
              linkCustomInfoToCode(
                {
                  code: codeToLink?.code,
                  body: {
                    fgCustomInformationId: customInfo.fgCustomInformationId,
                    informationValue: customInfo.informationValue,
                    semanticName: customInfo.semanticName,
                  },
                },
                {
                  onSuccess: () => {
                    notification.success({
                      message: t('notification_success_custom_info_updated'),
                    });
                  },
                }
              );
            },
          }
        );
      });
    }
  };

  const handleUnlinkCustomInfo = (semanticName: string) => {
    if (codeToLink) {
      unlinkCustomInfoCode(
        {
          code: codeToLink?.code,
          semanticName: semanticName,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_custom_info_unlinked'),
            });
          },
          onError: (error) => {
            notification.error({
              message: t('notification_error_custom_info_unlinked'),
            });
          },
        }
      );
    }
  };

  const handleLinkBadgeToCode = (values: { badgeIds: string[] }) => {
    const { badgeIds } = values;

    const badgesToUnlink = codeToLink?.badges.filter((badge) => {
      return !badgeIds.includes(badge.id);
    });

    const badgesToLink = badgeIds?.filter((badgeId) => {
      return !codeToLink?.badges.find(
        (existedBadge) => badgeId === existedBadge.id
      );
    });

    if (!codeToLink) return;
    if (badgesToLink) {
      badgesToLink.forEach((badgeId) => {
        linkBadgeToCode(
          {
            code: codeToLink?.code,
            badgeId,
          },
          {
            onSuccess: () => {
              setLinkBadgeModalOpen(false);
              notification.success({
                message: t('notification_success_badge_linked'),
              });
            },
            onError: (error) => {
              notification.error({
                message: t('notification_error_badge_linked'),
                description: error.message,
              });
            },
          }
        );
      });
    }

    if (badgesToUnlink) {
      badgesToUnlink?.forEach(({ id }) => {
        unlinkBadgeCode(
          {
            code: codeToLink?.code,
            badgeId: id,
          },
          {
            onSuccess: () => {
              setLinkBadgeModalOpen(false);
              notification.success({
                message: t('notification_success_badge_unlinked'),
              });
            },
            onError: (error) => {
              notification.error({
                message: t('notification_error_badge_unlinked'),
              });
            },
          }
        );
      });
    }
  };

  const handleMenuOnClick = (key: string) => {
    switch (key) {
      case 'BADGE':
        setLinkBadgeModalOpen(true);
        break;
      case 'CUSTOM_INFO':
        setLinkCustomInfoModalOpen(true);
        break;

      default:
        break;
    }
  };

  const items = [
    {
      label: t('badge_title'),
      key: 'BADGE',
    },
    {
      label: t('fan_tabs_custom_info'),
      key: 'CUSTOM_INFO',
    },
  ];

  const onSearch = (value: string) => {
    setQuery({ query: value, itemOffset: 0 });
  };

  useEffect(() => {
    setCodeToLink(
      codesData?.find(
        (codesDataItem) => codesDataItem.code === codeToLink?.code
      ) ?? null
    );
  }, [codesData]);

  return (
    <>
      <Table
        rowKey={(code) => {
          return code.code;
        }}
        dataSource={codesData}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
      >
        <Column
          title={t('admin_fan-group-protection-codes_code')}
          dataIndex="code"
          key="code"
          width="12%"
          render={(code, _, i) => {
            return (
              <Space size={8}>
                <span>{code}</span>
                <CopyOutlined onClick={() => copyCode(i)} rev={undefined} />
              </Space>
            );
          }}
        />
        <Column
          title={t('admin_fan-group-protection-codes_max-times-used')}
          dataIndex="maxTimesUsed"
          key="maxTimesUsed"
          width="15%"
          render={(maxTimesUsed = '–') => {
            return <span>{maxTimesUsed}</span>;
          }}
        />
        <Column
          title={t('admin_fan-group-protection-codes_times-used')}
          dataIndex="timesUsed"
          width="10%"
          key="timesUsed"
          render={(timesUsed) => {
            return <span>{timesUsed ?? '-'}</span>;
          }}
        />
        <Column
          title={t('admin_badges')}
          dataIndex="badges"
          key="badges"
          width="15%"
          render={(badges) => {
            return (
              <Space size={16} align="start">
                {!!badges.length && (
                  <Avatar.Group
                    maxCount={3}
                    maxStyle={{
                      color: token.colorPrimaryText,
                      backgroundColor: token.colorPrimaryBg,
                    }}
                  >
                    {badges?.map((badge: BadgeEntity) => (
                      <Tooltip
                        title={getSingleTranslation(badge.name)}
                        placement="top"
                      >
                        <Avatar size={40} src={badge.displayedLogoUrl} />
                      </Tooltip>
                    ))}
                  </Avatar.Group>
                )}
              </Space>
            );
          }}
        />
        <Column
          title={t('fan_tabs_custom_info')}
          dataIndex="customInfos"
          key="customInfos"
          width="25%"
          render={(customInfos, code: ProtectionCodeEnhancedEntity) => {
            return (
              <Space direction="vertical">
                {customInfos?.map((customInfo: ProtectionCodeCustomInfo) => (
                  <span>
                    {customInfo.semanticName}: {customInfo.informationValue}
                  </span>
                ))}
              </Space>
            );
          }}
        />
        <Column
          title={
            <Search
              defaultValue={query.search}
              placeholder={t('event_venue_search-btn')}
              onSearch={onSearch}
            />
          }
          key="actions"
          align="right"
          width="30%"
          render={(_, code: ProtectionCodeEnhancedEntity, i) => {
            return (
              <>
                <Dropdown
                  menu={{
                    items: items,
                    onClick: ({ key }) => {
                      setCodeToLink(code);
                      handleMenuOnClick(key);
                    },
                  }}
                >
                  <Button type="text" icon={<DownOutlined rev={undefined} />}>
                    {t('wl_manage-btn')}
                  </Button>
                </Dropdown>
                <Popconfirm
                  title={t('delete_code_button_text')}
                  description={t('delete_code_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() => {
                    handleDeleteCode(code.code);
                  }}
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    loading={isRemoving}
                    type="text"
                    color={token.colorError}
                    icon={<DeleteOutlined rev={undefined} />}
                  >
                    {t('wl_delete-btn')}
                  </Button>
                </Popconfirm>
              </>
            );
          }}
        />
      </Table>
      {linkCustomInfoModalOpen && (
        <ManageCustomInfoModal
          isOpen={linkCustomInfoModalOpen}
          setOpen={setLinkCustomInfoModalOpen}
          handleLinkCustomInfoToCode={handleLinkCustomInfoToCode}
          fgCustomInfosOptions={fgCustomInfosOptions}
          customInfos={codeToLink?.customInfos}
          handleUnlinkCustomInfo={handleUnlinkCustomInfo}
        />
      )}
      {linkBadgeModalOpen && (
        <ManageBadgesModal
          isOpen={linkBadgeModalOpen}
          setOpen={setLinkBadgeModalOpen}
          handleLinkBadgeToCode={handleLinkBadgeToCode}
          codeToLink={codeToLink}
          isLoading={isBadgeLinking || isBadgeUnLinking}
          badgesOptions={badgesOptions}
        />
      )}
    </>
  );
};

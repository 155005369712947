export enum UserStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
}
export enum UserRole {
  GUEST = 'GUEST',
  FAN = 'FAN',
  APPROVER = 'APPROVER',
  HOST = 'HOST',
  ADMIN = 'ADMIN',
  TRANSLATOR = 'TRANSLATOR',
  FAN_GROUP_OWNER = 'FAN_GROUP_OWNER',
  FAN_GROUP_DEVELOPER = 'FAN_GROUP_DEVELOPER',
}

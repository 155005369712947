import React from 'react';
import { routes } from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import { Result } from 'antd';
import { isRouteErrorResponse, useRouteError, NavLink } from 'react-router-dom';
import { getSlugFromUrl } from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';

export function ErrorPage() {
  const error = useRouteError();
  const slug = getSlugFromUrl();
  const { t } = useTranslation();
  return (
    <Result
      status="warning"
      title={t('error_other_info')}
      subTitle={
        isRouteErrorResponse(error) && (
          <div>{error.statusText ?? error.error?.message}</div>
        )
      }
      extra={
        <NavLink to={`${routes.root}${slug ?? routes.admin}`}>
          <Button type="primary">{t('back_to_homePage')}</Button>
        </NavLink>
      }
    />
  );
}

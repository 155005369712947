import { useParams } from 'react-router-dom';
import { useFGOWishListPositionsDetails } from '@seaters-app/data-access';

import { Space } from 'antd';
import { NumberItem } from './NumberItem';
import { useTranslation } from 'react-i18next';

export function PositionsDetails() {
  const { wishListId = '' } = useParams();
  const { t } = useTranslation();

  const { data: positionDetails } = useFGOWishListPositionsDetails(wishListId);

  if (!positionDetails) return null;

  return (
    <Space size="middle">
      <NumberItem
        maxWidth={80}
        tooltip="positions_details_fans_waiting_seat-tooltip"
        label={t('positions_details_fans_waiting_seat')} // Fans in wish list
        value={positionDetails.fansWaitingSeat}
      />
      <NumberItem
        maxWidth={80}
        tooltip="positions_details_seats_waiting_seat-tooltip"
        label={t('positions_details_seats_waiting_seat')} // Seats in wish list
        value={positionDetails.seatsWaitingSeat}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="positions_details_fans_declined-tooltip"
        label={t('positions_details_fans_declined')} // Declined positions
        value={positionDetails.fansDeclined}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="positions_details_tickets_declined-tooltip"
        label={t('positions_details_tickets_declined')} // Declined tickets
        value={positionDetails.seatsDeclined}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="positions_details_actual_fans-tooltip"
        label={t('positions_details_actual_fans')} // Actual fans
        value={positionDetails.fansWaitingSeat - positionDetails.fansDeclined}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="positions_details_actual_tickets-tooltip"
        label={t('positions_details_actual_tickets')} // Actual tickets
        value={positionDetails.seatsWaitingSeat - positionDetails.seatsDeclined}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="positions_details_distributed_positions-tooltip"
        label={t('positions_details_distributed_positions')} // Distributed positions
        value={positionDetails.fansHasSeat}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="positions_details_distributed_tickets-tooltip"
        label={t('positions_details_distributed_tickets')} // Distributed tickets
        value={positionDetails.seatsHasSeat}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="positions_details_total_fans-tooltip"
        label={t('positions_details_total_fans')} // Total fans
        value={positionDetails.fansWaitingSeat + positionDetails.fansHasSeat}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="positions_details_total_tickets-tooltip"
        label={t('positions_details_total_tickets')} // Total tickets
        value={positionDetails.seatsWaitingSeat + positionDetails.seatsHasSeat}
      />
    </Space>
  );
}

import dayjs from 'dayjs';
import { ConfigProvider, Space, Tooltip, Typography, theme } from 'antd';
import {
  ArrowRightOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { FanGroupOwnerEventEntity } from '@seaters-app/constants';
import {
  Button,
  baseColor200,
  baseColor600,
  baseColor900,
  primaryColor,
} from '@seaters-app/ui';
import imagePlaceholder from '../../../../../public/imagePlaceholder.svg';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

function EventCard({
  event,
  handleShowEventModal,
  handleSelectEvent,
  changeEvent,
  changingEvent,
  isSelected,
}: {
  event: FanGroupOwnerEventEntity;
  handleShowEventModal: (event: FanGroupOwnerEventEntity) => void;
  handleSelectEvent?: (eventId: string) => void;
  changeEvent?: () => void;
  changingEvent?: boolean;
  isSelected?: boolean;
}) {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const { eventImageUrl, venueImageUrl } = event;

  const eventImg = eventImageUrl || venueImageUrl;

  return (
    <div className={styles.card}>
      <div
        className={styles.imageWrap}
        style={{ backgroundColor: baseColor200 }}
      >
        {eventImg ? (
          <img className={styles.image} alt={event?.eventName} src={eventImg} />
        ) : (
          <img
            className={styles.imagePlaceholder}
            alt={event?.eventName}
            src={imagePlaceholder}
          />
        )}
      </div>
      <Space
        direction="horizontal"
        style={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Space direction="vertical" size={2}>
          <Text strong style={{ color: baseColor900 }}>
            {event?.eventName}{' '}
            {event.attendeeRequiredFields ? (
              <Tooltip title={t('wl_event_requiredinfo-help')}>
                <InfoCircleOutlined rev={undefined} />
              </Tooltip>
            ) : null}
          </Text>
          <Text style={{ color: baseColor900 }}>{event?.venueName}</Text>
          <Text style={{ color: baseColor600 }}>
            {dayjs(event?.startDate).format('DD MMM h:mm A')}
          </Text>
        </Space>
        <Space direction="horizontal">
          <ConfigProvider
            theme={{
              token: {
                colorText: primaryColor,
              },
            }}
          >
            <Button
              type="text"
              color={token.colorPrimary}
              icon={<EyeOutlined rev={undefined} />}
              onClick={() => (event ? handleShowEventModal(event) : undefined)}
            >
              {t('show_description_button')}
            </Button>
          </ConfigProvider>

          {!isSelected && (
            <Button
              color={baseColor600}
              data-testid="selectEventTestId"
              onClick={() =>
                handleSelectEvent && handleSelectEvent(event?.eventId || '')
              }
            >
              Select
              <ArrowRightOutlined rev={undefined} />
            </Button>
          )}
        </Space>
      </Space>
    </div>
  );
}

export default EventCard;

import {
  ApproverRequestValidator,
  ApproverRequestsEntity,
  AxiosAPIError,
  ListEntityV2,
  PaginationQueryV2,
  fanGroupOwnerWaitingListsKeys,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  approveFGOWaitingListApproverRequest,
  fetchFGOWaitingListApproverRequests,
  rejectFGOWaitingListApproverRequest,
  updateFGOWaitingListApproverRequest,
} from '../api';

export const useFetchFGOWaitingListApproverRequests = (
  waitingListId: string,
  params: PaginationQueryV2
) =>
  useQuery<ListEntityV2<ApproverRequestsEntity>, Error>(
    fanGroupOwnerWaitingListsKeys.approverRequests(waitingListId),
    () => fetchFGOWaitingListApproverRequests(waitingListId, params),
    {
      keepPreviousData: true,
    }
  );

type UpdateApproverRequestQuery = {
  waitingListId: string;
  requestId: string;
};

export const useUpdateApproverRequest = (): UseMutationResult<
  ApproverRequestsEntity,
  AxiosAPIError<
    UpdateApproverRequestQuery & { body: ApproverRequestValidator }
  >,
  UpdateApproverRequestQuery & { body: ApproverRequestValidator },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      waitingListId,
      requestId,
      body,
    }: UpdateApproverRequestQuery & { body: ApproverRequestValidator }) =>
      updateFGOWaitingListApproverRequest(waitingListId, requestId, body),
    {
      onSuccess: (_, { waitingListId }) => {
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.approverRequests(waitingListId)
        );
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(waitingListId)
        );
      },
    }
  );
};

export const useRejectApproverRequest = (): UseMutationResult<
  ApproverRequestsEntity,
  AxiosAPIError<UpdateApproverRequestQuery>,
  UpdateApproverRequestQuery,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ waitingListId, requestId }: UpdateApproverRequestQuery) =>
      rejectFGOWaitingListApproverRequest(waitingListId, requestId),
    {
      onSuccess: (_, { waitingListId }) => {
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.approverRequests(waitingListId)
        );
      },
    }
  );
};

export const useApproveApproverRequest = (): UseMutationResult<
  ApproverRequestsEntity,
  AxiosAPIError<UpdateApproverRequestQuery>,
  UpdateApproverRequestQuery,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ waitingListId, requestId }: UpdateApproverRequestQuery) =>
      approveFGOWaitingListApproverRequest(waitingListId, requestId),
    {
      onSuccess: (_, { waitingListId }) => {
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.approverRequests(waitingListId)
        );
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(waitingListId)
        );
      },
    }
  );
};

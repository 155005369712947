import React from 'react';
import { Breadcrumb, Flex } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchAdminSurvey } from '@seaters-app/data-access';

import GeneralSurveyInfoStep from '../SurveyCreate/steps/GeneralSurveyInfoStep';
import SurveyQuestions from '../SurveyCreate/steps/SurveyQuestions';
import Title from 'antd/lib/typography/Title';
import SurveyUsageList from '../../tabs/SurveyUsageList';

function SurveyDetails() {
  const { t } = useTranslation();
  const { questionnaireId = '' } = useParams();
  const { data: survey } = useFetchAdminSurvey(questionnaireId);

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="..">{t('admin_surveys_surveys')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{t('survey_details_title')}</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{survey?.name}</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <GeneralSurveyInfoStep isEdit />
      <Title level={5}>{t('survey_creation_questions_step')}:</Title>
      <SurveyQuestions footer={false} />
      <Title style={{ margin: '24px 0 16px' }} level={5}>
        {t('survey_usage_label')}:
      </Title>
      <SurveyUsageList />
    </Flex>
  );
}

export default SurveyDetails;

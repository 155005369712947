export const SESSION_STORAGE_VERIFICATION = 'verification';
export const SESSION_STORAGE_MODE = 'MODE';
export const SESSION_STORAGE_SURVEY_SHOWN = 'wasSurveyShown';
export const SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED = 'privacyPolicy';
export const SESSION_STORAGE_TOTAL_TICKETS = 'totalTickets';
export const OLD_LOCAL_STORAGE_VERIFICATION = 'session.CURRENT_V3';
export const LOCAL_STORAGE_WAITING_LIST_ID = 'wlID';
export const LOCAL_STORAGE_USER_LOCALE = 'app.user-locale';
export const LOCAL_STORAGE_SLUG = 'current_fangroup_slug';
export const SESSION_STORAGE_EMAIL_REMINDER = 'email_reminder';
export const SESSION_STORAGE_TARGET_APP = 'target_app';
export const SEATERS_COOKIES_ACCEPTED = 'SEATERS_COOKIES_ACCEPTED';
export const SESSION_STORAGE_JOB_ID = 'jobId';
export const LOCAL_STORAGE_STRIPE_DEEP_LINK = 'stripeDeepLink';
export const SESSION_STORAGE_ALLOWED_GROUPS = 'allowedGroups';

export const LOCATION_STATES = {
  REDIRECT_PATH: 'redirectPath',
} as const;

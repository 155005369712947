import {
  ListEntityV2,
  WaitingListWithApprovalQuery,
  WaitingListWithApproval,
  waitingListsWithApprovalKeys,
  waitingListInvitationsToApproveKeys,
  InvitationForApproverEntity,
  AxiosAPIError,
  ApproverRequest,
  waitingListsApproverRequestsKeys,
  FanGroupEntity,
  fanGroupsKeys,
  ReportEntity,
  ReportType,
  ApproverRequestBody,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  approveInvitation,
  createWaitingListsApproverRequest,
  fetchApproverFanGroups,
  fetchWaitingListWithApproval,
  fetchWaitingListsApproverRequest,
  fetchWaitingListsInvitationsToApprove,
  fetchWaitingListsWithApproval,
  rejectInvitation,
  updateWaitingListsApproverRequest,
  updateWaitingListsInvitationsToApprove,
  exportApproverReport,
} from '../api/approver';

export const useFetchApproverFanGroups = (isApprover?: boolean) =>
  useQuery<ListEntityV2<FanGroupEntity>, Error>(
    fanGroupsKeys.approver(),
    () => fetchApproverFanGroups(),
    {
      enabled: !!isApprover,
      keepPreviousData: true,
    }
  );

export const useFetchWaitingListsWithApproval = (
  id: string,
  params: WaitingListWithApprovalQuery
) =>
  useQuery<ListEntityV2<WaitingListWithApproval>, Error>(
    waitingListsWithApprovalKeys.list(id, params),
    () => fetchWaitingListsWithApproval(id, params),
    {
      enabled: !!id,
      keepPreviousData: true,
    }
  );

export const useFetchWaitingListWithApproval = (
  id: string,
  params: WaitingListWithApprovalQuery
) =>
  useQuery<WaitingListWithApproval, Error>(
    waitingListsWithApprovalKeys.detail(id),
    () => fetchWaitingListWithApproval(id, params)
  );

export const useFetchWaitingListsInvitationsToApprove = (
  id: string,
  params: WaitingListWithApprovalQuery
) =>
  useQuery<ListEntityV2<InvitationForApproverEntity>, Error>(
    waitingListInvitationsToApproveKeys.list(id, params),
    () => fetchWaitingListsInvitationsToApprove(id, params),
    {
      enabled: !!id,
      keepPreviousData: true,
    }
  );

export const useUpdateWaitingListsInvitationsToApprove = ({
  waitingListId,
}: {
  waitingListId?: string;
}): UseMutationResult<
  InvitationForApproverEntity,
  AxiosAPIError<{ id: string; body: { numberOfToAllocateSeats: number } }>,
  { id: string; body: { numberOfToAllocateSeats: number } },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, body }: { id: string; body: { numberOfToAllocateSeats: number } }) =>
      updateWaitingListsInvitationsToApprove(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsToApproveKeys.mutation()
        );

        if (waitingListId) {
          queryClient.invalidateQueries(
            waitingListInvitationsToApproveKeys.list(waitingListId, {
              toallocate: true,
            })
          );
          queryClient.invalidateQueries(
            waitingListsWithApprovalKeys.detail(waitingListId)
          );
        }
      },
    }
  );
};

//Currently not used, but let it be here

// export const useApproveNonAllocatedHostSeats = (): UseMutationResult<
//   InvitationForApprove,
//   AxiosAPIError<{ id: string }>,
//   { id: string },
//   unknown
// > => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     ({ id }: { id: string }) => approveNonAllocatedHostSeats(id),
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries(
//           waitingListInvitationsToApproveKeys.mutation()
//         );
//       },
//     }
//   );
// };
// export const updateNonAllocatedHostSeats = (
//   invitationId: string
//   // body: { nbrOfRequestedSeats: number }
// ): Promise<any> =>
//   api.post(
//     `v2/${endpoints.approver}/${endpoints.invitations}/${invitationId}/approve-non-allocated`
//   );

export const useCreateWaitingListsApproverRequest = (
  id: string
): UseMutationResult<
  ApproverRequest,
  AxiosAPIError<ApproverRequestBody>,
  ApproverRequestBody,
  string
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: ApproverRequestBody) => createWaitingListsApproverRequest(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListsApproverRequestsKeys.lists(id)
        );
        queryClient.invalidateQueries(waitingListsWithApprovalKeys.detail(id));
      },
    }
  );
};

export const useFetchWaitingListsApproverRequest = (
  id: string,
  isEnabled: boolean
) =>
  useQuery<ApproverRequest, Error>(
    waitingListsApproverRequestsKeys.lists(id),
    () => fetchWaitingListsApproverRequest(id),
    {
      enabled: !!id && isEnabled,
      keepPreviousData: true,
    }
  );

export const useUpdateWaitingListsApproverRequest = (
  waitingListId: string,
  requestId: string
): UseMutationResult<
  ApproverRequest,
  AxiosAPIError<{
    waitingListId: string;
    requestId: string;
    body: ApproverRequestBody;
  }>,
  {
    body: ApproverRequestBody;
  },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ body }: { body: ApproverRequestBody }) =>
      updateWaitingListsApproverRequest(waitingListId, requestId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListsApproverRequestsKeys.lists(waitingListId)
        );
        queryClient.invalidateQueries(
          waitingListsWithApprovalKeys.detail(waitingListId)
        );
      },
    }
  );
};

export const useApproveInvitationsToApprove = (
  waitingListId: string,
  params: any
): UseMutationResult<
  InvitationForApproverEntity,
  AxiosAPIError<{ invitationId: string }>,
  { invitationId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ invitationId }: { invitationId: string }) =>
      approveInvitation(invitationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsToApproveKeys.list(waitingListId, params)
        );
        queryClient.invalidateQueries(
          waitingListsWithApprovalKeys.detail(waitingListId)
        );
        queryClient.invalidateQueries(
          waitingListInvitationsToApproveKeys.mutation()
        );
      },
    }
  );
};

export const useRejectInvitationsToApprove = (): UseMutationResult<
  InvitationForApproverEntity,
  AxiosAPIError<{
    invitationId: string;
    body?: { rejectionReason: string };
  }>,
  {
    invitationId: string;
    body?: { rejectionReason: string };
  },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      invitationId,
      body,
    }: {
      invitationId: string;
      body?: { rejectionReason: string };
    }) => rejectInvitation(invitationId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsToApproveKeys.mutation()
        );
        queryClient.invalidateQueries(
          waitingListInvitationsToApproveKeys.all()
        );
      },
    }
  );
};

export const useGenerateApproverReport = (
  fgId: string
): UseMutationResult<
  ReportEntity,
  AxiosAPIError<{ reportName: ReportType }>,
  { reportName: ReportType },
  unknown
> => {
  return useMutation(({ reportName }: { reportName: ReportType }) =>
    exportApproverReport(fgId, reportName)
  );
};

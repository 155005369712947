import {
  DateFilter,
  FilterName,
  SessionTypeFilter,
  TicketCategoryFilter,
} from '@seaters-app/constants';
import {
  getObjectTranslation,
  useFanGroupStore,
  useFetchFanGroupOwnerWaitingList,
  useFetchLook,
  useUpdateWaitingListFilters,
} from '@seaters-app/data-access';
import { CloseOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Input,
  Select,
  Typography,
  notification,
  Button,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import { baseColor900 } from '@seaters-app/ui';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

interface FieldProps {
  field: ControllerRenderProps<
    Partial<Record<FilterName, string>>,
    | 'searchableText'
    | 'date'
    | 'sessionType'
    | 'ticketCategory'
    | 'hasAvailableTicketsOnly'
  >;
}

interface SelectInputProps extends FieldProps {
  filter: TicketCategoryFilter | SessionTypeFilter;
}

const SelectInput: React.FC<SelectInputProps> = ({ filter, field }) => {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const selectOptions = filter.values.map((option) => {
    return {
      label: getObjectTranslation(option.label, lang),
      value: option.value,
    };
  });
  return <Select {...field} options={selectOptions} />;
};

interface DatePickerInputProps extends FieldProps {
  filter: DateFilter;
}

const DatePickerInput: React.FC<DatePickerInputProps> = ({ field }) => {
  return (
    <DatePicker {...field} value={field.value ? dayjs(field.value) : null} />
  );
};

interface FiltersProps {
  isEdit: boolean;
  // Define the props for the Filters component here
}
const Filters: React.FC<FiltersProps> = ({ isEdit }) => {
  const { wishListId = '' } = useParams();

  const { next, prev } = useCurrentStep();

  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const { fanGroup } = useFanGroupStore();

  const { data: fanGroupData } = useFetchLook(fanGroup?.slug ?? '');

  const { data: wishList } = useFetchFanGroupOwnerWaitingList(wishListId);

  const { mutate: updateWishListFilters, isLoading: isUpdating } =
    useUpdateWaitingListFilters(wishListId);

  const wishListFilters = wishList?.filters;

  const methods = useForm<Partial<Record<FilterName, string>>>({
    defaultValues: wishListFilters,
    values: wishListFilters,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const fanGroupFilters = fanGroupData?.properties.filters?.filter(
    (el) => el.type !== 'toggle'
  );

  const onSubmit = (data: Partial<Record<FilterName, string>>) => {
    const dataCopy = { ...data };

    if (data.date) {
      dataCopy.date = dayjs(data.date).format('YYYY-MM-DD');
    }

    const keys = Object.keys(dataCopy) as FilterName[];

    const notEmptyObject = keys
      .filter((key) => dataCopy[key]?.length)
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: dataCopy[key],
        });
      }, {});

    if (Object.keys(notEmptyObject).length > 0) {
      updateWishListFilters(
        {
          filters: notEmptyObject,
        },
        {
          onSuccess: () => {
            next();
            notification.success({
              message: t('notification_success_wl_updated'),
            });
          },
        }
      );
    } else {
      next();
    }
  };

  return (
    <Form
      layout="vertical"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      name="filters_config"
      style={{ maxWidth: 600 }}
      autoComplete="off"
      onFinish={handleSubmit(onSubmit)}
      disabled={isUpdating}
    >
      <Paragraph strong style={{ color: baseColor900, marginBottom: 24 }}>
        {t('general_filter_parameters')}
      </Paragraph>
      {fanGroupFilters?.map((filter) => {
        const label = getObjectTranslation(filter.label, lang);
        return (
          <Form.Item label={label} name={`${filter.name}`}>
            <Controller
              control={control}
              name={`${filter.name}`}
              render={({ field }) => {
                return filter.type === 'text' ? (
                  <SelectInput filter={filter} field={field} />
                ) : filter.type === 'date' || filter.type === 'date-picker' ? (
                  <DatePickerInput filter={filter} field={field} />
                ) : (
                  <Input {...field} />
                );
              }}
            />
          </Form.Item>
        );
      })}
      <Footer
        handlePrevStep={prev}
        cancelButton={
          <Button
            size="middle"
            type="default"
            danger
            disabled={!isDirty}
            icon={<CloseOutlined rev={undefined} />}
            onClick={() => reset(wishListFilters)}
          >
            {t('button_text_cancel')}
          </Button>
        }
        isEdit={isEdit}
        isLoading={isUpdating}
      />
    </Form>
  );
};

export default Filters;

import { TicketingSystem } from '@seaters-app/constants';
import { useFetchTicketingSystems } from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import { Flex, Table, Tag } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import Title from 'antd/es/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import TableActions from './TableActions';
import { PlusOutlined } from '@ant-design/icons';
import CreateDrawer from './CreateDrawer';

const TicketingSystems: React.FC = () => {
  const { t } = useTranslation();

  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);

  const [query, setQuery] = useQueryParams({
    size: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
  });

  const { data: ticketingSystems, isLoading } = useFetchTicketingSystems({
    itemOffset: (query.page - 1) * query.size,
    maxPageSize: query.size,
  });

  const pagination = {
    pageSize: query.size,
    current: query.page,
    total: ticketingSystems?.totalSize,
    showSizeChanger: true,
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setQuery({
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  const showDrawer = () => {
    setOpenCreateDrawer(true);
  };

  const onClose = () => {
    setOpenCreateDrawer(false);
  };

  const columns: ColumnsType<TicketingSystem> = [
    {
      title: t('general_name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general_type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: TicketingSystem['type']) => {
        return <Tag>{type}</Tag>;
      },
    },
    {
      title: t('general_actions_text'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (value, system, index) => <TableActions system={system} />,
    },
  ];

  return (
    <>
      <Flex
        vertical
        style={{
          padding: 24,
        }}
      >
        <Flex gap="middle" vertical>
          <Flex justify="space-between">
            <Title level={2}>{t('admin_ticketing-systems')}</Title>
            <Button
              size="large"
              type="primary"
              icon={<PlusOutlined rev={undefined} />}
              onClick={showDrawer}
            >
              {t('admin_ticketing_systems-add')}
            </Button>
          </Flex>
          <Table<TicketingSystem>
            loading={isLoading}
            onChange={handleTableChange}
            pagination={pagination}
            columns={columns}
            dataSource={ticketingSystems?.items}
          />
        </Flex>
      </Flex>
      <CreateDrawer onClose={onClose} open={openCreateDrawer} />
    </>
  );
};

export default TicketingSystems;

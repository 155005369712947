import {
  FanGroupOwnerWaitingListEntity,
  ListEntity,
  fanGroupOwnerWaitingListsKeys,
} from '@seaters-app/constants';
import {
  fetchFanGroupOwnerWaitingLists,
  getSingleTranslation,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { useInfiniteQuery } from '@tanstack/react-query';
import { paginationFormat } from '../../../../utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type ParamsType = {
  pageSize: number;
  current: number;
};

const defaultParams: ParamsType = {
  pageSize: 10,
  current: 1,
};

export const useWLOptions = (selectedFG: string) => {
  const { fanGroup } = useFanGroupStore();
  const { t } = useTranslation();

  const {
    data,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<ListEntity<FanGroupOwnerWaitingListEntity>, Error>({
    queryKey: fanGroupOwnerWaitingListsKeys.allPerFanGroup(fanGroup?.id ?? ''),
    queryFn: ({
      pageParam = {
        ...paginationFormat(defaultParams),
      },
    }) =>
      fetchFanGroupOwnerWaitingLists(
        selectedFG,
        { includeOpen: true, mustHaveSeatsWaiting: false },
        pageParam
      ),
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.reduce(
        (prev, next) => prev + next.items.length,
        0
      );

      return lastPage.totalSize - lastPage.itemOffset >= lastPage.maxPageSize
        ? {
            itemOffset: nextOffset,
            maxPageSize: lastPage.maxPageSize,
          }
        : undefined;
    },
    enabled: !!selectedFG,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  useEffect(() => {
    if (selectedFG) {
      refetch();
    }
  }, [selectedFG]);

  const allItems = data?.pages?.flatMap((page) => page.items) || [];
  const waitingListsOptions = allItems.map((item) => {
    return {
      label: `${
        (getSingleTranslation(item.experienceName)
          ? getSingleTranslation(item.experienceName)
          : item.event.eventName ?? item?.name) || item.waitingListId
      } (${t('admin_category')}: ${item?.name})`,
      value: item.waitingListId,
    };
  });

  return {
    allItems,
    waitingListsOptions,
    loadMoreData: handleFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isFetching || isLoading,
  };
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsMutating } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import {
  Button,
  Card,
  Radio,
  Space,
  Spin,
  Typography,
  notification,
} from 'antd';
import { FormOutlined } from '@ant-design/icons';
import Form from 'antd/es/form';
import { DatePicker, baseColor600 } from '@seaters-app/ui';
import {
  useFetchFanGroupOwnerWaitingList,
  useUpdateWaitingListSchedule,
  useUpdateWaitingListToDraft,
  useUpdateWaitingListToOpen,
  useUpdateWaitingListToPublish,
} from '@seaters-app/data-access';
import { routes, WaitingListStatusEnum } from '@seaters-app/constants';
import Footer from '../components/Footer';
import { SCHEDULED, publishWLStatusOptions } from '../helpers';

import styles from './steps.module.css';
import { useTranslation } from 'react-i18next';
import { useCurrentStep } from '../hooks/useCurrentStep';

const { Paragraph, Text } = Typography;

export function PublishStep({ isEdit }: { isEdit?: boolean }) {
  const navigate = useNavigate();
  const { prev } = useCurrentStep();
  const isMutating = !!useIsMutating();
  const { wishListId = '' } = useParams<{ wishListId: string }>();
  const { t } = useTranslation();
  const [scheduledDate, setScheduledDate] = useState<Dayjs | null>(null);
  const [wishListStatus, setWishListStatus] = useState<
    WaitingListStatusEnum | typeof SCHEDULED
  >();

  const { data: wishList, isLoading: wishListIsLoading } =
    useFetchFanGroupOwnerWaitingList(wishListId);

  const { mutate: updateWLToOpen } = useUpdateWaitingListToOpen();
  const { mutate: updateWLToPublished } = useUpdateWaitingListToPublish();
  const { mutate: updateWLToDraft } = useUpdateWaitingListToDraft();
  const { mutate: updateWLSchedule } = useUpdateWaitingListSchedule();

  useEffect(() => {
    if (wishList?.status) {
      if (
        wishListStatus !== SCHEDULED &&
        wishList?.status === WaitingListStatusEnum.PUBLISHED &&
        wishList?.openingDate !== null
      ) {
        setWishListStatus(SCHEDULED);
        setScheduledDate(dayjs(wishList?.openingDate));
      } else {
        setWishListStatus(wishList.status);
      }
    }
  }, [wishList]);

  const onSuccess = () =>
    wishList
      ? navigate(`/${routes.admin}/${routes.wishLists}/${wishListId}`)
      : navigate('..');

  const onError = (err) => {
    notification.error({
      message: err.response.data?.message,
    });
  };

  const handleSaveWLAsDraft = () => {
    if (wishList?.waitingListId)
      updateWLToDraft(
        { waitingListId: wishList?.waitingListId },
        { onSuccess }
      );
  };

  const onSubmit = () => {
    if (wishList?.waitingListId) {
      switch (wishListStatus) {
        case WaitingListStatusEnum.OPEN:
          return updateWLToOpen(
            { waitingListId: wishList?.waitingListId },
            { onSuccess, onError }
          );

        case WaitingListStatusEnum.PUBLISHED:
          return updateWLToPublished(
            { waitingListId: wishList?.waitingListId },
            { onSuccess, onError }
          );
        case SCHEDULED:
          return updateWLToPublished(
            { waitingListId: wishList?.waitingListId },
            {
              onSuccess: () => {
                updateWLSchedule({
                  waitingListId: wishList?.waitingListId,
                  body: {
                    date: dayjs(scheduledDate).format(),
                  },
                });
              },
              onError,
            }
          );
        default:
          return null;
      }
    }
  };

  return (
    <Spin spinning={wishListIsLoading}>
      <Space
        size="large"
        direction="vertical"
        style={{ width: '100%', paddingBottom: 40 }}
      >
        <p style={{ color: baseColor600 }}>{t('cwl_takeoff_title')}</p>

        <Form
          layout="vertical"
          onFinish={onSubmit}
          autoComplete="off"
          size="small"
          disabled={isMutating}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              type="default"
              size="middle"
              icon={<FormOutlined rev={undefined} />}
              onClick={handleSaveWLAsDraft}
            >
              {t('save_and_finish_later_button_text')}
            </Button>

            {publishWLStatusOptions.map((option) => (
              <Card bordered className={styles.publishCard}>
                <Radio
                  value={option.value}
                  checked={wishListStatus === option.value}
                  onChange={(e) => {
                    setWishListStatus(e.target.value);
                    setScheduledDate(null);
                  }}
                >
                  {t(option.label)}
                </Radio>
                <Paragraph style={{ color: baseColor600, margin: 0 }}>
                  {t(option.text)}
                </Paragraph>

                {option.value === SCHEDULED && wishListStatus === SCHEDULED && (
                  <Space direction="horizontal" style={{ marginTop: 16 }}>
                    <DatePicker
                      format="YYYY-MM-DD HH:mm"
                      style={{ width: 300 }}
                      showTime={{
                        defaultValue: dayjs('00:00', 'HH:mm'),
                        format: 'HH:mm',
                      }}
                      size="middle"
                      onChange={(v) => {
                        setScheduledDate(v);
                      }}
                      value={scheduledDate}
                    />
                    <Text style={{ color: baseColor600 }}>
                      {t('wl_takeoff_schedule-subinfo')}
                    </Text>
                  </Space>
                )}
              </Card>
            ))}
          </Space>
          <Footer
            isEdit={isEdit}
            handlePrevStep={() => prev()}
            saveAsDraft={handleSaveWLAsDraft}
            isLoading={isMutating}
            submitButtonText={'general_save-btn'}
          />
        </Form>
      </Space>
    </Spin>
  );
}

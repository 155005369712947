import {
  endpoints,
  UploadPublicImageEntity,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const uploadPublicImage = (
  formData: any,
  progressCallback: (progress: number) => void
): Promise<UploadPublicImageEntity> =>
  api.put(`${endpoints.upload}/${endpoints.image}`, formData, {onUploadProgress: (progressEvent) => {
    const progress = Math.round((progressEvent.loaded / (progressEvent.total ?? 1)) * 100);
    console.log(progress);
    progressCallback(progress);
  }});
import { useEffect, useState } from 'react';
import AntLayout, { LayoutProps } from 'antd/es/layout';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Drawer,
  Grid,
  Modal,
  Popover,
  Space,
  Typography,
  notification,
  theme,
} from 'antd';
import { IMenuItem, fanWebMenuItems } from './constants';
import styles from './layout.module.css';
import { ProfilePopup } from './components/ProfilePopup';

import {
  getSessionToken,
  getSlugFromUrl,
  queryClient,
  useAppThemeStore,
  useAppUserStore,
  useEmailConfirmationStore,
  useFetchAfterRegistrationSurveyInstances,
  useFetchFGBySlug,
  useFetchFanSurveyAnswers,
  useFetchUserRolesForCurrentFG,
  useIsCustomFlow,
  useJoinPublicFG,
  useUpdateFanEmail,
} from '@seaters-app/data-access';
import {
  SESSION_STORAGE_EMAIL_REMINDER,
  LookEntity,
  UserRole,
  routes,
  SESSION_STORAGE_TARGET_APP,
  TranslationsTarget,
  SESSION_STORAGE_SURVEY_SHOWN,
  SurveyQuery,
  SurveyExtensionPoint,
  AccessMode,
  SESSION_STORAGE_ALLOWED_GROUPS,
} from '@seaters-app/constants';
import { Header } from 'antd/es/layout/layout';
import {
  CloseOutlined,
  DownOutlined,
  MenuOutlined,
  UpOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Sider from 'antd/es/layout/Sider';
import seatersLogo from '../../assets/icons/seaters_logo.svg';
import seatersLogoWhite from '../../assets/icons/seaters_white.svg';

import { useTranslation } from 'react-i18next';
import Color from 'color';
import { ProtectedComponent } from '../ProtectedComponent';
import {
  Button,
  Container,
  Overlay,
  baseColor800,
  getColorContrast,
  hexToRgb,
  primaryColor,
} from '@seaters-app/ui';
import AppFooter from '../../pages/public/Footer/Footer';
import { useAppTranslationsChange } from '../hooks';
import { ComplianceModal, Survey } from '@seaters-app/ui-shared';

const { Content } = AntLayout;
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const siderWidth = 255;
const mobileDrawerOffset = 80;

export function FanLayout({ children }: LayoutProps) {
  const PROD = import.meta.env.MODE === 'production';

  const isAllowedGroups = sessionStorage.getItem(
    SESSION_STORAGE_ALLOWED_GROUPS
  );

  const auth = getSessionToken();
  const slug = getSlugFromUrl();
  const isCustom = useIsCustomFlow();

  const { t, i18n, ready } = useTranslation();
  const { language: lang } = i18n;

  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const params: Omit<SurveyQuery, 'waitinglist_id'> = {
    size: 9999,
    page: 0,
    fangroup_id: fanGroupData?.fanGroupId ?? '',
    extension_point: SurveyExtensionPoint.AFTER_REGISTRATION,
  };

  const { data: surveys, isLoading: surveysLoading } =
    useFetchAfterRegistrationSurveyInstances(params, slug ?? '', !!auth);

  const { data: surveyAnswers, isLoading: surveyAnswersLoading } =
    useFetchFanSurveyAnswers(
      fanGroupData?.fanGroupId ?? '',
      surveys?.content[0]?.id ?? ''
    );

  const { data: userRoles, isLoading: userRolesIsLoading } =
    useFetchUserRolesForCurrentFG(fanGroupData?.fanGroupId || '');

  const toHideMyWL =
    fanGroupData?.properties?.fanGroupMode === 'NO_JOINED_WAITING_LIST';

  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const [isSurveyShown, setSurveyShown] = useState(false);
  const [isSettingsPopupOpen, setSettingsPopupOpen] = useState(false);
  const [isSettingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
  const [siderCollapsed, setSiderCollapsed] = useState(true);

  const { showReminder, setEmailReminder } = useEmailConfirmationStore();

  const { data: fanGroupBySlugData } = useFetchFGBySlug(slug ?? '', true);

  const { mutate: joinPublicFG } = useJoinPublicFG(slug ?? '');

  const { user } = useAppUserStore();
  const { mode } = useAppThemeStore();
  const isHost = !!userRoles?.roles.find((role) => role === UserRole.HOST);

  const openSettingsPopup = () => {
    if (xs) {
      setSettingsDrawerOpen(!isSettingsDrawerOpen);
    } else {
      setSettingsPopupOpen(!isSettingsPopupOpen);
    }
  };

  const avatar = fanGroupData?.profileImageUrl;

  const customPrimaryColor = fanGroupData?.color;

  const customColor = Color(
    hexToRgb(customPrimaryColor ? `#${customPrimaryColor}` : '#000') ??
      'rgb(0, 0, 0)'
  );

  const { colorContrast } = getColorContrast(customColor.color);
  const { mutate: updateFanEmail } = useUpdateFanEmail();

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  const customIgnoreList = {
    [routes.myWaitingLists]: {
      toIgnore: (isCustom && isHost) || toHideMyWL, // hide joined list for host
    },
    [routes.tickets]: {
      toIgnore: isCustom, // hide tickets for everyone
    },
    [routes.approvals]: {
      toIgnore: isCustom && PROD, // hide approvals for everyone
    },
    [routes.allocate]: {
      toIgnore: !isCustom, // show Allocate for custom flow only
    },
  };

  const { changeTranslations } = useAppTranslationsChange();

  useEffect(() => {
    if (isSettingsPopupOpen) {
      setSettingsPopupOpen(false);
    }
  }, [slug]);

  useEffect(() => {
    const isMember = fanGroupBySlugData?.fanMember;
    const isPublic = fanGroupBySlugData?.accessMode === AccessMode.PUBLIC;

    if (fanGroupBySlugData && !isMember && isPublic) {
      joinPublicFG(fanGroupBySlugData.id);
    }
  }, [fanGroupBySlugData]);

  useEffect(() => {
    const wasSurveyShown = sessionStorage.getItem(SESSION_STORAGE_SURVEY_SHOWN);
    if (
      !wasSurveyShown &&
      !!surveys?.content.length &&
      !surveyAnswers?.content.length
    ) {
      setSurveyShown(true);
      sessionStorage.setItem(SESSION_STORAGE_SURVEY_SHOWN, 'true');
    }
  }, [surveyAnswers]);

  useEffect(() => {
    document.title = fanGroupData?.name[lang] || 'Seaters';
    return () => {
      document.title = 'Seaters';
    };
  }, [fanGroupData, lang]);

  const currentTarget = sessionStorage.getItem(SESSION_STORAGE_TARGET_APP);

  useEffect(() => {
    if (currentTarget !== TranslationsTarget.WEB) {
      changeTranslations(SESSION_STORAGE_TARGET_APP, TranslationsTarget.WEB);
    }
  }, [currentTarget]);

  const renderMenuItems = (items: Array<IMenuItem & { key: string }>) => {
    return items.map((item) => {
      if (customIgnoreList[item.path] && customIgnoreList[item.path].toIgnore) {
        return null;
      } else
        return (
          <ProtectedComponent allowedRoles={item.roles} key={item.key}>
            <NavLink
              key={`${item.name}_${item.order}`}
              to={`/${slug}/${item.path}`}
            >
              {({ isActive }) => {
                return (
                  <Button
                    size="large"
                    type="link"
                    color={
                      isActive
                        ? `#${customPrimaryColor}` ?? primaryColor
                        : token.colorTextBase
                    }
                    style={{
                      fontWeight: 'bold',
                      borderBottom: isActive
                        ? `3px solid ${token.colorPrimary}`
                        : 'none',
                      borderRadius: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginRight: 20,
                      marginLeft: 20,
                    }}
                  >
                    {t(item.name)}
                  </Button>
                );
              }}
            </NavLink>
          </ProtectedComponent>
        );
    });
  };

  const renderSiderItems = (items: Array<IMenuItem & { key: string }>) => {
    return items.map((item) => {
      if (customIgnoreList[item.path] && customIgnoreList[item.path].toIgnore) {
        return null;
      } else {
        return (
          <ProtectedComponent allowedRoles={item.roles} key={item.key}>
            <NavLink
              key={`${item.name}_${item.order}`}
              to={item.path}
              className={
                !isHost && item.name === 'Invites' ? styles.hidden : ''
              }
            >
              {({ isActive }) => (
                <Button
                  type="link"
                  icon={item.icon}
                  color={
                    isActive
                      ? `#${customPrimaryColor}` ?? primaryColor
                      : token.colorTextBase
                  }
                  onClick={toggleSider}
                >
                  {t(item.name)}
                </Button>
              )}
            </NavLink>
          </ProtectedComponent>
        );
      }
    });
  };

  const verifyEmail = () => {
    if (user?.email) {
      updateFanEmail(
        { email: user?.email },
        {
          onSuccess: (data) => {
            notification.success({
              message: t(
                'waitinglist_unconfirmed-email-modal_email-resent-alert'
              ),
            });
          },
          onError: (err) => {
            console.error(err);
            notification.error({
              message: err.message,
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    const wasEmailReminderShown = sessionStorage.getItem(
      SESSION_STORAGE_EMAIL_REMINDER
    );
    if (user && !user?.validatedEmail && !wasEmailReminderShown) {
      sessionStorage.setItem(SESSION_STORAGE_EMAIL_REMINDER, 'true');
      setEmailReminder(true);
    }
    if (wasEmailReminderShown === 'true') {
      setEmailReminder(true);
    }
  }, [user]);

  return (
    <AntLayout
      style={{ minHeight: '100vh', backgroundColor: token.colorPrimaryBg }}
    >
      <Sider
        className={styles.sider}
        style={{
          borderRight: `1px solid ${token.colorBorderSecondary}`,
          display: !xs ? 'none' : 'block',
        }}
        theme="light"
        collapsedWidth="0"
        onBreakpoint={(breakpoint) => {
          console.log('breakpoint', breakpoint);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        collapsed={siderCollapsed}
        trigger={null}
        collapsible
        width={siderWidth}
      >
        <div className={styles.menubar}>
          <div
            className={styles.logo}
            style={{
              borderBottom: xs ? `1px solid ${token.colorBorder}` : 'none',
            }}
          >
            <Button
              type="text"
              icon={<CloseOutlined rev={undefined} />}
              onClick={toggleSider}
            />
            <img
              alt="Logo"
              src={mode === 'light' ? seatersLogo : seatersLogoWhite}
              height={24}
            />
          </div>

          <div className={styles.menuItemsContainer}>
            {renderSiderItems(fanWebMenuItems)}
          </div>
        </div>
      </Sider>
      <AntLayout
        style={{
          minHeight: '100vh',
          backgroundColor: token.colorPrimaryBg,
        }}
      >
        <Overlay show={!siderCollapsed} onClick={toggleSider} />
        {ready && (
          <Header
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: token.colorBgLayout,
              height: xs ? 'none' : '60px',
              padding: xs ? '0 8px' : '0',
              borderBottom: xs ? `1px solid ${token.colorBorder}` : 'none',
              justifyContent: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 10,
              width: '100%',
            }}
          >
            <Container>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {xs ? (
                  <Space>
                    <Button
                      type="text"
                      onClick={toggleSider}
                      icon={<MenuOutlined rev={undefined} />}
                    />
                  </Space>
                ) : (
                  <Space>
                    <Avatar
                      size="large"
                      icon={<UserOutlined rev={undefined} />}
                      src={avatar}
                      onClick={() => navigate('.')}
                      style={{ cursor: 'pointer' }}
                    />
                    {renderMenuItems(fanWebMenuItems)}
                  </Space>
                )}

                <Popover
                  content={<ProfilePopup setIsOpen={setSettingsPopupOpen} />}
                  trigger="click"
                  open={!xs && isSettingsPopupOpen}
                  onOpenChange={openSettingsPopup}
                >
                  <Button
                    color={baseColor800}
                    className={styles.settingsFan}
                    type="text"
                  >
                    <div>
                      <p className={styles.userName}>
                        {user?.firstName} {user?.lastName}
                      </p>
                    </div>
                    {isSettingsPopupOpen ? (
                      <UpOutlined rev={undefined} />
                    ) : (
                      <DownOutlined rev={undefined} />
                    )}
                  </Button>
                </Popover>
              </div>
            </Container>
          </Header>
        )}
        {user && !user?.validatedEmail && showReminder && (
          <div
            style={{
              backgroundColor: customColor.toString(),
            }}
          >
            <Container>
              <div className={styles.emailBanner}>
                <Space.Compact direction="vertical">
                  <Space
                    style={{
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      color: colorContrast,
                    }}
                  >
                    <Title
                      level={5}
                      style={{
                        color: colorContrast,
                      }}
                    >
                      {t('waitinglist_unconfirmed-email-modal_title')}
                    </Title>
                    <CloseOutlined
                      onClick={() => {
                        sessionStorage.setItem(
                          SESSION_STORAGE_EMAIL_REMINDER,
                          'false'
                        );
                        setEmailReminder(false);
                      }}
                      rev={undefined}
                    />
                  </Space>
                  <Text
                    style={{
                      color: colorContrast,
                    }}
                  >
                    {t('email_confirmation_email_sent_message')}{' '}
                    <strong>{user?.email}.</strong>
                  </Text>
                </Space.Compact>
                <Space>
                  <Button
                    type="primary"
                    onClick={verifyEmail}
                    color={colorContrast}
                  >
                    {t(
                      !xs
                        ? 'button_text_resend_email'
                        : 'waitinglist_unconfirmed-email-modal_resend-email-btn'
                    )}
                  </Button>
                  <NavLink to={routes.profile}>
                    <Button type="link" color={colorContrast}>
                      {t(
                        !xs ? 'button_text_change_email' : 'change_email_title'
                      )}
                    </Button>
                  </NavLink>
                </Space>
              </div>
            </Container>
          </div>
        )}
        <Drawer
          placement="bottom"
          closable={false}
          onClose={openSettingsPopup}
          open={isSettingsDrawerOpen}
          styles={{
            wrapper: {
              borderRadius: '24px 24px 0 0',
              overflow: 'hidden',
              maxHeight: `calc(100vh - ${mobileDrawerOffset}px)`,
              height: 'unset',
            },
          }}
        >
          <ProfilePopup setIsOpen={setSettingsDrawerOpen} />
        </Drawer>

        <Content className={styles.content}>
          <div className={styles.outletContainer}>
            <Outlet />
          </div>
        </Content>
        <div
          style={{
            height: 'fit-content',
            position: 'sticky',
            bottom: 0,
            zIndex: 15,
          }}
          id="portalForFooter"
        ></div>

        {!!slug &&
        isSurveyShown &&
        !surveysLoading &&
        !surveyAnswersLoading &&
        !!surveys?.content[0] ? (
          <Modal
            title={surveys?.content[0]?.survey?.title[0]?.text}
            centered
            footer={null}
            open
            onCancel={() => setSurveyShown(false)}
            width={680}
            style={{ maxHeight: 'fit-content', margin: '24px' }}
          >
            {surveys?.content[0]?.survey?.description[0]?.text}
            {surveys?.content[0] && (
              <Survey
                surveyInstance={surveys?.content[0]}
                cancel={() => setSurveyShown(false)}
                surveyAnswers={
                  surveyAnswers?.content.length
                    ? surveyAnswers?.content
                    : undefined
                }
              />
            )}
          </Modal>
        ) : null}
        <ComplianceModal fanGroupData={fanGroupData} />

        {ready && !isAllowedGroups && <AppFooter />}
      </AntLayout>
    </AntLayout>
  );
}

export default FanLayout;

import { FormTranslationType, TranslationEntity } from '@seaters-app/constants';

export const initialTranslations: FormTranslationType = {
  en: '',
  de: '',
  nl: '',
  cn: '',
  ru: '',
  it: '',
  fr: '',
  ja: '',
  es: '',
};

// getTranslations receives an Object and array of its keys, which values are arrays with translations:

//   Object = {
//   key1: [{lang: 'en', text: 'text'}, ..., {lang: 'de', text: 'text'}] ,
//   ... ,
//    keyN: [{lang: 'en', text: 'text'}, ..., {lang: 'de', text: 'text'}]
//   }

// and returns an object with the same keys with parsed values:

//   ReturnedObject = {
//    key1: {en: 'text', ... , de: 'text'},
//    ... ,
//    keyN: {en: 'text', ... , de: 'text'}
//    }

export function getTranslations<Type>(
  keys: Array<keyof Type>,
  entity?: Type
): {
  [x: string]: FormTranslationType;
} | null {
  if (entity) {
    const initialValue = {};
    const result = keys
      .map((key) => {
        const obj: FormTranslationType = { ...initialTranslations };

        const array = entity[key];

        if (Array.isArray(array)) {
          array?.forEach((item: TranslationEntity) => {
            obj[item.lang] = item.text;
          });
        }
        return { [key]: obj };
      }) // receive array [{key1: {en: 'text', de: 'text', ... , cn: 'text'}}, {key2: {en: 'text', de: 'text', ... , cn: 'text'}}, ..., {keyN: {en: 'text', de: 'text', ... , cn: 'text'}}]
      .reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, initialValue);

    //   receive an Object : {
    //   key1: {en: 'text', de: 'text', ... , cn: 'text'},
    //   ...
    //   keyN: {en: 'text', de: 'text', ... , cn: 'text'},
    //    }
    return result;
  } else {
    return null;
  }
}

import { useState } from 'react';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  DateFilter,
  FanGroupFilter,
  FilterTypeEnum,
  HasAvailableTicketsOnlyFilter,
  SearchableTextFilter,
  SessionTypeFilter,
  TicketCategoryFilter,
  WaitingListFiltersQuery,
} from '@seaters-app/constants';
import { getObjectTranslation } from '@seaters-app/data-access';
import { Button, DatePicker, baseColor900, errorColor } from '@seaters-app/ui';
import {
  Col,
  Flex,
  Form,
  Grid,
  Select,
  Space,
  Switch,
  Typography,
  theme,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { calculateVisibleItems } from '../../helpers/calculateVisibleItems';
import { camelCaseToNormal } from '../../helpers/camelCaseToNormalName';
import dayjs, { Dayjs } from 'dayjs';
import Search from 'antd/es/input/Search';
const { useBreakpoint } = Grid;
const { Title } = Typography;
interface DateItem {
  date: string;
  dayNumber: number;
}

interface Option {
  label: string;
  value: string;
  id?: string;
  // key: string;
}

function useRenderFilter(
  selectedFilters: WaitingListFiltersQuery,
  setSelectedFilters: (v: WaitingListFiltersQuery) => void,
  setFiltersInfoShown: (v: boolean) => void
) {
  const { token } = theme.useToken();
  const screens = useBreakpoint();

  const dateFilterSelectHandle = (dateItem: DateItem, filter: DateFilter) => {
    if (dateItem.date && selectedFilters.date !== dateItem.date) {
      setSelectedFilters({
        ...selectedFilters,
        date: dateItem.date,
      });
    } else {
      const updatedFilters = { ...selectedFilters };
      delete updatedFilters?.[filter.name];
      setSelectedFilters(updatedFilters);
    }
  };

  const onSearch = (val: string, filter: SearchableTextFilter) => {
    if (val) {
      setSelectedFilters({
        ...selectedFilters,
        [filter.name]: val,
      });
    } else {
      const updatedFilters = { ...selectedFilters };
      delete updatedFilters?.[filter.name];
      setSelectedFilters(updatedFilters);
    }
  };

  const onSelectTextFilter = (
    option: Option,
    filter: SessionTypeFilter | TicketCategoryFilter
  ) => {
    if (option.value) {
      setSelectedFilters({
        ...selectedFilters,
        [filter.name]: option.value,
      });
    } else {
      // clear filter if 'All' selected
      const updatedFilters = { ...selectedFilters };
      delete updatedFilters?.[filter.name];
      setSelectedFilters(updatedFilters);
    }
  };

  const handleToggleFilter = (
    val: boolean,
    filter: HasAvailableTicketsOnlyFilter
  ) => {
    setSelectedFilters({
      ...selectedFilters,
      [filter.name]: val,
    });
  };

  const dateItemStyle = {
    textDecoration: 'none',
    border: `3px solid white`,
    cursor: 'pointer',
    backgroundColor: 'white',
    width: screens.xs ? 50 : 70,
    height: screens.xs ? 50 : 70,
    borderRadius: '50%',
  };

  const selectedDateItemStyle = {
    textDecoration: 'none',
    border: `3px solid ${token.colorPrimary}`,
    cursor: 'pointer',
    color: token.colorPrimary,
    backgroundColor: 'white',
    width: screens.xs ? 50 : 70,
    height: screens.xs ? 50 : 70,
    borderRadius: '50%',
  };

  const activeDateColor = (dateItem: DateItem) => {
    return selectedFilters.date === dateItem.date
      ? token.colorPrimary
      : new Date(dateItem.date).getTime() > new Date().getTime()
      ? baseColor900
      : token.colorTextDisabled;
  };
  const [startIndex, setStartIndex] = useState(0);

  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const renderFilter = (filter: FanGroupFilter & { order: number }) => {
    switch (filter.type) {
      case FilterTypeEnum.TEXT: {
        if (!filter?.values) return null;
        const values = [...filter.values];
        values.unshift({
          label: { [lang]: t('all_options_select') },
          value: '',
        });
        const options = values.map((opt) => {
          return {
            label: getObjectTranslation(opt.label, lang, 'All options') || '',
            value: opt.value,
            // key: opt.value,
          };
        });
        return (
          <Col xs={24} sm={12} md={8} xl={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={
                <Space>
                  {getObjectTranslation(filter?.label, lang)}

                  {filter.description?.en && (
                    <QuestionCircleOutlined
                      onClick={() => setFiltersInfoShown(true)}
                      rev={undefined}
                    />
                  )}
                </Space>
              }
            >
              <Select
                style={{ width: '100%' }}
                defaultValue={''}
                className={'filterSelect'}
                options={options}
                onSelect={(_, option) => onSelectTextFilter(option, filter)}
              />
            </Form.Item>
          </Col>
        );
      }

      case FilterTypeEnum.DATE:
      case FilterTypeEnum.DATE_LINE: {
        if (!filter?.values) return null;
        const dateFilterIndexed = filter.values.map((filter, i) => {
          const newF = { date: filter, dayNumber: i + 1 };
          return newF;
        });

        const itemsToAdd = 3;
        const visibleItems = calculateVisibleItems(screens);

        const handleShowPrev = () => {
          setStartIndex((prevIndex) => {
            return Math.max(prevIndex - itemsToAdd, 0);
          });
        };
        const handleShowNext = () => {
          if (dateFilterIndexed) {
            setStartIndex((prevIndex) =>
              Math.min(
                prevIndex + itemsToAdd,
                dateFilterIndexed.length - visibleItems
              )
            );
          }
        };
        return (
          <Col xs={24}>
            <Flex align="center" gap={8} style={{ marginBottom: 12 }}>
              <Button
                disabled={startIndex === 0}
                onClick={handleShowPrev}
                icon={<ArrowLeftOutlined rev={undefined} />}
              />
              <Flex
                justify="space-around"
                style={{
                  flexGrow: 1,
                }}
              >
                {dateFilterIndexed
                  .slice(startIndex, startIndex + visibleItems)
                  .map((dateItem) => {
                    const selected = selectedFilters.date === dateItem.date;
                    return (
                      <Space
                        direction="vertical"
                        align="center"
                        className={'dateFilter'}
                      >
                        <button
                          disabled={
                            new Date(dateItem.date).getTime() <
                            new Date().getTime()
                          }
                          style={
                            selected ? selectedDateItemStyle : dateItemStyle
                          }
                          onClick={() =>
                            dateFilterSelectHandle(dateItem, filter)
                          }
                        >
                          <Space.Compact
                            direction="vertical"
                            className="dateFilterLabel"
                          >
                            <span style={{ fontWeight: 'bold' }}>
                              {camelCaseToNormal(
                                dayjs(dateItem.date).format('MMM')
                              )}
                            </span>
                            <Title
                              level={screens.xs ? 3 : 2}
                              style={{
                                margin: 0,
                                color: activeDateColor(dateItem),
                              }}
                            >
                              {dayjs(dateItem.date).format('DD')}
                            </Title>
                          </Space.Compact>

                          {selected ? (
                            <CloseOutlined
                              className="dateFilterButtonUncheck"
                              style={{ fontSize: 26 }}
                              rev={undefined}
                            />
                          ) : (
                            <CheckOutlined
                              className="dateFilterButtonCheck"
                              style={{ fontSize: 26 }}
                              rev={undefined}
                            />
                          )}
                        </button>

                        {filter.type === 'date' && (
                          <Space.Compact>
                            <span
                              style={{
                                color: activeDateColor(dateItem),
                              }}
                            >
                              {t('general_duration-day')} {dateItem.dayNumber}
                            </span>{' '}
                            {selectedFilters.date === dateItem.date && (
                              <CloseOutlined
                                rev="undefined"
                                style={{
                                  color: errorColor,
                                }}
                                onClick={() =>
                                  dateFilterSelectHandle(dateItem, filter)
                                }
                              />
                            )}
                          </Space.Compact>
                        )}
                      </Space>
                    );
                  })}
              </Flex>
              <Button
                disabled={startIndex + visibleItems >= dateFilterIndexed.length}
                onClick={handleShowNext}
                icon={<ArrowRightOutlined rev={undefined} />}
              />
            </Flex>
          </Col>
        );
      }

      case FilterTypeEnum.DATE_PICKER: {
        const dateObjects = filter.values.map((date) => dayjs(date));

        const firstDate = dayjs(filter.values[0]);
        const lastDate = dayjs(filter.values[filter.values.length - 1]);

        const isSameDay = (date1: Dayjs, date2: Dayjs) => {
          return date1.isSame(date2, 'day');
        };

        const disabledDate = (current: Dayjs) => {
          if (current.isBefore(firstDate, 'day')) {
            return true;
          }

          if (current.isAfter(lastDate, 'day')) {
            return true;
          }

          return !dateObjects.some((date) => isSameDay(date, current));
        };

        return (
          <Col xs={24} sm={12} md={8} xl={6}>
            <Form.Item labelCol={{ span: 24 }} label={t('filters_label_date')}>
              <DatePicker
                style={{ minWidth: '100%' }}
                format="DD/MM/YYYY"
                disabledDate={disabledDate}
                onChange={(date) => {
                  dateFilterSelectHandle(
                    {
                      date: date ? dayjs(date).format('YYYY-MM-DD') : '',
                      dayNumber: filter.values.indexOf(
                        dayjs(date).format('YYYY-MM-DD') + 1
                      ),
                    },
                    filter
                  );
                }}
              />
            </Form.Item>
          </Col>
        );
      }

      case FilterTypeEnum.SEARCHABLE_TEXT: {
        return (
          <Col xs={24} sm={12} md={8} xl={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={
                <Space size={3}>
                  {getObjectTranslation(filter?.label, lang)}
                  {filter.description?.en && (
                    <QuestionCircleOutlined
                      onClick={() => setFiltersInfoShown(true)}
                      rev={undefined}
                    />
                  )}
                </Space>
              }
            >
              <Search
                style={{ width: '100%' }}
                allowClear
                defaultValue={''}
                onSearch={(val) => onSearch(val, filter)}
              />
            </Form.Item>
          </Col>
        );
      }

      case FilterTypeEnum.TOGGLE: {
        return (
          <Col xs={24} sm={12} md={8} xl={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={
                <Space size={3}>
                  {getObjectTranslation(filter?.label, lang)}

                  {filter.description?.en && (
                    <QuestionCircleOutlined
                      onClick={() => setFiltersInfoShown(true)}
                      rev={undefined}
                    />
                  )}
                </Space>
              }
            >
              <Switch
                checkedChildren={<CheckOutlined rev={undefined} />}
                defaultChecked={filter.name === 'hasAvailableTicketsOnly'}
                onChange={(checked) => handleToggleFilter(checked, filter)}
              />
            </Form.Item>
          </Col>
        );
      }

      default: {
        return null;
      }
    }
  };
  return { renderFilter };
}

export default useRenderFilter;

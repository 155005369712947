import {
  fetchAdminFanGroups,
  getSingleTranslation,
  useAppUserStore,
} from '@seaters-app/data-access';
import {
  FanGroupEntity,
  ListEntity,
  UserRole,
  fanGroupsKeys,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useFGAdminOptions = (
  defaultParams: { page: number; size: number; query: string },
  fromWishList: boolean
) => {
  const { user } = useAppUserStore();

  const isAdmin = user?.roles.includes(UserRole.ADMIN);

  const { data, fetchNextPage, isFetching } = useInfiniteQuery<
    ListEntity<FanGroupEntity>
  >({
    queryKey: fanGroupsKeys.list(defaultParams),
    queryFn: ({ pageParam = defaultParams }) => {
      return fetchAdminFanGroups({
        page: pageParam?.page,
        size: pageParam?.size,
        query: pageParam?.query,
      });
    },
    getNextPageParam: (lastPage) => {
      return lastPage.totalSize - lastPage.itemOffset >= lastPage.maxPageSize
        ? {
            page: lastPage.itemOffset
              ? lastPage.itemOffset / lastPage.maxPageSize + 1
              : 1,
            size: lastPage.maxPageSize,
            query: defaultParams.query,
          }
        : undefined;
    },
    enabled: isAdmin && !fromWishList,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const allItems = data?.pages.flatMap((page) => page.items) || [];
  const options = allItems?.map((item) => {
    return {
      value: item?.id,
      label: `${getSingleTranslation(item?.name)} (slug: ${item?.slug})`,
    };
  });

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  return {
    fanGroupsOptions: options,
    loadMoreData: handleFetchNextPage,
    fanGroupsFetching: isFetching,
    allItems: allItems,
  };
};

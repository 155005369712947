import {
  searchFanAttributes,
  useSearchFanAttributes,
} from '@seaters-app/data-access';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useFanAttributes = (
  setSearchedValue: Dispatch<SetStateAction<string>>
) => {
  const [params, setParams] = useState({
    query: '',
    validated: true,
  });

  const handleSearch = (value: string) => {
    setSearchedValue(value);
    setParams({
      query: value,
      validated: true,
    });
  };

  useEffect(() => {
    searchFanAttributes(params);
  }, [params]);

  const { data: searchedFanAttributes, isLoading } =
    useSearchFanAttributes(params);

  return { searchedFanAttributes, handleSearch, setParams };
};

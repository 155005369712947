import Input from 'antd/es/input/Input';
import { Checkbox, Form, Radio, Rate, Select, Space, theme } from 'antd';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import {
  ChoiceEntity,
  ListEntityV2,
  QuestionItem,
  QuestionStructureType,
  QuestionType,
  SurveyExtensionPoint,
  questionChoicesKeys,
} from '@seaters-app/constants';
import './styles.css';

import {
  getSingleRangeOptionTranslation,
  getSingleTranslation,
  queryClient,
} from '@seaters-app/data-access';
import { CustomCarousel } from '../components/CustomCarousel';
import { t } from 'i18next';

type StructuredQuestionLabelsType = {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  idNumber: string;
  company: string;
  phoneNumber: string;
  address: string;
  language: string;
};

export const structuredQuestionLabels: StructuredQuestionLabelsType = {
  //TODO: add translations
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  birthDate: 'Birth date',
  idNumber: 'ID number',
  company: 'Company',
  phoneNumber: 'Phone number',
  address: 'Address',
  language: 'Language',
};

const renderStructured = (
  questionItem: QuestionItem,
  control: Control<any, any>,
  ticket: string
) => {
  return questionItem.question.structure?.map((structuredQuestionItem) => {
    return structuredQuestionItem.type !== QuestionStructureType.CHECK_BOX ? (
      <Form.Item
        name={`${questionItem.question.id}.${ticket}.${structuredQuestionItem.name}`}
        label={
          structuredQuestionLabels[
            structuredQuestionItem.name as keyof StructuredQuestionLabelsType
          ]
            ? structuredQuestionLabels[
                structuredQuestionItem.name as keyof StructuredQuestionLabelsType
              ]
            : structuredQuestionItem.name
        }
        required={structuredQuestionItem.mandatory}
      >
        <Controller
          control={control}
          name={`${questionItem.question.id}.${ticket}.${structuredQuestionItem.name}`}
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>
    ) : (
      <Form.Item
        valuePropName="checked"
        name={`${questionItem.question.id}.${ticket}.${structuredQuestionItem.name}`}
        required={structuredQuestionItem.mandatory}
      >
        <Controller
          control={control}
          name={`${questionItem.question.id}.${ticket}.${structuredQuestionItem.name}`}
          render={({ field }) => (
            <Checkbox {...field} checked={field.value}>
              {structuredQuestionItem.name}
            </Checkbox>
          )}
        />
      </Form.Item>
    );
  });
};

export const renderQuestion = (
  questionItem: QuestionItem,
  setValue: UseFormSetValue<any>,
  control: Control<any, any>,
  surveyType: SurveyExtensionPoint,
  setImportedData: (v: { data: any[]; questionId: string }) => void,
  ticketsAmount?: number,
  lang?: string,
  disabled?: boolean
) => {
  const { token } = theme.useToken();

  const tickets = Array(ticketsAmount ?? 1)
    .fill(1)
    .map((_, i) => `Instance ${i + 1}`);

  const structured = tickets?.map((ticket) => {
    return <div>{renderStructured(questionItem, control, ticket)}</div>;
  });

  const getOptions = (questionId: string) => {
    return queryClient
      .getQueryData<ListEntityV2<ChoiceEntity>>(
        questionChoicesKeys.listPerQuestion(questionId)
      )
      ?.content.map((option) => {
        return {
          label: getSingleTranslation(option.offeredAnswerTranslation, lang),
          value: option?.id,
        };
      });
  };

  const getRangeOptions = (questionId: string) => {
    const content = queryClient.getQueryData<ListEntityV2<ChoiceEntity>>(
      questionChoicesKeys.listPerQuestion(questionId)
    )?.content;

    const isDescriptedDefault =
      content &&
      !!content[0].offeredAnswerTranslation
        .find((item) => item.lang === 'en')
        ?.text.split(' - ')[1];

    const lastElementIndex = content?.length ? content?.length - 1 : 0;
    const translated = [0, lastElementIndex];
    return queryClient
      .getQueryData<ListEntityV2<ChoiceEntity>>(
        questionChoicesKeys.listPerQuestion(questionId)
      )
      ?.content.map((option, i) => {
        return translated.includes(i) && isDescriptedDefault
          ? {
              label: getSingleRangeOptionTranslation(
                option.offeredAnswerTranslation,
                lang
              ),
              value: option?.id,
            }
          : {
              label: getSingleTranslation(
                option.offeredAnswerTranslation,
                lang
              ),
              value: option?.id,
            };
      });
  };

  switch (questionItem.question.type) {
    case QuestionType.CHECKBOX: {
      return (
        <Controller
          control={control}
          name={`${questionItem.question.id}.offeredAnswerId`}
          render={({ field }) => (
            <Radio.Group {...field}>
              <Space direction="vertical">
                {getOptions(questionItem.question.id)?.map((option) => (
                  <Radio key={option?.value} value={option?.value}>
                    {option?.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          )}
        />
      );
    }
    case QuestionType.MULTIPLE_CHOICE: {
      return (
        <Controller
          control={control}
          name={`${questionItem.question.id}.offeredAnswerId`}
          render={({ field }) => (
            <Radio.Group {...field}>
              <Space direction="vertical">
                {getOptions(questionItem.question.id)?.map((option) => (
                  <Radio key={option?.value} value={option?.value}>
                    {option?.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          )}
        />
      );
    }

    case QuestionType.MULTIPLE_CHOICE_AUTO_COMPLETE: {
      return (
        <Controller
          control={control}
          name={`${questionItem.question.id}.offeredAnswerId`}
          render={({ field }) => (
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onSelect={(_, option) => {
                setValue(
                  `${questionItem.question.id}.translatedOfferedAnswer`,
                  option.label
                );
              }}
              options={getOptions(questionItem.question.id)}
              {...field}
            />
          )}
        />
      );
    }

    case QuestionType.MULTIPLE_CHOICE_RANGE: {
      const fullOptions = getRangeOptions(questionItem.question.id);
      const options = fullOptions?.map((option) => {
        return { value: option.value, label: option.label.split(' - ')[0] };
      });
      const minText = fullOptions?.[0]?.label.split(' - ')[1] ?? '';
      const maxText =
        fullOptions?.[fullOptions.length - 1]?.label.split(' - ')[1] ?? '';

      const isZero = options?.find((opt) => opt.label === '0');

      return (
        <Controller
          control={control}
          name={`${questionItem.question.id}.offeredAnswerId`}
          render={({ field }) => {
            return (
              <div
                className="rateWrapper"
                style={{ color: token.colorPrimary }}
              >
                <Rate
                  {...field}
                  disabled={disabled}
                  defaultValue={0}
                  count={options?.length ?? 0}
                  character={({ index = 0 }) => (
                    <Space direction="vertical" align="center">
                      {isZero ? index : index + 1}
                    </Space>
                  )}
                  value={
                    Number(
                      options?.find((opt) => opt.value === field.value)?.label
                    ) + (isZero ? 1 : 0)
                  }
                  onChange={(value) => {
                    const id = options?.find(
                      (opt) =>
                        opt.label === (isZero ? value - 1 : value).toString()
                    )?.value;
                    setValue(`${questionItem.question.id}.offeredAnswerId`, id);
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '70px', textAlign: 'center' }}>
                    {minText}
                  </div>
                  <div style={{ width: '70px', textAlign: 'center' }}>
                    {maxText}
                  </div>
                </div>
              </div>
            );
          }}
        />
      );
    }
    case QuestionType.MULTIPLE_CHOICE_AUTO_COMPLETE_MULTIPLEANSWER: {
      return (
        <Controller
          control={control}
          name={`${questionItem.question.id}.offeredAnswerId`}
          render={({ field }) => (
            <Select
              mode="multiple"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onSelect={(_, option) => {
                setValue(`${questionItem.question.id}.${option.value}`, option);
              }}
              options={getOptions(questionItem.question.id)}
              {...field}
            />
          )}
        />
      );
    }
    case QuestionType.MULTIPLE_CHOICE_DROPDOWN: {
      return (
        <Controller
          control={control}
          name={`${questionItem.question.id}.offeredAnswerId`}
          render={({ field }) => (
            <Select options={getOptions(questionItem.question.id)} {...field} />
          )}
        />
      );
    }
    case QuestionType.MULTIPLE_CHOICE_MULTIPLEANSWER: {
      return (
        <Controller
          control={control}
          name={`${questionItem.question.id}.offeredAnswerId`}
          render={({ field }) => (
            <Checkbox.Group {...field}>
              <Space direction="vertical">
                {getOptions(questionItem.question.id)?.map((option) => (
                  <Checkbox
                    key={option.value}
                    value={option.value}
                    onChange={(v) => {
                      v
                        ? setValue(
                            `${questionItem.question.id}.${option.value}`,
                            option
                          )
                        : setValue(
                            `${questionItem.question.id}.${option.value}`,
                            undefined
                          );
                    }}
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          )}
        />
      );
    }
    case QuestionType.MULTIPLE_CHOICE_WITH_OTHER: {
      return (
        <Space direction="vertical">
          <Controller
            control={control}
            name={`${questionItem.question.id}.offeredAnswerId`}
            render={({ field }) => (
              <Radio.Group {...field}>
                <Space direction="vertical">
                  {getOptions(questionItem.question.id)?.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            )}
          />
          <Controller
            control={control}
            name={`${questionItem.question.id}.answerText`}
            render={({ field: { onChange, ...field } }) => (
              <Space size={12}>
                {t('other_answer_label')}:{' '}
                <Input
                  {...field}
                  placeholder={t('please_specify_label') ?? 'Please specify'}
                  onChange={(v) => {
                    if (v.target.value) {
                      setValue(
                        `${questionItem.question.id}.offeredAnswerId`,
                        null
                      );
                    }
                    onChange(v);
                  }}
                />
              </Space>
            )}
          />
        </Space>
      );
    }
    case QuestionType.MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE: {
      return (
        <Space direction="vertical" style={{ width: '100%' }} size={24}>
          <Controller
            control={control}
            name={`${questionItem.question.id}.offeredAnswerId`}
            render={({ field }) => (
              <Select
                defaultActiveFirstOption
                showSearch
                optionFilterProp="label"
                onSelect={() => {
                  setValue(`${questionItem.question.id}.answerText`, null);
                }}
                options={getOptions(questionItem.question.id)}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name={`${questionItem.question.id}.answerText`}
            render={({ field: { onChange, ...field } }) => (
              <Space size={12}>
                {t('other_answer_label')}:{' '}
                <Input
                  {...field}
                  placeholder={t('please_specify_label') ?? 'Please specify'}
                  onChange={(v) => {
                    if (v.target.value) {
                      setValue(
                        `${questionItem.question.id}.offeredAnswerId`,
                        null
                      );
                    }
                    onChange(v);
                  }}
                />
              </Space>
            )}
          />
        </Space>
      );
    }
    case QuestionType.MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE_MULTIPLEANSWER: {
      return (
        <Space direction="vertical" style={{ width: '100%' }} size={24}>
          <Controller
            control={control}
            name={`${questionItem.question.id}.offeredAnswerId`}
            render={({ field }) => (
              <Select
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onSelect={(_, option) => {
                  setValue(
                    `${questionItem.question.id}.${option.value}`,
                    option
                  );
                  setValue(`${questionItem.question.id}.answerText`, null);
                }}
                options={getOptions(questionItem.question.id)}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name={`${questionItem.question.id}.answerText`}
            render={({ field: { onChange, ...field } }) => (
              <Space size={12}>
                {t('other_answer_label')}:{' '}
                <Input
                  {...field}
                  placeholder={t('please_specify_label') ?? 'Please specify'}
                  onChange={(v) => {
                    if (v.target.value) {
                      setValue(
                        `${questionItem.question.id}.offeredAnswerId`,
                        null
                      );
                    }
                    onChange(v);
                  }}
                />
              </Space>
            )}
          />
        </Space>
      );
    }
    case QuestionType.MULTIPLE_CHOICE_WITH_OTHER_DROPDOWN: {
      return (
        <Space direction="vertical" style={{ width: '100%' }} size={24}>
          <Controller
            control={control}
            name={`${questionItem.question.id}.offeredAnswerId`}
            render={({ field }) => (
              <Select
                defaultActiveFirstOption
                onSelect={() => {
                  setValue(`${questionItem.question.id}.answerText`, null);
                }}
                options={getOptions(questionItem.question.id)}
                {...field}
              />
            )}
          />{' '}
          <Controller
            control={control}
            name={`${questionItem.question.id}.answerText`}
            render={({ field: { onChange, ...field } }) => (
              <Space size={12}>
                {t('other_answer_label')}:{' '}
                <Input
                  {...field}
                  placeholder={t('please_specify_label') ?? 'Please specify'}
                  onChange={(v) => {
                    if (v.target.value) {
                      setValue(
                        `${questionItem.question.id}.offeredAnswerId`,
                        null
                      );
                    }
                    onChange(v);
                  }}
                />
              </Space>
            )}
          />
        </Space>
      );
    }
    case QuestionType.STRUCTURED: {
      return (
        <CustomCarousel
          questionItem={questionItem}
          ticketsAmount={ticketsAmount ?? 1}
          setImportedData={setImportedData}
          surveyType={surveyType}
        >
          {structured}
        </CustomCarousel>
      );
    }
    // case QuestionType.STRUCTURED_MULTIPLEANSWER: {
    //   return (
    //     <Controller
    //       control={control}
    //       name={questionItem.question.id}
    //       render={({ field }) => <Input {...field} />}
    //     />
    //   );
    // }

    default: {
      return (
        <Controller
          control={control}
          name={`${questionItem.question.id}.answerText`}
          render={({ field }) => <Input {...field} />}
        />
      );
    }
  }
};

import React from 'react';
import { Container } from '@seaters-app/ui';
import './styles.css';

import {
  FanGroupFilter,
  FilterTypeEnum,
  FormTranslationType,
  WaitingListFiltersQuery,
} from '@seaters-app/constants';

import useRenderFilter from './helpers';
import { Button, Col, Row, theme } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface FiltersProps {
  filters: FanGroupFilter[];
  selectedFilters: WaitingListFiltersQuery;
  setSelectedFilters: (v: WaitingListFiltersQuery) => void;
  setFiltersInfoShown: (v: boolean) => void;
  externalLink?: {
    value: string;
    label: FormTranslationType;
  };
}

function Filters({
  filters,
  selectedFilters,
  setSelectedFilters,
  setFiltersInfoShown,
  externalLink,
}: FiltersProps) {
  const { token } = theme.useToken();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const { renderFilter } = useRenderFilter(
    selectedFilters,
    setSelectedFilters,
    setFiltersInfoShown
  );
  //  remove when order will be added on BE
  const getOrderedFilters = (
    filters: FanGroupFilter[]
  ): Array<FanGroupFilter & { order: number }> => {
    return filters
      .map((filter) => {
        let count = 3;
        let order;
        if (
          filter.type === FilterTypeEnum.DATE ||
          filter.type === FilterTypeEnum.DATE_LINE
        ) {
          order = 1;
        } else if (filter.type === FilterTypeEnum.TOGGLE) {
          order = filters.length;
        } else if (filter.type === FilterTypeEnum.SEARCHABLE_TEXT) {
          order = 2;
        } else {
          order = count;
          count++;
        }
        return { ...filter, order };
      })
      .sort((a, b) => a.order - b.order);
  };
  const orderedFilters = getOrderedFilters(filters);
  return (
    <Container>
      {orderedFilters && (
        <div style={{ marginTop: 16, marginBottom: 0 }}>
          <Row gutter={24}>
            {orderedFilters.map((filter) => renderFilter(filter))}
          </Row>

          {externalLink && (
            <Col xs={24} md={12} lg={8} xl={8} xxl={8}>
              <Button
                icon={<LinkOutlined rev={undefined} />}
                type="link"
                color={`${token.colorPrimary}`}
                href={externalLink.value}
                target="_blank"
              >
                {externalLink?.label[lang] ?? externalLink?.label.en}
              </Button>
            </Col>
          )}
        </div>
      )}
    </Container>
  );
}

export default Filters;

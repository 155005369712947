import {
  CustomProfileScopeEnum,
  CustomProfileTypeEnum,
  FanGroupCustomInformation,
  ListEntityV2,
} from '@seaters-app/constants';
import { Col, Row, Form, Select, Checkbox } from 'antd';
import {
  Control,
  Controller,
  FieldErrors,
  FieldArrayWithId,
} from 'react-hook-form';
import { Option } from '../../../waiting-lists/components/Filters/helpers';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';

export interface CustomInfoItem {
  scope: CustomProfileScopeEnum;
  semanticName: string;
  type: CustomProfileTypeEnum;
  mandatory: boolean;
}

export interface CustomProfileForm {
  customInfos: CustomInfoItem[];
}

export const guestManagementRequiredCustomInfo = [
  'lastrsvp',
  'contactcost',
  'attendance',
  'rsvps',
  'jobtitle',
  'company',
  'public-official',
];

export const guestManagementRequiredCustomInfoSemantics: string =
  guestManagementRequiredCustomInfo.join(', ');

export const gmFields = guestManagementRequiredCustomInfo.map((item) => {
  return {
    scope: CustomProfileScopeEnum.PUBLIC,
    semanticName: item,
    type: CustomProfileTypeEnum.TEXT,
    mandatory: false,
  };
});

export const getInitialValues = (
  fgCustomInfo?: ListEntityV2<FanGroupCustomInformation>
) => {
  const initialValues = fgCustomInfo
    ? {
        customInfos: fgCustomInfo?.content.map((customInfo) => {
          return {
            scope: customInfo.scope,
            semanticName: customInfo.semanticName,
            type: customInfo.type,
            mandatory: customInfo.mandatory,
          };
        }),
      }
    : {
        customInfos: [],
      };
  return initialValues;
};

const scopeOptions = [
  {
    label: CustomProfileScopeEnum.PUBLIC,
    value: CustomProfileScopeEnum.PUBLIC,
  },
  {
    label: CustomProfileScopeEnum.PRIVATE,
    value: CustomProfileScopeEnum.PRIVATE,
  },
  {
    label: CustomProfileScopeEnum.READ_ONLY,
    value: CustomProfileScopeEnum.READ_ONLY,
  },
];

const typeOptions = [
  {
    label: CustomProfileTypeEnum.TEXT,
    value: CustomProfileTypeEnum.TEXT,
  },
  {
    label: CustomProfileTypeEnum.JSON,
    value: CustomProfileTypeEnum.JSON,
  },
];

export function CustomInfoFields({
  errors,
  field,
  i,
  control,
  fgCustomInfo,
  answerSemanticsOptions,
  arrayName,
  remove,
  values,
}: {
  errors: FieldErrors<CustomProfileForm>;
  field: FieldArrayWithId<CustomProfileForm, 'customInfos', 'id'>;
  i: number;
  control: Control<CustomProfileForm, any>;
  fgCustomInfo: ListEntityV2<FanGroupCustomInformation>;
  answerSemanticsOptions: Option[] | undefined;
  arrayName: 'customInfos';
  remove?: () => void;
  values: CustomProfileForm;
}) {
  const { t } = useTranslation();

  const answerSemanticsOptionsDisabled = answerSemanticsOptions?.map(
    (option) => {
      const usedOptions = fgCustomInfo?.content?.map(
        (item) => item.semanticName
      );
      const selectedOption = values.customInfos.map(
        (info) => info.semanticName
      );

      if (
        usedOptions?.includes(option.label) ||
        selectedOption?.includes(option.value)
      ) {
        return { ...option, disabled: true };
      } else {
        return option;
      }
    }
  );

  const [semanticOptions, setSemanticOptions] = useState(
    answerSemanticsOptionsDisabled
  );

  return (
    <Row
      style={{ verticalAlign: 'baseline' }}
      gutter={24}
      key={field.semanticName}
    >
      <Col span={6}>
        <Form.Item
          {...field}
          label={t('scope_filed_label')}
          name={`${arrayName}.${i}.scope`}
          validateStatus={
            errors[arrayName] &&
            errors[arrayName][i] &&
            errors[arrayName][i]?.scope &&
            'error'
          }
          help={
            errors[arrayName] &&
            errors[arrayName][i] &&
            errors[arrayName][i].scope &&
            errors[arrayName][i]?.scope?.message
          }
        >
          <Controller
            control={control}
            name={`${arrayName}.${i}.scope`}
            render={({ field }) => <Select options={scopeOptions} {...field} />}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          {...field}
          label={t('semantic_name_filed_label')}
          name={`${arrayName}.${i}.semanticName`}
          validateStatus={
            errors[arrayName] &&
            errors[arrayName][i] &&
            errors[arrayName][i]?.semanticName &&
            'error'
          }
          help={
            errors[arrayName] &&
            errors[arrayName][i] &&
            errors[arrayName][i].semanticName &&
            errors[arrayName][i]?.semanticName?.message
          }
        >
          <Controller
            control={control}
            name={`${arrayName}.${i}.semanticName`}
            render={({ field }) => {
              const prevValue = field.value;

              return (
                <Select
                  options={semanticOptions}
                  onSelect={(_, option) => {
                    const disabled = semanticOptions?.map((item) => {
                      if (item.value === option.value) {
                        return { ...item, disabled: true };
                      } else if (item.value === prevValue) {
                        return { ...item, disabled: false };
                      } else {
                        return item;
                      }
                    });

                    setSemanticOptions(disabled);
                  }}
                  {...field}
                  disabled={
                    arrayName === 'customInfos'
                      ? i < fgCustomInfo.content.length
                      : false
                  }
                />
              );
            }}
          />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          label={t('general_type')}
          {...field}
          name={`${arrayName}.${i}.type`}
          validateStatus={
            errors[arrayName] &&
            errors[arrayName][i] &&
            errors[arrayName][i]?.type &&
            'error'
          }
          help={
            errors[arrayName] &&
            errors[arrayName][i] &&
            errors[arrayName][i].type &&
            errors[arrayName][i]?.type?.message
          }
        >
          <Controller
            control={control}
            name={`${arrayName}.${i}.type`}
            render={({ field }) => (
              <Select
                options={typeOptions}
                {...field}
                disabled={
                  arrayName === 'customInfos'
                    ? i < fgCustomInfo.content.length
                    : false
                }
              />
            )}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          valuePropName="checked"
          label={t('survey_management_question_mandatory')}
          name={`${arrayName}.${i}.mandatory`}
        >
          <Controller
            control={control}
            name={`${arrayName}.${i}.mandatory`}
            render={({ field }) => (
              <Checkbox
                {...field}
                defaultChecked={
                  !!getInitialValues(fgCustomInfo).customInfos[i]?.mandatory
                }
                data-testid="signUpAllowSeatersMarketingCheckbox"
              />
            )}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        {i >= fgCustomInfo.content.length &&
          remove &&
          arrayName === 'customInfos' && (
            <Form.Item label="hidden">
              <MinusCircleOutlined
                rev={undefined}
                onClick={() => {
                  remove();
                  const disabled = semanticOptions?.map((item) => {
                    if (item.value === values?.customInfos[i].semanticName) {
                      return { ...item, disabled: false };
                    } else {
                      return item;
                    }
                  });

                  setSemanticOptions(disabled);
                }}
              />
            </Form.Item>
          )}
      </Col>
    </Row>
  );
}

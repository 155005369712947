import { useFetchFanGroupOwnerWaitingLists } from '@seaters-app/data-access';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrayParam,
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

export const useWishLists = ({ fanGroupId }: { fanGroupId?: string }) => {
  const { t } = useTranslation();
  const statusOptions: { label: string; value: string }[] = useMemo(
    () => [
      {
        label: 'wl_status-open',
        value: 'includeOpen',
      },
      {
        label: 'wl_status-draft',
        value: 'includeDraft',
      },
      {
        label: 'wl_status-closed',
        value: 'includeClosed',
      },
      {
        label: 'wl_status-coming_soon',
        value: 'includeSoon',
      },
    ],
    []
  );

  const [query, setQuery] = useQueryParams({
    maxPageSize: withDefault(NumberParam, 10),
    itemOffset: withDefault(NumberParam, 0),
    status: withDefault(ArrayParam, ['includeOpen']),
    search: withDefault(StringParam, ''),
    eventStartDate: DateParam,
  });

  const { maxPageSize, itemOffset, status } = query;

  const requestBody = statusOptions.reduce<{ [key: string]: boolean }>(
    (acc, el) => {
      if (status?.includes(el.value)) {
        acc[el.value] = true;
      } else {
        acc[el.value] = false;
      }
      return acc;
    },
    {}
  );

  const {
    data: wishLists,
    isFetching: wishListsLoading,
    refetch,
  } = useFetchFanGroupOwnerWaitingLists(
    fanGroupId || '',
    {
      ...requestBody,
      exprerienceNamePart: query.search,
      eventStartDate: query.eventStartDate,
    },
    {
      itemOffset,
      maxPageSize,
    }
  );

  useEffect(() => {
    setQuery({ itemOffset: 0, maxPageSize: 10 });
  }, [fanGroupId]);

  return {
    wishLists,
    wishListsLoading,
    maxPageSize,
    itemOffset,
    status,
    setQuery,
    statusOptions,
    query,
    refetch,
  };
};

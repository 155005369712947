import { getInvitationStatus } from '@seaters-app/data-access';
import { useGuestInvitation } from '../helpers/guestInvitation';
import { GuestEntity, InvitationEntity } from '@seaters-app/constants';
import { Popconfirm, Space, theme } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  statusesToAccept,
  statusesToCancel,
  statusesToTickets,
} from '../helpers';
import { useTranslation } from 'react-i18next';
import { CancelButton } from './CancelButton';
import { Button, errorColor, successColor } from '@seaters-app/ui';
import { TicketsButton } from './TicketsButton';
import { ParkingTicketButton } from './ParkingTicketsButton';
import { useParams } from 'react-router-dom';

interface InvitationsActionsProps {
  guest: GuestEntity;
  isToAllocated: boolean;
  setBusinessReason: (reason: string) => void;
  showBusinessReasonModal: () => void;
  showEditModal: (guest: GuestEntity) => void;
  confirmAcceptInvitation: (invitation: InvitationEntity) => void;
  handleDeclineInvitation: (invitationId: string) => void;
  invitationsCount: number;
}

export const InvitationsActions = ({
  guest,
  isToAllocated,
  setBusinessReason,
  showBusinessReasonModal,
  showEditModal,
  confirmAcceptInvitation,
  handleDeclineInvitation,
  invitationsCount,
}: InvitationsActionsProps) => {
  const { waitingListId = '' } = useParams();
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const guestInvitation = useGuestInvitation();
  const invitation = guestInvitation(guest.guestId);

  const status = getInvitationStatus(invitation);
  if (!invitation || !status) return null;
  return (
    <>
      {/* {isToAllocated && (
        <BusinessReasonButton
          invitation={invitation}
          status={status}
          isToAllocated={isToAllocated}
          setBusinessReason={setBusinessReason}
          showBusinessReasonModal={showBusinessReasonModal}
        />
      )} */}
      {isToAllocated && (
        <Button
          icon={<EditOutlined rev={undefined} />}
          type="link"
          onClick={() => {
            showBusinessReasonModal();
          }}
        >
          {t('guestlist_business_reason')}
        </Button>
      )}
      {statusesToCancel.includes(status) && (
        <CancelButton
          waitingListId={waitingListId}
          invitation={invitation}
          invitationsCount={invitationsCount}
        />
      )}
      {statusesToAccept.includes(status) && (
        <Space size="small">
          <Popconfirm
            title={t('popconfirm_invitation_accept-title')}
            description={t('popconfirm_invitation_accept-description')}
            cancelText={t('general_no')}
            onConfirm={() => confirmAcceptInvitation(invitation)}
          >
            <Button
              type="link"
              icon={<CheckOutlined rev={undefined} />}
              color={successColor}
            >
              {t('guestlist_comm_accept')}
            </Button>
          </Popconfirm>
          <Popconfirm
            title={t('popconfirm_invitation_decline-title')}
            description={t('popconfirm_invitation_decline-description')}
            cancelText={t('general_no')}
            onConfirm={() => handleDeclineInvitation(invitation.id)}
          >
            <Button
              type="link"
              icon={<CloseOutlined rev={undefined} />}
              color={errorColor}
            >
              {t('guestlist_comm_decline')}
            </Button>
          </Popconfirm>
        </Space>
      )}
      {statusesToTickets.includes(status) && (
        <Space size="small">
          <TicketsButton
            waitingListId={waitingListId}
            invitation={invitation}
          />
          <ParkingTicketButton
            waitingListId={waitingListId}
            invitation={invitation}
          />
        </Space>
      )}
    </>
  );
};

export const downloadFileWithCustomName = async (
  url: string | null,
  customFilename: string
) => {
  if (!url) return;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(
        `Failed to download file (HTTP status ${response.status})`
      );
    }

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = customFilename;

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

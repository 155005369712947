import { Skeleton, Space } from 'antd';
import { baseColor200, baseColor400 } from '@seaters-app/ui';

import styles from './styles.module.css';

function EventCardSkeleton() {
  return (
    <div
      className={styles.card}
      style={{ borderBottom: `2px solid ${baseColor400}` }}
    >
      <div
        className={styles.imageWrap}
        style={{ backgroundColor: baseColor200 }}
      >
        <Skeleton.Button block className={styles.image} />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Space direction="vertical" size="small" style={{ width: 300 }}>
          <Skeleton.Input
            active
            size="small"
            block
            style={{ height: 20, width: 300 }}
          />
          <Skeleton.Input active size="small" style={{ height: 15 }} />
          <Skeleton.Input active size="small" style={{ height: 15 }} />
        </Space>

        <Space direction="horizontal">
          <Skeleton.Input active size="small" block />
          <Skeleton.Input active size="small" block />
        </Space>
      </div>
    </div>
  );
}

export default EventCardSkeleton;

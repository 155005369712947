import {
  CountryEntity,
  endpoints,
  ListEntity,
  PaginationQuery,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchCountries = (
  params: PaginationQuery
): Promise<ListEntity<CountryEntity>> =>
  api.get(endpoints.countries, { params });

import { Language, VenueStatus } from '../../enums';
import { z } from 'zod';
import { TranslationValidatorSchema } from './translation.validator';

export const VenueValidatorSchema = z.object({
  timeZone: z.string().optional(),
  status: z.nativeEnum(VenueStatus),
  shortName: z.array(TranslationValidatorSchema),
  slug: z.string(),
  country: z.array(TranslationValidatorSchema),
  city: z.array(TranslationValidatorSchema),
  conditions: z.array(TranslationValidatorSchema),
  barcodeType: z.string(),
  longitude: z.number(),
  latitude: z.number(),
  currencyCode: z.string(),
  name: z.array(TranslationValidatorSchema),
  imageFileId: z.any().optional(),
});

export type VenueValidator = z.infer<typeof VenueValidatorSchema>;

const requiredTranslation = Language.EN;

export const NotEmptyDefaultTranslationValidationSchema = z
  .record(z.nativeEnum(Language), z.string().optional())
  .refine(
    (data) => {
      return (
        data &&
        data[requiredTranslation] &&
        data[requiredTranslation].trim() !== ''
      );
    },
    {
      message: 'Default translation (EN) for field must have non-empty text',
      path: [requiredTranslation],
    }
  );

const canvasElementSchema = z.custom(
  (value) => value instanceof HTMLCanvasElement,
  {
    message: 'Expected an HTMLCanvasElement',
  }
);

const stringOrCanvasSchema = z.union([
  z.string().min(1, 'venue_image_form_error_message'),
  canvasElementSchema,
]);

export const VenueFormValidatorSchema = z.object({
  timeZone: z.string().optional(),
  status: z.nativeEnum(VenueStatus),
  shortName: NotEmptyDefaultTranslationValidationSchema,
  slug: z.string().min(1),
  country: NotEmptyDefaultTranslationValidationSchema,
  city: NotEmptyDefaultTranslationValidationSchema,
  conditions: NotEmptyDefaultTranslationValidationSchema,
  // barcodeType: z.string(),
  longitude: z.union([z.string(), z.number()]),
  latitude: z.union([z.string(), z.number()]),
  currencyCode: z.string(),
  name: NotEmptyDefaultTranslationValidationSchema,
  imageUrl: stringOrCanvasSchema,
});

export type VenueFormValidator = z.infer<typeof VenueValidatorSchema>;

export const FGOVenueFormValidatorSchema = z
  .object({
    timeZone: z.string().optional(),
    shortName: NotEmptyDefaultTranslationValidationSchema,
    country: NotEmptyDefaultTranslationValidationSchema,
    city: NotEmptyDefaultTranslationValidationSchema,
    conditions: NotEmptyDefaultTranslationValidationSchema,
    longitude: z.union([z.string(), z.number()]),
    latitude: z.union([z.string(), z.number()]),
    currency: z.string(),
    name: NotEmptyDefaultTranslationValidationSchema,
    imageFileUrl: z.any(),
  })
  .refine(
    (data) => {
      return data && data?.imageFileUrl;
    },
    {
      message: 'Venue image is required',
      path: ['imageFileUrl'],
    }
  );

export type FGOVenueFormValidator = z.infer<typeof FGOVenueFormValidatorSchema>;

export type VenueConfigBody = {
  categories: string[];
  name: string;
  // seatingMap
};

import { Card, Popconfirm, Space, Typography, notification, theme } from 'antd';
import styles from './tickets.module.css';
import React from 'react';

import { Button, baseColor800 } from '@seaters-app/ui';
import Meta from 'antd/es/card/Meta';
import {
  PositionsDistributionMode,
  SurveyAnswersResponse,
  WaitingListEntity,
  DisplayMode,
  JoinWLAsFanResponse,
  WLPositionStatus,
  InvitationMode,
} from '@seaters-app/constants';
import dayjs from 'dayjs';
import { useUnjoinWaitingListAsFan } from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { RefetchingStatus } from '../../WaitingListFanView';

const { Text, Title } = Typography;

interface CancelTicketsComponentProps {
  waitingList: WaitingListEntity;
  setSurveyShown: (v: boolean) => void;
  surveyAnswers?: SurveyAnswersResponse;
  setIsJoined: (v: boolean) => void;
  counter: number;
  numberOfParkingTickets: number;
  isMobile?: boolean;
  setPosition: (v: JoinWLAsFanResponse | null) => void;
  setStartRefetching: (v: RefetchingStatus) => void;
}

function CancelTicketsComponent({
  waitingList,
  setSurveyShown,
  surveyAnswers,
  counter,
  numberOfParkingTickets,
  setIsJoined,
  setPosition,
  isMobile,
  setStartRefetching,
}: CancelTicketsComponentProps) {
  const { t } = useTranslation();

  const {
    positionsDistributionMode,
    participationEndDate,
    price,
    currency: { symbol },
    displayMode,
    freeWaitingList,
    waitingListId,
    groupRankAndLikelihoodHidden,
    position,
    invitationMode,
  } = waitingList;

  const rankIsShown =
    position?.status === WLPositionStatus.WAITING_SEAT &&
    positionsDistributionMode !== PositionsDistributionMode.RANDOM &&
    !groupRankAndLikelihoodHidden &&
    (!invitationMode || invitationMode === InvitationMode.DISABLED);

  const { mutate: unjoinWaitingListAsFan, isLoading } =
    useUnjoinWaitingListAsFan(waitingListId);

  const { token } = theme.useToken();
  const isWithParking =
    positionsDistributionMode === PositionsDistributionMode.WITH_PARKING;
  const isRandomDistribution =
    positionsDistributionMode === PositionsDistributionMode.RANDOM;
  const isRankBasedDistribution =
    positionsDistributionMode === PositionsDistributionMode.FIFS;
  const isInformativeDisplayMode = displayMode === DisplayMode.INFORMATIVE;

  const additionalInfo = isRandomDistribution
    ? t('waitinglist_random_distribution_explanation')
    : t('waitinglist_waiting_confirmation_explanation');

  const unjoinWaitingList = () => {
    unjoinWaitingListAsFan(
      { waitingListId },
      {
        onSuccess: () => {
          setStartRefetching('cancel');
          notification.success({
            message: t('waitinglist_request_cancelled_success'),
          });
          setIsJoined(false);
          setPosition(null);
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('waitinglist_request_cancelled_error'),
          });
        },
      }
    );
  };

  return (
    <Card
      className={!isMobile ? styles.card : styles.cardMobile}
      bordered={!isMobile}
      title={<Text>{t('waitinglist_your_demand_is_recorded')}</Text>}
    >
      <div className={styles.content}>
        {rankIsShown && (
          <Text strong style={{ color: baseColor800 }}>
            {t('waitinglist_rank_label')}: {waitingList.position.rank}
          </Text>
        )}
        {!freeWaitingList && (
          <div className={styles.prices}>
            <div className={styles.price}>
              <Text>{`${price?.facialPrice} ${symbol} x ${counter} guests`}</Text>
              <Text>
                {counter * Number(price?.facialPrice)} {symbol}
              </Text>
            </div>
            <div className={styles.price}>
              <Text>{t('waitinglist_fees')}</Text>
              <Text>
                {counter * Number(price?.fee)} {symbol}
              </Text>
            </div>
            <div className={styles.price}>
              <Text strong>{t('waitinglist_total_price')}</Text>
              <Text strong>
                {(counter *
                  (Number(price?.facialPrice) + Number(price?.fee)) *
                  100) /
                  100}{' '}
                {symbol}
              </Text>
            </div>
          </div>
        )}
        {isRandomDistribution && (
          <Text strong className={styles.distributionDate}>
            {t('wishlist_closes_at_label_text')}:
            <br />
            {`${
              participationEndDate
                ? dayjs(participationEndDate).format('ddd MMM D YYYY h:mm A')
                : ''
            }`}
            <br />
            {t('random_distridution_time_explanation_text')}
          </Text>
        )}

        <Meta
          description={
            !(isInformativeDisplayMode && isRankBasedDistribution) &&
            additionalInfo
          }
          className={styles.additionalInfo}
        />

        <Title level={5}>
          {t('waitinglist_quantity')}: {position?.numberOfSeats ?? counter}
        </Title>
        {isWithParking && (
          <Text>
            {t('waitinglist_parking_quantity')}
            {': '}
            {position?.numberOfParkingTickets ?? numberOfParkingTickets}
          </Text>
        )}

        {surveyAnswers && (
          <Space>
            <Button
              size="large"
              type="text"
              color={token.colorPrimary}
              onClick={() => setSurveyShown(true)}
            >
              {t('button_show_survey_answers')}
            </Button>
          </Space>
        )}
      </div>
      <Popconfirm
        title={t('button_cancel_request')}
        description={t('cancel_tickets_request_confirmation_text')}
        cancelText={t('general_no')}
        onConfirm={unjoinWaitingList}
        onOpenChange={() => console.log('open change')}
      >
        <Button size="large" style={{ width: '100%' }} loading={isLoading}>
          {t('button_cancel_request')}
        </Button>
      </Popconfirm>
    </Card>
  );
}

export default CancelTicketsComponent;

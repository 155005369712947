export function setNestedObject(
  obj: {
    [key: string]: unknown;
  },
  path: string,
  value: string
) {
  let schema = obj; // a moving reference to internal objects within obj

  if (!path) {
    return;
  }

  const pList = path.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
}

import { z } from 'zod';
import { ImportanceEnum } from '../../../enums/importance';

export const WaitingListInvitationValidatorSchema = z.object({
  numberOfSeats: z.number(),
  importance: z.nativeEnum(ImportanceEnum),
  order: z.number().nullable().optional(),
  numberOfNonAllocatedSeats: z.number().optional(),
  invitationReason: z.string().optional(),
  specialGuestId: z.string().optional(),
});

export type WaitingListInvitationValidator = z.infer<
  typeof WaitingListInvitationValidatorSchema
>;

export const InvitationSwapValidatorSchema = z
  .object({
    waitingListInvitationId: z.string(),
    order: z.number(),
  })
  .array();

export type InvitationSwapValidator = z.infer<
  typeof InvitationSwapValidatorSchema
>;

export type FGOInvitationRejectBody = {
  rejectionReason?: string | null;
};

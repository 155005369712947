import { Card, Typography } from 'antd';
import styles from './tickets.module.css';
import { EditOutlined } from '@ant-design/icons';

import { Button } from '@seaters-app/ui';
import { routes } from '@seaters-app/constants';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface EditGuestListComponentProps {
  isMobile?: boolean;
}

function EditGuestListComponent({ isMobile }: EditGuestListComponentProps) {
  const { t } = useTranslation();
  return (
    <Card
      className={!isMobile ? styles.card : styles.cardMobile}
      bordered={!isMobile}
      title={<Text className={styles.cardTitle}>{t('invitations_made')}</Text>}
    >
      <div className={styles.content}>
        <NavLink to={routes.guestlist} style={{ width: '100%' }}>
          <Button
            size="large"
            type="primary"
            style={{ width: '100%' }}
            icon={<EditOutlined rev={undefined} />}
          >
            {t('view_guestlist')}
          </Button>
        </NavLink>
      </div>
    </Card>
  );
}

export default EditGuestListComponent;

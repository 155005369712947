import React, { useMemo, useState } from 'react';
import { Card, Checkbox, Empty, Modal, Select } from 'antd';
import { Button } from '@seaters-app/ui';
import { Question, QuestionItem, QuestionType } from '@seaters-app/constants';
import QuestionForm from '../QuestionForm/QuestionForm';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import {
  getSingleTranslation,
  useFetchAdminSurveysQuestions,
  useFetchQuestionChoices,
} from '@seaters-app/data-access';
interface AddNewQuestionModalProps {
  newQuestionCreation: boolean;
  closeModal: () => void;
  selectedQuestion: QuestionItem | null;
  setSelectedQuestion: (v: QuestionItem | null) => void;
  handleUpdateSurvey: (v: QuestionItem) => void;
  setCreateFromScratch: (v: boolean) => void;
  setNewQuestionCreationModal: (v: boolean) => void;
  isCreateFromScratch: boolean;
  questionsOptions?:
    | {
        label: string;
        value: string;
        question: Question;
      }[];
  loadMore?: () => void;
  setQuestionModalOpen: (v: boolean) => void;
}

function AddNewQuestionModal({
  newQuestionCreation,
  closeModal,
  selectedQuestion,
  setSelectedQuestion,
  handleUpdateSurvey,
  setCreateFromScratch,
  setNewQuestionCreationModal,
  isCreateFromScratch,
  loadMore,
  setQuestionModalOpen,
}: AddNewQuestionModalProps) {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(true);
  const [mandatory, setMandatory] = useState(false);

  const { data: selectedQuestionAnswers } = useFetchQuestionChoices(
    selectedQuestion?.question?.id ?? '',
    { size: 999, page: 0 }
  );
  const { data: questions } = useFetchAdminSurveysQuestions({
    itemOffset: 0,
    maxPageSize: 9999,
  });

  const questionsOptions = useMemo(() => {
    return questions?.items.map((question, i) => ({
      label: getSingleTranslation(question.text),
      value: question.id,
      question: question,
    }));
  }, [questions?.items]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      loadMore
    ) {
      loadMore();
    }
  };
  return (
    <Modal
      destroyOnClose
      open={newQuestionCreation}
      title={`${t('select_from_list_placeholder_or_create_new')}`}
      onCancel={closeModal}
      width={700}
      footer={[
        <Button
          disabled={!selectedQuestion}
          type="primary"
          onClick={() => {
            selectedQuestion &&
              handleUpdateSurvey({
                question: selectedQuestion?.question,
                mandatory,
                enabled,
              });
          }}
        >
          {t('add_question_button_text')}
        </Button>,
        !selectedQuestion && (
          <Button
            onClick={() => {
              setCreateFromScratch(true);
              setQuestionModalOpen(true);
              setNewQuestionCreationModal(false);
            }}
          >
            {t('create_new_button_text')}
          </Button>
        ),
      ]}
    >
      <Select
        placeholder={t('search_question_placeholder')}
        showSearch
        allowClear
        onClear={() => {
          setSelectedQuestion(null);
        }}
        optionFilterProp="label"
        className={styles.select}
        onSelect={(_, item) => {
          setSelectedQuestion({
            question: item.question,
            mandatory: mandatory,
            enabled: enabled,
          });
        }}
        options={questionsOptions}
        onPopupScroll={handleScroll}
      />

      {selectedQuestion &&
      (selectedQuestionAnswers ||
        selectedQuestion.question.type === QuestionType.STRUCTURED ||
        selectedQuestion.question.type === QuestionType.FREE_TEXT) ? (
        <>
          <Card
            style={{ padding: 0 }}
            key={selectedQuestion?.question?.id ?? 'newQuestion'}
          >
            <QuestionForm
              isCreateFromScratch={isCreateFromScratch}
              isPreview
              question={selectedQuestion}
            />
          </Card>
          <Checkbox
            defaultChecked={enabled}
            value={enabled}
            onChange={() => setEnabled(!enabled)}
          >
            {t('survey_management_question_enabled')}
          </Checkbox>
          <Checkbox
            defaultChecked={mandatory}
            value={mandatory}
            onChange={() => setMandatory(!mandatory)}
          >
            {t('survey_management_question_mandatory')}
          </Checkbox>
        </>
      ) : (
        <Empty
          style={{ margin: 100 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{t('select_question_emply_state_text')}</span>}
        />
      )}
    </Modal>
  );
}

export default AddNewQuestionModal;

import { errorColor } from '@seaters-app/ui';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

export function PositionStatusTag({
  seatsRequestStatus,
}: {
  seatsRequestStatus: 'DECLINED' | null;
}) {
  const { t } = useTranslation();
  if (!seatsRequestStatus) return null;
  return <Tag color={errorColor}>{t('wl_status-declined')}</Tag>;
}

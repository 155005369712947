import {
  AxiosAPIError,
  LoginValidator,
  VerificationTypeV2,
} from '@seaters-app/constants';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { loginV2, refreshToken } from '../api';

export const useSignIn = (): UseMutationResult<
  VerificationTypeV2,
  AxiosAPIError<LoginValidator>,
  LoginValidator,
  unknown
> => {
  return useMutation((body: LoginValidator) => loginV2(body));
};

export const useRefreshToken = (): UseMutationResult<
  VerificationTypeV2,
  AxiosAPIError<{
    token: string;
  }>,
  string,
  unknown
> => {
  return useMutation((token: string) => refreshToken(token));
};

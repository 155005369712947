import {
  AnswerSemanticEntity,
  ChoiceEntity,
  ChoiceListEntity,
  ChoicesQuery,
  endpoints,
  FanAnswers,
  FansAnswersQuery,
  ListEntity,
  ListEntityV2,
  Question,
  QuestionChoicesDataValidator,
  QuestionFormDataType,
  seatersAdminPrefix,
  SurveyAdvancedQuery,
  SurveyAnswersResponse,
  SurveyAnswersValidator,
  SurveyEntity,
  SurveyInstanceBody,
  SurveyInstanceEntity,
  SurveyInstanceQuery,
  SurveyInstanceValidator,
  SurveyPaginationQuery,
  SurveyQuery,
  SurveyValidator,
  UserRole,
  V2Prefix,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchSurveyInstancesForWishList = (
  params: Omit<SurveyQuery, 'fangroup_id'>
): Promise<ListEntityV2<SurveyInstanceEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.survey}/${endpoints.instances}`,
    { params }
  );

// GET https://api.qa-seaters.com/api/v2/fan-short-session/survey/instances/

export const fetchSurveyInstancesWithoutHeaders = (
  params: Omit<SurveyQuery, 'fangroup_id'>
): Promise<ListEntityV2<SurveyInstanceEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanShortSession}/${endpoints.survey}/${endpoints.instances}`,
    { params }
  );

export const fetchSurveyInstancesForFanGroup = (
  params: Omit<SurveyQuery, 'waitinglist_id'>
): Promise<ListEntityV2<SurveyInstanceEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.survey}/${endpoints.instances}`,
    { params }
  );

export const fetchQuestionChoices = (
  questionId: string,
  params: ChoicesQuery
): Promise<ListEntityV2<ChoiceEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.surveys}/${endpoints.question}/${questionId}/${endpoints.choices}`,
    { params }
  );

// GET https://api.qa-seaters.com/api/v2/fan-short-session/surveys/question/questionId/choices/
export const fetchQuestionChoicesWithoutHeaders = (
  questionId: string,
  params: ChoicesQuery
): Promise<ListEntityV2<ChoiceEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanShortSession}/${endpoints.surveys}/${endpoints.question}/${questionId}/${endpoints.choices}`,
    { params }
  );

export const fetchSurveyAnswers = (
  instanceId: string
): Promise<ListEntityV2<SurveyAnswersResponse>> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.surveys}/${endpoints.instances}/${instanceId}/${endpoints.answers}`
  );

// GET https://api.qa-seaters.com/api/v2/fan-short-session/surveys/instances/instanceId/answers/

export const fetchSurveyAnswersWithoutHeaders = (
  instanceId: string,
  code: string
): Promise<ListEntityV2<SurveyAnswersResponse>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanShortSession}/${endpoints.surveys}/${endpoints.instances}/${instanceId}/${endpoints.answers}?code=${code}`
  );

export const sendAnswers = (
  instanceId: string,
  body: SurveyAnswersValidator
): Promise<SurveyAnswersResponse> =>
  api.post(
    `${V2Prefix}/${endpoints.fan}/${endpoints.surveys}/${endpoints.instances}/${instanceId}/${endpoints.answers}`,
    body
  );

// POST https://api.qa-seaters.com/api/v2/fan-short-session/surveys/instances/instanceId/answers/

export const sendAnswersWithoutHeaders = (
  instanceId: string,
  code: string,
  body: SurveyAnswersValidator
): Promise<SurveyAnswersResponse> =>
  api.post(
    `${V2Prefix}/${endpoints.fanShortSession}/${endpoints.surveys}/${endpoints.instances}/${instanceId}/${endpoints.answers}?code=${code}`,
    body
  );
//POST https://api.qa-seaters.com/api/v2/fan-group-owner/surveys/instances/instanceId/answers/fan/fanId/
export const sendFanAnswersAsFGO = (
  instanceId: string,
  fanId: string,
  body: SurveyAnswersValidator
): Promise<SurveyAnswersResponse> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.surveys}/${endpoints.instances}/${instanceId}/${endpoints.answers}/${endpoints.fan}/${fanId}`,
    body
  );

//  POST v2/host/surveys/instances/{instanceId}/answers/fan/{fanId}/
export const sendGuestAnswersAsHost = (
  instanceId: string,
  fanId: string,
  body: SurveyAnswersValidator
): Promise<SurveyAnswersResponse> =>
  api.post(
    `${V2Prefix}/${endpoints.host}/${endpoints.surveys}/${endpoints.instances}/${instanceId}/${endpoints.answers}/${endpoints.fan}/${fanId}`,
    body
  );

export const fetchAdminSurveys = (
  params: SurveyPaginationQuery
): Promise<ListEntity<SurveyEntity>> =>
  api.get(`${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}`, { params });

export const fetchFanGroupOwnerSurveys = (
  params: SurveyPaginationQuery
): Promise<ListEntity<SurveyEntity>> =>
  api.get(`${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.surveys}`, {
    params,
  });

// https://api.qa-seaters.com/api/v2/seaters-admin/groups/search/

export const fetchAdvancedSurveys = (
  userRole: UserRole.FAN_GROUP_OWNER | UserRole.ADMIN,
  params: SurveyAdvancedQuery
): Promise<ListEntity<SurveyEntity>> => {
  const endpointToUse =
    userRole === UserRole.FAN_GROUP_OWNER
      ? endpoints.fanGroupOwner
      : endpoints.seatersAdmin;
  return api.put(
    `${V2Prefix}/${endpointToUse}/${endpoints.surveys}/${endpoints.search}?maxPageSize=${params.maxPageSize}&itemOffset=${params.itemOffset}`,
    { query: params.query }
  );
};

export const fetchAdminSurveysInstances = (
  params: SurveyInstanceQuery
): Promise<ListEntity<SurveyInstanceEntity>> =>
  api.get(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.instances}`,
    { params }
  );

export const fetchAdminSurveyById = (surveyId: string): Promise<SurveyEntity> =>
  api.get(`${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${surveyId}`);

export const fetchAdminSurveyInstanceById = (
  surveyInstanceId: string
): Promise<SurveyInstanceEntity> =>
  api.get(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.instances}/${surveyInstanceId}`
  );

export const fetchAdminSurveysQuestions = (
  params: SurveyPaginationQuery
): Promise<ListEntity<Question>> =>
  api.get(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.questions}`,
    { params }
  );

export const fetchAdminAnswerSemantics = (
  params: SurveyPaginationQuery
): Promise<ListEntity<AnswerSemanticEntity>> =>
  api.get(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.answerSemantics}`,
    { params }
  );

export const fetchFGOWishListSurveysInstances = (
  waitinglistId: string,
  params: SurveyQuery
): Promise<ListEntityV2<SurveyInstanceEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitinglistId}/${endpoints.surveys}/${endpoints.instances}`,
    { params }
  );

export const createAdminSurvey = (
  body: SurveyValidator
): Promise<SurveyEntity> =>
  api.post(`${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}`, body);

export const updateAdminSurvey = (
  surveyId: string,
  body: SurveyValidator
): Promise<SurveyEntity> =>
  api.put(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${surveyId}`,
    body
  );

export const createAdminSurveyInstance = (
  body: SurveyInstanceValidator
): Promise<SurveyInstanceEntity> =>
  api.post(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.instances}`,
    body
  );

export const createFGOSurveyInstance = (
  body: SurveyInstanceValidator
): Promise<SurveyInstanceEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.surveys}/${endpoints.instances}`,
    body
  );

export const updateAdminSurveyInstance = (
  surveyInstanceId: string,
  body: SurveyInstanceValidator
): Promise<SurveyInstanceEntity> =>
  api.put(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.instances}/${surveyInstanceId}`,
    body
  );

export const updateFGOnSurveyInstance = (
  surveyInstanceId: string,
  body: SurveyInstanceBody
): Promise<SurveyInstanceEntity> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.surveys}/${endpoints.instances}/${surveyInstanceId}`,
    body
  );

export const deleteAdminSurveyInstance = (surveyInstanceId: string) =>
  api.delete(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.instances}/${surveyInstanceId}`
  );

export const createAdminQuestion = (
  body: QuestionFormDataType
): Promise<Question> =>
  api.post(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.questions}`,
    body
  );

export const updateAdminQuestion = (
  questionId: string,
  body: QuestionFormDataType
): Promise<Question> =>
  api.put(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.questions}/${questionId}`,
    body
  );

export const createAdminQuestionChoices = (
  questionId: string,
  body: QuestionChoicesDataValidator
): Promise<ChoiceListEntity[]> =>
  api.post(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.questions}/${questionId}/${endpoints.choices}`,
    body
  );

export const updateAdminQuestionChoices = (
  questionId: string,
  body: QuestionChoicesDataValidator
): Promise<ChoiceListEntity[]> =>
  api.put(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.questions}/${questionId}/${endpoints.choices}`,
    body
  );

export const deleteAdminQuestion = (questionId: string) =>
  api.delete(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.surveys}/${endpoints.questions}/${questionId}`
  );

// GEThttps://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/waitinglistId/surveys/instances/surveyInstanceId/answers/

export const fetchSurveyFansAnswers = (
  waitinglistId: string,
  surveyInstanceId: string,
  params: FansAnswersQuery
): Promise<ListEntityV2<FanAnswers>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitinglistId}/${endpoints.surveys}/${endpoints.instances}/${surveyInstanceId}/${endpoints.answers}`,
    { params }
  );

import { BadgeStatusEnum, BadgeInstance } from '@seaters-app/constants';

export const getBadgeStatus = (badge: {
  entities: BadgeInstance[];
  badgeId: string;
}) => {
  // check if at least one badge is active
  const isActive = badge.entities?.find(
    (badge: BadgeInstance) => badge.status === BadgeStatusEnum.ACTIVE
  );
  return isActive ? BadgeStatusEnum.ACTIVE : BadgeStatusEnum.ARCHIVED;
};

import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useDayjsLocale = () => {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  dayjs.locale(lang);
};

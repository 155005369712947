import { z } from 'zod';

export const RequestAccessSchema = z.object({
  code: z.string(),
});

export type RequestAccessValidator = z.infer<typeof RequestAccessSchema>;

export const RequestAccessForFanSchema = z.object({
  userId: z.string(),
  body: RequestAccessSchema,
});

export type RequestAccessForFanValidator = z.infer<
  typeof RequestAccessForFanSchema
>;

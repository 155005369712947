import {
  FormTranslationType,
  FormattedValuesWithTranslations,
  VenueEntity,
  VenueStatus,
} from '@seaters-app/constants';
import { initialTranslations } from './../../../utils/helpers/getTranslations';

export interface VenueFormType {
  addressLine: string;
  name: FormTranslationType;
  shortName: FormTranslationType;
  slug: string;
  status: VenueStatus;
  barcodeType: string;
  city: FormTranslationType;
  country: FormTranslationType;
  conditions: FormTranslationType;
  imageUrl: string;
  timeZone: string;
  longitude?: number;
  latitude?: number;
  currencyCode: string;
}

export const getInitialValues = (
  venue?: VenueEntity | null,
  valuesWithTranslations?: FormattedValuesWithTranslations | null
): VenueFormType => {
  return venue && valuesWithTranslations
    ? {
        ...venue,
        name: valuesWithTranslations.name,
        shortName: valuesWithTranslations.shortName,
        city: valuesWithTranslations.city,
        country: valuesWithTranslations.country,
        conditions: valuesWithTranslations.conditions,
        longitude: Number(venue.longitude),
        latitude: Number(venue.latitude),
        timeZone: venue.timeZone,
        addressLine: '',
      }
    : {
        name: initialTranslations,
        shortName: initialTranslations,
        slug: '',
        status: VenueStatus.ACTIVE,
        city: initialTranslations,
        addressLine: '',
        country: initialTranslations,
        conditions: initialTranslations,
        imageUrl: '',
        timeZone: '',
        currencyCode: 'EUR',
      };
};

const placeAddressComponentOfType = (
  place: google.maps.places.PlaceResult,
  type: string
) => {
  /* jshint camelcase: false */
  const matched = place.address_components?.filter(function (component) {
    return component.types.indexOf(type) >= 0;
  });
  /* jshint camelcase: true */
  if (matched && matched.length > 0) {
    if (matched.length > 1) {
      console.log('more than one address component of given type', type, place);
    }
    return matched[0];
  }
};

export const simplifySearchResult = (
  place: string | google.maps.places.PlaceResult
) => {
  if (typeof place !== 'string') {
    /* jshint camelcase: false */
    return {
      name: place.name,
      formatted_address: place.formatted_address,
      city: (
        placeAddressComponentOfType(place, 'locality') ||
        placeAddressComponentOfType(place, 'postal_town') ||
        placeAddressComponentOfType(place, 'administrative_area_level_3') ||
        {}
      ).long_name,
      postalCode: (placeAddressComponentOfType(place, 'postal_code') || {})
        .long_name,
      country: (placeAddressComponentOfType(place, 'country') || {}).long_name,
      countryShortName: (placeAddressComponentOfType(place, 'country') || {})
        .short_name,
      countryCode: (placeAddressComponentOfType(place, 'country') || {})
        .short_name,
      state: (
        placeAddressComponentOfType(place, 'administrative_area_level_1') || {}
      ).long_name,
    };
    /* jshint camelcase: true */
  }
};

import {
  FanGroupMember,
  FanGroupOwnerWaitingListEntity,
} from '@seaters-app/constants';
import {
  useCreateFGOFanGroupMembers,
  useFanGroupStore,
  useFetchFGOWaitingListExpiredPositions,
} from '@seaters-app/data-access';
import { baseColor600 } from '@seaters-app/ui';
import {
  Form,
  InputNumber,
  notification,
  Select,
  Spin,
  Typography,
} from 'antd';
import Modal from 'antd/es/modal/Modal';
import debounce from 'lodash/debounce';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFGMembers } from './hooks/useFGMembers';

const { Text } = Typography;

export interface CreatePositionModalProps {
  open: boolean;
  onCancel: () => void;
  wishList: FanGroupOwnerWaitingListEntity;
}

type FormValuesType = {
  fanId: string;
  numberOfSeats: number;
  expiredFanId: string;
};

export function CreatePositionModal({
  open,
  onCancel,
  wishList,
}: CreatePositionModalProps) {
  const { fanGroup: storeFanGroup } = useFanGroupStore();
  const { wishListId = '' } = useParams();
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');

  const {
    fgMembersOptions,
    loadMoreData: loadMoreMembers,
    fgMembersFetching,
  } = useFGMembers({
    page: 0,
    size: 20,
    query: search,
  });

  const { data: expiredPositions, isFetching: isExpiredPositionsFetching } =
    useFetchFGOWaitingListExpiredPositions(wishListId);

  const expiredPositinsOptions = expiredPositions?.content.map(
    (el: FanGroupMember) => ({
      label: `${el.firstName} ${el.lastName}`,
      value: el.fanId,
      email: el.fanEmail,
    })
  );

  const { mutate: createPosition } = useCreateFGOFanGroupMembers(
    storeFanGroup?.id || '',
    wishListId
  );

  const [form] = Form.useForm<FormValuesType>();

  const fanId = Form.useWatch('fanId', form);

  const expiredFanId = Form.useWatch('expiredFanId', form);

  const handleSubmit = (value: FormValuesType) => {
    createPosition(
      {
        fanId: value.fanId ?? value.expiredFanId,
        body: {
          numberOfSeats: value.numberOfSeats,
          nullableOriginalRank: null,
          nullableRank: null,
        },
      },
      {
        onError: ({ response }) => {
          notification.error({
            message: t('notification_error_position_creation'),
            description: response?.data.message,
          });
        },
        onSuccess: () => {
          notification.success({
            message: t('notification_success_position_creation'),
          });
          onCancel();
        },
      }
    );
  };

  const handleScrollMembers = (e: any) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      loadMoreMembers();
    }
  };

  const handleSearchMembers = debounce((searchValue) => {
    setSearch(searchValue);
  }, 500);

  const handleClear = () => {
    setSearch('');
  };

  return (
    <Modal
      title={t('create_position_title')}
      open={open}
      onCancel={onCancel}
      okText={t('main_breadcrumbs-create')}
      onOk={() => form.submit()}
    >
      <Form
        initialValues={{
          voucherExpirationTimeOutInMs: 0,
        }}
        form={form}
        onFinish={async (v) => {
          handleSubmit(v);
        }}
      >
        <Form.Item
          label={t('admin_user_role_fan')}
          name="fanId"
          dependencies={['expiredFanId']}
          rules={[
            () => ({
              validator(_, value) {
                if (expiredFanId || value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(t('field_validation_required'));
                }
              },
            }),
          ]}
        >
          <Select
            disabled={!!expiredFanId}
            allowClear
            onClear={handleClear}
            showSearch
            filterOption={false}
            onPopupScroll={handleScrollMembers}
            onSearch={handleSearchMembers}
            notFoundContent={fgMembersFetching ? <Spin size="small" /> : null}
            options={fgMembersOptions?.map((el) => ({
              label: `${el.firstName} ${el.lastName}`,
              value: el.fanId,
              email: el.fanEmail,
            }))}
            optionRender={({ data }) => {
              return (
                <>
                  <Text>{data?.label}</Text>{' '}
                  <Text style={{ color: baseColor600 }}>{data.email}</Text>
                </>
              );
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('admin_user_role_fan') + t('expired_wl_status_text')}
          name="expiredFanId"
          dependencies={['fanId']}
          rules={[
            () => ({
              validator(_, value) {
                if (fanId || value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(t('field_validation_required'));
                }
              },
            }),
          ]}
        >
          <Select
            allowClear
            disabled={!!fanId || !expiredPositinsOptions?.length}
            filterOption={false}
            notFoundContent={
              isExpiredPositionsFetching ? <Spin size="small" /> : null
            }
            options={expiredPositinsOptions}
            optionRender={({ data }) => {
              return (
                <>
                  <Text>{data?.label}</Text>{' '}
                  <Text style={{ color: baseColor600 }}>{data.email}</Text>
                </>
              );
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('wl_set_seats')}
          name="numberOfSeats"
          rules={[{ required: true, message: t('field_validation_required') }]}
        >
          <InputNumber
            min={wishList.minNumberOfSeatsPerPosition}
            max={wishList.maxNumberOfSeatsPerPosition}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

import React from 'react';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Table,
  Typography,
  Space,
  Popconfirm,
  theme,
  notification,
  Flex,
  Avatar,
} from 'antd';
import { Link } from 'react-router-dom';

import { FanGroupStatus, routes } from '@seaters-app/constants';
import { useFanGroups } from './hooks/useFanGroups';
import { getStatusStyle } from './helpers/getStatusStyle';
import { Button } from '@seaters-app/ui';
import {
  getSingleTranslation,
  useAppThemeStore,
  useDeleteFanGroup,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import Search from 'antd/es/input/Search';

const { Title } = Typography;
const { Column } = Table;

interface DataType {
  key: React.Key;
  name: string;
  id: string;
  slug: string;
  status: string;
}

export function FanGroups() {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const {
    data,
    isLoading,
    handleTableChange,
    pagination,
    queryParams,
    setQuery,
  } = useFanGroups();
  const { mode } = useAppThemeStore();
  const { mutateAsync: deleteFanGroup } = useDeleteFanGroup();

  const handleDeleteFanGroup = (fanGroupId: string) =>
    deleteFanGroup(
      { fanGroupId },
      {
        onSuccess: () => {
          notification.success({
            message: `Fan group removed`,
          });
        },
        onError: () => {
          notification.error({
            message: `Fan group was not removed`,
          });
        },
      }
    );

  const onSearch = (value: string) => {
    setQuery({ query: value });
  };

  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
    >
      <Title level={2}>{t('admin_fan-groups')}</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Search
          defaultValue={queryParams.query}
          placeholder={t('event_venue_search-btn')}
          onSearch={onSearch}
          size="large"
          style={{ width: '30%' }}
        />
        <Link to={routes.create}>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined rev={undefined} />}
          >
            {t('add_fg_button_text')}
          </Button>
        </Link>
      </div>
      <div>
        <Table
          rowKey={(fanGroup) => fanGroup.id}
          dataSource={data?.items}
          loading={isLoading}
          pagination={pagination}
          onChange={handleTableChange}
          tableLayout="fixed"
        >
          <Column
            title={t('fgc_graphics_logo')}
            dataIndex="logoImageUrl"
            key="logoImageUrl"
            render={(logoImageUrl) => {
              return (
                <Space size="small">
                  <Avatar
                    src={logoImageUrl}
                    style={{ height: 40, width: 40 }}
                  />
                </Space>
              );
            }}
          />
          <Column
            title={t('admin_fan-groups_name')}
            dataIndex="name"
            key="name"
            width="30%"
            render={(name) => {
              return <span>{getSingleTranslation(name)}</span>;
            }}
          />
          <Column
            title={t('admin_fan-groups_slug')}
            dataIndex="slug"
            key="slug"
          />
          <Column
            title={t('wl_status-label')}
            dataIndex="status"
            key="status"
            width="20%"
            render={(status: FanGroupStatus) => {
              return (
                <span style={getStatusStyle(status, mode)}>
                  {status.toLowerCase()}
                </span>
              );
            }}
          />
          <Column
            title=""
            dataIndex="details"
            key="details"
            align="right"
            render={(name: string, fanGroup: DataType) => {
              return (
                <Space size="small">
                  <Link to={fanGroup.id}>
                    <Button type="link" icon={<EditOutlined rev={undefined} />}>
                      {t('admin_item_edit')}
                    </Button>
                  </Link>
                  <Popconfirm
                    title={t('delete_fg_confirm_label')}
                    description={t('delete_fg_confirm_description')}
                    cancelText={t('general_no')}
                    onConfirm={() => handleDeleteFanGroup(fanGroup.id)}
                    onOpenChange={() => console.log('open change')}
                  >
                    <Button
                      type="link"
                      color={token.colorError}
                      icon={<DeleteOutlined rev={undefined} />}
                    >
                      {t('wl_delete-btn')}
                    </Button>
                  </Popconfirm>
                </Space>
              );
            }}
          />
        </Table>
      </div>
    </Flex>
  );
}

import {
  Button,
  Flex,
  Image,
  Modal,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { EnvironmentOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  AttendeeRequiredFieldsType,
  FanGroupOwnerEventEntity,
  UserRole,
  routes,
} from '@seaters-app/constants';
import { baseColor200, baseColor400, baseColor600 } from '@seaters-app/ui';
import imagePlaceholder from '../../../../../public/imagePlaceholder.svg';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import {
  getSingleTranslation,
  useAppUserStore,
  useFetchEvent,
} from '@seaters-app/data-access';
import InfoBlock from '../../../waiting-lists/[id]/components/InfoBlock/InfoBlock';
import { Link } from 'react-router-dom';

const { Text } = Typography;

type EventDetailedInfoModalProps = {
  isEventSet?: boolean;
  isModalOpen: boolean;
  handleCancel: () => void;
  event: FanGroupOwnerEventEntity | null;
  handleOnSelect: (id: string) => void;
};

export const attendeeInfoLabel: {
  [key in AttendeeRequiredFieldsType]: string;
} = {
  company: 'event_reqinfo_company',
  title: 'event_reqinfo_title',
  city: 'event_reqinfo_city',
  email: 'event_reqinfo_email',
  firstName: 'event_reqinfo_firstname',
  lastName: 'event_reqinfo_lastname',
  language: 'event_reqinfo_language',
  country: 'event_reqinfo_country',
  idNumber: 'event_reqinfo_idnumber',
  birthDate: 'event_reqinfo_dateofbirth',
  citizenshipCountryCode: 'event_reqinfo_citizenship',
  phoneNumber: 'event_reqinfo_phonenumber',
  zipCode: 'event_reqinfo_zipcode',
  address: 'event_reqinfo_address',
  state: 'event_reqinfo_state',
};

function EventDetailedInfoModal({
  isModalOpen,
  handleCancel,
  event,
  handleOnSelect,
  isEventSet,
}: EventDetailedInfoModalProps) {
  const { t } = useTranslation();

  const { user } = useAppUserStore();

  const isAdmin = user?.roles.includes(UserRole.ADMIN);

  const { data: eventData } = useFetchEvent(event?.eventId ?? '');

  const eventImg = event?.eventImageUrl || event?.venueImageUrl;

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <div
          className={styles.modalFooter}
          style={{
            borderTop: `1px solid ${baseColor400}`,
          }}
        >
          <Flex justify="space-between" style={{ width: '100%' }}>
            {isAdmin && (
              <Link to={`/${routes.admin}/${routes.events}/${event?.eventId}`}>
                <Button type="default">{t('wl_settings_edit-event')}</Button>
              </Link>
            )}
            <Space>
              <Button type="default" onClick={handleCancel}>
                {t('general_close-btn')}
              </Button>
              {!isEventSet && (
                <Button
                  type="primary"
                  onClick={() =>
                    event?.eventId && handleOnSelect(event?.eventId)
                  }
                >
                  {t('select_button_text')}
                </Button>
              )}
            </Space>
          </Flex>
        </div>,
      ]}
      width={1113}
      style={{ top: 20, borderRadius: 8 }}
    >
      <div
        className={styles.modalImageWrap}
        style={{ backgroundColor: baseColor200 }}
      >
        {eventImg ? (
          <img
            src={eventImg}
            alt={event?.eventName}
            className={styles.modalImage}
          />
        ) : (
          <img
            className={styles.modalImagePlaceholder}
            alt={event?.eventName}
            src={imagePlaceholder}
          />
        )}
      </div>
      <div className={styles.modalContent}>
        <Space direction="vertical" size="middle">
          <Space direction="vertical" size="small">
            <InfoBlock
              id={eventData?.id ?? ''}
              title={'Event information'}
              text={getSingleTranslation(eventData?.description ?? [])}
            />
          </Space>

          <Space direction="vertical" size="small">
            <Text strong>Venue</Text>
            <Space size="small" style={{ color: baseColor600 }}>
              <EnvironmentOutlined rev={undefined} />
              <p>{event?.venueName}</p>
            </Space>
          </Space>

          {event?.venueImageUrl && (
            <Image
              width="auto"
              height={280}
              src={event?.venueImageUrl}
              style={{ borderRadius: 8, backgroundColor: baseColor200 }}
              preview={false}
            />
          )}
          {event?.attendeeRequiredFields && (
            <Space direction="vertical" size="small">
              <Text strong>
                {t('event_timeline_requestedinfo_title')}{' '}
                <Tooltip title={t('wl_event_requiredinfo-help')}>
                  <InfoCircleOutlined rev={undefined} />
                </Tooltip>
              </Text>
              <Space size={[0, 8]} wrap>
                {event?.attendeeRequiredFields.map((field) => (
                  <Tag>{t(attendeeInfoLabel[field])}</Tag>
                ))}
              </Space>
            </Space>
          )}
        </Space>
      </div>
    </Modal>
  );
}

export default EventDetailedInfoModal;

export enum InterestStatus {
  DISLIKE = 'DISLIKE',
  NEUTRAL = 'NEUTRAL',
  LIKE = 'LIKE',
  UNKNOWN = 'UNKNOWN',
}

export enum FanAttributeStatus {
  LINKED = 'LINKED',
  UNLINKED = 'UNLINKED',
}

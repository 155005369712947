import React, { useState } from 'react';
import {
  Typography,
  Popconfirm,
  theme,
  notification,
  Tabs,
  TabsProps,
} from 'antd';
import styles from './profile.module.css';
import { SESSION_STORAGE_VERIFICATION, routes } from '@seaters-app/constants';
import { useDeleteFanProfile } from '@seaters-app/data-access';
import { Button, Container } from '@seaters-app/ui';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { useIsFetching } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
type ContextType = { message: boolean; setMessage: (v: boolean) => void };

const { Title } = Typography;

export function ProfileLayout() {
  const { t } = useTranslation();
  const [message, setMessage] = useState(false);
  const isFetching = !!useIsFetching();
  const { token } = theme.useToken();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { mutateAsync: deleteProfile } = useDeleteFanProfile();
  const onChangeTab = (key: string) => {
    navigate(key);
  };

  const items: TabsProps['items'] = [
    {
      key: routes.general,
      label: t('menus_label_profile_general_info'),
    },
    {
      key: routes.interests,
      label: t('menus_label_interests'),
    },
  ];

  const activeTab = items?.find((item) => pathname.includes(item.key))?.key;
  // Handlers

  const handleDeleteProfile = () =>
    deleteProfile(undefined, {
      onSuccess: () => {
        notification.success({
          message: 'Profile removed',
        });
        sessionStorage.removeItem(SESSION_STORAGE_VERIFICATION);
      },
      onError: () => {
        notification.error({
          message: 'Profile was not removed',
        });
      },
    });

  return (
    <div className={styles.profile}>
      <Container>
        <div className={styles.subheader}>
          <Title level={2}>{t('menus_label_my_profile')}</Title>
          <Popconfirm
            title={t('fan_profile_remove_account_title')}
            description={t('fan_profile_remove_account_confirmation')}
            cancelText={t('general_no')}
            onConfirm={() => handleDeleteProfile()}
            onOpenChange={() => console.log('open change')}
          >
            <Button
              size="large"
              type="text"
              icon={<DeleteOutlined rev={undefined} />}
              color={token.colorError}
              disabled={isFetching}
            >
              {t('fan_profile_remove_account_action')}
            </Button>
          </Popconfirm>
        </div>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={items[0].key}
          items={items}
          onChange={onChangeTab}
        />
        <Outlet context={{ message, setMessage }} />
      </Container>
    </div>
  );
}

export function useMessage() {
  return useOutletContext<ContextType>();
}

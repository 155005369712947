import React, { useEffect } from 'react';
import { Breadcrumb, Flex, Steps } from 'antd';
import { EventStep } from './steps/EventStep';
import { SettingsStep } from './steps/SettingsStep';
import { TicketsStep } from './steps/TicketsStep';
import { PublishStep } from './steps/PublishStep';
import styles from './createWishList.module.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentStep } from './hooks/useCurrentStep';
import { AddBadgeToWL } from '../../badges/WishListBadges/AddBadgeToWL';
import AddSurveyToWL from '../[id]/surveys/AddSurveyToWL';
import Filters from './steps/Filters';

export type StepType = {
  title: string;
  content: () => JSX.Element;
};

export function CreateWishList() {
  const { current: currentStep, resetSteps } = useCurrentStep();
  const { t } = useTranslation();
  const steps: StepType[] = [
    {
      title: t('cwl_timeline_event'),
      content: () => <EventStep />,
    },
    {
      title: t('mwl_tab-settings'),
      content: () => <SettingsStep />,
    },
    {
      title: t('mwl_tab-filters'),
      content: () => <Filters isEdit={false} />,
    },
    {
      title: t('admin_badges'),
      content: () => <AddBadgeToWL hideSubheader create />,
    },
    {
      title: t('admin_surveys'),
      content: () => <AddSurveyToWL isEdit={false} />,
    },
    {
      title: t('mwl_tab-tickets'),
      content: () => <TicketsStep />,
    },
    {
      title: t('mwl_tab-publish'),
      content: () => <PublishStep />,
    },
  ];

  useEffect(() => {
    return () => {
      resetSteps();
    };
  }, []);

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <div className={styles.headerWrap}>
        <Breadcrumb
          items={[
            {
              title: <Link to="..">{t('mwl_tab-wl')}</Link>,
            },
            {
              title: (
                <span>
                  {t('main_breadcrumbs-create')} {t('mwl_tab-wl')}
                </span>
              ),
            },
          ]}
        />
        <h3 className={styles.title}>
          {t('main_breadcrumbs-create')} {t('mwl_tab-wl')}
        </h3>
      </div>
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Steps.Step disabled title={step.title} key={step.title} />
        ))}
      </Steps>
      <div className={styles.stepContainer}>{steps[currentStep].content()}</div>
    </Flex>
  );
}

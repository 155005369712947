import { z } from 'zod';

export const LoginValidatorSchema = z.object({
  email: z.string(),
  password: z.string().min(4).optional(),
});

export type LoginValidator = z.infer<typeof LoginValidatorSchema>;

export const LoginValidatorSchemaV2 = z.object({
  email: z.string(),
  password: z.string().min(4).optional(),
});

export type LoginValidatorV2 = z.infer<typeof LoginValidatorSchemaV2>;

import React from 'react';
import ReactDOM from 'react-dom';
import Footer, { FooterProps } from './Footer';

export const FanProfileFooter = (props: FooterProps) => {
  return ReactDOM.createPortal(
    <Footer {...props} />,
    document.getElementById('portalForFooter') as HTMLElement
  );
};

export default FanProfileFooter;

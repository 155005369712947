import {
  FanActivityEntity,
  FanActivityStatus,
  WaitingListStatusEnum,
} from '@seaters-app/constants';
export const getFanActivities = (activities?: FanActivityEntity[]) => {
  const totalJoined = activities?.length ?? 0;
  const totalRSVP =
    activities?.filter((item) => {
      if (
        [
          FanActivityStatus.REJECT_SEATS,
          FanActivityStatus.RECEIVE_SEATS,
          FanActivityStatus.ACCEPT_SEATS,
          FanActivityStatus.POSITION_EXPIRES,
          FanActivityStatus.SEATS_EXPIRE,
        ].includes(item.status)
      ) {
        return item;
      }
    }).length ?? 0;

  const totalPrice =
    activities?.reduce((acc, activity) => {
      let price = Number(activity.seatPriceView.facialPrice);
      const feeRate = Number(activity.seatPriceView.distributionRate);
      const maxFee = Number(activity.seatPriceView.maxDistributionFee);
      const minFee = Number(activity.seatPriceView.minDistributionFee);
      if (feeRate !== 0) {
        if (price * feeRate < minFee) {
          price += minFee;
        }
        if (price * feeRate > maxFee) {
          price += maxFee;
        } else {
          price += price * feeRate;
        }
      }
      const finalPrice =
        activity.status === FanActivityStatus.ACCEPT_SEATS
          ? activity.numberOfSeats * price
          : 0;
      return acc + finalPrice;
    }, 0) ?? 0;

  const totalTickets =
    activities
      ?.filter((activity) => {
        return activity.status === FanActivityStatus.ACCEPT_SEATS;
      })
      .reduce(function (acc, activity) {
        return acc + activity.numberOfSeats;
      }, 0) ?? 0;

  const openedActivities = activities?.filter(
    (activity) => activity.waitingListStatus !== WaitingListStatusEnum.CLOSED
  );
  const joined =
    openedActivities?.filter(
      (activity) => activity.status === FanActivityStatus.JOIN_WL
    ).length ?? 0;
  const pending =
    openedActivities?.filter(
      (activity) => activity.status === FanActivityStatus.RECEIVE_SEATS
    ).length ?? 0;
  const confirmed =
    openedActivities?.filter(
      (activity) => activity.status === FanActivityStatus.ACCEPT_RSVP
    ).length ?? 0;
  const abandon =
    openedActivities?.filter(
      (activity) =>
        activity.status === FanActivityStatus.LEAVE_WL ||
        activity.status === FanActivityStatus.REJECT_SEATS ||
        activity.status === FanActivityStatus.POSITION_EXPIRES ||
        activity.status === FanActivityStatus.SEATS_EXPIRE
    ).length ?? 0;

  return {
    totalJoined,
    totalRSVP,
    totalPrice,
    totalTickets,
    joined,
    pending,
    confirmed,
    abandon,
  };
};

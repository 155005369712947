import {
  InvitationMode,
  SeatDistributionMode,
  SeatStatus,
  UserRole,
  WaitingListAcceptFormat,
  WaitingListEntity,
  waitingListsKeys,
} from '@seaters-app/constants';
import {
  fetchWaitingList,
  getSlugFromUrl,
  queryClient,
  useAcceptFanSeatsAsFGO,
  useAcceptWaitingList,
  useCheckoutStore,
  useFetchFGBySlug,
  useFetchUserRolesForCurrentFG,
} from '@seaters-app/data-access';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FGOStepItemIdEnum,
  HostStepItemIdEnum,
  StepItemIdEnum,
  StepType,
} from '../types';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useAcceptInvitation } from '../../InvitationList/hooks';

const checkSeatConfirmation = (wishList: WaitingListEntity) => {
  if (
    wishList &&
    wishList.seat &&
    wishList.seat.status === SeatStatus.ACCEPTED
  ) {
    if (wishList.seatDistributionMode === SeatDistributionMode.VOUCHER) {
      return true;
    } else if (wishList.seatDistributionMode === SeatDistributionMode.TICKET) {
      // TicketingSystemType is populated when done
      return typeof wishList.seat.ticketingSystemType === 'string';
    }
  }
  // All other cases are still in progress
  return false;
};

export function useFormSteps(
  steps: StepType<FGOStepItemIdEnum | StepItemIdEnum | HostStepItemIdEnum>[],
  selectedFanId?: string,
  closeModal?: () => void
): {
  currentStep: number;
  prevStep: () => void;
  nextStep: () => void;
  isLastStep: boolean;
  isLoading: boolean;
  waitingList?: WaitingListEntity;
} {
  const slug = getSlugFromUrl();

  const { waitingListId = '' } = useParams();
  const { wishListId = '' } = useParams();
  const wlId = slug ? waitingListId : wishListId;

  const { t } = useTranslation();

  const [wlRefetching, setWlRefetching] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number>(0);

  const { setCheckoutShown } = useCheckoutStore();

  const { mutate: acceptTickets } = useAcceptWaitingList(wlId);

  const handleAcceptInvitation = useAcceptInvitation(waitingListId);

  const { mutate: acceptFanSeatsAsFGO } = useAcceptFanSeatsAsFGO(wlId);

  const fgoWaitingList = queryClient.getQueryData<WaitingListEntity>(
    waitingListsKeys.detail(wlId)
  );

  const { data: waitingList } = useQuery<WaitingListEntity, Error>(
    waitingListsKeys.detail(wlId),
    () => fetchWaitingList(wlId),
    {
      enabled: !!wlId,
      refetchInterval(data, query) {
        const hasSeat = data && checkSeatConfirmation(data);

        if (hasSeat && !wlRefetching) {
          return false;
        }

        if (hasSeat && wlRefetching) {
          handleRefetchingEnd();
        }

        if (wlRefetching) {
          return 1000;
        }

        return false;
      },
    }
  );

  const isLastStep = currentStep === steps.length - 1;

  const prevStep = () => {
    if (currentStep === 0) {
      setCheckoutShown(false);
      closeModal && closeModal();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const isCustom = waitingList?.invitationMode === InvitationMode.ENABLED;

  const { data: fanGroupBySlugData } = useFetchFGBySlug(slug ?? '');
  const { data: userRoles } = useFetchUserRolesForCurrentFG(
    fanGroupBySlugData?.id ?? ''
  );

  const isHost = userRoles?.roles.find((role) => role === UserRole.HOST);

  useEffect(() => {
    if (
      waitingList?.seat?.status === SeatStatus.ACCEPTED ||
      waitingList?.seat?.status === SeatStatus.RSVP_ACCEPTED
    ) {
      setCheckoutShown(false);
      setIsLoading(false);
    }
  }, [waitingList?.seat?.status]);

  const handleRefetchingStart = () => {
    setWlRefetching(true);
  };

  const handleRefetchingEnd = () => {
    setWlRefetching(false);
  };

  const handleAcceptTickets = () => {
    setIsLoading(true);
    if (slug) {
      if (isCustom && isHost) {
        handleAcceptInvitation(selectedFanId ?? '');
      } else {
        acceptTickets(
          { format: WaitingListAcceptFormat.MULTIPAGE_PDF_FILE },
          {
            onSuccess: async () => {
              handleRefetchingStart();
            },
            onError: ({ response }) => {
              notification.error({
                message: t('notification_error_tickets_accepted'),
                description: response?.data?.message,
              });
            },
          }
        );
      }
    } else if (selectedFanId) {
      acceptFanSeatsAsFGO(selectedFanId);
    }
  };

  const nextStep = () => {
    if (isLastStep) {
      // accept tickets

      handleAcceptTickets();
      if (
        (!slug ||
          (isHost && waitingList?.invitationMode === InvitationMode.ENABLED)) && // is Host and guest list is enabled
        closeModal
      ) {
        closeModal();
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return {
    currentStep,
    prevStep,
    nextStep,
    isLastStep,
    isLoading,
    waitingList: slug ? waitingList : fgoWaitingList,
  };
}

import {
  ChoiceEntity,
  ListEntityV2,
  questionChoicesKeys,
} from '@seaters-app/constants';
import { queryClient } from '@seaters-app/data-access';

export const getOptions = (questionId: string) => {
  return queryClient
    .getQueryData<ListEntityV2<ChoiceEntity>>(
      questionChoicesKeys.listPerQuestion(questionId)
    )
    ?.content.map((option, i) => {
      return {
        offeredAnswerTranslation: option.offeredAnswerTranslation,
        id: option.id,
        order: i + 1,
      };
    });
};

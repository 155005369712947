import React, { useEffect, useRef } from 'react';
import { routes } from '@seaters-app/constants';
import { Button, baseColor600, baseColor900 } from '@seaters-app/ui';
import { ConfigProvider, Space, Typography, theme } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const { Title, Text } = Typography;

function ResetPasswordConfirmationCard({ email }: { email: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const tip = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (tip.current) {
      tip.current.innerHTML = t('forgotpassword_text_check_spam');
    }
  }, []);
  return (
    <Space direction="vertical" size="large" style={{ color: baseColor900 }}>
      <Title level={3}>{t('forgot_password_confirm_heading')}</Title>
      <Text>{t('forgot_password_confirm_text', { email })}</Text>
      <Button
        size="large"
        type="primary"
        onClick={() => navigate(`../${routes.login}`)}
        style={{ width: '100%' }}
      >
        {t('button_text_backto_login')}
      </Button>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 16,
          gap: 0,
        }}
      >
        <Text>{t('forgotpassword_text_noemail')}</Text>
        <ConfigProvider
          theme={{
            token: {
              colorLink: token.colorPrimary,
              colorLinkHover: token.colorPrimary,
              colorLinkActive: token.colorPrimary,
            },
          }}
        >
          <Text style={{ color: baseColor600 }}>
            <Text ref={tip}>{t('forgotpassword_text_check_spam')}</Text>
            <NavLink to={t('footer_support_faq-link')} target="_blank">
              {' '}
              {t('resetpassword_request_troubleshooting-tip-helpdesk-btn')}
            </NavLink>
          </Text>
        </ConfigProvider>
      </div>
    </Space>
  );
}

export default ResetPasswordConfirmationCard;

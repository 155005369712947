import React from 'react';
import Title from 'antd/es/typography/Title';
import { Image, Space, theme } from 'antd';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  TagOutlined,
} from '@ant-design/icons';
import styles from './styles.module.css';

import Text from 'antd/es/typography/Text';
import {
  TranslationEntity,
  WaitingListStatusEnum,
} from '@seaters-app/constants';

interface GeneralInfoProps {
  generalInfo: {
    imageUrl: string;
    title?: string;
    startDate: string;
    status?: WaitingListStatusEnum;
    venue: string;
    category: string;
  };
  isCustom?: boolean;
  part?: 'image' | 'info';
  isMobile?: boolean;
  isDetails?: boolean;
}

export function GeneralInfo({
  generalInfo,
  isCustom,
  part,
  isMobile,
  isDetails,
}: GeneralInfoProps) {
  const { token } = theme.useToken();
  const { imageUrl, venue, startDate, category, title } = generalInfo;
  return (
    <Space
      style={{ color: token.colorTextSecondary }}
      direction={isMobile ? 'vertical' : 'horizontal'}
    >
      {part !== 'info' && (
        <Image
          className={isMobile ? styles.image : ''}
          style={{
            borderRadius: 8,
            objectFit: 'cover',
          }}
          width={part === 'image' ? 50 : isMobile ? '100%' : 192}
          height={part === 'image' ? 50 : 120}
          src={imageUrl}
          preview={false}
        />
      )}

      {part !== 'image' && (
        <Space direction="vertical" style={{ padding: '0 10px' }} size={0}>
          {title && !isDetails && (
            <Title level={5} style={{ margin: 0, maxWidth: 340 }}>
              {title}
            </Title>
          )}
          <Space>
            <CalendarOutlined rev={undefined} />
            <Text type="secondary">{startDate}</Text>
          </Space>
          <Space>
            <EnvironmentOutlined rev={undefined} />
            <Text type="secondary">{venue}</Text>
          </Space>
          {isCustom && (
            <Space>
              <TagOutlined rev={undefined} />
              <Text type="secondary">{category}</Text>
            </Space>
          )}
        </Space>
      )}
    </Space>
  );
}

export default GeneralInfo;

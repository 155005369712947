import React, { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Pagination,
  PaginationProps,
  Space,
  Spin,
  Typography,
} from 'antd';
import AntLayout from 'antd/es/layout';
import { PlusOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Container,
  DatePicker,
  EmptyState,
  baseColor400,
  baseColor600,
} from '@seaters-app/ui';
import {
  useCreateWaitingList,
  useFanGroupStore,
  useFetchFanGroupOwnerWaitingList,
  useSearchFanGroupOwnerEventsList,
  useUpdateWaitingList,
} from '@seaters-app/data-access';
import {
  FanGroupOwnerEventEntity,
  FanGroupOwnerWaitingListEntity,
  routes,
  WaitingListStatusEnum,
} from '@seaters-app/constants';
import EventCard from '../components/EventCard';
import EventDetailedInfoModal from '../components/EventDetailedInfoModal';
import EventCardSkeleton from '../components/EventCardSkeleton';
import { getWLInitialValues } from '../helpers';

import styles from './steps.module.css';
import { useTranslation } from 'react-i18next';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { CreateEventModal } from './createEvent/CreateEventModal';
import { useEventCreateStepper } from './createEvent/hooks/useEventCreateStepper';
import debounce from 'lodash/debounce';
import Footer from '../components/Footer';

const { Text } = Typography;

export function EventStep({ isEdit }: { isEdit?: boolean }) {
  const navigate = useNavigate();
  const { wishListId = '' } = useParams();
  const { t } = useTranslation();
  const [changingEvent, setChangingEvent] = useState<boolean>(false);

  const { isModalOpen, setModalOpen } = useEventCreateStepper();

  const { next } = useCurrentStep();
  const { fanGroup } = useFanGroupStore();

  const [eventToShow, setEventToShow] =
    useState<FanGroupOwnerEventEntity | null>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const [query, setQuery] = useQueryParams({
    maxPageSize: withDefault(NumberParam, 5),
    itemOffset: withDefault(NumberParam, 0),
    search: withDefault(StringParam, undefined),
  });

  const { maxPageSize, itemOffset, search } = query;
  const currentPage = itemOffset ? itemOffset / maxPageSize + 1 : 1;
  const queryParams = { itemOffset, maxPageSize };
  const requestBody = {
    date: selectedDate?.toISOString() || undefined,
    query: search || undefined,
  };

  const { data: wishList } = useFetchFanGroupOwnerWaitingList(wishListId);

  // Redirect to settings page if WL is already created
  useEffect(() => {
    if (wishList?.status && wishList?.status !== WaitingListStatusEnum.SETUP) {
      navigate(
        `/${routes.admin}/${routes.wishLists}/${wishList?.waitingListId}/${routes.settings}/${routes.general}`
      );
    }
  }, [wishList]);

  const { data: events, isFetching: eventLoading } =
    useSearchFanGroupOwnerEventsList(requestBody, queryParams);

  const { mutate: createWishList, isLoading: wishListIsLoading } =
    useCreateWaitingList();

  const { mutate: updateWishList, isLoading: updateIsLoading } =
    useUpdateWaitingList();

  const handleInputChange = debounce((e) => {
    setQuery({ search: e.target.value });
    setQuery({ itemOffset: 0 });
  }, 500);

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    setQuery({ itemOffset: 0 });
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (_, pageSize) =>
    setQuery({ itemOffset: 0, maxPageSize: pageSize });

  const handleChangePage = (newPage: number, pageSize: number) =>
    setQuery({
      itemOffset: pageSize !== maxPageSize ? 0 : (newPage - 1) * maxPageSize,
    });

  const handleShowEventModal = (event: FanGroupOwnerEventEntity) =>
    setEventToShow(event);

  const onSuccess = (data: FanGroupOwnerWaitingListEntity) => {
    if (isEdit) {
      next();
    } else {
      navigate(data.waitingListId);
      next();
    }
  };

  const onSubmit = () => {
    next();
  };

  const handleSelectEvent = (eventId: string) => {
    createWishList(
      {
        fanGroupId: fanGroup?.id || '',
        body: {
          ...getWLInitialValues({ fanGroup }),
          eventId,
        },
      },
      { onSuccess }
    );
  };

  return (
    <Spin spinning={wishListIsLoading || updateIsLoading} size="large">
      <Space size="large" direction="vertical" className={styles.filters}>
        {wishList?.event ? (
          <EventCard
            key={wishList?.event.eventId}
            event={wishList?.event}
            isSelected
            // changeEvent={() => setChangingEvent(!changingEvent)}
            handleShowEventModal={handleShowEventModal}
            // changingEvent={changingEvent}
          />
        ) : (
          <p style={{ color: baseColor600 }}>{t('cwl_event_title')}</p>
        )}
        {!wishList?.event || changingEvent ? (
          <>
            <div className={styles.filtersWrap}>
              <Space direction="horizontal" align="end" size="large">
                <Space direction="vertical" size="small">
                  <Text>{t('event_venue_search-btn')}</Text>
                  <Input
                    style={{ width: 360 }}
                    size="middle"
                    placeholder={t('cwl_event_search-btn')}
                    prefix={<SearchOutlined rev={undefined} />}
                    onChange={handleInputChange}
                    defaultValue={search}
                  />
                </Space>

                <Space direction="vertical" size="small">
                  <Text>{t('filters_label_date')}</Text>
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: 360 }}
                    onChange={handleDateChange}
                    value={selectedDate}
                  />
                </Space>
              </Space>
              <Button
                onClick={() => setModalOpen(true)}
                type="primary"
                icon={<PlusOutlined rev={undefined} />}
              >
                {t('create_event_button_text')}
              </Button>
            </div>
            <div className={styles.eventsList}>
              {eventLoading ? (
                [...Array(10)].map((_, i) => <EventCardSkeleton key={i} />)
              ) : events?.items.length ? (
                events?.items.map((event) => (
                  <EventCard
                    key={event.eventId}
                    event={event}
                    handleShowEventModal={handleShowEventModal}
                    handleSelectEvent={handleSelectEvent}
                  />
                ))
              ) : (
                <EmptyState />
              )}
            </div>
            {!!events?.totalSize && (
              <Pagination
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={currentPage}
                current={currentPage}
                total={events?.totalSize}
                onChange={handleChangePage}
                pageSize={maxPageSize}
              />
            )}
          </>
        ) : null}
        <EventDetailedInfoModal
          isModalOpen={!!eventToShow}
          handleCancel={() => setEventToShow(null)}
          event={eventToShow}
          handleOnSelect={handleSelectEvent}
          isEventSet={!!wishList?.event}
        />
      </Space>
      <Form onFinish={onSubmit}>
        <Footer
          isEdit={isEdit}
          cancelButton={
            <Space>
              <Button
                size="middle"
                type="default"
                danger
                icon={<CloseOutlined rev={undefined} />}
                onClick={() => navigate('..')}
              >
                {isEdit
                  ? t('button_text_cancel')
                  : t('cancel_create_button_text')}
              </Button>
            </Space>
          }
        />
      </Form>

      {isModalOpen && (
        <CreateEventModal
          open={isModalOpen}
          onCancel={() => setModalOpen(false)}
          handleSelectEvent={handleSelectEvent}
        />
      )}
    </Spin>
  );
}

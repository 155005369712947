import { Tag } from 'antd';

import { BadgeStatusEnum } from '@seaters-app/constants';
import { errorColor, successColor } from '@seaters-app/ui';

export interface BadgeStatusTagProps {
  status: BadgeStatusEnum;
}

const badgeStatusToColor = {
  [BadgeStatusEnum.ARCHIVED]: errorColor,
  [BadgeStatusEnum.ACTIVE]: successColor,
};
export function WishListBadgeStatusTag({ status }: BadgeStatusTagProps) {
  return <Tag color={badgeStatusToColor[status]}>{status}</Tag>;
}

export default WishListBadgeStatusTag;

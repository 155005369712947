import { Card, Skeleton } from 'antd';

export const GalleryCardSkeleton = () => {
  return (
    <Card
      size="small"
      cover={<Skeleton.Button block active style={{ height: 152 }} />}
      actions={[
        <Skeleton.Input active size="small" />,
        <Skeleton.Input active size="small" />,
      ]}
    >
      <Skeleton active />
    </Card>
  );
};

import { t } from 'i18next';

export const convertMsToString = (ms: number) => {
  if (ms < 0) {
    return null;
  }

  const seconds: number = ms / 1000;
  const minutes: number = seconds / 60;
  const hours: number = minutes / 60;
  const days: number = hours / 24;

  if (days >= 1) {
    return `${days.toFixed(0)} ${t('general_duration-day')}`;
  } else if (hours >= 1) {
    return `${hours.toFixed(0)} ${t('general_duration-hrs')}`;
  } else if (minutes >= 1) {
    return `${minutes.toFixed(0)} ${t('general_duration-min')}`;
  } else {
    return `${seconds.toFixed(0)} ${t('general_duration-sec')}`;
  }
};

import {
  SeatDistributionMode,
  SeatEntity,
  SeatStatus,
} from '@seaters-app/constants';
import { Modal, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface RemoveMultipleSeatModalProps {
  open: boolean;
  rejectSelected: () => void;
  isPaid: boolean;
  isLoading: boolean;
  seatDistributionMode?: SeatDistributionMode;
  selectedRowItems: SeatEntity[];
  closeModal: () => void;
}

const RemoveMultipleSeatModal: React.FC<RemoveMultipleSeatModalProps> = ({
  open,
  rejectSelected,
  selectedRowItems,
  isPaid,
  isLoading,
  seatDistributionMode,
  closeModal,
}) => {
  const { t } = useTranslation();

  const acceptedSeats = selectedRowItems.filter(
    (seat) => seat.status === SeatStatus.ACCEPTED
  );
  return (
    <Modal
      title={t('confirm_areyousure')}
      open={open}
      onOk={rejectSelected}
      okButtonProps={{ loading: isLoading }}
      onCancel={closeModal}
      cancelText={t('general_cancel_text')}
      okText={t('mwl_wl_fan-remove-btn')}
    >
      <Space direction="vertical">
        {seatDistributionMode === SeatDistributionMode.VOUCHER && (
          <Text>
            {t('remove_voucher_explanation_text', {
              firstName: selectedRowItems
                .map((seat) => `${seat.firstName} ${seat.lastName}`)
                .join(', '),
              lastName: '',
              fanEmail: '',
            })}
          </Text>
        )}
        {seatDistributionMode === SeatDistributionMode.TICKET && (
          <Text>{t('accepted_positions_removing_warning')}</Text>
        )}
        {isPaid && !!acceptedSeats.length && (
          <Text>
            {t('accepted_positions_removing_warning_paid', {
              positions: acceptedSeats
                .map((s) => `${s.firstName} ${s.lastName}`)
                .join(', '),
            })}
          </Text>
        )}
      </Space>
    </Modal>
  );
};

export default RemoveMultipleSeatModal;

import { FanEntity } from '@seaters-app/constants';
import dayjs from 'dayjs';

export const getInitialValues = (fanProfile?: FanEntity) => {
  return {
    ...fanProfile,
    countryCallingCode: fanProfile?.mobilePhoneNumber?.countryCallingCode ?? '',
    localNumber: fanProfile?.mobilePhoneNumber?.localNumber ?? '',
    citizenship: fanProfile?.personalInfo?.citizenshipCountryCode,
    company: fanProfile?.personalInfo?.company ?? '',
    country: fanProfile?.personalInfo?.address?.countryCode,
    postalCode: fanProfile?.personalInfo?.address?.zipCode,
    city: fanProfile?.personalInfo?.address?.city,
    state: fanProfile?.personalInfo?.address?.state,
    line1: fanProfile?.personalInfo?.address?.line1,
    line2: fanProfile?.personalInfo?.address?.line2,
    line3: fanProfile?.personalInfo?.address?.line3,
    birthDate: fanProfile?.personalInfo?.birthDate
      ? dayjs(fanProfile?.personalInfo?.birthDate)
      : null,
    bySms: fanProfile?.notificationChannels?.bySms,
    byMail: fanProfile?.notificationChannels?.byMail,
    allowDirectMarketingFromSeaters:
      fanProfile?.directMarketingSettings?.allowDirectMarketingFromSeaters,
    allowDirectMarketingFromPartners:
      fanProfile?.directMarketingSettings?.allowDirectMarketingFromPartners,
  };
};

import { Button, Container } from '@seaters-app/ui';
import { LeftOutlined, OrderedListOutlined } from '@ant-design/icons';

import {
  ContactList,
  GuestList as GuestListComponent,
  InvitationList,
  ReserveGuestList,
  WishListStatusTag,
  WishListSummary,
} from '@seaters-app/ui-shared';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  GuestItem,
  getInvitationStatus,
  getSlugFromUrl,
  queryClient,
  useFetchUserRolesForCurrentFG,
  useFetchWaitingList,
  useFetchWaitingListInvitations,
  useFetchWaitingListTickets,
  useGuestListStore,
  useIsCustomFlow,
  useReserveGuestListStore,
} from '@seaters-app/data-access';
import {
  FanEntity,
  ImportanceEnum,
  InvitationEntity,
  InvitationForApprove,
  InvitationStatusEnum,
  LOCAL_STORAGE_SLUG,
  LookEntity,
  SESSION_STORAGE_TOTAL_TICKETS,
  UserRole,
  profileKeys,
  routes,
} from '@seaters-app/constants';
import { ConfigProvider, Grid, Space, Spin, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { useWLGuestListSummaryData } from './hooks/useWLGuestListSummaryData';
import { useEffect } from 'react';
const { useBreakpoint } = Grid;
const { Title } = Typography;

export function GuestList() {
  const { waitingListId = '' } = useParams();
  const { token } = theme.useToken();
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const { data: waitingList } = useFetchWaitingList(waitingListId);

  const { totalTickets, guests } = useGuestListStore();

  const { getData } = useWLGuestListSummaryData();
  const toAllocate = Object.values(guests).find((el) => el.isToAllocated);

  const tickets =
    Number(sessionStorage.getItem(SESSION_STORAGE_TOTAL_TICKETS)) ||
    totalTickets ||
    0;

  const { data: invitations, isFetching } =
    useFetchWaitingListInvitations(waitingListId);

  const { data: price } = useFetchWaitingListTickets(waitingListId, tickets);

  const isCustom = useIsCustomFlow();

  const toAllocateInvitation: InvitationEntity | undefined =
    invitations?.content.find(
      (invitation: InvitationEntity) =>
        invitation?.originalNumberOfNonAllocatedSeats
    );

  const invitationStatusToCount = ['APPROVED', 'PENDING'];

  const totalSeats =
    // Number(invitations?.content[0]?.numberOfNonAllocatedSeats) +   ---- HOST is not always first in the array!!!
    Number(
      invitations?.content?.reduce((acc, curr) => {
        if (
          (curr.status === 'APPROVED' &&
            (!curr.position ||
              curr.position.seatsRequestStatus === 'DECLINED')) ||
          curr.status === 'CANCELLED' ||
          curr.status === 'REJECTED' ||
          curr.importance === ImportanceEnum.OPTIONAL
        ) {
          return acc;
        }
        // GUESTS
        if (invitationStatusToCount.includes(curr.status)) {
          acc += curr.nbrOfRequestedSeats;
        }
        // HOST
        if (curr.numberOfNonAllocatedSeats) {
          acc += curr.numberOfNonAllocatedSeats;
        }
        return acc;
      }, 0)
    ) || tickets;

  const status = getInvitationStatus(toAllocateInvitation);

  const groupGuests = useGuestListStore((state) => Object.values(state.guests));

  const allocatedInvitation = groupGuests.find((guest) => guest.isToAllocated);

  const setTickets = useGuestListStore((state) => state.setTickets);

  const addGuest = useGuestListStore((state) => state.add);

  const removeReserveGuest = useReserveGuestListStore((state) => state.remove);

  const replaceGuestFromReserveToMain = (
    reservedGuests: GuestItem[],
    allocatedSeats: number
  ) => {
    let freeSlots = allocatedSeats;

    for (let i = 0; i < reservedGuests.length; i++) {
      if (reservedGuests[i].tickets <= freeSlots) {
        addGuest(reservedGuests[i].guest, reservedGuests[i].tickets);
        removeReserveGuest(reservedGuests[i].guest.guestId);
        freeSlots -= reservedGuests[i].tickets;
      } else {
        setTickets(
          allocatedInvitation?.guest.guestId ?? 'to_allocated_id',
          freeSlots
        );
        break;
      }
      setTickets(
        allocatedInvitation?.guest.guestId ?? 'to_allocated_id',
        freeSlots
      );
    }
  };

  const fanData: FanEntity | undefined = queryClient.getQueryData(
    profileKeys.fan()
  );

  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const { data: fanGroupRoles, isLoading: userRolesIsLoading } =
    useFetchUserRolesForCurrentFG(fanGroupData?.fanGroupId ?? '');

  const isAdmin = fanData?.roles.includes(UserRole.ADMIN);

  const isFGOCurrentGroup = fanGroupRoles?.roles.includes(
    UserRole.FAN_GROUP_OWNER
  );

  const slug = getSlugFromUrl();
  const navigate = useNavigate();

  const handleInterfaceSwitch = () => {
    queryClient.clear();
    if (slug) {
      localStorage.setItem(LOCAL_STORAGE_SLUG, slug);
      navigate(`/${routes.admin}/${routes.wishLists}/${waitingListId}`);
    }
  };
  const resetReserved = useReserveGuestListStore((state) => state.reset);

  useEffect(() => {
    return () => {
      resetReserved();
    };
  }, []);

  if (!waitingList) return null;

  return (
    <div
      style={{
        backgroundColor: '#FFFFFF',
        paddingBottom: 74,
      }}
    >
      <Container
        style={{
          paddingBottom: 20,
        }}
      >
        <Space style={{ width: '100%' }} direction="vertical">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ConfigProvider
              theme={{
                token: {
                  colorLink: token.colorPrimary,
                  colorLinkHover: token.colorPrimary,
                  colorLinkActive: token.colorPrimary,
                },
              }}
            >
              <Link to="..">
                <Button
                  type="link"
                  padding={0}
                  icon={<LeftOutlined rev={undefined} />}
                >
                  {t('waitinglist_back_to_list')}
                </Button>
              </Link>
            </ConfigProvider>
            {isAdmin && isFGOCurrentGroup && (
              <Button
                type="text"
                onClick={handleInterfaceSwitch}
                icon={<OrderedListOutlined rev={undefined} />}
              >
                {t('view_as_admin')}
              </Button>
            )}
          </div>
          {!screens.xs && (
            <Space>
              <Title level={3}>
                {waitingList.translatedExperienceName
                  ? waitingList.translatedExperienceName
                  : waitingList.translatedEventName}
              </Title>
              <WishListStatusTag status={waitingList.waitingListStatus} />
            </Space>
          )}

          {invitations && price && (
            <WishListSummary
              data={getData(
                waitingList,
                price,
                totalSeats,
                tickets,
                toAllocateInvitation
              )}
              isDetails
            />
          )}
        </Space>
      </Container>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          paddingBottom: 20,
        }}
      >
        {toAllocateInvitation?.status === InvitationForApprove.REJECTED && (
          <Space style={{ color: token.colorError }}>
            {t('rejected_invitations_warning_for_host')}
          </Space>
        )}
        {invitations && invitations.totalElements ? (
          <InvitationList
            replaceGuestFromReserveToMain={replaceGuestFromReserveToMain}
            invitations={invitations}
            waitingListId={waitingListId}
            isFetching={isFetching}
          />
        ) : (
          <GuestListComponent
            isFetching={isFetching}
            waitingListId={waitingListId}
            replaceGuestFromReserveToMain={replaceGuestFromReserveToMain}
          />
        )}

        {!isCustom && <ReserveGuestList waitingListId={waitingListId} />}
        <ContactList
          waitingListId={waitingListId}
          disabled={
            isCustom &&
            ((status && status !== InvitationStatusEnum.DEMAND_ACCEPTED) ||
              toAllocate?.tickets === 0)
          }
        />
      </Container>
      {/* <Modal></Modal> */}
    </div>
  );
}

import { ProtectionCodeEnhancedEntity } from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import { Avatar, Form, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

interface ManageBadgesModalProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  handleLinkBadgeToCode: (values: { badgeIds: string[] }) => void;
  codeToLink: ProtectionCodeEnhancedEntity | null;
  isLoading: boolean;
  badgesOptions?: {
    label: string;
    value: string;
    avatar?: string;
  }[];
}

function ManageBadgesModal({
  isOpen,
  setOpen,
  handleLinkBadgeToCode,
  codeToLink,
  isLoading,
  badgesOptions,
}: ManageBadgesModalProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onCancel = () => {
    setOpen(false);
  };
  return (
    <Modal
      title={t('link_badge_to_protected_code_title')}
      open={isOpen}
      onCancel={onCancel}
      footer={
        <Button
          type="primary"
          htmlType="submit"
          form="badgesForm"
          loading={isLoading}
        >
          {t('general_save-btn')}
        </Button>
      }
    >
      <Form
        id="badgesForm"
        form={form}
        name="basic"
        layout="vertical"
        disabled={isLoading}
        initialValues={{
          badgeIds: codeToLink?.badges.map((badge) => badge.id),
        }}
        onFinish={handleLinkBadgeToCode}
        autoComplete="off"
      >
        <Form.Item label={t('admin_badges')} name="badgeIds">
          <Select
            mode="multiple"
            showSearch
            optionFilterProp="filter"
            allowClear
            size="large"
            placeholder={t('search_badge_placeholder')}
            options={badgesOptions?.map((option) => ({
              label: (
                <div>
                  <Avatar src={option.avatar} size="small" /> {option.label}
                </div>
              ),
              value: option.value,
              key: option.value,
              filter: option.label,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ManageBadgesModal;

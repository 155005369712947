import { useFetchAdminFanGroup } from '@seaters-app/data-access';
import React from 'react';
import { useParams } from 'react-router-dom';
import FilterConfig from './FilterConfig';

const FilterConfigPage: React.FC = () => {
  const { fanGroupId = '' } = useParams();

  const { data: fanGroup } = useFetchAdminFanGroup(fanGroupId);

  return fanGroup ? (
    <FilterConfig fanGroupId={fanGroupId} properties={fanGroup.properties} />
  ) : null;
};

export default FilterConfigPage;

import React, { useState } from 'react';
import { Button } from '@seaters-app/ui';
import { To, useNavigate } from 'react-router-dom';
import {
  routes,
  CURRENT_LANGUAGE,
  SESSION_STORAGE_VERIFICATION,
  SESSION_STORAGE_SURVEY_SHOWN,
  LookEntity,
  SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED,
  PrivacyPolicyAcceptStatusEnum,
  BNPHospitalitySlug,
  LOCAL_STORAGE_SLUG,
} from '@seaters-app/constants';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import './styles.css';
import {
  getSlugFromUrl,
  queryClient,
  useAppUserStore,
} from '@seaters-app/data-access';
import { Modal, Spin, Space } from 'antd';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export function ComplianceModal({
  routeToRedirect,
  fanGroupData,
}: {
  fanGroupData?: LookEntity;
  routeToRedirect?: To;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const slug = getSlugFromUrl();
  const { setUser } = useAppUserStore();
  const [isDisabled, setIsDisabled] = useState(true);
  const [scale, setScale] = useState(1.7);
  const decreaseScale = () => {
    setScale(scale - 0.1);
  };
  const increaseScale = () => {
    setScale(scale + 0.1);
  };
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const isOpen =
    sessionStorage.getItem(SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED) ===
      PrivacyPolicyAcceptStatusEnum.NOT_ACCEPTED &&
    !!fanGroupData &&
    !!fanGroupData?.termsAndConditionsUrls &&
    slug === BNPHospitalitySlug;

  const logout = () => {
    sessionStorage.removeItem(SESSION_STORAGE_VERIFICATION);
    sessionStorage.removeItem(SESSION_STORAGE_SURVEY_SHOWN);
    sessionStorage.removeItem(SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED);
    localStorage.removeItem(LOCAL_STORAGE_SLUG);
    setUser(undefined);
    queryClient.clear();
    navigate(`../${routes.landing}`);
  };

  const acceptPP = () => {
    sessionStorage.setItem(
      SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED,
      PrivacyPolicyAcceptStatusEnum.ACCEPTED
    );

    if (routeToRedirect) {
      navigate(routeToRedirect);
    }
  };

  return (
    <Modal
      open={isOpen}
      width={'80%'}
      centered
      style={{ maxHeight: '800px', overflow: 'scroll' }}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={logout}>
          {t('not_agree_button_text')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={acceptPP}
          disabled={isDisabled}
        >
          {t('agree_button_text')}
        </Button>,
      ]}
    >
      <Space
        style={{
          width: '100%',
          justifyContent: 'center',
          position: 'sticky',
          top: 0,
          left: 0,
        }}
      >
        <MinusCircleOutlined onClick={decreaseScale} rev={undefined} />
        <PlusCircleOutlined onClick={increaseScale} rev={undefined} />
      </Space>
      <div
        id="wrap"
        style={{ height: '600px', overflow: 'scroll', position: 'relative' }}
        onScroll={() => {
          const frame = document.getElementById('wrap');
          const scrollTop = frame?.scrollTop;
          const scrollHeight = frame?.scrollHeight;

          if (scrollTop && scrollHeight) {
            if (scrollHeight - scrollTop < 800) setIsDisabled(false);
          }
        }}
      >
        <Document
          file={fanGroupData?.termsAndConditionsUrls[CURRENT_LANGUAGE]}
          onLoadSuccess={onDocumentLoadSuccess}
          onError={(error) => console.log('Error loading PDF:', error)}
          loading={
            <div style={{ verticalAlign: 'center', textAlign: 'center' }}>
              <Spin style={{ marginTop: '200px' }} />
            </div>
          }
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div key={`page_${index}`}>
              <Page pageNumber={index + 1} renderMode="canvas" scale={scale} />
            </div>
          ))}
        </Document>
      </div>
    </Modal>
  );
}

export default ComplianceModal;

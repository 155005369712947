import React from 'react';
import { baseColor900 } from '../theme/colors';
import styles from './Overlay.module.css';

type OverlayType = {
  show?: boolean;
};

export const Overlay: React.FC<OverlayType> = ({ show, ...props }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={styles.overlay}
      style={{
        backgroundColor: baseColor900,
      }}
      {...props}
    />
  );
};

import React from 'react';
import { FanGroupPersonalizationFormType } from '@seaters-app/constants';
import { FanGroupPersonalizationForm } from '../../fanGroupForm/FanGroupPersonalizationForm';

interface PersonalizationStepProps {
  values: FanGroupPersonalizationFormType;
  setValues: (val: FanGroupPersonalizationFormType) => void;
  isLoading: boolean;
  createFG: (
    values: FanGroupPersonalizationFormType,
    profileImageUrl?: string | File,
    logoImageUrl?: File | string,
    coverImageUrl?: File | string,
    backgroundImageUrl?: File | string
  ) => Promise<void>;
}

export function PersonalizationStep({
  values,
  setValues,
  isLoading,
  createFG,
}: PersonalizationStepProps) {
  const isSubmitting = isLoading;

  const onSubmit = () => {
    createFG(
      values,
      values.profileImageUrl,
      values.logoImageUrl,
      values.coverImageUrl,
      values.backgroundImageUrl
    );
  };

  return (
    <FanGroupPersonalizationForm
      fanGroup={values}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isEdit={false}
      setPersonalizationStepValues={setValues}
    />
  );
}

import React, { useCallback, useState } from 'react';
import {
  Input,
  Button,
  Col,
  Row,
  Select,
  Switch,
  Segmented,
  notification,
  Typography,
  Checkbox,
  Tooltip,
  Space,
} from 'antd';
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Form from 'antd/es/form';
import {
  FanGroupCreationFooter,
  Footer,
  StyledText,
} from '@seaters-app/ui-shared';
import {
  FangroupStatusOptions,
  fanGroupsKeys,
  FanGroupGeneralFormValidatorSchema,
  FanGroupGeneralFormType,
  UserRole,
  FanGroupBillingMode,
  SEATERS_APP_BASE_URL,
  PaymentMethod,
} from '@seaters-app/constants';
import { Editor, baseColor600 } from '@seaters-app/ui';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIsMutating } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  useAppUserStore,
  useFetchLanguages,
  useFetchTicketingSystems,
  useFetchPaymentSystems,
} from '@seaters-app/data-access';
import { formSteps, useCurrentStep } from '../create/CreateFanGroup';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;
const { TextArea } = Input;

type FanGroupGeneralFormProps = {
  initialValues: FanGroupGeneralFormType;
  onSubmit: (values: FanGroupGeneralFormType) => void;
  isLoading: boolean;
  setGeneralStepValues?: (v: FanGroupGeneralFormType) => void;
  isConfiguration?: boolean;
};

export function FanGroupGeneralForm({
  initialValues,
  onSubmit,
  isLoading,
  setGeneralStepValues,
  isConfiguration = false,
}: FanGroupGeneralFormProps) {
  const { t } = useTranslation();
  const { next } = useCurrentStep();
  const { fanGroupId = '' } = useParams();
  const isSubmitting = !!useIsMutating(fanGroupsKeys.mutation());
  const { user } = useAppUserStore();

  const [editorShown, setEditorShown] = useState({
    optInTermsTexts: false,
    optInRulesTexts: false,
    optInPlatformTexts: false,
    optInPartnersTexts: false,
    description: false,
    welcomeText: false,
  });

  const isAdmin = user?.roles.includes(UserRole.ADMIN);
  const [currentLanguage, setCurrentLanguage] = useState<string | number>('en');

  const { data: ticketingSystems, isLoading: ticketingSystemsLoading } =
    useFetchTicketingSystems(
      {
        maxPageSize: 9999,
      },
      !!isAdmin
    );

  const { data: paymentSystems, isLoading: paymentSystemsLoading } =
    useFetchPaymentSystems(
      {
        maxPageSize: 200,
      },
      !!isAdmin
    );

  const { data: languagesDate } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const languages = languagesDate
    ? languagesDate?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  const billingModesOptions = [
    {
      label: t('admin_fan-groups_billing-modes_free'),
      value: FanGroupBillingMode.FREE,
    },
    {
      label: t('admin_fan-groups_billing-modes_fair_price'),
      value: FanGroupBillingMode.FAIR_PRICE,
    },
  ];
  const paymentParametersOptions: {
    label: string;
    value: PaymentMethod;
  }[] = [
    {
      label: t('admin_fan-groups_payment-methods_credit_card'),
      value: PaymentMethod.CREDIT_CARD,
    },
    {
      label: t('admin_fan-groups_payment-methods_masterpass'),
      value: PaymentMethod.MASTERPASS,
    },
    {
      label: t('admin_fan-groups_payment-methods_ideal'),
      value: PaymentMethod.IDEAL,
    },
    {
      label: t('admin_fan-groups_payment-methods_virtual'),
      value: PaymentMethod.VIRTUAL,
    },
  ];

  const methods = useForm<FanGroupGeneralFormType>({
    mode: 'onBlur',
    resolver: zodResolver(FanGroupGeneralFormValidatorSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isValid },
  } = methods;

  const copyID = useCallback(() => {
    if (fanGroupId) {
      navigator.clipboard.writeText(fanGroupId);
      notification.success({
        message: t('notification_success_id_copied', { userId: fanGroupId }),
      });
    }
  }, []);

  const handleNextStep = () => {
    if (setGeneralStepValues) {
      setGeneralStepValues(getValues());
    }
    if (next) {
      next();
    }
  };

  const ticketingSystemOptions = ticketingSystems?.items.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const paymentSystemOptions = paymentSystems?.items.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      autoComplete="off"
      disabled={isSubmitting}
    >
      {fanGroupId && (
        <Row gutter={24}>
          <Col xs={24} md={16} lg={12} xl={8} xxl={6}>
            <Form.Item label={<Text strong>{t('admin_id')}</Text>}>
              <Input disabled value={fanGroupId} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={8} xxl={4}>
            <Form.Item label="hidden">
              <Button
                size="middle"
                type="text"
                onClick={copyID}
                icon={<CopyOutlined rev={undefined} />}
              >
                {t('copy_button_text')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={<Text strong>{t('admin_fan-groups_name')}</Text>}
            name={`name.${currentLanguage}`}
            validateStatus={errors.name && 'error'}
          >
            <Controller
              control={control}
              name={`name.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={12} md={8} lg={6} xl={6} xxl={4}>
          <Form.Item
            label={<Text strong>{t('admin_fan-groups_short-name')}</Text>}
            name={`shortName.${currentLanguage}`}
            validateStatus={errors.shortName && 'error'}
          >
            <Controller
              control={control}
              name={`shortName.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={10}>
          <Form.Item
            label={<Text strong>{`URL ${t('admin_fan-groups_slug')}`}</Text>}
            name={'slug'}
            validateStatus={errors.slug && errors.slug?.message && 'error'}
          >
            <Controller
              control={control}
              name={'slug'}
              render={({ field }) => (
                <Input
                  addonBefore={SEATERS_APP_BASE_URL}
                  {...field}
                  disabled={!isAdmin}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      {!isConfiguration && (
        <>
          <Row gutter={20}>
            <Col xs={12} md={8} lg={6} xl={6} xxl={4}>
              <Form.Item
                label={<Text strong>{t('admin_fan-groups_client-id')}</Text>}
                name="clientId"
                rules={[{ whitespace: true }]}
              >
                <Controller
                  control={control}
                  name={'clientId'}
                  render={({ field }) => (
                    <Input disabled={!!fanGroupId} {...field} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={12} md={8} lg={6} xl={6} xxl={4}>
              <Form.Item
                label={<Text strong>{t('admin_fan-groups_status')}</Text>}
                name="status"
              >
                <Controller
                  control={control}
                  name={'status'}
                  render={({ field }) => (
                    <Select options={FangroupStatusOptions} {...field} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
              <Form.Item
                label={
                  <Text strong>{t('admin_fan-groups_ticketing-system')}</Text>
                }
                name={`ticketingSystemId`}
              >
                <Controller
                  control={control}
                  name={`ticketingSystemId`}
                  disabled={ticketingSystemsLoading}
                  render={({ field }) => (
                    <Select
                      showSearch
                      optionFilterProp="label"
                      options={ticketingSystemOptions}
                      {...field}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
              <Form.Item
                label={
                  <Text strong>{t('admin_waiting-list_billing-mode')}</Text>
                }
                name="billingParameters.authorizedFanBillingModes"
              >
                <Controller
                  control={control}
                  name={'billingParameters.authorizedFanBillingModes'}
                  render={({ field }) => (
                    <Checkbox.Group
                      {...field}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 4,
                      }}
                      options={billingModesOptions}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
              <Form.Item
                label={
                  <Space>
                    <Text strong>{t('admin_payment-system_methods')}</Text>
                    <Tooltip title={t('admin_fan-groups_payment-methods-help')}>
                      <InfoCircleOutlined
                        rev={undefined}
                        style={{ color: baseColor600 }}
                      />
                    </Tooltip>
                  </Space>
                }
                name="billingParameters.paymentMethods"
              >
                <Controller
                  control={control}
                  name={'billingParameters.paymentMethods'}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={paymentParametersOptions}
                      mode="multiple"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
              <Form.Item
                label={<Text strong>{t('admin_payment-system')}</Text>}
                name={`billingParameters.paymentSystemId`}
              >
                <Controller
                  control={control}
                  name={`billingParameters.paymentSystemId`}
                  disabled={paymentSystemsLoading}
                  render={({ field }) => (
                    <Select options={paymentSystemOptions} {...field} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={<Text strong>{t('main_menu_terms-conditions_url')}</Text>}
            name={`termsAndConditions.${currentLanguage}`}
            validateStatus={errors.termsAndConditions && 'error'}
          >
            <Controller
              control={control}
              name={`termsAndConditions.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={<Text strong>{t('privacy_policy_url_label_text')}</Text>}
            name={`privacyPolicy.${currentLanguage}`}
            validateStatus={errors.privacyPolicy && 'error'}
          >
            <Controller
              control={control}
              name={`privacyPolicy.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={<Text strong>{t('rules_url_label_text')}</Text>}
            name={`rulesLinks.${currentLanguage}`}
            validateStatus={errors.rulesLinks && 'error'}
          >
            <Controller
              control={control}
              name={`rulesLinks.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={<Text strong>{t('legal_notice_url_label_text')}</Text>}
            name={`legalNotice.${currentLanguage}`}
            validateStatus={errors.legalNotice && 'error'}
          >
            <Controller
              control={control}
              name={`legalNotice.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={<Text strong>{t('faq_url_label_text')}</Text>}
            name={`faqLinks.${currentLanguage}`}
            validateStatus={errors.faqLinks && 'error'}
          >
            <Controller
              control={control}
              name={`faqLinks.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={<Text strong>{t('help_url_label_text')}</Text>}
            name={`helpLinks.${currentLanguage}`}
            validateStatus={errors.helpLinks && 'error'}
          >
            <Controller
              control={control}
              name={`helpLinks.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} xl={16}>
          <Form.Item
            label={<Text strong>{t('optin_terms_text_label')}</Text>}
            name={`optInTermsTexts.${currentLanguage}`}
            validateStatus={errors.optInTermsTexts && 'error'}
          >
            <Controller
              control={control}
              name={`optInTermsTexts.${currentLanguage}`}
              render={({ field: { onChange, ...field } }) => {
                return !editorShown.optInTermsTexts ? (
                  <StyledText
                    text={field.value ?? ''}
                    onClick={() =>
                      setEditorShown({ ...editorShown, optInTermsTexts: true })
                    }
                  />
                ) : (
                  <Editor {...field} onEditorChange={onChange} />
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} xl={16}>
          <Form.Item
            label={<Text strong>{t('optin_rules_text_label')}</Text>}
            name={`optInRulesTexts.${currentLanguage}`}
            validateStatus={errors.optInTermsTexts && 'error'}
          >
            <Controller
              control={control}
              name={`optInRulesTexts.${currentLanguage}`}
              render={({ field: { onChange, ...field } }) => {
                return !editorShown.optInRulesTexts ? (
                  <StyledText
                    text={field.value ?? ''}
                    onClick={() =>
                      setEditorShown({ ...editorShown, optInRulesTexts: true })
                    }
                  />
                ) : (
                  <Editor {...field} onEditorChange={onChange} />
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} xl={16}>
          <Form.Item
            label={<Text strong>{t('optin_platform_text_text')}</Text>}
            name={`optInPlatformTexts.${currentLanguage}`}
            validateStatus={errors.optInPlatformTexts && 'error'}
          >
            <Controller
              control={control}
              name={`optInPlatformTexts.${currentLanguage}`}
              render={({ field: { onChange, ...field } }) => {
                return !editorShown.optInPlatformTexts ? (
                  <StyledText
                    text={field.value ?? ''}
                    onClick={() =>
                      setEditorShown({
                        ...editorShown,
                        optInPlatformTexts: true,
                      })
                    }
                  />
                ) : (
                  <Editor {...field} onEditorChange={onChange} />
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item
            label={<Text strong>{t('hide_optin_platform_text_text')}</Text>}
            name="optInPlatformHidden"
          >
            <Controller
              control={control}
              name={'optInPlatformHidden'}
              render={({ field: { onChange, value } }) => (
                <Switch
                  onChange={onChange}
                  checked={value}
                  defaultChecked={initialValues?.optInPlatformHidden}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} xl={16}>
          <Form.Item
            label={<Text strong>{t('optin_partners_text_label')}</Text>}
            name={`optInPartnersTexts.${currentLanguage}`}
            validateStatus={errors.optInPartnersTexts && 'error'}
          >
            <Controller
              control={control}
              name={`optInPartnersTexts.${currentLanguage}`}
              render={({ field: { onChange, ...field } }) => {
                return !editorShown.optInPartnersTexts ? (
                  <StyledText
                    text={field.value ?? ''}
                    onClick={() =>
                      setEditorShown({
                        ...editorShown,
                        optInPartnersTexts: true,
                      })
                    }
                  />
                ) : (
                  <Editor {...field} onEditorChange={onChange} />
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item
            label={<Text strong>{t('hide_optin_partners_text_label')}</Text>}
            name="optInPartnersHidden"
          >
            <Controller
              control={control}
              name={'optInPartnersHidden'}
              render={({ field: { onChange, value } }) => (
                <Switch
                  onChange={onChange}
                  checked={value}
                  defaultChecked={initialValues?.optInPartnersHidden}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      {!isConfiguration && (
        <Row>
          <Col>
            <Form.Item
              label={<Text strong>{t('hide_rank_and_likelihood')}</Text>}
              name="rankAndLikelihoodHidden"
            >
              <Controller
                control={control}
                name={'rankAndLikelihoodHidden'}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    onChange={onChange}
                    checked={value}
                    defaultChecked={initialValues?.rankAndLikelihoodHidden}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={
              <Text strong>
                {t('fgc_general_geo_location_notification_title')}
              </Text>
            }
            name={`geoFilteringNotificationTitle.${currentLanguage}`}
            validateStatus={errors.geoFilteringNotificationTitle && 'error'}
          >
            <Controller
              control={control}
              name={`geoFilteringNotificationTitle.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={12} xxl={8}>
          <Form.Item
            label={
              <Text strong>
                {t('fgc_general_geo_location_notification_description')}
              </Text>
            }
            name={`geoFilteringNotificationDescription.${currentLanguage}`}
            validateStatus={
              errors.geoFilteringNotificationDescription && 'error'
            }
          >
            <Controller
              control={control}
              name={`geoFilteringNotificationDescription.${currentLanguage}`}
              render={({ field }) => <TextArea {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} xl={16}>
          <Form.Item
            label={<Text strong>{t('admin_fan-groups_description')}</Text>}
            name={`description.${currentLanguage}`}
            validateStatus={errors.description && 'error'}
          >
            <Controller
              control={control}
              name={`description.${currentLanguage}`}
              render={({ field: { onChange, ...field } }) => {
                return !editorShown.description ? (
                  <StyledText
                    text={field.value ?? ''}
                    onClick={() =>
                      setEditorShown({
                        ...editorShown,
                        description: true,
                      })
                    }
                  />
                ) : (
                  <Editor {...field} onEditorChange={onChange} />
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} xl={16}>
          <Form.Item
            label={<Text strong>{t('admin_fan-groups_welcome-text')}</Text>}
            name={`welcomeText.${currentLanguage}`}
            validateStatus={errors.welcomeText && 'error'}
          >
            <Controller
              control={control}
              name={`welcomeText.${currentLanguage}`}
              render={({ field: { onChange, ...field } }) => {
                return !editorShown.welcomeText ? (
                  <StyledText
                    text={field.value ?? ''}
                    onClick={() =>
                      setEditorShown({
                        ...editorShown,
                        welcomeText: true,
                      })
                    }
                  />
                ) : (
                  <Editor {...field} onEditorChange={onChange} />
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {!isConfiguration && (
        <Form.Item
          label={<Text strong>{t('admin_fan-groups_show-logo')}</Text>}
          name="showLogo"
        >
          <Controller
            control={control}
            name={'showLogo'}
            render={({ field: { onChange, value } }) => (
              <Switch
                onChange={onChange}
                checked={value}
                defaultChecked={initialValues?.showLogo}
              />
            )}
          />
        </Form.Item>
      )}
      {setGeneralStepValues ? (
        <FanGroupCreationFooter
          isDisabled={!isValid}
          isLoading={isLoading}
          handleNextStep={handleNextStep}
          currentStep={formSteps.indexOf('general')}
          formSteps={formSteps}
        >
          <Segmented
            size="large"
            options={languages}
            value={currentLanguage}
            onChange={setCurrentLanguage}
          />
        </FanGroupCreationFooter>
      ) : (
        <Footer
          onCancel={() => reset()}
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          <Segmented
            size="large"
            options={languages}
            value={currentLanguage}
            onChange={setCurrentLanguage}
          />
        </Footer>
      )}
    </Form>
  );
}

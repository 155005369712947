import { useFetchVenues } from '@seaters-app/data-access';
import { TablePaginationConfig } from 'antd/es/table';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { usePagination } from '../../../utils/hooks';

export const useVenues = () => {
  const [query, setQuery] = useQueryParams({
    size: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
    query: withDefault(StringParam, ''),
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      size: pageSize,
      page: current,
    });
  };

  const { data, isLoading } = useFetchVenues({
    query: query.query,
    size: query.size,
    page: query.page - 1,
  });

  const pagination = usePagination({
    pageSize: query.size,
    current: query.page,
    totalSize: data?.totalSize,
  });

  return {
    handleTableChange,
    pagination,
    data,
    isLoading,
    queryParams: query,
    setQuery,
  };
};

import {
  Avatar,
  Button,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
  notification,
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateFanGroupBadgeLink } from '@seaters-app/data-access';
import { useFGAdminOptions } from '../../../surveys/[id]/hooks/useFGAdminOptions';
import { debounce } from 'lodash';
import { useSearchedBadgesOptions } from '../hooks/useSearchedBadges';
const { Text } = Typography;

function LinkBadgeToFG({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
}) {
  const { t } = useTranslation();
  const [selectedFG, setSelectedFG] = useState('');
  const [selectedBadge, setSelectedBadge] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [searchBadgesText, setSearchBadgesText] = useState('');

  const { mutate: linkBadgeToFG } = useCreateFanGroupBadgeLink(selectedFG);

  const {
    fanGroupsOptions,
    loadMoreData: loadMoreFGs,
    fanGroupsFetching,
  } = useFGAdminOptions(
    {
      page: 0,
      size: 20,
      query: searchText,
    },
    false
  );

  const handleSearchFGs = debounce((searchValue) => {
    setSearchText(searchValue);
  }, 500);

  const {
    badgesOptions,
    loadMoreData: loadMoreBadges,
    badgesFetching,
  } = useSearchedBadgesOptions({
    page: 0,
    size: 20,
    query: searchBadgesText,
  });

  const handleScrollFGs = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      e.target.scrollTop + e.target.offsetHeight >
      e.target.scrollHeight - 5
    ) {
      loadMoreFGs();
    }
  };

  const handleScrollBadges = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      e.target.scrollTop + e.target.offsetHeight >
      e.target.scrollHeight - 5
    ) {
      loadMoreBadges();
    }
  };
  const handleSearchBadges = debounce((searchValue) => {
    setSearchBadgesText(searchValue);
  }, 500);

  const linkFGBadge = (badgeId: string) => {
    linkBadgeToFG(badgeId, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_badge_linked'),
        });
      },
      onError: (err) => {
        notification.error({
          message: t('notification_error_badge_linked'),
          description:
            t(
              `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
            ) ?? err.message,
        });
      },
      onSettled: () => {
        setOpen(false);
      },
    });
  };

  return (
    <Modal
      title={t('link_badge_to_fg_text')}
      open={isOpen}
      onCancel={() => setOpen(false)}
      footer={null}
      destroyOnClose
    >
      <Space direction="vertical" style={{ width: '100%' }} size={16}>
        <Text strong>{t('admin_fan-group')}</Text>
        <Select
          optionFilterProp="label"
          placeholder={t('search_by_placeholder_text', {
            by: t('admin_fan-groups_slug'),
          })}
          allowClear
          style={{ width: '100%' }}
          onClear={loadMoreFGs}
          notFoundContent={
            fanGroupsFetching ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Spin size="small" />
              </div>
            ) : (
              !fanGroupsOptions.length && t('not_found')
            )
          }
          showSearch
          onSearch={handleSearchFGs}
          onPopupScroll={handleScrollFGs}
          options={fanGroupsOptions}
          onSelect={(item, option) => {
            setSelectedFG(option.value?.toString() ?? '');
          }}
        />
        <Text strong>{t('select_badge_label_text')}</Text>
        <Select
          style={{ width: '100%' }}
          optionFilterProp="label"
          placeholder={t('search_by_placeholder_text', {
            by: t('admin_name'),
          })}
          allowClear
          loading={badgesFetching}
          onClear={loadMoreBadges}
          showSearch
          onSearch={handleSearchBadges}
          onPopupScroll={handleScrollBadges}
          options={badgesOptions}
          onSelect={(item, option) => {
            setSelectedBadge(option?.key?.toString() ?? '');
          }}
          optionRender={(option) => (
            <>
              <Avatar src={option.data.avatar} size="small" /> {option?.label}
            </>
          )}
        />
        <Button
          disabled={!selectedBadge}
          onClick={() => {
            linkFGBadge(selectedBadge);
            setSelectedBadge('');
          }}
          type="primary"
        >
          {t('add_badge_button_text')}
        </Button>
      </Space>
    </Modal>
  );
}

export default LinkBadgeToFG;

import {
  fetchGroupGuests,
  getSlugFromUrl,
  useFetchLook,
} from '@seaters-app/data-access';
import {
  GuestEntity,
  ListEntityV2,
  groupsKeys,
  SortDirectionMap,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { SortOrder } from 'antd/es/table/interface';

export const useFetchGroupGuests = () => {
  const slug = getSlugFromUrl();

  const [sort, setSort] = useState<{
    name: string;
    order: SortOrder;
  } | null>(null);

  const [paginationParams, setPaginationParams] = useState({
    size: 20,
    page: 0,
    search: '',
    sort: '',
  });

  const { data: lookData } = useFetchLook(slug ?? '');

  const fanGroupId = lookData?.fanGroupId ?? '';

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery<ListEntityV2<GuestEntity>, Error>({
    queryKey: groupsKeys.guests(fanGroupId),
    queryFn: ({
      pageParam = {
        ...paginationParams,
        sort:
          sort && sort.order
            ? `${sort.name}:${SortDirectionMap[sort.order]}:nullsLast`
            : '',
        size: 20,
        page: 0,
      },
    }) => fetchGroupGuests(fanGroupId, pageParam),
    getNextPageParam: (lastPage, allPages) => {
      return !lastPage.last
        ? {
            ...paginationParams,
            page: lastPage.number + 1,
            sort:
              sort && sort.order
                ? `${sort.name}:${SortDirectionMap[sort.order]}:nullsLast`
                : '',
          }
        : undefined;
    },
    enabled: !!fanGroupId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleSort = (columnName: string): void => {
    if (columnName === sort?.name) {
      setSort({
        ...sort,
        order: !sort.order
          ? 'ascend'
          : sort.order === 'ascend'
          ? 'descend'
          : null,
      });
    } else {
      setSort({
        name: columnName,
        order: 'ascend',
      });
    }
  };

  useEffect(() => {
    refetch();
  }, [paginationParams.search, sort]);

  const allItems = data?.pages.flatMap((page) => page.content) || [];

  const handleFetchNextPage = () => {
    if (fanGroupId) {
      fetchNextPage();
    }
  };

  return {
    totalItems: data?.pages[0].totalElements || 0,
    allItems,
    loadMoreData: handleFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    handleSort,
    sort,
    setPaginationParams,
    paginationParams,
  };
};

import { FanAccessRequest, LOCAL_STORAGE_SLUG } from '@seaters-app/constants';
import {
  useFetchFanGroupAccessRequests,
  useFetchLook,
} from '@seaters-app/data-access';
import { Table, Tag } from 'antd';
import Search from 'antd/es/input/Search';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { accessRequestStatusToColor } from '../../fans/[id]/FanAccessRequests/FanAccessRequests';

export const FanGroupAccessRequests = () => {
  const { t } = useTranslation();

  const slug = localStorage.getItem(LOCAL_STORAGE_SLUG);

  const { data: lookData } = useFetchLook(slug ?? '');

  const fanGroupId = lookData?.fanGroupId ?? '';

  const { data: fanGroupAccessRequests, isFetching } =
    useFetchFanGroupAccessRequests(fanGroupId, {
      itemOffset: 0,
      maxPageSize: 1000,
    });

  const [requestsList, setRequestsList] = useState(
    fanGroupAccessRequests?.items ?? []
  );
  useEffect(() => {
    setRequestsList(fanGroupAccessRequests?.items ?? []);
  }, [fanGroupAccessRequests?.items.length]);

  return (
    <Table
      rowKey={(accessRequest: FanAccessRequest, i) => i + accessRequest.code}
      loading={isFetching}
      dataSource={requestsList}
      pagination={{
        showSizeChanger: true,
      }}
      tableLayout="fixed"
    >
      <Column
        title={t('admin_user')}
        dataIndex="fan"
        key="name"
        width="15%"
        render={(fan) => {
          return <span>{fan.name}</span>;
        }}
      />
      <Column
        title={
          <Search
            onSearch={(v: string) =>
              setRequestsList(
                fanGroupAccessRequests?.items.filter((item) =>
                  item.fan.email.includes(v.trim())
                ) ?? []
              )
            }
          />
        }
        dataIndex="fan"
        key="email"
        width="35%"
        render={(fan) => {
          return <span>{fan.email}</span>;
        }}
      />
      <Column
        title={t('admin_fan-group-protection-codes_code')}
        dataIndex="code"
        key="code"
        width="12%"
      />

      <Column
        title={t('wl_creation-date-label')}
        dataIndex="createdDate"
        key="createdDate"
        render={(createdDate) => {
          return (
            <span>{dayjs(createdDate).format('DD MMM YYYY').toString()}</span>
          );
        }}
      />
      <Column
        title={t('status_label')}
        dataIndex="status"
        key="status"
        render={(status: 'PENDING' | 'REJECTED' | 'ACCEPTED') => {
          return (
            <Tag color={accessRequestStatusToColor[status].color}>
              {t(accessRequestStatusToColor[status]?.label)}
            </Tag>
          );
        }}
      />
    </Table>
  );
};

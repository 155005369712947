export enum RequestAccessStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}
export interface RequestAccessResult {
  rejectionReason: string | null;
  status: RequestAccessStatus;
  version: number;
}

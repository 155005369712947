import {
  getSingleTranslation,
  useCreateFanGroupBadgeLink,
  useRemoveFanGroupBadgeLink,
} from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import {
  Avatar,
  Popconfirm,
  Select,
  Space,
  Table,
  Typography,
  notification,
  theme,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import styles from './../fanGroupLayout.module.css';
import { BadgeFGOListEntity, routes } from '@seaters-app/constants';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Column from 'antd/es/table/Column';
import { useFGBadges } from './useFGBadges';
import { useMemo, useState } from 'react';
import { t } from 'i18next';
import { useSearchedBadgesOptions } from '../../../badges/tabs/hooks/useSearchedBadges';
import { debounce } from 'lodash';
const { Text } = Typography;

export function AddBadgeToFG() {
  const [selectedBadge, setSelectedBadge] = useState<string>('');
  const { fanGroupId = '' } = useParams();
  const { token } = theme.useToken();
  const { badges, isLoading, handleTableChange, pagination } =
    useFGBadges(fanGroupId);

  const [searchBadgesText, setSearchBadgesText] = useState('');

  const {
    badgesOptions,
    loadMoreData: loadMoreBadges,
    badgesFetching,
  } = useSearchedBadgesOptions({
    page: 0,
    size: 20,
    query: searchBadgesText,
  });

  const filtered = useMemo(() => {
    return badgesOptions?.filter((option) => {
      const isLinked = badges?.find((badge) => badge.id === option.key);
      return !isLinked;
    });
  }, [badges?.length, badgesOptions?.length]);

  const handleSearchBadges = debounce((searchValue) => {
    setSearchBadgesText(searchValue);
  }, 500);

  const handleScrollBadges = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      e.target.scrollTop + e.target.offsetHeight >
      e.target.scrollHeight - 5
    ) {
      loadMoreBadges();
    }
  };

  const { mutate: linkBadgeToFG } = useCreateFanGroupBadgeLink(fanGroupId);
  const { mutateAsync: removeBadge } = useRemoveFanGroupBadgeLink(fanGroupId);

  const unlinkFGBadge = (badgeId: string) =>
    removeBadge(badgeId, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_badge_unlinked'),
        });
      },
      onError: () => {
        notification.error({
          message: t('notification_error_badge_unlinked'),
        });
      },
    });

  const linkFGBadge = (badgeId: string) =>
    linkBadgeToFG(badgeId, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_badge_linked'),
        });
      },
      onError: (err) => {
        notification.error({
          message: t('notification_error_badge_linked'),
          description:
            t(
              `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
            ) ?? err.message,
        });
      },
    });

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }} size={16}>
          <Text strong>{t('select_badge_label_text')}</Text>
          <Select
            showSearch
            allowClear
            onSearch={handleSearchBadges}
            onClear={() => {
              setSearchBadgesText('');
              setSelectedBadge('');
              loadMoreBadges();
            }}
            onSelect={(item, option) => {
              setSelectedBadge(option?.key?.toString() ?? '');
            }}
            optionFilterProp="label"
            loading={badgesFetching}
            onPopupScroll={handleScrollBadges}
            style={{ width: '30%' }}
            size="large"
            placeholder={t('search_badge_placeholder')}
            options={filtered}
            optionRender={(option) => (
              <>
                <Avatar src={option.data.avatar} size="small" /> {option?.label}
              </>
            )}
          />
          <Button
            disabled={!selectedBadge}
            onClick={() => {
              linkFGBadge(selectedBadge);
              setSelectedBadge('');
            }}
            type="primary"
          >
            {t('add_badge_button_text')}
          </Button>
        </Space>
      </div>
      <Table
        rowKey={(badge: BadgeFGOListEntity) => badge.id}
        dataSource={badges}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
      >
        <Column
          title={t('admin_name')}
          dataIndex="name"
          key="name"
          width="30%"
          render={(name, badge: BadgeFGOListEntity) => {
            return (
              <Space>
                <Avatar
                  className={styles.avatar}
                  size="large"
                  src={badge.displayedLogoUrl}
                />
                {getSingleTranslation(name)}
              </Space>
            );
          }}
        />
        <Column
          title=""
          dataIndex="details"
          key="details"
          align="right"
          render={(name, badge: BadgeFGOListEntity) => {
            return (
              <Space size="small">
                <Link to={`../../../${routes.badges}/${badge.id}`}>
                  <Button type="link" icon={<EditOutlined rev={undefined} />}>
                    {t('admin_item_edit')}
                  </Button>
                </Link>
                <Popconfirm
                  title={t('delete_badge_button_text')}
                  description={t('delete_badge_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() => unlinkFGBadge(badge.id)}
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    type="link"
                    color={token.colorError}
                    icon={<DeleteOutlined rev={undefined} />}
                  >
                    {t('wl_delete-btn')}
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
    </>
  );
}

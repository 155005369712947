import { ContentType } from '@seaters-app/constants';
import { createUploadURL } from '@seaters-app/data-access';
import { MessageInstance } from 'antd/es/message/interface';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useUploadXlsx = (messageApi: MessageInstance) => {
  const [ticketsXlsxKey, setTicketsXlsxKey] = useState<string | null>(null);
  const [contactsXlsxKey, setContactsXlsxKey] = useState<string | null>(null);
  const [xlsxKey, setXlsxKey] = useState<string | null>(null);
  const [inputTicketsKey, setInputTicketsKey] = useState<string | null>(null);
  const { t } = useTranslation();
  const handleUploadFile = async (
    file: File,
    type?: 'tickets' | 'contacts' | 'pdf'
  ) => {
    messageApi.open({
      key: 'uploading',
      type: 'loading',
      content: t('file_uploading_message'),
      duration: 0,
    });

    await createUploadURL(file.type as ContentType)
      .then(async (createUrlRes) => {
        if (type === 'tickets') {
          setTicketsXlsxKey(createUrlRes.key);
        } else if (type === 'contacts') {
          setContactsXlsxKey(createUrlRes.key);
        } else if (type === 'pdf') {
          setInputTicketsKey(createUrlRes.key);
        } else if (!type) {
          setXlsxKey(createUrlRes.key);
        }

        if (file) {
          await axios
            .put(createUrlRes.url, file, {
              headers: {
                Authorization: null,
              },
            })
            .then(() => {
              messageApi.destroy('uploading');
              messageApi.open({
                type: 'success',
                content: t('file_uploaded_success_message', {
                  fileName: encodeURIComponent(file.name),
                }),
              });
            });
        }
      })
      .catch((err) => {
        messageApi.destroy('uploading');
        messageApi.open({
          type: 'error',
          content: `${t('file_upload_error_message')}: ${
            err.response.data.message
          }`,
        });
      });
  };

  return {
    handleUploadFile,
    contactsXlsxKey,
    ticketsXlsxKey,
    xlsxKey,
    inputTicketsKey,
  };
};

export default useUploadXlsx;

import { Flex, Modal, Spin, Steps, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { GeneralStep } from './steps/GeneralStep';
import {
  getGeneralInitialValues,
  getPersonalizationInitialValues,
  getAccessInitialValues,
} from '../helpers/getInitialValues';
import { PersonalizationStep } from './steps/PersonalizationStep';
import { AccessStep } from './steps/AccessStep';
import {
  AccessMode,
  FanGroupAccessFormType,
  FanGroupGeneralFormType,
  FanGroupGeneralValidatorSchema,
  FanGroupPersonalizationFormType,
  FanGroupVisibility,
} from '@seaters-app/constants';
import {
  requestOneTimeUpload,
  uploadFile,
  useCreateFanGroup,
  useUpdateFanGroupBackgroundImage,
  useUpdateFanGroupClientLogo,
  useUpdateFanGroupCoverImage,
  useUpdateFanGroupProfileImage,
} from '@seaters-app/data-access';
import { prepareValues } from '../helpers/prepareValues';
import { prepareValues as prepareProtCode } from '../[id]/helpers/prepareValues';
import { useNavigate } from 'react-router-dom';
import { create } from 'zustand';
import { useTranslation } from 'react-i18next';
const { Title } = Typography;
// mockedBillingParameters --- this is mocked part, it is required for create fangroup, but there is no fields for it in design

interface StepsState {
  current: number;
  next: () => void;
  prev: () => void;
  resetSteps: () => void;
}

export const useCurrentStep = create<StepsState>((set) => ({
  current: 0,
  next: () =>
    set((state: { current: number }) => ({ current: state.current + 1 })),
  prev: () =>
    set((state: { current: number }) => ({ current: state.current - 1 })),
  resetSteps: () => set(() => ({ current: 0 })),
}));

export const formSteps = ['general', 'personalization', 'access'];

export function CreateFanGroup() {
  const { t } = useTranslation();
  const { current: currentStep, resetSteps } = useCurrentStep();
  const { mutate: createFanGroup, isLoading: isFanGroupCreating } =
    useCreateFanGroup();
  const navigate = useNavigate();
  const [spinning, setSpinning] = useState<boolean>(false);
  const { mutate: updateFanGroupProfileImage } =
    useUpdateFanGroupProfileImage();

  const { mutate: updateFanGroupClientLogo } = useUpdateFanGroupClientLogo();

  const { mutate: updateFanGroupBackgroundImage } =
    useUpdateFanGroupBackgroundImage();

  const { mutate: updateFanGroupCoverImage } = useUpdateFanGroupCoverImage();

  const [generalStepValues, setGeneralStepValues] =
    useState<FanGroupGeneralFormType>(getGeneralInitialValues());

  const [personalizationStepValues, setPersonalizationStepValues] =
    useState<FanGroupPersonalizationFormType>(
      getPersonalizationInitialValues()
    );

  const [accessStepValues, setAccessStepValues] =
    useState<FanGroupAccessFormType>(getAccessInitialValues());

  async function createFG(
    values:
      | FanGroupGeneralFormType
      | FanGroupPersonalizationFormType
      | FanGroupAccessFormType
  ) {
    setSpinning(true);

    const allValues = {
      ...generalStepValues,
      ...personalizationStepValues,
      ...accessStepValues,
      ...values,
    };
    const preparedValues = prepareValues(allValues);
    const parsed = FanGroupGeneralValidatorSchema.safeParse(preparedValues);
    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }
    const optInPartnersTexts = parsed.data.optInPartnersTexts;
    const optInPlatformTexts = parsed.data.optInPlatformTexts;
    const optInTermsTexts = parsed.data.optInTermsTexts;
    const optInRulesTexts = parsed.data.optInRulesTexts;
    const termsAndConditions = parsed.data.termsAndConditions;
    const privacyPolicy = parsed.data.privacyPolicy;
    const rulesLinks = parsed.data.rulesLinks;
    const legalNotice = parsed.data.legalNotice;
    const faqLinks = parsed.data.faqLinks;
    const helpLinks = parsed.data.helpLinks;

    const visibility = allValues.visibility
      ? FanGroupVisibility.VISIBLE
      : FanGroupVisibility.INVISIBLE;

    const accessMode = allValues.accessMode;

    const color = allValues?.color1;

    const color1 =
      typeof color !== 'string'
        ? color?.hex?.length === 7
          ? color?.hex.slice(1, 7)
          : color?.hex
        : color;
    const protectionCodeExplanation = prepareProtCode({
      protectionCodeExplanation: allValues.protectionCodeExplanation,
      accessMode: allValues.accessMode,
      visibility: allValues.visibility,
    }).protectionCodeExplanation;

    const dataToSend = {
      ...parsed.data,
      operationParameters: {
        optInPartnersTexts,
        optInPlatformTexts,
        optInTermsTexts,
        optInRulesTexts,
        visibility,
        accessMode,
        termsAndConditions,
        privacyPolicy,
        rulesLinks,
        legalNotice,
        faqLinks,
        helpLinks,
      },
      visibility,
      color1,
      protectionCodeExplanation,
    };

    const { paymentSystemId, authorizedFanBillingModes, ...restData } =
      dataToSend;

    const profileImageUrlOrCanvas = allValues.profileImageUrl;
    const logoImageUrlOrCanvas = allValues.logoImageUrl;
    const coverImageUrlOrCanvas = allValues.coverImageUrl;
    const backgroundImageUrlOrCanvas = allValues.backgroundImageUrl;

    createFanGroup(restData, {
      onSuccess: async (response) => {
        notification.success({
          message: t('notification_fg_creation_success'),
        });
        if (
          profileImageUrlOrCanvas &&
          typeof profileImageUrlOrCanvas !== 'string'
        ) {
          const requestOneTimeUploadResponse = await requestOneTimeUpload({
            fileName: 'file.png',
          });
          if (requestOneTimeUploadResponse.fileId) {
            const formFile = new FormData();
            const blob = (await new Promise((resolve) =>
              profileImageUrlOrCanvas?.toBlob(resolve)
            )) as Blob;
            formFile.append('file', blob);
            await uploadFile(
              formFile,
              requestOneTimeUploadResponse.path.slice(20, 52)
            );

            updateFanGroupProfileImage(
              {
                id: response.id,
                fileId: requestOneTimeUploadResponse.fileId,
              },
              {
                onSuccess: (response) => {
                  notification.success({
                    message: t('notification_update_profile_image_success'),
                  });
                },
                onError: (err) => {
                  console.error(err);
                  setSpinning(false);
                  notification.error({
                    message: t('notification_update_profile_image_error'),
                  });
                },
              }
            );
          }
        }

        if (logoImageUrlOrCanvas && typeof logoImageUrlOrCanvas !== 'string') {
          const requestOneTimeUploadResponse = await requestOneTimeUpload({
            fileName: 'file.png',
          });
          if (requestOneTimeUploadResponse.fileId) {
            const formFile = new FormData();
            const blob = (await new Promise((resolve) =>
              logoImageUrlOrCanvas?.toBlob(resolve)
            )) as Blob;
            formFile.append('file', blob);
            await uploadFile(
              formFile,
              requestOneTimeUploadResponse.path.slice(20, 52)
            );

            updateFanGroupClientLogo(
              { id: response.id, fileId: requestOneTimeUploadResponse.fileId },
              {
                onSuccess: (response) => {
                  notification.success({
                    message: t('notification_update_client_logo_success'),
                  });
                },
                onError: (err) => {
                  console.error(err);
                  setSpinning(false);
                  notification.error({
                    message: t('notification_update_client_logo_error'),
                  });
                },
              }
            );
          }
        }

        if (
          coverImageUrlOrCanvas &&
          typeof coverImageUrlOrCanvas !== 'string'
        ) {
          const requestOneTimeUploadResponse = await requestOneTimeUpload({
            fileName: 'file.png',
          });
          if (requestOneTimeUploadResponse.fileId) {
            const formFile = new FormData();
            const blob = (await new Promise((resolve) =>
              coverImageUrlOrCanvas?.toBlob(resolve)
            )) as Blob;
            formFile.append('file', blob);
            await uploadFile(
              formFile,
              requestOneTimeUploadResponse.path.slice(20, 52)
            );

            updateFanGroupCoverImage(
              { id: response.id, fileId: requestOneTimeUploadResponse.fileId },
              {
                onSuccess: (response) => {
                  notification.success({
                    message: t('notification_update_cover_image_success'),
                  });
                },
                onError: (err) => {
                  console.error(err);
                  setSpinning(false);
                  notification.error({
                    message: t('notification_update_cover_image_error'),
                  });
                },
              }
            );
          }
        }

        if (
          backgroundImageUrlOrCanvas &&
          typeof backgroundImageUrlOrCanvas !== 'string'
        ) {
          const requestOneTimeUploadResponse = await requestOneTimeUpload({
            fileName: 'file.png',
          });
          if (requestOneTimeUploadResponse.fileId) {
            const formFile = new FormData();
            const blob = (await new Promise((resolve) =>
              allValues.backgroundImageUrl?.toBlob(resolve)
            )) as Blob;
            formFile.append('file', blob);
            await uploadFile(
              formFile,
              requestOneTimeUploadResponse.path.slice(20, 52)
            );

            updateFanGroupBackgroundImage(
              { id: response.id, fileId: requestOneTimeUploadResponse.fileId },
              {
                onSuccess: (response) => {
                  notification.success({
                    message: t('notification_update_background_image_success'),
                  });
                },
                onError: (err) => {
                  console.error(err);
                  setSpinning(false);
                  notification.error({
                    message: t('notification_update_background_image_error'),
                  });
                },
              }
            );
          }
        }
        setSpinning(false);

        if (
          currentStep !== formSteps.indexOf('access') &&
          accessStepValues.visibility &&
          accessStepValues.accessMode === AccessMode.PUBLIC &&
          !accessStepValues.protectionCodeExplanation.en
        ) {
          showWarning();
        } else {
          navigate(`../${response.id}`);
        }
      },
      onError: (err) => {
        console.error(err);
        setSpinning(false);
        notification.error({
          message: `${t('notification_fg_creation_error')}. ${
            err?.response?.data.message
          }`,
        });
      },
    });
  }

  type StepsProps = {
    values:
      | FanGroupGeneralFormType
      | FanGroupPersonalizationFormType
      | FanGroupAccessFormType;
    setValues: (v: any) => void;
    isLoading?: boolean;
    createFG: (
      values:
        | FanGroupGeneralFormType
        | FanGroupPersonalizationFormType
        | FanGroupAccessFormType
    ) => Promise<void>;
  };
  const steps: {
    title: string;
    content: (v: any) => JSX.Element;
    props: StepsProps;
  }[] = [
    {
      title: t('admin_tabs_general'),
      content: GeneralStep,
      props: {
        values: generalStepValues,
        setValues: setGeneralStepValues,
        isLoading: isFanGroupCreating,
        createFG,
      },
    },
    {
      title: t('wl_personalization_tab'),
      content: PersonalizationStep,
      props: {
        values: personalizationStepValues,
        setValues: setPersonalizationStepValues,
        isLoading: isFanGroupCreating,
        createFG,
      },
    },
    {
      title: t('admin_fan-groups_access'),
      content: AccessStep,
      props: {
        values: accessStepValues,
        setValues: setAccessStepValues,
        createFG,
      },
    },
  ];

  const showWarning = () => {
    Modal.warning({
      title: t('info_message_modal_title'),
      width: 500,
      content: (
        <div>
          {t('fg_creation_default_params_text')}
          <br />
          <strong>{t('visible_default_value_text')} </strong>
          {t('and_translation_key')}
          <strong> {t('accessmode_default_value_text')}</strong>
          <br /> {t('fg_values_change_tip')}
        </div>
      ),
      onOk: () => {
        navigate('..');
      },
    });
  };

  useEffect(() => {
    return () => {
      resetSteps();
    };
  }, []);

  return (
    <>
      <Spin tip={t('fg_creation_loader_tip')} fullscreen spinning={spinning} />
      <Flex
        vertical
        style={{
          padding: '24px 24px 80px',
        }}
        gap={12}
      >
        <Title level={3}>
          {t('main_breadcrumbs-create')} {t('admin_fan-group')}
        </Title>
        <Steps current={currentStep}>
          {steps.map((step) => (
            <Steps.Step disabled title={step.title} key={step.title} />
          ))}
        </Steps>
        {steps[currentStep].content(steps[currentStep].props)}
      </Flex>
    </>
  );
}

import { InvitationStatusEnum } from '@seaters-app/constants';

import {
  CheckOutlined,
  ClockCircleOutlined,
  MailOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { ReactElement } from 'react';

type StatusToBadge = {
  [status: string]: {
    icon: ReactElement<any, any>;
    status: 'success' | 'warning' | 'error' | 'info';
  };
};

export const statusToBadge: StatusToBadge = {
  [InvitationStatusEnum.DEMAND_ACCEPTED]: {
    icon: <CheckOutlined rev={undefined} />,
    status: 'success',
  },
  [InvitationStatusEnum.RSVP_CONFIRMED]: {
    icon: <CheckOutlined rev={undefined} />,
    status: 'success',
  },
  [InvitationStatusEnum.BEING_REVIEWED]: {
    icon: <ClockCircleOutlined rev={undefined} />,
    status: 'warning',
  },
  [InvitationStatusEnum.RSVP_TO_ACCEPT]: {
    icon: <MailOutlined rev={undefined} />,
    status: 'info',
  },
  [InvitationStatusEnum.DEMAND_REFUSED]: {
    icon: <StopOutlined rev={undefined} />,
    status: 'error',
  },
  [InvitationStatusEnum.RSVP_DECLINED]: {
    icon: <StopOutlined rev={undefined} />,
    status: 'error',
  },
  [InvitationStatusEnum.CANCELLED]: {
    icon: <StopOutlined rev={undefined} />,
    status: 'error',
  },
};

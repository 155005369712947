import React, { useEffect, useState } from 'react';
import { Progress, Space, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { baseColor400, successColor } from '../theme/colors';
const { Text } = Typography;

export const PasswordValidation = ({ password }: { password: string }) => {
  const { t } = useTranslation();
  const minPwdLength = 15;
  const [percent, setPercent] = useState<number>(0);
  const [minLength, setMinLength] = useState<boolean>(false);
  const [capitalCase, setCapitalCase] = useState<boolean>(false);
  const [alphaNum, setAlphaNum] = useState<boolean>(false);
  const [specials, setSpecials] = useState<boolean>(false);

  useEffect(() => {
    let counter = 0;

    if (password.length >= minPwdLength) {
      if (!minLength) {
        setMinLength(true);
        counter += 1;
      }
    } else {
      if (minLength) {
        setMinLength(false);
        counter -= 1;
      }
    }

    if (/[A-Z]/.test(password)) {
      if (!capitalCase) {
        setCapitalCase(true);
        counter += 1;
      }
    } else {
      if (capitalCase) {
        setCapitalCase(false);
        counter -= 1;
      }
    }

    if (/[a-z]/.test(password) && /\d/.test(password)) {
      if (!alphaNum) {
        setAlphaNum(true);
        counter += 1;
      }
    } else {
      if (alphaNum) {
        setAlphaNum(false);
        counter -= 1;
      }
    }

    if (/[^a-zA-Z0-9]/.test(password)) {
      if (!specials) {
        setSpecials(true);
        counter += 1;
      }
    } else {
      if (specials) {
        setSpecials(false);
        counter -= 1;
      }
    }

    setPercent(percent + 25 * counter);
  }, [password]);

  return (
    <Space direction="vertical" style={{ marginBottom: 24, width: '100%' }}>
      <Progress
        format={() => null}
        percent={percent}
        strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
      />

      <Space>
        <CheckOutlined
          style={{
            color:
              password.length >= minPwdLength ? successColor : baseColor400,
          }}
          rev={undefined}
        />
        <Text>{t('pwd_length_validation', { count: minPwdLength })}</Text>
      </Space>
      <Space>
        <CheckOutlined
          style={{
            color: /[A-Z]/.test(password) ? successColor : baseColor400,
          }}
          rev={undefined}
        />
        <Text>{t('pwd_capital_case')}</Text>
      </Space>
      <Space>
        <CheckOutlined
          style={{
            color:
              /[a-z]/.test(password) && /\d/.test(password)
                ? successColor
                : baseColor400,
          }}
          rev={undefined}
        />
        <Text>{t('pwd_alphanum')}</Text>
      </Space>
      <Space>
        <CheckOutlined
          style={{
            color: /[^a-zA-Z0-9]/.test(password) ? successColor : baseColor400,
          }}
          rev={undefined}
        />
        <Text>{t('pwd_special_char')}</Text>
      </Space>
    </Space>
  );
};

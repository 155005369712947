import { FanEntity, UserListEntity, UserRole } from '@seaters-app/constants';
import { create } from 'zustand';

interface UserState {
  user: FanEntity | undefined;
  setUser: (user?: FanEntity) => void;
}

export const useAppUserStore = create<UserState>((set) => ({
  user: undefined,
  setUser: (user?: FanEntity) => set(() => ({ user: user })),
}));

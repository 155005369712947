import { badgesKeys } from '@seaters-app/constants';
import {
  fetchAdminBadges,
  useFetchAdminBadges,
} from '@seaters-app/data-access';

import { useQueryClient } from '@tanstack/react-query';
import { TablePaginationConfig } from 'antd/es/table';
import { useEffect } from 'react';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { usePagination } from '../../../../utils/hooks';

export const useBadges = (categoryId?: string) => {
  const [query, setQuery] = useQueryParams({
    size: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 0),
    category_id: withDefault(StringParam, categoryId ?? ''),
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      size: pageSize,
      page: current ? current - 1 : 0,
      category_id: query.category_id,
    });
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery([badgesKeys.list(query)], () => {
      fetchAdminBadges(query);
    });
  }, [queryClient, query]);

  useEffect(() => {
    setQuery({ ...query, page: 0, category_id: categoryId });
  }, [categoryId]);

  const { data, isLoading } = useFetchAdminBadges(query);

  const pagination = usePagination({
    pageSize: query.size,
    current: query.page + 1,
    totalSize: data?.totalElements,
  });
  return {
    handleTableChange,
    pagination,
    badges: data?.content,
    isLoading,
  };
};

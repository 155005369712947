import { Button, Container } from '@seaters-app/ui';
import { ConfigProvider, Grid, Space, Typography, theme } from 'antd';
import {
  getSingleTranslation,
  useFetchWaitingListWithApproval,
} from '@seaters-app/data-access';
import { LeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { InvitationsTable } from './components/InvitationsTable';
import { useTranslation } from 'react-i18next';
import { WishListSummary } from '@seaters-app/ui-shared';
import { useWishListSummaryData } from '../hooks/useWishListSummaryData';

const { Title } = Typography;
const { useBreakpoint } = Grid;

export function Approval() {
  const { waitingListId = '' } = useParams();
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { xs } = useBreakpoint();
  const { data: waitingList } = useFetchWaitingListWithApproval(waitingListId, {
    toallocate: false,
  });

  const { getData } = useWishListSummaryData();

  if (!waitingList) return null;

  const { waitinglist } = waitingList;

  return (
    <div
      style={{
        backgroundColor: '#FFFFFF',
        paddingBottom: 100,
        height: '100%',
        paddingTop: 12,
      }}
    >
      <Container>
        <Space style={{ width: '100%' }} direction="vertical" size={12}>
          <ConfigProvider
            theme={{
              token: {
                colorLink: token.colorPrimary,
                colorLinkHover: token.colorPrimary,
                colorLinkActive: token.colorPrimary,
              },
            }}
          >
            <Link to="..">
              <Button
                padding={0}
                type="link"
                icon={<LeftOutlined rev={undefined} />}
              >
                {t('waitinglist_back_to_list')}
              </Button>
            </Link>
          </ConfigProvider>
          {!xs && (
            <Title level={3}>
              {getSingleTranslation(waitinglist.eventName)}
            </Title>
          )}
          <Space direction="vertical" style={{ width: '100%' }} size={24}>
            <WishListSummary data={getData(waitingList)} isDetails />
            <InvitationsTable />
          </Space>
        </Space>
      </Container>
    </div>
  );
}

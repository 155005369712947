import { Button, Pagination, Space } from 'antd';
import { EmptyState } from '@seaters-app/ui';
import EventCardSkeleton from '../../components/EventCardSkeleton';

import styles from '../steps.module.css';
import { useTranslation } from 'react-i18next';
import { VenueCard } from './VenueCard';
import Search from 'antd/es/input/Search';
import { VenueConfiguration, VenueEntity } from '@seaters-app/constants';
import { useFGOVenues } from './hooks/useFGOVenues';
import { useEventCreateStepper } from './hooks/useEventCreateStepper';
import { useState } from 'react';
import { VenueCreateAsFGO } from './VenueCreate';

export function VenueStep() {
  const { t } = useTranslation();

  const [isVenueCreate, setVenueCreate] = useState<boolean>(false);

  const { setSelectedVenue, setVenueConfiguration, next } =
    useEventCreateStepper();

  const { query, setQuery, venuesLoading, venues, paginationProps } =
    useFGOVenues();

  const { search } = query;

  const handleSelectVenue = (
    venue: VenueEntity,
    venueConfiguration: VenueConfiguration
  ) => {
    setSelectedVenue(venue);
    setVenueConfiguration(venueConfiguration);

    next();
  };

  const handleVenueCreateOpen = () => {
    setVenueCreate(true);
  };

  const handleVenueCreateClose = () => {
    setVenueCreate(false);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      {isVenueCreate ? (
        <VenueCreateAsFGO closeVenueCreation={handleVenueCreateClose} />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Search
              defaultValue={search}
              id="searchInput"
              placeholder={t('cwl_event_search-btn')}
              allowClear
              onSearch={(search) => {
                setQuery({
                  ...query,
                  search,
                  itemOffset: 0,
                });
              }}
              style={{ maxWidth: 300 }}
              size="middle"
            />
            <Button type="primary" onClick={handleVenueCreateOpen}>
              {t('create_venue_title')}
            </Button>
          </div>
          <div className={styles.eventsList}>
            {venuesLoading ? (
              [...Array(10)].map((_, i) => <EventCardSkeleton key={i} />)
            ) : venues?.items.length ? (
              venues?.items.map((venue) => (
                <VenueCard
                  key={venue.id}
                  venue={venue}
                  handleSelectVenue={handleSelectVenue}
                />
              ))
            ) : (
              <EmptyState />
            )}
          </div>
          {!!venues?.totalSize && (
            <Pagination showSizeChanger {...paginationProps} />
          )}
        </>
      )}
    </Space>
  );
}

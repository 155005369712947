import { MailOutlined } from '@ant-design/icons';
import {
  getSessionToken,
  getSlugFromUrl,
  useFetchLanguages,
} from '@seaters-app/data-access';
import Layout, { Header } from 'antd/es/layout/layout';
import { Link, Navigate, Outlet, useSearchParams } from 'react-router-dom';
import './PublicLayout.css';
import { Avatar, Button, Grid, Select, Space, Typography, theme } from 'antd';
import { useFanGroupData } from './hooks';
import { useEffect } from 'react';
import {
  LOCAL_STORAGE_SLUG,
  SESSION_STORAGE_TARGET_APP,
  LOCAL_STORAGE_USER_LOCALE,
  TranslationsTarget,
  routes,
  Language,
  SESSION_STORAGE_ALLOWED_GROUPS,
} from '@seaters-app/constants';
import { Container, primaryColor } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import Banner from '../../waiting-lists/components/Banner/Banner';
import { useAppTranslationsChange } from '../../../utils/hooks';
import AppFooter from '../Footer/Footer';
import { CookiesBanner } from '@seaters-app/ui-shared';
import { useFanGroupSlug } from '../../hooks';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const PublicLayout = () => {
  const auth = getSessionToken();
  const { t, i18n, ready } = useTranslation();
  const { language: lang } = i18n;
  const [searchParams] = useSearchParams();
  const prefilledLang = searchParams.get('lang') ?? null;
  const isAllowedGroups = sessionStorage.getItem(
    SESSION_STORAGE_ALLOWED_GROUPS
  );

  const { changeTranslations } = useAppTranslationsChange();

  const { token } = theme.useToken();

  const detectedSlug = getSlugFromUrl();

  const slug = localStorage.getItem(LOCAL_STORAGE_SLUG) ?? detectedSlug;

  const { xs } = useBreakpoint();

  // TO DO : rethink  language storage in local storage

  const currentLanguage =
    prefilledLang ??
    (localStorage.getItem(LOCAL_STORAGE_USER_LOCALE)?.replaceAll('"', '')
      ? localStorage.getItem(LOCAL_STORAGE_USER_LOCALE)?.replaceAll('"', '')
      : sessionStorage.getItem('i18nextLng')?.replaceAll('"', '')
      ? sessionStorage.getItem('i18nextLng')?.replaceAll('"', '')
      : 'en');

  const existLang =
    Object.values(Language).find((el) => el === currentLanguage) ?? 'en';

  const { data } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const languages = data
    ? data?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  const { profileImage, fanGroupName, coverImage, helpUrls, faqLinks } =
    useFanGroupData();

  useEffect(() => {
    if (
      detectedSlug &&
      detectedSlug !== localStorage.getItem(LOCAL_STORAGE_SLUG)
    ) {
      localStorage.setItem(LOCAL_STORAGE_SLUG, detectedSlug);
    }
  }, [detectedSlug]);

  useEffect(() => {
    document.title = fanGroupName[lang] || 'Seaters';
    return () => {
      document.title = 'Seaters';
    };
  }, [fanGroupName, lang]);

  const setCurrentLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  // if (!auth && !detectedSlug) {
  //   localStorage.removeItem(LOCAL_STORAGE_SLUG);
  // }

  const { customLightTheme } = useFanGroupSlug();

  const isLoading =
    !ready ||
    (!!detectedSlug && customLightTheme.token.colorPrimary === primaryColor);

  useEffect(() => {
    if (
      sessionStorage.getItem(SESSION_STORAGE_TARGET_APP) !==
      TranslationsTarget.WEB
    ) {
      changeTranslations(SESSION_STORAGE_TARGET_APP, TranslationsTarget.WEB);
    }
  }, []);

  useEffect(() => {
    changeTranslations(LOCAL_STORAGE_USER_LOCALE, existLang);
  }, [existLang]);

  return auth && detectedSlug ? (
    <Navigate to={`/${detectedSlug}`} />
  ) : (
    <Layout className="publicLayout">
      {profileImage && slug && (
        <Header
          style={{
            backgroundColor: token.colorPrimaryBg,
            padding: 0,
          }}
        >
          <Container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Space
                style={{
                  marginRight: 'auto',
                }}
              >
                <Link to={`./${routes.landing}`}>
                  <Avatar size="large" src={profileImage} />
                </Link>
                {!xs && (
                  <Text strong>
                    {fanGroupName[existLang] ?? fanGroupName?.en}
                  </Text>
                )}
              </Space>
              {ready && (
                <Space size={xs ? 0 : 12}>
                  <Link
                    to={faqLinks ? faqLinks : t('footer_support_faq-link')}
                    target="_blank"
                  >
                    <Text style={{ fontSize: xs ? 12 : 14 }} strong>
                      {t('footer_support_faq')}
                    </Text>
                  </Link>
                  <Link
                    to={helpUrls ? helpUrls : t('helpdesk_url')}
                    target="_blank"
                  >
                    <Button
                      type="link"
                      icon={
                        <MailOutlined size={xs ? 10 : 14} rev={undefined} />
                      }
                    >
                      <Text style={{ fontSize: xs ? 12 : 14 }} strong>
                        {t('footer_support_contact')}
                      </Text>
                    </Button>
                  </Link>
                  <Select
                    size="small"
                    variant="borderless"
                    defaultValue={existLang}
                    options={languages}
                    optionRender={({ data }) => {
                      return (
                        <Text
                          style={{
                            fontSize: xs ? 12 : 14,
                          }}
                        >
                          {data?.label}
                        </Text>
                      );
                    }}
                    style={{
                      width: xs ? '60px' : '70px',
                    }}
                    onChange={setCurrentLanguage}
                  ></Select>
                </Space>
              )}
            </div>
          </Container>
        </Header>
      )}
      <div
        style={{
          marginBottom: '30px',
        }}
      >
        {slug && <Banner image={coverImage ?? ''} />}
      </div>
      {ready && <Outlet />}
      {ready && !isAllowedGroups && <AppFooter />}
      {!isLoading && <CookiesBanner />}
    </Layout>
  );
};

export { PublicLayout };

import React from 'react';
import QuestionForm from './QuestionForm';
import { Card, Modal } from 'antd';
import { QuestionAnswers, QuestionItem } from '@seaters-app/constants';

function QuestionModal({
  isQuestionModalOpen,
  isCreateFromScratch,
  question,
  answers,
  setCreateFromScratch,
  handleUpdateSurvey,
  setQuestionModalOpen,
  removeQuestion,
  setQuestionToEdit,
}: {
  isQuestionModalOpen: boolean;
  isCreateFromScratch: boolean;
  question?: QuestionItem;
  answers?: QuestionAnswers[];
  creation?: boolean;
  setCreateFromScratch?: (v: boolean) => void;
  handleUpdateSurvey: (v: QuestionItem) => void;
  setQuestionModalOpen: (v: boolean) => void;
  removeQuestion?: (id: string) => void;
  setQuestionToEdit?: (v: QuestionItem | null) => void;
}) {
  return (
    <Modal
      destroyOnClose
      open={isQuestionModalOpen}
      title={isCreateFromScratch ? 'Create new question' : 'Edit question'}
      onCancel={() => {
        if (setCreateFromScratch) {
          setCreateFromScratch(false);
        }

        setQuestionModalOpen(false);
      }}
      width={700}
      footer={null}
    >
      {setQuestionToEdit && (
        <Card
          styles={{ body: { padding: 0 } }}
          key={question?.question?.id ?? 'newQuestion'}
        >
          <QuestionForm
            isCreateFromScratch={isCreateFromScratch}
            question={question}
            creation
            setCreateFromScratch={setCreateFromScratch}
            addToSurvey={handleUpdateSurvey}
            removeQuestion={removeQuestion}
            isQuestionModalOpen={isQuestionModalOpen}
            setQuestionToEdit={setQuestionToEdit}
            setQuestionModalOpen={setQuestionModalOpen}
          />
        </Card>
      )}
    </Modal>
  );
}

export default QuestionModal;

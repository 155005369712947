import { useEffect, useState } from 'react';
import Form from 'antd/es/form';
import {
  Avatar,
  Breadcrumb,
  Card,
  Col,
  ColorPicker,
  Flex,
  Input,
  Row,
  Segmented,
  Select,
  Spin,
  Switch,
  Typography,
  notification,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getTranslations } from '../../../utils';
import {
  BadgeEntity,
  BadgeForm,
  BadgeFormValidatorSchema,
  BadgeMoodEnum,
  BadgeValidatorSchema,
  FormattedValuesWithTranslations,
  Language,
  SEATERS_STATIC_URL,
  WaitingListEntity,
  badgesKeys,
} from '@seaters-app/constants';
import { useIsMutating } from '@tanstack/react-query';
import styles from './../styles.module.css';
import { baseColor200, baseColor600 } from '@seaters-app/ui';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSingleTranslation,
  requestOneTimeUpload,
  uploadFile,
  useCreateBadge,
  useFanGroupStore,
  useFetchBadgeData,
  useFetchLanguages,
  useUpdateBadge,
} from '@seaters-app/data-access';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import TextArea from 'antd/es/input/TextArea';
import { getInitialValues } from '../helpers/getInitialValues';
import { prepareValues } from '../helpers/prepareValues';
import WaitingListCard from '../../waiting-lists/components/WaitingListCard/WaitingListCard';
import { getMockedWishList } from '../helpers/getMockedWishList';
import { Footer, ImageCropper } from '@seaters-app/ui-shared';
import { useCategoryOptions } from './useCategoryOptions';
import { getErrorMessages } from 'apps/seaters/src/utils/helpers/getFormFieldsErrorMessages/getErrorMessages';
const { Title } = Typography;

export const BadgeFormComponent = () => {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const isSubmitting = !!useIsMutating(badgesKeys.mutation());

  const { badgeId = '' } = useParams();
  const navigate = useNavigate();
  const { fanGroup } = useFanGroupStore();

  const { data: badge } = useFetchBadgeData(badgeId);

  const [spinning, setSpinning] = useState<boolean>(false);

  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);

  const { data: languagesDate } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const { mutate: updateBadge, isLoading: isBadgeUpdating } =
    useUpdateBadge(badgeId);

  const { mutate: createBadge, isLoading: isBadgeCreating } = useCreateBadge();

  const languages = languagesDate
    ? languagesDate?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  const categoriesParams = { size: 10, page: 1 };

  const { categoryOptions, loadMoreCategories, categoriesFetching } =
    useCategoryOptions(categoriesParams);

  const handleScrollCategories = (e: any) => {
    if (
      e.target.scrollTop + e.target.offsetHeight >
      e.target.scrollHeight - 5
    ) {
      loadMoreCategories();
    }
  };

  const valuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<BadgeEntity>(
      ['name', 'description', 'displayedText'],
      badge
    );

  const initialValues = badge
    ? getInitialValues(badge, valuesWithTranslations)
    : getInitialValues();

  const methods = useForm<BadgeForm>({
    mode: 'onBlur',
    resolver: zodResolver(BadgeFormValidatorSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = methods;

  const fieldErrors = getErrorMessages<BadgeForm>(
    errors,
    ['name', 'displayedText', 'categoryId'],
    currentLanguage
  );

  const displayedText = useWatch({
    control,
    name: 'displayedText',
  });

  const color = useWatch({
    control,
    name: 'displayedColor',
  });

  const hidden = useWatch({
    control,
    name: 'hidden',
  });

  const displayedLogoUrl = useWatch({
    control,
    name: 'displayedLogoUrl',
  });

  const uploadLogo = async (
    displayedLogoUrlOrCanvas: string | HTMLCanvasElement | undefined
  ) => {
    if (
      displayedLogoUrlOrCanvas &&
      typeof displayedLogoUrlOrCanvas !== 'string'
    ) {
      const requestOneTimeUploadResponse = await requestOneTimeUpload({
        fileName: 'file.png',
      });
      if (requestOneTimeUploadResponse.fileId) {
        const formFile = new FormData();
        const blob = (await new Promise((resolve) =>
          (displayedLogoUrlOrCanvas as HTMLCanvasElement)?.toBlob(resolve)
        )) as Blob;
        formFile.append('file', blob);
        await uploadFile(
          formFile,
          requestOneTimeUploadResponse.path.slice(20, 52)
        ).catch(() => {
          notification.error({
            message: t('notification_error_logo_updated'),
          });
          requestOneTimeUploadResponse.fileId = '';
        });
      }
      return requestOneTimeUploadResponse.fileId;
    } else return;
  };

  const onSubmit = async (values: BadgeForm) => {
    setSpinning(true);
    const fileId = await uploadLogo(values.displayedLogoUrl);
    const preparedValues = prepareValues(values);
    const parsed = BadgeValidatorSchema.safeParse(preparedValues);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }
    const dataToSend = {
      ...badge,
      ...parsed.data,
    };
    if (fileId) {
      dataToSend.displayedLogoUrl = `${SEATERS_STATIC_URL}${fileId}`;
      dataToSend.displayedLogoImageId = fileId;
    }
    if (!dataToSend.categoryId) {
      delete dataToSend.categoryId;
    }
    if (badgeId) {
      updateBadge(dataToSend, {
        onSuccess: async () => {
          setSpinning(false);
          notification.success({
            message: t('notification_success_badge_updated'),
          });
          navigate('..');
        },
        onError: (err) => {
          setSpinning(false);
          console.error(err);
          notification.error({
            message: t('notification_error_badge_updated'),
            description:
              t(
                `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
              ) ?? err.message,
          });
        },
      });
    } else {
      createBadge(
        { ...dataToSend },
        {
          onSuccess: async () => {
            setSpinning(false);
            notification.success({
              message: t('notification_success_badge_created'),
            });
            navigate('..');
          },
          onError: (err) => {
            setSpinning(false);
            console.error(err);
            notification.error({
              message: t('notification_error_badge_created'),
              description:
                t(
                  `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
                ) ?? err.message,
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    if (errors && currentLanguage !== Language.EN) {
      setCurrentLanguage(Language.EN);
    }
  }, [errors]);

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <div style={{ height: '100vh' }}>
        <div className={styles.headerWrap}>
          <Breadcrumb
            items={[
              {
                title: (
                  <span onClick={() => navigate('..')}>
                    {t('admin_badges')}
                  </span>
                ),
              },
              {
                title: (
                  <span>
                    {badgeId
                      ? t('admin_badge_details_title')
                      : t('main_breadcrumbs-create')}
                  </span>
                ),
              },
            ]}
          />
          <Title level={3} className={styles.title}>
            {badgeId
              ? t('admin_badge_details_title')
              : t('admin_create_badge_title')}
          </Title>
        </div>

        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '50%', padding: '0 24px 0 0', height: '100%' }}>
            <Spin tip={t('please_wait_message_text')} spinning={spinning}>
              <FormProvider {...methods}>
                <Form
                  layout="vertical"
                  onFinish={handleSubmit(onSubmit)}
                  autoComplete="off"
                  size="middle"
                  disabled={isBadgeCreating || isBadgeUpdating}
                >
                  <Row gutter={24}>
                    <Col xs={12}>
                      <Form.Item
                        required
                        label={t('admin_name')}
                        name={`name.${currentLanguage}`}
                        validateStatus={errors.name && 'error'}
                        help={fieldErrors?.name}
                      >
                        <Controller
                          control={control}
                          name={`name.${currentLanguage}`}
                          render={({ field }) => <Input {...field} />}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        required
                        label={t('displayed_text_label')}
                        name={`displayedText.${currentLanguage}`}
                        validateStatus={errors.displayedText && 'error'}
                        help={fieldErrors?.displayedText}
                      >
                        <Controller
                          control={control}
                          name={`displayedText.${currentLanguage}`}
                          render={({ field }) => <Input {...field} />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24}>
                      <Form.Item
                        label={t('description_label')}
                        name={`description.${currentLanguage}`}
                        validateStatus={errors.description && 'error'}
                      >
                        <Controller
                          control={control}
                          name={`description.${currentLanguage}`}
                          render={({ field }) => (
                            <TextArea
                              placeholder={t('type_here_placeholder_text')}
                              {...field}
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={16}>
                      <Form.Item
                        name="categoryId"
                        label={t('admin_badges_category')}
                        validateStatus={errors.categoryId && 'error'}
                        help={fieldErrors?.categoryId}
                      >
                        <Controller
                          control={control}
                          name={`categoryId`}
                          render={({ field }) => (
                            <Select
                              loading={categoriesFetching}
                              onPopupScroll={handleScrollCategories}
                              allowClear
                              options={categoryOptions}
                              {...field}
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col>
                      <Row gutter={24}>
                        <Col>
                          <Form.Item name="hidden">
                            <Controller
                              control={control}
                              name={'hidden'}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <Switch
                                    checkedChildren={
                                      <CheckOutlined rev={undefined} />
                                    }
                                    onChange={onChange}
                                    checked={value}
                                    defaultChecked={initialValues.hidden}
                                  />
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item name="hidden">
                            <span>{t('badge_hidden_label')}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Form.Item
                    label={t('badge_logo_label')}
                    name="displayedLogoUrl"
                  >
                    <ImageCropper
                      imageOrCanvas={displayedLogoUrl}
                      handleRemoveImage={() => setValue('displayedLogoUrl', '')}
                      handleImageChange={(img) =>
                        setValue('displayedLogoUrl', img)
                      }
                      height={145}
                      width={145}
                      cropperProps={{
                        aspectRatio: 145 / 145,
                      }}
                      handleReset={() => {
                        setValue(
                          'displayedLogoUrl',
                          initialValues.displayedLogoUrl
                        );
                      }}
                    />
                  </Form.Item>

                  <Row gutter={24}>
                    <Col>
                      <Form.Item label="none" name="displayedColor">
                        <Controller
                          control={control}
                          name="displayedColor"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <ColorPicker
                                value={value}
                                onChange={(color) =>
                                  onChange(color.toHexString())
                                }
                                showText={(color) => (
                                  <span>
                                    {t('badge_color_label')} (
                                    {color.toHexString()})
                                  </span>
                                )}
                              />
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Footer
                    isDisabled={
                      !isDirty &&
                      !!badgeId &&
                      displayedLogoUrl === initialValues.displayedLogoUrl
                    }
                    isLoading={isSubmitting}
                    onCancel={
                      !!badgeId
                        ? () => reset(initialValues)
                        : () => navigate('..')
                    }
                  >
                    <Segmented
                      size="large"
                      options={languages}
                      value={currentLanguage}
                      onChange={setCurrentLanguage}
                    />
                  </Footer>
                </Form>
              </FormProvider>
            </Spin>
          </div>
          <div
            style={{
              width: '50%',
              backgroundColor: baseColor200,
              borderTopLeftRadius: 16,
              padding: 24,
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
            }}
          >
            <Title level={4} style={{ color: baseColor600 }}>
              {t('badge_preview_label')}
            </Title>
            <Card bordered size="small">
              <div className={styles.fanGroup}>
                <div>
                  <Avatar src={fanGroup?.logoImageUrl} size="large" alt="FG" />
                </div>
                <p className={styles.fanGroupName}>
                  {(fanGroup?.name
                    ? getSingleTranslation(fanGroup?.name)
                    : fanGroup?.slug) ??
                    `${t('admin_fan-group')} ${t('admin_name')}`}
                </p>
              </div>
            </Card>

            <div style={{ maxWidth: '320px' }}>
              <WaitingListCard
                preview
                waitingList={
                  getMockedWishList({
                    displayedText: [
                      { lang: lang, text: displayedText[currentLanguage] },
                    ],
                    displayedColor: color,
                    hidden: !hidden,
                    mood: BadgeMoodEnum.NEUTRAL,
                  }) as WaitingListEntity
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Flex>
  );
};

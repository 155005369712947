import React, { useEffect } from 'react';
import { Drawer, DrawerProps, Form, FormProps, Space, message } from 'antd';
import { Button } from '@seaters-app/ui';
import { useIsMutating } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  useEditTicketPool,
  useFetchTicketPool,
} from '@seaters-app/data-access';
import {
  TicketPoolFormValidator,
  TicketPoolValidator,
  TicketPoolValidatorSchema,
} from '@seaters-app/constants';
import { t } from 'i18next';
import TicketPoolForm from './TicketPoolForm';

interface EditTicketPoolProps extends DrawerProps {
  onClose: () => void;
  ticketPoolId: string;
}

const EditTicketPool: React.FC<EditTicketPoolProps> = ({
  onClose,
  ticketPoolId,
  ...props
}) => {
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();

  const key = 'editable';

  const { data: ticketPool } = useFetchTicketPool(ticketPoolId);

  const { mutate: editTicketPool } = useEditTicketPool();

  const initialValues = ticketPool
    ? {
        name: ticketPool.name,
        expirationDate: dayjs(ticketPool.expirationDate),
      }
    : undefined;

  useEffect(() => form.resetFields(), [initialValues]);

  const isMutating = !!useIsMutating();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const prepareValues = (values: any): TicketPoolValidator => {
    return {
      name: values.name,
      expirationDate: dayjs(values.expirationDate).toISOString(),
      ticketPdf: '',
    };
  };

  const onFinish: FormProps<TicketPoolFormValidator>['onFinish'] = (values) => {
    const parsed = TicketPoolValidatorSchema.safeParse(prepareValues(values));

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    const { ticketPdf, ...data } = parsed.data;

    editTicketPool(
      { ticketPoolId: ticketPoolId, ticketPool: data },
      {
        onSuccess: () => {
          messageApi.open({
            type: 'success',
            content: t('ticket_pool_edited'),
            key,
          });
          handleClose();
        },
      }
    );
  };

  const isSubmittingForm = form.isFieldsValidating() || isMutating;

  return (
    <>
      {contextHolder}
      <Drawer
        title={t('admin_ticket_pool_edit')}
        width={720}
        onClose={handleClose}
        destroyOnClose
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={handleClose} disabled={isSubmittingForm}>
              {t('general_cancel')}
            </Button>
            <Button
              onClick={() => form.submit()}
              type="primary"
              loading={isSubmittingForm}
            >
              {t('general_submit')}
            </Button>
          </Space>
        }
        {...props}
      >
        <TicketPoolForm
          onFinish={onFinish}
          form={form}
          initialValues={initialValues}
          isEdit
          disabled={isSubmittingForm}
        />
      </Drawer>
    </>
  );
};

export default EditTicketPool;

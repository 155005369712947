import React, { useState } from 'react';
import {
  getSingleTranslation,
  useAppThemeStore,
} from '@seaters-app/data-access';
import { Avatar, Flex, Modal, Space, Spin, Table, Typography } from 'antd';
import {
  PlusOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import styles from './tabs.module.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FanGroupEntity, FanGroupStatus, routes } from '@seaters-app/constants';
import { Button, EmptyState, baseColor600 } from '@seaters-app/ui';
import Column from 'antd/es/table/Column';

import Search from 'antd/es/input/Search';
import { useFanGroups } from '../../fan-groups/hooks/useFanGroups';
import { getStatusStyle } from '../../fan-groups/helpers/getStatusStyle';
import { useFGBadges } from '../../fan-groups/[id]/badges/useFGBadges';
import Meta from 'antd/es/card/Meta';
import LinkBadgeToFG from './modals/LinkBadgeToFG';
const { Text } = Typography;

function FGBadges() {
  const { t } = useTranslation();
  const { mode } = useAppThemeStore();
  const [selectedFG, setSelectedFG] = useState<FanGroupEntity | null>(null);
  const { badges: fgBadges, isLoading: isBadgesLoading } = useFGBadges(
    selectedFG?.id ?? ''
  );
  const [isLinkBadgeModalOpen, setLinkBadgeModalOpen] = useState(false);

  const openLinkBadgeModal = () => {
    setLinkBadgeModalOpen(true);
  };
  const [showBadgesModal, setShowBadgesModal] = useState(false);
  const {
    data,
    isLoading,
    handleTableChange,
    pagination,
    queryParams,
    setQuery,
  } = useFanGroups();

  const onSearch = (value: string) => {
    setQuery({ query: value });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Search
          defaultValue={queryParams.query}
          placeholder={t('event_venue_search-btn')}
          onSearch={onSearch}
          size="large"
          style={{ width: '30%' }}
        />
        <Button
          size="large"
          type="primary"
          onClick={openLinkBadgeModal}
          icon={<PlusOutlined rev={undefined} />}
        >
          {t('link_badge_to_fg_text')}
        </Button>
      </div>
      <Table
        rowKey={(fanGroup) => fanGroup.id}
        dataSource={data?.items}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
      >
        <Column
          title={t('admin_fan-groups_name')}
          dataIndex="name"
          key="name"
          width="30%"
          render={(name) => {
            return <span>{getSingleTranslation(name)}</span>;
          }}
        />
        <Column
          title={t('admin_fan-groups_slug')}
          dataIndex="slug"
          key="slug"
        />
        <Column
          title={t('wl_status-label')}
          dataIndex="status"
          key="status"
          width="20%"
          render={(status: FanGroupStatus) => {
            return (
              <span style={getStatusStyle(status, mode)}>
                {status.toLowerCase()}
              </span>
            );
          }}
        />
        <Column
          title=""
          dataIndex="details"
          key="details"
          align="right"
          render={(_, fanGroup: FanGroupEntity) => {
            return (
              <Button
                onClick={() => {
                  setSelectedFG(fanGroup);
                  setShowBadgesModal(true);
                }}
                type="text"
                icon={<EyeOutlined rev={undefined} />}
              >
                {t('show_fg_badges_btn')}
              </Button>
            );
          }}
        />
      </Table>
      <Modal
        width={800}
        title={`${getSingleTranslation(selectedFG?.name ?? [])} ${t('badges')}`}
        open={showBadgesModal}
        onCancel={() => {
          setShowBadgesModal(false);
          setSelectedFG(null);
        }}
        footer={null}
      >
        <div style={{ minHeight: 100 }}>
          <Spin tip={t('please_wait_message_text')} spinning={isBadgesLoading}>
            <Flex style={{ width: '100%' }} justify="center">
              {fgBadges?.length !== 0 ? (
                <Space direction="vertical" size={16} style={{ width: '100%' }}>
                  {fgBadges?.map((badge) => {
                    return (
                      <Link
                        to={`../../${routes.badges}/${badge.id}`}
                        style={{ width: '100%' }}
                      >
                        <Space>
                          <Avatar
                            className={styles.avatar}
                            size="large"
                            src={badge.displayedLogoUrl}
                          />
                          <Space direction="vertical" size={4}>
                            <Space>
                              <Text strong>
                                {getSingleTranslation(badge.name)}
                              </Text>
                              {badge.hidden ? (
                                <EyeInvisibleOutlined rev={undefined} />
                              ) : (
                                <EyeOutlined rev={undefined} />
                              )}
                            </Space>
                            <Meta
                              style={{ color: baseColor600 }}
                              description={getSingleTranslation(
                                badge.description
                              )}
                            />
                          </Space>
                        </Space>
                      </Link>
                    );
                  })}
                </Space>
              ) : (
                <EmptyState />
              )}
            </Flex>
          </Spin>
        </div>
      </Modal>
      <LinkBadgeToFG
        isOpen={isLinkBadgeModalOpen}
        setOpen={setLinkBadgeModalOpen}
      />
    </>
  );
}

export default FGBadges;

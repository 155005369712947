import React from 'react';
import { Input, Col, Row, notification, Typography, Space } from 'antd';
import Form from 'antd/es/form';
import { Button } from '@seaters-app/ui';
import styles from '../../profile.module.css';

import { useUpdateFanPassword } from '@seaters-app/data-access';
import { useIsFetching } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export function PasswordForm() {
  const { t } = useTranslation();
  const isFetching = !!useIsFetching();
  const [formPassword] = Form.useForm();

  const { mutate: updateFanPassword, isLoading: isPasswordUpdating } =
    useUpdateFanPassword();

  const handleUpdatePassword = (values: {
    newPassword: string;
    currentPassword: string;
    confirmNewPassword: string;
  }) => {
    const dataToSend = {
      password: values.newPassword,
      currentPassword: values.currentPassword,
    };
    updateFanPassword(dataToSend, {
      onSuccess: () => {
        notification.success({
          message: `Password was updated`,
        });
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: `Password was not updated`,
        });
      },
    });
  };
  return (
    <Form
      form={formPassword}
      name="basic"
      layout="vertical"
      initialValues={{
        newPassword: '',
        currentPassword: '',
        confirmNewPassword: '',
      }}
      onFinish={handleUpdatePassword}
      autoComplete="off"
      disabled={isFetching}
    >
      <Title className={styles.subtitle} level={4}>
        {t('profile_menu_password')}
      </Title>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
          <Form.Item
            label={t('settings_password_password-current-label')}
            name="currentPassword"
            rules={[{ whitespace: true }]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
          <Form.Item
            label={t('settings_password_password-label')}
            name="newPassword"
            rules={[
              { min: 15, message: t('pwd_length_validation', { count: 15 }) },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
          <Form.Item
            label={t('settings_personal-section_form_password-confirm-label')}
            name="confirmNewPassword"
            dependencies={['newPassword']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(t('general_new_password_validation'));
                  }
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Space>
        <Button size="middle" onClick={() => formPassword.resetFields()}>
          {t('button_text_cancel')}
        </Button>
        <Button
          size="middle"
          type="primary"
          htmlType="submit"
          loading={isPasswordUpdating}
        >
          {t('button_save_new_password')}
        </Button>
      </Space>
    </Form>
  );
}

import { CopyOutlined } from '@ant-design/icons';
import { Button } from '@seaters-app/ui';
import { Col, Form, Input, Row, notification } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';
import Text from 'antd/es/typography/Text';
import { TicketingSystem } from '@seaters-app/constants';

interface TicketingSystemFormProps {
  ticketingSystem: TicketingSystem;
}

const TicketingSystemForm: React.FC<TicketingSystemFormProps> = ({
  ticketingSystem,
}) => {
  const [ticketingSystemName, setTicketingSystemName] = useState<string>(
    ticketingSystem.name
  );

  const { id } = ticketingSystem;

  const copyID = useCallback(() => {
    if (id) {
      navigator.clipboard.writeText(id).then(
        () => {
          notification.success({
            message: t('notification_success_id_copied', {
              userId: id,
            }),
          });
        },
        () => {
          notification.error({
            message: t('notification_error_id_copied', {
              userId: id,
            }),
          });
        }
      );
    }
  }, []);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTicketingSystemName(e.target.value);
    },
    []
  );

  return (
    <Form layout="vertical">
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={8} xxl={6}>
          <Form.Item label={<Text strong>{t('admin_id')}</Text>}>
            <Input disabled value={ticketingSystem.id} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={8} xxl={4}>
          <Form.Item label="hidden">
            <Button
              type="text"
              onClick={copyID}
              icon={<CopyOutlined rev={undefined} />}
            >
              {t('copy_button_text')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={8} xxl={6}>
          <Form.Item label={<Text strong>{t('admin_name')}</Text>}>
            <Input value={ticketingSystemName} onChange={handleNameChange} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={8} xxl={4}>
          <Form.Item label="hidden">
            <Button
              type="primary"
              icon={<CopyOutlined rev={undefined} />}
              disabled={ticketingSystemName === ticketingSystem.name}
            >
              {t('button_text_save')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} lg={12} xl={8} xxl={6}>
          <Form.Item
            label={<Text strong>{t('admin_notifications_type')}</Text>}
          >
            <Input value={ticketingSystem?.type} disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TicketingSystemForm;

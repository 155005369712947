import {
  MailcampaignEntity,
  MailcampaignQuery,
  mailcampaignKeys,
  AxiosAPIError,
  CreateMailCampaignBody,
  CountEntity,
  UpdateMailCampaignBody,
  PartialMailcampaignEntity,
} from '@seaters-app/constants';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createMailcampaign,
  deleteMailcampaign,
  fetchMailcampaign,
  fetchMailcampaigns,
  sendMailcampaign,
  updateMailcampaign,
} from '../api/mailcampaign';
import { AxiosResponse } from 'axios';

export const useFetchMailcampaigns = (params: MailcampaignQuery) =>
  useQuery<CountEntity<PartialMailcampaignEntity>, Error>(
    mailcampaignKeys.list(params),
    () => fetchMailcampaigns(params),
    {
      keepPreviousData: true,
    }
  );
export const useFetchMailcampaign = (id: string) =>
  useQuery<MailcampaignEntity, Error>(
    mailcampaignKeys.lists(),
    async () => fetchMailcampaign(id),
    {
      keepPreviousData: true,
    }
  );
export const useCreateMailcampaign = (): UseMutationResult<
  MailcampaignEntity,
  AxiosAPIError<MailcampaignEntity>,
  { body: CreateMailCampaignBody },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ body }) => createMailcampaign(body), {
    onSuccess: () => queryClient.invalidateQueries(mailcampaignKeys.lists()),
  });
};

export const useUpdateMailcampaign = (): UseMutationResult<
  MailcampaignEntity,
  AxiosAPIError<MailcampaignEntity>,
  { id: string, body: UpdateMailCampaignBody },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, body }) => updateMailcampaign(id, body), {
    onSuccess: () => queryClient.invalidateQueries(mailcampaignKeys.lists()),
  });
};
export const useSendMailcampaign = (): UseMutationResult<
  AxiosResponse,
  AxiosAPIError<object>,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => sendMailcampaign(id), {
    onSuccess: () => queryClient.invalidateQueries(mailcampaignKeys.lists()),
  });
};

export const useDeleteMailcampaign = (): UseMutationResult<
  AxiosResponse,
  AxiosAPIError<object>,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => deleteMailcampaign(id), {
    onSuccess: () => queryClient.invalidateQueries(mailcampaignKeys.lists()),
  });
}
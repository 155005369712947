import {
  CurrencyCodeEntity,
  endpoints,
  ListEntity,
  PaginationQuery,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchCurrencyCodes = (
  params: PaginationQuery
): Promise<ListEntity<CurrencyCodeEntity>> =>
  api.get(endpoints.currencyCodes, { params });

import { Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from 'antd/es/modal/Modal';
import {
  groupsKeys,
  GuestFormValidator,
  Language,
} from '@seaters-app/constants';
import {
  queryClient,
  useCreateGuest,
  useFetchHostFanGroupCustomInfo,
} from '@seaters-app/data-access';
import { ContactForm } from './ContactForm';
import { useIsMutating } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useValidator } from './hooks';

interface CreateContactModalType {
  groupId: string;
  isCreateContactModal: boolean;
  setIsCreateContactModal: (state: boolean) => void;
}

const initialValues: GuestFormValidator = {
  firstName: '',
  lastName: '',
  guestEmail: '',
  mobilePhoneNumber: { countryCallingCode: '', localNumber: '' },
  company: '',
  jobtitle: '',
  locale: Language.EN,
};

export function CreateContactModal({
  groupId,
  isCreateContactModal,
  setIsCreateContactModal,
}: CreateContactModalType) {
  const { t } = useTranslation();

  const isGuestMutating = !!useIsMutating(groupsKeys.guestsMutation(groupId));

  const { mutate: createGuest } = useCreateGuest(groupId, 'single');

  const { data: fgCustomInfo } = useFetchHostFanGroupCustomInfo(groupId);

  const { GuestFormValidatorSchema } = useValidator(fgCustomInfo);

  const methods = useForm({
    mode: 'onBlur',
    resolver: zodResolver(GuestFormValidatorSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const { handleSubmit, reset } = methods;

  const hideModal = () => {
    setIsCreateContactModal(false);
    reset();
  };

  type GuestFormValidator = z.infer<typeof GuestFormValidatorSchema>;

  const onSubmit = (values: GuestFormValidator) => {
    const parsed: GuestFormValidator =
      GuestFormValidatorSchema.safeParse(values);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    if (
      !parsed.data.mobilePhoneNumber?.countryCallingCode?.length ||
      !parsed.data.mobilePhoneNumber?.localNumber?.length
    ) {
      parsed.data.mobilePhoneNumber = null;
    }

    createGuest(parsed.data, {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(groupsKeys.guests(groupId));
        notification.success({
          message: t('notification_contact_created_success', { count: 1 }),
        });
        hideModal();
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: t('notification_contact_created_error_one'),
          description:
            err.response?.data.message && t(err.response?.data.message),
        });
      },
    });
  };

  return (
    <Modal
      title={t('guestlist_create_guest')}
      open={isCreateContactModal}
      onOk={handleSubmit(onSubmit)}
      onCancel={hideModal}
      okText={t('button_text_create')}
      confirmLoading={isGuestMutating}
    >
      <FormProvider {...methods}>
        <Form layout="vertical" autoComplete="off">
          <ContactForm
            initialValues={initialValues}
            disabled={isGuestMutating}
            fgCustomInfo={fgCustomInfo}
          />
        </Form>
      </FormProvider>
    </Modal>
  );
}

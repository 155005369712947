import {
  Checkbox,
  ConfigProvider,
  Form,
  InputNumber,
  Modal,
  Space,
  Switch,
  Table,
  Typography,
  theme,
  Tooltip,
  Avatar,
} from 'antd';
import {
  getSingleTranslation,
  queryClient,
  useCurrencies,
} from '@seaters-app/data-access';
import {
  CurrencyCodeEntity,
  currencyCodesKeys,
  FanGroupOwnerWaitingListEntity,
  ListEntity,
} from '@seaters-app/constants';

import {
  ColumnGroupType,
  ColumnsType,
  TablePaginationConfig,
} from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DatePicker, baseColor900 } from '@seaters-app/ui';
import { useState } from 'react';
import { useWLMatrixStore } from './storage/wl-matrix-store';
import Paragraph from 'antd/es/typography/Paragraph';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

type MatrixViewType = {
  items?: FanGroupOwnerWaitingListEntity[];
  pagination: TablePaginationConfig;
  handleTableChange: ({ current, pageSize }: TablePaginationConfig) => void;
};

// TODO dynamic columns implementation

export function MatrixView({
  items,
  pagination,
  handleTableChange,
  setQuery,
}: MatrixViewType) {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const { token } = theme.useToken();

  const informationColumn: ColumnGroupType<FanGroupOwnerWaitingListEntity> = {
    title: t('matrix_header_information'),
    align: 'center',
    children: [
      {
        title: t('matrix_header_date'),
        key: 'informationDate',
        dataIndex: ['event', 'startDate'],
        align: 'center',
        render: (value) => dayjs(value).format('DD/MM/YYYY'),
      },
      {
        title: t('matrix_header_name'),
        key: 'informationName',
        dataIndex: 'experienceName',

        render: (value, record) => (
          <Link to={record.waitingListId}>
            {value?.length
              ? getSingleTranslation(value)
              : record.event.eventName}
          </Link>
        ),
      },
      {
        title: t('matrix_header_status'),
        key: 'informationStatus',
        dataIndex: 'status',
        align: 'center',
      },

      {
        title: t('matrix_header_badge'),
        key: 'informationBadges',
        dataIndex: 'waitingListBadges',
        align: 'center',

        render: (
          waitingListBadges: FanGroupOwnerWaitingListEntity['waitingListBadges']
        ) => (
          <Avatar.Group
            maxCount={2}
            maxStyle={{
              color: token.colorPrimaryText,
              backgroundColor: token.colorPrimaryBg,
            }}
          >
            {waitingListBadges.map(({ badge, attribute }) => (
              <Tooltip
                title={`${getSingleTranslation(badge.name)}: ${attribute}`}
                placement="top"
              >
                <Avatar src={badge.displayedLogoUrl} />
              </Tooltip>
            ))}
          </Avatar.Group>
        ),
      },
      {
        title: t('matrix_header_category'),
        key: 'informationCategory',
        dataIndex: 'name',
      },
      {
        title: t('matrix_header_price'),
        key: 'informationPrice',
        dataIndex: 'price',
        render: (value, record) => {
          const data: ListEntity<CurrencyCodeEntity> | undefined =
            queryClient.getQueryData(
              currencyCodesKeys.list({
                itemOffset: 0,
                maxPageSize: 80,
              })
            );

          const currency = data?.items.find(
            (el) => el.code === record.event.venueCurrency
          );

          if (Number(value)) {
            return `${currency?.symbol || ''} ${value}`;
          } else return t('component_waitinglist_price-free');
        },
      },
    ],
  };

  const inventoryColumn: ColumnGroupType<FanGroupOwnerWaitingListEntity> = {
    title: t('matrix_header_inventory'),
    align: 'center',

    children: [
      {
        title: t('matrix_header_total'),
        dataIndex: 'totalTickets',
        key: 'inventoryTotalTickets',
        align: 'center',
      },
      {
        title: t('matrix_header_remaining'),
        key: 'inventoryRemainingTickets',
        render: (_, record) => record.totalTickets - record.distributedTickets,
        align: 'center',
      },
    ],
  };

  const demandColumn: ColumnGroupType<FanGroupOwnerWaitingListEntity> = {
    title: t('matrix_header_demand'),
    align: 'center',

    children: [
      {
        title: t('matrix_header_total'),
        key: 'demandTotal',
        align: 'center',

        render: (_, record) => {
          const data = record.distributedTickets + record.actualDemandedSeats;

          return <Text type={!data ? 'danger' : undefined}>{data}</Text>;
        },
      },
      {
        title: t('matrix_header_actual'),
        dataIndex: 'actualDemandedSeats',
        key: 'demandTotalActual',
        align: 'center',

        render: (data) => {
          return <Text type={!data ? 'danger' : undefined}>{data}</Text>;
        },
      },
      {
        title: t('matrix_header_new'),
        dataIndex: 'newDemandedSeats',
        key: 'demandNew',
        align: 'center',

        render: (data) => {
          return <Text type={!data ? 'danger' : undefined}>{data}</Text>;
        },
      },
    ],
  };

  const allocationColumn: ColumnGroupType<FanGroupOwnerWaitingListEntity> = {
    title: t('matrix_header_allocation'),
    align: 'center',

    children: [
      {
        title: t('matrix_header_confirmed'),
        key: 'allocationConfirmed',
        align: 'center',

        render: (_, record) =>
          `${record.totalSeatsAccepted} / ${record.totalTickets}`,
      },
      {
        title: t('matrix_header_declined'),
        key: 'allocationDeclined',
        align: 'center',

        render: (_, record) =>
          record.actualDemandedSeats - record.newDemandedSeats,
      },
      {
        title: t('matrix_header_wo_seats'),
        dataIndex: 'newDemandedSeats',
        key: 'allocationWithOutSeats',
        align: 'center',

        render: (_, record) =>
          record.distributedTickets -
          record?.totalSeatsAssigned -
          record?.totalSeatsAccepted,
      },
    ],
  };

  const distributionColumn: ColumnGroupType<FanGroupOwnerWaitingListEntity> = {
    title: t('matrix_header_distribution'),
    align: 'center',

    children: [
      {
        title: (
          <Tooltip
            title={`${t('admin_waiting-list_total-tickets')} / ${t(
              'matrix_header_confirmed'
            )} / ${t('matrix_header_distributed')}`}
          >
            {t('matrix_header_distributed')}
          </Tooltip>
        ),
        key: 'distributed',
        align: 'center',

        render: (_, record) =>
          `${record.totalTickets} / ${record.totalSeatsAccepted} / ${record.distributedTickets}`,
      },
    ],
  };

  const informationOptions = [
    { label: t('matrix_header_category'), value: 'informationCategory' },
    { label: t('matrix_header_status'), value: 'informationStatus' },
    { label: t('matrix_header_price'), value: 'informationPrice' },
    { label: t('matrix_header_badge'), value: 'informationBadges' },
  ];

  const demandsOptions = [
    { label: t('matrix_header_actual'), value: 'demandTotalActual' },
    { label: t('matrix_header_new'), value: 'demandNew' },
    { label: t('matrix_header_total'), value: 'demandTotal' },
  ];

  const inventoryOptions = [
    { label: t('matrix_header_total'), value: 'inventoryTotalTickets' },
    {
      label: t('matrix_header_remaining'),
      value: 'inventoryRemainingTickets',
    },
  ];

  const allocationOptions = [
    { label: t('matrix_header_declined'), value: 'allocationDeclined' },
    {
      label: t('matrix_header_wo_seats'),
      value: 'allocationWithOutSeats',
    },
  ];

  const allOptions = [
    ...informationOptions,
    ...demandsOptions,
    ...inventoryOptions,
    ...allocationOptions,
  ];

  const columns: ColumnsType<FanGroupOwnerWaitingListEntity> = [
    informationColumn,
    inventoryColumn,
    demandColumn,
    allocationColumn,
    distributionColumn,
  ];
  const [highlightedDaysBefore, setHighlightedDaysBefore] = useState<
    number | null
  >(3);

  const [isGroupedByEvent, setIsGroupedByEvent] = useState<boolean>(false);
  const [isEventsExpanded, setIsEventsExpanded] = useState<boolean>(false);
  const [isWLHighlighted, setIsWLHighlighted] = useState<boolean>(false);

  const [selectedInformationCols, setSelectedInformationCols] = useState<
    CheckboxValueType[]
  >(JSON.parse(localStorage.getItem('selectedInformationCols') || '[]'));

  const [selectedInventoryCols, setSelectedInventoryCols] = useState<
    CheckboxValueType[]
  >(JSON.parse(localStorage.getItem('selectedInventoryCols') || '[]'));

  const [selectedDemandsCols, setSelectedDemandsCols] = useState<
    CheckboxValueType[]
  >(JSON.parse(localStorage.getItem('selectedDemandsCols') || '[]'));

  const [selectedAllocationCols, setSelectedAllocationCols] = useState<
    CheckboxValueType[]
  >(JSON.parse(localStorage.getItem('selectedAllocationCols') || '[]'));

  const shownColumns = selectedInformationCols
    .concat(selectedInventoryCols)
    .concat(selectedDemandsCols)
    .concat(selectedAllocationCols);

  const { isSettingsModalOpen, setIsSettingsModalOpen } = useWLMatrixStore();

  const { currenciesOptions } = useCurrencies();

  const setToLocalStorage = () => {
    localStorage.setItem(
      'selectedInformationCols',
      JSON.stringify(selectedInformationCols)
    );
    localStorage.setItem(
      'selectedInventoryCols',
      JSON.stringify(selectedInventoryCols)
    );
    localStorage.setItem(
      'selectedDemandsCols',
      JSON.stringify(selectedDemandsCols)
    );
    localStorage.setItem(
      'selectedAllocationCols',
      JSON.stringify(selectedAllocationCols)
    );
    localStorage.setItem('isGroupedByEvent', JSON.stringify(isGroupedByEvent));

    localStorage.setItem(
      'highlightedDaysBefore',
      JSON.stringify(highlightedDaysBefore)
    );

    localStorage.setItem('isWLHighlighted', JSON.stringify(isWLHighlighted));
  };

  const handleCloseSettings = () => {
    setIsSettingsModalOpen(false);

    setToLocalStorage();
  };

  const handleGroupByEventChange = (enable: boolean) => {
    setIsGroupedByEvent(enable);
  };

  const handleEventsExpanded = (enable: boolean) => {
    setIsEventsExpanded(enable);
  };

  const handleWLHighlighted = (enable: boolean) => {
    setIsWLHighlighted(enable);
  };

  const filteredColumns = columns.map((column) => {
    return {
      ...column,
      children: column.children.filter((child) =>
        allOptions.map((el) => el.value).includes(child.key)
          ? shownColumns.includes(child.key)
          : true
      ),
    };
  });

  if (!items) {
    return null;
  }

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: token.colorBorder,
            },
          },
          token: {
            colorLink: token.colorPrimary,
            colorLinkHover: token.colorPrimary,
          },
        }}
      >
        <Table
          size="small"
          columns={filteredColumns}
          dataSource={items}
          bordered
          pagination={pagination}
          onChange={handleTableChange}
          onRow={({ event, totalTickets, actualDemandedSeats }, ...rest) => {
            if (isWLHighlighted) {
              const daysDiff = dayjs(event.startDate).diff(new Date(), 'day');

              const isHighlighted =
                daysDiff <= Number(highlightedDaysBefore) &&
                daysDiff >= 0 &&
                totalTickets > 0 &&
                actualDemandedSeats > 0;

              if (isHighlighted) {
                return {
                  style: {
                    backgroundColor: token.colorBgTextActive,
                  },
                };
              }
            }

            return {};
          }}
        />
      </ConfigProvider>
      <Modal
        title={
          <Title level={4} style={{ margin: 0 }}>
            {t('matrix_table_settings')}
          </Title>
        }
        open={isSettingsModalOpen}
        footer={null}
        onCancel={handleCloseSettings}
      >
        <Space direction="vertical" size="large">
          <Space direction="vertical">
            <Title level={5} style={{ color: baseColor900, margin: 0 }}>
              {t('matrix_table_settings_display_desc')}
              {/* Choose how to display
              the items */}
            </Title>
            <Form.Item
              label={t('matrix_table_settings_event_group')}
              // label="Group by event"
              labelAlign="left"
              colon={false}
              style={{ margin: 0 }}
            >
              <Switch
                disabled
                checked={isGroupedByEvent}
                onChange={handleGroupByEventChange}
              />
            </Form.Item>
            <Form.Item
              label={t('matrix_table_settings_events_expanded')}
              // label="Expand all events on load"
              labelAlign="left"
              colon={false}
              style={{ margin: 0 }}
            >
              <Switch
                disabled
                checked={isEventsExpanded}
                onChange={handleEventsExpanded}
              />
            </Form.Item>
            <Form.Item
              label={
                <Space size={8}>
                  {/* Highlight WL */}
                  {t('matrix_table_settings_highlight_WL-before')}
                  <InputNumber
                    min={1}
                    defaultValue={3}
                    value={highlightedDaysBefore}
                    onChange={setHighlightedDaysBefore}
                    width={100}
                  />
                  {t('matrix_table_settings_highlight_WL-after')}
                  {/* days before
                  start date */}
                </Space>
              }
              labelAlign="left"
              colon={false}
              style={{ margin: 0 }}
            >
              <Switch
                checked={isWLHighlighted}
                onChange={handleWLHighlighted}
              />
            </Form.Item>
            <Form.Item
              label={t('event_starts_after_label')}
              labelAlign="left"
              colon={false}
              style={{ margin: 0 }}
            >
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(date, dateString) => {
                  setQuery({
                    eventStartDate: dayjs(date).toDate(),
                  });
                }}
              />
            </Form.Item>
          </Space>

          <Space direction="vertical">
            <Title level={5} style={{ color: baseColor900 }}>
              {t('matrix_table_settings_columns_selection_desc')}
              {/* Select the columns you want to display: */}
            </Title>
            <Space direction="vertical">
              <Paragraph strong style={{ color: baseColor900, margin: 0 }}>
                {t('matrix_header_information')}
              </Paragraph>
              <Checkbox.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 4,
                }}
                options={informationOptions}
                value={selectedInformationCols}
                onChange={setSelectedInformationCols}
              />
            </Space>
            <Space direction="vertical">
              <Paragraph strong style={{ color: baseColor900, margin: 0 }}>
                {t('matrix_header_inventory')}
              </Paragraph>
              <Checkbox.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 4,
                }}
                options={inventoryOptions}
                value={selectedInventoryCols}
                onChange={setSelectedInventoryCols}
              />
            </Space>
            <Space direction="vertical">
              <Paragraph strong style={{ color: baseColor900, margin: 0 }}>
                {t('matrix_header_demand')}
              </Paragraph>
              <Checkbox.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 4,
                }}
                options={demandsOptions}
                value={selectedDemandsCols}
                onChange={setSelectedDemandsCols}
              />
            </Space>
            <Space direction="vertical">
              <Paragraph strong style={{ color: baseColor900, margin: 0 }}>
                {t('matrix_header_allocation')}
              </Paragraph>
              <Checkbox.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 4,
                }}
                options={allocationOptions}
                value={selectedAllocationCols}
                onChange={setSelectedAllocationCols}
              />
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
}

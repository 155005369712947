import { BNPHospitalitySlug } from '@seaters-app/constants';
import { downloadFileByURL, useExportFGOSeats } from '@seaters-app/data-access';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ExcelJS from 'exceljs';
import * as Papa from 'papaparse';
import dayjs from 'dayjs';

const fetchCsvAndConvertToXlsx = async (csvUrl: string) => {
  try {
    const response = await fetch(csvUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch report');
    }

    const csvData = await response.text();

    const { data: rows }: { data: string[][] } = Papa.parse<string[]>(csvData, {
      header: false,
      skipEmptyLines: true,
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.addRows(rows);

    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF008800' },
      };
      cell.font = { bold: true };
    });

    const dateColumnIndex = rows[0].findIndex((cell) =>
      cell.toLowerCase().includes('date')
    );
    if (dateColumnIndex !== -1) {
      for (let i = 1; i < rows.length; i++) {
        const dateValue = rows[i][dateColumnIndex];
        if (dateValue) {
          const parsedDate = dayjs(dateValue);
          if (parsedDate.isValid()) {
            worksheet.getCell(i + 1, dateColumnIndex + 1).value =
              parsedDate.toDate();
            worksheet.getCell(i + 1, dateColumnIndex + 1).numFmt =
              'DD MMM YYYY HH:mm';
          }
        }
      }
    }

    rows[0].forEach((_, i) => {
      worksheet.getColumn(i + 1).width = 20;
    });

    const emailColumnIndex = rows[0].findIndex((cell) =>
      cell.toLowerCase().includes('email')
    );
    if (emailColumnIndex !== -1) {
      worksheet.getColumn(emailColumnIndex + 1).width = 40;
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Seats report.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error fetching or converting CSV to XLSX:', error);
  }
};

export const useExportList = (): {
  isExporting: boolean;
  handleExport: () => void;
} => {
  const { wishListId = '' } = useParams();

  const [reportLoading, setReportLoading] = useState(false);

  const { mutate } = useExportFGOSeats(wishListId);

  const handleExportFGReport = () => {
    setReportLoading(true);

    mutate(
      { reportName: BNPHospitalitySlug },
      {
        onSuccess: async (response) => {
          // downloadFileByURL(response.url);
          fetchCsvAndConvertToXlsx(response.url);
          setReportLoading(false);
        },
      }
    );
  };
  return {
    isExporting: reportLoading,
    handleExport: handleExportFGReport,
  };
};

import { Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from 'antd/es/modal/Modal';
import {
  groupsKeys,
  GuestEntity,
  GuestFormValidator,
  GuestFormValidatorSchema,
  SemanticNameEnum,
  waitingListInvitationsKeys,
} from '@seaters-app/constants';
import {
  queryClient,
  useCreateGuestCustomInfo,
  useUpdateGuest,
} from '@seaters-app/data-access';
import { ContactForm } from './ContactForm';
import { useIsMutating } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useParams } from 'react-router-dom';

interface EditContactModalType {
  groupId: string;
  isEditContactModal: boolean;
  setIsEditContactModal: (state: boolean) => void;
  guestToEdit?: GuestEntity;
  setGuestToEdit: (state: GuestEntity) => void;
  isInvitationExists?: boolean;
}

const defaultInitialValues: GuestFormValidator = {
  firstName: '',
  lastName: '',
  guestEmail: '',
  mobilePhoneNumber: { countryCallingCode: '', localNumber: '' },
  company: '',
  jobtitle: '',
  locale: '',
};

export function EditContactModal({
  groupId,
  isEditContactModal,
  setIsEditContactModal,
  guestToEdit,
  setGuestToEdit,
  isInvitationExists = false,
}: EditContactModalType) {
  const { t } = useTranslation();
  const { waitingListId = '' } = useParams();

  const isGuestMutating = !!useIsMutating(groupsKeys.guestsMutation(groupId));

  const { mutate: updateGuest } = useUpdateGuest(groupId);
  const { mutateAsync: createGuestCustomInfo } =
    useCreateGuestCustomInfo(groupId);

  const initialValues = guestToEdit
    ? {
        firstName: guestToEdit.firstName,
        lastName: guestToEdit.lastName,
        guestEmail: guestToEdit.guestEmail,
        mobilePhoneNumber: {
          countryCallingCode: guestToEdit.mobileCountryCallingCode,
          localNumber: guestToEdit.mobileLocalNumber,
        },
        company:
          guestToEdit.customInfos.find(
            (customInfo) => customInfo.semanticName === SemanticNameEnum.COMPANY
          )?.informationValue || '',
        jobtitle:
          guestToEdit.customInfos.find(
            (customInfo) =>
              customInfo.semanticName === SemanticNameEnum.JOB_TITLE
          )?.informationValue || '',
        locale: guestToEdit.lang,
      }
    : defaultInitialValues;

  const hideModal = () => {
    setIsEditContactModal(false);
  };

  const onSubmit = (values: GuestFormValidator) => {
    const parsed = GuestFormValidatorSchema.safeParse(values);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    const { company, jobtitle, ...rest } = parsed.data;

    const customInfos = [
      {
        semanticName: SemanticNameEnum.COMPANY,
        value: company,
      },
      {
        semanticName: SemanticNameEnum.JOB_TITLE,
        value: jobtitle,
      },
    ];

    if (
      !rest.mobilePhoneNumber?.countryCallingCode?.length ||
      !rest.mobilePhoneNumber?.localNumber?.length
    ) {
      rest.mobilePhoneNumber = null;
    }

    updateGuest(
      {
        guestId: guestToEdit?.guestId || '',
        body: rest,
      },
      {
        onSuccess: async (data) => {
          customInfos.forEach(async ({ semanticName, value }) => {
            if (value && value.length) {
              await createGuestCustomInfo({
                guestId: guestToEdit?.guestId || '',
                body: {
                  informationValue: value,
                  semanticName: semanticName,
                },
              });
            }
          });
          setGuestToEdit(data);
          setIsEditContactModal(false);

          if (!isInvitationExists) {
            queryClient.invalidateQueries(groupsKeys.guests(groupId));
          } else {
            queryClient.invalidateQueries(
              waitingListInvitationsKeys.allPerWaitingList(waitingListId)
            );
          }

          notification.success({
            message: t('notification_contact_updated_success'),
          });
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_contact_updated_error'),
            description:
              err.response?.data.message && t(err.response?.data.message),
          });
        },
      }
    );
  };

  const methods = useForm({
    mode: 'onBlur',
    resolver: zodResolver(GuestFormValidatorSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const { handleSubmit } = methods;

  return (
    <Modal
      title={t('guestlist_contact_edit')}
      open={isEditContactModal}
      onOk={handleSubmit(onSubmit)}
      onCancel={hideModal}
      okText="Submit"
      confirmLoading={isGuestMutating}
    >
      <FormProvider {...methods}>
        <Form layout="vertical" autoComplete="off">
          <ContactForm
            initialValues={initialValues}
            disabled={isGuestMutating}
          />
        </Form>
      </FormProvider>
    </Modal>
  );
}

import { Tag } from 'antd';

import { FanBadgeStatusEnum } from '@seaters-app/constants';
import { errorColor, successColor } from '@seaters-app/ui';

export interface FanBadgeStatusTagProps {
  status: FanBadgeStatusEnum;
}

const badgeStatusToColor = {
  [FanBadgeStatusEnum.REVOKED]: errorColor,
  [FanBadgeStatusEnum.AWARDED]: successColor,
};
export function FanBadgeStatusTag({ status }: FanBadgeStatusTagProps) {
  return <Tag color={badgeStatusToColor[status]}>{status}</Tag>;
}

export default FanBadgeStatusTag;

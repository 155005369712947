import { useFetchAdminUsers } from '@seaters-app/data-access';
import { TablePaginationConfig } from 'antd/es/table';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
  createEnumParam,
} from 'use-query-params';
import { usePagination } from '../../../utils/hooks';
import { paginationFormat } from '../../../utils/helpers';
import { SearchType } from '@seaters-app/constants';

export const useUsers = () => {
  const [query, setQuery] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    current: withDefault(NumberParam, 1),
    search: withDefault(StringParam, ''),
    searchType: withDefault(
      createEnumParam([
        SearchType.emailFragment,
        SearchType.lastNameFragment,
        SearchType.phoneFragment,
      ]),
      SearchType.emailFragment
    ),
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      pageSize,
      current,
    });
  };

  const queryParams = paginationFormat(query);

  const { data, isLoading } = useFetchAdminUsers({
    ...queryParams,
    query: query.search,
    searchType: query.searchType,
  });

  const pagination = usePagination({ ...query, totalSize: data?.totalSize });

  return {
    handleTableChange,
    pagination,
    data,
    isLoading,
    queryParams: query,
    setQuery,
  };
};

import { Language } from '@seaters-app/constants';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function getErrorMessages<Type extends FieldValues>(
  errors: FieldErrors<Type>,
  fields: Array<keyof Type>,
  language: Language
): {
  [key: string]: string;
} | null {
  const { t } = useTranslation();
  const fieldsErrors = fields.reduce(
    (acc: { [field: string]: string }, curr: any) => {
      return {
        ...acc,
        [`${curr}`]: errors[curr]?.[language]
          ? t(errors[curr][language].message)
          : t(errors[curr]?.message),
      };
    },
    {}
  );
  return fieldsErrors;
}

import React from 'react';
import styles from './logo.module.css';
import { useAppThemeStore } from '@seaters-app/data-access';
import seatersLogo from './../assets/icons/seaters_logo.svg';
import seatersLogoWhite from './../assets/icons/seaters_white_full.svg';

export const Logo = ({ inverted }: { inverted?: boolean }) => {
  const { mode } = useAppThemeStore();

  return (
    <div className={styles.logo}>
      <img
        alt="Logo"
        src={mode === 'light' && inverted ? seatersLogoWhite : seatersLogo}
      />
    </div>
  );
};

export default Logo;

import { Space } from 'antd';
import { useFGOWishListsDistribution } from './hooks/useFGOWishListsDistribution';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Container,
  EmptyState,
  WishListSummarySkeleton,
} from '@seaters-app/ui';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';
import styles from './distribute.module.css';
import { useTranslation } from 'react-i18next';
import { WishListSummary } from '@seaters-app/ui-shared';
import { ExportReportButton } from './ExportReportButton';
import { useIsCustomFlow } from '@seaters-app/data-access';
import { useWLDistributionData } from './hooks/useWLDistributeSummaryData';

export function Allocate() {
  const { t } = useTranslation();

  const { allItems, loadMoreData, hasNextPage, isFetching } =
    useFGOWishListsDistribution();
  const { getData } = useWLDistributionData();

  const isCustom = useIsCustomFlow();

  return (
    <div className={styles.invitations}>
      {!isFetching || allItems.length ? (
        <Container>
          <Space style={{ width: '100%' }} direction="vertical">
            <div className={styles.title}>
              <Title level={2}>{t('menus_label_allocate')}</Title>
              {isCustom && <ExportReportButton />}
            </div>
            {allItems.length ? (
              <InfiniteScroll
                dataLength={allItems?.length ?? 0}
                next={() => loadMoreData()}
                hasMore={!!hasNextPage}
                loader={
                  <div style={{ marginTop: 15 }}>
                    {[...Array(2)].map(() => (
                      <WishListSummarySkeleton />
                    ))}
                  </div>
                }
                scrollableTarget="scrollableDiv"
                style={{ overflow: 'visible' }}
              >
                <Space
                  className={styles.waitingListsContainer}
                  direction="vertical"
                  size={24}
                >
                  {allItems?.map((request) => {
                    return (
                      <Link
                        to={request.waitinglist.waitingListId}
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <WishListSummary data={getData(request)} />
                      </Link>
                    );
                  })}
                </Space>
              </InfiniteScroll>
            ) : (
              <EmptyState />
            )}
          </Space>
        </Container>
      ) : (
        <Container>
          <div style={{ marginTop: 15 }}>
            {[...Array(9)].map(() => (
              <WishListSummarySkeleton />
            ))}
          </div>
        </Container>
      )}
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { useCurrentStep } from './hooks/useCurrentStep';
import { DetailStep } from './steps/DetailStep';
import { useEffect } from 'react';
import { Breadcrumb, Flex, Steps } from 'antd';
import styles from './createMailCampaign.module.css';
import { Link } from 'react-router-dom';
import { ContentStep } from './steps/ContentStep';
import { SendStep } from './steps/SendStep';

export type StepType = {
  title: string;
  content: () => JSX.Element;
};

export default function MailCampaignCreate({ edit = false }) {
  const { current: currentStep, resetSteps } = useCurrentStep();
  const { t } = useTranslation();
  const steps: StepType[] = [
    {
      title: t('mailcampaigns_details'),
      content: () => <DetailStep />,
    },
    {
      title: t('mailcampaigns_content'),
      content: () => <ContentStep />,
    },
    {
      title: t('mailcampaigns_preview_send'),
      content: () => <SendStep />,
    },
  ];

  useEffect(() => {
    return () => {
      resetSteps();
    };
  }, []);

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <div className={styles.headerWrap}>
        <Breadcrumb
          items={[
            {
              title: <Link to="..">{t('mailcampaign')}</Link>,
            },
            {
              title: (
                <span>
                  {edit
                    ? t('main_breadcrumbs-edit')
                    : t('main_breadcrumbs-create')}{' '}
                  {t('mailcampaign')}
                </span>
              ),
            },
          ]}
        />
        <h3 className={styles.title}>
          {edit ? t('main_breadcrumbs-edit') : t('main_breadcrumbs-create')}{' '}
          {t('mailcampaign')}
        </h3>
      </div>
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Steps.Step disabled title={step.title} key={step.title} />
        ))}
      </Steps>
      <div className={styles.stepContainer}>{steps[currentStep].content()}</div>
    </Flex>
  );
}

import { ReactElement } from 'react';
import AntLayout from 'antd/es/layout';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { baseColor400 } from '@seaters-app/ui';
import { Flex, Space } from 'antd';
import { Button } from '@seaters-app/ui';
import styles from '../../styles.module.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const { Footer: AntFooter } = AntLayout;

interface FooterProps {
  handlePrevStep?: () => void;
  saveAsDraft?: () => void;
  isLoading?: boolean;
  submitButton?: ReactElement;
  cancelButton?: ReactElement;
}

function Footer({
  handlePrevStep,
  saveAsDraft,
  isLoading,
  submitButton,
  cancelButton,
}: FooterProps) {
  const { t } = useTranslation();
  return (
    <AntFooter
      style={{
        borderTop: `1px solid ${baseColor400}`,
      }}
      className={styles.footer}
    >
      <Flex justify="flex-end" style={{ width: '100%' }}>
        <Space>
          {!!cancelButton ? (
            cancelButton
          ) : (
            <Button
              size="middle"
              type="default"
              icon={<LeftOutlined rev={undefined} />}
              onClick={handlePrevStep}
              disabled={isLoading}
            >
              {t('prev_step_button_text')}
            </Button>
          )}
          {saveAsDraft && (
            <Button
              size="middle"
              type="default"
              onClick={saveAsDraft}
              disabled={isLoading}
            >
              {t('save_and_finish_later_button_text')}
            </Button>
          )}

          {!!submitButton ? (
            submitButton
          ) : (
            <Button
              size="middle"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {t('next_step_button_text')} <RightOutlined />
            </Button>
          )}
        </Space>
      </Flex>
    </AntFooter>
  );
}

export default Footer;

import { Modal, ModalProps, Space, Steps } from 'antd';

import { VenueStep } from './VenueStep';
import { EventFormStep } from './EventFormStep';
import { AttendeesInfoStep } from './AttendeesInfoStep';
import { useEventCreateStepper } from './hooks/useEventCreateStepper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const modalWidth = 1113;

type StepType = {
  title: string;
  content: () => JSX.Element;
};

interface CreateEventModalProps extends ModalProps {
  handleSelectEvent: (eventId: string) => void;
}

export function CreateEventModal({
  open,
  onCancel,
  handleSelectEvent,
}: CreateEventModalProps) {
  const { t } = useTranslation();
  const steps: StepType[] = [
    {
      title: t('event_venue'),
      content: () => <VenueStep />,
    },
    {
      title: t('admin_event'),
      content: () => <EventFormStep />,
    },
    {
      title: t('event_timeline_requestedinfo'),
      content: () => (
        <AttendeesInfoStep handleSelectEvent={handleSelectEvent} />
      ),
    },
  ];
  const {
    current: currentStep,
    resetSteps,
    setEventFormValues,
  } = useEventCreateStepper();

  useEffect(() => {
    return () => {
      resetSteps();
      setEventFormValues(undefined);
    };
  }, []);

  return (
    <Modal
      title={t('cwl_event_create-btn')}
      open={open}
      width={modalWidth}
      footer={null}
      onCancel={onCancel}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Steps current={currentStep}>
          {steps.map((step) => (
            <Steps.Step disabled title={step.title} key={step.title} />
          ))}
        </Steps>
        {steps[currentStep].content()}
      </Space>
    </Modal>
  );
}

import { requestOneTimeUpload, uploadFile } from '@seaters-app/data-access';
import { RcFile } from 'antd/es/upload';

export const uploadImage = async ({
  imageUrl,
  fileName,
}: {
  fileName?: string;
  imageUrl: RcFile;
}) => {
  const requestOneTimeUploadResponse = await requestOneTimeUpload({
    fileName: fileName ? encodeURIComponent(fileName) : 'file.png',
  });
  if (requestOneTimeUploadResponse.fileId && imageUrl) {
    const formFile = new FormData();
    formFile.append('file', imageUrl);
    try {
      await uploadFile(
        formFile,
        requestOneTimeUploadResponse.path.slice(20, 52)
      );
    } catch (error) {
      console.log(error);
    }
  }

  return requestOneTimeUploadResponse;
};

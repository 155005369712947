import { Job, SupportAPIError } from '@seaters-app/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createBulkWishLists,
  createBulkAccessCodes,
  fetchJob,
  updateJob,
  generateFGReportUrl,
  createBulkBadges,
  removeBulkBadges,
  distributeBulkPositions,
  generateFansReportUrl,
  generateOlympicsReportUrl,
  generateSurveyReportUrl,
  updateBulkAccessCodes,
  assignUserRoles,
  updateJobXLSX,
  generatePDFTickets,
  removeAccessCodes,
} from '../api';
import { AxiosError } from 'axios';

export const useJob = (jobId: string) =>
  useQuery<Job, Error>(['job', jobId], () => fetchJob(jobId), {
    enabled: !!jobId,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: (job) =>
      job?.status === 'running' || job?.status === 'pending' ? 3000 : false,
  });

export const useUpdateJob = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Job,
    AxiosError<SupportAPIError>,
    { jobId: string; action: 'moveNext' | 'cancel' | 'retryCurrent' }
  >(({ jobId, action }) => updateJob(jobId, { action }), {
    onSuccess: async ({}, { jobId }) => {
      await queryClient.invalidateQueries(['job', jobId]);
    },
  });
};

export const useUpdateJobXLSX = () => {
  const queryClient = useQueryClient();
  return useMutation<
    Job,
    AxiosError<SupportAPIError>,
    { jobId: string; key: string }
  >(({ jobId, key }) => updateJobXLSX(jobId, { key }), {
    onSuccess: async ({}, { jobId }) => {
      await queryClient.invalidateQueries(['job', jobId]);
    },
  });
};

export const useCreateBulkWishLists = () => {
  // const queryClient = useQueryClient();

  return useMutation(createBulkWishLists);
};

export const useCreateBulkAccessCodes = () => {
  return useMutation(createBulkAccessCodes);
};

export const useCreateBulkBadges = () => {
  return useMutation(createBulkBadges);
};

export const useRemoveBulkBadges = () => {
  return useMutation(removeBulkBadges);
};

export const useDistributeBulkPositions = () => {
  return useMutation(distributeBulkPositions);
};

export const useGenerateFGReportUrl = () => {
  return useMutation(generateFGReportUrl);
};

export const useGeneratePDFTickets = () => {
  return useMutation(generatePDFTickets);
};

export const useGenerateFansReportUrl = () => {
  return useMutation(generateFansReportUrl);
};

export const useGenerateOlympicsReportUrl = () => {
  return useMutation(generateOlympicsReportUrl);
};

export const useGenerateSurveyReportUrl = () => {
  return useMutation(generateSurveyReportUrl);
};

export const useUpdateBulkAccessCodes = () => {
  return useMutation(updateBulkAccessCodes);
};

export const useAssignUserRoles = () => {
  return useMutation(assignUserRoles);
};

export const useRemoveAccessCodes = () => {
  return useMutation(removeAccessCodes);
};

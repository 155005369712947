import {
  AnswersReportBody,
  AnswersReportEntity,
  ApproverEntity,
  endpoints,
  FanGroupEntity,
  FanGroupMember,
  FanGroupOwnerFanGroupEntity,
  FanGroupValidator,
  FGOReportEntity,
  ListEntity,
  ListEntityV2,
  PaginationQuery,
  PaginationQueryV2,
  PositionDetailsEntity,
  ReportType,
  SeatDetailsEntity,
  V2Prefix,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchFanGroupOwnerGroups = async (
  params: PaginationQuery
): Promise<ListEntity<FanGroupOwnerFanGroupEntity>> => {
  const response: ListEntity<FanGroupOwnerFanGroupEntity> = await api.get(
    `${endpoints.fanGroupOwner}/${endpoints.groups}`,
    { params }
  );

  return response;
};

export const updateFGOFanGroup = (
  id: string,
  body: FanGroupValidator
): Promise<FanGroupEntity> => {
  return api.put(`${endpoints.fanGroupOwner}/${endpoints.groups}/${id}`, body);
};

export const fetchFanGroupOwnerGroup = (
  id: string
): Promise<FanGroupEntity> => {
  return api.get(`${endpoints.fanGroupOwner}/${endpoints.groups}/${id}`);
};

// GET URL: https://api.{{domain}}/api/v2/fan-group-owner/waiting-lists/{{wlId}}/aggregate-invitations/approvers
export const fetchFGOWaitingListApprovers = (
  waitingListId: string,
  params: PaginationQueryV2
): Promise<ListEntityV2<ApproverEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.aggregateInvitations}/${endpoints.approvers}`,
    { params }
  );

export const exportFGOReport = (
  fgId: string,
  reportName: ReportType
): Promise<FGOReportEntity> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fgId}/${endpoints.exportFGReport}/${reportName}/xlsx`
  );

// GET https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/listId/positions/details/
export const fetchFGOWaitingListPositionsDetails = (
  waitingListId: string
): Promise<PositionDetailsEntity> =>
  api.get(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${endpoints.details}`
  );

// GET https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/listId/positions/details/
export const fetchFGOWaitingListSeatsDetails = (
  waitingListId: string
): Promise<SeatDetailsEntity> =>
  api.get(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.seats}/${endpoints.details}`
  );

// PUT URL: https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/export-seats
export const generateSeatsList = (
  waitingListId: string
): Promise<FGOReportEntity> =>
  api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.exportSeats}`
  );

// https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/7cadc78c-6b8d-4fcd-8e7b-1a75a5dd70e6/export-survey-answer-report?page=0&size=10

export const generateSurveyAnswersReport = (
  waitingListId: string,
  body: AnswersReportBody
): Promise<AnswersReportEntity> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.exportSurveyAnswerReport}`,
    body
  );

// PUT https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/listId/available-seats/
export const fetchFGOWaitingListAvailableSeats = (
  waitingListId: string
): Promise<{ numberOfAvailableSeats: number }> =>
  api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.availableSeats}`
  );

// PUT https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/listId/available-secondary-tickets/
export const fetchFGOWaitingListAvailableSecondarySeats = (
  waitingListId: string
): Promise<{ numberOfAvailableSeats: number }> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.availableSecondarySeats}`
  );

// PUT https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/id/expired-positions
export const fetchFGOWaitingListExpiredPositions = (
  waitingListId: string
): Promise<ListEntityV2<FanGroupMember>> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.expiredPositions}`
  );

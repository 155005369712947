import {
  FanGroupAccessFormType,
  FanGroupAccessValidator,
  FormTranslationType,
  TranslationEntity,
} from '@seaters-app/constants';

const convertTranslation = (
  translation: FormTranslationType
): TranslationEntity[] => {
  return Object.keys(translation).map((locale) => ({
    lang: locale,
    text: translation[locale],
  }));
};

export const prepareValues = (
  values: FanGroupAccessFormType
): FanGroupAccessValidator => {
  const protectionCodeExplanation = convertTranslation(
    values.protectionCodeExplanation
  );

  return { ...values, protectionCodeExplanation };
};

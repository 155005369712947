import { queryClient } from '@seaters-app/data-access';
import { baseColor400, successColor } from '@seaters-app/ui';
import { Space, Spin, Tooltip, DescriptionsProps, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { FanEntity, fansKeys } from '@seaters-app/constants';
import { BellOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';

export function FanPersonalInfo({ fanId }: { fanId: string }) {
  const { t } = useTranslation();
  const fanPersonalInfo = queryClient.getQueryData<FanEntity>(
    fansKeys.detail(fanId)
  );

  const personalInfoItems: DescriptionsProps['items'] = [
    {
      key: 'firstName',
      label: t('admin_user_firstName'),
      children: fanPersonalInfo?.firstName,
    },
    {
      key: 'lastName',
      label: t('admin_user_lastName'),
      children: fanPersonalInfo?.lastName,
    },
    {
      key: 'dateofbirth',
      label: t('event_reqinfo_dateofbirth'),
      children: fanPersonalInfo?.personalInfo.birthDate,
    },
    {
      key: 'email',
      label: t('admin_user_email'),
      children: (
        <Space align="center">
          {fanPersonalInfo?.email}

          <Tooltip
            title={`${t('fans_list_tbl-email')} ${
              fanPersonalInfo?.validatedEmail
                ? t('verified_label')
                : t('not_verified_label')
            }`}
          >
            <MailOutlined
              style={{
                color: fanPersonalInfo?.validatedEmail
                  ? successColor
                  : baseColor400,
              }}
              rev={undefined}
            />
          </Tooltip>
          <Tooltip
            title={
              fanPersonalInfo?.notificationChannels.byMail
                ? t('notifications_by_email_enabled')
                : t('notifications_by_email_disabled')
            }
          >
            <BellOutlined
              rev={undefined}
              style={{
                color: successColor,
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      key: 'phonenumber',
      label: t('event_reqinfo_phonenumber'),
      children: (
        <Space>
          <span>
            {fanPersonalInfo?.mobilePhoneNumber?.countryCallingCode}{' '}
            {fanPersonalInfo?.mobilePhoneNumber?.localNumber}
          </span>
          <Tooltip
            title={`${t('settings_phone_form_phone-label')} ${
              fanPersonalInfo?.validatedMobilePhone
                ? t('verified_label')
                : t('not_verified_label')
            }`}
          >
            <PhoneOutlined
              style={{
                color: fanPersonalInfo?.validatedMobilePhone
                  ? successColor
                  : baseColor400,
              }}
              rev={undefined}
            />
          </Tooltip>
          <Tooltip
            title={
              fanPersonalInfo?.notificationChannels.bySms
                ? t('notifications_by_sms_enabled')
                : t('notifications_by_sms_disabled')
            }
          >
            <BellOutlined
              rev={undefined}
              style={{
                color: successColor,
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      key: 'address_line1',
      label: t('settings_personal-information_address-label-line1'),
      children: fanPersonalInfo?.personalInfo.address.line1,
    },
    {
      key: 'address_line2',
      label: t('settings_personal-information_address-label-line2'),
      children: fanPersonalInfo?.personalInfo.address.line2,
    },
    {
      key: 'address_line3',
      label: t('settings_personal-information_address-label-line3'),
      children: fanPersonalInfo?.personalInfo.address.line3,
    },
  ];

  return (
    <div style={{ height: '100vh' }}>
      {fanPersonalInfo ? (
        <Descriptions items={personalInfoItems} column={1} />
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Spin />
        </div>
      )}
    </div>
  );
}

import { useParams } from 'react-router-dom';
import { useFGOWishListSeatsDetails } from '@seaters-app/data-access';

import { Space } from 'antd';
import { NumberItem } from './NumberItem';
import { useTranslation } from 'react-i18next';

export function SeatsDetails() {
  const { wishListId = '' } = useParams();
  const { t } = useTranslation();

  const { data: seatsDetails } = useFGOWishListSeatsDetails(wishListId);

  if (!seatsDetails) return null;

  return (
    <Space size="middle">
      <NumberItem
        maxWidth={'min-content'}
        tooltip="seats_details_fans_accepted-tooltip"
        label={t('seats_details_fans_accepted')} // Fans accepted
        value={seatsDetails.totalFansAccepted}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="seats_details_fans_assigned-tooltip"
        label={t('seats_details_fans_assigned')} // Fans assigned
        value={seatsDetails.totalFansAssigned}
      />
      <NumberItem
        tooltip="seats_details_fans_assigned_without_seats-tooltip"
        label={t('seats_details_fans_assigned_without_seats')} // Fans assigned w/o seats
        value={seatsDetails.totalFansAssignedWithoutSeats}
        maxWidth={90}
      />
      <NumberItem
        tooltip="seats_details_fans_RSVP_confirmed-tooltip"
        label={t('seats_details_fans_RSVP_confirmed')} // Fans RSVP confirmed
        value={seatsDetails.totalFansRsvpConfirmed}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="seats_details_seats_accepted-tooltip"
        label={t('seats_details_seats_accepted')} // Seats accepted
        value={seatsDetails.totalSeatsAccepted}
      />
      <NumberItem
        maxWidth={'min-content'}
        tooltip="seats_details_seats_assigned-tooltip"
        label={t('seats_details_seats_assigned')} // Seats assigned
        value={seatsDetails.totalSeatsAssigned}
      />
      <NumberItem
        tooltip="seats_details_seats_assigned_without_seats-tooltip"
        label={t('seats_details_seats_assigned_without_seats')} // Seats assigned w/o seats
        value={seatsDetails.totalSeatsAssignedWithoutTickets}
        maxWidth={90}
      />
      <NumberItem
        tooltip="seats_details_seats_RSVP_confirmed-tooltip"
        label={t('seats_details_seats_RSVP_confirmed')} // Seats RSVP confirmed
        value={seatsDetails.totalSeatsRsvpConfirmed}
      />
    </Space>
  );
}

import {
  useFetchMailcampaign,
  useSendMailcampaign,
} from '@seaters-app/data-access';
import { notification, Button, Spin } from 'antd';
import MailCampaignDetail from '../../detail/components/MailcampaignDetail';
import { useState } from 'react';
import Footer from '../components/Footer';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { MailcampaignRequiredFormSchema } from '@seaters-app/constants';
import { MailOutlined } from '@ant-design/icons';

export function SendStep() {
  const { prev, setCurrent } = useCurrentStep();
  const { t } = useTranslation();
  const { mailcampaignId = '' } = useParams();
  const navigate = useNavigate();

  // const colorPrimary = theme.useToken().token.colorPrimary;
  // const sendOptions = [
  //   { label: t('send_now'), value: 'send_now' },
  //   { label: t('schedule'), value: 'schedule' },
  // ];
  // const [sendMode, setSendMode] = useState('send_now');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: mailcampaignValues } = useFetchMailcampaign(mailcampaignId);
  const { mutate: sendMailCampaign } = useSendMailcampaign();

  const submit = (onSuccess: () => void) => {
    setIsSubmitting(true);
    const callbacks = {
      onSuccess: () => {
        onSuccess();
        setIsSubmitting(false);
      },
      onError: (error: any) => {
        notification.error({
          message: t('notification_error_wl_updated'),
          description: error.response?.data?.message || error.message,
        });
        setIsSubmitting(false);
      },
    };
    sendMailCampaign({ id: mailcampaignId }, callbacks);
  };

  const parsedMailcampaignValues =
    MailcampaignRequiredFormSchema.safeParse(mailcampaignValues);
  const handleSaveAsDraft = () => navigate('..');
  const handleNextStep = () => submit(() => navigate('../sent'));
  return (
    <>
      <MailCampaignDetail
        edit={true}
        editContent={() => setCurrent(1)}
        editDetail={() => setCurrent(0)}
      />
      <Footer
        handlePrevStep={prev}
        saveAsDraft={handleSaveAsDraft}
        isLoading={isSubmitting}
        submitButton={
          <Button
            size="middle"
            type="primary"
            loading={isSubmitting}
            onClick={handleNextStep}
            disabled={!parsedMailcampaignValues.success || isSubmitting}
          >
            {t('mailcampaign_send_email')} <MailOutlined />
          </Button>
        }
      ></Footer>
      <Spin
        fullscreen
        spinning={isSubmitting}
        size="large"
        tip={t('mailcampaigns_submitting')}
      />
    </>
  );
}

import {
  getSlugFromUrl,
  useFetchLook,
  useFetchMailcampaign,
  useUpdateMailcampaign,
} from '@seaters-app/data-access';
import { Editor } from '@seaters-app/ui';
import {
  Col,
  Form,
  Row,
  Space,
  Spin,
  Typography,
  notification,
  theme,
} from 'antd';
const { Text } = Typography;
import { useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import Footer from '../components/Footer';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultTemplate } from './defaultMailTemplate';
import { editorOptions } from './editorOptions';

export function ContentStep() {
  const { next, prev, current } = useCurrentStep();
  const { t } = useTranslation();
  const { mailcampaignId = '' } = useParams();
  const navigate = useNavigate();
  const colorPrimary = theme.useToken().token.colorPrimary;
  const slug = getSlugFromUrl();
  const { data: lookData } = useFetchLook(slug ?? '');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: mailcampaignValues } = useFetchMailcampaign(mailcampaignId);
  const methods = useForm({
    values: {
      body:
        mailcampaignValues?.body ||
        defaultTemplate(
          colorPrimary,
          lookData?.coverImageUrl ||
            'https://seaters-public-images-6120fd4.s3.eu-west-1.amazonaws.com/public/704269ed-e9c2-4f37-80f7-1bdfaae3c32a'
        ),
    },
  });
  const { handleSubmit, control, getValues } = methods;

  const { mutate: updateMailCampaign } = useUpdateMailcampaign();
  const submit = (data, onSuccess: () => void) => {
    setIsSubmitting(true);
    const callbacks = {
      onSuccess: () => {
        onSuccess();
        setIsSubmitting(false);
      },
      onError: (error: any) => {
        notification.error({
          message: t('notification_error_wl_updated'),
          description: error.response?.data?.message || error.message,
        });
        setIsSubmitting(false);
      },
    };
    updateMailCampaign({ id: mailcampaignId, body: data }, callbacks);
  };
  const onNextStep = (data) => {
    submit(data, next);
  };

  return (
    <>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            onFinish={handleSubmit(onNextStep)}
            autoComplete="off"
            disabled={isSubmitting}
          >
            <Row align="middle">
              <Col xs={24} md={24} xl={12}>
                <Form.Item
                  label={<Text strong>{t('mailcampaigns_email_body')}</Text>}
                  name="body"
                >
                  <Controller
                    control={control}
                    name="body"
                    render={({ field: { onChange, ...field } }) => {
                      return (
                        <Editor
                          {...field}
                          onEditorChange={onChange}
                          overrideInitOptions={editorOptions(colorPrimary)}
                        />
                      );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Footer
              handlePrevStep={prev}
              saveAsDraft={() => {
                submit(getValues(), () => navigate('..'));
              }}
              isLoading={isSubmitting}
            ></Footer>
          </Form>
        </FormProvider>
      </Space>
      <Spin
        fullscreen
        spinning={isSubmitting}
        size="large"
        tip={t('mailcampaigns_submitting')}
      />
    </>
  );
}

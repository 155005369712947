import { EventValidator } from '@seaters-app/constants';
import dayjs from 'dayjs';
import { EventCreateFormType } from '../../Create/Create';
import { convertTranslation } from '../../../../utils/helpers/convertTranslations';

export const prepareValues = (values: EventCreateFormType): EventValidator => {
  const shortName = convertTranslation(values.shortName);
  const name = convertTranslation(values.name);
  const description = convertTranslation(values.description);

  const startDate = dayjs(values.startDate).format();
  const endDate = values.endDate ? dayjs(values.endDate).format() : null;

  return { ...values, shortName, name, description, startDate, endDate };
};

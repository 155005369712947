import { BillingVariables } from '@seaters-app/constants';
import { computeDistributionFee } from './computeDistributionFee';
import { computeVat } from './computeVat';

export const getTotalSeatPrice = (
  face = 0,
  billingVariables: BillingVariables,
  pricePadding: number
) => {
  const distributionFee = computeDistributionFee(face, billingVariables);

  const vatFee = computeVat(distributionFee, billingVariables);

  return face + distributionFee + vatFee + pricePadding;
};

import {
  downloadFileByURL,
  useExportWaitingListInvitationSeat,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';

import { TagsOutlined } from '@ant-design/icons';

import { Button, notification, theme } from 'antd';
import { ActionButtonType } from './CancelButton';
import { retryExport } from '../helpers';

export function TicketsButton({ waitingListId, invitation }: ActionButtonType) {
  const { t } = useTranslation();

  const { token } = theme.useToken();

  const { mutateAsync: exportSeat } =
    useExportWaitingListInvitationSeat(waitingListId);

  const handleTicketDownload = async () => {
    if (invitation.position.exportedVoucherUrl) {
      downloadFileByURL(invitation.position.exportedVoucherUrl, 'Tickets');
    } else {
      exportSeat(
        { invitationId: invitation.id },
        {
          onSuccess: async () => {
            retryExport(invitation.id, 'exportedVoucherUrl', 'Tickets');
          },
          onError: () => {
            notification.error({
              message: t('tickets_generate_notification_error'),
            });
          },
        }
      );
    }
  };

  return invitation.position.seatStatus === 'RSVP_ACCEPTED' ? (
    <Button type="link" icon={<TagsOutlined />} disabled>
      {t('guestlist_no_tickets_yet-btn')}
    </Button>
  ) : (
    <Button
      type="link"
      icon={<TagsOutlined />}
      color={token.colorWarning}
      onClick={handleTicketDownload}
    >
      {t('guestlist_download_ticket-btn')}
    </Button>
  );
}

import {
  Button,
  Row,
  Col,
  Input,
  notification,
  Segmented,
  Select,
  List,
  Typography,
  Checkbox,
} from 'antd';

import { CopyOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';

import Form from 'antd/es/form';
import { Footer, ImageCropper, StyledText } from '@seaters-app/ui-shared';
import { useCallback, useState } from 'react';
import {
  useFetchLanguages,
  useFetchVenue,
  useFetchVenueConfig,
} from '@seaters-app/data-access';
import {
  EventFormValidatorSchema,
  eventsKeys,
  EventStatus,
  Language,
  VenueConfigEntity,
  VenueEntity,
} from '@seaters-app/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { SelectVenue } from './SelectVenue';
import { formatLocation } from '../../../utils';
import { EventCreateFormType } from '../Create/Create';
import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DatePicker, Editor } from '@seaters-app/ui';
import dayjs from 'dayjs';
import { attendeesRequiredFieldList } from '../../wish-lists/create/steps/createEvent/AttendeesInfoStep';
import { AttendeeRequiredFieldsType } from 'libs/constants/types';
import { getErrorMessages } from 'apps/seaters/src/utils/helpers/getFormFieldsErrorMessages/getErrorMessages';
const { Text } = Typography;

export type VenueFieldType = {
  venue: VenueEntity;
  config: VenueConfigEntity;
};

const selectOptions = Object.keys(EventStatus).map((statusItem) => ({
  label: statusItem.toLowerCase(),
  value: statusItem,
}));

interface IEventForm {
  initialValues: EventCreateFormType;
  saveChanges: (values: EventCreateFormType) => void;
  isDetail?: boolean;
  imageUrl?: string;
}

export function EventForm({
  initialValues,
  saveChanges,
  isDetail = false,
}: IEventForm) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editorShown, setEditorShown] = useState(false);

  const methods = useForm<EventCreateFormType>({
    mode: 'onBlur',
    resolver: zodResolver(EventFormValidatorSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = methods;

  ////////////////////////////

  const imageUrl = useWatch({
    control,
    name: 'imageUrl',
  });

  ////////////////////////////

  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);
  const [isVenuesModalOpen, setVenuesModalOpen] = useState(false);

  const fieldErrors = getErrorMessages<EventCreateFormType>(
    errors,
    [
      'name',
      'shortName',
      'description',
      'slug',
      'startDate',
      'endDate',
      'venueConfigId',
    ],
    currentLanguage
  );

  const venueConfigId = useWatch({
    control,
    name: 'venueConfigId',
  });

  const { data: venueConfig } = useFetchVenueConfig(venueConfigId || '');
  const { data: venue } = useFetchVenue(venueConfig?.venueId || '');

  const startDate = useWatch({
    control,
    name: 'startDate',
  });

  // Languages
  const { data } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const languages = data
    ? data?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  const { eventId = '' } = useParams();

  const copyID = useCallback(() => {
    if (eventId) {
      navigator.clipboard.writeText(eventId).then(
        () => {
          notification.success({
            message: t('notification_success_id_copied', { userId: eventId }),
          });
        },
        () => {
          notification.error({
            message: t('notification_error_id_copied', { userId: eventId }),
          });
        }
      );
    }
  }, []);

  const isSubmitting = !!useIsMutating(eventsKeys.mutation());

  const watchCheckboxes = watch('attendeeRequiredFields', []);

  const handleCheckboxChange = (
    checkedValues: AttendeeRequiredFieldsType[]
  ) => {
    // If checkbox 'firstName' and 'lastName' unchecked, also uncheck checkbox 'title'
    if (
      !checkedValues.includes('lastName') &&
      !checkedValues.includes('firstName')
    ) {
      setValue(
        'attendeeRequiredFields',
        checkedValues.filter((value) => value !== 'title')
      );
    } else {
      setValue('attendeeRequiredFields', checkedValues);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form
        layout="vertical"
        onFinish={handleSubmit(saveChanges)}
        autoComplete="off"
        disabled={isSubmitting}
      >
        {/* start */}
        <Form.Item label={t('admin_event_image')} name="imageUrl">
          <ImageCropper
            imageOrCanvas={imageUrl}
            handleRemoveImage={() => setValue('imageUrl', '')}
            handleImageChange={(img) => setValue('imageUrl', img)}
            handleReset={() => {
              setValue('imageUrl', initialValues.imageUrl);
            }}
          />
        </Form.Item>
        {/* end */}
        {isDetail && (
          <Row gutter={24}>
            <Col xs={24} md={16} lg={12} xl={8} xxl={6}>
              <Form.Item label={<Text strong>{t('admin_id')}</Text>}>
                <Input disabled value={eventId} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8} xl={8} xxl={4}>
              <Form.Item label="hidden">
                <Button
                  size="large"
                  type="text"
                  onClick={copyID}
                  icon={<CopyOutlined rev={undefined} />}
                  disabled={!eventId || isSubmitting}
                >
                  {t('copy_button_text')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col xs={24} md={16} xl={12}>
            <Form.Item
              label={<Text strong>{t('admin_fan-groups_name')}</Text>}
              required
              name={`name.${currentLanguage}`}
              validateStatus={fieldErrors?.name && 'error'}
              help={fieldErrors?.name}
            >
              <Controller
                control={control}
                name={`name.${currentLanguage}`}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8} xl={6}>
            <Form.Item
              label={<Text strong>{t('admin_fan-groups_short-name')}</Text>}
              required
              name={`shortName.${currentLanguage}`}
              validateStatus={fieldErrors?.shortName && 'error'}
              help={fieldErrors?.shortName}
            >
              <Controller
                control={control}
                name={`shortName.${currentLanguage}`}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          {/* Default name is not provided as body value from API */}
          {/* <Col xs={24} md={8} xl={6}>
              <Form.Item label={'Default name'} name={'defaultName'}>
                <Controller
                  control={control}
                  name={'defaultName'}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>
            </Col> */}
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={16}>
            <Form.Item
              label={<Text strong>{t('admin_fan-groups_description')}</Text>}
              required
              name={`description.${currentLanguage}`}
              validateStatus={fieldErrors?.description && 'error'}
              help={fieldErrors?.description}
            >
              <Controller
                control={control}
                name={`description.${currentLanguage}`}
                render={({ field: { onChange, ...field } }) => {
                  return !editorShown ? (
                    <StyledText
                      isError={!!errors.description}
                      text={field.value ?? ''}
                      onClick={() => setEditorShown(true)}
                    />
                  ) : (
                    <Editor {...field} onEditorChange={onChange} />
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8} xl={6}>
            <Form.Item
              label={<Text strong>{t('admin_fan-groups_slug_url')}</Text>}
              name={'slug'}
              required
              validateStatus={fieldErrors?.slug && 'error'}
              help={fieldErrors?.slug}
            >
              <Controller
                control={control}
                name={'slug'}
                render={({ field }) => (
                  <Input addonBefore="seaters.com/" {...field} />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8} xl={6}>
            <Form.Item
              label={<Text strong>{t('admin_fan-groups_status')}</Text>}
              name={'status'}
            >
              <Controller
                control={control}
                name={'status'}
                render={({ field }) => (
                  <Select options={selectOptions} {...field} />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8} xl={6}>
            <Form.Item
              label={<Text strong>{t('event_settings_start')}</Text>}
              name="startDate"
              required
              validateStatus={fieldErrors?.startDate && 'error'}
              help={fieldErrors?.startDate}
            >
              <Controller
                control={control}
                name={'startDate'}
                render={({ field }) => (
                  <DatePicker
                    format="DD/MM/YYYY HH:mm"
                    style={{ width: '100%' }}
                    showTime={{
                      defaultValue: dayjs('00:00', 'HH:mm'),
                      format: 'HH:mm',
                    }}
                    {...field}
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} xl={6}>
            <Form.Item
              label={<Text strong>{t('event_settings_end')}</Text>}
              name="endDate"
              validateStatus={fieldErrors?.endDate && 'error'}
              help={fieldErrors?.endDate}
            >
              <Controller
                control={control}
                name={'endDate'}
                render={({ field }) => (
                  <DatePicker
                    format="DD/MM/YYYY HH:mm"
                    style={{ width: '100%' }}
                    showTime={{
                      defaultValue: dayjs('00:00', 'HH:mm'),
                      format: 'HH:mm',
                    }}
                    minDate={dayjs(startDate, 'DD/MM/YYYY HH:mm')}
                    {...field}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={16} xl={12}>
            <Form.Item
              label={<Text strong>{t('event_venue')}</Text>}
              name="venueConfigId"
              required
              validateStatus={fieldErrors?.venueConfigId && 'error'}
              help={fieldErrors?.venueConfigId}
            >
              {/* <Space> */}
              {venue ? (
                <List
                  bordered
                  dataSource={[venue]}
                  renderItem={(item) => {
                    return (
                      <List.Item
                        key={item?.id}
                        style={{ alignItems: 'flex-start' }}
                        actions={[
                          <Button
                            size="small"
                            onClick={() => {
                              setVenuesModalOpen(true);
                            }}
                            disabled={isSubmitting}
                          >
                            <FormOutlined rev={undefined} />
                            {t('change_event_button_text')}
                          </Button>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={
                            <img
                              width={160}
                              height={80}
                              alt="logo"
                              src={item?.imageUrl}
                              style={{ borderRadius: 4 }}
                            />
                          }
                          title={item?.name[0]?.text}
                          style={{ alignItems: 'center' }}
                          description={formatLocation([
                            item?.city[0]?.text,
                            item?.country[0]?.text,
                          ])}
                        />
                      </List.Item>
                    );
                  }}
                />
              ) : (
                <Button
                  icon={<PlusOutlined rev={undefined} />}
                  size="middle"
                  onClick={() => setVenuesModalOpen(true)}
                >
                  {t('select_venue_button_text')}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        {!isDetail && (
          <Form.Item
            label={<Text strong>{t('event_show-reqinfo-btn')}</Text>}
            name="attendeeRequiredFields"
          >
            <Controller
              control={control}
              name={'attendeeRequiredFields'}
              render={({ field }) => (
                <Checkbox.Group
                  style={{ width: '100%' }}
                  {...field}
                  onChange={handleCheckboxChange}
                >
                  <Row gutter={[16, 24]}>
                    {attendeesRequiredFieldList.map((el) => {
                      return (
                        <Col span={12}>
                          <Checkbox
                            disabled={
                              el.value === 'title' &&
                              !(
                                getValues(`attendeeRequiredFields`)?.includes(
                                  'firstName'
                                ) ||
                                getValues(`attendeeRequiredFields`)?.includes(
                                  'lastName'
                                )
                              )
                            }
                            value={el.value}
                          >
                            {t(el.label)}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              )}
            />
          </Form.Item>
        )}
        <Footer
          onCancel={
            isDetail ? () => reset(initialValues) : () => navigate('..')
          }
          isDisabled={
            !isDirty &&
            isDetail &&
            imageUrl === initialValues.imageUrl &&
            getValues()?.venueConfigId === initialValues.venueConfigId
          }
        >
          <Segmented
            size="large"
            options={languages}
            value={currentLanguage}
            onChange={setCurrentLanguage}
          />
        </Footer>
        {isVenuesModalOpen && (
          <SelectVenue
            isVenuesModalOpen={isVenuesModalOpen}
            setVenuesModalOpen={setVenuesModalOpen}
          />
        )}
      </Form>
    </FormProvider>
  );
}

import { fanGroupsKeys } from '@seaters-app/constants';
import {
  fetchFGOGroupMembers,
  useFanGroupStore,
  useFetchFGOFanGroupMembers,
} from '@seaters-app/data-access';

import { useQueryClient } from '@tanstack/react-query';
import { TablePaginationConfig } from 'antd/es/table';
import { useEffect } from 'react';
import { usePagination } from '../../../../utils/hooks';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

export const useFans = () => {
  const { fanGroup } = useFanGroupStore();

  const [query, setQuery] = useQueryParams({
    size: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 0),
    search: withDefault(StringParam, ''),
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      size: pageSize,
      page: current ? current - 1 : 0,
      search: query.search,
    });
  };

  const queryClient = useQueryClient();

  const { data, isLoading } = useFetchFGOFanGroupMembers(
    fanGroup?.id ?? '',
    {
      page: query.page,
      size: query.size,
    },
    { query: query.search ?? '' }
  );

  useEffect(() => {
    queryClient.prefetchQuery(
      [fanGroupsKeys.membersList(fanGroup?.id ?? '', query, query.search)],
      () =>
        fetchFGOGroupMembers(
          fanGroup?.id ?? '',
          {
            page: query.page,
            size: query.size,
          },
          { query: query.search ?? '' }
        )
    );
  }, [queryClient, query]);

  const pagination = usePagination({
    pageSize: query.size,
    current: query.page + 1,
    totalSize: data?.totalElements,
  });

  return {
    handleTableChange,
    pagination,
    queryParams: query,
    setPaginationParams: setQuery,
    fans: data?.content,
    totalFansCount: data?.totalElements,
    isLoading,
  };
};

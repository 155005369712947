import {
  BillingMode,
  CURRENT_LANGUAGE,
  FormTranslationType,
  Language,
  TranslationEntity,
  WaitingListEntity,
  WishListWithInvitations,
} from '@seaters-app/constants';
import { getSingleTranslation } from '@seaters-app/data-access';
import { Grid } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;

export const useMyInvitesSummaryData = () => {
  const { xs } = useBreakpoint();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const getData = ({
    waitinglist,
    availableSeats,
    distributedSeats,
    numberOfTickets,
  }: WishListWithInvitations) => {
    const { symbol: currencySymbol } = waitinglist.currency;

    const isPaid = waitinglist.billingMode === BillingMode.FAIR_PRICE;

    return {
      isHost: true,
      generalInfo: {
        imageUrl:
          waitinglist.eventImageUrl ||
          waitinglist.waitingListImageUrl ||
          waitinglist.venueImageUrl,

        title: getSingleTranslation(
          waitinglist.eventName as TranslationEntity[],
          lang
        ),
        startDate: dayjs(waitinglist.eventStartDate).format('ddd D MMMM'),
        status: (waitinglist as WaitingListEntity)?.waitingListStatus,
        venue: (waitinglist?.venueName as FormTranslationType)[Language.EN]
          ? (waitinglist?.venueName as FormTranslationType)[CURRENT_LANGUAGE]
          : getSingleTranslation(
              waitinglist?.venueName as TranslationEntity[],
              lang
            ),
        category:
          waitinglist.waitingListCategory ||
          (waitinglist as WaitingListEntity)?.seatCategory,
      },
      tableInfo: xs
        ? [
            // Main numbers:
            [
              {
                label: `${t('request_card_available_seats')} `,
                value: availableSeats,
                isCustom: true,
              },
              {
                label: `${t('request_card_distributed_seats')} `,
                value: distributedSeats,
                isCustom: true,
              },
              {
                label: `${t('waitinglist_estimated_tickets')} `,
                value: numberOfTickets,
              },
            ],
          ]
        : [
            // Price:
            [
              {
                label: `${t('invite_wl_price')} `,
                value: `${currencySymbol}${Number(waitinglist.price)
                  .toFixed(2)
                  .toString()}`,
                isHidden: !isPaid,
              },
              {
                label: `${t('invite_wl_value')} `,
                value: `${
                  waitinglist.currency.symbol
                }${waitinglist.originalPrice.toString()}`,
              },
              {
                label: `${t('wl_fee-lbl')} `,
                value: `${
                  waitinglist.currency.symbol
                }${waitinglist.billingVariables.maxDistributionFee.toString()}`,
                isHidden: !isPaid,
              },
            ],
            // Max amounts:
            [
              {
                label: `${t('waitinglist_max_tickets_per_host')}: `,
                value: (waitinglist as WaitingListEntity)
                  ?.maxNumberOfSeatsPerHost,
              },
              {
                label: `${t('waitinglist_max_tickets_per_guest')}: `,
                value: (waitinglist as WaitingListEntity)
                  ?.maxNumberOfSeatsPerPosition,
              },
            ],
            // Main numbers:
            [
              {
                label: `${t('request_card_available_seats')} `,
                value: availableSeats,
                isCustom: true,
              },
              {
                label: `${t('request_card_distributed_seats')} `,
                value: distributedSeats,
                isCustom: true,
              },
              {
                label: `${t('waitinglist_estimated_tickets')} `,
                value: numberOfTickets,
              },
            ],
            [
              {
                label: isPaid ? `${t('budget_cost')} ` : null,
                value: isPaid
                  ? `${currencySymbol}${Number(waitinglist.price)
                      .toFixed(2)
                      .toString()}`
                  : t('waitinglist_free_ticket'),
              },
            ],
          ],
    };
  };
  return { getData };
};

import { useFanGroupStore, useFetchFGOBadges } from '@seaters-app/data-access';

import { TablePaginationConfig } from 'antd/es/table';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { usePagination } from '../../../../utils/hooks';

export const useFGBadges = (fanGroupId?: string) => {
  const [query, setQuery] = useQueryParams({
    size: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
  });

  const { fanGroup } = useFanGroupStore();

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      size: pageSize,
      page: current,
    });
  };

  const { data, isLoading } = useFetchFGOBadges(
    fanGroupId ?? fanGroup?.id ?? '',
    query
  );
  const pagination = usePagination({
    pageSize: query.size,
    current: query.page,
    totalSize: data?.totalElements,
  });

  return {
    handleTableChange,
    pagination,
    badges: data?.content,
    isLoading,
  };
};

import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Select,
  Space,
  notification,
} from 'antd';
import {
  getFanInitialValues,
  useFetchCountries,
  useUpdateFanProfile,
  useUserProfile,
} from '@seaters-app/data-access';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { PurchaseSummary } from '../PurchaseSummary';
import { StepContentProps } from './types';
import {
  FanProfileFormEntity,
  FanValidatorSchema,
} from '@seaters-app/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { PhoneInputWithCountryCode } from '@seaters-app/ui';
import { useEffect, useState } from 'react';
const { useBreakpoint } = Grid;

export function PersonalInfoForm({ ...props }: StepContentProps) {
  // const { token } = theme.useToken();
  const { t } = useTranslation();
  const { data: fanProfile } = useUserProfile(true);
  const { xs } = useBreakpoint();

  const { mutate: updateFan } = useUpdateFanProfile();

  const initialValues = getFanInitialValues(fanProfile);
  const [phoneNumber, setPhoneNumber] = useState(
    initialValues
      ? initialValues?.countryCallingCode + initialValues?.localNumber
      : ''
  );

  const [countryCode, setCountryCode] = useState(
    initialValues ? initialValues?.countryCallingCode : ''
  );

  const { data: countries } = useFetchCountries({
    itemOffset: 0,
    maxPageSize: 240,
  });

  const countriesOptions = countries?.items.map((country) => ({
    label: country.name,
    value: country.alpha2Code,
  }));

  const methods = useForm<any>({
    mode: 'onBlur',
    resolver: zodResolver(FanValidatorSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const { handleSubmit, control, setValue } = methods;

  const onSubmit = async (values: FanProfileFormEntity) => {
    const parsed = FanValidatorSchema.safeParse(values);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }
    const {
      email,
      firstName,
      lastName,
      language,
      mobilePhoneNumber,
      personalInfo,
    } = parsed.data;

    const dataToSend = {
      ...initialValues,
      email,
      firstName,
      lastName,
      language,
      mobilePhoneNumber,
      personalInfo: {
        ...initialValues.personalInfo,
        address: personalInfo?.address,
      },
    };
    if (parsed.success) {
      updateFan(dataToSend, {
        onSuccess: () => {
          props.next();
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_fan_profile_updated'),
          });
        },
      });
    }
  };

  useEffect(() => {
    if (phoneNumber) {
      setValue(
        'mobilePhoneNumber.localNumber',
        phoneNumber?.slice(countryCode?.length)
      );
    }
    if (countryCode) {
      setValue('mobilePhoneNumber.countryCallingCode', countryCode);
    }
  }, [phoneNumber, countryCode]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: xs ? 'center' : 'flex-start',
        flexDirection: xs ? 'column' : 'row',
      }}
    >
      <FormProvider {...methods}>
        <Form
          style={{ maxWidth: 630, width: '100%' }}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          // disabled={formDisabled}
        >
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                name={`firstName`}
                label={t('settings_personal-section_form_firstname-label')}
                required
              >
                <Controller
                  control={control}
                  name={`firstName`}
                  render={({ field }) => <Input {...field} size="middle" />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={`lastName`}
                label={t('settings_personal-section_form_lastname-label')}
                required
              >
                <Controller
                  control={control}
                  name={`lastName`}
                  render={({ field }) => <Input {...field} size="middle" />}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} md={18}>
              <Space.Compact block size="large">
                <Form.Item
                  label={t('settings_phone_form_phone-label')}
                  name="mobilePhoneNumber.localNumber"
                >
                  <PhoneInputWithCountryCode
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                  />
                </Form.Item>
              </Space.Compact>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24}>
              <Form.Item
                name={`personalInfo.address.line1`}
                label={t('settings_personal-information_address-label-line1')}
                required
              >
                <Controller
                  control={control}
                  name={`personalInfo.address.line1`}
                  render={({ field }) => <Input {...field} size="middle" />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <Form.Item
                name={`personalInfo.address.line2`}
                label={`${t(
                  'settings_personal-information_address-label-line2'
                )} (${t('general_label_optional')})`}
              >
                <Controller
                  control={control}
                  name={`personalInfo.address.line2`}
                  render={({ field }) => <Input {...field} size="middle" />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                name={`personalInfo.address.city`}
                label={t('addressCity')}
                required
              >
                <Controller
                  control={control}
                  name={`personalInfo.address.city`}
                  render={({ field }) => <Input {...field} size="middle" />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name={`personalInfo.address.zipCode`}
                label={t('settings_personal-information_postal-code-label')}
                required
              >
                <Controller
                  control={control}
                  name={`personalInfo.address.zipCode`}
                  render={({ field }) => <Input {...field} size="middle" />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                name={`personalInfo.address.state`}
                label={t('checkout_att_field_state')}
                // required
              >
                <Controller
                  control={control}
                  name={`personalInfo.address.state`}
                  render={({ field }) => <Input {...field} size="middle" />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={`personalInfo.address.countryCode`}
                label={t('settings_personal-information_country-label')}
                required
              >
                <Controller
                  control={control}
                  name={`personalInfo.address.countryCode`}
                  render={({ field }) => (
                    <Select
                      {...field}
                      size="middle"
                      showSearch
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase()) ?? false
                      }
                      options={countriesOptions}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Button onClick={props.prev}>{t('general_cancel-btn')}</Button>
            <Button htmlType="submit" type="primary">
              {t('button_text_next')}
            </Button>
          </Space>
        </Form>
      </FormProvider>
      <PurchaseSummary />
    </div>
  );
}

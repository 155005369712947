import { Card, Skeleton } from 'antd';
import styles from './tickets.module.css';

function TicketsComponentSkeleton({ isMobile }: { isMobile?: boolean }) {
  return (
    <Card
      className={!isMobile ? styles.card : styles.cardMobile}
      bordered={!isMobile}
      title={<Skeleton.Input active />}
    >
      <Skeleton active />
    </Card>
  );
}

export default TicketsComponentSkeleton;

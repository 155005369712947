import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form, notification, Modal, InputNumber } from 'antd';
import { waitingListsKeys } from '@seaters-app/constants';
import { useDistributeMore, queryClient } from '@seaters-app/data-access';

export function DistributeMoreModal({
  open,
  onClose,
  fanId,
}: {
  open: boolean;
  onClose: () => void;
  fanId?: string | null;
}) {
  const { wishListId = '' } = useParams();

  const { t } = useTranslation();

  const { mutate: distributeMore, isLoading } = useDistributeMore(wishListId);

  const [form] = Form.useForm();

  const handleSubmit = (values: { ticketsToDistribute: number }) => {
    if (fanId)
      distributeMore(
        {
          fanId,
          nrOfSeats: values.ticketsToDistribute,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_distributed_more'),
            });
            onClose();
            queryClient.invalidateQueries(waitingListsKeys.seats(wishListId));
          },
          onError: ({ response }) => {
            notification.error({
              message: t('notification_error_distributed_more'),
              description: response?.data.message,
            });
          },
        }
      );
  };

  return (
    <Modal
      open={open}
      title={t('mwl_distri_title')}
      onOk={() => form.submit()}
      onCancel={onClose}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form
        disabled={isLoading}
        initialValues={{
          voucherExpirationTimeOutInMs: 0,
        }}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t('distribute_more_tickets_to_add')}
          name="ticketsToDistribute"
          rules={[{ required: true, message: 'Required field' }]}
        >
          <InputNumber autoFocus min={0} type="number" defaultValue={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

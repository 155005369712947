import { Button } from '@seaters-app/ui';
import { Typography, theme } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './infoBlock.module.css';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledText } from '@seaters-app/ui-shared';
const { Title, Paragraph } = Typography;

interface InfoBlockProps {
  id: string;
  title: string;
  text?: string;
  children?: ReactNode;
}

function InfoBlock({ id, title, text, children }: InfoBlockProps) {
  const { token } = theme.useToken();
  const [isExpanded, setExpanded] = useState(false);
  const switchExpand = () => {
    setExpanded(!isExpanded);
  };

  const { t } = useTranslation();

  return (
    <div id={id}>
      <Title level={5} className={styles.title}>
        {title}
      </Title>
      {children}
      {text && (
        <Paragraph className={styles.paragraph}>
          <StyledText text={text} isExpanded={isExpanded} />
          {text?.length >= 300 && (
            <Button
              type="link"
              onClick={switchExpand}
              color={token.colorPrimary}
              className={styles.showMore}
              icon={
                isExpanded ? (
                  <UpOutlined rev={undefined} />
                ) : (
                  <DownOutlined rev={undefined} />
                )
              }
            >
              {!isExpanded
                ? t('general_button_show_more')
                : t('general_button_hide')}
            </Button>
          )}
        </Paragraph>
      )}
    </div>
  );
}

export default InfoBlock;

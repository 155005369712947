import {
  SurveyCreationFormValues,
  SurveyValidator,
} from '@seaters-app/constants';
import { convertTranslation } from '../../../../utils/helpers/convertTranslations';

export const prepareValues = (
  values: SurveyCreationFormValues
): SurveyValidator => {
  const { title, description, name, status } = values;

  const dataToSend = {
    title: convertTranslation(title),
    description: convertTranslation(description),
    name,
    status,
  };

  return dataToSend;
};

import { Language, SemanticNameEnum } from '../../enums';
import { AnyZodObject, z } from 'zod';

export const GuestValidatorSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  guestEmail: z.string().min(1),
  mobilePhoneNumber: z
    .object({
      countryCallingCode: z.string().nullable(),
      localNumber: z.string().nullable(),
    })
    .nullable(),
  locale: z.nativeEnum(Language),
});

const GuestCustomInfoValidatorSchema = z.object({
  company: z.string(),
  jobtitle: z.string(),
});

const CustomInfoValidator = z.object({
  informationValue: z.boolean().or(z.string()),
  semanticName: z.nativeEnum(SemanticNameEnum),
});

export const GuestFormValidatorSchema = GuestValidatorSchema.merge(
  GuestCustomInfoValidatorSchema
);

export type GuestFormValidator = z.infer<typeof GuestFormValidatorSchema>;

export type GuestValidator = z.infer<typeof GuestValidatorSchema>;

export type CustomInfoValidator = z.infer<typeof CustomInfoValidator>;

export function generateConditionalGuestCustomInfoValidatorSchema(
  fields?: { name: string; isRequired: boolean }[]
): AnyZodObject {
  let schema = z.object({});
  if (!fields) return schema;
  fields.forEach((field, i) => {
    if (field.isRequired) {
      schema = z.object({
        ...schema.shape,
        [field.name]: z.string().min(1),
      });
    } else {
      schema = z.object({
        ...schema.shape,
        [field.name]: z.string().optional(),
      });
    }
  });
  return schema;
}

import { WaitingListWithApproval } from '@seaters-app/constants';
import { getSingleTranslation } from '@seaters-app/data-access';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useWishListSummaryData = () => {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const getData = (item: WaitingListWithApproval) => {
    return {
      isHost: false,
      generalInfo: {
        imageUrl:
          item.waitinglist.eventImageUrl ||
          item.waitinglist.waitingListImageUrl ||
          item.waitinglist.venueImageUrl,

        title: getSingleTranslation(item.waitinglist.eventName, lang),
        startDate: dayjs(item.waitinglist.eventStartDate).format('ddd D MMMM'),
        venue: getSingleTranslation(item.waitinglist?.venueName, lang),
        category: item.waitinglist.waitingListCategory,
      },
      tableInfo: [
        [
          {
            label: `${t('request_card_requested_seats')} `,
            value: item.totalOriginalSeats,
          },
          {
            label: `${t('request_card_total_demand')} `,
            value: item.totalRequestedToFgo,
          },
          {
            label: `${t('request_card_available_seats')} `,
            value: item.totalAvailableSeats,
          },
        ],
      ],
    };
  };
  return { getData };
};

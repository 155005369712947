import {
  getSingleTranslation,
  useFanGroupStore,
  useFetchFanActivities,
} from '@seaters-app/data-access';
import { CheckOutlined } from '@ant-design/icons';
import { Button } from '@seaters-app/ui';
import { Spin, Table, Typography, Space, Form, Switch } from 'antd';
import { Link } from 'react-router-dom';
import {
  FanActivityEntity,
  WaitingListStatusEnum,
  routes,
} from '@seaters-app/constants';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import { ActivityStatusTag } from './FanActivityStatusTag';
import { useTranslation } from 'react-i18next';
import { WishListStatusTag } from '@seaters-app/ui-shared';
import styles from './styles.module.css';
import { useEffect, useState } from 'react';

const { Text } = Typography;

export function FanActivity({ fanId }: { fanId: string }) {
  const { t } = useTranslation();
  const { fanGroup } = useFanGroupStore();

  const { data: activities } = useFetchFanActivities(fanId, fanGroup?.id ?? '');

  const [showClosed, setShowClosed] = useState(false);

  const [wls, setWls] = useState<FanActivityEntity[] | undefined>([]);

  useEffect(() => {
    if (showClosed) {
      setWls(activities);
    } else {
      const filteredWls = activities?.filter((wl) => {
        return (
          wl.waitingListStatus !== WaitingListStatusEnum.CLOSED &&
          wl.waitingListStatus !== WaitingListStatusEnum.ARCHIVED
        );
      });
      setWls(filteredWls);
    }
  }, [showClosed, activities]);

  return activities ? (
    <>
      <Form.Item label={t('show_closed_label')} valuePropName="checked">
        <Switch
          checkedChildren={<CheckOutlined rev={undefined} />}
          onChange={() => setShowClosed(!showClosed)}
          checked={showClosed}
          defaultChecked={true}
        />
      </Form.Item>
      <Table
        rowKey={(activity: FanActivityEntity) => activity.waitingListId}
        dataSource={wls}
        tableLayout="fixed"
        pagination={{
          showSizeChanger: true,
        }}
      >
        <Column
          title={t('mwl_tab-wl')}
          dataIndex="waitinglistExperienceName"
          key="waitinglistExperienceName"
          width="20%"
          render={(waitinglistExperienceName, wl: FanActivityEntity) => {
            const isActive = !(
              wl.waitingListStatus === WaitingListStatusEnum.CLOSED ||
              wl.waitingListStatus === WaitingListStatusEnum.ARCHIVED
            );
            return (
              <Space size={10} style={{ flexWrap: 'wrap' }}>
                <Link
                  to={`/${routes.admin}/${routes.wishLists}/${wl.waitingListId}`}
                >
                  <Button type="link" className={styles.wishListButton}>
                    {waitinglistExperienceName.length
                      ? getSingleTranslation(waitinglistExperienceName)
                      : getSingleTranslation(wl.eventName)}{' '}
                  </Button>
                </Link>
                {!isActive && (
                  <WishListStatusTag status={wl.waitingListStatus} secondary />
                )}
              </Space>
            );
          }}
        />
        <Column
          title={t('matrix_header_category')}
          dataIndex="waitingListName"
          key="waitingListName"
          width="20%"
        />
        <Column
          title={t('matrix_header_date')}
          dataIndex="waitingListStatusDate"
          key="waitingListStatusDate"
          width="15%"
          defaultSortOrder={'ascend'}
          sortDirections={['descend', 'ascend', 'descend']}
          sorter={(a: FanActivityEntity, b: FanActivityEntity) =>
            dayjs(a.waitingListStatusDate).unix() -
            dayjs(b.waitingListStatusDate).unix()
          }
          render={(waitingListStatusDate) => {
            return (
              <Text>{dayjs(waitingListStatusDate).format('YYYY MMM DD')}</Text>
            );
          }}
        />
        <Column
          title={t('fans_table_tickets_column')}
          dataIndex="numberOfSeats"
          key="numberOfSeats"
          width="10%"
        />
        <Column
          title={t('admin_waiting-list_status')}
          dataIndex="status"
          key="status"
          width="15%"
          render={(status) => {
            return <ActivityStatusTag status={status} />;
          }}
        />
      </Table>
    </>
  ) : (
    <Spin />
  );
}

import { QuestionStructureType, QuestionType } from './../enums';

export const QuestionTypeOptions = [
  // { label: 'CHECKBOX', value: QuestionType.CHECKBOX },
  { label: 'question_type_free_text', value: QuestionType.FREE_TEXT },
  { label: 'question_type_radio_single', value: QuestionType.MULTIPLE_CHOICE },
  {
    label: 'question_type_autocomplete_single',
    value: QuestionType.MULTIPLE_CHOICE_AUTO_COMPLETE,
  },
  {
    label: 'question_type_range',
    value: QuestionType.MULTIPLE_CHOICE_RANGE,
  },
  {
    label: 'question_type_autocomplete_multi',
    value: QuestionType.MULTIPLE_CHOICE_AUTO_COMPLETE_MULTIPLEANSWER,
  },
  {
    label: 'question_type_dropdown_single',
    value: QuestionType.MULTIPLE_CHOICE_DROPDOWN,
  },
  {
    label: 'question_type_checkboxes_multi',
    value: QuestionType.MULTIPLE_CHOICE_MULTIPLEANSWER,
  },
  {
    label: 'question_type_radio_single_other',
    value: QuestionType.MULTIPLE_CHOICE_WITH_OTHER,
  },
  {
    label: 'question_type_autocomplete_single_other',
    value: QuestionType.MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE,
  },
  {
    label: 'question_type_autocomplete_multi_other',
    value: QuestionType.MULTIPLE_CHOICE_WITH_OTHER_AUTO_COMPLETE_MULTIPLEANSWER,
  },
  {
    label: 'question_type_dropdown_single_other',
    value: QuestionType.MULTIPLE_CHOICE_WITH_OTHER_DROPDOWN,
  },
  {
    label: 'question_type_structured',
    value: QuestionType.STRUCTURED,
  },
];

export const StructuredQuestionTypeOptions = [
  { label: 'question_type_free_text', value: QuestionStructureType.FREE_TEXT },

  { label: 'question_type_checkbox', value: QuestionStructureType.CHECK_BOX },
];

import {
  LoginTypeEnum,
  LoginEntity,
  RegistrationTypeEnum,
  LookEntity,
  usersKeys,
} from '@seaters-app/constants';
import {
  getSlugFromUrl,
  queryClient,
  useFetchLook,
} from '@seaters-app/data-access';

export const useGetAuthOption = (
  type: LoginTypeEnum | RegistrationTypeEnum
): {
  login: LoginEntity | undefined;
  registration: RegistrationTypeEnum | undefined;
} => {
  const slug = getSlugFromUrl();

  const look = queryClient.getQueryData<LookEntity>(usersKeys.look());

  return {
    login: look?.login.find((option) => option.type === type) || undefined,
    registration:
      look?.registration.find((option) => option === type) || undefined,
  };
};

export async function retryCallback<TAttempt = boolean>(
  attempt: () => Promise<TAttempt>,
  test: (value: TAttempt) => boolean,
  processResult: (result: TAttempt) => void,
  errorHandler: (error: any) => void,
  max = 5
) {
  let p = Promise.reject() as any;

  for (let i = 0; i < max; i++) {
    p = p.catch(attempt).then(test);
  }

  return p.then(processResult).catch(errorHandler);
}

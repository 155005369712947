import {
  endpoints,
  ListEntity,
  LanguageEntity,
  LanguagesQuery,
  Language,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchLanguages = async (
  params: LanguagesQuery
): Promise<ListEntity<LanguageEntity>> => {
  try {
    const response: ListEntity<LanguageEntity> = await api.get(
      endpoints.languages,
      { params }
    );

    const preferredLanguages = [Language.EN, Language.FR, Language.NL];

    /**
     * Represents the sorted response of language items.
     * @typedef {Array} SortedResponse
     * @property {number} aIndex - The index of the first item in the preferred languages array.
     * @property {number} bIndex - The index of the second item in the preferred languages array.
     * @returns {number} The comparison result of the two items based on their indices in the preferred languages array.
     */

    const sortedResponse = response.items.sort((a, b) => {
      const aIndex = preferredLanguages.indexOf(a.locale);
      const bIndex = preferredLanguages.indexOf(b.locale);
      if (aIndex === -1 && bIndex === -1) {
        return 0;
      } else if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      } else {
        return aIndex - bIndex;
      }
    });

    const reorderedLanguages = { ...response, items: sortedResponse };

    return reorderedLanguages;
  } catch (error) {
    throw new Error('Failed to fetch data');
  }
};

import { UserRole } from '@seaters-app/constants';
import { useUserProfile } from '@seaters-app/data-access';
import useCurrentGroupRoles from '../hooks/useCurrentGroupRoles';

export function useHasAccess(allowedRoles?: UserRole[]): boolean {
  const { data: userData } = useUserProfile(true);
  const { hasIntersections } = useCurrentGroupRoles(allowedRoles);
  if (!allowedRoles) return true;

  const hasAccess = !!userData && hasIntersections;

  return hasAccess;
}

import { Tag } from 'antd';

import { InvitationForApprove } from '@seaters-app/constants';
import {
  alertColor,
  baseColor600,
  errorColor,
  successColor,
} from '@seaters-app/ui';

export interface InvitationRequestStatusTagProps {
  status: InvitationForApprove;
}

const invitationRequestsStatusToColor = {
  [InvitationForApprove.APPROVED]: successColor,
  [InvitationForApprove.CANCELLED]: baseColor600,
  [InvitationForApprove.REJECTED]: errorColor,
  [InvitationForApprove.PENDING]: alertColor,
  [InvitationForApprove.IGNORED]: baseColor600,
};
export function InvitationRequestStatusTag({
  status,
}: InvitationRequestStatusTagProps) {
  return <Tag color={invitationRequestsStatusToColor[status]}>{status}</Tag>;
}

export default InvitationRequestStatusTag;

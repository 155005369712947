import React, { useState } from 'react';
import {
  Typography,
  Tabs,
  TabsProps,
  Space,
  Spin,
  Popconfirm,
  notification,
  Flex,
  Breadcrumb,
  Alert,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './fanLayout.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, baseColor600, errorColor } from '@seaters-app/ui';
import {
  useDeleteFanGroupMember,
  useFanGroupStore,
  useFetchFanActivities,
  useFetchFanPersonalInfo,
} from '@seaters-app/data-access';
import dayjs from 'dayjs';
import { NumberItem } from '../../wish-lists/[id]/NumberItem';
import { getFanActivities } from './helpers';
import { FanActivity } from './FanActivity/FanActivity';
import { FanPersonalInfo } from './FanPersonalInfo/FanPersonalInfo';
import { AddFanBadge } from './FanBadges/AddFanBadge';
import { FanCustomInfo } from './FanCustomInfo/FanCustomInfo';
import { FanAccessRequests } from './FanAccessRequests/FanAccessRequests';

const { Title, Text } = Typography;

export function FanDetailsLayout({
  fanId: fanIdFromProps,
}: {
  fanId?: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fanId = '' } = useParams();
  const fanGroupMemberId = fanIdFromProps ?? fanId;
  const { data: fanPersonalInfo, isLoading } =
    useFetchFanPersonalInfo(fanGroupMemberId);

  const { fanGroup } = useFanGroupStore();
  const { data: activities } = useFetchFanActivities(
    fanGroupMemberId,
    fanGroup?.id ?? ''
  );
  const [activeTab, setActiveTab] = useState<string>();
  const onTabsChange = (key: string) => {
    setActiveTab(key);
  };

  const { totalJoined, totalRSVP, totalPrice, totalTickets } =
    getFanActivities(activities);

  const { mutate: deleteFan } = useDeleteFanGroupMember(fanGroup?.id ?? '');

  const handleDeleteFGMember = (fanId: string) => {
    // only possible to remove Fan from Fans Menu tab (not from Modal in WL details)
    deleteFan(
      { userId: fanId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_fg_member_removed'),
          });
          navigate('..');
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_fg_member_removed'),
          });
        },
      }
    );
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('fan_tabs_activity'),
      children: <FanActivity fanId={fanGroupMemberId} />,
    },
    {
      key: '2',
      label: t('fan_tabs_personal_info'),
      children: <FanPersonalInfo fanId={fanGroupMemberId} />,
    },
    {
      key: '3',
      label: t('admin_badges'),
      children: <AddFanBadge fanId={fanGroupMemberId} />,
    },
    {
      key: '4',
      label: t('fan_tabs_custom_info'),
      children: <FanCustomInfo fanId={fanGroupMemberId} />,
    },
    {
      key: '5',
      label: t('fan_tabs_access-requests'),
      children: <FanAccessRequests fanId={fanGroupMemberId} />,
    },
  ];

  return (
    <Flex
      vertical
      style={{
        padding: 24,
        paddingBottom: 80,
      }}
      gap={8}
    >
      <div>
        {!fanIdFromProps && (
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="..">{t('fans_tab-fans')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{t('admin_tabs_details')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        <div className={styles.subheader}>
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <div>
              <Title style={{ marginLeft: 0 }} level={4}>
                {fanPersonalInfo?.firstName} {fanPersonalInfo?.lastName}
              </Title>
              <Text style={{ color: baseColor600, marginBottom: 16 }}>
                {t('registered_since_label')}{' '}
                {dayjs(fanPersonalInfo?.creationDate).format('YYYY/MM/DD')}
              </Text>
            </div>
            {fanId && (
              <Popconfirm
                title={t('delete_fg_member_title')}
                description={t('delete_fg_member_confirmation_text')}
                cancelText={t('general_no')}
                onConfirm={() => handleDeleteFGMember(fanId)}
                onOpenChange={() => console.log('open change')}
              >
                <Button
                  type="text"
                  color={errorColor}
                  style={{ borderColor: errorColor }}
                  icon={<DeleteOutlined rev={undefined} />}
                >
                  {t('fans_list_tbl-actions-removefan')}
                </Button>
              </Popconfirm>
            )}
          </Space>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Space size="middle">
              <NumberItem
                maxWidth={80}
                label={t('fan_total_joined_label')}
                value={totalJoined}
              />
              <NumberItem
                maxWidth={80}
                label={t('fan_total_rsvp_label')}
                value={totalRSVP}
              />
              <NumberItem
                maxWidth={'min-content'}
                label={t('fan_total_assets_label')}
                value={totalTickets}
              />
              <NumberItem
                maxWidth={'min-content'}
                label={t('fan_total_paid_label')}
                value={totalPrice}
              />
            </Space>
            {/* <Space size="middle">
              <NumberItem
                maxWidth={80}
                label={`${t('admin_fan-groups_waiting-lists_tab')} ${t(
                  'joined_wl_status_text'
                )}`}
                value={joined}
              />
              <NumberItem
                maxWidth={80}
                label={t('mwl_seats_table-status-pending')}
                value={pending}
              />
              <NumberItem
                maxWidth={'min-content'}
                label={t('mwl_seats_table-status-confirmed')}
                value={confirmed}
              />
              <NumberItem
                maxWidth={'min-content'}
                label={t('abandon_label')}
                value={abandon}
              />
            </Space> */}
          </div>
        </div>
      </div>
      {fanPersonalInfo ? (
        <Tabs items={items} onChange={onTabsChange} />
      ) : isLoading ? (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Spin />
        </div>
      ) : (
        <Alert
          message={t('position_personal_info_error_message')}
          description={t('position_personal_info_error_description')}
          type="error"
        />
      )}
    </Flex>
  );
}

export const camelCaseToNormal = (name?: string | null): string => {
  if (!name) {
    return '';
  }
  const parts = name.split(/(?=[A-Z])/);
  const normalName = parts
    .map((part) => part.charAt(0).toLowerCase() + part.slice(1))
    .join(' ');
  return normalName.charAt(0).toUpperCase() + normalName.slice(1);
};

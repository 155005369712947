import { z } from 'zod';

export const AccessCodeCustomInfoValidationSchema = z.object({
  accessCodeCustomInfos: z
    .array(
      z.object({
        fgCustomInformationId: z.string(),
        informationValue: z.string(),
        semanticName: z.string(),
      })
    )
    .optional(),
});

export type AccessCodeCustomInfoValidation = z.infer<
  typeof AccessCodeCustomInfoValidationSchema
>;

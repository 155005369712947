import {
  FanGroupFilter,
  WaitingListFiltersQuery,
} from '@seaters-app/constants';
import { getSlugFromUrl, useFetchLook } from '@seaters-app/data-access';
import { useEffect, useState } from 'react';

export const useFilters = () => {
  const detectedSlug = getSlugFromUrl();

  const { data: fanGroupData } = useFetchLook(detectedSlug ?? '');
  const [filters, setFilters] = useState<FanGroupFilter[]>();

  const [selectedFilters, setSelectedFilters] =
    useState<WaitingListFiltersQuery>({});

  const fanGroupFilters = fanGroupData?.properties?.filters;

  useEffect(() => {
    if (fanGroupFilters) {
      setFilters(fanGroupFilters);
    }
  }, [fanGroupData?.properties]);

  useEffect(() => {
    if (
      fanGroupFilters?.find(
        (filter) => filter.name === 'hasAvailableTicketsOnly'
      )
    ) {
      setSelectedFilters({ hasAvailableTicketsOnly: true });
    }
  }, [fanGroupFilters?.length]);

  return {
    filters,
    setSelectedFilters,
    selectedFilters,
  };
};

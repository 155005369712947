import { LOCATION_STATES, routes } from '@seaters-app/constants';
import { useLocation } from 'react-router-dom';
import { Base64 } from 'js-base64';

export const getModifiedUrl = (url: string) => {
  const { state: locationState } = useLocation();

  let splittedURI = url?.split('&') ?? [];

  const state = splittedURI
    ?.find((item) => item.startsWith('state='))
    ?.slice('state='.length)
    ?.replace('\r\n', '');

  const decodedState = state ? atob(state) : null;

  const isSurveyAfterEvent = locationState?.[
    LOCATION_STATES.REDIRECT_PATH
  ]?.pathname?.includes(routes.surveyAfterEvent);

  let wlId =
    locationState?.[LOCATION_STATES.REDIRECT_PATH]?.pathname.split('/')[3];

  let surveyAfterEventToken =
    locationState?.[LOCATION_STATES.REDIRECT_PATH]?.search.split('token=')[1];

  let modifiedState = decodedState + `&wlId=${wlId}`;

  if (isSurveyAfterEvent) {
    modifiedState =
      modifiedState + `&surveyAfterEventToken=${surveyAfterEventToken}`;
  }

  const encodedModifiedState = Base64.encode(modifiedState, true);

  const stateIndex = splittedURI?.findIndex((item) =>
    item.startsWith('state=')
  );

  splittedURI[stateIndex ?? 0] = `state=${encodedModifiedState}`;

  const newURI = splittedURI.join('&');
  return newURI;
};

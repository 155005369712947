import {
  PreauthorizationMode,
  FanGroupEntity,
  BillingMode,
  AccessMode,
  WaitingListStatusEnum,
  SeatDistributionMode,
  CreateWishListBody,
  FanGroupOwnerWaitingListEntity,
  PositionsDistributionMode,
  DisplayMode,
  InvitationApprovalMode,
  AssignationMode,
  FanGroupOwnerFanGroupEntity,
  WLAccessModeEnum,
} from '@seaters-app/constants';
import { getTranslations, initialTranslations } from '../../../../utils';
import dayjs from 'dayjs';
import { timeUnitOptions } from './constants';
import { CreateWishListFormType } from '../steps/SettingsStep';
import { stringToPercentage } from '@seaters-app/data-access';

export const getWLInitialValues = ({
  fanGroup,
}: {
  fanGroup?: FanGroupEntity;
}): CreateWishListBody => {
  return {
    eventId: undefined,
    maxNumberOfSeatsPerPosition: 1,
    rankOffset: 0,
    totalTickets: 0,
    maxSize: null,
    estimatedTickets: 0,
    originalPrice: 0,
    price: 0,
    preauthorizationMode: PreauthorizationMode.NEVER,
    billingVariables: {
      distributionRate: '0.00',
      maxDistributionFee: '0.00',
      minDistributionFee: '0.00',
      vatRate: '0.00',
    },
    billingMode:
      fanGroup?.availableBillingModes?.length === 2
        ? BillingMode.FREE
        : fanGroup?.availableBillingModes?.[0] || BillingMode.FAIR_PRICE,
    accessMode: AccessMode.PUBLIC,
    voucherText: [],
    protectionCodeExplanation: [],
    voucherExpirationTimeOutInMs: 1000 * 60 * 60 * 48,
    positionExpirationTimeOutInMs: 1000 * 60 * 60 * 24,
    paidSeatExpirationTimeOutInMs: 0,
    status: WaitingListStatusEnum.DRAFT,
    seatDistributionMode: SeatDistributionMode.VOUCHER,
    interests: [],
    fanAttributes: [],
    unlinkInterestsBeforeSave: false,
    unlinkFanAttributesBeforeSave: false,
    // @TODO: remove mocked data - it should come from backend
    fanGroupPopulationTotal: 24166,
    fanGroupNotificationOptInTotal: 22216,
    notificationsEnabled: false,
    notificationsByRewardsReceivedEnabled: false,
    notificationsByRewardsReceivedAmount: 2,
    notificationsByUserInterestsEnabled: false,
    notificationsByUserInterestsTargetGroup: 'all',
    notificationsByLocationEnabled: false,
    notificationsByLocationPlace: undefined,
    notificationsByLocationRadius: 35,
    notificationsByLocationRadiusType: 'km',
    notificationsByLocationIncludeUsersWithoutAddress: true,

    notificationsInterestLikedTotal: 9871,
    notificationsInterestDislikedTotal: 750,
    notificationsTotalUsers: 9871,
    notificationsTotalUsersWithoutAddress: 35,
    notificationsTotalTickets: 5800,
  };
};

export const getWLSettingsStepInitialValues = (
  wishList: FanGroupOwnerWaitingListEntity | undefined,
  fanGroup: FanGroupOwnerFanGroupEntity | undefined,
  keywordsInitialValues: { [key: string]: string[] },
  initialUnit: keyof typeof timeUnitOptions
): CreateWishListFormType => {
  const keywords: {
    [key: string]: string[];
  } = {};

  const wlKeywords = getTranslations(['keywords'], wishList)?.keywords;

  if (wlKeywords) {
    Object.keys(wlKeywords).forEach((lang) => {
      if (wlKeywords[lang].length) {
        return (keywords[lang] = wlKeywords[lang].length
          ? wlKeywords[lang].split(',')
          : []);
      }
    });
  }

  return {
    coverImage: wishList?.event.venueImageUrl ?? null,
    experienceName: wishList?.experienceName.length
      ? getTranslations(['experienceName'], wishList)?.experienceName
      : initialTranslations,
    name: wishList?.name ?? '',
    highlighted: wishList?.highlighted ?? false,
    waitingListImageUrl: wishList?.waitingListImageUrl ?? null,
    description: wishList?.description.length
      ? getTranslations(['description'], wishList)?.description
      : initialTranslations,
    keywords: wishList?.keywords.length ? keywords : keywordsInitialValues,
    billingMode: wishList?.billingMode || BillingMode.FREE,
    accessMode: wishList?.accessMode || WLAccessModeEnum.PUBLIC,
    originalPrice: wishList?.originalPrice ?? 0,
    showFreePrice: wishList?.showFreePrice ?? false,
    directSales: wishList?.directSales ?? false,
    termsAndConditionFileURL: wishList?.termsAndConditionFileURL ?? '',
    positionsDistributionMode:
      wishList?.positionsDistributionMode || PositionsDistributionMode.FIFS,
    displayMode: wishList?.displayMode || DisplayMode.CLASSIC,
    minNumberOfSeatsPerPosition: wishList?.minNumberOfSeatsPerPosition ?? 1,
    maxNumberOfSeatsPerPosition: wishList?.maxNumberOfSeatsPerPosition ?? 1,
    fixedNumberOfSeatsPerPosition: wishList?.fixedNumberOfSeatsPerPosition ?? 1,
    voucherExpirationTimeOutInMs: wishList?.voucherExpirationTimeOutInMs
      ? wishList?.voucherExpirationTimeOutInMs /
        timeUnitOptions[initialUnit].value
      : 0,

    participationEndDate: wishList?.participationEndDate
      ? dayjs(wishList?.participationEndDate)
      : null,

    invitationParameters: wishList?.invitationParameters || {
      approvalMode: InvitationApprovalMode.DEFAULT,
      minNumberOfInvitations: 1,
      maxNumberOfInvitations: 10,
      assignationMode: AssignationMode.HOST_RSVP,
      specialGuestEnabled: false,
    },
    billingVariables: wishList?.billingVariables
      ? {
          ...wishList.billingVariables,
          distributionRate: stringToPercentage(
            wishList.billingVariables.distributionRate
          ),
        }
      : {
          distributionRate: '0.00',
          maxDistributionFee: '0.00',
          minDistributionFee: '0.00',
          vatRate: '0.00',
        },
    price: wishList?.price ?? '0.00',
    prepaidFee: wishList?.prepaidFee ?? false,
  };
};

import { Tag } from 'antd';

import { ApproverRequestsStatus } from '@seaters-app/constants';
import {
  alertColor,
  baseColor600,
  errorColor,
  successColor,
} from '@seaters-app/ui';

export interface ApproversRequestStatusTagProps {
  status: ApproverRequestsStatus;
}

const approverRequestsStatusToColor = {
  [ApproverRequestsStatus.APPROVED]: successColor,
  [ApproverRequestsStatus.CANCELLED]: baseColor600,
  [ApproverRequestsStatus.REJECTED]: errorColor,
  [ApproverRequestsStatus.PENDING]: alertColor,
};
export function ApproversRequestStatusTag({
  status,
}: ApproversRequestStatusTagProps) {
  return <Tag color={approverRequestsStatusToColor[status]}>{status}</Tag>;
}

export default ApproversRequestStatusTag;

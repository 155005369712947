import { Tag } from 'antd';

import { VenueStatus } from '@seaters-app/constants';

import { useTranslation } from 'react-i18next';

export interface VenueStatusTagProps {
  status: VenueStatus;
}

const venueStatusToColor = {
  [VenueStatus.ARCHIVED]: 'error',
  [VenueStatus.ACTIVE]: 'success',
  [VenueStatus.DRAFT]: 'default',
};

const venueStatusTranslations: { [key in VenueStatus]: string } = {
  ARCHIVED: 'admin_event_status_archived',
  DRAFT: 'admin_event_status_draft',
  ACTIVE: 'admin_user_status_active',
};
export function VenueStatusTag({ status }: VenueStatusTagProps) {
  const { t } = useTranslation();
  return (
    <Tag color={venueStatusToColor[status]}>
      {t(venueStatusTranslations[status])}
    </Tag>
  );
}

export default VenueStatusTag;

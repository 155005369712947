import React, { useState } from 'react';
import {
  AccessMode,
  FanGroupAccessFormType,
  FanGroupAccessValidatorSchema,
  FanGroupVisibility,
} from '@seaters-app/constants';
import { Skeleton, notification, Spin } from 'antd';
import {
  useFanGroupStore,
  useUpdateFGOFanGroup,
} from '@seaters-app/data-access';
import { prepareValues } from '../[id]/helpers/prepareValues';
import { FanGroupAccessForm } from '../fanGroupForm/FanGroupAccessForm';
import {
  getTranslations,
  initialTranslations,
} from '../../../utils/helpers/getTranslations';
import { useTranslation } from 'react-i18next';

export function FGConfigurationAccess() {
  const { t } = useTranslation();
  const [spinning, setSpinning] = useState(false);

  const { fanGroup } = useFanGroupStore();

  const { mutate: updateFanGroup, isLoading: isFanGroupUpdating } =
    useUpdateFGOFanGroup(fanGroup?.id ?? '');

  const formValues = {
    visibility: fanGroup?.visibility === FanGroupVisibility.VISIBLE,
    accessMode: fanGroup?.accessMode ?? AccessMode.PRIVATE,
    protectionCodeExplanation:
      getTranslations(['protectionCodeExplanation'], fanGroup)
        ?.protectionCodeExplanation ?? initialTranslations,
  };

  const onSubmit = (values: FanGroupAccessFormType) => {
    const preparedValues = {
      ...prepareValues(values),
      visibility: values.visibility,
    };

    const parsed = FanGroupAccessValidatorSchema.safeParse(preparedValues);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    if (fanGroup) {
      const dataToSend = {
        ...fanGroup,
        operationParameters: {
          visibility: parsed.data.visibility
            ? FanGroupVisibility.VISIBLE
            : FanGroupVisibility.INVISIBLE,
          accessMode: parsed.data.accessMode,
        },
        protectionCodeExplanation: parsed.data.protectionCodeExplanation,
        visibility: parsed.data.visibility
          ? FanGroupVisibility.VISIBLE
          : FanGroupVisibility.INVISIBLE,
        accessMode: parsed.data.accessMode,
      };

      setSpinning(true);
      updateFanGroup(dataToSend, {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_fg_updated'),
          });
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_fg_updated'),
          });
        },
        onSettled: () => {
          setSpinning(false);
        },
      });
    }
  };

  return formValues ? (
    <Spin tip={t('please_wait_message_text')} spinning={spinning}>
      <FanGroupAccessForm
        fanGroup={formValues}
        onSubmit={onSubmit}
        isConfiguration
      />
    </Spin>
  ) : (
    <Skeleton />
  );
}

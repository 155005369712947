import { Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import { useExportReport } from './hooks/useExportReport';

export function ExportReportButton() {
  const { t } = useTranslation();

  const { isExporting, handleExport } = useExportReport();

  return (
    <Button onClick={handleExport} loading={isExporting}>
      {t('export_FGO_report_button')}
    </Button>
  );
}

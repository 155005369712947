import { UserRole } from '../enums';

export const UserRoleOptions = [
  { label: 'Admin', value: UserRole.ADMIN },
  { label: 'Approver', value: UserRole.APPROVER },
  { label: 'Fan', value: UserRole.FAN },
  { label: 'Fan group owner', value: UserRole.FAN_GROUP_OWNER },
  { label: 'Fan group developer', value: UserRole.FAN_GROUP_DEVELOPER },
  { label: 'Guest', value: UserRole.GUEST },
  { label: 'Host', value: UserRole.HOST },
  { label: 'Translator', value: UserRole.TRANSLATOR },
];

import { PaginationQuery } from '@seaters-app/constants';

export interface IUsePaginationAdapter {
  pageSize: number;
  current: number;
}

export const paginationFormat = ({
  pageSize,
  current,
}: IUsePaginationAdapter): PaginationQuery => {
  const params = {
    itemOffset: (current - 1) * pageSize,
    maxPageSize: pageSize,
  };

  return params;
};

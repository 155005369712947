import {
  downloadFileByURL,
  fetchInvitation,
  useExportWaitingListInvitationParkingTicket,
} from '@seaters-app/data-access';
import { TagsOutlined } from '@ant-design/icons';

import { Button, notification, theme } from 'antd';
import { ActionButtonType } from './CancelButton';
import { retryExport } from '../helpers';
import { useTranslation } from 'react-i18next';

export function ParkingTicketButton({
  waitingListId,
  invitation,
}: ActionButtonType) {
  const { token } = theme.useToken();

  const { t } = useTranslation();

  const { mutateAsync: exportParkingTicket } =
    useExportWaitingListInvitationParkingTicket(waitingListId);

  const handleParkingTicketDownload = async () => {
    if (invitation.position.exportedParkingTicketUrl) {
      downloadFileByURL(
        invitation.position.exportedParkingTicketUrl,
        'Parking tickets'
      );
    } else {
      exportParkingTicket(
        { invitationId: invitation.id },
        {
          onSuccess: async () => {
            retryExport(
              invitation.id,
              'exportedParkingTicketUrl',
              'Parking tickets'
            );

            const fetchedInvitation = await fetchInvitation(invitation.id);

            if (fetchedInvitation.position.exportedParkingTicketUrl) {
              await downloadFileByURL(
                fetchedInvitation.position.exportedParkingTicketUrl,
                'Parking tickets'
              );
            }
          },
          onError: () => {
            notification.error({
              message: t('tickets_generate_notification_error'),
            });
          },
        }
      );
    }
  };

  return invitation.position.parkingTicketStatus ? (
    <Button
      type="link"
      icon={<TagsOutlined rev={undefined} />}
      color={token.colorWarning}
      onClick={handleParkingTicketDownload}
    >
      {t('guestlist_download_parking-btn')}
    </Button>
  ) : null;
}

import { VerificationType, VerificationTypeV2 } from '@seaters-app/constants';

export const getVerificationToken = (
  verification: VerificationType | VerificationTypeV2
): string => {
  if (typeof verification.token === 'string') {
    return verification.token;
  }
  return verification.token.value;
};

import {
  endpoints,
  ListEntity,
  ProtectionCodeEntity,
  CodesQuery,
  CodeValidator,
  V2Prefix,
  SortedPaginationQueryV2,
  ListEntityV2,
  ProtectionCodeEnhancedEntity,
  EnhancedCodesQuery,
  CustomInfoItem,
  ProtectionCodeCustomInfo,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchFanGroupProtectionCodes = (
  fanGroupId: string,
  params: CodesQuery
): Promise<ListEntity<ProtectionCodeEntity>> =>
  api.get(
    `${endpoints.seatersAdmin}/${endpoints.fanGroups}/${fanGroupId}/${endpoints.fanGroupProtectionCodes}/`,
    { params }
  );

// GET https://api.qa-seaters.com/api/v2/seaters-admin/fan-groups/id/enhanced-protection-codes/

export const fetchWishListProtectionCodes = (
  wlId: string,
  params: CodesQuery
): Promise<ListEntity<ProtectionCodeEntity>> =>
  api.get(
    `${endpoints.seatersAdmin}/${endpoints.waitingLists}/${wlId}/${endpoints.wishListProtectionCodes}/`,
    { params }
  );

// https://api.qa-seaters.com/api/seaters-admin/waiting-lists/id/protection-codes/

export const fetchFanGroupProtectionCodesEnhanced = (
  fanGroupId: string,
  params: EnhancedCodesQuery
): Promise<ListEntityV2<ProtectionCodeEnhancedEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.seatersAdmin}/${endpoints.fanGroups}/${fanGroupId}/${endpoints.fanGroupProtectionCodesEnhanced}`,
    { params }
  );

// GET https://api.qa-seaters.com/api/v2/fan-group-owner/fan-groups/id/enhanced-protection-codes/

export const fetchFanGroupProtectionCodesEnhancedAsFGO = (
  fanGroupId: string,
  params: EnhancedCodesQuery
): Promise<ListEntityV2<ProtectionCodeEnhancedEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.fanGroups}/${fanGroupId}/${endpoints.fanGroupProtectionCodesEnhanced}`,
    { params }
  );

export const fetchFanGroupProtectionCodesBadges = (
  fanGroupId: string,
  params: SortedPaginationQueryV2
): Promise<ListEntityV2<ProtectionCodeEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.badgesCodeProtection}/`,
    { params }
  );

export const fetchFanGroupProtectionCodesCustomInfo = (
  fanGroupId: string,
  params: SortedPaginationQueryV2
): Promise<ListEntityV2<ProtectionCodeCustomInfo>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.customInfoCodeProtection}/`,
    { params }
  );

// POST https://api.qa-seaters.com/api/v2/fan-group-owner/groups/groupId/code/code/custom-info/

export const linkFanGroupProtectionCodeToCustomInfo = (
  fanGroupId: string,
  code: string,
  body: CustomInfoItem
): Promise<any> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.code}/${code}/${endpoints.customInfo}/`,
    body
  );

// DELETE https://api.qa-seaters.com/api/v2/fan-group-owner/groups/groupId/code/code/custom-info/semanticName/

export const unlinkFanGroupProtectionCodeCustomInfo = (
  fanGroupId: string,
  code: string,
  semanticName: string
): Promise<any> =>
  api.delete(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.code}/${code}/${endpoints.customInfo}/${semanticName}`
  );

//POST https://api.qa-seaters.com/api/v2/fan-group-owner/groups/groupId/badges/badgeId/code/code/

export const linkFanGroupProtectionCodeToBadge = (
  fanGroupId: string,
  code: string,
  badgeId: string
): Promise<any> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.badges}/${badgeId}/${endpoints.code}/${code}`
  );

// DELETE https://api.qa-seaters.com/api/v2/fan-group-owner/groups/groupId/badges/badgeId/code/code/

export const unlinkFanGroupProtectionCodeBadge = (
  fanGroupId: string,
  code: string,
  badgeId: string
): Promise<any> =>
  api.delete(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.badges}/${badgeId}/${endpoints.code}/${code}`
  );

export const createFanGroupProtectionCode = (
  fanGroupId: string,
  body: CodeValidator
): Promise<ProtectionCodeEntity> =>
  api.post(
    `${endpoints.seatersAdmin}/${endpoints.fanGroups}/${fanGroupId}/${endpoints.fanGroupProtectionCodes}/`,
    body
  );

export const createWLProtectionCode = (
  wlId: string,
  body: CodeValidator
): Promise<ProtectionCodeEntity> =>
  api.post(
    `${endpoints.seatersAdmin}/${endpoints.waitingLists}/${wlId}/${endpoints.wishListProtectionCodes}/`,
    body
  );

// POST https://api.qa-seaters.com/api/seaters-admin/waiting-lists/id/protection-codes/

export const deleteFanGroupProtectionCode = (
  fanGroupId: string,
  code: string
) =>
  api.delete(
    `${endpoints.seatersAdmin}/${endpoints.fanGroups}/${fanGroupId}/${endpoints.fanGroupProtectionCodes}/${code}`
  );

export const deleteWLProtectionCode = (wlId: string, code: string) =>
  api.delete(
    `${endpoints.seatersAdmin}/${endpoints.waitingLists}/${wlId}/${endpoints.wishListProtectionCodes}/${code}`
  );

import React, { useState } from 'react';
import { SurveyInstanceEntity, routes } from '@seaters-app/constants';
import { Link, useParams } from 'react-router-dom';
import { Button, errorColor } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  notification,
} from 'antd';
import Column from 'antd/es/table/Column';
import {
  InfoCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  getSingleTranslation,
  useDeleteAdminSurveyInstance,
  useFetchAdminSurveysInstances,
} from '@seaters-app/data-access';
import { surveyExtensionPoints } from '../[id]/SurveyInstanceDetails/constants';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { paginationFormat, usePagination } from '../../../utils';
import { useFGAdminOptions } from '../[id]/hooks/useFGAdminOptions';
import { debounce } from 'lodash';
import { useWLOptions } from '../[id]/hooks/useWLOptions';

function SurveyUsageList() {
  const { t } = useTranslation();

  const { questionnaireId = '' } = useParams();

  const [query, setQuery] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    current: withDefault(NumberParam, 1),
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      pageSize,
      current,
    });
  };

  const [selectedFG, setSelectedFG] = useState('');
  const [selectedWL, setSelectedWL] = useState('');
  const queryParams = paginationFormat(query);

  const { waitingListsOptions, loadMoreData: loadMoreWLs } = useWLOptions(
    selectedFG ?? ''
  );

  const { data, isLoading } = useFetchAdminSurveysInstances(true, {
    ...queryParams,
    survey_id: questionnaireId,
    fangroup_id: selectedWL ? '' : selectedFG,
    waitinglist_id: selectedWL,
  });
  const [searchText, setSearchText] = useState('');

  const pagination = usePagination({ ...query, totalSize: data?.totalSize });

  const { mutate: deleteSurveyInstance } = useDeleteAdminSurveyInstance();

  const {
    fanGroupsOptions,
    loadMoreData: loadMoreFGs,
    fanGroupsFetching,
  } = useFGAdminOptions(
    {
      page: 0,
      size: 20,
      query: searchText,
    },
    false
  );
  const [openWishlistsDropdown, setOpenWishlistsDropdown] = useState(
    !!selectedFG
  );
  const handleRemoveSurveyInstance = (id: string) => {
    deleteSurveyInstance(
      { surveyInstanceId: id },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_survey_usage_remove'),
          });
        },
        onError: () => {
          notification.error({
            message: t('notification_error_survey_usage_remove'),
          });
        },
      }
    );
  };

  const handleScrollFGs = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      loadMoreFGs();
    }
  };

  const handleScrollWLs = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      loadMoreWLs();
    }
  };

  const handleSearchFGs = debounce((searchValue) => {
    setSearchText(searchValue);
  }, 500);

  return (
    <>
      <Flex align="space-between">
        <Space size={24} style={{ width: '100%', marginBottom: 16 }}>
          <Select
            style={{ width: '250px' }}
            optionFilterProp="label"
            placeholder={t('search_by_placeholder_text', {
              by: t('admin_fan-groups_slug'),
            })}
            allowClear
            onClear={() => {
              setSelectedFG('');
              setSearchText('');
            }}
            notFoundContent={
              fanGroupsFetching ? (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Spin size="small" />
                </div>
              ) : (
                !fanGroupsOptions.length && t('not_found')
              )
            }
            showSearch
            onSearch={handleSearchFGs}
            onPopupScroll={handleScrollFGs}
            options={fanGroupsOptions}
            onSelect={(item, option) => {
              setSelectedFG(option.value?.toString() ?? '');
            }}
          />
          <Select
            style={{ width: '250px' }}
            placeholder={`${t('select_button_text')} ${t('mwl_tab-wl')}`}
            open={openWishlistsDropdown}
            onDropdownVisibleChange={(visible) =>
              setOpenWishlistsDropdown(visible)
            }
            showSearch
            allowClear
            onClear={() => {
              setSelectedWL('');
            }}
            optionFilterProp="label"
            options={waitingListsOptions}
            onPopupScroll={handleScrollWLs}
            onSelect={(item, option) => {
              setSelectedWL(option.value?.toString() ?? '');
            }}
            disabled={!selectedFG && !selectedWL}
          />
        </Space>

        <Link
          to={
            questionnaireId
              ? `../${routes.usage}/${routes.create}`
              : routes.create
          }
          state={questionnaireId}
        >
          <Button type="primary" icon={<PlusOutlined rev={undefined} />}>
            {t('general_add-btn')}
          </Button>
        </Link>
      </Flex>
      <Table
        rowKey={(surveyInstance) => surveyInstance.id}
        dataSource={data?.items}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
        locale={{
          emptyText: !(selectedFG || selectedFG)
            ? t('no_data')
            : t('no_data_filtered_by_fan_group_and_wl'),
        }}
      >
        <Column
          title={t('survey_management_extension_point_label')}
          dataIndex="extensionPoint"
          key="extensionPoint"
          width="25%"
          render={(extensionPoint) => {
            return (
              <span>
                {t(
                  surveyExtensionPoints.find(
                    (extPoint) => extPoint.value === extensionPoint
                  )?.label
                )}
              </span>
            );
          }}
        />
        <Column
          title={t('mwl_tab-wl')}
          dataIndex="waitinglist"
          key="waitinglist"
          width="25%"
          render={(_, surveyInstance: SurveyInstanceEntity) => {
            return (
              <span>
                {surveyInstance?.waitinglist?.experienceName?.length
                  ? getSingleTranslation(
                      surveyInstance?.waitinglist?.experienceName
                    )
                  : getSingleTranslation(
                      surveyInstance?.waitinglist?.eventName ?? []
                    )}
              </span>
            );
          }}
        />
        <Column
          title={t('admin_fan-group')}
          dataIndex="fangroup"
          key="fangroup"
          width="20%"
          render={(_, surveyInstance: SurveyInstanceEntity) => {
            return (
              <span>
                {surveyInstance?.fangroup
                  ? getSingleTranslation(surveyInstance?.fangroup?.name)
                  : ''}
              </span>
            );
          }}
        />

        <Column
          dataIndex="id"
          key="details"
          align="right"
          render={(id: string) => {
            return (
              <Space size="small">
                <Link to={`../${routes.usage}/${id}`}>
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined rev={undefined} />}
                  >
                    {t('admin_tabs_details')}
                  </Button>
                </Link>
                <Popconfirm
                  title={t('delete_wl_survey_button_text')}
                  description={t('delete_wl_survey_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() => handleRemoveSurveyInstance(id)}
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    type="link"
                    color={errorColor}
                    icon={<DeleteOutlined rev={undefined} />}
                  >
                    {t('mwl_wl_fan-remove-btn')}
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
    </>
  );
}

export default SurveyUsageList;

import { Language } from '@seaters-app/constants';

/**
 * Retrieves the translation of an object based on the specified language.
 * If the object is null or undefined, it returns the fallback value (if provided) or null.
 * If the translation for the specified language is found, it returns the translation.
 * If the translation for the specified language is not found, it checks for the translation in the default language.
 * If the translation for the default language is found, it returns the translation.
 * If the fallback value is provided, it returns the fallback value.
 * Otherwise, it returns null.
 *
 * @param obj - The object containing translations.
 * @param language - The language code for which to retrieve the translation.
 * @param fallback - The fallback value to return if the object is null or undefined.
 * @returns The translation of the object in the specified language, or the fallback value, or null.
 */
const defaultLanguage = Language.EN;

export function getObjectTranslation(
  obj: { [key in Language]?: string } | null | undefined,
  language: Language | string,
  fallback?: string
): string | undefined | null {
  if (!obj) {
    if (fallback) {
      return fallback;
    }
    return null;
  }

  if (obj[language]) {
    return obj[language];
  }

  if (obj[defaultLanguage]) {
    return obj[defaultLanguage];
  }

  if (fallback) {
    return fallback;
  }

  return null;
}

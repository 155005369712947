import { FormTranslationType, TranslationEntity } from '@seaters-app/constants';

export const convertTranslation = (
  translation: FormTranslationType
): TranslationEntity[] => {
  return Object.keys(translation).map((locale) => ({
    lang: locale,
    text: translation[locale] ?? '',
  }));
};

export const convertTranslationToObject = (
  translation: TranslationEntity[]
): FormTranslationType => {
  const res = translation.reduce(
    (acc: FormTranslationType, curr: TranslationEntity) => {
      acc[curr.lang] = curr.text;
      return acc;
    },
    {}
  );
  return res;
};

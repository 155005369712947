import React from 'react';

import { ConfigProvider, Divider, DividerProps, theme } from 'antd';
import styles from './divider.module.css';

interface CustomDividerProps extends DividerProps {
  color?: string;
}
export const CustomDivider: React.FC<CustomDividerProps> = ({ color }) => {
  const { token } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: color ?? token.colorBorder,
        },
      }}
    >
      <Divider className={styles.divider} />
    </ConfigProvider>
  );
};

export default CustomDivider;

import React, { useRef, useState } from 'react';
import Cropper, { ReactCropperElement, ReactCropperProps } from 'react-cropper';
import { Flex, FormItemProps, Modal, Space } from 'antd';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/es/upload';
import { ImageUploader } from '../UploadImages';
import { Button } from '@seaters-app/ui';
import {
  ReloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

interface ImageCropperProps {
  width?: number;
  height?: number;
  imageOrCanvas: string;
  handleRemoveImage: () => void;
  handleImageChange: (image: HTMLCanvasElement) => void;
  handleReset: () => void;
  formItemProps?: FormItemProps;
  cropperProps?: ReactCropperProps;
  quality?: number;
  listType?: UploadProps['listType'];
  beforeUpload?: UploadProps['beforeUpload'];
  sideContent?: React.ReactNode;
  size?: string;
  uploadText?: string;
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const ImageCropper: React.FC<ImageCropperProps> = ({
  width = 480,
  height = 240,
  imageOrCanvas,
  handleRemoveImage,
  handleImageChange,
  handleReset,
  formItemProps,
  cropperProps,
  uploadText,
  quality,
  listType,
  beforeUpload,
  sideContent,
  size = `${width} x ${height}`,
}) => {
  const [cropper, setCropper] = useState<Cropper>();

  const [bufferImage, setBufferImage] = useState<string>();
  const [cropModalOpen, setCropModalOpen] = useState(false);

  const imageRef = useRef<ReactCropperElement>(null);

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      const preparedImage = quality
        ? cropper.getCroppedCanvas({
            width,
            height,
          })
        : cropper.getCroppedCanvas();
      handleImageChange(preparedImage);
    }
  };

  const handleCropModalConfirm = () => {
    getCropData();
    setCropModalOpen(false);
  };

  const handleCropModalCancel = () => {
    setCropModalOpen(false);
    handleReset();
  };

  const customRequestHandler = (info: any) => {
    const { file, onSuccess, onError } = info;

    onSuccess();
  };

  const onUploadChangeHandler = async (
    info: UploadChangeParam<UploadFile<RcFile>>
  ) => {
    console.log('onchange', info);
    if (info.file.status === 'uploading') {
      console.log('uploading');
    }
    if (info.file.status === 'done') {
      console.log('done', info);

      if (info.file.originFileObj) {
        const imgBase64 = await getBase64(info.file.originFileObj);

        setBufferImage(imgBase64);

        setCropModalOpen(true);
      }
    } else if (info.file.status === 'error') {
      console.log('error', info);
    }
  };

  const handleZoomIn = () => {
    if (cropper) {
      cropper.zoom(0.1);
    }
  };

  const handleZoomOut = () => {
    if (cropper) {
      cropper.zoom(-0.1);
    }
  };

  const handleRotateLeft = () => {
    if (cropper) {
      cropper.rotate(-90);
    }
  };

  const handleRotateRight = () => {
    if (cropper) {
      cropper.rotate(90);
    }
  };
  const handleResetCropping = () => {
    if (cropper) {
      cropper.reset();
    }
  };

  return (
    <>
      <Modal
        open={cropModalOpen}
        onCancel={handleCropModalCancel}
        onOk={handleCropModalConfirm}
        cancelButtonProps={{
          size: 'middle',
        }}
        okButtonProps={{
          size: 'middle',
        }}
        width={1000}
      >
        <Flex vertical align="center" gap={12}>
          {bufferImage ? (
            <Cropper
              src={bufferImage}
              style={{ height: 600, width: '100%' }}
              // Cropper.js options
              aspectRatio={2 / 1}
              ref={imageRef}
              {...cropperProps}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          ) : null}
          {cropperProps?.zoomable === false ? null : (
            <Space>
              <Button
                size="middle"
                type="primary"
                onClick={handleRotateLeft}
                icon={<RotateLeftOutlined rev={undefined} />}
              />
              <Button
                size="middle"
                type="primary"
                onClick={handleRotateRight}
                icon={<RotateRightOutlined rev={undefined} />}
              />
              <Button
                size="middle"
                type="primary"
                onClick={handleZoomOut}
                icon={<ZoomOutOutlined rev={undefined} />}
              />
              <Button
                size="middle"
                type="primary"
                onClick={handleZoomIn}
                icon={<ZoomInOutlined rev={undefined} />}
              />
              <Button
                size="middle"
                type="primary"
                onClick={handleResetCropping}
                icon={<ReloadOutlined rev={undefined} />}
              />
            </Space>
          )}
        </Flex>
      </Modal>
      <ImageUploader
        imageOrCanvas={imageOrCanvas}
        customRequest={customRequestHandler}
        handleChange={onUploadChangeHandler}
        remove={handleRemoveImage}
        width={width}
        height={height}
        className="eventImage"
        formItemProps={formItemProps}
        uploadText={uploadText}
        accept="image/png, image/jpeg"
        beforeUpload={beforeUpload}
        listType={listType}
        sideContent={sideContent}
        size={size}
      />
      {/* Add your JSX code for the ImageCropper component here */}
    </>
  );
};

export default ImageCropper;

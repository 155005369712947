import {
  AdyenSystem,
  BraintreeSystem,
  Language,
  PaymentSystem,
  SeatersSystem,
} from '@seaters-app/constants';
import {
  useCreatePaymentSystem,
  useFetchLanguages,
  useUpdatePaymentSystem,
} from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import { useIsMutating } from '@tanstack/react-query';
import {
  Drawer,
  Space,
  Form,
  Row,
  Col,
  Input,
  Select,
  DrawerProps,
  Typography,
  Flex,
  Segmented,
  Checkbox,
} from 'antd';
import { t } from 'i18next';
import React, { useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface FormDrawerProps extends DrawerProps {
  paymentSystem?: PaymentSystem | null;
}

function isSeaters(form: PaymentSystem): form is SeatersSystem {
  return form.type === 'SEATERS';
}

function isAdyen(form: PaymentSystem): form is AdyenSystem {
  return form.type === 'ADYEN';
}

const types: PaymentSystem['type'][] = [
  'ADYEN',
  'BRAINTREE',
  'SEATERS',
  'STRIPE',
];

const options = types.map((type) => ({ label: type, value: type }));

const BraintreeForm: React.FC = () => {
  const { control } = useFormContext<BraintreeSystem>();

  return (
    <Flex vertical gap="middle">
      <Text strong>Braintree</Text>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="configuration.3dsEnabled">
            <Controller
              control={control}
              name="configuration.3dsEnabled"
              render={({ field }) => (
                <Checkbox {...field} checked={Boolean(field.value)}>
                  {t('admin_payment-system_braintree_3ds_enabled')}
                </Checkbox>
              )}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.environment"
            label={t('admin_payment-system_braintree_environment')}
          >
            <Controller
              control={control}
              name="configuration.environment"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.merchantId"
            label={t('admin_payment-system_braintree_merchant_id')}
          >
            <Controller
              control={control}
              name="configuration.merchantId"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.subMerchantId_EUR"
            label={t('admin_payment-system_braintree_submerchant_id_eur')}
          >
            <Controller
              control={control}
              name="configuration.subMerchantId_EUR"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.privateKey"
            label={t('admin_payment-system_braintree_private_key')}
          >
            <Controller
              control={control}
              name="configuration.privateKey"
              render={({ field }) => (
                <Input.Password visibilityToggle={false} {...field} />
              )}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.publicKey"
            label={t('admin_payment-system_braintree_public_key')}
          >
            <Controller
              control={control}
              name="configuration.publicKey"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
    </Flex>
  );
};

const AdydenForm: React.FC = () => {
  const { control } = useFormContext<AdyenSystem>();

  return (
    <Flex vertical gap="middle">
      <Text strong>Adyen</Text>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="configuration.apiUrl"
            label={t('admin_payment-system_adyen_api_url')}
          >
            <Controller
              control={control}
              name="configuration.apiUrl"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.hmacKey"
            label={t('admin_payment-system_adyen_hmac_key')}
          >
            <Controller
              control={control}
              name="configuration.hmacKey"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.mobileHmacKey"
            label={t('admin_payment-system_adyen_mobile_hmac_key')}
          >
            <Controller
              control={control}
              name="configuration.mobileHmacKey"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.hppEndpoint"
            label={t('admin_payment-system_adyen_hpp_endpoint')}
          >
            <Controller
              control={control}
              name="configuration.hppEndpoint"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.mobileHppEndpoint"
            label={t('admin_payment-system_adyen_mobile_hpp_endpoint')}
          >
            <Controller
              control={control}
              name="configuration.mobileHppEndpoint"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.merchantAccount"
            label={t('admin_payment-system_adyen_merchant_account')}
          >
            <Controller
              control={control}
              name="configuration.merchantAccount"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.skinCode"
            label={t('admin_payment-system_adyen_skin_code')}
          >
            <Controller
              control={control}
              name="configuration.skinCode"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.mobileSkinCode"
            label={t('admin_payment-system_adyen_mobile_skin_code')}
          >
            <Controller
              control={control}
              name="configuration.mobileSkinCode"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.validityDuration"
            label={t('admin_payment-system_adyen_validity_duration')}
          >
            <Controller
              control={control}
              name="configuration.validityDuration"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.wsUser"
            label={t('admin_payment-system_adyen_ws_user')}
          >
            <Controller
              control={control}
              name="configuration.wsUser"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="configuration.wsPassword"
            label={t('admin_payment-system_adyen_ws_password')}
          >
            <Controller
              control={control}
              name="configuration.wsPassword"
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
    </Flex>
  );
};

// TODO - Add the correct type for the form and replace stringified type with the correct type
const SeatersForm: React.FC<{ language: Language }> = ({ language }) => {
  const { control } = useFormContext<SeatersSystem>();

  return (
    <Flex vertical gap="middle">
      <Text strong>Seaters</Text>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={`configuration.virtualTitle.${language}`}
            label={t('admin_payment-system_virtual_title')}
          >
            <Controller
              control={control}
              name={`configuration.virtualTitle.${language}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={`configuration.virtualDesc.${language}`}
            label={t('admin_payment-system_virtual_desc')}
          >
            <Controller
              control={control}
              name={`configuration.virtualDesc.${language}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="bottom">
        <Col span={12}>
          <Form.Item
            name={`configuration.virtualTcLink.${language}`}
            label={t('admin_payment-system_virtual_tc_link')}
          >
            <Controller
              control={control}
              name={`configuration.virtualTcLink.${language}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
    </Flex>
  );
};

const SubForm: React.FC<{ form: PaymentSystem; language: Language }> = ({
  form,
  language,
}) => {
  if (isSeaters(form)) {
    return <SeatersForm language={language} />;
  } else if (isAdyen(form)) {
    return <AdydenForm />;
  } else if (form.type === 'BRAINTREE') {
    return <BraintreeForm />;
  }
  return null;
};

const FormDrawer: React.FC<FormDrawerProps> = ({
  onClose,
  paymentSystem,
  ...props
}) => {
  const isEdit = !!paymentSystem;
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);

  const { data } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const languages = data
    ? data?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  const { mutate: createPaymentSystem } = useCreatePaymentSystem();
  const { mutate: updatePaymentSystem } = useUpdatePaymentSystem(
    paymentSystem?.id || ''
  );

  const isMutating = !!useIsMutating();

  // TODO fix dirty hack
  const seatersConfig = {
    virtualTitle: paymentSystem?.configuration?.virtualTitle
      ? JSON.parse(paymentSystem?.configuration?.virtualTitle)
      : {},
    virtualDesc: paymentSystem?.configuration?.virtualDesc
      ? JSON.parse(paymentSystem?.configuration?.virtualDesc)
      : {},
    virtualTcLink: paymentSystem?.configuration?.virtualTcLink
      ? JSON.parse(paymentSystem?.configuration?.virtualTcLink)
      : {},
  };

  const config =
    paymentSystem?.type === 'SEATERS'
      ? seatersConfig
      : paymentSystem?.configuration;

  const defaultValues = (paymentSystem
    ? { ...paymentSystem, configuration: config }
    : null) || {
    type: 'SEATERS',
  };

  const methods = useForm<PaymentSystem>({
    mode: 'onBlur',
    defaultValues: defaultValues,
    values: defaultValues,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (
    data: PaymentSystem,
    e: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    const { configuration, ...rest } = data;

    const onSuccessfulSubmit = () => {
      if (onClose)
        onClose(
          e as
            | React.MouseEvent<Element, MouseEvent>
            | React.KeyboardEvent<Element>
        );
      reset();
    };

    if (rest.type === 'SEATERS') {
      Object.keys(configuration).map(
        (key: string) =>
          (configuration[key] = JSON.stringify(configuration[key]))
      );
    }

    const system = {
      ...rest,
      configuration: configuration,
    };

    if (rest.type === 'STRIPE') {
      delete system.configuration;
    }

    if (isEdit) {
      updatePaymentSystem(system, {
        onSuccess: onSuccessfulSubmit,
      });
    } else {
      createPaymentSystem(system, {
        onSuccess: onSuccessfulSubmit,
      });
    }
  };

  const isSubmittingForm = isSubmitting || isMutating;

  const type = useWatch({
    control,
    name: 'type',
  });

  const isSeaters = type === 'SEATERS';
  const isStripe = type === 'STRIPE';

  return (
    <Drawer
      title={
        isEdit ? t('admin_tabs_details') : t('admin_payment-system_create')
      }
      width={720}
      onClose={onClose}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      footer={
        isSeaters ? (
          <Segmented
            size="middle"
            options={languages}
            value={currentLanguage}
            onChange={setCurrentLanguage}
          />
        ) : null
      }
      extra={
        isSeaters || isStripe ? (
          <Space>
            <Button onClick={onClose} disabled={isSubmittingForm}>
              {t('general_cancel')}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              type="primary"
              loading={isSubmittingForm}
            >
              {t('general_submit')}
            </Button>
          </Space>
        ) : null
      }
      {...props}
    >
      <FormProvider {...methods}>
        <Form
          name="basic"
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          disabled={(!isSeaters && !isStripe) || isSubmittingForm}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label={t('general_name')}>
                <Controller
                  control={control}
                  name={'name'}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type" label={t('general_type')}>
                <Controller
                  control={control}
                  name={'type'}
                  disabled={!isSeaters && !isStripe}
                  render={({ field }) => (
                    <Select {...field}>
                      {options.map((option) => (
                        <Select.Option
                          key={option.value}
                          value={option.value}
                          disabled={
                            option.value !== 'SEATERS' &&
                            option.value !== 'STRIPE'
                          }
                        >
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <SubForm form={methods.watch()} language={currentLanguage} />
        </Form>
      </FormProvider>
    </Drawer>
  );
};

export default FormDrawer;

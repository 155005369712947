import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Segmented,
  Select,
  Space,
  TablePaginationConfig,
  Typography,
} from 'antd';
import {
  BarsOutlined,
  LayoutOutlined,
  PlusOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import {
  queryClient,
  useFanGroupStore,
  useFetchFGOFanGroup,
} from '@seaters-app/data-access';
import {
  SESSION_STORAGE_TARGET_APP,
  TranslationsTarget,
  routes,
} from '@seaters-app/constants';
import { useTranslation } from 'react-i18next';
import { EmptyState, Container, Button } from '@seaters-app/ui';
import WishListCard from './components/WishListCard';
import WishListCardSkeleton from './components/WishListCardSkeleton';
import { useWishLists } from './hooks/useWishLists';
import Banner from '../waiting-lists/components/Banner/Banner';

import styles from './waitingLists.module.css';
import { MatrixView } from './MatrixView';
import { SegmentedValue } from 'antd/es/segmented';
import { useWLMatrixStore } from './storage/wl-matrix-store';
import debounce from 'lodash/debounce';
import { useAppTranslationsChange } from '../../utils';
const { Text } = Typography;

export function WishLists() {
  const navigate = useNavigate();
  const { fanGroup } = useFanGroupStore();

  const [dataViewMode, setIsMatrixView] = useState<SegmentedValue>(
    localStorage.getItem('list_template') ?? 'list'
  );

  const { setIsSettingsModalOpen } = useWLMatrixStore();

  const { t } = useTranslation();
  const { data: fanGroupData } = useFetchFGOFanGroup(fanGroup?.id || '');
  const {
    wishLists,
    wishListsLoading,
    itemOffset,
    maxPageSize,
    status,
    statusOptions,
    setQuery,
    query,
  } = useWishLists({ fanGroupId: fanGroup?.id });

  const { changeTranslations } = useAppTranslationsChange();

  const setCurrentTarget = (target: TranslationsTarget) => {
    changeTranslations(SESSION_STORAGE_TARGET_APP, target);
  };
  const handleInterfaceSwitch = () => {
    queryClient.clear();

    setCurrentTarget(TranslationsTarget.WEB);
    navigate(`/${fanGroup?.slug}`);
  };

  const statusOptionsTranslated = statusOptions.map((status) => {
    const newSt = { label: t(status.label), value: status.value };
    return newSt;
  });

  const currentPage = itemOffset ? itemOffset / maxPageSize + 1 : 1;

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (_, pageSize) =>
    setQuery({ itemOffset: 0, maxPageSize: pageSize });

  const handleChangePage = (newPage: number, pageSize: number) =>
    setQuery({
      itemOffset: pageSize !== maxPageSize ? 0 : (newPage - 1) * maxPageSize,
    });

  const handleStatusChange = (value: string[]) =>
    setQuery({ status: value, itemOffset: 0 });

  const handleDataViewMode = (type: SegmentedValue) => {
    setIsMatrixView(type);

    localStorage.setItem('list_template', type as string);
  };

  const handleTableChange = ({ current, pageSize }: TablePaginationConfig) => {
    setQuery({
      itemOffset: (Number(current) - 1) * Number(pageSize),
      maxPageSize: pageSize,
    });
  };

  const handleDebouncedSearch = debounce((e) => {
    setQuery({ search: e.target.value, itemOffset: 0 });
  }, 500);

  return (
    <div className={styles.waitingLists}>
      <Banner
        image={fanGroupData?.coverImageUrl ?? ''}
        isLoading={!fanGroupData}
      />
      <Container>
        <div className={styles.waitingLists}>
          <Space className={styles.filtersWrap}>
            <Space size={24}>
              <Input
                defaultValue={query.search}
                placeholder={t('event_venue_search-btn')}
                onChange={handleDebouncedSearch}
              />
              <Select
                mode="multiple"
                allowClear
                placeholder={t('please_select_placeholder_text')}
                value={status as string[]}
                onChange={handleStatusChange}
                options={statusOptionsTranslated}
                disabled={wishListsLoading}
                style={{ width: '100%', minWidth: 200 }}
              />
              <Space>
                <Segmented
                  value={dataViewMode}
                  onChange={handleDataViewMode}
                  options={[
                    {
                      value: 'list',
                      icon: <BarsOutlined rev={undefined} />,
                    },
                    {
                      value: 'matrix',
                      icon: <LayoutOutlined rev={undefined} />,
                    },
                  ]}
                />
                {dataViewMode === 'matrix' && (
                  <Button onClick={() => setIsSettingsModalOpen(true)}>
                    Settings
                  </Button>
                )}
              </Space>
            </Space>
            <Space size={24}>
              <Button
                type="text"
                onClick={handleInterfaceSwitch}
                icon={<LogoutOutlined rev={undefined} />}
              >
                {`${t('main_topbar_go-to-btn')} ${t('admin_fan-group')}`}
              </Button>
              <Button
                data-testid="createWishListButton"
                type="primary"
                icon={<PlusOutlined rev={undefined} />}
                onClick={() => navigate(routes.create)}
              >
                {t('wl_create_button_text')}
              </Button>
            </Space>
          </Space>
          <Space style={{ marginBottom: 12 }}>
            <Text>
              {wishLists?.totalSize} {t('wl_title')}
            </Text>
          </Space>

          {dataViewMode === 'matrix' ? (
            <MatrixView
              items={wishLists?.items}
              pagination={{
                showSizeChanger: true,
                onShowSizeChange,
                current: currentPage,
                pageSize: maxPageSize,
                total: wishLists?.totalSize,
              }}
              handleTableChange={handleTableChange}
              setQuery={setQuery}
              query={query}
            />
          ) : wishListsLoading ? (
            [...Array(10)].map((el) => <WishListCardSkeleton />)
          ) : wishLists?.items ? (
            wishLists?.items.map((event) => {
              return <WishListCard wishList={event} />;
            })
          ) : (
            <EmptyState />
          )}
        </div>

        {!!wishLists?.totalSize && dataViewMode !== 'matrix' && (
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={currentPage}
            current={currentPage}
            total={wishLists?.totalSize}
            onChange={handleChangePage}
            pageSize={maxPageSize}
          />
        )}
      </Container>
    </div>
  );
}

import { waitingListsKeys } from '@seaters-app/constants';
import {
  fetchWishListProtectionCodes,
  useFetchWishListProtectionCodes,
} from '@seaters-app/data-access';
import { useQueryClient } from '@tanstack/react-query';
import { TablePaginationConfig } from 'antd/es/table';
import { usePagination } from '../../../../utils/hooks';

import { useEffect } from 'react';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

export const useWLCodes = (wlId: string) => {
  const [query, setQuery] = useQueryParams({
    size: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      size: pageSize,
      page: current,
    });
  };

  const queryClient = useQueryClient();

  const params = {
    maxPageSize: query.size,
    itemOffset: query.size * (query.page - 1),
  };

  useEffect(() => {
    if (wlId) {
      queryClient.prefetchQuery(
        [waitingListsKeys.codesList(wlId, params)],
        () => fetchWishListProtectionCodes(wlId, params)
      );
    }
  }, [queryClient, query]);

  const { data, isLoading } = useFetchWishListProtectionCodes(wlId, params);

  const pagination = usePagination({
    pageSize: query.size,
    current: query.page,
    totalSize: data?.totalSize,
  });

  return {
    handleTableChange,
    pagination,
    data,
    isLoading,
    setQuery,
    query,
  };
};

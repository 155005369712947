import { CreateSurveyQuestionFormValidator } from '@seaters-app/constants';

export const prepareValues = (values: CreateSurveyQuestionFormValidator) => {
  const {
    type,
    text,
    answerSemanticId,
    answerLabel,
    questionChoices,
    structure,
    rangeAnswerMax,
    rangeAnswerMin,
    rangeAnswerMinText,
    rangeAnswerMaxText,
  } = values;

  const question = {
    type,
    text,
    answerSemanticId,
    answerLabel,
    structure: [],
  };

  const choices = questionChoices?.map((choice, i) => {
    return {
      order: i + 1,
      offeredAnswerTranslation: choice.offeredAnswerTranslation,
    };
  });

  const rangeQuestionChoices = [];

  if (
    rangeAnswerMax &&
    rangeAnswerMin !== undefined &&
    rangeAnswerMin !== null
  ) {
    for (let i = rangeAnswerMin; i <= rangeAnswerMax; i++) {
      rangeQuestionChoices.push({
        order: rangeAnswerMin === 0 ? i + 1 : i,
        offeredAnswerTranslation: { en: i.toString() },
      });
    }

    if (rangeAnswerMinText && rangeAnswerMaxText) {
      rangeQuestionChoices[0].offeredAnswerTranslation = {
        en: `${rangeAnswerMin} - ${rangeAnswerMinText?.en}`,
        fr: `${rangeAnswerMin} - ${rangeAnswerMinText?.fr}`,
        nl: `${rangeAnswerMin} - ${rangeAnswerMinText?.nl}`,
      };
      rangeQuestionChoices[
        rangeQuestionChoices.length - 1
      ].offeredAnswerTranslation = {
        en: `${rangeAnswerMax} - ${rangeAnswerMaxText?.en}`,
        fr: `${rangeAnswerMax} - ${rangeAnswerMaxText?.fr}`,
        nl: `${rangeAnswerMax} - ${rangeAnswerMaxText?.nl}`,
      };
    }
  }

  const structured = structure?.map((field) => {
    return { ...field, mandatory: !!field.mandatory };
  });

  return {
    question,
    choices,
    rangeQuestionChoices,
    structureFields: structured,
  };
};

import { PaginationQuery } from './pagination.query';

export enum SearchType {
  emailFragment = 'emailFragment',
  lastNameFragment = 'lastNameFragment',
  phoneFragment = 'phoneFragment',
}

export interface UsersQuery extends PaginationQuery {
  query?: string;
  searchType?: SearchType;
}

import {
  BadgeEntity,
  BadgeValidator,
  BadgeFGOListEntity,
  endpoints,
  ListEntityV2,
  PaginationQueryV2,
  seatersAdminPrefix,
  V2Prefix,
  BadgeInstance,
  BadgesQuery,
  FanGroupsBadgesListEntity,
  CategoryQuery,
  CategoryEntity,
  BadgeEventEntity,
  WishListBadgeInstanceValidator,
  WLBadgesState,
  BadgesSearchedQuery,
} from '@seaters-app/constants';

import { api } from '../axios-instance';

export const fetchAdminBadges = (
  params: BadgesQuery
): Promise<ListEntityV2<BadgeEntity>> =>
  api.get(`${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}`, { params });

export const fetchAdminSearchedBadges = (
  params: BadgesSearchedQuery
): Promise<ListEntityV2<BadgeEntity>> =>
  api.get(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}/${endpoints.search}`,
    { params }
  );

export const fetchBadgesCategories = (
  params: CategoryQuery
): Promise<ListEntityV2<CategoryEntity>> =>
  api.get(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}/${endpoints.category}`,
    { params }
  );

export const fetchFanGroupBadgeLinks = (
  params: PaginationQueryV2
): Promise<ListEntityV2<FanGroupsBadgesListEntity>> =>
  api.get(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}/${endpoints.groups}`,
    { params }
  );

export const fetchFGOBadges = (
  fanGroupId: string,
  params: PaginationQueryV2
): Promise<ListEntityV2<BadgeFGOListEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.badges}`,
    { params }
  );

export const fetchWishListBadges = (
  wishListId: string,
  params: PaginationQueryV2
): Promise<ListEntityV2<BadgeInstance>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${wishListId}/${endpoints.badges}`,
    { params }
  );

export const fetchWishListBadgesState = (
  wlId: string
): Promise<WLBadgesState> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.waitingLists}/${wlId}/${endpoints.badges}`
  );

export const fetchBadgeData = (badgeId: string): Promise<BadgeEntity> =>
  api.get(`${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}/${badgeId}`);

export const fetchUserBadgesEvents = (
  groupId: string,
  userId: string,
  params: PaginationQueryV2
): Promise<ListEntityV2<BadgeEventEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${groupId}/${endpoints.badges}/${endpoints.usersFGO}/${userId}`
  );

export const updateBadge = (
  badgeId: string,
  body: BadgeValidator
): Promise<BadgeEntity> => {
  return api.put(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}/${badgeId}`,
    body
  );
};

export const createBadge = (body: BadgeValidator): Promise<BadgeEntity> => {
  return api.post(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}`,
    body
  );
};

export const createFanGroupBadgeLink = (
  badgeId: string,
  fanGroupId: string
): Promise<FanGroupsBadgesListEntity> => {
  return api.post(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}/${badgeId}/${endpoints.groups}/${fanGroupId}`
  );
};

export const addBadgeToWishList = (
  wishListId: string,
  body: WishListBadgeInstanceValidator
): Promise<BadgeInstance> => {
  return api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${wishListId}/${endpoints.badges}`,
    body
  );
};

export const unarchiveBadgeForWishList = (
  wishListId: string,
  badgeId: string
): Promise<BadgeInstance> => {
  return api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${wishListId}/${endpoints.wlBadges}/${badgeId}`
  );
};

export const deleteBadgeFromWishList = (
  wishListId: string,
  badgeId: string
): Promise<BadgeInstance> => {
  return api.delete(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${wishListId}/${endpoints.wlBadges}/${badgeId}`
  );
};

// PUT https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/listId/wl-badges/wlBadgeId/schedule-activation/

export const scheduleBadgeActivation = (
  wishListId: string,
  badgeId: string,
  body: { date: string }
): Promise<any> => {
  return api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${wishListId}/${endpoints.wlBadges}/${badgeId}/${endpoints.scheduleActivation}`,
    body
  );
};

// PUT https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/listId/wl-badges/wlBadgeId/schedule-archiving/

export const scheduleBadgeArchiving = (
  wishListId: string,
  badgeId: string,
  body: { date: string }
): Promise<BadgeInstance> => {
  return api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${wishListId}/${endpoints.wlBadges}/${badgeId}/${endpoints.scheduleArchiving}/`,
    body
  );
};

export const removeFanGroupBadgeLink = (
  fanGroupId: string,
  badgeId: string
) => {
  return api.delete(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}/${badgeId}/${endpoints.groups}/${fanGroupId}`
  );
};

export const archiveBadge = (badgeId: string) => {
  return api.delete(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.badges}/${badgeId}/`
  );
};

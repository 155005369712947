import { z } from 'zod';
import { UserRole, UserStatus } from './../enums/user';

export const SignupValidatorSchema = z.object({
  accessCode: z.string().optional(),
  email: z.string(),
  password: z.string().min(15),
  confirmPassword: z.string().min(15),
  firstName: z.string(),
  lastName: z.string(),
  status: z.nativeEnum(UserStatus),
  roles: z.array(z.nativeEnum(UserRole)),
  confirmationReturnURLPath: z.string().optional(),
});

export type SignupValidator = z.infer<typeof SignupValidatorSchema>;

export const SignupFormValidatorSchema = z
  .object({
    accessCode: z.string().optional(),
    email: z
      .string()
      .min(1, 'api_authentication_signup_email_required')
      .email('invalid_email_validation_message'),
    password: z
      .string()
      .min(15, 'api_authentication_signup_password_length')
      .regex(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{15,}$/,
        'profile_password_requirements_validation'
      ),
    confirmPassword: z.string(),
    firstName: z
      .string()
      .min(1, 'api_authentication_signup_firstname_required'),
    lastName: z.string().min(1, 'api_authentication_signup_lastname_required'),
    termsAndConditions: z.boolean().refine((value) => value === true, {
      message: 'general_label_required',
    }),
    allowDirectMarketingFromSeaters: z.boolean().optional(),
    allowDirectMarketingFromPartners: z.boolean().optional(),
    fanGroupRules: z.boolean().optional(),
  })
  .superRefine((values, ctx) => {
    if (values.password !== values.confirmPassword) {
      ctx.addIssue({
        message: 'confirm_password_validation_message'
          ? 'confirm_password_validation_message'
          : 'Should be equal to New password',
        code: z.ZodIssueCode.custom,
        path: ['confirmPassword'],
      });
    }
    if (values.fanGroupRules !== undefined && !values.fanGroupRules) {
      ctx.addIssue({
        message: 'general_label_required',
        code: z.ZodIssueCode.custom,
        path: ['fanGroupRules'],
      });
    }
  });

export type SignupFormValidator = z.infer<typeof SignupFormValidatorSchema>;

import { useWaitingLists } from './hooks/useWaitingLists';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './waitingLists.module.css';
import {
  Container,
  GalleryCardSkeleton,
  Spinner,
  EmptyState,
} from '@seaters-app/ui';
import WaitingListCard from '../waiting-lists/components/WaitingListCard/WaitingListCard';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { LookEntity, routes } from '@seaters-app/constants';
import { Flex } from 'antd';
import { queryClient } from '@seaters-app/data-access';

export function MyWaitingLists() {
  const { allItems, loadMoreData, hasNextPage, isLoading } = useWaitingLists();
  const { t } = useTranslation();

  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const myPreferencesViewEnabled =
    fanGroupData?.properties?.myPreferencesViewEnabled;

  return (
    <div className={styles.myWaitingLists}>
      <Container>
        <Flex justify="space-between">
          <Title level={2} style={{ marginBottom: 24 }}>
            {t('my-waitinglist_title')}
          </Title>
        </Flex>

        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={allItems?.length ?? 0}
          next={() => loadMoreData()}
          hasMore={!!hasNextPage}
          loader={<Spinner />}
          scrollableTarget="scrollableDiv"
        >
          {isLoading ? (
            <div className={styles.waitingListsContainer}>
              {[...Array(9)].map(() => (
                <GalleryCardSkeleton />
              ))}
            </div>
          ) : allItems.length ? (
            <div className={styles.waitingListsContainer}>
              {allItems?.map((item, index) => (
                <WaitingListCard
                  redirectPath={`../${routes.waitingList}/${item.waitingListId}`}
                  waitingList={item}
                  preference={myPreferencesViewEnabled ? index + 1 : null}
                />
              ))}
            </div>
          ) : (
            <EmptyState
              description={<p>{t('api_joined_waiting_lists_missing')}</p>}
            />
          )}
        </InfiniteScroll>
      </Container>
    </div>
  );
}

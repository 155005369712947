import { Space, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

export const NumberItem = ({
  label,
  value,
  tooltip,
  maxWidth = 'fit-content',
  isInverted = false,
  color,
}: {
  label: string;
  value: number;
  tooltip?: string;
  maxWidth?: number | string;
  isInverted?: boolean;
  color?: string;
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(tooltip)}>
      <Space size={6}>
        <Title
          level={4}
          style={{ margin: 0, color: color, whiteSpace: 'nowrap' }}
        >
          {value}
        </Title>
        <Text
          style={{
            maxWidth,
            fontSize: 12,
            lineHeight: 1.3,
            display: 'flex',
            wordBreak: 'unset',
            color: color,
          }}
          type={!isInverted ? 'secondary' : undefined}
        >
          {label}
        </Text>
      </Space>
    </Tooltip>
  );
};

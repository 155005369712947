import styles from './styles.module.css';
import { ConfigProvider, Flex, Image, Skeleton, Typography, theme } from 'antd';
import { Container } from '@seaters-app/ui';
import { WaitingListEntity } from '@seaters-app/constants';

function Banner({
  image,
  waitingList,
  isLoading = false,
}: {
  image?: string;
  waitingList?: WaitingListEntity;
  isLoading?: boolean;
}) {
  const { token } = theme.useToken();

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={styles.blurOverlay}>
        <Container>
          {isLoading ? (
            <Skeleton.Button
              active
              block
              style={{
                height: 300,
              }}
            />
          ) : (
            <ConfigProvider
              theme={{
                token: {
                  colorText: token.colorPrimaryBg,
                },
              }}
            >
              <Flex justify="center">
                <Image
                  src={image}
                  preview={false}
                  width="auto"
                  style={{ maxHeight: 280 }}
                />
              </Flex>
            </ConfigProvider>
          )}
        </Container>
      </div>
    </div>
  );
}

export default Banner;

// 280px max height

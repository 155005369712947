import { usePagination } from '../../../../utils';
import { useParams } from 'react-router-dom';
import { TablePaginationConfig } from 'antd';
import { useState } from 'react';
import { useFetchFGOWishListInvitations } from '@seaters-app/data-access';

export const useWishListInvitations = () => {
  const { wishListId } = useParams();

  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    current: 0,
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setPaginationParams({
      pageSize: pageSize || 10,
      current: current ? current - 1 : 0,
    });
  };

  const { data, isLoading, isFetching } = useFetchFGOWishListInvitations(
    wishListId || '',
    {
      page: paginationParams.current,
      size: paginationParams.pageSize,
    }
  );

  const pagination = usePagination({
    ...paginationParams,
    current: paginationParams.current + 1,
    totalSize: data?.totalElements,
  });

  return {
    seats: data,
    handleTableChange,
    pagination,
    isLoading,
    isFetching,
    queryParams: paginationParams,
    setPaginationParams: setPaginationParams,
  };
};

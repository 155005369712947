import { SurveyExtensionPoint } from '@seaters-app/constants';
export const surveyExtensionPoints = [
  {
    label: 'survey_management_extension_point_before_joining_waitinglist',
    value: SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST,
  },
  {
    label: 'survey_management_extension_point_before_payment',
    value: SurveyExtensionPoint.BEFORE_PAYMENT,
  },

  {
    label: 'survey_management_extension_point_after_registration',
    value: SurveyExtensionPoint.AFTER_REGISTRATION,
  },
  {
    label: 'survey_management_extension_point_at_checkout',
    value: SurveyExtensionPoint.AT_CHECKOUT,
  },
  {
    label: 'survey_management_extension_point_after_event',
    value: SurveyExtensionPoint.AFTER_EVENT,
  },
];

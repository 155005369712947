import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

export const FanCustomInfoValidationSchema = z.object({
  fanCustomInfos: z
    .array(
      z.object({
        informationValue: z.union([
          z.string(),
          z.instanceof(dayjs as unknown as typeof Dayjs),
        ]),
        semanticName: z.string(),
      })
    )
    .optional(),
});

export type FanCustomInfoValidation = z.infer<
  typeof FanCustomInfoValidationSchema
>;

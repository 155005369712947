import { fanGroupsKeys } from '@seaters-app/constants';
import {
  fetchFanGroupProtectionCodesEnhanced,
  fetchFanGroupProtectionCodesEnhancedAsFGO,
  useFetchFanGroupProtectionCodesEnhanced,
  useFetchFanGroupProtectionCodesEnhancedAsFGO,
} from '@seaters-app/data-access';
import { useQueryClient } from '@tanstack/react-query';
import { TablePaginationConfig } from 'antd/es/table';
import { usePagination } from '../../../../utils/hooks';

import { useEffect } from 'react';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

export const useFanGroupProtectionCodes = (
  fanGroupId: string,
  isConfiguration: boolean
) => {
  const [query, setQuery] = useQueryParams({
    size: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
    query: withDefault(StringParam, ''),
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      size: pageSize,
      page: current,
      query: query.query,
    });
  };

  const queryClient = useQueryClient();

  const params = {
    maxPageSize: query.size,
    itemOffset: query.size * (query.page - 1),
    query: query.query,
  };

  const fetchFunction = isConfiguration
    ? fetchFanGroupProtectionCodesEnhancedAsFGO
    : fetchFanGroupProtectionCodesEnhanced;

  const fetchHook = isConfiguration
    ? useFetchFanGroupProtectionCodesEnhancedAsFGO
    : useFetchFanGroupProtectionCodesEnhanced;

  useEffect(() => {
    if (fanGroupId) {
      queryClient.prefetchQuery(
        [fanGroupsKeys.enhancedCodesList(fanGroupId, params)],
        () => fetchFunction(fanGroupId, params)
      );
    }
  }, [queryClient, query]);

  const { data, isLoading } = fetchHook(fanGroupId, params);

  const pagination = usePagination({
    pageSize: query.size,
    current: query.page,
    totalSize: data?.totalElements,
  });

  return {
    handleTableChange,
    pagination,
    data,
    isLoading,
    setQuery,
    query,
  };
};

import { InvitationEntity } from '@seaters-app/constants';
import { useAcceptWaitingListInvitation } from '@seaters-app/data-access';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

export const useAcceptInvitation = (
  waitingListId: string
): ((invitationId: string) => Promise<InvitationEntity>) => {
  const { t } = useTranslation();

  const { mutateAsync: acceptWaitingListInvitation } =
    useAcceptWaitingListInvitation(waitingListId);

  const handleAcceptInvitation = (invitationId: string) =>
    acceptWaitingListInvitation(
      { invitationId },
      {
        onSuccess: () => {
          notification.success({
            message: t('invitation_accept_notification_success'),
          });
        },
        onError: () => {
          notification.error({
            message: t('invitation_accept_notification_error'),
          });
        },
      }
    );

  return handleAcceptInvitation;
};

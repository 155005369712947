import { Grid } from 'antd';
import React, { ReactNode } from 'react';

const { useBreakpoint } = Grid;

const maxContainerWidth = 1280;

type ContainerProps = {
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
  children?: ReactNode;
  style?: React.CSSProperties;
};

export const Container: React.FC<ContainerProps> = ({
  paddingTop,
  paddingLeft,
  paddingRight,
  paddingBottom,
  children,
  style,
  ...props
}) => {
  const screens = useBreakpoint();
  const { xxl } = screens;
  if (!Object.keys(screens).length) return null;
  return (
    <div
      style={{
        maxWidth: xxl ? maxContainerWidth : '92%',
        width: xxl ? '100%' : '92%',
        position: 'relative',
        backgroundColor: 'transparent',
        padding: '0',
        paddingLeft: paddingLeft ?? '0',
        paddingRight: paddingRight ?? '0',
        paddingTop: paddingTop ?? '0',
        paddingBottom: paddingBottom ?? '0',
        margin: '0 auto',
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

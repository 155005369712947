import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import {
  QuestionItem,
  QuestionType,
  SurveyStatus,
  SurveyValidatorSchema,
} from '@seaters-app/constants';
import { Button, baseColor400 } from '@seaters-app/ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getOptions } from '../../SurveyDetails/getOptions';
import { Collapse, Empty, Space, notification } from 'antd';
import styles from '../createSurvey.module.css';
import {
  getSingleTranslation,
  useFetchAdminSurvey,
  useUpdateAdminSurvey,
} from '@seaters-app/data-access';
import AddNewQuestionModal from '../../components/AddNewQuestionModal/AddNewQuestionModal';
import QuestionModal from '../../components/QuestionForm/QuestionModal';
import QuestionForm from '../../components/QuestionForm/QuestionForm';
import { useCurrentStep } from '../useCurrentStep';
import AntLayout from 'antd/es/layout';

const { Footer: AntFooter } = AntLayout;

export function SurveyQuestions({ footer = true }: { footer: boolean }) {
  const { questionnaireId = '' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { prev, next } = useCurrentStep();
  const { data: survey } = useFetchAdminSurvey(questionnaireId);
  const { mutate: updateSurvey, isLoading: isUpdating } =
    useUpdateAdminSurvey(questionnaireId);

  const [newQuestionCreation, setNewQuestionCreationModal] =
    useState<boolean>(false);
  const [isQuestionModalOpen, setQuestionModalOpen] = useState(false);

  const [selectedQuestion, setSelectedQuestion] = useState<QuestionItem | null>(
    null
  );

  const [isCreateFromScratch, setCreateFromScratch] = useState<boolean>(false);

  // *** For Editing question in modal window:
  const [questionToEdit, setQuestionToEdit] = useState<QuestionItem | null>(
    null
  );

  const questionToEditAnswers = getOptions(questionToEdit?.question?.id ?? '');

  const isMultipleChoiceQuestion =
    questionToEdit?.question.type !== QuestionType.FREE_TEXT &&
    questionToEdit?.question.type !== QuestionType.STRUCTURED &&
    questionToEditAnswers;

  const isQuestionWithoutChoices =
    questionToEdit?.question.type === QuestionType.FREE_TEXT ||
    questionToEdit?.question.type === QuestionType.STRUCTURED;
  // ***

  const closeModal = () => {
    setNewQuestionCreationModal(false);
    setSelectedQuestion(null);
  };

  const handleUpdateSurvey = (question: QuestionItem) => {
    const preparedQuestions = survey?.surveyQuestions
      ? [...survey.surveyQuestions].map((question) => {
          return {
            mandatory: question.mandatory,
            enabled: question.enabled,
            questionId: question.question.id,
          };
        })
      : [];

    preparedQuestions.push({
      mandatory: question.mandatory,
      enabled: question.enabled,
      questionId: question.question.id,
    });

    const dataToSend = { ...survey, surveyQuestions: preparedQuestions };

    const parsed = SurveyValidatorSchema.safeParse(dataToSend);
    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    updateSurvey(
      { ...parsed.data, status: SurveyStatus.ACTIVE },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_survey_updated'),
          });
          closeModal();
          setCreateFromScratch(false);
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_survey_updated'),
          });
        },
      }
    );
  };

  const removeQuestion = (questionId: string) => {
    if (survey) {
      const preparedQuestions = [...survey.surveyQuestions].reduce(
        (
          acc: { mandatory: boolean; enabled: boolean; questionId: string }[],
          curr: QuestionItem
        ) => {
          if (curr.question.id !== questionId) {
            acc.push({
              mandatory: curr.mandatory,
              enabled: curr.enabled,
              questionId: curr.question.id,
            });
            return acc;
          } else {
            return acc;
          }
        },
        []
      );

      updateSurvey(
        {
          ...survey,
          surveyQuestions: [...preparedQuestions],
        },
        {
          onSuccess: async () => {
            notification.success({
              message: t('notification_success_question_removed'),
            });
            setQuestionToEdit(null);
          },
          onError: (err) => {
            console.error(err);
            notification.error({
              message: t('notification_error_question_removed'),
            });
          },
        }
      );
    }
  };

  return (
    <>
      {!!survey?.surveyQuestions.length && (
        <div style={{ width: '100%', textAlign: 'right' }}>
          <Button
            style={{ marginRight: 0, marginLeft: 'auto' }}
            size="middle"
            type="primary"
            icon={<PlusOutlined rev={undefined} />}
            onClick={() => setNewQuestionCreationModal(true)}
          >
            {t('add_question_button')}
          </Button>
        </div>
      )}
      <Space
        direction="vertical"
        style={{ width: '100%', paddingTop: 16 }}
        size={16}
      >
        {isCreateFromScratch && (
          <QuestionModal
            creation
            isQuestionModalOpen={isQuestionModalOpen}
            isCreateFromScratch={isCreateFromScratch}
            setCreateFromScratch={setCreateFromScratch}
            handleUpdateSurvey={handleUpdateSurvey}
            setQuestionModalOpen={setQuestionModalOpen}
            setQuestionToEdit={setQuestionToEdit}
          />
        )}
        {survey?.surveyQuestions.length ? (
          survey?.surveyQuestions.map((question) => {
            return (
              <Collapse
                items={[
                  {
                    key: question?.question?.id ?? 'newQuestion',
                    label: getSingleTranslation(question?.question?.text ?? []),
                    children: (
                      <QuestionForm
                        isCreateFromScratch={isCreateFromScratch}
                        question={question}
                        removeQuestion={removeQuestion}
                        isQuestionModalOpen={isQuestionModalOpen}
                        addToSurvey={handleUpdateSurvey}
                        setQuestionModalOpen={setQuestionModalOpen}
                        setQuestionToEdit={setQuestionToEdit}
                      />
                    ),
                  },
                ]}
              />
            );
          })
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={
              <span>
                {t('survey_draft_warning_first_part')} <br />
                {t('survey_draft_warning_second_part')}
              </span>
            }
          >
            <Button
              style={{ marginRight: 0, marginLeft: 'auto' }}
              size="middle"
              type="default"
              icon={<PlusOutlined rev={undefined} />}
              onClick={() => setNewQuestionCreationModal(true)}
            >
              {t('add_question_button')}
            </Button>
          </Empty>
        )}
      </Space>

      {!isCreateFromScratch &&
        questionToEdit &&
        (isMultipleChoiceQuestion || isQuestionWithoutChoices) && (
          <QuestionModal
            isQuestionModalOpen={isQuestionModalOpen}
            isCreateFromScratch={isCreateFromScratch}
            question={questionToEdit}
            answers={questionToEditAnswers}
            setCreateFromScratch={setCreateFromScratch}
            handleUpdateSurvey={handleUpdateSurvey}
            setQuestionModalOpen={setQuestionModalOpen}
            removeQuestion={removeQuestion}
            setQuestionToEdit={setQuestionToEdit}
          />
        )}
      <AddNewQuestionModal
        newQuestionCreation={newQuestionCreation}
        closeModal={closeModal}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        handleUpdateSurvey={handleUpdateSurvey}
        setCreateFromScratch={setCreateFromScratch}
        setNewQuestionCreationModal={setNewQuestionCreationModal}
        isCreateFromScratch={isCreateFromScratch}
        setQuestionModalOpen={setQuestionModalOpen}
      />
      {footer && (
        <AntFooter
          className={styles.footer}
          style={{
            borderTop: `1px solid ${baseColor400}`,
          }}
        >
          <>
            <Button
              size="large"
              type="default"
              icon={<LeftOutlined rev={undefined} />}
              onClick={prev}
            >
              {t('event_back-btn')}
            </Button>
            <Space size={12}>
              <Button
                disabled={!survey?.surveyQuestions.length}
                size="large"
                type="default"
                icon={<RightOutlined rev={undefined} />}
                onClick={next}
              >
                {t('link_survey_to_button')}
              </Button>
              <Button
                disabled={!survey?.surveyQuestions.length}
                size="large"
                type="primary"
                onClick={() => navigate(`..`)}
              >
                {t('general_finish-btn')}
              </Button>
            </Space>
          </>
        </AntFooter>
      )}
    </>
  );
}

export default SurveyQuestions;

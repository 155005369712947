import { Typography, notification, Spin, Flex, Breadcrumb } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { prepareValues } from '../helpers/prepareValues';
import {
  requestAdminOneTimeImageUpload,
  uploadFile,
  useAdminCreateVenueConfig,
  useCreateAdminVenue,
} from '@seaters-app/data-access';
import { VenueStatus, VenueValidatorSchema } from '@seaters-app/constants';
import { VenueFormAsAdmin } from '../VenueForm';
import { getInitialValues } from '../helpers/getInitialValues';
import { VenueFormType } from '../helpers/getInitialValues';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

type FormTranslationType = {
  [lang: string]: string;
};

export interface VenueCreateFormType {
  name: FormTranslationType;
  shortName: FormTranslationType;
  country: FormTranslationType;
  city: FormTranslationType;
  conditions: FormTranslationType;
  slug: string;
  timeZone: string;
  barCode: string;
  longitude: number;
  latitude: number;
  currencyCode: string;
  status: VenueStatus;
}

const initialValues = getInitialValues();

export function CreateVenue() {
  const { state = '' } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);

  const { mutate: createVenue } = useCreateAdminVenue();
  const { mutate: createVenueConfig } = useAdminCreateVenueConfig();

  const onSubmit = (values: VenueFormType) => {
    const preparedValues = prepareValues(values);

    const imageUrlOrCanvas = values?.imageUrl as string | HTMLCanvasElement;

    const parsed = VenueValidatorSchema.safeParse(preparedValues);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }
    setSpinning(true);
    createVenue(parsed.data, {
      onSuccess: async (response) => {
        notification.success({
          message: t('notification_success_venue_created'),
        });

        if (imageUrlOrCanvas && typeof imageUrlOrCanvas !== 'string') {
          setUploadingImage(true);

          await requestAdminOneTimeImageUpload({
            id: response.id,
            fileName: 'file.png',
            entityType: 'venues',
          }).then(async (requestOneTimeUploadResponse) => {
            if (requestOneTimeUploadResponse.token) {
              const formFile = new FormData();
              const blob = (await new Promise((resolve) =>
                imageUrlOrCanvas.toBlob(resolve)
              )) as Blob;
              formFile.append('file', blob);
              await uploadFile(
                formFile,
                requestOneTimeUploadResponse.token
              ).then(() => setUploadingImage(false));
            }
          });
        }

        await createVenueConfig(
          {
            venueId: response.id,
            body: { name: 'DEFAULT', categories: [] },
          },
          {
            onSuccess: () => {
              notification.success({
                message: t('notification_success_venue_config_created'),
              });

              navigate(`../${state ?? ''}`);
            },
            onError: ({ response }) => {
              notification.error({
                message: t('notification_error_venue_config_created'),
              });
            },
            onSettled: () => {
              setSpinning(false);
            },
          }
        );
      },
      onError: ({ response }) => {
        notification.error({
          message: t('notification_error_venue_created'),
        });
        setSpinning(false);
      },
    });
  };

  const loading = spinning || uploadingImage;

  return (
    <>
      <Spin tip={t('fg_creation_loader_tip')} fullscreen spinning={loading} />
      <Flex
        vertical
        style={{
          padding: '24px 24px 80px',
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`../${state ?? ''}`}>{t('admin_venues')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('cwl_event_create-btn')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Title level={3}>{t('create_venue_title')}</Title>
        <VenueFormAsAdmin
          initialValues={initialValues}
          saveChanges={onSubmit}
        />
      </Flex>
    </>
  );
}

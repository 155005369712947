import { GuestEntity } from '@seaters-app/constants';
import { useState } from 'react';

export const useEditContact = () => {
  const [isEditContactModal, setIsEditContactModal] = useState(false);
  const [guestToEdit, setGuestToEdit] = useState<GuestEntity>();

  const showEditModal = (guest: GuestEntity) => {
    setIsEditContactModal(true);
    setGuestToEdit(guest);
  };
  return {
    isEditContactModal,
    setIsEditContactModal,
    showEditModal,
    guestToEdit,
    setGuestToEdit,
  };
};

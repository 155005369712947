import { create } from 'zustand';

interface WLMatrixState {
  isSettingsModalOpen: boolean;
  setIsSettingsModalOpen: (isSettingsModalOpen: boolean) => void;
}

export const useWLMatrixStore = create<WLMatrixState>((set) => ({
  isSettingsModalOpen: false,
  setIsSettingsModalOpen: (isSettingsModalOpen: boolean) =>
    set(() => ({ isSettingsModalOpen })),
}));

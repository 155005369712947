import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Image,
  Spin,
  Upload,
  theme,
  UploadProps,
  Space,
  FormItemProps,
  Row,
  Form,
  Col,
} from 'antd';
import { Button, UploadButton, errorColor } from '@seaters-app/ui';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';

interface ImageUploaderProps {
  width: number;
  height: number;
  remove: () => void;
  className: string;
  isLoading?: boolean;
  handleChange: (info: UploadChangeParam<UploadFile>) => void;
  beforeUpload?: UploadProps['beforeUpload'];
  uploadText?: string | JSX.Element;
  accept?: UploadProps['accept'];
  formItemProps?: FormItemProps;
  imageOrCanvas: HTMLCanvasElement | string | null;
  customRequest: UploadProps['customRequest'];
  listType?: UploadProps['listType'];
  sideContent?: React.ReactNode;
  size: string;
}

export const ImageUploader = ({
  handleChange,
  remove,
  width,
  height,
  className,
  isLoading = false,
  beforeUpload,
  uploadText,
  accept,
  formItemProps,
  customRequest,
  imageOrCanvas,
  sideContent,
  listType,
  size,
}: ImageUploaderProps) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  return (
    <Row style={{ maxWidth: '100%' }} align="top">
      <Col
        xs={24}
        md={listType === 'picture-circle' ? 6 : 24}
        lg={listType === 'picture-circle' ? 6 : 24}
        xl={listType === 'picture-circle' ? 4 : 24}
        xxl={listType === 'picture-circle' ? 3 : 24}
      >
        <Spin wrapperClassName={styles.spin} spinning={isLoading}>
          <Space direction="vertical" size="small" style={{ maxWidth: '100%' }}>
            <div
              style={{
                width: listType === 'picture-circle' ? 102 : width,
                height: listType === 'picture-circle' ? 102 : 'unset',
                maxWidth: '100%',
                aspectRatio: width / height,
              }}
            >
              {imageOrCanvas ? (
                <Image
                  width={'100%'}
                  src={
                    imageOrCanvas && typeof imageOrCanvas !== 'string'
                      ? imageOrCanvas?.toDataURL()
                      : imageOrCanvas
                  }
                  style={{
                    borderRadius: listType === 'picture-circle' ? '50%' : 12,
                    maxWidth: '100%',
                  }}
                />
              ) : (
                <Upload
                  multiple={false}
                  customRequest={customRequest}
                  listType={listType || 'picture-card'}
                  onChange={handleChange}
                  className={styles.hiddenDrop}
                  beforeUpload={beforeUpload}
                  accept={accept}
                  style={{
                    borderRadius: listType === 'picture-circle' ? '50%' : 12,
                  }}
                  name={formItemProps?.name}
                >
                  <UploadButton
                    style={{
                      position: 'absolute',
                      left: listType === 'picture-circle' ? -1 : 0,
                      top: 0,
                      bottom: 0,
                      width: listType === 'picture-circle' ? 104 : width,
                      maxWidth: '100%',
                      borderRadius: listType === 'picture-circle' ? '50%' : 12,
                    }}
                    className={
                      listType === 'picture-circle' ? 'profileImage' : className
                    }
                    size={size}
                    isError={formItemProps?.validateStatus === 'error'}
                    uploadText={uploadText}
                  />
                  {formItemProps?.help && <> {formItemProps?.help}</>}
                </Upload>
              )}
            </div>
            {imageOrCanvas ? (
              <Button
                color={token.colorError}
                size="small"
                type="text"
                onClick={remove}
                icon={<DeleteOutlined rev={undefined} />}
              >
                {t('remove_image_button_text')}
              </Button>
            ) : null}
          </Space>
        </Spin>
      </Col>
      {sideContent && (
        <Col
          xs={16}
          md={16}
          lg={12}
          xl={8}
          xxl={6}
          className={styles.centeredCol}
        >
          <Form.Item label="hidden">
            <div className={styles.tips}>{sideContent}</div>
          </Form.Item>
        </Col>
      )}
      {formItemProps?.help && (
        <span style={{ color: errorColor }}> {formItemProps?.help}</span>
      )}
    </Row>
  );
};

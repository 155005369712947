import { SESSION_STORAGE_VERIFICATION } from '@seaters-app/constants';
import { refreshToken } from '../../../api/auth';

export const handleRefreshToken = () => {
  const sessionVerificationData = sessionStorage.getItem(
    SESSION_STORAGE_VERIFICATION
  );

  const token = sessionVerificationData
    ? JSON.parse(sessionVerificationData).token.value
    : null;

  if (token) refreshToken(token);
};

import {
  ChoiceEntity,
  FormattedValuesWithTranslations,
  QuestionFormType,
  QuestionItem,
  QuestionStructureType,
  QuestionType,
} from '@seaters-app/constants';
import { initialTranslations } from '../../../../../../utils';
import { convertTranslationToObject } from '../../../../../../utils/helpers/convertTranslations';

const defaultStructureFields = [
  {
    name: 'First name',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
  {
    name: 'Last name',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
  {
    name: 'Birthdate',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
  { name: 'Email', type: QuestionStructureType.FREE_TEXT, mandatory: false },
  {
    name: 'Phone number',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
  { name: 'Language', type: QuestionStructureType.FREE_TEXT, mandatory: false },
  { name: 'Address', type: QuestionStructureType.FREE_TEXT, mandatory: false },
  {
    name: 'ID number',
    type: QuestionStructureType.FREE_TEXT,
    mandatory: false,
  },
];

export const getInitialValues = (
  question?: QuestionItem,
  answers?: ChoiceEntity[],
  valuesWithTranslations?: FormattedValuesWithTranslations | null
): QuestionFormType => {
  let min = 0;
  let max = 5;
  let minText = initialTranslations;
  let maxText = initialTranslations;
  if (
    question &&
    answers?.length &&
    question.question.type === QuestionType.MULTIPLE_CHOICE_RANGE
  ) {
    min = Math.min(
      ...answers.map((ans) =>
        Number(ans.offeredAnswerTranslation[0].text.split('-')[0])
      )
    );
    max = Math.max(
      ...answers.map((ans) =>
        Number(ans.offeredAnswerTranslation[0].text.split('-')[0])
      )
    );

    minText = convertTranslationToObject(
      answers
        .find(
          (ans) =>
            ans.offeredAnswerTranslation[0].text.split('-')[0].trim() ===
            min.toString()
        )
        ?.offeredAnswerTranslation.map((item) => {
          return { ...item, text: item.text.split('-')[1]?.trim() };
        }) ?? []
    );
    maxText = convertTranslationToObject(
      answers
        .find(
          (ans) =>
            ans.offeredAnswerTranslation[0].text.split('-')[0].trim() ===
            max.toString()
        )
        ?.offeredAnswerTranslation.map((item) => {
          return { ...item, text: item.text.split('-')[1]?.trim() };
        }) ?? []
    );
  }

  return question
    ? answers?.length
      ? question.question.type === QuestionType.MULTIPLE_CHOICE_RANGE
        ? {
            text: valuesWithTranslations?.text ?? initialTranslations,
            type: question.question.type,
            answerSemanticId: question.question.answerSemanticId,
            rangeAnswerMin: min,
            rangeAnswerMax: max,
            rangeAnswerMinText: minText,
            rangeAnswerMaxText: maxText,
            answerLabel:
              valuesWithTranslations?.answerLabel ?? initialTranslations,
            questionChoices: answers.map((answer: ChoiceEntity) => {
              return {
                ...answer,
                offeredAnswerTranslation: convertTranslationToObject(
                  answer.offeredAnswerTranslation
                ),
              };
            }),
            structure: defaultStructureFields,
          }
        : {
            text: valuesWithTranslations?.text ?? initialTranslations,
            type: question.question.type,
            answerSemanticId: question.question.answerSemanticId,
            answerLabel:
              valuesWithTranslations?.answerLabel ?? initialTranslations,
            questionChoices: answers.map((answer: ChoiceEntity) => {
              return {
                ...answer,
                offeredAnswerTranslation: convertTranslationToObject(
                  answer.offeredAnswerTranslation
                ),
              };
            }),
            structure: defaultStructureFields,
          }
      : question.question.type === QuestionType.STRUCTURED
      ? {
          text: valuesWithTranslations?.text ?? initialTranslations,
          type: question.question.type,
          answerSemanticId: question.question.answerSemanticId,
          answerLabel:
            valuesWithTranslations?.answerLabel ?? initialTranslations,
          structure: question.question.structure,
        }
      : {
          text: valuesWithTranslations?.text ?? initialTranslations,
          type: QuestionType.FREE_TEXT,
          answerSemanticId: question.question.answerSemanticId,
          answerLabel:
            valuesWithTranslations?.answerLabel ?? initialTranslations,
          structure: defaultStructureFields,
        }
    : {
        text: initialTranslations,
        type: QuestionType.FREE_TEXT,
        answerSemanticId: '',
        answerLabel: initialTranslations,
        structure: defaultStructureFields,
        rangeAnswerMin: min,
        rangeAnswerMax: max,
        rangeAnswerMinText: minText,
        rangeAnswerMaxText: maxText,
      };
};

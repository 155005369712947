import {
  ArrowsAltOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  LoadingOutlined,
  ReloadOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  SEATERS_APP_BASE_URL,
  SESSION_STORAGE_JOB_ID,
  routes,
} from '@seaters-app/constants';
import { useJob, useUpdateJob } from '@seaters-app/data-access';
import { Button, alertColor, errorColor, successColor } from '@seaters-app/ui';

import { Card, Space, Typography, notification } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import JobSteps from './JobSteps';
const { Text } = Typography;

const getJobStatusIcon = (status: string | undefined) => {
  switch (status) {
    case 'pending':
    case 'running':
      return <LoadingOutlined style={{ color: alertColor }} rev={undefined} />;
    case 'failed':
    case 'cancelled':
      return <CloseOutlined style={{ color: errorColor }} rev={undefined} />;
    default:
      return <CheckOutlined style={{ color: successColor }} rev={undefined} />;
  }
};

function SupportActionsModal({
  setSupportModalClosed,
}: {
  setSupportModalClosed: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  const [jobId, setJobId] = useState<string | null>(
    sessionStorage.getItem(SESSION_STORAGE_JOB_ID) ?? null
  );
  const { data: job, refetch } = useJob(jobId || '');
  const { mutate: mutateJob } = useUpdateJob();

  const [isExpanded, setIsExpanded] = useState(true);

  const closeModal = () => {
    setSupportModalClosed(true);
  };

  const showModal = () => {
    setIsExpanded(true);
  };
  const hideModal = () => {
    setIsExpanded(false);
  };

  const handleJobAction = (action: 'moveNext' | 'cancel' | 'retryCurrent') => {
    if (job?.id)
      mutateJob(
        { jobId: job.id, action },
        {
          onSuccess: (data) => {},
          onError: ({ response }) => {
            if (response?.data.message === 'InvalidStatus') {
              notification.error({
                message: response.data.message,
                description: `${response.data.error} Status: ${job.status}`,
              });
            } else {
              notification.error({
                message: response?.data.message,
                description: response?.data.error,
              });
            }
          },
        }
      );
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Card
      title={
        <Space
          size={40}
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Space>
            <Text strong>
              {job?.status === 'pending'
                ? t(job?.nextStep)
                : t(job?.latestStep)}
            </Text>
            {getJobStatusIcon(job?.status)}
          </Space>
          <Space>
            {isExpanded ? (
              <DownOutlined onClick={hideModal} rev={undefined} />
            ) : (
              <UpOutlined rev={undefined} onClick={showModal} />
            )}
            <Link
              to={`${SEATERS_APP_BASE_URL}${routes.admin}/${routes.supportActions}`}
            >
              <ArrowsAltOutlined rev={undefined} />
            </Link>
            <CloseOutlined rev={undefined} onClick={closeModal} />
          </Space>
        </Space>
      }
      style={{
        position: 'fixed',
        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.3)',
        width: 'fit-content',
        bottom: 5,
        right: 5,
        height: isExpanded ? 'fit-content' : 50,
        zIndex: 100,
      }}
    >
      <Space direction="vertical" size={20} style={{ alignItems: 'center' }}>
        {!!job && <JobSteps job={job} />}
        <Space>
          {job && (
            <Space>
              <Button
                style={{ width: '100%' }}
                type="primary"
                onClick={() => handleJobAction('moveNext')}
                disabled={
                  !(job?.nextStep && job?.status === 'awaitingNextStep')
                }
              >
                {t('start_next_step_btn')}
              </Button>
              <Button
                style={{ width: '100%' }}
                icon={<ReloadOutlined rev={undefined} />}
                onClick={() => handleJobAction('retryCurrent')}
                disabled={
                  !['awaitingNextStep', 'cancelled', 'failed'].includes(
                    job.status
                  )
                }
              >
                {t('retry_current_step_btn')}
              </Button>
              <Button
                style={{ width: '100%' }}
                danger
                onClick={() => handleJobAction('cancel')}
                disabled={!['running', 'pending'].includes(job.status)}
              >
                {t('cancel_step_btn')}
              </Button>
            </Space>
          )}
        </Space>
      </Space>
    </Card>
  );
}

export default SupportActionsModal;

import {
  AxiosAPIError,
  CategoriesQuery,
  FanAttributeEntity,
  FanAttributesEntity,
  FanAttributesQuery,
  FanAttributesSearchQuery,
  InterestCategoryEntity,
  InterestEntity,
  InterestsQuery,
  ListEntityV2,
  fanAttributesKeys,
  interestsKeys,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  fetchFanAttributes,
  fetchInterestsCategories,
  fetchProfileInterests,
  likeInterest,
  linkFanAttributeByName,
  neutralInterest,
  searchFanAttributes,
  dislikeInterest,
  unlinkFanAttribute,
  linkFanAttribute,
} from '../api';

export const useFetchProfileInterests = (params: InterestsQuery) =>
  useQuery<ListEntityV2<InterestEntity>, Error>(
    interestsKeys.lists(),
    () => fetchProfileInterests(params),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchInterestCategories = (params: CategoriesQuery) =>
  useQuery<ListEntityV2<InterestCategoryEntity>, Error>(
    interestsKeys.categories(),
    () => fetchInterestsCategories(params),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchFanAttributes = (params: FanAttributesQuery) =>
  useQuery<ListEntityV2<FanAttributesEntity>, Error>(
    fanAttributesKeys.lists(),
    () => fetchFanAttributes(params),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useSearchFanAttributes = (params: FanAttributesSearchQuery) =>
  useQuery<Array<FanAttributeEntity>, Error>(
    fanAttributesKeys.searchKey(params.query),
    () => searchFanAttributes(params),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useLinkFanAttribute = (): UseMutationResult<
  FanAttributesEntity,
  AxiosAPIError<{ id: string }>,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: string }) => linkFanAttribute(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(fanAttributesKeys.lists());
    },
  });
};

export const useLinkFanAttributeByName = (): UseMutationResult<
  FanAttributesEntity,
  AxiosAPIError<{ name: string }>,
  { name: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name }: { name: string }) => linkFanAttributeByName(name),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanAttributesKeys.lists());
      },
    }
  );
};

export const useUnlinkFanAttribute = (): UseMutationResult<
  FanAttributesEntity,
  AxiosAPIError<{ id: string }>,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: string }) => unlinkFanAttribute(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(fanAttributesKeys.all());
    },
  });
};

export const useLikeInterest = (): UseMutationResult<
  InterestEntity,
  AxiosAPIError<{ id: string }>,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: string }) => likeInterest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(interestsKeys.lists());
    },
  });
};

export const useDislikeInterest = (): UseMutationResult<
  InterestEntity,
  AxiosAPIError<{ id: string }>,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: string }) => dislikeInterest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(interestsKeys.lists());
    },
  });
};

export const useSendNeutralInterest = (): UseMutationResult<
  InterestEntity,
  AxiosAPIError<{ id: string }>,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: string }) => neutralInterest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(interestsKeys.lists());
    },
  });
};

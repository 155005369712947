import {
  Container,
  EmptyState,
  WishListSummarySkeleton,
} from '@seaters-app/ui';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

import styles from './approvals.module.css';
import { useWishListsWithApprovals } from './hooks/useWishListsWithApprovals';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { WishListSummary } from '@seaters-app/ui-shared';
import { useWishListSummaryData } from './hooks/useWishListSummaryData';
const { Title } = Typography;

export function Approvals() {
  const { t } = useTranslation();

  const { allItems, loadMoreData, hasNextPage, isFetching } =
    useWishListsWithApprovals();

  const { getData } = useWishListSummaryData();

  return (
    <div className={styles.approvals}>
      {!isFetching || allItems.length ? (
        <Container>
          <Space style={{ width: '100%' }} direction="vertical">
            <Title level={2}>{t('menus_label_approvals')}</Title>
            {allItems.length ? (
              <InfiniteScroll
                style={{ overflow: 'visible' }}
                dataLength={allItems?.length ?? 0}
                next={() => loadMoreData()}
                hasMore={!!hasNextPage}
                loader={
                  <div>
                    {[...Array(2)].map(() => (
                      <WishListSummarySkeleton />
                    ))}
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <Space
                  className={styles.approvalsContainer}
                  direction="vertical"
                >
                  {allItems?.map((item) => (
                    <Link
                      to={item.waitinglist.waitingListId}
                      style={{ textDecoration: 'none' }}
                    >
                      <WishListSummary data={getData(item)} />
                    </Link>
                  ))}
                </Space>
              </InfiniteScroll>
            ) : (
              <EmptyState
                description={<p>{t('api_wishlists_with_approvals_missing')}</p>}
              />
            )}
          </Space>
        </Container>
      ) : (
        <Container>
          <div>
            {[...Array(9)].map(() => (
              <WishListSummarySkeleton />
            ))}
          </div>
        </Container>
      )}
    </div>
  );
}

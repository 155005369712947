import { useTranslation } from 'react-i18next';

import { CloseOutlined } from '@ant-design/icons';

import { InvitationEntity, InvitationForApprove } from '@seaters-app/constants';
import {
  useCancelWaitingListInvitation,
  useIsCustomFlow,
} from '@seaters-app/data-access';
import { Button, notification, Popconfirm } from 'antd';
import { useNavigate } from 'react-router-dom';

export interface ActionButtonType {
  waitingListId: string;
  invitation: InvitationEntity;
  invitationsCount?: number;
}

export function CancelButton({
  waitingListId,
  invitation,
  invitationsCount,
}: ActionButtonType) {
  const { t } = useTranslation();

  const isCustom = useIsCustomFlow();
  const navigate = useNavigate();

  const { mutateAsync: cancelWaitingListInvitation } =
    useCancelWaitingListInvitation(waitingListId);

  const handleCancelInvitation = (invitationId: string) =>
    cancelWaitingListInvitation(
      { invitationId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_invitation_cancel-success'),
          });

          if (isCustom || invitationsCount === 1) {
            navigate('..');
          }
        },
        onError: () => {
          notification.error({
            message: t('notification_invitation_cancel-error'),
          });
        },
      }
    );

  return (
    <Popconfirm
      title={t('popconfirm_invitation_cancel-title')}
      description={
        invitation?.status === InvitationForApprove.PENDING
          ? t('popconfirm_invitation_cancel-description')
          : invitation?.status === InvitationForApprove.APPROVED
          ? 'The request has been approved. Are you sure to cancel this invitation?'
          : ''
      }
      cancelText={t('general_no')}
      onConfirm={() => handleCancelInvitation(invitation.id)}
    >
      <Button type="link" icon={<CloseOutlined rev={undefined} />} danger>
        {t('general_cancel-btn')}
      </Button>
    </Popconfirm>
  );
}

import { z } from 'zod';
import { SeatStatus, WLPositionStatus } from '../../enums';

export const FanGroupMembersValidatorSchema = z.object({
  query: z.string(),
  statuses: z.nativeEnum(SeatStatus).optional(),
  positionStatus: z.nativeEnum(WLPositionStatus).optional(),
});

export type FanGroupMembersValidator = z.infer<
  typeof FanGroupMembersValidatorSchema
>;

export const CreateFGOGroupMemberValidatorSchema = z.object({
  numberOfSeats: z.number(),
  nullableOriginalRank: z.number().nullable(),
  nullableRank: z.number().nullable(),
});

export type CreateFGOGroupMemberValidator = z.infer<
  typeof CreateFGOGroupMemberValidatorSchema
>;

import {
  FanGroupGeneralFormType,
  FanGroupGeneralValidator,
  FormTranslationType,
  TranslationEntity,
} from '@seaters-app/constants';

// TODO move to utils
export const convertTranslation = (
  translation: FormTranslationType | undefined
): TranslationEntity[] => {
  if (translation) {
    return Object.keys(translation).map((locale) => ({
      lang: locale,
      text: translation[locale],
    }));
  } else {
    return [
      { lang: 'en', text: '' },
      { lang: 'de', text: '' },
      { lang: 'nl', text: '' },
      { lang: 'cn', text: '' },
      { lang: 'ru', text: '' },
      { lang: 'it', text: '' },
      { lang: 'fr', text: '' },
      { lang: 'ja', text: '' },
      { lang: 'es', text: '' },
    ];
  }
};

export const prepareValues = (
  values: FanGroupGeneralFormType
): FanGroupGeneralValidator => {
  const shortName = convertTranslation(values.shortName);
  const name = convertTranslation(values.name);
  const description = convertTranslation(values.description);
  const welcomeText = convertTranslation(values.welcomeText);
  const privacyPolicy = convertTranslation(values.privacyPolicy);
  const rulesLinks = convertTranslation(values.rulesLinks);
  const legalNotice = convertTranslation(values.legalNotice);
  const faqLinks = convertTranslation(values.faqLinks);
  const helpLinks = convertTranslation(values.helpLinks);
  const termsAndConditions = convertTranslation(values.termsAndConditions);
  const optInPlatformTexts = convertTranslation(values.optInPlatformTexts);
  const optInPartnersTexts = convertTranslation(values.optInPartnersTexts);
  const optInTermsTexts = convertTranslation(values.optInTermsTexts);
  const optInRulesTexts = convertTranslation(values.optInRulesTexts);
  const geoFilteringNotificationTitle = convertTranslation(
    values.geoFilteringNotificationTitle
  );
  const geoFilteringNotificationDescription = convertTranslation(
    values.geoFilteringNotificationDescription
  );

  return {
    ...values,
    shortName,
    name,
    description,
    welcomeText,
    privacyPolicy,
    rulesLinks,
    legalNotice,
    termsAndConditions,
    optInPlatformTexts,
    optInPartnersTexts,
    optInTermsTexts,
    optInRulesTexts,
    geoFilteringNotificationTitle,
    geoFilteringNotificationDescription,
    faqLinks,
    helpLinks,
  };
};

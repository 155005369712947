import { useFetchTicketingSystem } from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import TicketingSystemForm from './TicketingSystemForm';
import TicketPools from './TicketPools';
import { Breadcrumb, Flex } from 'antd';

const TicketingSystem: React.FC = () => {
  const { ticketingSystemId = '' } = useParams<{ ticketingSystemId: string }>();

  const { t } = useTranslation();

  const { data: ticketingSystem } = useFetchTicketingSystem(ticketingSystemId);

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'..'}>{t('admin_ticketing-systems')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{t('admin_tabs_details')}</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Title level={3}>{ticketingSystem?.name}</Title>
      {ticketingSystem && (
        <>
          <TicketingSystemForm ticketingSystem={ticketingSystem} />
          <TicketPools id={ticketingSystem.id} />
        </>
      )}
    </Flex>
  );
};

export default TicketingSystem;

import Modal from 'antd/es/modal/Modal';
import { fetchVenueConfigs, useFetchVenues } from '@seaters-app/data-access';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import List from 'antd/es/list';
import { formatLocation, usePagination } from '../../../../utils';
import { Button } from '@seaters-app/ui';
import { routes, VenueConfigEntity, VenueEntity } from '@seaters-app/constants';
import Table from 'antd/es/table';
import Column from 'antd/es/table/Column';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Search from 'antd/es/input/Search';

type SelectVenueType = {
  isVenuesModalOpen: boolean;
  setVenuesModalOpen: (isOpen: boolean) => void;
};

type ParamsType = {
  pageSize: number;
  current: number;
  query: string;
};

export function SelectVenue({
  isVenuesModalOpen,
  setVenuesModalOpen,
}: SelectVenueType) {
  const [params, setParams] = useState<ParamsType>({
    pageSize: 10,
    current: 1,
    query: '',
  });

  const { t } = useTranslation();
  const [isVenueConfigModalOpen, setVenueConfigModalOpen] = useState(false);

  const [selectedVenue, setSelectedVenue] = useState<VenueEntity | null>();

  const [venueConfigs, setVenueConfigs] = useState<VenueConfigEntity[]>();

  const { data: venues } = useFetchVenues({
    size: params.pageSize,
    page: params.current - 1,
    query: params.query,
  });

  const handleGetVenueConfigs = async () => {
    if (selectedVenue) {
      const response = await fetchVenueConfigs(selectedVenue.id, {
        itemOffset: 0,
        maxPageSize: 100,
      });

      setVenueConfigs(response.items);
    }
  };

  useEffect(() => {
    if (selectedVenue) {
      handleGetVenueConfigs();
    }
  }, [selectedVenue]);

  const pagination = usePagination({ ...params, totalSize: venues?.totalSize });

  const handleTableChange = (page: number, pageSize: number) => {
    setParams({
      ...params,
      pageSize,
      current: page,
    });
  };

  const { setValue } = useFormContext(); // retrieve all hook methods

  const onSearch = (value: string) => {
    setParams({ ...params, query: value, current: 1 });
  };

  return (
    <>
      <Modal
        title={t('select_venue_button_text')}
        centered
        open={isVenuesModalOpen}
        onOk={() => setVenuesModalOpen(false)}
        onCancel={() => setVenuesModalOpen(false)}
        width={1156}
      >
        <List
          pagination={{
            position: 'bottom',
            align: 'start',
            onChange: handleTableChange,
            ...pagination,
          }}
          dataSource={venues?.items}
          header={
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Search
                placeholder={t('event_venue_search-btn')}
                onSearch={onSearch}
                size="large"
                style={{ width: '30%' }}
              />
              <Button size="small">
                <Link to={`/${routes.admin}/${routes.venues}/${routes.create}`}>
                  {t('create_venue_title')}
                </Link>
              </Button>
            </div>
          }
          renderItem={(item) => {
            return (
              <List.Item
                key={item.id}
                actions={[
                  <Button
                    onClick={() => {
                      setVenueConfigModalOpen(true);
                      setSelectedVenue(item);
                    }}
                    size="small"
                  >
                    Select <ArrowRightOutlined rev={undefined} />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <img
                      width={160}
                      height={80}
                      alt="logo"
                      src={item.imageUrl}
                      style={{ borderRadius: 4 }}
                    />
                  }
                  title={item.name[0].text}
                  description={formatLocation([
                    item.city[0]?.text,
                    item.country[0]?.text,
                  ])}
                />
              </List.Item>
            );
          }}
        />
      </Modal>
      <Modal
        title={t('venue_config_select')}
        open={isVenueConfigModalOpen}
        // onOk={handleOk}
        onCancel={() => setVenueConfigModalOpen(false)}
      >
        <Table
          dataSource={venueConfigs}
          tableLayout="fixed"
          pagination={false}
          rowKey={({ id }) => id}
        >
          <Column title={t('admin_name')} dataIndex="name" key="name" />
          <Column
            title={t('event_venue_config_categories')}
            dataIndex="categories"
            key="roles"
            render={(roles: string[]) => <span>{roles.join(', ')}</span>}
          />
          <Column
            dataIndex="action"
            key="action"
            render={(_, venueConfig: VenueConfigEntity) => {
              return (
                <Button
                  onClick={() => {
                    setValue('venueConfigId', venueConfig.id);
                    setVenueConfigModalOpen(false);
                    setVenuesModalOpen(false);
                  }}
                >
                  Select <ArrowRightOutlined rev={undefined} />
                </Button>
              );
            }}
          />
        </Table>
      </Modal>
    </>
  );
}

import { create } from 'zustand';

type MutationCounter = {
  error: number;
  success: number;
};

interface ImportContactsState extends MutationCounter {
  reset: () => void;
  increaseError: () => void;
  increaseSuccess: () => void;
  errorGuests: string[];
  setErrorGuests: (email: string) => void;
}

export const useImportContactsStore = create<ImportContactsState>()((set) => ({
  error: 0,
  success: 0,
  errorGuests: [],
  reset: () => set(() => ({ error: 0, success: 0, errorGuests: [] })),
  setErrorGuests: (email: string) =>
    set((state) => ({ errorGuests: [...state.errorGuests, email] })),
  increaseError: () => set((state) => ({ error: state.error + 1 })),
  increaseSuccess: () => set((state) => ({ success: state.success + 1 })),
}));

import {
  BadgeForm,
  BadgeFormValues,
  FormTranslationType,
  Language,
  TranslationEntity,
  VenueFormValues,
} from '@seaters-app/constants';

const convertTranslation = (
  translation: FormTranslationType
): TranslationEntity[] => {
  return Object.keys(translation).map(
    (locale): TranslationEntity => ({
      lang: locale,
      text: translation[locale],
    })
  );
};

export const prepareValues = (values: BadgeForm): BadgeFormValues => {
  const name = convertTranslation(values.name);
  const description = convertTranslation(values.description);
  const displayedText = convertTranslation(values.displayedText);

  const hidden = !values.hidden;
  return {
    ...values,
    name,
    description,
    displayedText,
    hidden,
  };
};

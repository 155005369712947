import { z } from 'zod';

export const TranslationValidatorSchema = z.object({
  text: z.string(),
  lang: z.string(),
});

export type TranslationValidator = z.infer<typeof TranslationValidatorSchema>;

export const NotEmptyTranslationValidationSchema = z
  .array(TranslationValidatorSchema)
  .refine(
    (dataArray) => {
      // Check if at least  object with lang 'en' has non-empty text
      return dataArray.some(
        (data) => data.lang === 'en' && data.text.trim() !== ''
      );
    },
    {
      message: 'default translation (EN) for field must have non-empty text',
    }
  );

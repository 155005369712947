import {
  GuestWaitingList,
  guestKeys,
  LookEntity,
  SurveyQuery,
  ListEntityV2,
  SurveyInstanceEntity,
  AttendeesInfoValidator,
  SurveyAnswersValidator,
  SurveyAnswersResponse,
  AxiosAPIError,
  usersKeys,
  AcceptWaitingListValidator,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  acceptGuestWaitingList,
  exportGuestWaitingListParkingSeats,
  exportGuestWaitingListSeats,
  fetchGuestFanGroup,
  fetchGuestSurvey,
  fetchGuestWaitingList,
  guestCancelWaitingList,
  reExportGuestVoucher,
  rejectGuestWaitingList,
  sendGuestAnswers,
  updateGuestAttendeeInfo,
  updateGuestWaitingListSeats,
} from '../api';

export const useFetchGuestFanGroup = (slug: string, params: { code: string }) =>
  useQuery<LookEntity, Error>(
    usersKeys.look(),
    () => fetchGuestFanGroup(slug, params),
    {
      keepPreviousData: true,
    }
  );

export const useFetchGuestWaitingList = (
  waitingListId: string,
  params: { code: string }
) =>
  useQuery<GuestWaitingList, Error>(
    guestKeys.waitingList(waitingListId),
    () => fetchGuestWaitingList(waitingListId, params),
    {
      keepPreviousData: true,
    }
  );

export const useRejectGuestWaitingList = (
  waitingListId: string,
  params: { code: string }
) => {
  const queryClient = useQueryClient();

  return useMutation(() => rejectGuestWaitingList(waitingListId, params), {
    mutationKey: guestKeys.rejectMutation(),

    onSuccess: () => {
      queryClient.invalidateQueries(guestKeys.waitingList(waitingListId));
    },
  });
};

export const useAcceptGuestWaitingList = (
  waitingListId: string,
  params: { code: string }
) => {
  const queryClient = useQueryClient();

  return useMutation(() => acceptGuestWaitingList(waitingListId, params), {
    mutationKey: guestKeys.acceptMutation(),

    onSuccess: () => {
      queryClient.invalidateQueries(guestKeys.waitingList(waitingListId));
    },
  });
};

export const useUpdateGuestWaitingListSeats = (
  waitingListId: string,
  params: { code: string }
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ nbrOfSeats }: { nbrOfSeats: number }) =>
      updateGuestWaitingListSeats(waitingListId, nbrOfSeats, params),
    {
      mutationKey: guestKeys.nbrOfSeatsMutation(),

      onSuccess: () => {
        queryClient.invalidateQueries(guestKeys.waitingList(waitingListId));
      },
    }
  );
};

export const useExportGuestWaitingListSeats = (
  waitingListId: string,
  params: { code: string }
) => {
  const queryClient = useQueryClient();

  return useMutation(() => exportGuestWaitingListSeats(waitingListId, params), {
    mutationKey: guestKeys.seatsExportMutation(),

    onSuccess: () => {
      queryClient.invalidateQueries(guestKeys.waitingList(waitingListId));
    },
  });
};

export const useExportGuestWaitingListParkingSeats = (
  waitingListId: string,
  params: { code: string }
) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => exportGuestWaitingListParkingSeats(waitingListId, params),
    {
      mutationKey: guestKeys.parkingSeatsExportMutation(),

      onSuccess: () => {
        queryClient.invalidateQueries(guestKeys.waitingList(waitingListId));
      },
    }
  );
};

// fetchGuestSurvey;
export const useFetchGuestSurvey = (params: SurveyQuery & { code: string }) =>
  useQuery<ListEntityV2<SurveyInstanceEntity>, Error>(
    guestKeys.surveysList(params.waitinglist_id),
    () => fetchGuestSurvey(params),
    {
      keepPreviousData: true,
    }
  );

// sendGuestAnswers;
export const useSendGuestAnswers = (
  instanceId: string,
  params: { code: string }
): UseMutationResult<
  SurveyAnswersResponse,
  AxiosAPIError<SurveyAnswersValidator>,
  SurveyAnswersValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: SurveyAnswersValidator) =>
      sendGuestAnswers(instanceId, body, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(guestKeys.waitingList(instanceId));
      },
    }
  );
};

// useUpdateGuestAttendeeInfo;
export const useUpdateGuestAttendeeInfo = (
  waitingListId: string,
  params: { code: string }
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ attendeeInfo }: { attendeeInfo: AttendeesInfoValidator }) =>
      updateGuestAttendeeInfo(waitingListId, attendeeInfo, params),
    {
      mutationKey: guestKeys.attendeeInfoMutation(),

      onSuccess: () => {
        queryClient.invalidateQueries(guestKeys.waitingList(waitingListId));
      },
    }
  );
};

export const useReexportGuestVoucher = (
  waitingListId: string,
  params: { code: string }
): UseMutationResult<
  unknown,
  AxiosAPIError<AcceptWaitingListValidator>,
  AcceptWaitingListValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: AcceptWaitingListValidator) =>
      reExportGuestVoucher(waitingListId, body, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(guestKeys.waitingList(waitingListId));
      },
    }
  );
};

export const useGuestCancelWaitingList = (
  waitingListId: string,
  params: { code: string }
): UseMutationResult<
  null,
  AxiosAPIError<GuestWaitingList>,
  { waitingListId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(() => guestCancelWaitingList(waitingListId, params), {
    onSuccess: () => {
      queryClient.invalidateQueries(guestKeys.waitingList(waitingListId));
    },
  });
};

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import {
  LOCAL_STORAGE_USER_LOCALE,
  SESSION_STORAGE_TARGET_APP,
  TranslationsTarget,
} from '@seaters-app/constants';
import { fetchTranslations } from '@seaters-app/data-access';

const loadResources = async (language: string) => {
  const currentTarget = getTarget();

  return await fetchTranslations({
    lang: language,
    target: currentTarget,
  })
    .then((response) => response)
    .catch((error) => {
      console.error('Error loading translations:', error);
      return null;
    });
};

// Cache for storing loaded translations
const translationsCache: Record<
  TranslationsTarget,
  Record<string, Record<string, string>>
> = {
  [TranslationsTarget.WEB]: {},
  [TranslationsTarget.COCKPIT]: {},
  [TranslationsTarget.GUEST]: {},
};

const backendOptions: HttpBackendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  customHeaders: {
    'Cache-Control': 'no-cache',
  },
  request: async (options, url, payload, callback) => {
    try {
      // Parse the language from the URL (format: "lng|ns")
      const language = url.split('|')[0];
      const currentTarget = getTarget(); // This should be refactored into namespaces

      // If translations are cached, use them
      if (translationsCache[currentTarget][language]) {
        return callback(null, {
          data: translationsCache[currentTarget][language],
          status: 200,
        });
      }

      // Load requested language translations
      const translations = await loadResources(language);

      if (translations) {
        translationsCache[currentTarget][language] = translations;
      }

      // If translations are missing or language is not English, load English as fallback
      if (!translations && language !== 'en') {
        const englishTranslations = await loadResources('en');
        if (englishTranslations) {
          translationsCache[currentTarget]['en'] = englishTranslations;
          return callback(null, {
            data: englishTranslations,
            status: 200,
          });
        }
      }

      callback(null, {
        data: translations || {},
        status: translations ? 200 : 500,
      });
    } catch (e) {
      console.error('Error in i18next backend:', e);
      callback(null, {
        data: {},
        status: 500,
      });
    }
  },
};

i18next
  .use(
    new LanguageDetector(null, {
      lookupLocalStorage: LOCAL_STORAGE_USER_LOCALE,
    })
  )
  .use(initReactI18next)
  .use(backend)
  .init({
    backend: backendOptions,
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    returnNull: false,
    load: 'languageOnly', // Prevents loading specific country variations
    detection: {
      order: ['localStorage', 'navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

function getTarget(): TranslationsTarget {
  return (
    sessionStorage.getItem(SESSION_STORAGE_TARGET_APP)?.replaceAll('"', '') ||
    TranslationsTarget.WEB
  );
}

export default i18next;

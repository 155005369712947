import {
  SESSION_STORAGE_EMAIL_REMINDER,
  SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED,
  SESSION_STORAGE_SURVEY_SHOWN,
  SESSION_STORAGE_TARGET_APP,
  SESSION_STORAGE_VERIFICATION,
  routes,
} from '@seaters-app/constants';
import {
  getSessionToken,
  getSlugFromUrl,
  queryClient,
  useAppUserStore,
  useEmailConfirmationStore,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { useIdleTimer } from 'react-idle-timer';

export const useIdle = () => {
  // const navigate = useNavigate();

  const slug = getSlugFromUrl();
  const auth = getSessionToken();

  const { setFanGroup } = useFanGroupStore();
  const { setEmailReminder } = useEmailConfirmationStore();
  const { setUser } = useAppUserStore();

  const { pathname } = window.location;

  const onIdle = () => {
    sessionStorage.removeItem(SESSION_STORAGE_VERIFICATION);
    sessionStorage.removeItem(SESSION_STORAGE_EMAIL_REMINDER);
    sessionStorage.removeItem(SESSION_STORAGE_SURVEY_SHOWN);
    sessionStorage.removeItem(SESSION_STORAGE_PRIVACY_POLICY_ACCEPTED);
    sessionStorage.removeItem(SESSION_STORAGE_TARGET_APP);
    setEmailReminder(false);
    setFanGroup(undefined);
    setUser(undefined);
    queryClient.clear();

    window.location.href = pathname.includes(routes.admin)
      ? `/${routes.admin}`
      : `/${slug}`;
  };

  useIdleTimer({
    timeout: 60 * 60 * 1000, // 60 minutes

    disabled: !auth,
    onIdle,
  });
};

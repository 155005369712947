import { Button as AntdButton, ButtonProps, ConfigProvider, theme } from 'antd';
import React from 'react';
import styles from './button.module.css';
import {
  getSessionToken,
  getSlugFromUrl,
  queryClient,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { hexToRgb } from '../theme/hexToRgb';
import { getColorContrast } from '../theme/getColorContrast';
import Color from 'color';
import { baseColor400, errorColor, primaryColor } from '../theme/colors';
import { LookEntity } from '@seaters-app/constants';

interface CustomButtonProps extends ButtonProps {
  color?: string;
  padding?: number;
}

export const Button: React.FC<CustomButtonProps> = ({
  color,
  padding,
  ...rest
}) => {
  const { token } = theme.useToken();

  const slug = getSlugFromUrl();

  const fanGroup: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const auth = getSessionToken();

  const { fanGroup: storedFanGroup } = useFanGroupStore();

  const fgColorToUse = slug
    ? fanGroup?.color
    : auth
    ? storedFanGroup?.color1
    : fanGroup?.color;

  const customColor = fgColorToUse
    ? Color(hexToRgb(`${fgColorToUse}`) ?? 'rgb(0, 0, 0)')
    : Color(hexToRgb(primaryColor) ?? 'rgb(0, 0, 0)');

  const { colorContrast } = getColorContrast(customColor.color);

  const customColorDarken = customColor.darken(0.3).toString();

  const isLight = colorContrast === 'black';
  const textColor = rest.disabled
    ? baseColor400
    : rest.type === 'primary'
    ? color === colorContrast
      ? customColor.toString()
      : colorContrast
    : rest.danger
    ? errorColor
    : color ?? (isLight ? customColorDarken : customColor.toString());

  const borderColor = !rest.type
    ? rest.disabled
      ? baseColor400
      : rest.danger
      ? errorColor
      : color ?? customColor.toString()
    : rest.disabled && rest.type === 'primary'
    ? baseColor400
    : 'none';

  const restProps = { ...rest };
  delete restProps.style;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: color ?? token.colorLink,
          colorLinkHover: color ?? token.colorLink,
          colorLinkActive: color ?? token.colorLinkActive,
          colorTextBase: color ?? token.colorTextBase,
          colorPrimary: color ?? token.colorPrimary,
          colorBgTextActive: baseColor400,
        },
      }}
    >
      <AntdButton
        className={styles.button}
        style={{
          padding: padding ?? 'auto',
          color: textColor,
          borderColor: borderColor,
          ...rest.style,
        }}
        {...restProps}
      />
    </ConfigProvider>
  );
};

import { UserRole, UserStatus } from '../../enums';

import { z } from 'zod';

export const UserValidatorSchema = z.object({
  email: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  mobilePhoneNumber: z
    .object({
      localNumber: z.string(),
      countryCallingCode: z.string(),
    })
    .nullable(),
  confirmedMobilePhoneNumber: z.boolean().optional(),
  confirmedEmail: z.boolean().optional(),
  facebookId: z.string().optional(),
  language: z.string().optional(),
  createdDate: z.string().optional(),
  lastModifiedDate: z.string().optional(),
  status: z.nativeEnum(UserStatus),
  roles: z.array(z.nativeEnum(UserRole)),
});

export type UserValidator = z.infer<typeof UserValidatorSchema>;

export const FanValidatorSchema = z.object({
  email: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  mobilePhoneNumber: z
    .object({
      localNumber: z.string(),
      countryCallingCode: z.string(),
    })
    .nullable(),
  confirmedMobilePhoneNumber: z.boolean().optional(),
  confirmedEmail: z.boolean().optional(),
  language: z.string(),
  personalInfo: z
    .object({
      address: z
        .object({
          country: z.string().optional().nullable(),
          countryCode: z.string().optional(),
          zipCode: z.string().optional(),
          city: z.string().optional(),
          line1: z.string(),
          line2: z.string().optional().nullable(),
          line3: z.string().optional().nullable(),
          state: z.string().optional().nullable(),
        })
        .optional(),
    })
    .optional(),
});

export type FanValidator = z.infer<typeof FanValidatorSchema>;

export const PasswordValidatorSchema = z.object({
  password: z.string(),
  currentPassword: z.string(),
});

export type PasswordValidator = z.infer<typeof PasswordValidatorSchema>;

export const PhoneValidatorSchema = z.object({
  countryCallingCode: z.string(),
  localNumber: z.string(),
});

export type PhoneValidator = z.infer<typeof PhoneValidatorSchema>;

export const VerifyCodeValidatorSchema = z.object({
  code: z.string(),
  mobile: z.object({
    countryCallingCode: z.string(),
    localNumber: z.string(),
  }),
});

export type VerifyCodeValidator = z.infer<typeof VerifyCodeValidatorSchema>;

export const EmailValidatorSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
});

export type EmailValidator = z.infer<typeof EmailValidatorSchema>;

export const ResetPasswordValidatorSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  slug: z.string(),
});

export type ResetPasswordValidator = z.infer<
  typeof ResetPasswordValidatorSchema
>;

export const UpdatePasswordValidatorSchema = z.object({
  password: z.string(),
  token: z.string(),
});

export type UpdatePasswordValidator = z.infer<
  typeof UpdatePasswordValidatorSchema
>;

export const UserOwnershipValidatorSchema = z.object({
  userId: z.string(),
  fanGroupId: z.string(),
});

export type UserOwnershipValidator = z.infer<
  typeof UserOwnershipValidatorSchema
>;

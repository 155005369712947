import {
  endpoints,
  ListEntity,
  TimeZoneEntity,
  TimeZonesQuery,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

const timeZoneEnv = 'NX9D7RUS95HF';

const timezonedBaseURL = (lat: string, lng: string) =>
  `https://api.timezonedb.com/?key=${timeZoneEnv}&lat=${lat}&lng=${lng}&format=json`;

export const fetchTimeZones = (
  params: TimeZonesQuery
): Promise<ListEntity<TimeZoneEntity>> =>
  api.get(endpoints.timeZones, { params });

type TimeZone = {
  abbreviation: string;
  countryCode: string;
  dst: string;
  gmtOffset: string;
  message: string;
  status: string;
  timestamp: number;
  zoneName: string;
};

export const fetchTimeZone = async (
  lat: string,
  lng: string
): Promise<TimeZone | undefined> => {
  try {
    const response = await fetch(timezonedBaseURL(lat, lng));
    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error:', error);
  }
  return undefined;
};

export enum PositionsDistributionMode {
  FIFS = 'wl_positions_distribution_mode_fifs',
  RANDOM = 'wl_positions_distribution_mode_random',
  PICK = 'wl_positions_distribution_mode_pick',
  AVAILABLE_ONLY = 'wl_positions_distribution_mode_available_only',
  WITH_PARKING = 'wl_positions_distribution_mode_with_parking',
}

export enum DisplayMode {
  CLASSIC = 'CLASSIC',
  CONTEST = 'CONTEST',
  INFORMATIVE = 'INFORMATIVE',
  DETAILED = 'DETAILED',
  MERCHANDISE = 'MERCHANDISE',
}

export enum WLAccessModeEnum {
  PUBLIC = 'PUBLIC',
  CODE_PROTECTED = 'CODE_PROTECTED',
}

export enum AssignationMode {
  NO_RSVP = 'NO_RSVP',
  GUEST_RSVP = 'GUEST_RSVP',
  HOST_RSVP = 'HOST_RSVP',
}

export enum InvitationMode {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum WLPositionStatus {
  BEING_PROCESSED = 'BEING_PROCESSED',
  WAITING_SEAT = 'WAITING_SEAT',
  HAS_SEAT = 'HAS_SEAT',
  ARCHIVED = 'ARCHIVED',
}

export enum SeatStatus {
  ASSIGNED = 'ASSIGNED',
  ASSIGNED_WITHOUT_SEATS = 'ASSIGNED_WITHOUT_SEATS',
  RSVP_ACCEPTED = 'RSVP_ACCEPTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export enum FanActivityStatus {
  JOIN_WL = 'JOIN_WL',
  LEAVE_WL = 'LEAVE_WL',
  CANCEL_POSITION = 'CANCEL_POSITION',
  REJECT_SEATS = 'REJECT_SEATS',
  RECEIVE_SEATS = 'RECEIVE_SEATS',
  CANCEL_SEATS = 'CANCEL_SEATS',
  RECEIVE_RSVP_WITHOUT_SEATS = 'RECEIVE_RSVP_WITHOUT_SEATS',
  ACCEPT_SEATS = 'ACCEPT_SEATS',
  ACCEPT_RSVP = 'ACCEPT_RSVP',
  POSITION_EXPIRES = 'POSITION_EXPIRES',
  SEATS_EXPIRE = 'SEATS_EXPIRE',
  FAN_CANCEL_SEATS = 'FAN_CANCEL_SEATS',
}

export enum ParkingTicketsStatus {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export enum TransactionStatus {
  CREATING = 'CREATING',
  FAILURE = 'FAILURE',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  REFUNDING = 'REFUNDING',
  REFUNDED = 'REFUNDED',
}

export enum BillingMode {
  FREE = 'FREE',
  FAIR_PRICE = 'FAIR_PRICE',
}

export enum WaitingListAcceptFormat {
  MULTIPAGE_PDF_FILE = 'MULTIPAGE_PDF_FILE',
  ZIP_FILE = 'ZIP_FILE',
}

export enum WaitingListStatusEnum {
  SETUP = 'SETUP',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED', // PUBLISHED with opening date === SCHEDULED.
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
}

export enum SeatDistributionMode {
  VOUCHER = 'VOUCHER',
  TICKET = 'TICKET',
}

export enum SeatDistributionModeLabels {
  VOUCHER = 'VOUCHER',
  TICKET = 'TICKET',
  LATER = 'LATER',
}

export enum PreauthorizationMode {
  LIKELIHOOD_BASED = 'LIKELIHOOD_BASED',
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS',
}

export enum InvitationApprovalMode {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

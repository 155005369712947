export enum CustomProfileScopeEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  READ_ONLY = 'READ_ONLY',
}

export enum CustomProfileTypeEnum {
  TEXT = 'TEXT',
  JSON = 'JSON',
}

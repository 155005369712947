import { SESSION_STORAGE_MODE } from '@seaters-app/constants';
import { create } from 'zustand';

interface ThemeState {
  mode: string;
  switch: () => void;
}

export const useAppThemeStore = create<ThemeState>((set) => ({
  mode: sessionStorage.getItem(SESSION_STORAGE_MODE) ?? 'light',
  switch: () =>
    set((state: { mode: string }) => ({
      mode: state.mode === 'light' ? 'dark' : 'light',
    })),
}));

import {
  InfoCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Table, Space, Popconfirm, theme, notification, Flex } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { EventEntity, EventStatus, routes } from '@seaters-app/constants';
import { Typography } from 'antd';
import { useEvents } from './hooks/useEvents';
import { getStatusStyle } from './helpers/getStatusStyle';
import {
  getSingleTranslation,
  useAppThemeStore,
  useDeleteEvent,
} from '@seaters-app/data-access';
import { Button, DatePicker } from '@seaters-app/ui';
import dayjs from 'dayjs';
import Search from 'antd/es/input/Search';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const { Column } = Table;

export function Events() {
  const { t } = useTranslation();

  const {
    data,
    isLoading,
    handleTableChange,
    pagination,
    queryParams,
    setQuery,
  } = useEvents();

  const { token } = theme.useToken();
  const { mode } = useAppThemeStore();
  const { mutateAsync: deleteEvent } = useDeleteEvent();

  const onSearch = (value: string) => {
    setQuery({ query: value, page: 1 });
  };

  const onDateChange = (value: string) => {
    setQuery({ date: value ? dayjs(value).format('YYYY-MM-DD') : '', page: 1 });
  };

  const handleDeleteEvent = (eventId: string) =>
    deleteEvent(
      { eventId },
      {
        onSuccess: () => {
          notification.success({
            message: `Event removed`,
          });
        },
        onError: () => {
          notification.error({
            message: `Event was not removed`,
          });
        },
      }
    );

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
      gap={12}
    >
      <Title level={2}>{t('admin_events')}</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Search
          defaultValue={queryParams.query}
          placeholder={t('event_venue_search-btn')}
          onSearch={onSearch}
          size="large"
          style={{ width: '30%' }}
        />
        <DatePicker
          defaultValue={queryParams.date ? dayjs(queryParams.date) : null}
          onChange={onDateChange}
          size="large"
          style={{ width: '30%' }}
        />
        <Link to={routes.create}>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined rev={undefined} />}
          >
            {t('add_event_text')}
          </Button>
        </Link>
      </div>
      <Table
        rowKey={(event) => event.id}
        dataSource={data?.items}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
      >
        <Column
          title={t('admin_name')}
          dataIndex="name"
          key="name"
          render={(name) => {
            return <span>{getSingleTranslation(name)}</span>;
          }}
        />
        <Column
          title={t('event_settings_start')}
          dataIndex="startDate"
          key="startDate"
          render={(startDate) => {
            return (
              <span>{dayjs(startDate).format('DD MMM YYYY').toString()}</span>
            );
          }}
        />
        <Column
          title={t('status_label')}
          dataIndex="status"
          key="status"
          render={(status: EventStatus) => {
            return (
              <span style={getStatusStyle(status, mode)}>
                {status.toLowerCase()}
              </span>
            );
          }}
        />
        <Column
          key="actions"
          align="right"
          render={(_, event: EventEntity) => {
            return (
              <Space size="small">
                <NavLink to={event.id}>
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined rev={undefined} />}
                  >
                    Details
                  </Button>
                </NavLink>
                <Popconfirm
                  title={t('remove_event_text')}
                  description={t('remove_event_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() => handleDeleteEvent(event.id)}
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    type="link"
                    color={token.colorError}
                    icon={<DeleteOutlined rev={undefined} />}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
    </Flex>
  );
}

import React from 'react';
import { Typography, Tabs, TabsProps, Flex } from 'antd';
import styles from './styles.module.css';
import { routes } from '@seaters-app/constants';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export function SurveysLayout() {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const onChangeTab = (key: string) => {
    navigate(key);
  };

  const items: TabsProps['items'] = [
    {
      key: routes.questionnaires,
      label: t('survey_questionnaires_label'),
    },
    {
      key: routes.usage,
      label: t('survey_usage_label'),
    },
  ];

  const activeTab = items?.find((item) => pathname.includes(item.key))?.key;

  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
    >
      <div className={styles.subheader}>
        <Title level={2}>{t('admin_surveys')}</Title>
      </div>
      <Tabs
        activeKey={activeTab}
        defaultActiveKey={items[0].key}
        items={items}
        onChange={onChangeTab}
      />
      <Outlet />
    </Flex>
  );
}

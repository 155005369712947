import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { Editor } from '@seaters-app/ui';

import styles from './styles.module.css';
import {
  FGOVenueFormValidator,
  FGOVenueFormValidatorSchema,
  Language,
  currencyMap,
  googleMapsApiKey,
} from '@seaters-app/constants';
import { useEffect, useState } from 'react';
import { useLanguages } from './hooks/useLanguages';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  fetchTimeZone,
  requestOneTimeUpload,
  uploadFile,
  useCreateFGOVenue,
  useCurrencies,
  useFetchTimeZones,
} from '@seaters-app/data-access';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { GooglePlacesSearch } from '../../../../../shared/GooglePlacesSearch';
import { ImageCropper, StyledText } from '@seaters-app/ui-shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { prepareValues } from './helpers/prepareValues';
import { useEventCreateStepper } from './hooks/useEventCreateStepper';
import {
  getDetails,
  getGeocode,
  getLatLng,
  SetValue,
} from 'use-places-autocomplete';
import { getTimezone } from '../../../../../utils/helpers/getTimezone';
import { simplifySearchResult } from '../../../../../shared/simplifySearchResults';

const { Text } = Typography;

type VenueCreateAsFGOProps = {
  closeVenueCreation: () => void;
};

export function VenueCreateAsFGO({
  closeVenueCreation,
}: VenueCreateAsFGOProps) {
  const { t } = useTranslation();
  const { languages } = useLanguages();

  const [spinning, setSpinning] = useState<boolean>(false);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);

  const [editorShown, setEditorShown] = useState(false);

  const [currentLanguage, setCurrentLanguage] = useState(Language.EN);

  const { mutate: createVenue } = useCreateFGOVenue();

  const { timeZonesOptions } = useFetchTimeZones();

  const { setSelectedVenue, setVenueConfiguration, next } =
    useEventCreateStepper();

  const { isLoaded: mapsApiLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ['places'],
  });

  const { currenciesOptions: currencies } = useCurrencies();

  const currencyOptions = currencies?.map((currency) => ({
    label: `${currency.id} (${currency.name})`,
    value: currency.id,
  }));

  const methods = useForm({
    mode: 'onBlur',
    resolver: zodResolver(FGOVenueFormValidatorSchema),
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = methods;

  const onSubmit = async (values: FGOVenueFormValidator) => {
    setSpinning(true);

    const preparedValues = prepareValues(values);
    const imageUrlOrCanvas = values.imageFileUrl;
    if (imageUrlOrCanvas && typeof imageUrlOrCanvas !== 'string') {
      setUploadingImage(true);
      await requestOneTimeUpload({
        fileName: 'file.png',
      }).then(async (res) => {
        if (res.fileId) {
          const formFile = new FormData();
          const blob = (await new Promise((resolve) =>
            imageUrlOrCanvas.toBlob(resolve)
          )) as Blob;
          formFile.append('file', blob);
          await uploadFile(formFile, res.path.slice(20, 52))
            .then(() => {
              createVenue(
                { ...preparedValues, imageFileId: res.fileId },
                {
                  onSuccess: (res) => {
                    setSelectedVenue(res);
                    if (res.configurations?.length) {
                      setVenueConfiguration(res.configurations[0]);
                    }
                    next();
                    closeVenueCreation();
                  },
                  onError: ({ response }) => {
                    notification.error({
                      message: t('notification_error_venue_created'),
                      description: response?.data.message,
                    });
                  },
                  onSettled: () => {
                    setSpinning(false);
                  },
                }
              );
            })
            .finally(() => {
              setUploadingImage(false);
            });
        }
      });
    }
  };

  const longitude = useWatch({
    control,
    name: 'longitude',
  });
  const latitude = useWatch({
    control,
    name: 'latitude',
  });

  const currency = useWatch({
    control,
    name: 'currency',
  });

  const imageFileUrl = useWatch({
    control,
    name: 'imageFileUrl',
  });

  useEffect(() => {
    async function fetchTimeZoneData() {
      const timeZone = await fetchTimeZone(latitude, longitude);

      if (timeZone) {
        const currencyCode =
          currencyMap[timeZone.countryCode as keyof typeof currencyMap];

        if (currencyCode && currency !== currencyCode) {
          setValue('currency', currencyCode);

          trigger('currency');
        }
      }
    }
    fetchTimeZoneData();
  }, [latitude, longitude]);

  const loading = spinning || uploadingImage;

  const handleSelectPlace = async (
    name: string,
    id: string,
    setValueForPlaces: SetValue
  ) => {
    const details = await getDetails({
      placeId: id,
    });

    const simplifiedPlace = simplifySearchResult(details);
    setValueForPlaces(name, false);

    const res = await getGeocode({ address: name });
    const coordinates = await getLatLng(res[0]);
    const timeZone = await getTimezone(coordinates.lat, coordinates.lng);
    if (timeZone) {
      setValue('timeZone', timeZone);
    }
    setValue('latitude', coordinates.lat);
    setValue('longitude', coordinates.lng);

    languages.forEach(({ value }) =>
      setValue(`city.${value}`, simplifiedPlace?.formatted_address ?? '')
    );

    if (simplifiedPlace) {
      languages.forEach(({ value }) =>
        setValue(`country.${value}`, simplifiedPlace?.country ?? '')
      );
    }

    trigger([
      `country.${currentLanguage}`,
      `city.${currentLanguage}`,
      'latitude',
      'longitude',
      'currency',
    ]);
  };

  return (
    <Spin tip={t('please_wait_message_text')} spinning={loading}>
      <Space
        direction="vertical"
        style={{ marginBottom: 50, width: '100%' }}
        size={0}
      >
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            autoComplete="off"
            size="large"
            // disabled={isSubmitting}
          >
            <Space
              direction="vertical"
              style={{ maxWidth: 630, width: '100%' }}
            >
              <Row gutter={24}>
                <Col xs={24} md={16}>
                  <Form.Item
                    label={'Name'}
                    name={`name.${currentLanguage}`}
                    validateStatus={
                      errors.name &&
                      errors.name[currentLanguage]?.message &&
                      'error'
                    }
                    help={errors.name && errors.name[currentLanguage]?.message}
                    required={currentLanguage === Language.EN}
                  >
                    <Controller
                      control={control}
                      name={`name.${currentLanguage}`}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    label={'Short name'}
                    name={`shortName.${currentLanguage}`}
                    validateStatus={
                      errors.shortName &&
                      errors.shortName[currentLanguage]?.message &&
                      'error'
                    }
                    help={
                      errors.shortName &&
                      errors.shortName[currentLanguage]?.message
                    }
                    required={currentLanguage === Language.EN}
                  >
                    <Controller
                      control={control}
                      name={`shortName.${currentLanguage}`}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {mapsApiLoaded && (
                <Row gutter={24}>
                  <Col xs={24}>
                    <Form.Item
                      name="addressLine"
                      validateStatus={errors.addressLine && 'error'}
                      help={!!errors.addressLine}
                    >
                      <Controller
                        control={control}
                        name="addressLine"
                        render={() => (
                          <GooglePlacesSearch
                            handleSelect={handleSelectPlace}
                            placeholder={t('venue_address_search_placeholder')}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24}>
                  {mapsApiLoaded && (
                    <GoogleMap
                      zoom={10}
                      center={{
                        lat: Number(latitude ?? 50.8476),
                        lng: Number(longitude ?? 4.3572),
                      }}
                      mapContainerStyle={{ width: '100%', height: '400px' }}
                    >
                      {latitude &&
                        longitude && ( // no Market by default in create flow
                          <MarkerF
                            position={{
                              lat: Number(latitude),
                              lng: Number(longitude),
                            }}
                          />
                        )}
                    </GoogleMap>
                  )}
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={8}>
                  <Form.Item
                    label={<Text>{t('venue_details_country')}</Text>}
                    name={`country.${currentLanguage}`}
                    validateStatus={errors.country && 'error'}
                    help={
                      errors?.country &&
                      errors?.country[currentLanguage] &&
                      errors?.country[currentLanguage]?.message
                    }
                    required={currentLanguage === Language.EN}
                  >
                    <Controller
                      control={control}
                      name={`country.${currentLanguage}`}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    label={t('address_city')}
                    name={`city.${currentLanguage}`}
                    validateStatus={errors.city && 'error'}
                    help={
                      errors?.city &&
                      errors?.city[currentLanguage] &&
                      errors?.city[currentLanguage]?.message
                    }
                    required={currentLanguage === Language.EN}
                  >
                    <Controller
                      control={control}
                      name={`city.${currentLanguage}`}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={8}>
                  <Form.Item
                    label={t('venue_details_time-zone')}
                    name={'timeZone'}
                  >
                    <Controller
                      control={control}
                      name={'timeZone'}
                      render={({ field }) => (
                        <Select
                          {...field}
                          showSearch
                          defaultActiveFirstOption={false}
                          filterOption={(input, option) =>
                            option?.value
                              .toLowerCase()
                              .includes(input.toLowerCase()) ?? false
                          }
                          onSelect={(item) => {
                            setValue('timeZone', item.slice(0, -1)); // TODO: find another way to avoid autoscrolling
                          }}
                          options={timeZonesOptions}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    label={t('venue_details_currency')}
                    name={`currency`}
                    validateStatus={errors.currency && 'error'}
                    help={errors?.currency?.message}
                  >
                    <Controller
                      control={control}
                      name={`currency`}
                      render={({ field }) => {
                        return <Select options={currencyOptions} {...field} />;
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={24} xl={16}>
                  <Form.Item
                    label={<Text>{t('venue_details_conditions')}</Text>}
                    name={`conditions.${currentLanguage}`}
                    validateStatus={errors.conditions && 'error'}
                    help={
                      errors?.conditions &&
                      errors?.conditions[currentLanguage] &&
                      errors?.conditions[currentLanguage]?.message
                    }
                    required={currentLanguage === Language.EN}
                  >
                    <Controller
                      control={control}
                      name={`conditions.${currentLanguage}`}
                      render={({ field: { onChange, ...field } }) => {
                        return !editorShown ? (
                          <StyledText
                            text={field.value ?? ''}
                            onClick={() => setEditorShown(true)}
                          />
                        ) : (
                          <Editor {...field} onEditorChange={onChange} />
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
            <ImageCropper
              imageOrCanvas={imageFileUrl ?? ''}
              handleRemoveImage={() => setValue('imageFileUrl', '')}
              handleImageChange={(img) => {
                setValue('imageFileUrl', img);
                trigger('imageFileUrl');
              }}
              handleReset={() => {
                setValue('imageFileUrl', '');
              }}
              formItemProps={{
                label: <Text>{t('venue_image_label')}</Text>,
                name: 'imageFileUrl',
                required: true,
                help: errors.imageFileUrl?.message,
                validateStatus: errors.imageFileUrl && 'error',
              }}
            />

            <div className={styles.footer}>
              <Button size="middle" onClick={closeVenueCreation}>
                {t('general_cancel')}
              </Button>
              <Segmented
                options={languages}
                value={currentLanguage}
                onChange={setCurrentLanguage}
              />
              <Button size="middle" type="primary" htmlType="submit">
                {t('create_and_select_btn_text')}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Space>
    </Spin>
  );
}

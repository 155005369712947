import Modal from 'antd/es/modal/Modal';
import List from 'antd/es/list';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { Button } from '@seaters-app/ui';
import { Link, useSearchParams } from 'react-router-dom';
import { FanGroupEntity, routes } from '@seaters-app/constants';
import { getSingleTranslation } from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { useFanGroups } from '../../fan-groups/hooks/useFanGroups';
import Search from 'antd/es/input/Search';

type SelectFanGroupPropsType = {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  fanGroupsList: FanGroupEntity[] | [];
  setFanGroupsList: (list: FanGroupEntity[]) => void;
};

export function SelectFanGroup({
  isModalOpen,
  setModalOpen,
  fanGroupsList,
  setFanGroupsList,
}: SelectFanGroupPropsType) {
  const { data: fanGroups, pagination, setQuery, queryParams } = useFanGroups();

  const { t } = useTranslation();

  const [_, setSearchParams] = useSearchParams();

  const handleTableChange = (page: number, pageSize: number) => {
    setQuery({
      size: pageSize,
      page: page,
    });
  };

  const onSearch = (value: string) => {
    setQuery({ query: value, size: queryParams.size, page: 1 });
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setSearchParams({});
  };

  return (
    <Modal
      title={t('select_fg_title_text')}
      centered
      destroyOnClose
      open={isModalOpen}
      onOk={() => setModalOpen(false)}
      onCancel={handleCloseModal}
      okText={t('general_ok-btn')}
      cancelText={t('general_cancel-btn')}
      width={900}
      style={{ height: 'fit-content', maxHeight: '100vh', overflow: 'scroll' }}
    >
      <List
        pagination={{
          position: 'bottom',
          align: 'start',
          onChange: handleTableChange,
          ...pagination,
        }}
        dataSource={fanGroups?.items}
        header={
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Search
              defaultValue={queryParams.query}
              placeholder={t('search_by_placeholder_text', {
                by: t('admin_fan-groups_slug'),
              })}
              onSearch={onSearch}
              size="large"
              style={{ width: '40%' }}
            />
            <Button size="small">
              <Link
                to={`/${routes.admin}/${routes.fanGroups}/${routes.create}`}
              >
                {t('create_new_fg_button_text')}
              </Link>
            </Button>
          </div>
        }
        renderItem={(item) => {
          return (
            <List.Item
              className={'fanGroupItem'}
              key={item.id}
              actions={[
                <Button
                  onClick={() => {
                    setFanGroupsList([...fanGroupsList, item]);
                    setModalOpen(false);
                  }}
                  size="small"
                >
                  {t('select_button_text')}{' '}
                  <ArrowRightOutlined rev={undefined} />
                </Button>,
              ]}
            >
              <List.Item.Meta
                style={{ alignItems: 'center' }}
                avatar={
                  <Avatar
                    src={item?.profileImageUrl}
                    style={{ height: 40, width: 40 }}
                  />
                }
                title={
                  getSingleTranslation(item?.name)
                    ? getSingleTranslation(item?.name)
                    : 'No name provided'
                }
                description={`${t('admin_fan-groups_slug')}: ${item.slug}`}
              />
            </List.Item>
          );
        }}
      />
    </Modal>
  );
}

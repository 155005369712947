import {
  fetchWaitingLists,
  getSlugFromUrl,
  useFetchLook,
} from '@seaters-app/data-access';
import { paginationFormat } from '../../../utils/helpers';
import {
  ListEntity,
  WaitingListEntity,
  waitingListsKeys,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

type ParamsType = {
  pageSize: number;
  current: number;
};

const defaultParams: ParamsType = {
  pageSize: 10,
  current: 1,
};
export const useWaitingLists = () => {
  const slug = getSlugFromUrl();

  const { data: lookData } = useFetchLook(slug ?? '');

  const fanGroupId = lookData?.fanGroupId ?? '';

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<ListEntity<WaitingListEntity>, Error>({
    queryKey: waitingListsKeys.allPerFanGroup(fanGroupId),
    queryFn: ({
      pageParam = {
        ...paginationFormat(defaultParams),
      },
    }) => fetchWaitingLists(fanGroupId, pageParam),
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.reduce(
        (prev, next) => prev + next.items.length,
        0
      );

      return lastPage.totalSize - lastPage.itemOffset >= lastPage.maxPageSize
        ? {
            itemOffset: nextOffset,
            maxPageSize: lastPage.maxPageSize,
          }
        : undefined;
    },
    enabled: !!fanGroupId && !lookData?.properties?.filters,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const allItems = data?.pages.flatMap((page) => page.items) || [];

  const handleFetchNextPage = () => {
    if (fanGroupId) {
      fetchNextPage();
    }
  };

  return {
    allItems,
    loadMoreData: handleFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  };
};

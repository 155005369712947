import { z } from 'zod';
import { t } from 'i18next';

export const ProfileValidatorSchema = z
  .object({
    email: z.string().email({
      message: t('invalid_email_validation_message')
        ? (t('invalid_email_validation_message') as string)
        : 'Invalid email address',
    }),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string(),
    password: z.string().optional(),
    newPassword: z.string().optional(),
    confirmNewPassword: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (values.newPassword !== values.confirmNewPassword) {
      ctx.addIssue({
        message: t('confirm_password_validation_message')
          ? (t('confirm_password_validation_message') as string)
          : 'Should be equal to New password',
        code: z.ZodIssueCode.custom,
        path: ['confirmNewPassword'],
      });
    }
  });

export type ProfileValidator = z.infer<typeof ProfileValidatorSchema>;

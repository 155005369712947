import { LookEntity, usersKeys } from '@seaters-app/constants';
import { queryClient } from '../react-query';

export const useIsCustomFlow = (): boolean => {
  const look = queryClient.getQueryData<LookEntity>(usersKeys.look());

  if (!look) return false;

  return Boolean(look.properties?.guestManagementMode === 'APPROVER_REQUEST');
};

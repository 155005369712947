import React from 'react';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { UserRole, routes } from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import { useSurveys } from './hooks/useSurveys';
import { Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import styles from './styles.module.css';
import Search from 'antd/es/input/Search';
import {
  useFanGroupStore,
  useFetchUserRolesForCurrentFG,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';

function Surveys() {
  const { t } = useTranslation();

  const { fanGroup } = useFanGroupStore();

  const { data: userRoles, isLoading: userRolesIsLoading } =
    useFetchUserRolesForCurrentFG(fanGroup?.id ?? '');

  const userRole = userRoles?.roles?.includes(UserRole.FAN_GROUP_OWNER)
    ? UserRole.FAN_GROUP_OWNER
    : UserRole.ADMIN;

  const {
    surveys,
    surveysLoading: isLoading,
    handleTableChange,
    pagination,
    setQuery,
    query,
  } = useSurveys(userRole);

  const handleDebouncedSearch = (value: string) => {
    setQuery({ search: value, itemOffset: 0 });
  };
  return (
    <>
      <div className={styles.subheader}>
        <Search
          defaultValue={query.search}
          onSearch={handleDebouncedSearch}
          style={{ width: '30%' }}
        />
        <Link to={routes.create}>
          <Button size="large" type="primary" icon={<PlusOutlined />}>
            {t('general_add-btn')} {t('survey_management_survey_label')}
          </Button>
        </Link>
      </div>

      <Table
        rowKey={(survey) => survey.id}
        dataSource={surveys?.items}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
      >
        <Column
          title={t('survey_management_name_label')}
          dataIndex="name"
          key="name"
          width="30%"
        />
        <Column
          title={t('survey_management_questions_label')}
          dataIndex="surveyQuestions"
          key="surveyQuestions"
          width="20%"
          render={(surveyQuestions) => {
            return <span>{surveyQuestions.length}</span>;
          }}
        />
        <Column
          title=""
          dataIndex="id"
          key="details"
          align="right"
          render={(id: string) => {
            return (
              <Space size="small">
                <Link to={id}>
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined rev={undefined} />}
                  >
                    {t('admin_tabs_details')}
                  </Button>
                </Link>
              </Space>
            );
          }}
        />
      </Table>
    </>
  );
}

export default Surveys;

import { UserRole, routes } from '@seaters-app/constants';
import { Navigate, createBrowserRouter, useLocation } from 'react-router-dom';
import { ErrorPage } from './error/ErrorPage';
import { Venues } from './venues/Venues';
import { Venue } from './venues/[id]/Venue';
import { Events } from './events/Events';
import { Event } from './events/[id]/Event';
import { FanGroups } from './fan-groups/FanGroups';
import { FanGroupLayout } from './fan-groups/[id]/FanGroupLayout';
import { General } from './fan-groups/[id]/general/General';
import { Personalization } from './fan-groups/[id]/personalization/Personalization';
import { Access } from './fan-groups/[id]/access/Access';
import { PublicLayout } from './public/PublicLayout';
import { PrivateRoutes } from '../utils/PrivateRoute';
import { Users } from './users/Users';
import { CreateEvent } from './events/Create/Create';
import { CreateVenue } from './venues/create';
import { User } from './users/[id]/User';
import { CreateFanGroup } from './fan-groups/create/CreateFanGroup';
import { ProfileLayout } from './profile/ProfileLayout';
import ResetPassword from './public/ResetPassword/ResetPassword';
import { CreateUser } from './users/create';
import SignIn from './public/SignIn/SignIn';
import Landing from './public/Landing/Landing';
import SignUp from './public/SignUp/SignUp';
import { WaitingLists } from './waiting-lists/WaitingLists';
import { WaitingListFanView } from './waiting-lists/[id]/WaitingListFanView';
import GeneralInfo from './profile/tabs/GeneralInfo';
import Interests from './profile/tabs/Interests';
import { Tickets } from './tickets/Tickets';
import { Requests } from './requests/Requests';
import { Approvals } from './approvals/Approvals';
import { MyWaitingLists } from './my-waiting-lists/WaitingLists';
import { WishLists } from './wish-lists/WishLists';
import { CreateWishList } from './wish-lists/create/CreateWishList';
import { SettingsStep } from './wish-lists/create/steps/SettingsStep';
import { GuestList } from './waiting-lists/GuestList';
import { MyInvites } from './my-invites/MyInvites';
import EmailConfirmation from './public/EmailConfirmation/EmailConfirmation';
import { Allocate } from './distribute/Distribute';
import { DistributeEdit } from './distribute/DistributeEdit';
import { Approval } from './approvals/[id]/Approval';
import { Request } from './requests/[id]/Request';
import AdminLayout from '../utils/layout/AdminLayout';
import FanLayout from '../utils/layout/FanLayout';
import { BadgesLayout } from './badges/BadgesLayout';
import AllBadges from './badges/tabs/AllBadges';
import { WishList } from './wish-lists/[id]/WishList';
import { AddBadgeToWL } from './badges/WishListBadges/AddBadgeToWL';
import { AddBadgeToFG } from './fan-groups/[id]/badges/AddBadgesToFG';
import { BadgeFormComponent } from './badges/[id]/BadgeForm';
import { SurveysLayout } from './surveys/SurveysLayout';
import Surveys from './surveys/tabs/Surveys';
import SurveyDetails from './surveys/[id]/SurveyDetails/SurveyDetails';
import SurveyInstanceCreate from './surveys/[id]/SurveyInstanceDetails/SurveyInstanceCreate';
import SurveyInstanceEdit from './surveys/[id]/SurveyInstanceDetails/SurveyInstanceEdit';
import Fans from './fans/Fans';
import { FanDetailsLayout } from './fans/[id]/FanLayout';
import { EditWishListLayout } from './wish-lists/create/EditWishListLayout';
import { TicketsStep } from './wish-lists/create/steps/TicketsStep';
import AddSurveyToWL from './wish-lists/[id]/surveys/AddSurveyToWL';
import TicketingSystems from './ticketing-systems/TicketingSystems';
import PaymentSystems from './payment-systems/PaymentSystems';
import TicketingSystem from './ticketing-systems/[id]/TicketingSystem';
import { FGOGroupConfigLayout } from './fan-groups/fgo-configuration/FGOGroupConfigLayout';
import { FGConfigurationGeneral } from './fan-groups/fgo-configuration/General';
import { FGConfigurationPersonalization } from './fan-groups/fgo-configuration/Personalization';
import { FGConfigurationAccess } from './fan-groups/fgo-configuration/Access';
import { FGConfigurationNotifications } from './fan-groups/fgo-configuration/Notifications';
import { CreateSurveySteps } from './surveys/[id]/SurveyCreate/CreateSurveySteps';
import { PublishStep } from './wish-lists/create/steps/PublishStep';
import VerifyAuthentication from './public/VerifyAuthentication/VerifyAuthentication';
import WLBadges from './badges/tabs/WLBadges';
import SurveyUsageList from './surveys/tabs/SurveyUsageList';
import FGFilterConfigPage from './fan-groups/[id]/filterConfig/FilterConfigPage';
import FilterConfigPage from './fan-groups/fgo-configuration/FilterConfigPage';
import WLFilters from './wish-lists/create/steps/Filters';
import FGBadges from './badges/tabs/FGBadges';
import CustomProfile from './fan-groups/[id]/custom-profile/CustomProfile';
import SupportActions from './supportActions/SupportActions';
import FGReporting from './reporting/FGReporting';
import { FanGroupAccessRequests } from './fan-groups/fgo-configuration/FanGroupAccessRequests';
import SurveyAfterEvent from './public/SurveyAfterEvent/SurveyAfterEvent';
import { SEATERS_MARKETING_BASE_URL } from '@seaters-app/constants';
import { useEffect } from 'react';
import { WLProtectionCodes } from './wish-lists/create/steps/WLProtectionCodes';
import Mailcampaigns from './mailcampaign/MailcampaignsLayout';
import MailcampaignCreate from './mailcampaign/create/MailcampaignCreate';
import MailcampaignDraft from './mailcampaign/tabs/MailcampaignDraft';
import MailcampaignSent from './mailcampaign/tabs/MailcampaignSent';
import MailcampaignScheduled from './mailcampaign/tabs/MailcampaignScheduled';
import MailCampaignDetailPage from './mailcampaign/detail/MailcampaignDetailPage';

const publicPages = [
  {
    path: routes.login,
    element: <SignIn />,
    index: true,
  },
  {
    path: routes.signup,
    element: <SignUp />,
    index: true,
  },
  {
    path: `${routes.security}/${routes.authenticate}/oauth/:provider`,
    element: <VerifyAuthentication />,
  },
  {
    path: `${routes.authenticate}/${routes.resetPassword}`,
    element: <ResetPassword />,
  },
  {
    path: routes.emailConfirmation,
    element: <EmailConfirmation />,
  },
];

const cockpitWishLists = {
  path: routes.wishLists,
  children: [
    {
      element: <WishLists />,
      index: true,
    },
    {
      path: ':wishListId',
      children: [
        {
          index: true,
          element: <WishList />,
        },
        {
          element: <EditWishListLayout />,
          path: routes.settings,
          children: [
            {
              index: true,
              element: <Navigate to={routes.general} replace />,
            },
            {
              element: <SettingsStep isEdit />,
              path: routes.general,
            },
            {
              element: <TicketsStep isEdit />,
              path: routes.tickets,
            },
            {
              element: <WLProtectionCodes />,
              path: routes.protectionCodes,
            },
            {
              element: <AddBadgeToWL hideSubheader />,
              path: routes.badges,
            },
            {
              path: routes.surveys,
              element: <AddSurveyToWL />,
            },
            {
              path: routes.publish,
              element: <PublishStep isEdit />,
            },
            {
              path: routes.filterConfig,
              element: <WLFilters isEdit />,
            },
          ],
        },
      ],
    },
    {
      element: <CreateWishList />,
      path: routes.create,

      children: [
        {
          element: <SettingsStep />,
          path: ':wishListId',
          index: true,
        },
      ],
    },
  ],
};

const fanGroups = {
  path: routes.fanGroups,
  children: [
    {
      element: <FanGroups />,
      index: true,
    },
    {
      element: <CreateFanGroup />,
      path: routes.create,
    },
    {
      element: <FanGroupLayout />,
      path: ':fanGroupId',
      children: [
        {
          index: true,
          element: <Navigate to={routes.general} replace />,
        },
        {
          element: <General />,
          path: routes.general,
        },
        {
          element: <Personalization />,
          path: routes.personalization,
        },
        {
          element: <Access />,
          path: routes.access,
        },
        {
          element: <AddBadgeToFG />,
          path: routes.badges,
        },
        {
          element: <CustomProfile />,
          path: routes.customInfo,
        },
        {
          element: <FGFilterConfigPage />,
          path: routes.filterConfig,
        },
        {
          element: <FGConfigurationNotifications />,
          path: routes.notifications,
        },
      ],
    },
  ],
};

const venues = {
  path: routes.venues,
  children: [
    {
      element: <Venues />,
      index: true,
    },
    {
      element: <Venue />,
      path: ':venueId',
    },
    {
      element: <CreateVenue />,
      path: routes.create,
    },
  ],
};

const events = {
  path: routes.events,
  children: [
    {
      index: true,
      element: <Events />,
    },
    {
      element: <Event />,
      path: ':eventId',
    },
    {
      element: <CreateEvent />,
      path: routes.create,
    },
  ],
};

const fans = {
  path: routes.fans,
  children: [
    {
      element: <Fans />,
      index: true,
    },
    {
      element: <FanDetailsLayout />,
      path: ':fanId',
    },
  ],
};

const mailcampaigns = {
  path: routes.mailcampaigns,
  children: [
    {
      element: <Mailcampaigns />,
      children: [
        {
          index: true,
          element: <Navigate to={routes.draft} replace />,
        },
        {
          element: <MailcampaignDraft />,
          path: routes.draft,
        },
        {
          element: <MailcampaignSent />,
          path: routes.sent,
        },
        {
          element: <MailcampaignScheduled />,
          path: routes.scheduled,
        },
      ],
    },
    {
      element: <MailCampaignDetailPage />,
      path: `${routes.detail}/:mailcampaignId`,
    },
    {
      element: <MailcampaignCreate />,
      path: routes.create,
      children: [
        {
          element: <MailcampaignCreate />,
          path: `:mailcampaignId`,
        },
      ],
    },
    {
      path: routes.edit,
      children: [
        {
          index: true,
          element: <Navigate to=".." replace />,
        },
        {
          index: true,
          element: <MailcampaignCreate edit={true} />,
          path: `:mailcampaignId`,
        },
      ],
    },
  ],
};

const users = {
  path: routes.users,
  children: [
    {
      element: <Users />,
      index: true,
    },
    {
      element: <User />,
      path: ':userId',
    },
    {
      element: <CreateUser />,
      path: routes.create,
    },
  ],
};

const surveys = {
  path: routes.surveys,
  children: [
    {
      element: <SurveysLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={routes.questionnaires} replace />,
        },
        {
          element: <Surveys />,
          path: routes.questionnaires,
        },
        {
          element: <SurveyUsageList />,
          path: routes.usage,
        },
      ],
    },
    {
      element: <SurveyDetails />,
      path: `${routes.questionnaires}/:questionnaireId`,
    },
    {
      element: <CreateSurveySteps />,
      path: `${routes.questionnaires}/${routes.create}`,
      children: [
        {
          element: <CreateSurveySteps />,
          path: ':questionnaireId',
          index: true,
        },
      ],
    },
    {
      element: <SurveyInstanceEdit />,
      path: `${routes.usage}/:surveyInstanceId`,
    },
    {
      element: <SurveyInstanceCreate />,
      path: `${routes.usage}/${routes.create}`,
    },
  ],
};

const ticketingSystems = {
  path: routes.ticketingSystems,
  children: [
    {
      element: <TicketingSystems />,
      index: true,
    },
    {
      element: <TicketingSystem />,
      path: ':ticketingSystemId',
    },
    // {
    //   element: <CreateTicketingSystem />,
    //   path: routes.create,
    // },
  ],
};

const supportActions = {
  path: routes.supportActions,
  children: [
    {
      element: <SupportActions />,
      index: true,
    },
  ],
};

const paymentSystems = {
  path: routes.paymentSystems,
  children: [
    {
      element: <PaymentSystems />,
      index: true,
    },
    // {
    //   element: <TicketingSystem />,
    //   path: ':paymentId',
    // },
    // {
    //   element: <CreateTicketingSystem />,
    //   path: routes.create,
    // },
  ],
};

const badges = {
  path: routes.badges,
  children: [
    {
      element: <BadgesLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={routes.all} replace />,
        },
        {
          element: <AllBadges />,
          path: routes.all,
        },
        {
          element: <FGBadges />,
          path: routes.fanGroups,
        },
        {
          element: <WLBadges />,
          path: routes.wishLists,
        },
      ],
    },
    {
      path: routes.wishLists,
      children: [
        {
          element: <AddBadgeToWL />,
          path: ':wishListId',
        },
      ],
    },
    {
      element: <BadgeFormComponent />,
      path: ':badgeId',
    },
    {
      element: <BadgeFormComponent />,
      path: routes.create,
    },
  ],
};

const profile = {
  path: routes.profile,
  children: [
    {
      element: <ProfileLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={routes.general} replace />,
        },
        {
          element: <GeneralInfo />,
          path: routes.general,
        },
        {
          element: <Interests />,
          path: routes.interests,
        },
      ],
    },
  ],
};

const fgConfig = {
  path: routes.fanGroupConfiguration,
  children: [
    {
      element: <FGOGroupConfigLayout />,

      children: [
        {
          index: true,
          element: <Navigate to={routes.general} replace />,
        },
        {
          element: <FGConfigurationGeneral />,
          path: routes.general,
        },
        {
          element: <FGConfigurationPersonalization />,
          path: routes.personalization,
        },
        {
          element: <FGConfigurationAccess />,
          path: routes.access,
        },
        {
          element: <FGConfigurationNotifications />,
          path: routes.notifications,
        },
        {
          element: <FilterConfigPage />,
          path: routes.filterConfig,
        },
        {
          element: <FanGroupAccessRequests />,
          path: routes.accessRequests,
        },
      ],
    },
  ],
};

const reporting = {
  path: routes.reporting,
  children: [
    {
      element: <FGReporting />,
      index: true,
    },
  ],
};

const RedirectRoot = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      window.location.href = SEATERS_MARKETING_BASE_URL;
    }
  }, [location]);

  return null;
};

export const router = (roles: UserRole[]) => {
  return createBrowserRouter([
    {
      path: routes.root,
      index: true,
      errorElement: <ErrorPage />,
      element: <RedirectRoot />,
    },
    {
      path: routes.root,
      errorElement: <ErrorPage />,
      element: <PublicLayout />,
      children: [
        {
          path: `${routes.security}/${routes.authenticate}/oauth/:provider`,
          element: <VerifyAuthentication />,
        },
      ],
    },
    {
      path: routes.admin,
      errorElement: <ErrorPage />,
      element: <AdminLayout />,
      children: [
        {
          element: (
            <PrivateRoutes roles={[UserRole.ADMIN, UserRole.FAN_GROUP_OWNER]} />
          ),
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to={
                    roles.includes(UserRole.FAN_GROUP_OWNER)
                      ? routes.wishLists
                      : routes.fanGroups
                  }
                  replace
                />
              ),
            },
            profile,
            {
              element: <PrivateRoutes roles={[UserRole.FAN_GROUP_OWNER]} />,
              children: [
                cockpitWishLists,
                fans,
                fgConfig,
                reporting,
                mailcampaigns,
              ],
            },
            {
              element: <PrivateRoutes roles={[UserRole.ADMIN]} />,
              children: [
                fanGroups,
                surveys,
                venues,
                ticketingSystems,
                paymentSystems,
                events,
                users,
                badges,
                supportActions,
              ],
            },
          ],
        },
      ],
    },
    {
      path: routes.admin,
      errorElement: <ErrorPage />,
      element: <PublicLayout />,
      children: [...publicPages],
    },
    {
      path: `:slug`,
      element: <FanLayout />,
      children: [
        {
          element: <PrivateRoutes />,
          children: [
            {
              index: true,
              element: <Navigate to={routes.waitingLists} replace />,
            },
            {
              path: routes.waitingLists,
              element: <WaitingLists />,
            },
            {
              path: `${routes.waitingList}/:waitingListId/${routes.surveyAfterEvent}`,
              element: <SurveyAfterEvent />,
            },
            {
              path: `${routes.waitingList}/:waitingListId`,
              children: [
                {
                  index: true,
                  element: <WaitingListFanView />,
                },
                // {
                //   path: `${routes.waitingList}/:waitingListId/${routes.surveyAfterEvent}`,
                //   element: <SurveyAfterEvent />,
                // },
                // {
                //   element: <PrivateRoutes roles={[UserRole.FAN]} />,
                //   path: `test`,
                //   children: [
                //     {
                //       element: <SurveyAfterEvent />,
                //       index: true,
                //     },
                //   ],
                // },
                // {
                //   path: `${routes.waitingList}/:waitingListId/test`,
                //   element: <>TEST</>,
                // },
                {
                  element: <PrivateRoutes roles={[UserRole.HOST]} />,
                  path: routes.guestlist,
                  children: [
                    {
                      element: <GuestList />,
                      index: true,
                    },
                  ],
                },
                {
                  element: <PrivateRoutes roles={[UserRole.FAN]} />,
                  path: routes.surveyAfterEvent,
                  children: [
                    {
                      element: <SurveyAfterEvent />,
                      index: true,
                    },
                  ],
                },
              ],
            },
            {
              element: <MyWaitingLists />,
              path: routes.myWaitingLists,
            },
            {
              path: routes.invites,
              element: <PrivateRoutes roles={[UserRole.HOST, UserRole.FAN]} />,
              children: [
                {
                  element: <MyInvites />,
                  index: true,
                },
              ],
            },
            {
              element: <Tickets />,
              path: routes.tickets,
            },
            {
              path: routes.requests,
              element: <PrivateRoutes roles={[UserRole.APPROVER]} />,
              children: [
                {
                  element: <Requests />,
                  index: true,
                },
                {
                  element: <Request />,
                  path: ':waitingListId',
                },
              ],
            },
            {
              path: routes.approvals,
              element: <PrivateRoutes roles={[UserRole.APPROVER]} />,
              children: [
                {
                  element: <Approvals />,
                  index: true,
                },
                {
                  element: <Approval />,
                  path: ':waitingListId',
                },
              ],
            },
            {
              path: routes.allocate,
              element: <PrivateRoutes roles={[UserRole.FAN_GROUP_OWNER]} />,

              children: [
                {
                  element: <Allocate />,
                  index: true,
                },
                {
                  path: ':waitingListId',
                  element: <DistributeEdit />,
                },
              ],
            },
            profile,
          ],
        },
      ],
    },
    {
      path: routes.portofantwerpemployees,
      element: <Navigate to={'/portofantwerpbruges-medewerkers'} />,
    },
    {
      path: `:slug`,
      errorElement: <ErrorPage />,
      element: <PublicLayout />,
      children: [
        ...publicPages,
        {
          path: routes.landing,
          element: <Landing />,
          index: true,
        },
        {
          path: `${routes.waitingList}/:waitingListId/${routes.surveyAfterEvent}`,
          element: <SurveyAfterEvent />,
        },
      ],
    },
  ]);
};

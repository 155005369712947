import { Job } from '@seaters-app/constants';
import { Alert, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AlertProps {
  job?: Job;
}

const Alerts: React.FC<AlertProps> = ({ job }) => {
  if (!job) {
    return null;
  }
  const { t } = useTranslation();

  const errors = Object.keys(job.errorData);
  return (
    <Space direction="vertical">
      {errors.map((errorKey: string) => {
        if (typeof job.errorData[errorKey] !== 'string') {
          return job.errorData[errorKey]?.map((error) => {
            return <Alert message={`${t(errorKey)}: ${error}`} type="error" />;
          });
        } else {
          return (
            <Alert
              message={`${errorKey}: ${job.errorData[errorKey]}`}
              type="error"
            />
          );
        }
      })}
    </Space>
  );
};

export default Alerts;

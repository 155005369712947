import { Card, Typography } from 'antd';
import './styles.css';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@seaters-app/ui';
import { Link, useSearchParams } from 'react-router-dom';
import { SEATERS_APP_BASE_URL, routes } from '@seaters-app/constants';
import { getSlugFromUrl } from '@seaters-app/data-access';

const { Title, Text } = Typography;

function EmailConfirmation() {
  const slug = getSlugFromUrl();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect') ?? '';
  const redirectURI = decodeURIComponent(redirect)
    ? decodeURIComponent(redirect)
    : `${SEATERS_APP_BASE_URL}${slug}/${routes.landing}`;

  return (
    <Card className="publicForm" bordered={false}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CheckCircleOutlined
          style={{ fontSize: '30px', marginBottom: '24px' }}
          rev={undefined}
        />
        <Title level={5}>{t('email_confirmation_general_title')}</Title>
        <Text>{t('email_confirmation_general_message')}</Text>
        <Link to={redirectURI}>
          <Button style={{ marginTop: 16 }} type="primary">
            {t('go_to_fg_button_text')}
          </Button>
        </Link>
      </div>
    </Card>
  );
}

export default EmailConfirmation;

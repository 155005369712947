import { CloseOutlined } from '@ant-design/icons';
import { Language } from '@seaters-app/constants';
import { Form, Input, Space } from 'antd';
import React from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { FormType } from './types';
import { Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';

interface NestedFieldArrayProps {
  nestIndex: number;
  currentLanguage: Language;
  control: Control<FormType>;
}

const NestedFieldArray: React.FC<NestedFieldArrayProps> = ({
  nestIndex,
  control,
  currentLanguage,
}) => {
  const { t } = useTranslation();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `filters.${nestIndex}.values`,
  });

  return (
    <div>
      <Form.Item label={t('general_values')}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 16,
          }}
        >
          {fields.map((subField, subFieldIndex) => (
            <Space key={subField.id}>
              <Form.Item
                noStyle
                name={`filters.${nestIndex}.values.${subFieldIndex}.label.${currentLanguage}`}
              >
                <Controller
                  control={control}
                  name={`filters.${nestIndex}.values.${subFieldIndex}.label.${currentLanguage}`}
                  render={({ field }) => {
                    return (
                      <Input placeholder={t('general_label')} {...field} />
                    );
                  }}
                />
              </Form.Item>

              {/* Value */}
              <Form.Item
                noStyle
                name={`filters.${nestIndex}.values.${subFieldIndex}.value`}
              >
                <Controller
                  control={control}
                  name={`filters.${nestIndex}.values.${subFieldIndex}.value`}
                  render={({ field }) => {
                    return (
                      <Input placeholder={t('admin_env_value')} {...field} />
                    );
                  }}
                />
              </Form.Item>
              <CloseOutlined
                onClick={() => remove(subFieldIndex)}
                rev={undefined}
              />
            </Space>
          ))}
          <Button
            type="dashed"
            onClick={() =>
              append({
                label: {
                  en: '',
                },
                value: '',
              })
            }
            block
          >
            + {t('filter_config_add_value')}
          </Button>
        </div>
      </Form.Item>
    </div>
  );
};

export default NestedFieldArray;

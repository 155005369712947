import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CustomProfileFormValidatorSchema,
  CustomProfileScopeEnum,
  CustomProfileTypeEnum,
} from '@seaters-app/constants';
import { useFetchAdminAnswerSemantics } from '@seaters-app/data-access';
import {
  useCreateFanGroupCustomInfo,
  useFetchFanGroupCustomInfo,
  useUpdateFanGroupCustomInfo,
} from '@seaters-app/data-access/hooks/customInfo';
import { Button, EmptyState } from '@seaters-app/ui';
import { Form, Spin, Tooltip, notification } from 'antd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Option } from '../../../waiting-lists/components/Filters/helpers';
import {
  CustomInfoFields,
  CustomProfileForm,
  getInitialValues,
  gmFields,
  guestManagementRequiredCustomInfo,
  guestManagementRequiredCustomInfoSemantics,
} from './helpers';
import { Footer } from '@seaters-app/ui-shared';

export function CustomProfile() {
  const { t } = useTranslation();
  const { fanGroupId = '' } = useParams();

  const { mutate: createFanGroupCustomInfo, isLoading: isCreating } =
    useCreateFanGroupCustomInfo(fanGroupId);

  const { mutate: updateFanGroupCustomInfo, isLoading: isUpdating } =
    useUpdateFanGroupCustomInfo(fanGroupId);

  const { data: fgCustomInfo, isLoading } = useFetchFanGroupCustomInfo(
    fanGroupId,
    {
      size: 100,
      page: 0,
    }
  );

  const guestManagementEnabled = guestManagementRequiredCustomInfo.reduce(
    (acc: boolean, curr: string) => {
      return (
        acc &&
        !!fgCustomInfo?.content.find((info) => info.semanticName === curr)
      );
    },
    true
  );

  const { data: answerSemantics } = useFetchAdminAnswerSemantics({
    maxPageSize: 200,
    itemOffset: 0,
  });

  const answerSemanticsOptions: Option[] | undefined =
    answerSemantics?.items.map((semantic, i) => ({
      label: semantic.name,
      value: semantic.name,
      id: semantic.id,
    }));

  const methods = useForm<CustomProfileForm>({
    mode: 'onBlur',
    resolver: zodResolver(CustomProfileFormValidatorSchema),
    defaultValues: getInitialValues(fgCustomInfo),
    values: getInitialValues(fgCustomInfo),
  });

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = methods;

  const {
    remove: removeField,
    append: addField,
    fields,
  } = useFieldArray({
    control,
    name: 'customInfos',
  });

  const onSubmit = (values: CustomProfileForm) => {
    const { customInfos } = values;

    const customInfosWithId = customInfos.map((customInfo) => {
      const { type, scope, semanticName, mandatory } = customInfo;
      const id = answerSemantics?.items.find(
        (item) => item.name === semanticName
      )?.id;

      return { mandatory, type, scope, semanticId: id ?? '' };
    });

    customInfosWithId.forEach((info) => {
      const existed = fgCustomInfo?.content.find((fgCustomInfo) => {
        return fgCustomInfo.semanticId === info.semanticId;
      });
      if (existed) {
        if (
          existed.scope !== info.scope ||
          existed.mandatory !== info.mandatory
        ) {
          updateFanGroupCustomInfo(
            {
              fgCustomInfoId: existed.id,
              scope: info.scope,
              mandatory: info.mandatory,
            },
            {
              onSuccess: () => {
                notification.success({
                  message: t('notification_success_updated_general'),
                });
              },
              onError: (error) => {
                notification.error({
                  message: error.message,
                });
              },
            }
          );
        }
      } else {
        createFanGroupCustomInfo(
          {
            type: info.type,
            scope: info.scope,
            semanticId: info.semanticId,
            mandatory: info.mandatory,
          },
          {
            onSuccess: () => {
              notification.success({
                message: t('notification_success_created_general'),
              });
            },
            onError: (error) => {
              notification.error({
                message: error.message,
              });
            },
          }
        );
      }
    });
  };

  const addGuestManagementRequiredFields = () => {
    const fieldsToAdd = gmFields.filter((value) => {
      const existedSemantics = getValues('customInfos').map(
        (customInfoItem) => customInfoItem.semanticName
      );
      return !existedSemantics.includes(value.semanticName);
    });

    addField(fieldsToAdd);
  };

  return fgCustomInfo ? (
    <>
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          size="middle"
          disabled={isCreating || isUpdating}
          initialValues={getInitialValues(fgCustomInfo)}
        >
          <>
            <Form.Item>
              <Button
                type="link"
                onClick={() => addGuestManagementRequiredFields()}
                disabled={guestManagementEnabled}
              >
                {t('add_guest_mng_fields')}
                <Tooltip
                  title={guestManagementRequiredCustomInfoSemantics.toString()}
                >
                  <InfoCircleOutlined rev={undefined} />
                </Tooltip>
              </Button>
            </Form.Item>
            <>
              {fields.map((field, i) => {
                return (
                  <CustomInfoFields
                    values={getValues()}
                    errors={errors}
                    field={field}
                    i={i}
                    remove={() => {
                      removeField(i);
                    }}
                    control={control}
                    fgCustomInfo={fgCustomInfo}
                    answerSemanticsOptions={answerSemanticsOptions}
                    arrayName="customInfos"
                  />
                );
              })}
            </>
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  addField({
                    scope: CustomProfileScopeEnum.PRIVATE,
                    semanticName: '',
                    type: CustomProfileTypeEnum.TEXT,
                    mandatory: false,
                  })
                }
                block
                icon={<PlusOutlined rev={undefined} />}
              >
                {t('add_custom_info_btn_text')}
              </Button>
            </Form.Item>
          </>

          <Footer onCancel={() => reset()} />
        </Form>
      </FormProvider>
    </>
  ) : isLoading ? (
    <Spin />
  ) : (
    <EmptyState />
  );
}

export default CustomProfile;

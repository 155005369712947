import { useTickets } from './hooks/useTickets';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './tickets.module.css';
import Title from 'antd/es/typography/Title';
import WaitingListCard from '../waiting-lists/components/WaitingListCard/WaitingListCard';
import {
  Container,
  Spinner,
  EmptyState,
  GalleryCardSkeleton,
} from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import { routes } from '@seaters-app/constants';

export function Tickets() {
  const { allItems, loadMoreData, hasNextPage, isLoading } = useTickets();
  const { t } = useTranslation();
  return (
    <div className={styles.tickets}>
      <Container>
        <Title level={2} style={{ marginBottom: 24 }}>
          {t('seatlist_title')}
        </Title>
        {/* <Banner image={fanGroupCoverImage ?? ''} /> */}

        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={allItems?.length ?? 0}
          next={() => loadMoreData()}
          hasMore={!!hasNextPage}
          loader={<Spinner />}
          scrollableTarget="scrollableDiv"
        >
          {isLoading ? (
            <div className={styles.ticketsContainer}>
              {[...Array(6)].map(() => (
                <GalleryCardSkeleton />
              ))}
            </div>
          ) : allItems.length ? (
            <div className={styles.ticketsContainer}>
              {allItems?.map((item) => (
                <WaitingListCard
                  redirectPath={`../${routes.waitingList}/${item.waitingListId}`}
                  waitingList={item}
                />
              ))}
            </div>
          ) : (
            <EmptyState
              description={
                <p>{t('api_active_waiting_lists_with_seat_missing')}</p>
              }
            />
          )}
        </InfiniteScroll>
      </Container>
    </div>
  );
}

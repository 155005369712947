import { useFetchLanguages } from '@seaters-app/data-access';

// Languages
export const useLanguages = () => {
  const { data } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const languages = data
    ? data?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  return { languages };
};

export enum BadgeMoodEnum {
  GOOD = 'GOOD',
  NEUTRAL = 'NEUTRAL',
  BAD = 'BAD',
}

export enum BadgeStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  PENDING = 'PENDING',
}

export enum FanBadgeStatusEnum {
  REVOKED = 'REVOKED',
  AWARDED = 'AWARDED',
}

export enum WLBadgesStateEnum {
  RESTRICTED = 'RESTRICTED',
  ACCESSIBLE = 'ACCESSIBLE',
  PUBLIC = 'PUBLIC',
}

import { InvitationEntity, InvitationStatus } from '@seaters-app/constants';
import { successColor, alertColor, errorColor } from '@seaters-app/ui';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

export interface InvitationStatusTagProps {
  status: InvitationEntity['status'];
}

type InvitationStatusToColorType = {
  [status in Partial<InvitationStatus>]?: {
    color: string;
    label: string;
  };
};

const invitationStatusToColor: InvitationStatusToColorType = {
  APPROVED: {
    color: successColor,
    label: 'reviewed_status_text',
  },
  REJECTED: {
    color: errorColor,
    label: 'rejected_status_text',
  },
  PENDING: {
    color: alertColor,
    label: 'pending_status_text',
  },
};
export function InvitationStatusTag({ status }: InvitationStatusTagProps) {
  const { t } = useTranslation();

  return (
    <Tag color={invitationStatusToColor[status]?.color}>
      {t(invitationStatusToColor[status]?.label)}
    </Tag>
  );
}

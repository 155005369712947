import {
  SeatDistributionMode,
  SeatEntity,
  SeatStatus,
  waitingListsKeys,
} from '@seaters-app/constants';
import {
  useDeleteFGOWishListSeat,
  queryClient,
} from '@seaters-app/data-access';
import { Modal, notification, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
const { Text } = Typography;
interface RemoveSeatModalProps {
  open: boolean;
  seatToDelete?: SeatEntity | null;
  setSeatToDelete: (seat: SeatEntity | null) => void;
  isPaid: boolean;
  seatDistributionMode?: SeatDistributionMode;
}

const RemoveSeatModal: React.FC<RemoveSeatModalProps> = ({
  open,
  seatToDelete,
  setSeatToDelete,
  isPaid,
  seatDistributionMode,
}) => {
  const { wishListId = '' } = useParams();

  const { t } = useTranslation();
  const { mutate: removeSeat, isLoading } = useDeleteFGOWishListSeat(
    wishListId || ''
  );

  const handleSeatsRemove = (positionId: string) => {
    removeSeat(
      { fanId: positionId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_removed'),
          });

          queryClient.invalidateQueries(waitingListsKeys.seats(wishListId));
        },
        onSettled: () => {
          setSeatToDelete(null);
        },
      }
    );
  };

  return (
    <Modal
      title={t('confirm_areyousure')}
      open={open}
      onOk={() => {
        if (seatToDelete) {
          handleSeatsRemove(seatToDelete.fanId);
        }
      }}
      onCancel={() => setSeatToDelete(null)}
      okButtonProps={{ loading: isLoading }}
      cancelText={t('general_cancel_text')}
      okText={t('mwl_wl_fan-remove-btn')}
    >
      <Space direction="vertical">
        {seatToDelete &&
          seatDistributionMode === SeatDistributionMode.VOUCHER &&
          t('remove_voucher_explanation_text', {
            firstName: seatToDelete.firstName,
            lastName: seatToDelete.lastName,
            fanEmail: seatToDelete.fanEmail,
          })}
        {seatDistributionMode === SeatDistributionMode.TICKET && (
          <Text>{t('accepted_positions_removing_warning')}</Text>
        )}
        {isPaid && seatToDelete?.status === SeatStatus.ACCEPTED && (
          <Text>
            {t('accepted_positions_removing_warning_paid', {
              positions: `${seatToDelete.firstName} ${seatToDelete.lastName}`,
            })}
          </Text>
        )}
      </Space>
    </Modal>
  );
};

export default RemoveSeatModal;

import {
  LOCAL_STORAGE_USER_LOCALE,
  Language,
  TranslationsTarget,
} from '@seaters-app/constants';
import { useTranslation } from 'react-i18next';

export const useAppTranslationsChange = () => {
  const { i18n } = useTranslation();
  const changeTranslations = (
    storageItem: string,
    value: Language | TranslationsTarget | string
  ) => {
    if (storageItem === LOCAL_STORAGE_USER_LOCALE) {
      localStorage.setItem(storageItem, value);
      i18n.changeLanguage(value);
    } else {
      sessionStorage.setItem(storageItem, value);
      i18n.init();
    }
  };
  return { changeTranslations };
};

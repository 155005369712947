import { StepItemContentProps } from './types';
import Survey from '../Survey/Survey';

export function SurveyForm({
  ticketsAmount,
  surveyInstances,
  code,
  selectedFanId,
  ...props
}: StepItemContentProps) {
  const onSubmit = () => {
    if (props?.next) {
      props?.next();
    }
  };
  const onCancel = () => {
    if (props?.prev) {
      props?.prev();
    }
  };

  if (!surveyInstances) return null;

  return (
    <div style={{ maxWidth: 630, width: '100%' }}>
      <Survey
        cancel={onCancel}
        surveyInstance={surveyInstances.content[0]}
        onSuccess={onSubmit}
        ticketsAmount={ticketsAmount}
        code={code}
        selectedFanId={selectedFanId}
      />
    </div>
  );
}

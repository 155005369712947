import {
  LookEntity,
  privacyPolicyUrl,
  userAgreementUrl,
  usersKeys,
} from '@seaters-app/constants';
import packageJSON from 'package.json';

import {
  Container,
  Logo,
  baseColor600,
  baseColor800,
  baseColor900,
} from '@seaters-app/ui';
import { ConfigProvider, Grid, Space, Typography, theme } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  getSlugFromUrl,
  queryClient,
  useFetchLook,
} from '@seaters-app/data-access';
import { useTranslatedProperty } from '../helpers';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function AppFooter() {
  const screens = useBreakpoint();

  const PROD = import.meta.env.MODE === 'production';
  const slug = getSlugFromUrl();
  const data = queryClient.getQueryData<LookEntity>(usersKeys.look());

  const fgPrivacyPolicyUrl = useTranslatedProperty(data?.privacyPolicyUrls);

  const fgTermsAndConditionsUrl = useTranslatedProperty(
    data?.termsAndConditionsUrls
  );

  const rulesLinksUrls = useTranslatedProperty(data?.rulesLinks);
  const legalNoticeUrls = useTranslatedProperty(data?.legalNoticeUrls);

  const helpUrls = useTranslatedProperty(data?.helpLinks);
  const faqLinks = useTranslatedProperty(data?.faqLinks);

  const { t } = useTranslation();
  const { token } = theme.useToken();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: token.colorBgContainer,
          colorBgContainer: token.colorText,
        },
      }}
    >
      <div style={{ backgroundColor: baseColor800 }} className="publicFooter">
        <Container paddingTop={24} paddingBottom={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 24,
            }}
          >
            <Space
              style={{
                minWidth: screens.xs ? '100%' : '40%',
                justifyContent: 'space-between',
              }}
            >
              <Space direction="vertical" style={{ minWidth: 150 }}>
                <Text
                  style={{
                    color: baseColor600,
                  }}
                >
                  {t('footer_agreement').toUpperCase()}
                </Text>
                <Space
                  size={40}
                  style={{
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  <Space direction="vertical">
                    <Link
                      to={fgPrivacyPolicyUrl ?? privacyPolicyUrl}
                      target="_blank"
                    >
                      <Text>{t('footer_agreement_privacy')}</Text>
                    </Link>
                    <Link
                      to={fgTermsAndConditionsUrl ?? userAgreementUrl}
                      target="_blank"
                    >
                      <Text>{t('footer_agreement_user')}</Text>
                    </Link>
                  </Space>
                  <Space direction="vertical">
                    {rulesLinksUrls && (
                      <Link to={rulesLinksUrls} target="_blank">
                        <Text>{t('rules_label')}</Text>
                      </Link>
                    )}
                    {legalNoticeUrls && (
                      <Link to={legalNoticeUrls} target="_blank">
                        <Text>{t('legal_notice_label')}</Text>
                      </Link>
                    )}
                  </Space>
                </Space>
              </Space>
              <Space direction="vertical" style={{ minWidth: 100 }}>
                <Text
                  style={{
                    color: baseColor600,
                  }}
                >
                  {t('footer_support').toUpperCase()}
                </Text>
                <Link
                  to={helpUrls ? helpUrls : t('helpdesk_url')}
                  target="_blank"
                >
                  <Text>{t('footer_support_contact')}</Text>
                </Link>
                <Link
                  to={faqLinks ? faqLinks : t('footer_support_faq-link')}
                  target="_blank"
                >
                  <Text>{t('footer_support_faq')}</Text>
                </Link>
              </Space>
            </Space>
            <Space
              style={{
                minWidth: 150,
                alignSelf: 'flex-start',
                marginRight: '0',
                marginLeft: 'auto',
              }}
            >
              <Logo inverted />
            </Space>
          </div>
        </Container>
        <div
          style={{
            backgroundColor: baseColor900,
            display: 'flex',
            justifyContent: 'space-between',
            padding: '12px 5%',
          }}
        >
          <Text>
            {t('footer_dynamic_copyright', {
              currentYear: dayjs(new Date()).format('YYYY'),
            })}
          </Text>
          {!PROD && <Text>{packageJSON.version}</Text>}
        </div>
      </div>
    </ConfigProvider>
  );
}

export default AppFooter;

import {
  AxiosAPIError,
  BadgeEventEntity,
  FanAccessRequest,
  FanActivityEntity,
  FanBadgeLinkBody,
  FanEntity,
  FanListEntity,
  ListEntity,
  PaginationQuery,
  badgesEventsKeys,
  fansKeys,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchFanAccessRequests,
  fetchFanActivities,
  fetchFanGroupFans,
  fetchFanPersonalInfo,
  grantUserBadge,
  revokeUserBadge,
} from '../api';

export const useFetchFanGroupFans = (
  fanGroupId: string,
  params: PaginationQuery
) =>
  useQuery<ListEntity<FanListEntity>, Error>(
    fansKeys.listPerFG(fanGroupId, params),
    () => fetchFanGroupFans(fanGroupId, params),
    {
      enabled: !!fanGroupId,
      keepPreviousData: true,
    }
  );

export const useFetchFanActivities = (fanId: string, groupId: string) =>
  useQuery<FanActivityEntity[], Error>(
    fansKeys.activitiesPerFan(fanId, groupId),
    () => fetchFanActivities(fanId, groupId),
    {
      enabled: !!fanId && !!groupId,
      keepPreviousData: true,
    }
  );

export const useFetchFanAccessRequests = (fanId: string, groupId: string) =>
  useQuery<FanAccessRequest[], Error>(
    fansKeys.accessRequestsPerFan(fanId),
    () => fetchFanAccessRequests(fanId, groupId),
    {
      enabled: !!fanId && !!groupId,
      keepPreviousData: true,
    }
  );

export const useFetchFanPersonalInfo = (fanId: string) =>
  useQuery<FanEntity, Error>(
    fansKeys.detail(fanId),
    () => fetchFanPersonalInfo(fanId),
    {
      enabled: !!fanId,
    }
  );

export const useGrantUserBadge = (
  groupId: string
): UseMutationResult<
  BadgeEventEntity,
  AxiosAPIError<FanBadgeLinkBody>,
  FanBadgeLinkBody,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: FanBadgeLinkBody) => grantUserBadge(groupId, body),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          badgesEventsKeys.listPerUser(groupId, response.userId)
        );
      },
    }
  );
};
// badgesEventsKeys.listPerUser(fanGroupId, userId),
export const useRevokeUserBadge = (
  groupId: string
): UseMutationResult<
  BadgeEventEntity,
  AxiosAPIError<FanBadgeLinkBody>,
  FanBadgeLinkBody,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: FanBadgeLinkBody) => revokeUserBadge(groupId, body),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          badgesEventsKeys.listPerUser(groupId, response.userId)
        );
      },
    }
  );
};

import { Space, Card, Skeleton, Grid, theme } from 'antd';
const { useBreakpoint } = Grid;
export const WishListSummarySkeleton = () => {
  const screens = useBreakpoint();
  const {
    token: { colorBorder },
  } = theme.useToken();

  return Object.keys(screens).length ? (
    !screens.xs ? (
      <Space
        style={{
          border: `1px solid ${colorBorder}`,
          display: 'flex',
          padding: 10,
          gap: 10,
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: 8,
          marginBottom: 16,
        }}
      >
        <Space>
          <Skeleton.Image active />
          <Space direction="vertical">
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
          </Space>
        </Space>
        <Space>
          <Space direction="vertical">
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
          </Space>
          <Space direction="vertical">
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
          </Space>
        </Space>
      </Space>
    ) : (
      <Card
        size="small"
        cover={<Skeleton.Button block active style={{ height: 152 }} />}
        actions={[
          <Space
            direction="vertical"
            style={{ width: '100%', padding: '0 10px' }}
          >
            <Skeleton.Button block active style={{ height: 32 }} />
            <Skeleton.Button block active style={{ height: 32 }} />
          </Space>,
        ]}
        style={{ marginBottom: 16 }}
      >
        <Space direction="vertical" style={{ width: '50%', padding: '0 8px' }}>
          <Skeleton.Button block active style={{ height: 16 }} />
          <Skeleton.Button block active style={{ height: 16 }} />
          <Skeleton.Button block active style={{ height: 16 }} />
        </Space>
      </Card>
    )
  ) : null;
};

import { BillingVariables } from '@seaters-app/constants';

export const computeDistributionFee = (
  price: number,
  billingVariables: BillingVariables
) => {
  const minFee = Math.round(
    parseFloat(billingVariables.minDistributionFee || '0') * 100
  );
  const maxFee = Math.round(
    parseFloat(billingVariables.maxDistributionFee || '0') * 100
  );
  const distributionRate =
    parseFloat(billingVariables.distributionRate || '0') * 100;

  return (
    Math.round(Math.min(maxFee, Math.max(minFee, price * distributionRate))) /
      100 || 0
  );
};

import { Select, SelectProps } from 'antd';
import usePlacesAutocomplete, { SetValue } from 'use-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { useEffect, useRef } from 'react';

export function GooglePlacesSearch({
  handleSelect,
  ...props
}: SelectProps & {
  handleSelect: (name: string, id: string, setValueFunction: SetValue) => void;
}) {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { t } = useTranslation();
  const prevStatusRef = useRef<string>(status);

  useEffect(() => {
    if (prevStatusRef.current !== 'ZERO_RESULTS' && status === 'ZERO_RESULTS') {
      notification.error({
        message: t('search_places_no_results'),
      });
    }
    prevStatusRef.current = status;
  }, [status, t]);

  const options = data.map(({ place_id, description }) => {
    return { key: place_id, value: description, label: description };
  });

  return (
    <Select
      allowClear
      onSelect={(value, option) => {
        handleSelect(value, option.key, setValue);
        clearSuggestions();
      }}
      showSearch
      onSearch={(e) => {
        setValue(e);
      }}
      disabled={!ready}
      options={options}
      {...props}
    />
  );
}

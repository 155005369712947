import {
  TicketsUrl,
  downloadFileByURL,
  fetchInvitation,
} from '@seaters-app/data-access';

export function retryExport(
  invitationId: string,
  fieldName: TicketsUrl,
  fileName: string
) {
  let max = 5;

  let p: Promise<void | string> = Promise.reject();
  for (let i = 0; i < max; i++) {
    p = p
      .catch(async () => {
        const response = await fetchInvitation(invitationId);
        if (response.position) {
          if (response.position[fieldName]) {
            return response.position[fieldName];
          } else {
            throw response.position[fieldName] ?? '';
          }
        }
      })
      .then((value) => {
        if (value) {
          return value;
        } else {
          throw value;
        }
      });
  }
  p = p
    .then((result) => {
      if (typeof result === 'string') {
        downloadFileByURL(result, fileName);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

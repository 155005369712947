import {
  LOCAL_STORAGE_SLUG,
  LookEntity,
  UserRole,
} from '@seaters-app/constants';
import {
  getSlugFromUrl,
  queryClient,
  useFetchFGBySlug,
  useFetchUserRolesForCurrentFG,
} from '@seaters-app/data-access';

function useCurrentGroupRoles(allowedRoles?: UserRole[]) {
  const slug = getSlugFromUrl();

  const localStorageSlug = localStorage.getItem(LOCAL_STORAGE_SLUG);

  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const { data: fanGroupBySlugData, isLoading: fgBySlugIsLoading } =
    useFetchFGBySlug(slug ?? localStorageSlug ?? '', false);

  const fgId = fanGroupBySlugData?.id ?? fanGroupData?.fanGroupId ?? '';

  const { data: userRoles, isLoading: userRolesIsLoading } =
    useFetchUserRolesForCurrentFG(fgId);

  const hasIntersections =
    !allowedRoles ||
    allowedRoles.some((allowedRole) => {
      if (!userRoles) {
        return false;
      } else {
        return userRoles.roles.includes(allowedRole);
      }
    });

  return {
    hasIntersections,
    isLoading: fgBySlugIsLoading || userRolesIsLoading,
  };
}

export default useCurrentGroupRoles;

import { create } from 'zustand';

interface StepsState {
  current: number;
  setCurrent: (step: number) => void;
  next: () => void;
  prev: () => void;
  resetSteps: () => void;
}

export const useCurrentStep = create<StepsState>((set) => ({
  current: 0,
  setCurrent: (step: number) => set(() => ({ current: step })),
  next: () =>
    set((state: { current: number }) => ({ current: state.current + 1 })),
  prev: () =>
    set((state: { current: number }) => ({ current: state.current - 1 })),
  resetSteps: () => set(() => ({ current: 0 })),
}));

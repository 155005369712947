import { handleRefreshToken } from '@seaters-app/data-access';
import { useEffect } from 'react';

export const useRefreshToken = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshToken();
    }, 10 * 60 * 1000); // Refresh every 10 minutes

    return () => clearInterval(interval);
  }, []);
};

import React, { useState } from 'react';
import { Skeleton, notification, Spin, Form, Row, Col, Select } from 'antd';
import {
  useAppUserStore,
  useFanGroupStore,
  useFetchAdminFanGroup,
  useFetchAdminThemes,
  useUpdateAdminFanGroup,
} from '@seaters-app/data-access';

import { useTranslation } from 'react-i18next';
import { Button } from '@seaters-app/ui';
import { UserRole } from '@seaters-app/constants';
import { useParams } from 'react-router-dom';

export function FGConfigurationNotifications() {
  const { t } = useTranslation();
  const [spinning, setSpinning] = useState(false);
  const { fanGroupId = '' } = useParams();

  const isFGDetails = fanGroupId !== '';

  const { fanGroup } = useFanGroupStore();

  const { data: adminFGEntity } = useFetchAdminFanGroup(fanGroup?.id ?? '');

  const { data: fanGroupDetails } = useFetchAdminFanGroup(fanGroupId ?? '');

  const fgData = isFGDetails ? fanGroupDetails : adminFGEntity;

  const { data: themes } = useFetchAdminThemes({
    itemOffset: 0,
    maxPageSize: 1000,
  });

  const defaultThemeId = themes?.items.find(
    (theme) => theme.defaultTheme === true
  )?.id;

  const { user } = useAppUserStore();

  const isAdmin = user?.roles.includes(UserRole.ADMIN);

  const { mutate: updateFanGroup, isLoading: isFanGroupUpdating } =
    useUpdateAdminFanGroup(isFGDetails ? fanGroupId : adminFGEntity?.id ?? '');
  const [form] = Form.useForm();

  const formValues = {
    themeId: fgData?.themeId ?? defaultThemeId,
  };

  const onSubmit = (values: { themeId: string }) => {
    if (fgData) {
      const dataToSend = {
        ...fgData,
        themeId: values?.themeId,
      };

      setSpinning(true);
      updateFanGroup(dataToSend, {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_fg_updated'),
          });
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_fg_updated'),
          });
        },
        onSettled: () => {
          setSpinning(false);
        },
      });
    }
  };

  return formValues && themes ? (
    <Spin tip={t('please_wait_message_text')} spinning={spinning}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={formValues}
        disabled={!isAdmin}
      >
        <Row gutter={24}>
          <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
            <Form.Item label={t('theme_id_label')} name="themeId">
              <Select
                allowClear
                options={themes?.items.map((theme) => {
                  return { label: theme.name, value: theme.id };
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
            <Form.Item label="hidden">
              <Button
                htmlType="submit"
                type="primary"
                loading={isFanGroupUpdating}
              >
                {t('fgc_templatetheme-apply-btn')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  ) : (
    <Skeleton />
  );
}

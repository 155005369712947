import { routes } from '@seaters-app/constants';

export const getSlugFromUrl = (): string | null => {
  const url = window.location.pathname;

  const hash = window.location.hash;

  if (hash && url === '/') {
    window.open(window.location.href.replace('#!', ''), '_self');
  }

  let slug = url.split('/')[1];

  const querySlug = new URLSearchParams(window.location.search).get(
    'fanGroupSlug'
  );

  const ignoreList = [
    routes.admin,
    routes.security,
    routes.error,
    routes.portofantwerpemployees,
  ];

  if (slug && !ignoreList.includes(slug)) {
    return slug;
  } else if (querySlug) {
    return querySlug;
  } else {
    return null;
  }
};

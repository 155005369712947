import {
  Space,
  Row,
  Col,
  Card,
  Upload,
  UploadProps,
  Form,
  Input,
  notification,
} from 'antd';
import { CSVLink } from 'react-csv';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from '@seaters-app/ui';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'antd/es/modal/Modal';
import { useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import {
  useCreateFanGroupProtectionCode,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { useParams } from 'react-router-dom';
import { ProtectionCodeEntity } from '@seaters-app/constants';
import { useTranslation } from 'react-i18next';

const templateCodesData = [{ code: 'example', maxTimesUsed: 0 }];

enum CodePropertiesEnum {
  CODE = 'code',
  MAX_TIMES_USE = 'maxTimesUsed',
}

const contactProperties = (
  Object.keys(CodePropertiesEnum) as (keyof typeof CodePropertiesEnum)[]
).map((key) => {
  return CodePropertiesEnum[key];
});

interface ImportCodesModalType {
  open: boolean;
  onClose: () => void;
}

export function ImportCodesModal({ ...props }: ImportCodesModalType) {
  const { fanGroupId = '' } = useParams();
  const { fanGroup } = useFanGroupStore();
  const fgId = fanGroupId || fanGroup?.id;
  const { t } = useTranslation();
  const [tableModalIsOpen, setTableModalIsOpen] = useState(false);
  const [columnsBuffer, setColumnsBuffer] =
    useState<ColumnsType<ProtectionCodeEntity>>();
  const [dataSource, setDataSource] = useState<ProtectionCodeEntity[]>([]);

  const methods = useForm<{ codes: ProtectionCodeEntity[] }>({
    mode: 'onBlur',
    defaultValues: { codes: dataSource },
    values: { codes: dataSource },
  });

  const { mutate: createCode, isLoading: isCodeCreating } =
    useCreateFanGroupProtectionCode(fgId);

  const { handleSubmit, control } = methods;

  const defaultColumns: ColumnsType<ProtectionCodeEntity> = [
    {
      title: '#',
      dataIndex: 'key',
    },
  ];

  const uploadProps: UploadProps = {
    accept: '.csv',
    showUploadList: false,

    onChange(info) {
      if (info.file.status !== 'uploading') {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csvRows = (e.target?.result as string)
            .split('\n')
            .filter((row) => !!row);

          const csvHeader = csvRows.shift();

          const columns = csvHeader?.split(';') as CodePropertiesEnum[];

          const data: ProtectionCodeEntity[] = csvRows.map((row, rowIndex) => {
            const splittedRow = row.split(';');

            const mappedObject = {};

            splittedRow.forEach((value, index) => {
              mappedObject[contactProperties[index]] = value;
              mappedObject.key = rowIndex + 1;
            });
            return mappedObject;
          });
          setDataSource(data);

          setColumnsBuffer(
            defaultColumns.concat(
              columns.map((column) => ({
                dataIndex: column,
                key: column,
                title: column[0].toUpperCase() + column.slice(1),
                render: (_, record, index) => {
                  return (
                    <Form.Item
                      name={`codes[${index}].${column}`}
                      style={{ margin: 0 }}
                    >
                      <Controller
                        control={control}
                        name={`codes[${index}].${column}`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              }))
            )
          );

          setTableModalIsOpen(true);
          props.onClose();
        };
        reader.readAsText(info.file.originFileObj as Blob);
      }
    },
  };

  const onSubmit = ({ codes }: { codes: ProtectionCodeEntity[] }) => {
    const codesToCreate: ProtectionCodeEntity[] = Object.values(codes).map(
      (code) => {
        return { ...code, timesUsed: 0 };
      }
    );
    codesToCreate.forEach((code) => {
      createCode(code, {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_codes_created'),
          });
          setTableModalIsOpen(false);
          props.onClose();
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_codes_created'),
          });
        },
      });
    });
  };

  return (
    <>
      <Modal
        {...props}
        onCancel={props.onClose}
        title={t('admin_fan-groups_import-codes-btn')}
        footer={null}
      >
        <Space direction="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Card style={{ height: '100%' }}>
                <Space
                  direction="vertical"
                  align="center"
                  style={{ textAlign: 'center' }}
                >
                  <CSVLink
                    data={templateCodesData}
                    separator={';'}
                    enclosingCharacter={``}
                    filename={'template.csv'}
                  >
                    <Button icon={<DownloadOutlined rev={undefined} />}>
                      {t('download_template_button_text')}
                    </Button>
                  </CSVLink>
                  {t('download_template_info_text')}
                </Space>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ height: '100%' }}>
                <Space
                  direction="vertical"
                  align="center"
                  style={{ textAlign: 'center' }}
                >
                  <Upload {...uploadProps}>
                    <Button
                      type="primary"
                      icon={<UploadOutlined rev={undefined} />}
                      size="large"
                    />
                  </Upload>
                  {t('import_codes_info_text')}{' '}
                </Space>
              </Card>
            </Col>
          </Row>
        </Space>
      </Modal>

      <Modal
        title={t('check_imported_data_text')}
        open={tableModalIsOpen}
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{
          htmlType: 'submit',
        }}
        onCancel={async () => {
          props.onClose();
          setTableModalIsOpen(false);
        }}
        width="100%"
      >
        <Form component={false} initialValues={dataSource}>
          <Table
            columns={columnsBuffer}
            dataSource={dataSource}
            pagination={false}
            scroll={{
              x: true,
            }}
          />
        </Form>
      </Modal>
    </>
  );
}

import {
  Card,
  Popconfirm,
  Space,
  Statistic,
  Typography,
  notification,
  theme,
} from 'antd';
import styles from './tickets.module.css';
import React from 'react';

import { Button } from '@seaters-app/ui';
import {
  TransactionEntity,
  WaitingListEntity,
  waitingListsKeys,
} from '@seaters-app/constants';
import {
  queryClient,
  useCheckoutStore,
  useDeleteTransaction,
  useRejectWaitingListAsFan,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { RefetchingStatus } from '../../WaitingListFanView';
const { Countdown } = Statistic;

const { Text, Title } = Typography;

interface AcceptTicketsComponentProps {
  waitingList: WaitingListEntity;
  setIsActive: (v: boolean) => void;
  counter: number;
  setStartRefetching: (v: RefetchingStatus) => void;
  isMobile?: boolean;
}

function AcceptTicketsComponent({
  waitingList,
  counter,
  setIsActive,
  isMobile,
  setStartRefetching,
}: AcceptTicketsComponentProps) {
  const {
    price,
    currency: { symbol },
    freeWaitingList,
    waitingListId,
    position,
    seat,
  } = waitingList;

  const { setCheckoutShown } = useCheckoutStore();
  const { t } = useTranslation();
  const { mutate: rejectWaitingListAsFan } =
    useRejectWaitingListAsFan(waitingListId);

  const transaction = queryClient.getQueryData<TransactionEntity>(
    waitingListsKeys.transaction(waitingListId)
  );

  const { mutate: deleteTransaction } = useDeleteTransaction();

  const { token } = theme.useToken();
  const deadline = new Date(seat?.assignmentExpirationDate ?? '').getTime();

  const rejectWaitingList = () => {
    rejectWaitingListAsFan(
      { waitingListId },
      {
        onSuccess: () => {
          setStartRefetching(null);
          notification.success({
            message: t('booking_cancellation_notification_success'),
          });
          if (transaction) {
            deleteTransaction({ wlId: waitingListId });
          }
          setIsActive(false);
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('booking_cancellation_notification_error'),
          });
        },
      }
    );
  };

  const handleAcceptTickets = () => {
    //TODO OPEN checkout modal or redirect to checkout route
    setCheckoutShown(true); // opens checkout modal
  };

  return (
    <Card
      className={!isMobile ? styles.card : styles.cardMobile}
      bordered={!isMobile}
      title={
        <Space className={styles.cardTitleAccept}>
          <Text strong={false}>{t('waitinglist_quantity')}</Text>
          <Text strong>{position?.numberOfSeats ?? counter}</Text>
        </Space>
      }
    >
      <div className={styles.content}>
        {seat?.assignmentExpirationDate ? (
          <Countdown
            className={styles.countdown}
            title={t('seat_infos_confirm_notice-warning-free')}
            value={deadline}
          />
        ) : (
          <Text>{t('seat_infos_confirm_notice-warning-free')}</Text>
        )}

        <Button
          size="large"
          type="primary"
          style={{ width: '100%' }}
          onClick={handleAcceptTickets}
        >
          {t('seat_infos_confirmation_accept-free-btn')}
        </Button>
        <Popconfirm
          title={t('cancel_tickets_request_button_text')}
          description={t('cancel_tickets_request_confirmation_text')}
          cancelText={t('general_no')}
          onConfirm={rejectWaitingList}
          onOpenChange={() => console.log('open change')}
        >
          <Button
            size="large"
            type="link"
            style={{ width: '100%' }}
            color={token.colorPrimary}
          >
            {t('cancel_tickets_request_button_text')}
          </Button>
        </Popconfirm>
        {!freeWaitingList && (
          <div className={styles.prices}>
            <div className={styles.price}>
              <Text>{`${price?.facialPrice} ${symbol} x ${position?.numberOfSeats} guests`}</Text>
              <Text>
                {position?.numberOfSeats * Number(price?.facialPrice)} {symbol}
              </Text>
            </div>
            <div className={styles.price}>
              <Text>{t('waitinglist_fees')}</Text>
              <Text>
                {Number(price?.fee)} {symbol}
              </Text>
            </div>
            <div className={styles.price}>
              <Text strong>{t('waitinglist_total_price')}</Text>
              <Text strong>
                {position?.numberOfSeats * Number(price?.facialPrice) +
                  Number(price?.fee)}{' '}
                {symbol}
              </Text>
            </div>
            {!!position?.numberOfParkingTickets && (
              <Text>
                {t('waitinglist_parking_quantity')}
                {': '}
                {position?.numberOfParkingTickets}
              </Text>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

export default AcceptTicketsComponent;

import React, { useState } from 'react';
import {
  getSingleTranslation,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { Avatar, Input, Space, Table } from 'antd';
import {
  SearchOutlined,
  EditOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import styles from './tabs.module.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FanGroupOwnerWaitingListEntity,
  WaitingListEntity,
} from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import Column from 'antd/es/table/Column';
import { useWishLists } from '../../wish-lists/hooks/useWishLists';
import { getGroupedBadges } from '../helpers/getGroupedBadges';
import { debounce } from 'lodash';
import ManageWLBadgesModal from './ManageWLBadgesModal';

function WLBadges() {
  const { fanGroup } = useFanGroupStore();
  const { t } = useTranslation();
  const {
    wishLists,
    wishListsLoading,
    itemOffset,
    maxPageSize,
    setQuery,
    refetch,
  } = useWishLists({ fanGroupId: fanGroup?.id });

  const currentPage = itemOffset ? itemOffset / maxPageSize + 1 : 1;
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    refetch();
  };

  const handleChangePage = (newPage: number, pageSize: number) =>
    setQuery({
      itemOffset: pageSize !== maxPageSize ? 0 : (newPage - 1) * maxPageSize,
    });

  const pagination = {
    total: wishLists?.totalSize,
    current: currentPage,
    pageSize: maxPageSize,
    onChange: handleChangePage,
  };

  const handleDebouncedSearch = debounce((e) => {
    setQuery({ search: e.target.value, itemOffset: 0 });
  }, 500);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Input
          style={{ width: '30%' }}
          size="large"
          prefix={<SearchOutlined rev={undefined} />}
          onChange={handleDebouncedSearch}
        />
        <Button
          size="large"
          type="primary"
          onClick={openModal}
          icon={<SettingOutlined rev={undefined} />}
        >
          {t('manage_wl_badges_title')}
        </Button>
      </div>
      <Table
        rowKey={(wishList: FanGroupOwnerWaitingListEntity) =>
          wishList.waitingListId
        }
        dataSource={wishLists?.items}
        loading={wishListsLoading}
        pagination={pagination}
        tableLayout="fixed"
      >
        <Column
          title={t('mwl_tab-wl')}
          dataIndex="experienceName"
          key="name"
          width="30%"
          render={(experienceName, wl: FanGroupOwnerWaitingListEntity) => {
            return (
              <Space>
                {getSingleTranslation(experienceName)
                  ? getSingleTranslation(experienceName)
                  : wl.event.eventName}
              </Space>
            );
          }}
        />

        <Column
          title={t('admin_badges')}
          dataIndex="waitingListBadges"
          key="waitingListBadges"
          render={(waitingListBadges) => {
            return (
              <Space size="small">
                {getGroupedBadges(waitingListBadges)?.map((badge) => (
                  <Avatar
                    className={styles.avatar}
                    size="large"
                    src={badge?.entities[0].badge.displayedLogoUrl}
                  />
                ))}
              </Space>
            );
          }}
        />
        <Column
          title=""
          dataIndex="details"
          key="details"
          align="right"
          render={(name, wishList: WaitingListEntity) => {
            return (
              <Space size="small">
                <Link to={`${wishList.waitingListId}`}>
                  <Button type="link" icon={<EditOutlined rev={undefined} />}>
                    {t('admin_item_edit')}
                  </Button>
                </Link>
              </Space>
            );
          }}
        />
      </Table>
      <ManageWLBadgesModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
}

export default WLBadges;

import {
  ListEntity,
  CurrencyCodeEntity,
  currencyCodesKeys,
} from '@seaters-app/constants';
import { useQuery } from '@tanstack/react-query';
import { fetchCurrencyCodes } from '../api';

export const useCurrencies = () => {
  const params = {
    itemOffset: 0,
    maxPageSize: 80,
  };

  const { data: currencies } = useQuery<ListEntity<CurrencyCodeEntity>, Error>(
    currencyCodesKeys.list(params),
    () => fetchCurrencyCodes(params),
    {
      keepPreviousData: true,
    }
  );

  const currenciesOptions = currencies?.items.map((currencyItem) => ({
    id: currencyItem.code,
    name: currencyItem.symbol,
  }));

  return {
    currenciesOptions,
  };
};

import {
  waitingListsKeys,
  WLPositionEntity,
  ListEntityV2,
  SeatEntity,
  PositionDetailsEntity,
  AxiosAPIError,
  SortedPaginationQueryV2,
  UseUpdatePositionTimeOutsType,
  SeatDetailsEntity,
  AttendeesInfoValidator,
  PaymentInfo,
  waitingListInvitationsKeys,
} from '@seaters-app/constants';

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  assignPosition,
  assignPositionWithParking,
  declineSeatsPosition,
  deleteFGOWishListSeat,
  fetchFGOWaitingListPositions,
  fetchFGOWaitingListPositionsDetails,
  fetchFGOWaitingListSeats,
  fetchFGOWaitingListSeatsDetails,
  updateFanAttendeesInfo,
  unmarkParkingPosition,
  updateParkingPosition,
  updatePositionTimeOuts,
  updateSeatsPosition,
  assignPositionWithoutSeats,
  distributeMoreSeats,
  giveSeats,
  fetchPaymentInfo,
  acceptFanSeatsAsFGO,
  updateFanAttendeesInfoAsFGO,
  updateGuestAttendeesInfoAsHost,
  giveSeatsParking,
} from '../api';
import { notification } from 'antd';
import { t } from 'i18next';

export const useFGOWishListPositions = (
  wishListId: string,
  params: SortedPaginationQueryV2
) =>
  useQuery<ListEntityV2<WLPositionEntity>, Error>(
    waitingListsKeys.positionsList(wishListId, params),
    () => fetchFGOWaitingListPositions(wishListId, params),
    {
      refetchOnWindowFocus: false,
    }
  );

export const useDeclineSeatsPosition = (wishListId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ positionId }: { positionId: string }) =>
      declineSeatsPosition(wishListId, positionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.positions(wishListId));
      },
    }
  );
};

export const useUpdateSeatsPosition = (
  wishListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ positionId: string; nrOfSeats: number }>,
  { positionId: string; nrOfSeats: number },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ positionId, nrOfSeats }: { positionId: string; nrOfSeats: number }) =>
      updateSeatsPosition(wishListId, positionId, nrOfSeats),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.positions(wishListId));
      },
    }
  );
};

export const useUpdateParkingPosition = (wishListId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      positionId,
      nbrOfParkingTickets,
    }: {
      positionId: string;
      nbrOfParkingTickets: number;
    }) => updateParkingPosition(wishListId, positionId, nbrOfParkingTickets),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.positions(wishListId));
      },
    }
  );
};

export const useUnmarkParkingPosition = (wishListId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ positionId }: { positionId: string }) =>
      unmarkParkingPosition(wishListId, positionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.positions(wishListId));
      },
    }
  );
};

export const useAssignPositionsWithParking = (wishListId: string) => {
  return useMutation(({ positionId }: { positionId: string }) =>
    assignPositionWithParking(wishListId, positionId)
  );
};

export const useAssignPositions = (wishListId: string) => {
  return useMutation(({ positionId }: { positionId: string }) =>
    assignPosition(wishListId, positionId)
  );
};

export const useAssignPositionsWithoutSeats = (wishListId: string) => {
  return useMutation(({ positionId }: { positionId: string }) =>
    assignPositionWithoutSeats(wishListId, positionId)
  );
};

export const useFGOWishListSeats = (
  wishListId: string,
  params: SortedPaginationQueryV2
) =>
  useQuery<ListEntityV2<SeatEntity>, Error>(
    waitingListsKeys.seatsList(wishListId, params),
    () => fetchFGOWaitingListSeats(wishListId, params),
    {
      refetchOnWindowFocus: false,
    }
  );

export const useAcceptFanSeatsAsFGO = (wishListId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (fanId: string) => acceptFanSeatsAsFGO(wishListId, fanId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.seats(wishListId));
      },
      onError: (err) => {
        notification.error({
          message: t('notification_error_tickets_accepted'),
          description: t(err?.response?.data?.message),
        });
      },
    }
  );
};

export const useFGOWishListPositionsDetails = (wishListId: string) =>
  useQuery<PositionDetailsEntity, Error>(
    waitingListsKeys.positionsDetails(wishListId),
    () => fetchFGOWaitingListPositionsDetails(wishListId),
    {
      refetchOnWindowFocus: false,
    }
  );

export const useFGOWishListSeatsDetails = (wishListId: string) =>
  useQuery<SeatDetailsEntity, Error>(
    waitingListsKeys.seatsDetails(wishListId),
    () => fetchFGOWaitingListSeatsDetails(wishListId),
    {
      refetchOnWindowFocus: false,
    }
  );

export const useUpdatePositionTimeOuts = (
  wishListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<UseUpdatePositionTimeOutsType>,
  UseUpdatePositionTimeOutsType,
  unknown
> => {
  return useMutation(({ fanId, body }: UseUpdatePositionTimeOutsType) =>
    updatePositionTimeOuts(wishListId, fanId, body)
  );
};

export const useDeleteFGOWishListSeat = (
  wishListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ fanId: UseUpdatePositionTimeOutsType['fanId'] }>,
  { fanId: UseUpdatePositionTimeOutsType['fanId'] },
  unknown
> => {
  return useMutation(
    ({ fanId }: { fanId: UseUpdatePositionTimeOutsType['fanId'] }) =>
      deleteFGOWishListSeat(wishListId, fanId)
  );
};

export const useUpdateFanAttendeesInfo = (
  waitingListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ body: AttendeesInfoValidator }>,
  { body: AttendeesInfoValidator },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ body }: { body: AttendeesInfoValidator }) =>
      updateFanAttendeesInfo(waitingListId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};

export const useUpdateFanAttendeesInfoAsFGO = (
  waitingListId: string,
  fanId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ body: AttendeesInfoValidator }>,
  { body: AttendeesInfoValidator },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ body }: { body: AttendeesInfoValidator }) =>
      updateFanAttendeesInfoAsFGO(waitingListId, fanId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.seats(waitingListId));
      },
    }
  );
};

export const useUpdateGuestAttendeesInfoAsHost = (
  waitingListId: string,
  invitationId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ body: AttendeesInfoValidator }>,
  { body: AttendeesInfoValidator },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ body }: { body: AttendeesInfoValidator }) =>
      updateGuestAttendeesInfoAsHost(invitationId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(waitingListId)
        );
      },
    }
  );
};

export const useDistributeMore = (
  waitingListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<{ fanId: string; nrOfSeats: number }>,
  { fanId: string; nrOfSeats: number },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ fanId, nrOfSeats }: { fanId: string; nrOfSeats: number }) =>
      distributeMoreSeats(waitingListId, fanId, nrOfSeats),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};

export const useGiveSeats = (wishListId: string) => {
  return useMutation(({ positionId }: { positionId: string }) =>
    giveSeats(wishListId, positionId)
  );
};

export const useGiveSeatsParking = (wishListId: string) => {
  return useMutation(({ positionId }: { positionId: string }) =>
    giveSeatsParking(wishListId, positionId)
  );
};

export const usePaymentInfo = (waitingListId: string) => {
  return useQuery<PaymentInfo, Error>(
    waitingListsKeys.paymentInfo(waitingListId),
    () => fetchPaymentInfo(waitingListId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

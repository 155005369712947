import React from 'react';

import { FanGroupAccessFormType } from '@seaters-app/constants';

import { FanGroupAccessForm } from '../../fanGroupForm/FanGroupAccessForm';

interface AccessStepProps {
  values: FanGroupAccessFormType;
  setValues: (val: FanGroupAccessFormType) => void;
  createFG: (values: FanGroupAccessFormType) => Promise<void>;
}

export function AccessStep({ values, setValues, createFG }: AccessStepProps) {
  const onSubmit = (values: FanGroupAccessFormType) => {
    setValues(values);
    createFG(values);
  };

  return (
    <FanGroupAccessForm
      fanGroup={values}
      onSubmit={onSubmit}
      setAccessStepValues={setValues}
      isEdit={false}
    />
  );
}

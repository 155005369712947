import { EventStatus } from '@seaters-app/constants';
import {
  colorTextBaseDark,
  colorTextBaseLight,
  errorColor,
  errorColor200,
  errorColor400,
  successColor,
  successColor200,
  successColor400,
} from '@seaters-app/ui';

export const getStatusStyle = (status: EventStatus, mode: string) => {
  switch (status) {
    case EventStatus.PUBLISHED: {
      return {
        borderRadius: '5px',
        padding: '5px 10px',
        color: successColor,
        border: `1px solid ${successColor400}`,
        backgroundColor: successColor200,
      };
    }
    case EventStatus.CLOSED: {
      return {
        borderRadius: '5px',
        padding: '5px 10px',
        color: errorColor,
        border: `1px solid ${errorColor400}`,
        backgroundColor: errorColor200,
      };
    }

    default: {
      return {
        borderRadius: '5px',
        padding: '5px 10px',
        color: mode === 'light' ? colorTextBaseLight : colorTextBaseDark,
        border: `1px solid ${
          mode === 'light' ? colorTextBaseLight : colorTextBaseDark
        }`,
        backgroundColor: `1px solid ${
          mode === 'light' ? colorTextBaseLight : colorTextBaseDark
        }`,
      };
    }
  }
};

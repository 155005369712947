import { baseColor600 } from '@seaters-app/ui';
import { Space, Typography } from 'antd';

const { Text } = Typography;

export const NumberItemVertical = ({
  label,
  value,
  tooltip,
  maxWidth = 70,
  isInverted = false,
  direction = 'vertical',
}: {
  label: string;
  value: number | string;
  tooltip?: string;
  maxWidth?: number | string;
  isInverted?: boolean;
  direction?: 'vertical' | 'horizontal';
}) => {
  return (
    <Space
      size={direction === 'horizontal' ? 20 : 6}
      direction={direction}
      style={{ alignItems: 'center' }}
    >
      <Text style={{ margin: 0, color: baseColor600, fontSize: 20 }}>
        {value}
      </Text>
      <Text
        style={{
          maxWidth,
          fontSize: 14,
          lineHeight: 1.3,
          display: 'flex',
          wordBreak: 'unset',
          textAlign: 'center',
        }}
        type={!isInverted ? 'secondary' : undefined}
      >
        {label}
      </Text>
    </Space>
  );
};

import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker } from '@seaters-app/ui';
import { Col, Form, FormProps, Input, Row, Upload, notification } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { UploadFile } from 'antd/lib';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

const normFile = (e: any) => {
  console.log('Upload event:', e);
  const isLt2M = e.file.size / 1024 / 1024 < 100;
  if (!isLt2M) {
    return undefined;
  }

  if (Array.isArray(e)) {
    return { ...e, status: 'success' };
  }
  return e?.file;
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};

const customRequestHandler = (info: any) => {
  const { file, onSuccess, onError } = info;
  onSuccess();
};

interface TicketPoolFormProps extends FormProps {
  isEdit?: boolean;
  // Define the props for the component here
}

const TicketPoolForm: React.FC<TicketPoolFormProps> = ({
  isEdit,
  form,
  ...props
}) => {
  const { t } = useTranslation();
  const [stateFileList, setStateFileList] = React.useState<UploadFile[]>([]);

  const handleChange = (file) => {
    const isLt2M = file.file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
      notification.error({
        message: t('admin_ticket_pool_tickets-add_validation_title'),
        description: t('admin_ticket_pool_tickets-add_validation_description'),
      });
      setStateFileList([]);
      return false;
    }
    setStateFileList(file.fileList);
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      autoComplete="off"
      form={form}
      {...props}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            label={t('general_name')}
            rules={[
              { required: true, message: t('admin_ticket_pool_form_name') },
            ]}
          >
            <Input placeholder={t('admin_ticket_pool_form_name')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="expirationDate"
            label={t('expiration_date_label')}
            rules={[
              {
                required: true,
                message: t('admin_ticket_pool_form_expiration-date'),
              },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              placeholder={t('admin_ticket_pool_form_expiration-date')}
            />
          </Form.Item>
        </Col>
      </Row>
      {!isEdit && (
        <Form.Item
          name="ticketPdf"
          label={t('admin_ticket_pool_tickets-add')}
          valuePropName="file"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: t('general_label_required') }]}
          shouldUpdate
        >
          <Upload
            name="ticketPdf"
            listType="picture"
            fileList={stateFileList}
            multiple={false}
            customRequest={customRequestHandler}
            maxCount={1}
            onChange={handleChange}
          >
            <Button
              icon={<UploadOutlined rev={undefined} />}
              danger={!!form?.getFieldError('ticketPdf').length}
            >
              {t('admin_fileselector_upload-btn')}
            </Button>
          </Upload>
        </Form.Item>
      )}
    </Form>
  );
};

export default TicketPoolForm;

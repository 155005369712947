import { InboxOutlined } from '@ant-design/icons';
import { Form, Upload } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import type { UploadProps } from 'antd/lib/upload';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

interface XlsxUploadProps {
  messageApi: MessageInstance;
  onUpload: (file: File) => Promise<void>;
}

const XlsxUpload: React.FC<XlsxUploadProps> = ({ messageApi, onUpload }) => {
  const { t } = useTranslation();

  const props: UploadProps = {
    style: {
      position: 'relative',
    },
    name: 'dragger',
    multiple: false,
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;

      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        onUpload(info.file.originFileObj as File);
        // handleUploadFile(info.file.originFileObj);
      } else if (status === 'error') {
        messageApi.open({
          type: 'error',
          content: `${info.file.name} file upload failed.`,
        });
      }
    },

    customRequest: (info: any) => {
      const { onSuccess } = info;
      onSuccess();
    },
  };

  return (
    <Form.Item
      label={t('admin_support_bulk_requests_file_upload')}
      name="dragger"
      rules={[
        {
          required: true,
          message: t('admin_support_bulk_requests_file_upload'),
        },
      ]}
      noStyle
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('upload_file_instruction-1')}</p>
        <p className="ant-upload-hint">{t('upload_file_instruction-2')}</p>
      </Dragger>
    </Form.Item>
  );
};

export default XlsxUpload;

import { AttendeesInfoFormValidator } from '@seaters-app/constants';
import { create } from 'zustand';

interface CheckoutState {
  attendees: AttendeesInfoFormValidator[];

  setAttendeeInfo: (
    attendeeData: AttendeesInfoFormValidator,
    index?: number
  ) => void;

  reset: () => void;
  currentAttendee: number;
  setCurrentAttendee: (index: number) => void;
  setAttendees: (attendees: AttendeesInfoFormValidator[]) => void;
}

export const useAttendeeInfoStore = create<CheckoutState>()((set) => ({
  attendees: [],
  currentAttendee: 0,
  setAttendees: (attendees) => set(() => ({ attendees })),
  setCurrentAttendee: (index) => set(() => ({ currentAttendee: index })),
  reset: () =>
    set(() => ({
      attendees: [],
    })),
  setAttendeeInfo: (attendeeData, index = 0) =>
    set(({ attendees }) => {
      attendees[index] = attendeeData;

      return {
        attendees,
      };
    }),
}));

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface GuestTicketsState {
  tickets: {
    [guestId: string]: number;
  };

  add: (guestId: string, ticketsAmount: string | number) => void;
  remove: (guestId: string) => void;
  increase: (guestId: string) => void;
  decrease: (guestId: string) => void;
}

export const useGuestTicketsStore = create<GuestTicketsState>()(
  devtools((set) => ({
    tickets: {},
    add: (guestId, ticketsAmount) =>
      set((state) => ({
        tickets: { ...state.tickets, [guestId]: Number(ticketsAmount) },
      })),
    remove: (guestId) =>
      set((state) => {
        const copy = state.tickets;
        delete copy[guestId];

        return copy;
      }),
    increase: (guestId) =>
      set((state) => ({
        tickets: {
          ...state.tickets,
          [guestId]: state.tickets[guestId] ? state.tickets[guestId] + 1 : 1,
        },
      })),
    decrease: (guestId) =>
      set((state) => ({
        tickets: {
          ...state.tickets,
          [guestId]: state.tickets[guestId] ? state.tickets[guestId] - 1 : 0,
        },
      })),
  }))
);

export function hexToRgb(hex: string): string | null {
  const hexRegex = /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i;
  if (!hexRegex.test(hex)) {
    console.error('Invalid color code.');
    return null;
  }
  hex = hex.replace('#', '');

  let r: number, g: number, b: number;
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }
  return `rgb(${r}, ${g}, ${b})`;
}

import {
  fetchBadgesCategories,
  getSingleTranslation,
} from '@seaters-app/data-access';

import {
  CategoryEntity,
  ListEntityV2,
  badgesCategoryKeys,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useCategoryOptions = (defaultParams: {
  page: number;
  size: number;
}) => {
  const { data, fetchNextPage, isFetching } = useInfiniteQuery<
    ListEntityV2<CategoryEntity>
  >({
    queryKey: badgesCategoryKeys.all(),
    queryFn: ({ pageParam = defaultParams }) => {
      return fetchBadgesCategories({
        page: pageParam?.page,
        size: pageParam?.size,
      });
    },
    getNextPageParam: (lastPage) => {
      return !lastPage.last
        ? {
            page: lastPage.number + 1,
            size: defaultParams.size,
          }
        : undefined;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const allItems = data?.pages?.flatMap((page) => page.content) || [];

  const options = allItems?.map((category) => {
    return {
      value: category?.id,
      label: getSingleTranslation(category?.name),
      key: category?.id,
    };
  });

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  return {
    categoryOptions: options,
    loadMoreCategories: handleFetchNextPage,
    categoriesFetching: isFetching,
  };
};

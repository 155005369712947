import { create } from 'zustand';

interface CheckoutState {
  isCheckoutShown: boolean;
  setCheckoutShown: (isShown: boolean) => void;
}

export const useCheckoutStore = create<CheckoutState>()((set) => ({
  isCheckoutShown: false,
  setCheckoutShown: (isShown) =>
    set(() => ({
      isCheckoutShown: isShown,
    })),
}));

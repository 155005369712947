import {
  FGOEventFormValidator,
  VenueConfiguration,
  VenueEntity,
} from '@seaters-app/constants';
import { create } from 'zustand';

interface StepsState {
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
  current: number;
  setCurrent: (step: number) => void;
  next: () => void;
  prev: () => void;
  resetSteps: () => void;
  selectedVenue?: VenueEntity;
  venueConfiguration?: VenueConfiguration;
  setVenueConfiguration: (venueConfiguration: VenueConfiguration) => void;
  setSelectedVenue: (venue: VenueEntity) => void;
  eventFormValues?: FGOEventFormValidator;
  setEventFormValues: (eventValues?: FGOEventFormValidator) => void;
}

export const useEventCreateStepper = create<StepsState>((set) => ({
  isModalOpen: false,
  setModalOpen: (isModalOpen: boolean) => set(() => ({ isModalOpen })),
  current: 0,
  setCurrent: (step: number) => set(() => ({ current: step })),
  next: () =>
    set((state: { current: number }) => ({ current: state.current + 1 })),
  prev: () =>
    set((state: { current: number }) => ({ current: state.current - 1 })),
  resetSteps: () => set(() => ({ current: 0 })),
  setSelectedVenue: (venue: VenueEntity) =>
    set(() => ({ selectedVenue: venue })),
  setVenueConfiguration: (venueConfiguration: VenueConfiguration) =>
    set(() => ({ venueConfiguration })),
  setEventFormValues: (eventFormValues?: FGOEventFormValidator) =>
    set(() => ({ eventFormValues })),
}));

import { AxiosError } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

function queryErrorHandler(error: AxiosError) {
  // error is type unknown because in js, anything can be an error (e.g. throw(5))
  // const title =
  //   error instanceof Error ? error.message : 'error connecting to server';

  if (
    [400, 403, 404].includes(Number(error?.response?.status)) ||
    Number(error?.response?.status) >= 500
  ) {
    Sentry.captureException(error, {
      extra: {
        stack: error.stack,
      },
    });
  }
}

export function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        onError: queryErrorHandler,
      },
      mutations: {
        onError: queryErrorHandler,
      },
    },
  });
}

export const queryClient = generateQueryClient();

import {
  ListEntity,
  LanguageEntity,
  languagesKeys,
  LanguagesQuery,
} from '@seaters-app/constants';
import { useQuery } from '@tanstack/react-query';
import { fetchLanguages } from '../api/languages';

export const useFetchLanguages = (params: LanguagesQuery) =>
  useQuery<ListEntity<LanguageEntity>, Error>(
    languagesKeys.list(params),
    () => fetchLanguages(params),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

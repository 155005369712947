import {
  getSingleTranslation,
  useAddBadgeToWishList,
  useDeleteBadgeFromWishList,
  useFetchFanGroupOwnerWaitingList,
  useFetchWishListBadges,
  useUnarchiveBadgeForWishList,
} from '@seaters-app/data-access';
import { Button, baseColor600 } from '@seaters-app/ui';
import {
  Avatar,
  Breadcrumb,
  Checkbox,
  Flex,
  Form,
  Select,
  Space,
  Spin,
  Typography,
  theme,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './../styles.module.css';
import { BadgeStatusEnum, BadgeInstance, routes } from '@seaters-app/constants';
import { useState } from 'react';
import { getGroupedBadges } from '../helpers/getGroupedBadges';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useFGBadges } from '../../fan-groups/[id]/badges/useFGBadges';
import { getBadgeStatus } from '../helpers/getBadgeStatus';
import { WishListBadgeStatusTag } from '@seaters-app/ui-shared';
import Footer from '../../wish-lists/create/components/Footer';
import { useCurrentStep } from '../../wish-lists/create/hooks/useCurrentStep';
const { Title, Text } = Typography;
const { Option } = Select;

export function AddBadgeToWL({
  hideSubheader = false,
  create = false,
}: {
  hideSubheader?: boolean;
  create?: boolean;
}) {
  const { token } = theme.useToken();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const params = { page: 1, size: 20 };
  const { wishListId = '' } = useParams();
  const [selectedBadge, setSelectedBadge] = useState('');
  const { data: waitingList } = useFetchFanGroupOwnerWaitingList(wishListId);

  const { data: badges } = useFetchWishListBadges(wishListId, params);

  // ***  For WL creation flow ****

  const { next, prev } = useCurrentStep();
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const onSubmit = () => {
    next();
  };

  // ***

  const { badges: fanGroupBadges } = useFGBadges();

  const badgesOptions = fanGroupBadges?.map((badge) => ({
    label: getSingleTranslation(badge.name),
    value: badge.id,
    avatar: badge.displayedLogoUrl,
  }));

  const { mutate: addBadge } = useAddBadgeToWishList();

  const { mutate: unarchiveBadge } = useUnarchiveBadgeForWishList(wishListId);
  const { mutate: deleteBadge } = useDeleteBadgeFromWishList();

  const isDefaultChecked = (
    attribute: 'VISIBLE' | 'JOINABLE',
    item: {
      entities: BadgeInstance[];
      badgeId: string;
    }
  ) => {
    return (
      !!item?.entities?.find((e) => e.attribute === attribute) &&
      item?.entities?.find((e) => e.attribute === attribute)?.status ===
        BadgeStatusEnum.ACTIVE
    );
  };

  const handleChange = (
    e: CheckboxChangeEvent,
    item: {
      entities: BadgeInstance[];
      badgeId: string;
    },
    attribute: 'VISIBLE' | 'JOINABLE'
  ) => {
    if (e.target.checked) {
      if (item?.entities?.find((e) => e.attribute === attribute)) {
        unarchiveBadge(
          item?.entities?.find((e) => e.attribute === attribute)?.id ?? ''
        );
      } else {
        addBadge({
          wishListId: wishListId,
          body: {
            badgeAttribute: attribute,
            badgeId: item.badgeId,
            status: BadgeStatusEnum.ACTIVE,
          },
        });
      }
    } else {
      deleteBadge({
        wishListId: wishListId,
        badgeId:
          item?.entities?.find((e) => e.attribute === attribute)?.id ?? '',
      });
    }
  };

  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
    >
      <div>
        {!hideSubheader && (
          <div className={styles.headerWrap}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/${routes.admin}/${routes.badges}`}>
                  {t('admin_badges')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="..">{t('main_breadcrumbs-wl')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('add_badge_to_wl_title')}</Breadcrumb.Item>
            </Breadcrumb>
            <Title level={3} className={styles.title}>
              {t('add_badge_to_wl_title')}
            </Title>
          </div>
        )}
        {create && (
          <p style={{ color: baseColor600, marginBottom: 24 }}>
            {t('wl_badge_info_tip')}
          </p>
        )}
        {waitingList ? (
          <>
            <Space direction="vertical" style={{ width: '100%' }} size={16}>
              {!hideSubheader && (
                <>
                  <Text strong>{t('mwl_tab-wl')}:</Text>
                  <Space>
                    <Text style={{ fontSize: 16 }}>
                      {getSingleTranslation(
                        waitingList?.experienceName,
                        lang
                      ) ?? waitingList?.event.eventName}
                    </Text>
                  </Space>
                </>
              )}

              <Text strong>{t('select_badge_label_text')}</Text>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                onClear={() => {
                  setSelectedBadge('');
                }}
                onSelect={(item, option) =>
                  setSelectedBadge(option.value?.toString() ?? '')
                }
                style={{ width: '30%' }}
                size="large"
                placeholder={t('search_badge_placeholder')}
                options={badgesOptions}
                optionRender={({ data }) => {
                  return (
                    <>
                      <Avatar src={data.avatar} size="small" /> {data?.label}
                    </>
                  );
                }}
              />
              <Button
                disabled={!selectedBadge}
                onClick={() => {
                  addBadge({
                    wishListId: wishListId,
                    body: {
                      badgeAttribute: 'VISIBLE',
                      badgeId: selectedBadge,
                      status: BadgeStatusEnum.ACTIVE,
                    },
                  });
                  setSelectedBadge('');
                }}
                type="primary"
              >
                {t('add_badge_button_text')}
              </Button>
            </Space>

            {badges && (
              <div className={styles.badgesContainer}>
                {getGroupedBadges(badges.content)?.map((item) => (
                  <div
                    style={{ border: `1px solid ${token.colorBorder}` }}
                    key={item?.badgeId}
                    className={styles.badgeCard}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Space size={10}>
                        <Avatar
                          className={styles.avatar}
                          size="large"
                          src={item.entities[0].badge.displayedLogoUrl}
                        />
                        <Text strong className={styles.title}>
                          {getSingleTranslation(item.entities[0].badge.name)}
                        </Text>
                      </Space>
                      <div
                        style={{
                          marginLeft: 'auto',
                          marginRight: 0,
                          color: token.colorError,
                        }}
                      >
                        <WishListBadgeStatusTag status={getBadgeStatus(item)} />
                      </div>
                    </div>
                    <Space direction="vertical" size={10}>
                      <Checkbox
                        defaultChecked={isDefaultChecked('VISIBLE', item)}
                        onChange={(e) => handleChange(e, item, 'VISIBLE')}
                      >
                        {t('badge_attribute_visible')}
                      </Checkbox>
                      <Checkbox
                        defaultChecked={isDefaultChecked('JOINABLE', item)}
                        onChange={(e) => handleChange(e, item, 'JOINABLE')}
                      >
                        {t('badge_joinable')}
                      </Checkbox>
                    </Space>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <div style={{ textAlign: 'center', width: '100%' }}>
            <Spin />
          </div>
        )}
      </div>
      {create && (
        <Form onFinish={onSubmit}>
          <Footer
            handlePrevStep={prev}
            saveAsDraft={() => {
              setSaveAsDraft(true);
            }}
          />
        </Form>
      )}
    </Flex>
  );
}

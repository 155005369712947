import { BillingVariables } from '@seaters-app/constants';

export const computeVat = (
  distributionFee: number,
  billingVariables: BillingVariables
) => {
  const vatRate = parseFloat(billingVariables.vatRate || '0') * 100;

  return Math.round(distributionFee * vatRate) / 100 || 0;
};

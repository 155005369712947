import { useIsFetching } from '@tanstack/react-query';

interface IUsePagination {
  totalSize?: number;
  pageSize: number;
  current: number;
}

interface PaginationConfig {
  total?: number;
  pageSize: number;
  current: number;
  showTotal: (total: number) => string;
  disabled: boolean;
  showSizeChanger: boolean;
}

export const usePagination = ({
  pageSize,
  current,
  totalSize,
}: IUsePagination): PaginationConfig => {
  const fetching = !!useIsFetching();

  const pagination: PaginationConfig = {
    total: totalSize,
    pageSize,
    current,
    showTotal: (total: number) => `Total ${total} items`,
    disabled: fetching,
    showSizeChanger: true,
  };

  return pagination;
};

import {
  FanGroupCustomInformation,
  GuestValidatorSchema,
  generateConditionalGuestCustomInfoValidatorSchema,
} from '@seaters-app/constants';

export const useValidator = (fgCustomInfo?: FanGroupCustomInformation[]) => {
  const fields = fgCustomInfo?.map((item) => {
    return { name: item.semanticName, isRequired: item.mandatory };
  });

  const GuestCustomInfoValidatorSchema =
    generateConditionalGuestCustomInfoValidatorSchema(fields);

  const GuestFormValidatorSchema = GuestValidatorSchema.merge(
    GuestCustomInfoValidatorSchema
  );
  return { GuestFormValidatorSchema };
};

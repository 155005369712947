import React from 'react';

import { Spin, theme } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './styles.module.css';

type LoaderType = {
  show?: boolean;
};

export const Loader: React.FC<LoaderType> = ({
  show = false,
  ...props
}): JSX.Element | null => {
  const { token } = theme.useToken();

  if (!show) return null;

  return (
    <div
      style={{
        background: token.colorWhite,
      }}
      className={styles.loader}
    >
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 42 }} spin rev={undefined} />
        }
      />
    </div>
  );
};

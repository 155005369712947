import {
  CategoriesQuery,
  endpoints,
  InterestCategoryEntity,
  InterestEntity,
  InterestsQuery,
  ListEntityV2,
  FanAttributesQuery,
  V2Prefix,
  FanAttributesEntity,
  FanAttributesSearchQuery,
  FanAttributeEntity,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchProfileInterests = (
  params: InterestsQuery
): Promise<ListEntityV2<InterestEntity>> =>
  api.get(`${V2Prefix}/${endpoints.fan}/${endpoints.interests}`, { params });

export const fetchInterestsCategories = (
  params: CategoriesQuery
): Promise<ListEntityV2<InterestCategoryEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.interests}/${endpoints.categories}`,
    { params }
  );

export const fetchFanAttributes = (
  params: FanAttributesQuery
): Promise<ListEntityV2<FanAttributesEntity>> =>
  api.get(`${V2Prefix}/${endpoints.fan}/${endpoints.fanAttributes}`, {
    params,
  });

export const searchFanAttributes = (
  params: FanAttributesSearchQuery
): Promise<Array<FanAttributeEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.fanAttributes}/${endpoints.search}`,
    {
      params,
    }
  );

export const linkFanAttribute = (id: string): Promise<FanAttributesEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.fan}/${endpoints.fanAttributes}/${id}/${endpoints.link}`
  );

export const linkFanAttributeByName = (
  name: string
): Promise<FanAttributesEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.fan}/${endpoints.fanAttributes}/${endpoints.linkByName}`,
    { name }
  );

export const unlinkFanAttribute = (id: string): Promise<FanAttributesEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.fan}/${endpoints.fanAttributes}/${id}/${endpoints.unlink}`
  );

export const likeInterest = (id: string): Promise<InterestEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.fan}/${endpoints.interests}/${id}/${endpoints.like}`
  );

export const dislikeInterest = (id: string): Promise<InterestEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.fan}/${endpoints.interests}/${id}/${endpoints.dislike}`
  );

export const neutralInterest = (id: string): Promise<InterestEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.fan}/${endpoints.interests}/${id}/${endpoints.neutral}`
  );

import { z } from 'zod';

export const PositionTimeoutsValidatorSchema = z.object({
  voucherExpirationTimeOutInMs: z.number(),
  positionExpirationTimeOutMs: z.number(),
  paidSeatExpirationTimeOutInMs: z.number(),
});

export type PositionTimeoutsValidator = z.infer<
  typeof PositionTimeoutsValidatorSchema
>;

export type UseUpdatePositionTimeOutsType = {
  fanId: string | React.Key;
  body: PositionTimeoutsValidator;
};

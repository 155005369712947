import { useFetchTicketPools } from '@seaters-app/data-access';
import { Checkbox, Flex, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { useQueryParams, withDefault, NumberParam } from 'use-query-params';
import { TicketPool } from '@seaters-app/constants';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Button } from '@seaters-app/ui';
import CreateTicketPool from './CreateTicketPool';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import EditTicketPool from './EditTicketPool';

const { Text } = Typography;

interface TicketPoolsProps {
  id: string;
}

const StatusColumn: React.FC<{ locked: boolean }> = ({ locked }) => {
  const { t } = useTranslation();
  return (
    <Checkbox disabled checked={locked}>
      {t('locked_label')}
    </Checkbox>
  );
};

const TicketPools: React.FC<TicketPoolsProps> = ({ id }) => {
  const { t } = useTranslation();

  const { ticketingSystemId = '' } = useParams<{ ticketingSystemId: string }>();

  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
  const [ticketPoolToEdit, setTicketPoolToEdit] = useState<string | null>(null);

  const [query, setQuery] = useQueryParams({
    size: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
  });

  const { data: ticketPools, isLoading } = useFetchTicketPools(id, {
    itemOffset: (query.page - 1) * query.size,
    maxPageSize: query.size,
  });

  const pagination = {
    pageSize: query.size,
    current: query.page,
    total: ticketPools?.totalSize,
    showSizeChanger: true,
  };

  const columns: ColumnsType<TicketPool> = [
    {
      title: t('general_name'),
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: t('expiration_date_label'),
      dataIndex: 'expirationDate',
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      title: t('canceled_seats_label'),
      dataIndex: ['uploadConfiguration', 'cancelledSeats'],
    },
    {
      title: t('distributed_seats_label'),
      dataIndex: ['uploadConfiguration', 'distributedSeats'],
    },
    {
      title: t('total_seats_label'),
      dataIndex: ['uploadConfiguration', 'totalSeats'],
    },
    {
      title: t('status_label'),
      dataIndex: ['uploadConfiguration', 'locked'],
      key: 'status',
      render: (locked: boolean) => <StatusColumn locked={locked} />,
    },
    {
      title: t('general_actions_text'),
      dataIndex: 'id',
      key: 'actions',
      render: (id: string, ticketPool) => {
        return (
          <Button
            type="link"
            icon={<EditOutlined rev={undefined} />}
            onClick={() => setTicketPoolToEdit(id)}
          >
            {t('admin_item_edit')}
          </Button>
        );
      },
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setQuery({
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  const showDrawer = () => {
    setOpenCreateDrawer(true);
  };

  const onCloseCreateDrawer = () => {
    setOpenCreateDrawer(false);
  };

  const onCloseEditDrawer = () => {
    setTicketPoolToEdit(null);
  };

  return (
    <>
      <Table<TicketPool>
        loading={isLoading}
        onChange={handleTableChange}
        pagination={pagination}
        columns={columns}
        dataSource={ticketPools?.items}
        title={() => (
          <Flex align="center" justify="space-between">
            <Text strong>{t('admin_ticket_pools')}</Text>
            <Button type="primary" onClick={showDrawer}>
              {t('admin_ticket_pools-add')}
            </Button>
          </Flex>
        )}
      />
      <CreateTicketPool
        onClose={onCloseCreateDrawer}
        open={openCreateDrawer}
        ticketingSystemId={ticketingSystemId}
      />
      {ticketPoolToEdit && (
        <EditTicketPool
          onClose={onCloseEditDrawer}
          ticketPoolId={ticketPoolToEdit}
          open={!!ticketPoolToEdit}
        />
      )}
    </>
  );
};

export default TicketPools;

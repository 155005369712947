import { googleMapsApiKey } from '@seaters-app/constants';

export const getTimezone = async (latitude: number, longitude: number) => {
  const timestamp = Math.floor(Date.now() / 1000);
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${googleMapsApiKey}`
    );
    const data = await response.json();

    if (response.ok) {
      return data.timeZoneId;
    } else {
      console.error('Failed to retrieve timezone:', data.status);
    }
  } catch (error) {
    console.error('Failed to retrieve timezone:', error);
  }
};

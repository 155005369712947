import { InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { PaymentSystem } from '@seaters-app/constants';
import { useDeletePaymentSystem } from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import { Popconfirm, Space, notification, theme } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TableActionsProps {
  system: PaymentSystem;
  handleDetailPaymentSystem: () => void;
}

const TableActions: React.FC<TableActionsProps> = ({
  system,
  handleDetailPaymentSystem,
}) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const { mutateAsync: deleteSystem } = useDeletePaymentSystem();

  const handleDeletePaymentSystem = () =>
    deleteSystem(system.id, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_removed'),
        });
      },
      onError: () => {
        notification.error({
          message: t('notification_error_ticketing_system_remove'),
        });
      },
    });

  return (
    <Space>
      <Button
        type="link"
        icon={<InfoCircleOutlined rev={undefined} />}
        onClick={handleDetailPaymentSystem}
      >
        {t('admin_tabs_details')}
      </Button>

      <Popconfirm
        title={t('admin_payment-system_delete_title')}
        onConfirm={handleDeletePaymentSystem}
      >
        <Button
          type="link"
          color={token.colorError}
          icon={<DeleteOutlined rev={undefined} />}
        >
          {t('wl_delete-btn')}
        </Button>
      </Popconfirm>
    </Space>
  );
};

export default TableActions;

import { useState } from 'react';
import {
  getSingleTranslation,
  useArchiveBadge,
  useFetchBadgesCategories,
} from '@seaters-app/data-access';
import {
  Avatar,
  Popconfirm,
  Select,
  Space,
  Table,
  notification,
  theme,
} from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styles from './tabs.module.css';
import { Link, NavLink } from 'react-router-dom';
import {
  BadgeEntity,
  BadgeStatusEnum,
  CategoryEntity,
  routes,
} from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import Column from 'antd/es/table/Column';
import { useBadges } from './hooks/useBadges';
import { DefaultOptionType } from 'antd/es/select';
import { WishListBadgeStatusTag } from '@seaters-app/ui-shared';
import { useTranslation } from 'react-i18next';

function AllBadges() {
  const { token } = theme.useToken();
  const [selectedCategory, setSelectedCategory] =
    useState<DefaultOptionType | null>(null);
  const { t } = useTranslation();
  const { badges, isLoading, handleTableChange, pagination } = useBadges(
    selectedCategory?.id
  );
  const categoriesParams = { size: 10, page: 1 };

  const { data: categories } = useFetchBadgesCategories(categoriesParams);

  const { mutateAsync: deleteBadge } = useArchiveBadge();

  const handleDeleteBadge = (badgeId: string) =>
    deleteBadge(badgeId, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_badge_archived'),
        });
      },
      onError: () => {
        notification.error({
          message: t('notification_error_badge_archived'),
        });
      },
    });

  const categoryOptions = categories?.content?.map(
    (category: CategoryEntity) => ({
      label: getSingleTranslation(category.name),
      value: getSingleTranslation(category.name),
      id: category.id,
    })
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Select
          allowClear
          value={selectedCategory}
          showSearch
          onSelect={(item, option) => {
            setSelectedCategory(option);
          }}
          onClear={() => {
            setSelectedCategory(null);
          }}
          style={{ width: '30%' }}
          size="large"
          placeholder={t('filter_category_placeholder')}
          options={categoryOptions}
        />
        <NavLink to={`../${routes.create}`}>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined rev={undefined} />}
          >
            {t('add_badge_button_text')}
          </Button>
        </NavLink>
      </div>
      <Table
        rowKey={(badge: BadgeEntity) => badge.id}
        dataSource={badges}
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        tableLayout="fixed"
      >
        <Column
          title={t('admin_name')}
          dataIndex="name"
          key="name"
          width="30%"
          render={(name, badge: BadgeEntity) => {
            return (
              <Space>
                <Avatar
                  className={styles.avatar}
                  size="large"
                  src={badge.displayedLogoUrl}
                />
                {getSingleTranslation(name)}
              </Space>
            );
          }}
        />
        <Column
          title={t('admin_user_status')}
          dataIndex="status"
          key="status"
          width="20%"
          render={(status: BadgeStatusEnum) => {
            return <WishListBadgeStatusTag status={status} />;
          }}
        />

        <Column
          title={t('admin_category')}
          dataIndex="categoryId"
          key="categoryId"
          width="20%"
          render={(categoryId) => {
            const category = categories?.content?.find(
              (category) => category.id === categoryId
            );
            return category ? (
              <Space>{getSingleTranslation(category?.name)}</Space>
            ) : (
              <Space>{t('no_category_text')}</Space>
            );
          }}
        />

        <Column
          title=""
          dataIndex="details"
          key="details"
          align="right"
          render={(name, badge: BadgeEntity) => {
            return (
              <Space size="small">
                <Link to={`../${badge.id}`}>
                  <Button
                    disabled={badge.status === BadgeStatusEnum.ARCHIVED}
                    type="link"
                    icon={<EditOutlined rev={undefined} />}
                  >
                    {t('admin_item_edit')}
                  </Button>
                </Link>
                <Popconfirm
                  title={t('delete_badge_button_text')}
                  description={t('delete_badge_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() => handleDeleteBadge(badge.id)}
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    disabled={badge.status === BadgeStatusEnum.ARCHIVED}
                    type="link"
                    color={token.colorError}
                    icon={<DeleteOutlined rev={undefined} />}
                  >
                    {t('mwl_wl_fan-remove-btn')}
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
    </>
  );
}

export default AllBadges;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AccessCodeCustomInfoValidationSchema,
  CustomInfoItem,
  ProtectionCodeCustomInfo,
} from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export interface AccessCodeCustomInfoForm {
  accessCodeCustomInfos: CustomInfoItem[];
}

interface ManageCustomInfoModalProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  handleLinkCustomInfoToCode: (values: AccessCodeCustomInfoForm) => void;
  customInfos?: ProtectionCodeCustomInfo[];
  handleUnlinkCustomInfo: (semanticName: string) => void;
  fgCustomInfosOptions?: {
    label: string;
    value: string;
    disabled?: boolean;
  }[];
}

function ManageCustomInfoModal({
  isOpen,
  setOpen,
  handleLinkCustomInfoToCode,
  customInfos,
  fgCustomInfosOptions,
  handleUnlinkCustomInfo,
}: ManageCustomInfoModalProps) {
  const { t } = useTranslation();
  const onCancel = () => {
    setOpen(false);
  };

  const initialValues = customInfos
    ? {
        accessCodeCustomInfos: customInfos.map((customInfo) => ({
          fgCustomInformationId: customInfo.semanticName,
          informationValue: customInfo.informationValue,
          semanticName: customInfo.semanticName,
        })),
      }
    : {
        accessCodeCustomInfos: [],
      };

  const methods = useForm<AccessCodeCustomInfoForm>({
    mode: 'onBlur',
    resolver: zodResolver(AccessCodeCustomInfoValidationSchema),
    defaultValues: initialValues,
  });

  const { handleSubmit, control, setValue, getValues } = methods;

  const {
    fields,
    remove: removeField,
    append: appendField,
  } = useFieldArray({
    control,
    name: 'accessCodeCustomInfos',
  });

  return (
    <Modal
      title={t('link_custom_info_to_protected_code_title')}
      open={isOpen}
      onCancel={onCancel}
      onOk={handleSubmit(handleLinkCustomInfoToCode)}
      okText={t('general_save-btn')}
      width={800}
    >
      <FormProvider {...methods}>
        <Form
          name="basic"
          layout="vertical"
          size="middle"
          autoComplete="off"
          initialValues={initialValues}
        >
          <>
            {fields.map((item, i) => {
              return (
                <Row
                  style={{ verticalAlign: 'baseline' }}
                  gutter={24}
                  key={item.id}
                >
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      label={t('fan_tabs_custom_info')}
                      name={`accessCodeCustomInfos.${i}.fgCustomInformationId`}
                    >
                      <Controller
                        control={control}
                        name={`accessCodeCustomInfos.${i}.fgCustomInformationId`}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              onSelect={(_, option) => {
                                setValue(
                                  `accessCodeCustomInfos.${i}.semanticName`,
                                  option.label
                                );
                              }}
                              options={fgCustomInfosOptions?.map((option) => ({
                                label: option.label,
                                disabled:
                                  !!getValues().accessCodeCustomInfos.find(
                                    (info) =>
                                      info.fgCustomInformationId ===
                                      option.value
                                  ),
                                value: option.value,
                                key: option.value,
                              }))}
                            />
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label={t('matrix_header_information')}
                      name={`accessCodeCustomInfos.${i}.informationValue`}
                    >
                      <Controller
                        control={control}
                        name={`accessCodeCustomInfos.${i}.informationValue`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label={t('semantic_name_filed_label')}
                      name={`accessCodeCustomInfos.${i}.semanticName`}
                    >
                      <Controller
                        control={control}
                        name={`accessCodeCustomInfos.${i}.semanticName`}
                        render={({ field }) => <Input {...field} disabled />}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="hidden">
                      <MinusCircleOutlined
                        rev={undefined}
                        onClick={() => {
                          removeField(i); // Remove from react-hook-form
                          handleUnlinkCustomInfo(item.semanticName);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  appendField({
                    fgCustomInformationId: '',
                    informationValue: '',
                    semanticName: '',
                  })
                }
                block
                icon={<PlusOutlined rev={undefined} />}
              >
                {t('add_custom_info_btn_text')}
              </Button>
            </Form.Item>
          </>
        </Form>
      </FormProvider>
    </Modal>
  );
}

export default ManageCustomInfoModal;

import React, { useState } from 'react';
import { Flex, Popconfirm, Space, Table, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FanGroupMember } from '@seaters-app/constants';
import { Button, errorColor } from '@seaters-app/ui';
import Column from 'antd/es/table/Column';
import { useFans } from '../badges/tabs/hooks/useFans';
import Search from 'antd/es/input/Search';
import AddFanModal from './AddFanModal';
import {
  useDeleteFanGroupMember,
  useFanGroupStore,
} from '@seaters-app/data-access';
const { Title, Text } = Typography;

function Fans() {
  const { t } = useTranslation();

  const {
    handleTableChange,
    pagination,
    fans,
    isLoading,
    queryParams,
    totalFansCount,
    setPaginationParams,
  } = useFans();
  const [isAddFanModalOpen, setAddFanModalOpen] = useState(false);
  const { fanGroup } = useFanGroupStore();
  const { mutate: deleteFan } = useDeleteFanGroupMember(fanGroup?.id ?? '');

  const handleDeleteFGMember = (fanId: string) => {
    deleteFan(
      { userId: fanId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_fg_member_removed'),
          });
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_fg_member_removed'),
          });
        },
      }
    );
  };
  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
    >
      <Title level={2}>
        {t('fans_tab-fans')} {fans ? `(${totalFansCount})` : ''}
      </Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Search
          defaultValue={queryParams.search}
          id="searchInput"
          placeholder={t('cwl_event_search-btn')}
          allowClear
          onSearch={(search) => {
            setPaginationParams({
              ...queryParams,
              search,
              page: 0,
            });
          }}
          style={{ maxWidth: 300 }}
          size="middle"
        />
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined rev={undefined} />}
          onClick={() => setAddFanModalOpen(true)}
        >
          {t('add_fan_button_text')}
        </Button>
      </div>

      <Table
        rowKey={(fan: FanGroupMember) => fan.fanId}
        dataSource={fans}
        loading={isLoading}
        pagination={pagination}
        tableLayout="fixed"
        onChange={handleTableChange}
      >
        <Column
          title={t('admin_user_role_fan')}
          dataIndex="fan"
          key="fan"
          width="20%"
          render={(_, fan: FanGroupMember) => {
            return (
              <Text>
                {fan.firstName} {fan.lastName}
              </Text>
            );
          }}
        />
        <Column
          title={t('fans_list_tbl-email')}
          dataIndex="fanEmail"
          key="fanEmail"
          width="30%"
        />
        <Column
          dataIndex="fanId"
          key="details"
          width="30%"
          align="right"
          render={(fanId) => {
            return (
              <Space size="small">
                <Link to={`${fanId}`}>
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined rev={undefined} />}
                  >
                    {t('admin_tabs_details')}
                  </Button>
                </Link>
                <Popconfirm
                  title={t('delete_fg_member_title')}
                  description={t('delete_fg_member_confirmation_text')}
                  cancelText={t('general_no')}
                  onConfirm={() => handleDeleteFGMember(fanId)}
                  onOpenChange={() => console.log('open change')}
                >
                  <Button
                    type="text"
                    color={errorColor}
                    icon={<CloseOutlined rev={undefined} />}
                  >
                    {t('wl_delete-btn')}
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
      {isAddFanModalOpen && (
        <AddFanModal
          isAddFanModalOpen={isAddFanModalOpen}
          setAddFanModalOpen={setAddFanModalOpen}
        />
      )}
    </Flex>
  );
}

export default Fans;

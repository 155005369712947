import React from 'react';
import {
  Typography,
  notification,
  Popconfirm,
  Skeleton,
  Tabs,
  TabsProps,
  theme,
  Flex,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styles from '../[id]/fanGroupLayout.module.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserRole, routes } from '@seaters-app/constants';
import {
  getSingleTranslation,
  useAppUserStore,
  useDeleteFanGroup,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';

const { Title } = Typography;

export function FGOGroupConfigLayout() {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAppUserStore();
  const isAdmin = user?.roles.includes(UserRole.ADMIN);

  const { fanGroup } = useFanGroupStore();
  const { mutateAsync: deleteFanGroup } = useDeleteFanGroup();
  const onChange = (key: string) => {
    navigate(key);
  };

  const items: TabsProps['items'] = [
    {
      key: routes.general,
      label: t('admin_tabs_general'),
    },
    {
      key: routes.personalization,
      label: t('wl_personalization_tab'),
    },
    {
      key: routes.access,
      label: t('fgc_tab-access'),
    },
    {
      key: routes.filterConfig,
      label: t('admin_tabs_filter_config'),
    },
  ];

  // Notifications tab is accessible only for Admins

  const adminItems: TabsProps['items'] = items.concat([
    {
      key: routes.notifications,
      label: t('admin_notifications'),
    },
    {
      key: routes.accessRequests,
      label: t('fan_tabs_access-requests'),
    },
  ]);

  const tabsToShow = isAdmin ? adminItems : items;
  // Handlers

  const handleDeleteFanGroup = (fanGroupId: string) =>
    deleteFanGroup(
      { fanGroupId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_fg_removed'),
          });
          navigate('..');
        },
        onError: () => {
          notification.error({
            message: t('notification_error_fg_removed'),
          });
        },
      }
    );

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
      gap={12}
    >
      <div className={styles.subheader}>
        <Title level={2}>{getSingleTranslation(fanGroup?.name ?? [])}</Title>
        {fanGroup?.id && (
          <Popconfirm
            title={t('delete_fg_confirm_label')}
            description={t('delete_fg_confirm_description')}
            cancelText={t('general_no')}
            onConfirm={() => handleDeleteFanGroup(fanGroup.id)}
            onOpenChange={() => console.log('open change')}
          >
            <Button
              size="large"
              type="text"
              icon={<DeleteOutlined rev={undefined} />}
              color={token.colorError}
            >
              {t('remove_fg_button_text')}
            </Button>
          </Popconfirm>
        )}
      </div>
      <Tabs
        activeKey={pathname.split('/').pop()}
        items={tabsToShow}
        onChange={onChange}
      />
      {fanGroup ? <Outlet /> : <Skeleton />}
    </Flex>
  );
}

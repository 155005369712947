import { ChoicesQuery, questionChoicesKeys } from '@seaters-app/constants';
import {
  fetchGuestQuestionChoices,
  fetchQuestionChoices,
  fetchQuestionChoicesWithoutHeaders,
  getSessionToken,
} from '@seaters-app/data-access';
import { useQueries } from '@tanstack/react-query';

export const useFetchAllQuestionsChoices = (
  questionIds: string[] | [],
  isGuest: boolean
) => {
  const auth = getSessionToken();

  const params: ChoicesQuery = {
    size: 5000,
  };

  const code = new URLSearchParams(window.location.search).get('code');
  const token = new URLSearchParams(window.location.search).get('token'); // SURVEY AFTER EVENT

  const queries = questionIds.map((questionId) => {
    return {
      queryKey: questionChoicesKeys.listPerQuestion(questionId),
      queryFn:
        (!auth && !isGuest) || token
          ? () =>
              fetchQuestionChoicesWithoutHeaders(questionId, {
                ...params,
                code: token ?? '',
              })
          : isGuest && code
          ? () => fetchGuestQuestionChoices(questionId, { ...params, code })
          : () => fetchQuestionChoices(questionId, params),
    };
  });
  return useQueries({
    queries: queries,
  });
};

import {
  BadgeEntity,
  WaitingListEntity,
  SEATERS_STATIC_URL,
  WaitingListStatusEnum,
} from '@seaters-app/constants';

export const getMockedWishList = (
  badge: Partial<BadgeEntity>
): Partial<WaitingListEntity> => {
  const item = {
    groupId: '2ad5e829-de85-42bd-8ce0-5c0fe13f9d49',
    groupName: {
      en: 'PanencoFanGROUP',
    },
    translatedGroupName: 'PanencoFanGROUP',
    groupShortName: {
      en: 'panenco 1',
    },
    translatedShortGroupName: 'panenco 1',
    groupCategories: [],
    groupProfileImageUrl: `${SEATERS_STATIC_URL}file-0f7a20ee-116f-42dd-8195-18f084a86b9e.png`,
    groupSlug: 'test',
    groupTermsAndConditions: {
      en: '',
    },
    groupPrivacyPolicy: {
      en: '',
    },
    groupRulesLinks: {
      en: '',
    },
    groupHelpLinks: {
      en: '',
    },
    groupOptInPlatformTexts: {
      en: '',
    },
    groupOptInPartnersTexts: {
      en: '',
    },
    eventName: {
      en: 'Night of the Proms',
    },
    translatedEventName: 'Night of the Proms',

    translatedEventShortName: 'Night of the Proms',
    eventStartDate: '2024-12-31T20:00:00.000+01:00',
    eventImageUrl:
      'https://static.qa-seaters.com/miscellaneous/file-7b855cf5-2236-45ac-9d8d-5207d2b86227.png',
    waitingListImageUrl: undefined,
    venueName: {
      en: 'The Schorre',
    },
    translatedVenueName: 'The Schorre',
    // venueShortName: {
    //   en: 'The Schorre',
    // },
    translatedVenueShortName: 'The Schorre',
    venueCity: {
      en: 'Boom',
    },
    translatedVenueCity: 'Boom',
    venueImageUrl:
      'https://static.qa-seaters.com/tomorrowvenue-7fd750ff-7d75-4f5a-a480-d6d66ea8bf71.jpg',
    waitingListId: '29fa04c6-3404-4296-ab23-41597aa30318',
    price: {
      facialPrice: '10.00',
      numberOfSeats: 1,
      totalFacialPrice: '10.00',
      feeExcVat: '0.00',
      feeVat: '0.00',
      fee: '0.00',
      total: '10.00',
      originalPrice: '10.00',
      discountAmount: '0',
      discountPercentage: '0',
      formattedFacialPrice: '€10.00',
      formattedTotalFacialPrice: '€10.00',
      formattedFeeExcVat: '€0.00',
      formattedFeeVat: '€0.00',
      formattedFee: '€0.00',
      formattedTotal: '€10.00',
      formattedOriginalPrice: '€10.00',
      formattedDiscountAmount: '€0.00',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
    },
    displayName: 'guest management',
    freeWaitingList: false,
    waitingListStatus: WaitingListStatusEnum.OPEN,
    feeCalculationParameters: {
      distributionRate: '0.00',
      minDistributionFee: '0.00',
      maxDistributionFee: '0.00',
      vatRate: '0.00',
    },
    position: null,
    seat: null,
    accessMode: 'PUBLIC',
    request: null,
    protectionCodeExplanation: '',
    directSalesEnabled: false,
    seatDistributionMode: 'VOUCHER',
    eventEndDate: '2025-01-01T12:00:00.000+01:00',
    minNumberOfSeatsPerPosition: 1,
    maxNumberOfSeatsPerPosition: 1,
    translatedEventDescription: 'Big party',
    eventRequiredAttendeeInfo: [
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'company',
      'language',
    ],
    keywords: {
      en: '',
    },
    positionsDistributionMode: 'wl_positions_distribution_mode_fifs',
    fixedNumberOfSeatsPerPosition: 1,
    participationEndDate: null,
    termsAndConditionFileURL: null,
    termsAndConditionExpirationDate: null,
    voucherText: {
      en: '',
    },
    groupRankAndLikelihoodHidden: true,
    visibilityRadius: null,
    visibilityRadiusEnabled: false,
    experienceName: {
      en: '',
    },
    translatedExperienceName: '',
    eventId: '6f6d12ca-3265-486f-b71f-3c66b51300a9',
    showFreePrice: false,
    highlighted: false,
    highlightedStartDate: null,
    highlightedEndDate: null,
    displayMode: 'CLASSIC',
    invitationMode: 'ENABLED',
    assignationMode: null,
    maxNumberOfSeatsPerHost: 25,
    specialGuestEnabled: false,
    publicProperties: null,
    waitingListJoinInfoFanView: {
      state: 'PUBLIC',
      badgeFanViews: [badge],
    },
    invitations: [],
    seatCategory: 'guest management',
  };
  return item;
};

import { AnswersReportBody, Job } from '@seaters-app/constants';
import {
  downloadFileWithCustomName,
  useGenerateSurveyReportUrl,
  useJob,
} from '@seaters-app/data-access';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDownloadSurveyAnswers = (slug: string, wishListId: string) => {
  const [xlsxUrl, setXlsxUrl] = useState<string | null>(null);

  const [answersLoading, setAnswersLoading] = useState(false);
  const [messageApi] = message.useMessage();

  const [jobId, setJobId] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const { data: job } = useJob(jobId || '');
  const { mutate: generateSurveyReport } = useGenerateSurveyReportUrl();
  const { t } = useTranslation();

  const onSuccess = (res: Job) => {
    setIsGenerating(true);
    setAnswersLoading(false);
    setJobId(res.id);
    messageApi.destroy('startingJob');
    messageApi.open({
      key: 'startingJob',
      type: 'success',

      content: t('message_job_started_successfully'),
    });
  };

  const handleExportAnswers = (values: AnswersReportBody) => {
    setAnswersLoading(true);

    generateSurveyReport(
      {
        fangroupSlug: slug ?? '',
        wishlistId: wishListId,
        extensionPoint: values.extensionPoint,
        positionStatus: values.positionStatus,
        userLanguage: values.userLanguage,
      },
      {
        onSuccess,
      }
    );
  };

  const handleDownload = (name: string) => {
    downloadFileWithCustomName(xlsxUrl ?? '', name);
  };

  useEffect(() => {
    if (
      job?.latestXlsxSingedUrl &&
      !['running', 'pending'].includes(job.status)
    ) {
      setXlsxUrl(job?.latestXlsxSingedUrl);
      setIsGenerating(false);
    }
  }, [job?.latestXlsxSingedUrl]);

  useEffect(() => {
    if (job?.status === 'failed') {
      messageApi.open({
        key: 'jobFailed',
        type: 'error',
        content: t('report_generation_error'),
      });
      setIsGenerating(false);
    }
  }, [job?.status]);

  return {
    job,
    xlsxUrl,
    answersLoading,
    isGenerating,
    handleExportAnswers,
    handleDownload,
  };
};

import React from 'react';
import styles from './styles.module.css';
import { Skeleton, theme } from 'antd';

function FanAttributeCardSkeleton() {
  const { token } = theme.useToken();
  return (
    <div
      style={{ border: `1px solid ${token.colorBorder}`, gap: 8 }}
      className={styles.card}
    >
      <Skeleton.Avatar size="large" active />
      <Skeleton.Input size="small" active block />
    </div>
  );
}

export default FanAttributeCardSkeleton;

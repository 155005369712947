import {
  endpoints,
  FanEntity,
  FanValidator,
  ListEntity,
  PasswordValidator,
  PhoneValidator,
  EmailValidator,
  UserListEntity,
  UsersQuery,
  UserValidator,
  UserOwnershipEntity,
  PaginationQuery,
  V2Prefix,
  PaginationQueryV2,
  ListEntityV2,
  seatersAdminPrefix,
  VerifyCodeValidator,
  UserRole,
  FanGroupBySlug,
  FanCustomInfo,
  FanGroupCustomInformation,
} from '@seaters-app/constants';
import { api } from '../axios-instance';
export interface FanCustomInfoBody {
  fgCustomInformationId: string;
  informationValue: string;
  semanticName: string;
}

export const fetchAdminUsers = (
  params: UsersQuery
): Promise<ListEntity<UserListEntity>> =>
  api.put(`${endpoints.users}`, {
    maxResults: params.maxPageSize,
    [params.searchType as string]: params.query,
    offset: params.itemOffset,
  });

export const fetchUser = (id: string): Promise<UserListEntity> =>
  api.get(`${endpoints.users}/${id}`);

export const fetchUserGroups = (
  userId: string
): Promise<ListEntityV2<FanGroupBySlug>> =>
  api.get(`${V2Prefix}/${endpoints.users}/${userId}/${endpoints.groups}`);

export const fetchApproverGroups = (
  userId: string
): Promise<ListEntityV2<UserOwnershipEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.users}/${userId}/${endpoints.fanGroupApprover}`
  );

export const fetchUserRolesForCurrentFG = (
  fanGroupId: string
): Promise<{ roles: UserRole[] }> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.groups}/${fanGroupId}/${endpoints.userRoles}`
  );

export const fetchUserOwnerships = (
  id: string,
  params: PaginationQuery
): Promise<ListEntity<UserOwnershipEntity>> =>
  api.get(`${endpoints.users}/${id}/${endpoints.ownerships}`, { params });

export const fetchUserHostRoles = (
  id: string,
  params: PaginationQueryV2
): Promise<ListEntityV2<UserOwnershipEntity>> =>
  api.get(`${V2Prefix}/${endpoints.users}/${id}/${endpoints.fanGroupHost}`, {
    params,
  });

export const fetchFan = async (): Promise<FanEntity> => {
  const response: FanEntity = await api.get(endpoints.fan);

  return response;
};

export const createUser = (body: UserValidator): Promise<UserListEntity> =>
  api.post(endpoints.users, body);

export const createUserOwnership = (body: {
  userId: string;
  fanGroupId: string;
}): Promise<ResponseType> =>
  api.post(`${endpoints.users}/${body.userId}/${endpoints.ownerships}`, {
    fanGroupId: body.fanGroupId,
  });

// DELETE https://api.qa-seaters.com/api/seaters-admin/users/userId/ownerships/groupId/

export const deleteUserOwnership = (body: {
  userId: string;
  fanGroupId: string;
}): Promise<ResponseType> =>
  api.delete(
    `${endpoints.users}/${body.userId}/${endpoints.ownerships}/${body.fanGroupId}`,
    {}
  );

export const createUserHostRole = (body: {
  userId: string;
  fanGroupId: string;
}): Promise<ResponseType> =>
  api.post(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.groups}/${body.fanGroupId}/${endpoints.hosts}/${body.userId}`
  );

// DELETE https://api.qa-seaters.com/api/v2/seaters-admin/groups/fanGroupId/hosts/userId/

export const deleteUserHostRole = (body: {
  userId: string;
  fanGroupId: string;
}): Promise<ResponseType> =>
  api.delete(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.groups}/${body.fanGroupId}/${endpoints.hosts}/${body.userId}`,
    {}
  );

export const updateUser = (
  userId: string,
  body: UserValidator
): Promise<UserListEntity> => api.put(`${endpoints.users}/${userId}`, body);

export const unlockUser = (userId: string): Promise<unknown> =>
  api.put(`${V2Prefix}/${endpoints.users}/${userId}/${endpoints.unlock}`);

export const updateFanPassword = (
  body: PasswordValidator
): Promise<FanEntity> =>
  api.put(`${endpoints.fan}/${endpoints.password}`, body);

export const updateFanPhone = (body: PhoneValidator): Promise<FanEntity> =>
  api.put(`${endpoints.fan}/${endpoints.phone}`, body);

export const verifyPhoneBySms = (
  body: VerifyCodeValidator
): Promise<FanEntity> =>
  api.put(`${endpoints.auth}/${endpoints.validate}`, body);

export const updateFanEmail = (body: EmailValidator): Promise<FanEntity> =>
  api.put(`${endpoints.fan}/${endpoints.email}`, body);

export const updateFan = (body: FanValidator): Promise<FanEntity> =>
  api.put(endpoints.fan, body);

export const deleteFan = (): Promise<FanEntity> => api.delete(endpoints.fan);

export const deleteUser = (userId: string) =>
  api.delete(`${endpoints.users}/${userId}`);

export const fetchUserCustomInfo = (
  fanGroupId: string,
  userId: string
): Promise<ListEntityV2<FanCustomInfo>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.customInfo}/users/${userId}`
  );

// POST https://api.qa-seaters.com/api/v2/fan-group-owner/groups/groupId/users/userId/custom-info/

export const addFanCustomInfo = (
  fanGroupId: string,
  userId: string,
  body: FanCustomInfoBody
): Promise<FanGroupCustomInformation> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.usersFGO}/${userId}/${endpoints.customInfo}`,
    body
  );

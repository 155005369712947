import {
  FanGroupOwnerWaitingListEntity,
  SeatDistributionMode,
} from '@seaters-app/constants';
import { hasDefaultText } from '@seaters-app/data-access';
import { notification } from 'antd';
import { t } from 'i18next';

export const checkVoucherText = (wishList?: FanGroupOwnerWaitingListEntity) => {
  // Check if valid voucher text available
  if (wishList?.seatDistributionMode === SeatDistributionMode.VOUCHER) {
    if (!hasDefaultText(wishList.voucherText)) {
      notification.error({
        message: t('cwl_set_err-voucher-text-missing-title'),
        description: t('cwl_set_err-voucher-text-missing-text'),
      });

      return false;
    }
  }

  return true;
};

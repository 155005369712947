import { InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { TicketingSystem } from '@seaters-app/constants';
import { useDeleteTicketingSystem } from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import { Popconfirm, Space, notification, theme } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface TableActionsProps {
  system: TicketingSystem;
}

const TableActions: React.FC<TableActionsProps> = ({ system }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const { mutateAsync: deleteSystem } = useDeleteTicketingSystem();

  const handleDeleteTicketingSystem = () =>
    deleteSystem(
      { systemId: system.id },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_removed'),
          });
        },
        onError: () => {
          notification.error({
            message: t('notification_error_ticketing_system_remove'),
          });
        },
      }
    );

  return (
    <Space>
      <Link to={system.id}>
        <Button type="link" icon={<InfoCircleOutlined rev={undefined} />}>
          {t('admin_tabs_details')}
        </Button>
      </Link>
      <Popconfirm
        title={t('admin_ticketing_system_delete')}
        description={t('admin_ticketing_system_delete_description')}
        onConfirm={handleDeleteTicketingSystem}
      >
        <Button
          type="link"
          color={token.colorError}
          icon={<DeleteOutlined rev={undefined} />}
        >
          {t('wl_delete-btn')}
        </Button>
      </Popconfirm>
    </Space>
  );
};

export default TableActions;

import {
  Table,
  Space,
  Collapse,
  Form,
  InputNumber,
  Typography,
  Divider,
  Modal,
  Tooltip,
  notification,
  theme,
  Grid,
} from 'antd';
import AntLayout from 'antd/es/layout';

import { Button, CollapseButton } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import {
  GuestItem,
  useCreateWaitingListInvitation,
  useFetchHost,
  useFetchWaitingList,
  useFetchWaitingListInvitations,
  useGuestListStore,
  useReserveGuestListStore,
} from '@seaters-app/data-access';
import {
  CheckOutlined,
  MinusCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import Column from 'antd/es/table/Column';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import {
  GuestEntity,
  ImportanceEnum,
  SemanticNameEnum,
  SESSION_STORAGE_TOTAL_TICKETS,
  WaitingListInvitationValidator,
} from '@seaters-app/constants';

import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useState } from 'react';

import TextArea from 'antd/es/input/TextArea';

import './styles.css';
import { useQueryClient } from '@tanstack/react-query';
import { PublicOfficialModal } from '../PublicOfficialModal';
import { InternalEmployeeModal } from '../InternalEmployeeModal';

const { Footer: AntFooter } = AntLayout;
const { Title, Text } = Typography;

dayjs.extend(relativeTime);
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

// Request URL: https://api.qa-seaters.com/api/v2/host/waiting-lists/e2b0fd9f-eebc-4871-bde6-3b76aa95e387/guest/5e63d195-56a0-4cbc-8e0e-d4e76beda903
// payload reserved: {"numberOfSeats":1,"importance":"OPTIONAL","order":0}

// Request URL: https://api.qa-seaters.com/api/v2/host/waiting-lists/e2b0fd9f-eebc-4871-bde6-3b76aa95e387/guest/f89ad14b-978b-4552-bf97-d3a897833dd8
// payload allocated + self included: {"numberOfSeats":1,"numberOfNonAllocatedSeats":2,"importance":"REQUIRED","order":null,"invitationReason":"Reason text"}

// Request URL: https://api.qa-seaters.com/api/v2/host/waiting-lists/e2b0fd9f-eebc-4871-bde6-3b76aa95e387/guest/638ef3e1-3aa3-4db8-b87f-3b7aaba09424
// payload non allocated: {"numberOfSeats":2,"importance":"REQUIRED","order":null}

export function GuestList({
  waitingListId,
  replaceGuestFromReserveToMain,
  isFetching,
}: {
  isFetching?: boolean;
  waitingListId: string;
  replaceGuestFromReserveToMain?: (guests: GuestItem[]) => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const screens = useBreakpoint();

  const { token } = theme.useToken();

  const [allocatedTickets, setAllocatedTickets] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [businessReason, setBusinessReason] = useState<string>();

  const [businessReasonModal, setBusinessReasonModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  // const [specialGuestModal, setSpecialGuestModal] = useState<boolean>(false);
  const [publicOfficialModal, setPublicOfficialModal] =
    useState<boolean>(false);

  const [emptyBusinessReasonModal, setEmptyBusinessReasonModal] =
    useState(false);

  const { data: waitingList } = useFetchWaitingList(waitingListId);

  const { data: host } = useFetchHost();

  const { mutateAsync: createWaitingListInvitation } =
    useCreateWaitingListInvitation(waitingListId);

  const { refetch: refetchInvitations } =
    useFetchWaitingListInvitations(waitingListId);

  const addGuest = useGuestListStore((state) => state.add);
  const reset = useGuestListStore((state) => state.reset);
  const resetReserved = useReserveGuestListStore((state) => state.reset);

  const groupGuests = useGuestListStore((state) => Object.values(state.guests));

  const setTickets = useGuestListStore((state) => state.setTickets);

  const removeGuest = useGuestListStore((state) => state.remove);

  const removeReserveGuest = useReserveGuestListStore((state) => state.remove);

  const reserveGuests = Object.values(
    useReserveGuestListStore((state) => state.guests)
  );

  const reserveListTotal = reserveGuests.length;

  const totalGuestTickets = groupGuests
    .map((el) => el.tickets)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const maxTickets =
    Number(sessionStorage.getItem(SESSION_STORAGE_TOTAL_TICKETS)) || 0;

  const hostGuest = groupGuests.find(
    (guest) => guest.guest.guestId === host?.userId
  );

  const handleAddHostAsGuest = () => {
    if (host) {
      addGuest(
        {
          guestId: host.userId,
          firstName: host.firstName,
          lastName: host.lastName,
          guestEmail: host.email,
          lang: host.language,
          mobileCountryCallingCode: host.mobileCountryCallingCode,
          mobileLocalNumber: host.mobileLocalNumber,
          customInfos: [],
        },
        1
      );
    }
  };

  useEffect(() => {
    if (!groupGuests.length) handleAddHostAsGuest();
  }, [host]);

  useEffect(() => {
    return () => {
      reset();
      resetReserved();
    };
  }, []);

  const seatsLeft = maxTickets - totalGuestTickets;

  useEffect(() => {
    if (seatsLeft >= 0) {
      setAllocatedTickets(seatsLeft);
    }
  }, [totalGuestTickets]);

  const [form] = Form.useForm();

  const showBusinessReasonModal = () => {
    setBusinessReasonModal(true);
  };

  const hideBusinessReasonModal = () => {
    setBusinessReasonModal(false);
  };

  const handleRemoveGuest = async (guest: GuestEntity, tickets: number) => {
    removeGuest(guest.guestId);
    if (replaceGuestFromReserveToMain) {
      replaceGuestFromReserveToMain(reserveGuests);
    }
  };

  const submitRequest = async (specialGuests?: {
    [guestId: string]: string;
  }) => {
    setLoading(true);
    try {
      return await new Promise((resolve) => {
        if (allocatedTickets && host) {
          createWaitingListInvitation(
            {
              guestId: host.userId,
              body: {
                numberOfSeats: 0,
                importance: ImportanceEnum.REQUIRED,
                numberOfNonAllocatedSeats: allocatedTickets,
                invitationReason: businessReason,
                order: 1,
                specialGuestId: specialGuests
                  ? specialGuests[host.userId]
                  : undefined,
              },
            },
            {
              onError: (err) => {
                console.error(err);
                notification.error({
                  message: `Invitation for "to allocate" was not created`,
                  description: err.response?.data.message,
                });
              },
              onSettled: resolve,
            }
          );
        }
        if (reserveGuests.length) {
          reserveGuests.map(({ guest, tickets }, index) => {
            createWaitingListInvitation(
              {
                guestId: guest.guestId,
                body: {
                  numberOfSeats: tickets,
                  importance: ImportanceEnum.OPTIONAL,
                  order: groupGuests.length + 1 + index,
                },
              },

              {
                onError: (err) => {
                  console.error(err);
                  notification.error({
                    message: `Invitation for ${guest.firstName} ${guest.lastName} was not created`,
                    description: err.response?.data.message,
                  });
                },

                onSettled: resolve,
              }
            );
          });
        }
        const hostItem = [
          groupGuests.find(({ guest }) => guest.guestId === host?.userId),
        ];
        const regularGuests = groupGuests.filter(
          (guest) =>
            guest.guest.guestId !== host?.userId && !guest.isToAllocated
        );

        const orderedGuests = [...hostItem, ...regularGuests]
          .filter((guest) => !!guest)
          .map((guest, index) => {
            const guestOrder =
              index === 0
                ? !!hostItem[0]
                  ? 0 // if host order === 0
                  : 2 // if no host, should starts from 2, because 0 is dedicated for the host, 1 - for toAllocate
                : !!hostItem[0]
                ? index + 1
                : index + 2;

            return {
              ...guest,
              order: guestOrder,
            };
          });

        orderedGuests.map(({ guest, tickets, order }) => {
          const body: WaitingListInvitationValidator = {
            numberOfSeats: tickets,
            importance: ImportanceEnum.REQUIRED,
            order: order,
          };
          // to allocated

          if (specialGuests && specialGuests[guest.guestId]) {
            body.specialGuestId = specialGuests[guest.guestId];
          }
          createWaitingListInvitation(
            {
              guestId: guest.guestId,
              body,
            },
            {
              onError: (err) => {
                console.error(err);
                notification.error({
                  message: `Invitation for ${guest.firstName} ${guest.lastName} was not created`,
                  description: err.response?.data.message,
                });
              },
              onSettled: resolve,
            }
          );
        });
      });
    } finally {
      setTimeout(
        () => {
          refetchInvitations();
          setLoading(false);
        },

        3000
      );
    }
  };

  const handleRequest = () => {
    if (allocatedTickets > 0 && !businessReason) {
      setEmptyBusinessReasonModal(true);
    } else if (waitingList?.specialGuestEnabled) {
      // setSpecialGuestModal(true);
    } else {
      setConfirmationModal(true);
    }
  };

  if (!waitingList) {
    return null;
  }

  const handlePublicOfficialModal = () => {
    setPublicOfficialModal(true);
  };

  return (
    <>
      <Collapse
        size="large"
        expandIcon={({ isActive }) => {
          return <CollapseButton isActive={isActive} />;
        }}
        expandIconPosition="end"
        defaultActiveKey={['1']}
        items={[
          {
            key: '1',
            label: `${t('guestlist_invitation_table_title')} (${
              groupGuests.length
            })`,
            children: (
              <Space direction="vertical" style={{ width: '100%' }}>
                <Table
                  loading={isFetching}
                  dataSource={groupGuests}
                  pagination={false}
                  title={
                    !hostGuest
                      ? () => (
                          <Button onClick={handleAddHostAsGuest}>
                            {t('guestlist_add_host_as_guest-btn')}
                          </Button>
                        )
                      : undefined
                  }
                  footer={() => (
                    <Space>
                      <Title level={4} style={{ margin: 0 }}>
                        {`${t('not_allocated')}: `}
                      </Title>
                      <InputNumber disabled min={0} value={seatsLeft} />
                      <Button
                        disabled={!allocatedTickets}
                        onClick={showBusinessReasonModal}
                        icon={
                          businessReason && <CheckOutlined rev={undefined} />
                        }
                        type={businessReason ? 'default' : 'primary'}
                      >
                        {t('guestlist_business_reason')}
                      </Button>
                    </Space>
                  )}
                >
                  <Column
                    key="actions"
                    render={(_, { guest, tickets }: GuestItem) => {
                      return (
                        <Button
                          type="text"
                          onClick={() => handleRemoveGuest(guest, tickets)}
                          icon={<MinusCircleOutlined rev={undefined} />}
                        />
                      );
                    }}
                  />
                  <Column
                    title={t('guestlist_name')}
                    dataIndex="name"
                    key="name"
                    render={(_, { guest }: GuestItem) => {
                      const isPublicOfficial =
                        guest.customInfos.find(
                          (customInfo) =>
                            customInfo.semanticName ===
                            SemanticNameEnum.PUBLIC_OFFICIAL
                        )?.informationValue === 'true';

                      return (
                        <span>
                          {guest.firstName} {guest.lastName}{' '}
                          {isPublicOfficial && (
                            <Tooltip title={t('public_official')}>
                              <SolutionOutlined rev={undefined} />
                            </Tooltip>
                          )}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title={t('guestlist_company')}
                    dataIndex="company"
                    key="company"
                    render={(_, { guest }: GuestItem) => {
                      return (
                        <span>
                          {guest.customInfos?.find(
                            (customInfo) =>
                              customInfo.semanticName ===
                              SemanticNameEnum.COMPANY
                          )?.informationValue || '–'}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title={t('guestlist_seats')}
                    dataIndex="tickets"
                    key="tickets"
                    render={(_, { guest, tickets }: GuestItem) => {
                      const maxValue = tickets + seatsLeft;
                      return (
                        <InputNumber
                          min={waitingList.minNumberOfSeatsPerPosition}
                          max={
                            maxValue > waitingList.maxNumberOfSeatsPerPosition
                              ? waitingList.maxNumberOfSeatsPerPosition
                              : maxValue
                          }
                          defaultValue={
                            waitingList.minNumberOfSeatsPerPosition || 1
                          }
                          onChange={(value) =>
                            setTickets(guest.guestId, Number(value))
                          }
                          value={tickets}
                        />
                      );
                    }}
                  />
                  <Column
                    title={t('guestlist_last_rsvp')}
                    dataIndex="lastInvitation"
                    key="lastInvitation"
                    render={(_, { guest }: GuestItem) => {
                      const date = guest.customInfos?.find(
                        (customInfo) =>
                          customInfo.semanticName === SemanticNameEnum.LAST_RSVP
                      )?.informationValue;

                      return date ? <span>{dayjs(date).fromNow()}</span> : null;
                    }}
                  />
                  <Column
                    title={t('guestlist_attendance')}
                    dataIndex="lastInvitation"
                    key="lastInvitation"
                    render={(_, { guest }: GuestItem) => {
                      return (
                        <span>
                          {`${
                            guest.customInfos?.find(
                              (customInfo) =>
                                customInfo.semanticName ===
                                SemanticNameEnum.ATTENDANCE
                            )?.informationValue || 0
                          }/${
                            guest.customInfos?.find(
                              (customInfo) =>
                                customInfo.semanticName ===
                                SemanticNameEnum.RSVPS
                            )?.informationValue || 0
                          }`}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title={t('guestlist_cost_contact')}
                    dataIndex="lastInvitation"
                    key="lastInvitation"
                    render={(_, { guest }: GuestItem) => {
                      const cost =
                        guest.customInfos?.find(
                          (customInfo) =>
                            customInfo.semanticName ===
                            SemanticNameEnum.CONTACT_COST
                        )?.informationValue || 0;
                      return cost ? (
                        <span>{Number(cost).toFixed(0)} €</span>
                      ) : (
                        '–'
                      );
                    }}
                  />
                </Table>
              </Space>
            ),
          },
        ]}
      />
      {document.getElementById('portalForFooter') &&
        ReactDOM.createPortal(
          <AntFooter
            style={{
              backgroundColor: token.colorBgBase,
              borderTop: `1px solid ${token.colorBorder}`,
              width: '100%',
              padding: '16px 5%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
              zIndex: 10,
            }}
          >
            <Space
              direction={screens.xs ? 'vertical' : 'horizontal'}
              style={{ justifyContent: 'space-between', width: '100%' }}
            >
              <Space>
                <Space>
                  <Text style={{ fontSize: '20px' }} strong>
                    {allocatedTickets}
                  </Text>
                  <Text style={{ fontSize: '20px' }}>{t('in_guestlist')}</Text>
                </Space>
                <Divider type="vertical" />
                <Space>
                  <Text style={{ fontSize: '20px' }} strong>
                    {reserveListTotal}
                  </Text>
                  <Text style={{ fontSize: '20px' }}>{t('in_reserve')}</Text>
                </Space>
              </Space>
              <Space>
                <Button size="large" type="primary" onClick={handleRequest}>
                  {t('guestlist_confirm_selection')}
                </Button>
              </Space>
            </Space>
          </AntFooter>,
          document.getElementById('portalForFooter') as HTMLElement
        )}
      <Modal
        title={t('guestlist_confirm_header')}
        open={businessReasonModal}
        onOk={form.submit}
        onCancel={hideBusinessReasonModal}
        okText={t('button_text_confirm')}
      >
        <Form
          initialValues={{
            businessReason,
          }}
          form={form}
          layout="vertical"
          onFinish={(v) => {
            setBusinessReason(v.businessReason);
            hideBusinessReasonModal();
          }}
        >
          <Form.Item
            label={t('guestlist_business_reason')}
            name="businessReason"
            rules={[
              { required: true, message: 'Please input business reason' },
            ]}
          >
            <TextArea
              showCount
              maxLength={150}
              style={{ height: 120, resize: 'none' }}
              placeholder={t('guestlist_business_reason') as string}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t('guestlist_business_reason')}
        open={emptyBusinessReasonModal}
        onOk={() => {
          setEmptyBusinessReasonModal(false);
          showBusinessReasonModal();
        }}
        onCancel={() => setEmptyBusinessReasonModal(false)}
        okText={t('guestlist_business_reason_missing-btn')}
      >
        <Text>
          {t('guestlist_business_reason_missing', {
            nonallocated: allocatedTickets,
          })}
          {/* You have {allocatedTickets} non allocated seats. Please give us a
          business reason. */}
        </Text>
      </Modal>
      <Modal
        title={t('guestlist_confirm_header')}
        open={confirmationModal}
        onCancel={() => setConfirmationModal(false)}
        footer={[
          <Button type="text" onClick={handlePublicOfficialModal}>
            {t('guestlist_public_official_add-btn')}
          </Button>,
          <Button key="back" onClick={() => setConfirmationModal(false)}>
            {t('button_text_cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={submitRequest}
          >
            {t('button_text_submit')}
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <Text>{t('guestlist_confirm_phrase')}</Text>
          <Text type="secondary">{t('public_official_action')}</Text>
        </Space>
      </Modal>
      {/* <InternalEmployeeModal
        specialGuestModal={specialGuestModal}
        setSpecialGuestModal={setSpecialGuestModal}
        onSubmitForm={submitRequest}
      /> */}
      <PublicOfficialModal
        waitingListId={waitingListId}
        isOpened={publicOfficialModal}
        onClose={() => setPublicOfficialModal(false)}
      />
    </>
  );
}

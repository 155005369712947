import {
  FormTranslationType,
  TranslationEntity,
  VenueFormValues,
} from '@seaters-app/constants';

const convertTranslation = (
  translation: FormTranslationType
): TranslationEntity[] => {
  return Object.keys(translation).map((locale) => ({
    lang: locale,
    text: translation[locale],
  }));
};

export const prepareValues = (values): VenueFormValues => {
  const shortName = convertTranslation(values.shortName);
  const name = convertTranslation(values.name);
  const city = convertTranslation(values.city);
  const country = convertTranslation(values.country);
  const conditions = convertTranslation(values.conditions);
  const latitude = Number(values.latitude);
  const longitude = Number(values.longitude);

  return {
    ...values,
    country,
    shortName,
    name,
    city,
    conditions,
    latitude,
    longitude,
  };
};

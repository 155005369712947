import { QuestionItem, SurveyExtensionPoint } from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import Carousel, { CarouselRef } from 'antd/es/carousel';
import { useEffect, useRef, useState } from 'react';
import { ImportDataModal } from './ImportDataModal';
import { LeftOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons';
import styles from './carousel.module.css';
import { useTranslation } from 'react-i18next';

interface CustomCarouselProps {
  children: JSX.Element[];
  ticketsAmount: number;
  questionItem: QuestionItem;
  setImportedData: (v: { data: any[]; questionId: string }) => void;
  surveyType: SurveyExtensionPoint;
}

export const CustomCarousel = ({
  children,
  ticketsAmount,
  questionItem,
  setImportedData,
  surveyType,
}: CustomCarouselProps) => {
  const { t } = useTranslation();
  const [isImportDataModal, setIsImportDataModal] = useState(false);
  const templateFields = questionItem?.question?.structure?.map((i) => i.name);
  const carouselRef = useRef<CarouselRef>(null);
  const [structuredNumber, setStructuredNumber] = useState(1);

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      setStructuredNumber(structuredNumber - 1);
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setStructuredNumber(structuredNumber + 1);
    }
  };

  useEffect(() => {
    return () => {
      if (carouselRef.current) {
        carouselRef.current.goTo(0);
      }
    };
  }, []);

  return (
    <>
      {(surveyType === SurveyExtensionPoint.AT_CHECKOUT ||
        surveyType === SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST) && (
        <Button
          style={{ marginBottom: 20 }}
          icon={<UploadOutlined rev={undefined} />}
          onClick={() => setIsImportDataModal(true)}
        >
          Import data
        </Button>
      )}
      <Carousel ref={carouselRef}>{children}</Carousel>
      {(surveyType === SurveyExtensionPoint.AT_CHECKOUT ||
        surveyType === SurveyExtensionPoint.BEFORE_PAYMENT ||
        surveyType === SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST) &&
        ticketsAmount > 1 && (
          <div className={styles.carouselControls}>
            <Button
              size="small"
              type="primary"
              icon={<LeftOutlined rev={undefined} />}
              onClick={handlePrev}
              disabled={structuredNumber === 1}
            >
              {t('prev_btn_text')}
            </Button>
            <Button
              size="small"
              type="primary"
              icon={<RightOutlined rev={undefined} />}
              onClick={handleNext}
              disabled={structuredNumber === ticketsAmount}
            >
              {t('button_text_next')}
            </Button>
          </div>
        )}
      <ImportDataModal
        templateFields={[templateFields]}
        open={isImportDataModal}
        onClose={() => setIsImportDataModal(false)}
        setImportedData={setImportedData}
        questionId={questionItem.question.id}
        ticketsAmount={ticketsAmount}
      />
    </>
  );
};

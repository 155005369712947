export const SEATERS_API_BASE_URL = import.meta.env.SEATERS_API_BASE_URL;
export const SEATERS_SUPPORT_API_BASE_URL = import.meta.env
  .SEATERS_SUPPORT_API_BASE_URL;
export const SEATERS_APP_BASE_URL = import.meta.env.SEATERS_APP_BASE_URL;
export const SEATERS_MARKETING_BASE_URL = import.meta.env
  .SEATERS_MARKETING_BASE_URL;
export const FAN_WEB_APP_API_BASE_URL = import.meta.env
  .FAN_WEB_APP_API_BASE_URL;
export const SEATERS_STATIC_URL = import.meta.env.SEATERS_STATIC_URL;
export const SEATERS_TINY_API_KEY = import.meta.env.SEATERS_TINY_API_KEY;

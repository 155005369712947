import {
  QuestionType,
  StructureItemEntity,
  SurveyAnswersResponse,
  SurveyFormEntity,
  SurveyEntity,
  QuestionItem,
} from '@seaters-app/constants';

type StructuredQuestionMultipleTicketsForm = {
  [id: string]: {
    [ticketNumber: string]: {
      [name: string]: string | boolean | null | undefined;
    };
  };
};

const initialValuesForRegularField = (
  curr: QuestionItem,
  surveyAnswers?: SurveyAnswersResponse[]
) => {
  const previousAnswer = surveyAnswers?.filter(
    (answer) => answer?.questionId === curr.question.id
  )[0]?.answers;
  if (previousAnswer?.length) {
    if (previousAnswer?.length > 1) {
      const previousAnswerTranslatedValues = previousAnswer?.reduce(
        (
          acc: {
            [id: string]: {
              offeredAnswerId: string;
              translatedOfferedAnswer: string;
            };
          },
          curr
        ) => {
          if (curr?.offeredAnswerId && curr.translatedOfferedAnswer) {
            acc[curr.offeredAnswerId] = {
              offeredAnswerId: curr.offeredAnswerId,
              translatedOfferedAnswer: curr.translatedOfferedAnswer,
            };
          }

          return acc;
        },
        {}
      );
      const previousAnswerValues = {
        offeredAnswerId: previousAnswer?.map(
          (answer) => answer.offeredAnswerId
        ),
        answerText: previousAnswer?.map((answer) => answer.answerText)[0],
        ...previousAnswerTranslatedValues,
      };

      // If answers were already submitted => set previous answer as initial form values, else => set ''.
      const field = {
        [curr.question.id]: {
          ...previousAnswerValues,
        },
      };
      return field;
    } else if (previousAnswer?.length === 1) {
      const previousAnswerValues = {
        offeredAnswerId: previousAnswer[0].offeredAnswerId,
        answerText: previousAnswer[0].answerText,
        translatedOfferedAnswer: previousAnswer[0].translatedOfferedAnswer,
      };
      const field = {
        [curr.question.id]: {
          ...previousAnswerValues,
        },
      };
      return field;
    }
  } else {
    const field = {
      [curr.question.id]: {
        offeredAnswerId: null,
        answerText: null,
        translatedOfferedAnswer: null,
      },
    };
    return field;
  }
};

const initialValuesForStructuredFields = (
  curr: QuestionItem,
  surveyAnswers?: SurveyAnswersResponse[],
  ticketsAmount?: number
) => {
  const previousAnswersStructured = surveyAnswers?.filter(
    (answer) => answer?.questionId === curr.question.id
  )[0]?.answers;

  const result = Array(ticketsAmount ?? 1)
    .fill(1)
    ?.map((_, i) => {
      const fieldsFromStructuredQuestion = curr.question.structure.reduce(
        (
          accStructured: StructuredQuestionMultipleTicketsForm,
          currStructured: StructureItemEntity
        ) => {
          const previousAnswerValue = previousAnswersStructured
            ? previousAnswersStructured[i]?.structuredAnswer?.filter(
                (field) => field.name === currStructured.name
              )[0]
            : null;

          // If answers were already submitted => set previous answer as initial form values, else => set ''.

          const fieldStructured = {
            [currStructured.name]: previousAnswerValue?.value
              ? previousAnswerValue?.value === 'true'
                ? true
                : previousAnswerValue?.value === 'false'
                ? false
                : previousAnswerValue?.value
              : '',
          };

          accStructured[curr.question.id][`Instance ${i + 1}`] = {
            ...accStructured[curr.question.id][`Instance ${i + 1}`],
            ...fieldStructured,
          };
          return accStructured;
        },
        { [curr.question.id]: {} }
      );
      return fieldsFromStructuredQuestion;
    });

  const multipleTicketsStructuredForm: StructuredQuestionMultipleTicketsForm = {
    [curr.question.id]: {},
  };
  for (let i = 0; i < (ticketsAmount ?? 1); i++) {
    multipleTicketsStructuredForm[curr.question.id][`Instance ${i + 1}`] =
      result[i][curr.question.id][`Instance ${i + 1}`];
  }
  return multipleTicketsStructuredForm;
};

export const getInitialValues = (
  survey: SurveyEntity | undefined,
  surveyAnswers?: SurveyAnswersResponse[],
  ticketsAmount?: number
): SurveyFormEntity | null => {
  if (!survey) return null;

  const result: SurveyFormEntity = survey?.surveyQuestions.reduce(
    (acc, curr) => {
      if (curr.question.type !== QuestionType.STRUCTURED) {
        const field = initialValuesForRegularField(curr, surveyAnswers);
        return { ...acc, ...field };
      } else {
        const structuredField = initialValuesForStructuredFields(
          curr,
          surveyAnswers,
          ticketsAmount
        );
        return { ...acc, ...structuredField };
      }
    },
    {}
  );
  return result;
};

// Example:
// "survey": {
//   "surveyQuestions": [
//       {
//           "question": {
//               "id": "randomId_1",
//               "type": "MULTIPLE_CHOICE",
//         },
//       },
//       {
//           "question": {
//               "id": "randomId_2",
//               "type": "MULTIPLE_CHOICE",
//           },
//       },
//      {
//           "question": {
//               "id": "randomId_3",
//               "type": "STRUCTURED",
//               "structure": [
//                {
//                 "name": "firstName",
//                },
//                {
//                 "name": "email",
//                },
//                {
//                 "name": "lastName",
//                }
//              ]
//            },
//          ]
//         }

// IF surveyAnswers === null , initialValues will be :
// {
//   randomId_1: {
//     offeredAnswerId: '',
//     answerText: ''
//   },
//   randomId_2: {
//     offeredAnswerId: '',
//     answerText: ''
//   },
//   randomId_3: {
//     firstName: '',
//     email: '',
//     lastName: ''
//   },
// }

// If surveyAnswers !== null:
// surveyAnswers = [
//   {
//     questionId: 'randomId_1',
//     answers: [
//       {
//         offeredAnswerId: 'answerId_1',
//         translatedOfferedAnswer: 'Some answer 1',
//         answerText: null,
//         structuredAnswer: [],
//       },
//     ],
//   },
//   {
//     questionId: 'randomId_2',
//     answers: [
//       {
//         offeredAnswerId: null,
//         translatedOfferedAnswer: null,
//         answerText: 'Some answer 2',
//         structuredAnswer: [],
//       },
//     ],
//   },
//   {
//     "questionId": "randomId_3",
//     "answers": [
//         {
//             "offeredAnswerId": null,
//             "translatedOfferedAnswer": null,
//             "answerText": null,
//             "structuredAnswer": [
//                 {
//                     "name": "lastName",
//                     "value": "James"
//                 },
//                 {
//                     "name": "firstName",
//                     "value": "Jane"
//                 },
//                 {
//                     "name": "email",
//                     "value": "email@com"
//                 }
//             ]
//         }
//     ]
// },
// ];
//  Then Initial Values:
// {
//   randomId_1: {
//     offeredAnswerId: 'answerId_1',
//     answerText: null
//   },
//   randomId_2: {
//     offeredAnswerId: null,
//     answerText: 'Some answer 2'
//   },
//   randomId_3: {
//     firstName: 'Jane',
//     email: 'email@com',
//     lastName: 'James'
//   },
// }

const truncateAtPrelastWord = (str: string) => {
  const lastSpaceIndex = str.lastIndexOf(' ', str.lastIndexOf(' '));

  if (lastSpaceIndex !== -1) {
    return str.slice(0, lastSpaceIndex);
  }
  return str;
};

export const defineText = (text: string, prevTextLength: number): string => {
  const DEFAULT_LENGTH = 600;
  const addedSymbolsNumber = 10;
  if (text.length <= DEFAULT_LENGTH) {
    return text;
  }

  if (prevTextLength >= text.length - addedSymbolsNumber) {
    return truncateAtPrelastWord(text);
  }
  const tempElement = document.createElement('div');
  tempElement.innerHTML = text.slice(0, DEFAULT_LENGTH + prevTextLength);

  let textContent = tempElement.textContent || tempElement.innerText;

  textContent = textContent.trim();

  const symbolCount = textContent.length;

  if (
    symbolCount > DEFAULT_LENGTH ||
    text.length < DEFAULT_LENGTH + prevTextLength
  ) {
    const res = text.slice(0, DEFAULT_LENGTH + prevTextLength);
    return `${truncateAtPrelastWord(res)}...`;
  } else {
    return defineText(text, prevTextLength + addedSymbolsNumber);
  }
};

import {
  AccessMode,
  FanGroupAccessFormType,
  FanGroupEntity,
  FanGroupGeneralFormType,
  FanGroupOwnerFanGroupEntity,
  FanGroupPersonalizationFormType,
  FanGroupStatus,
  FormattedValuesWithTranslations,
} from '@seaters-app/constants';
import { initialTranslations } from '../../../utils/helpers/getTranslations';

export const getGeneralInitialValues = (
  fanGroup?: FanGroupEntity | null,
  valuesWithTranslations?: FormattedValuesWithTranslations | null,
  operationParametersValuesWithTranslations?: FormattedValuesWithTranslations | null
): FanGroupGeneralFormType => {
  return fanGroup &&
    valuesWithTranslations &&
    operationParametersValuesWithTranslations
    ? {
        slug: fanGroup.slug,
        clientId: fanGroup.clientId,
        status: fanGroup.status,
        showLogo: fanGroup.showLogo,
        ticketingSystemId: fanGroup.operationParameters.ticketingSystemId,
        optInPlatformHidden: !!fanGroup.operationParameters.optInPlatformHidden,
        optInPartnersHidden: !!fanGroup.operationParameters.optInPartnersHidden,
        ...valuesWithTranslations,
        ...operationParametersValuesWithTranslations,
        billingParameters: fanGroup.billingParameters,
        rankAndLikelihoodHidden: fanGroup.rankAndLikelihoodHidden,
      }
    : {
        name: initialTranslations,
        shortName: initialTranslations,
        slug: '',
        status: FanGroupStatus.DRAFT,
        clientId: '',
        billingParameters: {
          authorizedFanBillingModes: [],
          billingVariables: {
            distributionRate: '0.00',
            maxDistributionFee: '0.00',
            minDistributionFee: '0.00',
            vatRate: '0.00',
          },
          paymentMethods: [],
          paymentSystemId: '',
          prepaidFee: false,
        },
        termsAndConditions: initialTranslations,
        privacyPolicy: initialTranslations,
        rulesLinks: initialTranslations,
        legalNotice: initialTranslations,
        faqLinks: initialTranslations,
        helpLinks: initialTranslations,
        geoFilteringNotificationTitle: initialTranslations,
        geoFilteringNotificationDescription: initialTranslations,
        optInPlatformTexts: initialTranslations,
        optInPartnersTexts: initialTranslations,
        optInPlatformHidden: false,
        optInPartnersHidden: false,
        optInTermsTexts: initialTranslations,
        optInRulesTexts: initialTranslations,
        description: initialTranslations,
        welcomeText: initialTranslations,
        showLogo: true,
        rankAndLikelihoodHidden: true,
      };
};

export const getGeneralInitialValuesFGConfig = (
  fanGroup?: FanGroupOwnerFanGroupEntity | null,
  valuesWithTranslations?: FormattedValuesWithTranslations | null,
  operationParametersValuesWithTranslations?: FormattedValuesWithTranslations | null
): FanGroupGeneralFormType => {
  return fanGroup &&
    valuesWithTranslations &&
    operationParametersValuesWithTranslations
    ? {
        slug: fanGroup.slug,
        showLogo: fanGroup.showLogo,
        optInPlatformHidden: !!fanGroup.optInPlatformHidden,
        optInPartnersHidden: !!fanGroup.optInPartnersHidden,

        ...valuesWithTranslations,
        ...operationParametersValuesWithTranslations,
        billingParameters: fanGroup.billingParameters,
        rankAndLikelihoodHidden: fanGroup.rankAndLikelihoodHidden,
      }
    : {
        name: initialTranslations,
        shortName: initialTranslations,
        slug: '',
        status: FanGroupStatus.DRAFT,
        clientId: '',
        termsAndConditions: initialTranslations,
        privacyPolicy: initialTranslations,
        rulesLinks: initialTranslations,
        legalNotice: initialTranslations,
        faqLinks: initialTranslations,
        helpLinks: initialTranslations,
        geoFilteringNotificationTitle: initialTranslations,
        geoFilteringNotificationDescription: initialTranslations,
        optInPlatformTexts: initialTranslations,
        optInPartnersTexts: initialTranslations,
        optInPlatformHidden: false,
        optInPartnersHidden: false,
        optInTermsTexts: initialTranslations,
        description: initialTranslations,
        welcomeText: initialTranslations,
        showLogo: true,
        rankAndLikelihoodHidden: true,
      };
};

export const getPersonalizationInitialValues =
  (): FanGroupPersonalizationFormType => {
    return {
      color1: {
        hex: '000000',
      },
      profileImageUrl: '',
      logoImageUrl: '',
      coverImageUrl: '',
      backgroundImageUrl: '',
    };
  };

export const getAccessInitialValues = (): FanGroupAccessFormType => {
  return {
    visibility: true,
    accessMode: AccessMode.PUBLIC,
    protectionCodeExplanation: initialTranslations,
  };
};

import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Modal, ModalProps, Spin, Steps, notification } from 'antd';
import { useParams } from 'react-router-dom';
import {
  queryClient,
  useUpdateGuestAttendeesInfoAsHost,
} from '@seaters-app/data-access';
import {
  AttendeesInfoValidator,
  InvitationEntity,
  ListEntityV2,
  SurveyInstanceEntity,
  WaitingListEntity,
  waitingListsKeys,
} from '@seaters-app/constants';
import { StepContentProps, HostStepItemIdEnum, StepType } from './types';
import { SurveyForm } from './SurveyForm';
import { useFormSteps } from './hooks/useFormSteps';
import { useTranslation } from 'react-i18next';
import { AttendeeInfoStep } from './AttendeeInfoStep';

const StepComponent = (props: StepContentProps) => {
  const { filteredSteps, currentStep, ...rest } = props;
  return filteredSteps[currentStep]?.content({ ...rest, currentStep });
};

export interface CheckoutModalHostProps extends ModalProps {
  children?: ReactElement;
  isDisabled?: boolean;
  isLoading?: boolean;
  selectedGuest: InvitationEntity;
  surveyInstances?: ListEntityV2<SurveyInstanceEntity>;
  closeModal: () => void;
}

export function CheckoutModalHost({ open, ...props }: CheckoutModalHostProps) {
  const selectedGuest = props.selectedGuest;
  const closeModal = props.closeModal;
  const { t } = useTranslation();
  const { waitingListId = '' } = useParams();

  const surveys = props?.surveyInstances;
  const waitingList = queryClient.getQueryData<WaitingListEntity>(
    waitingListsKeys.detail(waitingListId)
  );

  const surveyStep: StepType<HostStepItemIdEnum> = {
    key: HostStepItemIdEnum.SURVEY_FORM,
    title: t('survey_title_text'),
    content: (props) => (
      <SurveyForm
        {...props}
        ticketsAmount={selectedGuest?.nbrOfRequestedSeats}
        surveyInstances={surveys}
        selectedFanId={selectedGuest?.guest.guestId}
      />
    ),
    submitText: t('finish_button_text'),
    orderIndex: 2,
  };

  const attendeeInfoStep: StepType<HostStepItemIdEnum> = {
    key: HostStepItemIdEnum.ATTENDEE_INFO,
    title: t('checkout_requiredinfo_steptitle'),
    content: (props) => {
      return (
        <AttendeeInfoStep
          {...props}
          selectedFan={selectedGuest?.guest}
          selectedFanId={selectedGuest?.id}
          requestedSeats={selectedGuest?.nbrOfRequestedSeats}
          closeCheckout={closeModal}
        />
      );
    },
    submitText: t('button_text_next'),
    orderIndex: 3,
  };

  let steps = [surveyStep, attendeeInfoStep];

  const [stepsVisibility, setStepsVisibility] = useState<{
    [key in HostStepItemIdEnum]: boolean;
  }>({
    attendeeInfo: false,
    surveyStep: false,
  });

  const { mutate: updateFanAttendeesInfoAsFGO } =
    useUpdateGuestAttendeesInfoAsHost(waitingListId, selectedGuest?.id ?? '');

  const filteredSteps = steps.filter((el) => stepsVisibility[el.key]);

  const { nextStep, prevStep, currentStep, isLastStep, isLoading } =
    useFormSteps(filteredSteps, selectedGuest?.id, closeModal);

  const handleAttendeeForm = (
    attendeeValues: AttendeesInfoValidator['attendees']
  ) => {
    updateFanAttendeesInfoAsFGO(
      { body: { attendees: attendeeValues } },
      {
        onSuccess: () => {
          nextStep();
        },
        onError: (error) => {
          notification.error({
            message: t('notification_error_attendees_info_send'),
            description:
              `${error?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorDescription}` ??
              error.message,
          });
        },
      }
    );
  };

  const contentStyle: React.CSSProperties = {
    marginTop: 32,
  };

  useEffect(() => {
    if (waitingList) {
      setStepsVisibility({
        ...stepsVisibility,
        [HostStepItemIdEnum.ATTENDEE_INFO]:
          !!waitingList.eventRequiredAttendeeInfo?.length,
        [HostStepItemIdEnum.SURVEY_FORM]: !!surveys?.content.length,
      });
    }
  }, [waitingList, surveys?.content.length]);

  return (
    <Modal
      open={open}
      style={{ maxWidth: 1284 }}
      title={t('checkout_title')}
      closeIcon={null}
      footer={false}
      width={'100%'}
      maskClosable
      onCancel={props.onCancel}
    >
      <Spin spinning={isLoading}>
        {!waitingList?.freeWaitingList && (
          <Alert
            style={{ margin: '12px 0' }}
            message={t('paid_wl_checkout_alert_message')}
            type="warning"
          />
        )}

        {filteredSteps.length > 1 ? (
          <Steps
            current={currentStep}
            items={filteredSteps.sort((a, b) => a.orderIndex - b.orderIndex)}
          />
        ) : null}
        <div style={contentStyle}>
          <StepComponent
            next={nextStep}
            prev={prevStep}
            isLastStep={isLastStep}
            waitingList={waitingList}
            handleAttendeeForm={handleAttendeeForm}
            filteredSteps={filteredSteps}
            currentStep={currentStep}
          />
        </div>
      </Spin>
    </Modal>
  );
}

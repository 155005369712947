import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Space, Typography } from 'antd';
import {
  Container,
  EmptyState,
  WishListSummarySkeleton,
} from '@seaters-app/ui';
import { useWishListsWithApprovals } from '../approvals/hooks/useWishListsWithApprovals';
import styles from './requests.module.css';

import { useTranslation } from 'react-i18next';
import { WishListSummary } from '@seaters-app/ui-shared';
import { useWishListSummaryData } from './hooks/useWishListSummaryData';
import { useIsCustomFlow } from '@seaters-app/data-access';
import { ExportReportButton } from './ExportReportButton';
const { Title } = Typography;

export function Requests() {
  const { t } = useTranslation();

  const { allItems, loadMoreData, hasNextPage, isFetching } =
    useWishListsWithApprovals(true);

  const { getData } = useWishListSummaryData();

  const isCustom = useIsCustomFlow();

  return (
    <div className={styles.requests}>
      {!isFetching || allItems.length ? (
        <Container>
          <Space style={{ width: '100%' }} direction="vertical">
            <div className={styles.title}>
              <Title level={2}>{t('menus_label_requests')}</Title>
              {isCustom && <ExportReportButton />}
            </div>
            {allItems.length ? (
              <InfiniteScroll
                style={{ overflow: 'visible' }}
                dataLength={allItems?.length ?? 0}
                next={() => loadMoreData()}
                hasMore={!!hasNextPage}
                loader={
                  <div>
                    {[...Array(2)].map(() => (
                      <WishListSummarySkeleton />
                    ))}
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <Space
                  className={styles.requestsContainer}
                  direction="vertical"
                >
                  {allItems?.map((item) => (
                    <Link
                      to={item.waitinglist.waitingListId}
                      style={{ textDecoration: 'none' }}
                    >
                      <WishListSummary data={getData(item)} />
                    </Link>
                  ))}
                </Space>
              </InfiniteScroll>
            ) : (
              <EmptyState
                description={
                  <p>{t('api_waiting_lists_with_approvals_missing')}</p>
                }
              />
            )}
          </Space>
        </Container>
      ) : (
        <Container>
          <div>
            {[...Array(9)].map(() => (
              <WishListSummarySkeleton />
            ))}
          </div>
        </Container>
      )}
    </div>
  );
}

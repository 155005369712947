import {
  FormattedValuesWithTranslations,
  BadgeEntity,
  BadgeForm,
  BadgeMoodEnum,
} from '@seaters-app/constants';
import { initialTranslations } from './../../../utils/helpers/getTranslations';
import { theme } from 'antd';

export const getInitialValues = (
  badge?: BadgeEntity | null,
  valuesWithTranslations?: FormattedValuesWithTranslations | null
): BadgeForm => {
  const { token } = theme.useToken();
  const values: BadgeForm =
    badge && valuesWithTranslations
      ? {
          ...badge,
          name: valuesWithTranslations.name,
          description: valuesWithTranslations.description,
          displayedText: valuesWithTranslations.displayedText,
          hidden: !badge.hidden,
        }
      : {
          name: initialTranslations,
          description: initialTranslations,
          displayedText: initialTranslations,
          displayedColor: token.colorPrimary,
          hidden: true,
          mood: BadgeMoodEnum.NEUTRAL,
        };

  return values;
};

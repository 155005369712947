import { errorColor } from '@seaters-app/ui';
import { Button, Modal } from 'antd';
import { useLogout } from 'apps/seaters/src/utils/helpers/logout';
import { useTranslation } from 'react-i18next';

export const SSOWarning = ({
  isSSOWarningModalShown,
}: {
  isSSOWarningModalShown: boolean;
}) => {
  const { t } = useTranslation();
  const { logout } = useLogout();

  return (
    <Modal
      open={isSSOWarningModalShown}
      title={t('access_denied_title')}
      centered
      footer={
        <Button type="primary" onClick={logout} color={errorColor}>
          {t('settings_logout')}
        </Button>
      }
    >
      <div>{t('sso_fan_group_login_warning_notification')}</div>
    </Modal>
  );
};

import {
  DateFilter,
  FilterName,
  HasAvailableTicketsOnlyFilter,
  SearchableTextFilter,
  SessionTypeFilter,
  TicketCategoryFilter,
} from '@seaters-app/constants';

export const multipleValuesFilters: FilterName[] = [
  'sessionType',
  'ticketCategory',
];

export const filterTypeToName = {
  date: 'date',
  hasAvailableTicketsOnly: 'toggle',
  searchableText: 'searchableText',
  sessionType: 'text',
  ticketCategory: 'text',
};

export const dateFilterDefaultValues: DateFilter = {
  label: {},
  name: 'date',
  type: 'date-line',
  values: [],
};

export const searchableTextFilterDefaultValues: SearchableTextFilter = {
  label: {},
  name: 'searchableText',
  type: 'searchableText',
};

export const sessionTypeFilterDefaultValues: SessionTypeFilter = {
  label: {},
  name: 'sessionType',
  type: 'text',
  values: [],
};

export const ticketCategoryFilterDefaultValues: TicketCategoryFilter = {
  label: {},
  name: 'ticketCategory',
  type: 'text',
  values: [],
};

export const hasAvailableTicketsOnlyFilterDefaultValues: HasAvailableTicketsOnlyFilter =
  {
    label: {},
    name: 'hasAvailableTicketsOnly',
    type: 'toggle',
  };

export const defaultOptions = {
  date: dateFilterDefaultValues,
  searchableText: searchableTextFilterDefaultValues,
  sessionType: sessionTypeFilterDefaultValues,
  ticketCategory: ticketCategoryFilterDefaultValues,
  hasAvailableTicketsOnly: hasAvailableTicketsOnlyFilterDefaultValues,
};

import {
  endpoints,
  seatersAdminPrefix,
  TranslationsQuery,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchTranslations = async (
  params: TranslationsQuery
): Promise<Record<string, string>> => {
  const result = await fetch(
    `/translations/${params.target.toLowerCase()}/${params.lang}.json`
  );
  return result.json();
};

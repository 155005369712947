import React from 'react';
import { PictureOutlined } from '@ant-design/icons';
import { Card, ConfigProvider, Typography, theme } from 'antd';
import { baseColor200, layoutDarkColor } from '../theme/colors';
import './styles.css';
import { useAppThemeStore } from '@seaters-app/data-access';
import { t } from 'i18next';
const { Text } = Typography;

interface UploadButtonProps {
  size: string;
  className: string;
  uploadText?: string | JSX.Element;
  style: Partial<React.CSSProperties>;
  isError?: boolean;
}

export const UploadButton = ({
  size,
  className,
  uploadText = t('drop_file_info_message') ??
    'Drop your file here or click to this zone',
  style,
  isError,
}: UploadButtonProps) => {
  const { token } = theme.useToken();
  const { mode } = useAppThemeStore();
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBgContainer: mode === 'light' ? baseColor200 : layoutDarkColor,
            colorBorderSecondary: isError
              ? token.colorError
              : token.colorBorder,
            lineType: 'dashed',
          },
        },
      }}
    >
      {className !== 'profileImage' ? (
        <Card bordered className={className} style={style}>
          <PictureOutlined
            style={{ fontSize: '28px', color: token.colorPrimary }}
            rev={undefined}
          />
          <Text style={{ display: 'block' }}>{size}</Text>
          <Text type="secondary">{uploadText}</Text>
        </Card>
      ) : (
        <div className={className} style={style}>
          <PictureOutlined
            style={{ fontSize: '28px', color: token.colorPrimary }}
            rev={undefined}
          />
          <Text style={{ fontSize: 10, display: 'block' }}>{size}</Text>
        </div>
      )}
    </ConfigProvider>
  );
};

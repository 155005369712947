import {
  getSlugFromUrl,
  setWaitingListsFilters,
  useFetchLook,
} from '@seaters-app/data-access';
import {
  ListEntity,
  WaitingListEntity,
  WaitingListFiltersQuery,
  waitingListsKeys,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

type ParamsType = {
  pageSize: number;
  current: number;
};

export const useFilteredWaitingLists = (
  defaultParams: WaitingListFiltersQuery
) => {
  const slug = getSlugFromUrl();

  const { data: lookData } = useFetchLook(slug ?? '');

  const fanGroupId = lookData?.fanGroupId ?? '';
  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<ListEntity<WaitingListEntity>>({
    queryKey: waitingListsKeys.filtered(fanGroupId, defaultParams),
    queryFn: ({ pageParam = defaultParams }) =>
      setWaitingListsFilters(fanGroupId ?? '', pageParam),
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.reduce(
        (prev, next) => prev + next.items.length,
        0
      );
      const filters = { ...defaultParams };
      delete filters.itemOffset;
      delete filters.maxPageSize;

      return lastPage.totalSize - lastPage.itemOffset >= lastPage.maxPageSize
        ? {
            itemOffset: nextOffset,
            maxPageSize: lastPage.maxPageSize,
            ...filters,
          }
        : undefined;
    },
    enabled: !!fanGroupId && !!lookData?.properties?.filters,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const allItems = data?.pages.flatMap((page) => page.items) || [];

  const handleFetchNextPage = (
    filters?: WaitingListFiltersQuery,
    newFilter?: boolean
  ) => {
    if (newFilter) {
      setWaitingListsFilters(fanGroupId ?? '', {
        maxPageSize: 20,
        itemOffset: 0,
        ...filters,
      });
    } else if (fanGroupId) {
      fetchNextPage();
    }
  };

  return {
    allFilteredItems: allItems,
    loadMoreFilteredData: handleFetchNextPage,
    hasNextFilteredPage: hasNextPage,
    isFetchingNextFilteredPage: isFetchingNextPage,
    isFetchingFiltered: isFetching,
    isLoadingFiltered: isLoading,
    refetchFiltered: refetch,
  };
};

import { count } from 'console';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { AttendeeRequiredFieldsType } from 'libs/constants/types';
import { AnyZodObject, z } from 'zod';

const stringValidation = z.string().min(1);

export const AttendeesInfoFormValidatorSchema = z.object({
  language: stringValidation,
  email: stringValidation,
  state: stringValidation,
  country: stringValidation,
  countryCode: stringValidation,

  address: z.object({
    state: stringValidation,
    zipCode: stringValidation,
    city: stringValidation,
    line1: stringValidation,
    line2: z.string().optional().nullable(),
    line3: z.string().optional().nullable(),
    countryCode: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
  }),
  company: stringValidation,
  title: stringValidation,
  lastName: stringValidation,
  firstName: stringValidation,
  birthDate: z.instanceof(dayjs as unknown as typeof Dayjs, {
    message: 'Birth date was not configured',
  }),
  idNumber: stringValidation,
  citizenshipCountryCode: stringValidation,
  phoneNumber: z.object({
    localNumber: stringValidation,
    countryCallingCode: stringValidation,
  }),
});

export const attendeeFields: {
  name: AttendeeRequiredFieldsType;
  required: boolean;
}[] = [
  { name: 'language', required: true },
  { name: 'email', required: true },
  // { name: 'city', required: true },
  // { name: 'state', required: true },
  { name: 'address', required: true },
  { name: 'company', required: true },
  { name: 'country', required: true },
  { name: 'title', required: true },
  { name: 'lastName', required: true },
  { name: 'firstName', required: true },
  { name: 'birthDate', required: true },
  { name: 'idNumber', required: true },
  { name: 'citizenshipCountryCode', required: true },
  { name: 'phoneNumber', required: true },
];

export function generateConditionalAttendeesInfoFormValidatorSchema(
  fields?: AttendeeRequiredFieldsType[]
): AnyZodObject {
  let schema = z.object({});
  if (!fields) {
    return schema;
  }
  // *** Configuring address schema depends on fields
  let addressSchema: {
    [key: string]: z.ZodString | z.ZodNullable<z.ZodOptional<z.ZodString>>;
  } = {
    city: AttendeesInfoFormValidatorSchema.shape['address'].shape.city,
    zipCode: AttendeesInfoFormValidatorSchema.shape['address'].shape.zipCode,
    state: AttendeesInfoFormValidatorSchema.shape['address'].shape.state,
  };

  if (fields.includes('address')) {
    addressSchema = {
      ...addressSchema,
      line1: AttendeesInfoFormValidatorSchema.shape['address'].shape.line1,
      line2: AttendeesInfoFormValidatorSchema.shape['address'].shape.line2,
      line3: AttendeesInfoFormValidatorSchema.shape['address'].shape.line3,
    };
  }

  const addressFields: Array<Partial<AttendeeRequiredFieldsType>> = [
    'city',
    'state',
    'zipCode',
  ];

  addressFields.forEach((field) => {
    if (!fields.includes(field)) {
      delete addressSchema[field as keyof typeof addressSchema];
    }
  });

  // ***

  const filtered = fields.filter((field) => !addressFields.includes(field));
  if (Object.keys(addressSchema).length > 0) {
    filtered.push('address');
  }

  filtered.forEach((field) => {
    if (
      attendeeFields.find((attendeeField) => attendeeField.name === field)
        ?.required
    ) {
      if (field === 'address') {
        schema = z.object({
          ...schema.shape,
          [field]: z.object({
            ...addressSchema,
          }),
        });
      } else {
        schema = z.object({
          ...schema.shape,
          [field]:
            field === 'phoneNumber.localNumber'
              ? AttendeesInfoFormValidatorSchema.shape['phoneNumber'].shape
                  .localNumber
              : AttendeesInfoFormValidatorSchema.shape[field],
        });
      }
    } else {
      if (field === 'address') {
        schema = z.object({
          ...schema.shape,
          [field]: z
            .object({
              ...addressSchema,
            })
            .optional(),
        });
      } else {
        schema = z.object({
          ...schema.shape,
          [field]:
            field === 'phoneNumber.localNumber'
              ? AttendeesInfoFormValidatorSchema.shape[
                  'phoneNumber'
                ].shape.localNumber.optional()
              : AttendeesInfoFormValidatorSchema.shape[field].optional(),
        });
      }
    }
  });

  return schema;
}

export const AttendeesInfoValidatorSchema = z.object({
  attendees: z.array(AttendeesInfoFormValidatorSchema),
});

export type AttendeesInfoValidator = z.infer<
  typeof AttendeesInfoValidatorSchema
>;

export type AttendeesInfoFormValidator = z.infer<
  typeof AttendeesInfoFormValidatorSchema
>;

import {
  ListEntity,
  CountryEntity,
  countriesKeys,
  PaginationQuery,
} from '@seaters-app/constants';
import { useQuery } from '@tanstack/react-query';
import { fetchCountries } from '../api';

export const useFetchCountries = (params: PaginationQuery) =>
  useQuery<ListEntity<CountryEntity>, Error>(
    countriesKeys.list(params),
    () => fetchCountries(params),
    {
      keepPreviousData: true,
    }
  );

// DO NOT USE import.meta.env.PROD as it doesn't rely on mode that is passed
export const PROD = import.meta.env.MODE === 'production';

// DO NOT USE import.meta.env.DEV as it doesn't rely on mode that is passed
export const DEV = import.meta.env.MODE === 'development';

export const QA = import.meta.env.MODE === 'qa';

export const MODE = import.meta.env.MODE;

export const SENTRY_DSN = import.meta.env.SEATERS_APP_PUBLIC_SENTRY_DSN;

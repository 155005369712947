export const routes = {
  access: 'access',
  accessRequests: 'access-requests',
  activity: 'activity',
  add: 'add',
  admin: 'admin',
  all: 'all',
  allocate: 'allocate',
  detail: 'detail',
  answers: 'answers',
  approvals: 'approvals',
  authenticate: 'authenticate',
  badges: 'badges',
  mailcampaigns: 'mailcampaigns',
  companies: 'companies',
  create: 'create',
  edit: 'edit',
  customInfo: 'custom-info',
  emailConfirmation: 'email-confirmation',
  events: 'events',
  error: 'error',
  fanGroupConfiguration: 'fan-group-configuration',
  fanGroups: 'fan-groups',
  fans: 'fans',
  fanWebApp: 'fan-web-app',
  filterConfig: 'filter-config',
  general: 'general',
  guestlist: 'guestlist',
  interests: 'interests',
  invites: 'invites',
  landing: 'landing',
  login: 'sign-in',
  myWaitingLists: 'my-waiting-lists',
  notifications: 'notifications',
  paymentSystems: 'payment-systems',
  personalInfo: 'personal-info',
  personalization: 'personalization',
  profile: 'settings',
  profiling: 'profiling',
  protectionCodes: 'protection-codes',
  publish: 'publish',
  questionnaires: 'questionnaires',
  reporting: 'reporting',
  requests: 'requests',
  resetPassword: 'reset-password',
  root: '/',
  security: 'security',
  settings: 'settings',
  signup: 'sign-up',
  surveys: 'surveys',
  surveyAfterEvent: 'survey-after-event',
  supportActions: 'support-actions',
  ticketingSystems: 'ticketing-systems',
  tickets: 'tickets',
  updatePassword: 'forgot-password',
  usage: 'usage',
  users: 'users',
  venues: 'venues',
  verify: 'verify',
  waitingList: 'waitinglist',
  waitingLists: 'waiting-lists',
  wishLists: 'wish-lists',
  wls: 'wls',
  draft: 'draft',
  sent: 'sent',
  scheduled: 'scheduled',

  // custom slug
  portofantwerpemployees: 'portofantwerpemployees',
};

import {
  ApproverRequest,
  ApproverRequestBody,
  endpoints,
  FanGroupEntity,
  InvitationForApproverEntity,
  ListEntityV2,
  ReportEntity,
  ReportType,
  V2Prefix,
  WaitingListWithApproval,
  WaitingListWithApprovalQuery,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

// https://api.qa-seaters.com/api/v2/approver/groups/

export const fetchApproverFanGroups = (): Promise<
  ListEntityV2<FanGroupEntity>
> => api.get(`v2/${endpoints.approver}/${endpoints.groups}/`);

export const fetchWaitingListsWithApproval = (
  groupId: string,
  params: WaitingListWithApprovalQuery
): Promise<ListEntityV2<WaitingListWithApproval>> =>
  api.get(
    `v2/${endpoints.approver}/${endpoints.groups}/${groupId}/${endpoints.waitingListsWithApprovals}`,
    { params }
  );

export const fetchWaitingListWithApproval = (
  waitingListId: string,
  params: WaitingListWithApprovalQuery
): Promise<WaitingListWithApproval> =>
  api.get(
    `v2/${endpoints.approver}/${endpoints.waitingLists}/${waitingListId}`,
    { params }
  );

export const fetchWaitingListsInvitationsToApprove = (
  waitingListId: string,
  params: WaitingListWithApprovalQuery
): Promise<ListEntityV2<InvitationForApproverEntity>> =>
  api.get(
    `v2/${endpoints.approver}/${endpoints.waitingLists}/${waitingListId}/${endpoints.invitations}/filtered?toallocate=${params.toallocate}`,
    { params }
  );

export const updateWaitingListsInvitationsToApprove = (
  invitationId: string,
  body: { numberOfToAllocateSeats: number }
): Promise<InvitationForApproverEntity> =>
  api.put(
    `v2/${endpoints.approver}/${endpoints.invitations}/${invitationId}`,
    body
  );

//Currently not used, but let it be here

// https://api.qa-seaters.com/api/v2/approver/invitations/id/approve-non-allocated/
// export const approveNonAllocatedHostSeats = (
//   id: string
//   // body: { nbrOfRequestedSeats: number }
// ): Promise<any> => {
//   return api.post(
//     `v2/${endpoints.approver}/${endpoints.invitations}/${id}/approve-non-allocated`
//   );
// };

export const createWaitingListsApproverRequest = (
  waitingListId: string,
  body: ApproverRequestBody
): Promise<ApproverRequest> =>
  api.post(
    `v2/${endpoints.approver}/${endpoints.waitingLists}/${waitingListId}/${endpoints.request}`,
    body
  );

export const updateWaitingListsApproverRequest = (
  waitingListId: string,
  requestId: string,
  body: ApproverRequestBody
): Promise<ApproverRequest> =>
  api.put(
    `v2/${endpoints.approver}/${endpoints.waitingLists}/${waitingListId}/${endpoints.request}/${requestId}`,
    body
  );

export const fetchWaitingListsApproverRequest = (
  waitingListId: string
): Promise<ApproverRequest> =>
  api.get(
    `v2/${endpoints.approver}/${endpoints.waitingLists}/${waitingListId}/${endpoints.request}`
  );

export const approveInvitation = (
  invitationId: string
): Promise<InvitationForApproverEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.approver}/${endpoints.invitations}/${invitationId}/${endpoints.approve}`
  );

export const rejectInvitation = (
  invitationId: string,
  body?: { rejectionReason: string }
): Promise<InvitationForApproverEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.approver}/${endpoints.invitations}/${invitationId}/${endpoints.reject}`,
    body
  );

export const exportApproverReport = (
  fgId: string,
  reportName: ReportType
): Promise<ReportEntity> =>
  api.put(
    `${V2Prefix}/${endpoints.approver}/${endpoints.groups}/${fgId}/${endpoints.exportFGReport}/${reportName}/xlsx`
  );

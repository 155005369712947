import { SEATERS_COOKIES_ACCEPTED } from '@seaters-app/constants';
import { create } from 'zustand';

interface CookiesState {
  cookies: boolean;
  setCookies: () => void;
}

export const useCookiesStore = create<CookiesState>((set) => ({
  cookies: !!document.cookie.includes(`${SEATERS_COOKIES_ACCEPTED}=true;`),
  setCookies: () =>
    set(() => ({
      cookies: true,
    })),
}));

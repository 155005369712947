import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

export const TicketPoolValidatorSchema = z.object({
  name: z.string(),
  expirationDate: z.string(),
  ticketPdf: z.string().optional(),
});

export type TicketPoolValidator = z.infer<typeof TicketPoolValidatorSchema>;

export const TicketPoolFormValidatorSchema = z.object({
  name: z.string(),
  expirationDate: z.instanceof(dayjs as unknown as typeof Dayjs, {
    message: 'Expiration date was not configured',
  }),
  ticketPdf: z
    .any()
    .refine((value) => value?.fileList.length, 'File is required'),
});

export type TicketPoolFormValidator = z.infer<
  typeof TicketPoolFormValidatorSchema
>;

import {
  fetchWaitingListsWithInvitations,
  getSlugFromUrl,
  useFetchLook,
} from '@seaters-app/data-access';
import {
  ListEntityV2,
  WishListWithInvitations,
  waitingListsWithInvitationsKeys,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

type ParamsType = {
  pageSize: number;
  current: number;
};

const defaultParams: ParamsType = {
  pageSize: 10,
  current: 1,
};
export const useWishListsWithInvitations = () => {
  const slug = getSlugFromUrl();

  const { data: lookData } = useFetchLook(slug ?? '');

  const fanGroupId = lookData?.fanGroupId ?? '';

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery<ListEntityV2<WishListWithInvitations>, Error>({
      queryKey: waitingListsWithInvitationsKeys.all(),
      queryFn: ({
        pageParam = {
          page: 0,
          size: defaultParams.pageSize,
        },
      }) => fetchWaitingListsWithInvitations(fanGroupId, pageParam),
      getNextPageParam: (lastPage, allPages) => {
        return !lastPage.last
          ? {
              page: lastPage.number + 1,
              size: defaultParams.pageSize,
            }
          : undefined;
      },
      enabled: !!fanGroupId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

  const allItems = data?.pages.flatMap((page) => page.content) || [];

  const handleFetchNextPage = () => {
    if (fanGroupId) {
      fetchNextPage();
    }
  };

  return {
    allItems,
    loadMoreData: handleFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  };
};

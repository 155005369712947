import {
  AcceptWaitingListValidator,
  AttendeesInfoValidator,
  ChoiceEntity,
  ChoicesQuery,
  endpoints,
  GuestWaitingList,
  ListEntityV2,
  LookEntity,
  SurveyAnswersResponse,
  SurveyAnswersValidator,
  SurveyInstanceEntity,
  SurveyQuery,
  V2Prefix,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchGuestFanGroup = (
  slug: string,
  params: { code: string }
): Promise<LookEntity> =>
  api.get(
    `${V2Prefix}/${endpoints.guest}/${endpoints.fanGroupsBySlug}/${slug}/${endpoints.look}`,
    { params }
  );

export const fetchGuestWaitingList = (
  waitingListId: string,
  params: { code: string }
): Promise<GuestWaitingList> =>
  api.get(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}`,
    { params }
  );

export const rejectGuestWaitingList = (
  waitingListId: string,
  params: { code: string }
) =>
  api.post(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}/${endpoints.reject}?code=${params.code}`,
    { params }
  );

export const acceptGuestWaitingList = (
  waitingListId: string,
  params: { code: string }
) =>
  api.post(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}/${endpoints.accept}?code=${params.code}`,
    { params }
  );

export const updateGuestWaitingListSeats = (
  waitingListId: string,
  nbrOfSeats: number,
  params: { code: string }
) =>
  api.put(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}/${endpoints.seats}/${nbrOfSeats}?code=${params.code}`,
    { params }
  );

export const exportGuestWaitingListSeats = (
  waitingListId: string,
  params: { code: string }
) =>
  api.put(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}/${endpoints.exportSeat}?code=${params.code}`,
    { params }
  );

export const exportGuestWaitingListParkingSeats = (
  waitingListId: string,
  params: { code: string }
) =>
  api.put(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}/${endpoints.exportParkingTicket}?code=${params.code}`,
    { params }
  );

export const fetchGuestSurvey = (
  params: SurveyQuery & { code: string }
): Promise<ListEntityV2<SurveyInstanceEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.guest}/${endpoints.survey}/${endpoints.instances}`,
    { params }
  );

export const sendGuestAnswers = (
  instanceId: string,
  body: SurveyAnswersValidator,
  params: { code: string }
): Promise<SurveyAnswersResponse> =>
  api.post(
    `${V2Prefix}/${endpoints.guest}/${endpoints.surveys}/${endpoints.instances}/${instanceId}/${endpoints.answers}?code=${params.code}`,
    body
  );

// updateGuestAttendeeInfo
export const updateGuestAttendeeInfo = (
  waitingListId: string,
  body: AttendeesInfoValidator,
  params: { code: string }
) =>
  api.put(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}/${endpoints.position}/${endpoints.attendeesInfo}?code=${params.code}`,
    body
  );

export const fetchGuestQuestionChoices = (
  questionId: string,
  params: ChoicesQuery & { code: string }
): Promise<ListEntityV2<ChoiceEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.guest}/${endpoints.surveys}/${endpoints.question}/${questionId}/${endpoints.choices}`,
    { params }
  );

export const reExportGuestVoucher = (
  waitingListId: string,
  body: AcceptWaitingListValidator,
  params: { code: string }
): Promise<unknown> =>
  api.put(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}/${endpoints.reExportVoucher}?code=${params.code}`,
    body
  );

// POST api/v2/guest/waiting-lists/{listId}/cancel

export const guestCancelWaitingList = (
  waitingListId: string,
  params: { code: string }
): Promise<null> =>
  api.post(
    `${V2Prefix}/${endpoints.guest}/${endpoints.waitingLists}/${waitingListId}/${endpoints.cancel}?code=${params.code}`
  );

import { FanActivityStatus } from '@seaters-app/constants';

import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

export interface ActivityStatusTagProps {
  status: FanActivityStatus;
}

const activityStatusToColor = {
  [FanActivityStatus.FAN_CANCEL_SEATS]: {
    color: 'error',
    label: 'mwl_seats_table-status-fan-cancel-seats', // Ticket(s) Cancelled
  },
  [FanActivityStatus.ACCEPT_RSVP]: {
    color: 'success',
    label: 'confirmed_wo_seats_wl_status_text', // Confirmed w/o seats
  },
  [FanActivityStatus.JOIN_WL]: {
    // Fan joined the waiting list
    color: 'warning',
    label: 'joined_wl_status_text', // Joined
  },
  [FanActivityStatus.ACCEPT_SEATS]: {
    color: 'success',
    label: 'mwl_seats_table-status-confirmed', // Confirmed
  },
  [FanActivityStatus.CANCEL_POSITION]: {
    // FGO removed Fan from  WL tab
    color: 'error',
    label: 'mwl_seats_table-status-positions-removed', // Positions removed
  },
  [FanActivityStatus.CANCEL_SEATS]: {
    // FGO removed Fan from RSVP tab
    color: 'error',
    label: 'mwl_seats_table-status-seats-removed', // Seats removed
  },
  [FanActivityStatus.LEAVE_WL]: {
    // Fan left the waiting list (cancelled the request of tickets)
    color: 'default',
    label: 'left_wl_status_text', // Left
  },
  [FanActivityStatus.POSITION_EXPIRES]: {
    color: 'warning',
    label: 'expired_wl_status_text', // Expired
  },
  [FanActivityStatus.RECEIVE_RSVP_WITHOUT_SEATS]: {
    color: 'warning',
    label: 'assigned_without_seats_wl_status', // Assigned w/o seats
  },
  [FanActivityStatus.RECEIVE_SEATS]: {
    // FGO distributed tickets to Fan in WL tab
    color: 'warning',
    label: 'mwl_seats_table-status-pending', // Pending
  },
  [FanActivityStatus.REJECT_SEATS]: {
    // Fan cancelled tickets (when had timer in FWA)
    color: 'error',
    label: 'rejected_status_text', // Rejected
  },
  [FanActivityStatus.SEATS_EXPIRE]: {
    // Fan did not accept tickets in time
    color: 'warning',
    label: 'expired_wl_status_text', //Expired
  },
};
export function ActivityStatusTag({ status }: ActivityStatusTagProps) {
  const { t } = useTranslation();

  if (!activityStatusToColor[status]) return null;

  return (
    <Tag color={activityStatusToColor[status].color}>
      {t(activityStatusToColor[status]?.label)}
    </Tag>
  );
}

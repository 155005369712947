import {
  AxiosAPIError,
  BadgeEntity,
  BadgeEventEntity,
  BadgeFGOListEntity,
  BadgeValidator,
  BadgesQuery,
  CategoryEntity,
  CategoryQuery,
  FanGroupsBadgesListEntity,
  ListEntityV2,
  PaginationQueryV2,
  badgesEventsKeys,
  badgesKeys,
  BadgeInstance,
  badgesCategoryKeys,
  WishListBadgeInstanceValidator,
  fanGroupsKeys,
  waitingListsKeys,
  WLBadgesState,
  BadgesSearchedQuery,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  addBadgeToWishList,
  archiveBadge,
  createBadge,
  createFanGroupBadgeLink,
  deleteBadgeFromWishList,
  fetchBadgeData,
  fetchBadgesCategories,
  fetchFGOBadges,
  fetchUserBadgesEvents,
  fetchAdminBadges,
  fetchWishListBadges,
  removeFanGroupBadgeLink,
  unarchiveBadgeForWishList,
  updateBadge,
  fetchFanGroupBadgeLinks,
  fetchWishListBadgesState,
  scheduleBadgeActivation,
  scheduleBadgeArchiving,
  fetchAdminSearchedBadges,
} from '../api';
import { notification } from 'antd';
import { t } from 'i18next';

export const useFetchAdminBadges = (params: BadgesQuery) =>
  useQuery<ListEntityV2<BadgeEntity>, Error>(badgesKeys.list(params), () =>
    fetchAdminBadges(params)
  );

export const useFetchAdminSearchedBadges = (params: BadgesSearchedQuery) =>
  useQuery<ListEntityV2<BadgeEntity>, Error>(
    badgesKeys.list(params),
    () => fetchAdminSearchedBadges(params),
    {
      keepPreviousData: true,
    }
  );

export const useFetchBadgesCategories = (params: CategoryQuery) =>
  useQuery<ListEntityV2<CategoryEntity>, Error>(badgesCategoryKeys.all(), () =>
    fetchBadgesCategories(params)
  );

export const useFetchFanGroupBadgeLinks = (
  fanGroupId: string,
  params: PaginationQueryV2
) =>
  useQuery<ListEntityV2<FanGroupsBadgesListEntity>, Error>(
    fanGroupsKeys.badges(fanGroupId),
    () => fetchFanGroupBadgeLinks(params)
  );

export const useFetchFGOBadges = (
  fanGroupId: string,
  params: PaginationQueryV2
) =>
  useQuery<ListEntityV2<BadgeFGOListEntity>, Error>(
    fanGroupsKeys.badges(fanGroupId),
    () => fetchFGOBadges(fanGroupId, params),
    {
      enabled: !!fanGroupId,
      // keepPreviousData: true,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // refetchOnWindowFocus: false,
    }
  );

export const useFetchWishListBadges = (
  wishListId: string,
  params: PaginationQueryV2
) =>
  useQuery<ListEntityV2<BadgeInstance>, Error>(
    waitingListsKeys.badges(wishListId),
    () => fetchWishListBadges(wishListId, params),
    {
      refetchOnWindowFocus: false,
      enabled: !!wishListId,
    }
  );

export const useFetchWishListBadgesState = (wishListId: string) =>
  useQuery<WLBadgesState, Error>(
    waitingListsKeys.badgesPerWL(wishListId),
    () => fetchWishListBadgesState(wishListId),
    {
      enabled: !!wishListId,
    }
  );

export const useFetchBadgeData = (badgeId: string) =>
  useQuery<BadgeEntity, Error>(
    badgesKeys.detail(badgeId),
    () => fetchBadgeData(badgeId),
    {
      enabled: !!badgeId,
    }
  );

export const useUpdateBadge = (
  id: string
): UseMutationResult<
  BadgeEntity,
  AxiosAPIError<BadgeValidator>,
  BadgeValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation((body: BadgeValidator) => updateBadge(id, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(badgesKeys.detail(id));
    },
  });
};

export const useCreateBadge = (): UseMutationResult<
  BadgeEntity,
  AxiosAPIError<BadgeValidator>,
  BadgeValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation((body: BadgeValidator) => createBadge(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(badgesKeys.all());
    },
  });
};

export const useCreateFanGroupBadgeLink = (
  fanGroupId: string
): UseMutationResult<
  FanGroupsBadgesListEntity,
  AxiosAPIError<{ badgeId: string }>,
  string,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (badgeId: string) => createFanGroupBadgeLink(badgeId, fanGroupId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.badges(fanGroupId));
      },
    }
  );
};

export const useAddBadgeToWishList = (): UseMutationResult<
  BadgeInstance,
  AxiosAPIError<{ wishListId: string; body: WishListBadgeInstanceValidator }>,
  { wishListId: string; body: WishListBadgeInstanceValidator },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      wishListId,
      body,
    }: {
      wishListId: string;
      body: WishListBadgeInstanceValidator;
    }) => addBadgeToWishList(wishListId, body),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          waitingListsKeys.badges(res.waitingListId)
        );
      },
      onError: (error) => {
        notification.error({
          message:
            t(
              `${error?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
            ) ?? error.message,
        });
      },
    }
  );
};

export const useUnarchiveBadgeForWishList = (
  wishListId: string
): UseMutationResult<
  BadgeInstance,
  AxiosAPIError<{ badgeId: string }>,
  string,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (badgeId: string) => unarchiveBadgeForWishList(wishListId, badgeId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.badges(wishListId));
      },
    }
  );
};

export const useScheduleBadgeActivation = (): UseMutationResult<
  any,
  AxiosAPIError<{
    wishListId: string;
    badgeId: string;
    body: { date: string };
  }>,
  { wishListId: string; badgeId: string; body: { date: string } },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      wishListId,
      badgeId,
      body,
    }: {
      wishListId: string;
      badgeId: string;
      body: { date: string };
    }) => {
      return scheduleBadgeActivation(wishListId, badgeId, body);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          waitingListsKeys.scheduledActivationBadges(res.waitingListId)
        );
      },
    }
  );
};

export const useScheduleBadgeArchiving = (): UseMutationResult<
  any,
  AxiosAPIError<{
    wishListId: string;
    badgeId: string;
    body: { date: string };
  }>,
  { wishListId: string; badgeId: string; body: { date: string } },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      wishListId,
      badgeId,
      body,
    }: {
      wishListId: string;
      badgeId: string;
      body: { date: string };
    }) => scheduleBadgeArchiving(wishListId, badgeId, body),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          waitingListsKeys.scheduledArchivingBadges(res.waitingListId)
        );
      },
    }
  );
};

export const useDeleteBadgeFromWishList = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ wishListId, badgeId }: { wishListId: string; badgeId: string }) =>
      deleteBadgeFromWishList(wishListId, badgeId),
    {
      onSuccess: (res, req) => {
        queryClient.invalidateQueries(waitingListsKeys.badges(req.wishListId));
      },
    }
  );
};

export const useRemoveFanGroupBadgeLink = (fanGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (badgeId: string) => removeFanGroupBadgeLink(fanGroupId, badgeId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.badges(fanGroupId));
      },
    }
  );
};

export const useArchiveBadge = () => {
  const queryClient = useQueryClient();
  return useMutation((badgeId: string) => archiveBadge(badgeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(badgesKeys.all());
    },
  });
};

export const useFetchUserBadgesEvents = (
  fanGroupId: string,
  userId: string,
  params: PaginationQueryV2
) =>
  useQuery<ListEntityV2<BadgeEventEntity>, Error>(
    badgesEventsKeys.listPerUser(fanGroupId, userId),
    () => fetchUserBadgesEvents(fanGroupId, userId, params),
    {
      enabled: !!fanGroupId,
    }
  );

import {
  AxiosAPIError,
  FGOVenueFormValidator,
  ListEntity,
  PaginationQuery,
  VenueCategoryEntity,
  VenueConfigBody,
  VenueConfigEntity,
  VenueEntity,
  venuesKeys,
  VenuesQuery,
  VenueValidator,
} from '@seaters-app/constants';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createAdminVenue,
  createAdminVenueConfig,
  createFGOVenue,
  deleteVenue,
  deleteVenueConfig,
  fetchFGOVenues,
  fetchVenue,
  fetchVenueCategories,
  fetchVenueConfig,
  fetchVenueConfigs,
  fetchVenues,
  updateVenue,
  updateVenueImage,
} from '../api/venues';

// Venues
export const useFetchVenues = (params: VenuesQuery) =>
  useQuery<ListEntity<VenueEntity>, Error>(
    venuesKeys.list(params),
    () => fetchVenues(params),
    {
      keepPreviousData: true,
    }
  );

// Venues
export const useFetchFGOVenues = (queryParams: VenuesQuery, bodyParams) =>
  useQuery<ListEntity<VenueEntity>, Error>(
    venuesKeys.list(queryParams, bodyParams),
    () => fetchFGOVenues(queryParams, bodyParams),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

export const useFetchVenue = (venueId: string) =>
  useQuery<VenueEntity, Error>(
    venuesKeys.detail(venueId),
    () => fetchVenue(venueId),
    {
      enabled: !!venueId,
    }
  );

export const useCreateAdminVenue = (): UseMutationResult<
  VenueEntity,
  AxiosAPIError<VenueValidator>,
  VenueValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation((body: VenueValidator) => createAdminVenue(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(venuesKeys.all());
    },
  });
};

export const useCreateFGOVenue = (): UseMutationResult<
  VenueEntity,
  AxiosAPIError<FGOVenueFormValidator>,
  VenueValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation((body: VenueValidator) => createFGOVenue(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(venuesKeys.all());
    },
  });
};

export const useUpdateVenue = (
  id: string
): UseMutationResult<
  VenueEntity,
  AxiosAPIError<VenueValidator>,
  VenueValidator,
  unknown
> => {
  return useMutation(venuesKeys.mutation(), (body: VenueValidator) =>
    updateVenue(id, body)
  );
};

export const useUpdateVenueImage = (
  id: string
): UseMutationResult<
  VenueEntity,
  AxiosAPIError<File>,
  { fileId: string },
  unknown
> => {
  return useMutation(venuesKeys.mutation(), (body: { fileId: string }) =>
    updateVenueImage(id, { fileId: body.fileId })
  );
};

export const useDeleteVenue = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ venueId }: { venueId: string }) => deleteVenue(venueId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(venuesKeys.all());
      },
    }
  );
};

export const useDeleteVenueConfig = (venueId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ venueConfigId }: { venueConfigId: string }) =>
      deleteVenueConfig(venueConfigId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(venuesKeys.configs(venueId));
      },
    }
  );
};

// Venue configs

export const useFetchVenueConfigs = (
  id: string,
  enabled: boolean,
  params: PaginationQuery
) =>
  useQuery<ListEntity<VenueConfigEntity>, Error>(
    venuesKeys.configs(id),
    () => fetchVenueConfigs(id, params),
    {
      keepPreviousData: true,
      enabled,
    }
  );

export const useFetchVenueCategories = (id: string, params: PaginationQuery) =>
  useQuery<ListEntity<VenueCategoryEntity>, Error>(
    venuesKeys.categories(id),
    () => fetchVenueCategories(id, params),
    {
      keepPreviousData: true,
    }
  );

export const useFetchVenueConfig = (venueConfigId: string) =>
  useQuery<VenueConfigEntity, Error>(
    venuesKeys.configDetail(venueConfigId),
    () => fetchVenueConfig(venueConfigId),
    {
      enabled: !!venueConfigId,
    }
  );

export const useAdminCreateVenueConfig = (): UseMutationResult<
  VenueConfigEntity,
  AxiosAPIError<{ venueId: string; body: VenueConfigBody }>,
  { venueId: string; body: VenueConfigBody },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ venueId, body }) => createAdminVenueConfig(venueId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(venuesKeys.all());
      },
    }
  );
};

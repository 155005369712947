export async function downloadFileByURL(url: string, fileName?: string) {
  const link = document.createElement('a');

  link.href = url;
  link.rel = 'noopener noreferrer';

  if (fileName) {
    link.download = fileName;
  }

  link.target = '_blank';

  document.body.appendChild(link);

  link.click();

  link?.parentNode?.removeChild(link);
}

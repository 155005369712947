import React from 'react';
import { Typography, Tabs, TabsProps, Flex } from 'antd';
import styles from './styles.module.css';
import { UserRole, routes } from '@seaters-app/constants';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIsFetching } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  getSingleTranslation,
  useAppUserStore,
  useFanGroupStore,
  useFetchFanGroupOwnerGroups,
} from '@seaters-app/data-access';

const { Title } = Typography;

export function BadgesLayout() {
  const { t } = useTranslation();
  const { fanGroup } = useFanGroupStore();
  const { pathname } = useLocation();
  const { user } = useAppUserStore();

  const isAdmin = user?.roles.includes(UserRole.ADMIN);
  const isFGO = user?.roles.includes(UserRole.FAN_GROUP_OWNER);

  const { data: fanGroupOwnerGroups, isLoading: isFGOFanGroupsLoading } =
    useFetchFanGroupOwnerGroups({
      itemOffset: 0,
      maxPageSize: 100,
    });

  const navigate = useNavigate();

  const onChangeTab = (key: string) => {
    navigate(key);
  };

  const FGOitems: TabsProps['items'] = [
    {
      key: routes.wishLists,
      label: `${fanGroup?.name ? getSingleTranslation(fanGroup?.name) : ''} ${t(
        'wl_title'
      )}`,
    },
  ];

  const adminItems: TabsProps['items'] = [
    {
      key: routes.all,
      label: t('admin_badges'),
    },
    {
      key: routes.fanGroups,
      label: t('admin_user_tab-groups'),
    },
  ];

  const items =
    isAdmin &&
    isFGO &&
    fanGroupOwnerGroups?.items.find((group) => group.id === fanGroup?.id)
      ? [...adminItems, ...FGOitems]
      : isAdmin
      ? [...adminItems]
      : fanGroupOwnerGroups?.items.find((group) => group.id === fanGroup?.id)
      ? [...FGOitems]
      : null;

  const activeTab = items?.find((item) => pathname.includes(item.key))?.key;

  return (
    items && (
      <Flex
        vertical
        style={{
          padding: 24,
        }}
      >
        <div className={styles.subheader}>
          <Title level={2}>{t('admin_badges')}</Title>
        </div>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={items[0].key}
          items={items}
          onChange={onChangeTab}
        />
        <Outlet />
      </Flex>
    )
  );
}

import { Button, Container } from '@seaters-app/ui';
import { LeftOutlined } from '@ant-design/icons';

import {
  getSingleTranslation,
  useFetchFGOWaitingList,
  useUpdateWLEstimatedSeats,
} from '@seaters-app/data-access';
import { Link, useParams } from 'react-router-dom';
import {
  ConfigProvider,
  Grid,
  InputNumber,
  Space,
  Typography,
  theme,
} from 'antd';
import { ApproversTable } from './ApproversTable';
import { useTranslation } from 'react-i18next';
import { WishListSummary } from '@seaters-app/ui-shared';
import { useWLDistributionData } from './hooks/useWLDistributeSummaryData';
import { useEffect, useState } from 'react';
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export function DistributeEdit() {
  const { waitingListId = '' } = useParams();
  const { token } = theme.useToken();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const { xs } = useBreakpoint();
  const { data: waitingListData } = useFetchFGOWaitingList(waitingListId);

  const { getData } = useWLDistributionData();
  const { mutate: updateWLEstimatedSeats } = useUpdateWLEstimatedSeats();

  const [estimatedSeats, setEstimatedSeats] = useState(
    waitingListData?.waitinglist?.estimatedTickets ?? 0
  );

  const updateEstimatedSeats = () => {
    updateWLEstimatedSeats({
      waitingListId: waitingListData?.waitinglist.waitingListId ?? '',
      estimatedTickets: estimatedSeats,
    });
  };

  useEffect(() => {
    if (waitingListData) {
      setEstimatedSeats(waitingListData.waitinglist.estimatedTickets);
    }
  }, [waitingListData]);

  return waitingListData ? (
    <div
      style={{
        backgroundColor: '#FFFFFF',
        paddingBottom: 74,
        height: '100%',
        paddingTop: 12,
      }}
    >
      <Container>
        <Space style={{ width: '100%' }} direction="vertical" size={12}>
          <ConfigProvider
            theme={{
              token: {
                colorLink: token.colorPrimary,
                colorLinkHover: token.colorPrimary,
                colorLinkActive: token.colorPrimary,
              },
            }}
          >
            <Link to="..">
              <Button
                padding={0}
                type="link"
                icon={<LeftOutlined rev={undefined} />}
              >
                {t('waitinglist_back_to_list')}
              </Button>
            </Link>
          </ConfigProvider>
          {!xs && (
            <Title level={3}>
              {getSingleTranslation(
                waitingListData.waitinglist.eventName,
                lang
              )}
            </Title>
          )}

          <Space direction="vertical" style={{ width: '100%' }} size={24}>
            <WishListSummary data={getData(waitingListData)} isDetails />

            <Space>
              <Text>{t('wl_set_estimated-seats')}:</Text>
              {waitingListData && (
                <InputNumber
                  style={{ width: '68px' }}
                  min={waitingListData?.waitinglist?.estimatedTickets ?? 0}
                  defaultValue={
                    waitingListData?.totalAllocatedSeats +
                    waitingListData?.totalAvailableSeats
                  }
                  onChange={(val) => setEstimatedSeats(val ?? 0)}
                  value={estimatedSeats}
                />
              )}
              <Button
                disabled={
                  waitingListData?.waitinglist?.estimatedTickets ===
                  estimatedSeats
                }
                type="primary"
                onClick={updateEstimatedSeats}
              >
                {t('button_text_save')}
              </Button>
            </Space>

            {waitingListData && (
              <ApproversTable waitingListData={waitingListData} />
            )}
          </Space>
        </Space>
      </Container>
    </div>
  ) : null;
}

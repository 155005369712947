import { useState } from 'react';
import { notification, Skeleton, Spin } from 'antd';
import {
  FanGroupEntity,
  OperationParametersType,
  FormattedValuesWithTranslations,
  FanGroupGeneralValidatorSchema,
  FanGroupGeneralFormType,
} from '@seaters-app/constants';
import {
  useFetchAdminFanGroup,
  useUpdateAdminFanGroup,
} from '@seaters-app/data-access';
import { getTranslations } from '../../../../utils/helpers/getTranslations';
import { prepareValues } from '../../helpers/prepareValues';
import { useParams } from 'react-router-dom';
import { getGeneralInitialValues } from '../../helpers/getInitialValues';
import { FanGroupGeneralForm } from '../../fanGroupForm/FanGroupGeneralForm';
import { useTranslation } from 'react-i18next';

export function General() {
  const [spinning, setSpinning] = useState(false);
  const { t } = useTranslation();
  const { fanGroupId = '' } = useParams();

  const { data: fanGroup } = useFetchAdminFanGroup(fanGroupId);

  const { mutate: updateFanGroup, isLoading: isFanGroupUpdating } =
    useUpdateAdminFanGroup(fanGroupId);

  const valuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<FanGroupEntity>(
      [
        'welcomeText',
        'description',
        'name',
        'shortName',
        'geoFilteringNotificationTitle',
        'geoFilteringNotificationDescription',
      ],
      fanGroup
    );

  const operationParametersValuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<OperationParametersType>(
      [
        'optInPartnersTexts',
        'optInPlatformTexts',
        'optInTermsTexts',
        'optInRulesTexts',
        'termsAndConditions',
        'privacyPolicy',
        'rulesLinks',
        'legalNotice',
        'faqLinks',
        'helpLinks',
      ],
      fanGroup?.operationParameters
    );

  const onSubmit = (values: FanGroupGeneralFormType) => {
    const preparedValues = prepareValues(values);

    const parsed = FanGroupGeneralValidatorSchema.safeParse(preparedValues);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    const optInPartnersTexts = parsed.data.optInPartnersTexts;
    const optInPlatformTexts = parsed.data.optInPlatformTexts;
    const optInPartnersHidden = parsed.data.optInPartnersHidden;
    const optInPlatformHidden = parsed.data.optInPlatformHidden;
    const optInTermsTexts = parsed.data.optInTermsTexts;
    const optInRulesTexts = parsed.data.optInRulesTexts;
    const termsAndConditions = parsed.data.termsAndConditions;
    const privacyPolicy = parsed.data.privacyPolicy;
    const rulesLinks = parsed.data.rulesLinks;
    const legalNotice = parsed.data.legalNotice;
    const faqLinks = parsed.data.faqLinks;
    const helpLinks = parsed.data.helpLinks;

    const dataToSend = {
      ...fanGroup,
      ...parsed.data,

      operationParameters: {
        ...fanGroup?.operationParameters,
        ticketingSystemId: parsed.data.ticketingSystemId,
        optInPartnersTexts,
        optInPlatformTexts,
        optInPartnersHidden,
        optInPlatformHidden,
        optInTermsTexts,
        termsAndConditions,
        optInRulesTexts,
        privacyPolicy,
        rulesLinks,
        legalNotice,
        faqLinks,
        helpLinks,
      },
    };

    setSpinning(true);
    updateFanGroup(dataToSend, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_fg_updated'),
        });
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: t('notification_error_fg_updated'),
        });
      },
      onSettled: () => {
        setSpinning(false);
      },
    });
  };

  return fanGroup?.id ? (
    <>
      <Spin
        tip={t('please_wait_message_text')}
        fullscreen
        spinning={spinning}
      />
      <FanGroupGeneralForm
        initialValues={getGeneralInitialValues(
          fanGroup,
          valuesWithTranslations,
          operationParametersValuesWithTranslations
        )}
        onSubmit={onSubmit}
        isLoading={isFanGroupUpdating}
      />
    </>
  ) : (
    <Skeleton />
  );
}

import { z } from 'zod';
import { WaitingListAcceptFormat } from '../../../enums';

export const AcceptWaitingListValidatorSchema = z.object({
  format: z.nativeEnum(WaitingListAcceptFormat),
});

export type AcceptWaitingListValidator = z.infer<
  typeof AcceptWaitingListValidatorSchema
>;

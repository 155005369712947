import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Container } from '@seaters-app/ui';
import { useLocation } from 'react-router-dom';

export function ApiErrorPage() {
  const { t } = useTranslation();
  const state = useLocation();
  console.log('state', state);
  return (
    <Container style={{ height: '100%' }}>
      <Result
        style={{ marginTop: '20%' }}
        status="error"
        title={t('error_other_title')}
        subTitle={t('error_slug')}
      />
    </Container>
  );
}

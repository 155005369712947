import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { useFGAdminOptions } from '../../surveys/[id]/hooks/useFGAdminOptions';
import { getSingleTranslation } from '@seaters-app/data-access';

interface BaseSupportActionFormProps {}

const BaseSupportActionForm: React.FC<BaseSupportActionFormProps> = () => {
  const [searchText, setSearchText] = useState('');

  const {
    allItems,
    loadMoreData: loadMoreFGs,
    fanGroupsFetching,
  } = useFGAdminOptions(
    {
      page: 0,
      size: 20,
      query: searchText,
    },
    false
  );

  const fanGroupsOptions = allItems?.map((item) => {
    return {
      value: item?.id,
      label: `${getSingleTranslation(item?.name)} (slug: ${item?.slug})`,
    };
  });
  const handleSearchFGs = debounce((searchValue) => {
    setSearchText(searchValue);
  }, 500);

  const handleScrollFGs = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      e.target.scrollTop + e.target.offsetHeight >
      e.target.scrollHeight - 5
    ) {
      loadMoreFGs();
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <Form.Item label={t('admin_fan-groups')} name="fanGroupId">
        <Select
          optionFilterProp="label"
          placeholder={t('search_by_placeholder_text', {
            by: t('admin_fan-groups_slug'),
          })}
          allowClear
          style={{ width: '100%' }}
          onClear={loadMoreFGs}
          notFoundContent={
            fanGroupsFetching ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Spin size="small" />
              </div>
            ) : (
              !fanGroupsOptions.length && t('not_found')
            )
          }
          showSearch
          onSearch={handleSearchFGs}
          onPopupScroll={handleScrollFGs}
          options={fanGroupsOptions}
        />
      </Form.Item>
    </>
  );
};

export default BaseSupportActionForm;

import React from 'react';
import { Empty, EmptyProps } from 'antd';
import { useTranslation } from 'react-i18next';

export const EmptyState = ({
  image,
  children,
  description,
  ...props
}: EmptyProps) => {
  const { t } = useTranslation();
  return (
    <Empty
      image={image || Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{ height: 70 }}
      description={description || <span>{t('no_data')}</span>}
      {...props}
    >
      {children}
    </Empty>
  );
};

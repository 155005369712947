import { LOCAL_STORAGE_SLUG } from '@seaters-app/constants';
import {
  useFanGroupStore,
  useFetchFanGroupOwnerGroups,
} from '@seaters-app/data-access';
import { useEffect } from 'react';

/**
 * Custom hook for managing the FGO (Fan Group Owner) group.
 * This hook retrieves the fan group from the store and fetches the FGO groups.
 * It also manages the local storage for the fan group slug.
 */
export const useFGOGroup = () => {
  const { fanGroup, setFanGroup } = useFanGroupStore();

  const { data: fgoGroups } = useFetchFanGroupOwnerGroups({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const localStorageFanGroupSlug = localStorage.getItem(LOCAL_STORAGE_SLUG);

  // If the fan group slug changes, update the local storage
  useEffect(() => {
    if (fanGroup?.slug && localStorageFanGroupSlug !== fanGroup?.slug) {
      localStorage.setItem(LOCAL_STORAGE_SLUG, fanGroup?.slug);
    }
  }, [fanGroup?.slug, localStorageFanGroupSlug]);

  // If the local storage has a fan group slug, check if the user has access to it
  if (localStorageFanGroupSlug) {
    const localStorageFanGroup = fgoGroups?.items.find(
      (el) => el.slug === localStorageFanGroupSlug
    );

    const hasAccessToLocalStorageFanGroup = !!localStorageFanGroup;

    // If the user has access to the local storage fan group, set it
    if (hasAccessToLocalStorageFanGroup) {
      const fanGroupToSet = localStorageFanGroup;

      // If the fan group is not set, set it
      if (!fanGroup) {
        setFanGroup(fanGroupToSet);
      }
    } else {
      // If the user doesn't have access to the local storage fan group, set the first FGO group
      if (fgoGroups?.items.length) {
        setFanGroup(fgoGroups.items[0]);
      }
    }
  } else {
    // If the local storage doesn't have a fan group slug, set the first FGO group
    if (fgoGroups?.items.length && !localStorageFanGroupSlug) {
      setFanGroup(fgoGroups.items[0]);
    }
  }
};

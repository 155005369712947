import React, { useEffect } from 'react';
import { Breadcrumb, Flex, Steps, Typography, notification } from 'antd';
import styles from './createSurvey.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentStep } from './useCurrentStep';
import GeneralSurveyInfoStep from './steps/GeneralSurveyInfoStep';
import SurveyQuestions from './steps/SurveyQuestions';
import SurveyInstanceDetails from '../SurveyInstanceDetails/SurveyInstanceDetails';
import {
  SurveyExtensionPoint,
  SurveyInputMode,
  SurveyInstanceValidator,
  routes,
} from '@seaters-app/constants';
import {
  fetchAdminSurveys,
  useCreateAdminSurveyInstance,
} from '@seaters-app/data-access';
const { Title } = Typography;

export type StepType = {
  title: string;
  content: () => JSX.Element;
};

export function CreateSurveySteps() {
  const { current: currentStep, resetSteps, prev } = useCurrentStep();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: createSurveyInstance, isLoading: isCreating } =
    useCreateAdminSurveyInstance();

  const initialValues = {
    surveyId: '',
    fangroupId: null,
    waitinglistId: '',
    inputMode: SurveyInputMode.EDITABLE,
  };

  const createSurveyUsage = (dataToSend: SurveyInstanceValidator) => {
    createSurveyInstance(dataToSend, {
      onSuccess: (res) => {
        notification.success({
          message: t('notification_success_survey_usage_created'),
        });
        navigate(`../${routes.usage}/${res.id}`);
      },
      onError: ({ response }) => {
        response?.data?.errors?.errorsTypes?.validation_errors.map(
          ({ error }: { error: { errorCode: string } }) => {
            notification.error({
              message: t('notification_error_survey_usage_created'),
              description: t(error.errorCode),
            });
          }
        );
      },
    });
  };

  const steps: StepType[] = [
    {
      title: t('survey_creation_general_step'),
      content: () => <GeneralSurveyInfoStep isEdit={false} />,
    },
    {
      title: t('survey_creation_questions_step'),
      content: () => <SurveyQuestions footer />,
    },
    {
      title: t('survey_usage_label'),
      content: () => (
        <SurveyInstanceDetails
          surveysFetchFunction={fetchAdminSurveys}
          back={prev}
          initialValues={initialValues}
          onSubmit={createSurveyUsage}
        />
      ),
    },
  ];

  useEffect(() => {
    return () => {
      resetSteps();
    };
  }, []);

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
      gap={12}
    >
      <div className={styles.headerWrap}>
        <Breadcrumb
          items={[
            {
              title: <Link to={`..`}>{t('admin_surveys')}</Link>,
            },
            {
              title: <span>{t('main_breadcrumbs-create')}</span>,
            },
          ]}
        />
        <Title level={2}>{t('create_survey_title')}</Title>
      </div>
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Steps.Step disabled title={step.title} key={step.title} />
        ))}
      </Steps>
      <div>{steps[currentStep].content()}</div>
    </Flex>
  );
}

import { InterestEntity, InterestStatus } from '@seaters-app/constants';
import styles from '../../tabs.module.css';
import { Popover, Space, Table, Typography, theme } from 'antd';
import Column from 'antd/es/table/Column';
import { InterestsTableProps } from './InterestsTable';
import { getSingleTranslation } from '@seaters-app/data-access';
import { SmileOutlined, FrownOutlined, MehOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function InterestsTableMobile({
  dataSource,
  handleChange,
}: InterestsTableProps) {
  const { token } = theme.useToken();

  const [openPopover, setOpenPopover] = useState<{ open: boolean; id: string }>(
    { open: false, id: '' }
  );

  const { t } = useTranslation();
  const hide = () => {
    setOpenPopover({ open: false, id: '' });
  };

  const handleOpenChange = (newOpen: boolean, id: string) => {
    setOpenPopover({ open: newOpen, id: id });
  };

  const content = (record: InterestEntity) => {
    return (
      <Space>
        <SmileOutlined
          style={{ fontSize: '24px', color: token.colorSuccess }}
          rev={undefined}
          onClick={() => {
            handleChange(record.interest.id, InterestStatus.LIKE);
            hide();
          }}
        />
        <MehOutlined
          style={{ fontSize: '24px', color: token.colorWarning }}
          rev={undefined}
          onClick={() => {
            handleChange(record.interest.id, InterestStatus.NEUTRAL);
            hide();
          }}
        />
        <FrownOutlined
          style={{ fontSize: '24px', color: token.colorError }}
          rev={undefined}
          onClick={() => {
            handleChange(record.interest.id, InterestStatus.DISLIKE);
            hide();
          }}
        />
      </Space>
    );
  };

  return (
    <Table
      pagination={false}
      dataSource={dataSource}
      className={styles.interestsMobileTable}
      size="middle"
    >
      <Column
        title={t('general_name')}
        dataIndex="interest"
        key="name"
        render={(interest) => (
          <span>{getSingleTranslation(interest.name)}</span>
        )}
      />
      <Column
        key="status"
        align="center"
        width="25%"
        render={(_, record: InterestEntity) => {
          return (
            <Popover
              content={content(record)}
              open={openPopover.open && openPopover.id === record.interest.id}
              onOpenChange={() => {
                handleOpenChange(!openPopover.open, record.interest.id);
              }}
            >
              {record.status === InterestStatus.LIKE ? (
                <SmileOutlined
                  style={{ fontSize: '24px', color: token.colorSuccess }}
                  rev={undefined}
                />
              ) : record.status === InterestStatus.DISLIKE ? (
                <FrownOutlined
                  style={{ fontSize: '24px', color: token.colorError }}
                  rev={undefined}
                />
              ) : (
                <MehOutlined
                  style={{ fontSize: '24px', color: token.colorWarning }}
                  rev={undefined}
                />
              )}
            </Popover>
          );
        }}
      />
    </Table>
  );
}

export default InterestsTableMobile;

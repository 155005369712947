import React from 'react';
import {
  Breadcrumb,
  Typography,
  notification,
  Space,
  Popconfirm,
  theme,
  Flex,
} from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  fetchAdminSurveys,
  useDeleteAdminSurveyInstance,
  useFetchAdminSurveyInstance,
  useUpdateAdminSurveyInstance,
} from '@seaters-app/data-access';
import SurveyInstanceDetails from './SurveyInstanceDetails';
import { getInitialValues } from './getInitialValues';
import { Button } from '@seaters-app/ui';
import { SurveyInstanceBody, routes } from '@seaters-app/constants';
import { DeleteOutlined } from '@ant-design/icons';
const { Title } = Typography;

function SurveyInstanceEdit() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { surveyInstanceId = '' } = useParams();
  const { data: surveyInstance } =
    useFetchAdminSurveyInstance(surveyInstanceId);

  const { mutate: updateSurveyInstance, isLoading: isUpdating } =
    useUpdateAdminSurveyInstance(surveyInstanceId);

  const { mutate: deleteSurveyInstance } = useDeleteAdminSurveyInstance();

  const handleRemoveSurveyInstance = (id: string) => {
    deleteSurveyInstance(
      { surveyInstanceId: id },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_survey_usage_remove'),
          });
        },
        onError: () => {
          notification.error({
            message: t('notification_error_survey_usage_remove'),
          });
        },
      }
    );
  };

  const initialValues = getInitialValues(surveyInstance);

  const updateSurvey = (dataToSend: SurveyInstanceBody) => {
    updateSurveyInstance(dataToSend, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_survey_usage_updated'),
        });
        navigate(`../${routes.usage}`);
      },
      onError: ({ response }) => {
        response?.data?.errors?.errorsTypes?.validation_errors.map(
          ({ error }) => {
            notification.error({
              message: t('notification_error_survey_usage_updated'),
              description: t(error.errorCode),
            });
          }
        );
      },
    });
  };

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`../${routes.usage}`}>{t('survey_usage_label')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('survey_usage_details_label')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Popconfirm
          title={t('delete_wl_survey_button_text')}
          description={t('delete_wl_survey_confirmation_text')}
          cancelText={t('general_no')}
          onConfirm={() => handleRemoveSurveyInstance(surveyInstanceId)}
          onOpenChange={() => console.log('open change')}
        >
          <Button
            type="link"
            color={token.colorError}
            icon={<DeleteOutlined rev={undefined} />}
          >
            {t('mwl_wl_fan-remove-btn')}
          </Button>
        </Popconfirm>
      </Space>
      <Title level={3}>{t('survey_usage_details_label')}</Title>
      <SurveyInstanceDetails
        initialValues={initialValues}
        onSubmit={updateSurvey}
        surveyInstance={surveyInstance}
        surveysFetchFunction={fetchAdminSurveys}
      />
    </Flex>
  );
}

export default SurveyInstanceEdit;

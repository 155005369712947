import {
  ListEntity,
  PaginationQuery,
  PaymentSystem,
  paymentSystemsKeys,
} from '@seaters-app/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createPaymentSystem,
  deletePaymentSystem,
  fetchPaymentSystems,
  updatePaymentSystem,
} from '../api';

// Ticketing systems
export const useFetchPaymentSystems = (
  params: PaginationQuery,
  enabled = true
) =>
  useQuery<ListEntity<PaymentSystem>, Error>(
    paymentSystemsKeys.list(params),
    () => fetchPaymentSystems(params),
    {
      keepPreviousData: true,
      enabled: enabled,
    }
  );

export const useCreatePaymentSystem = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (paymentSystem: PaymentSystem) => createPaymentSystem(paymentSystem),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(paymentSystemsKeys.all());
      },
    }
  );
};

export const useUpdatePaymentSystem = (paymentSystemId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (paymentSystem: PaymentSystem) =>
      updatePaymentSystem(paymentSystemId, paymentSystem),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(paymentSystemsKeys.all());
      },
    }
  );
};

// useDeletePaymentSystem;
export const useDeletePaymentSystem = () => {
  const queryClient = useQueryClient();

  return useMutation((systemId: string) => deletePaymentSystem(systemId), {
    onSuccess: () => {
      queryClient.invalidateQueries(paymentSystemsKeys.all());
    },
  });
};

import {
  endpoints,
  FanEntity,
  ListEntity,
  PaginationQuery,
  V2Prefix,
  FanListEntity,
  BadgeEventEntity,
  FanBadgeLinkBody,
  FanActivityEntity,
  FanAccessRequest,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchFanGroupFans = (
  fanGroupId: string,
  params: PaginationQuery
): Promise<ListEntity<FanListEntity>> =>
  api.get(
    `/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.members}`,
    { params }
  );

export const fetchFanPersonalInfo = (fanId: string): Promise<FanEntity> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.fan}/${fanId}/${endpoints.personalInfos}`
  );

export const grantUserBadge = (
  groupId: string,
  body: FanBadgeLinkBody
): Promise<BadgeEventEntity> => {
  return api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${groupId}/${endpoints.badges}/${endpoints.grant}/`,
    body
  );
};

export const revokeUserBadge = (
  groupId: string,
  body: FanBadgeLinkBody
): Promise<BadgeEventEntity> => {
  return api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${groupId}/${endpoints.badges}/${endpoints.revoke}/`,
    body
  );
};

export const fetchFanActivities = (
  fanId: string,
  groupId: string
): Promise<FanActivityEntity[]> =>
  api.get(
    `/${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.fan}/${fanId}/${endpoints.groups}/${groupId}?sort=eventDate:ASC`
  );

// GET https://api.qa-seaters.com/api/v2/fan-group-owner/fan/fanId/groups/groupId/requests/

export const fetchFanAccessRequests = (
  fanId: string,
  groupId: string
): Promise<FanAccessRequest[]> =>
  api.get(
    `/${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.fan}/${fanId}/${endpoints.groups}/${groupId}/${endpoints.requests}`
  );

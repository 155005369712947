import {
  GuestItem,
  useCreateGuestCustomInfo,
  useFetchWaitingList,
  useGuestListStore,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { groupsKeys, SemanticNameEnum } from '@seaters-app/constants';

import Modal from 'antd/es/modal';
import Table from 'antd/es/table';
import { useEffect, useState } from 'react';
import { notification, Typography } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@seaters-app/ui';

const { Text } = Typography;

type PublicOfficialModalType = {
  waitingListId: string;
  isOpened: boolean;
  onClose: () => void;
};

export function PublicOfficialModal({
  waitingListId,
  isOpened,
  onClose,
}: PublicOfficialModalType) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { data: waitingList } = useFetchWaitingList(waitingListId);

  const groupId = waitingList?.groupId ?? '';

  const groupGuests = useGuestListStore((state) => Object.values(state.guests));

  const { mutate: createGuestCustomInfo } = useCreateGuestCustomInfo(groupId);

  const publicOfficialGuests = groupGuests
    .filter(
      (guest) =>
        guest.guest.customInfos.find(
          (customInfo) =>
            customInfo.semanticName === SemanticNameEnum.PUBLIC_OFFICIAL
        )?.informationValue || false
    )
    .map((el) => el.guest.guestId);

  useEffect(() => {
    if (!selectedRowKeys.length && publicOfficialGuests.length) {
      setSelectedRowKeys(publicOfficialGuests);
    }
  }, [publicOfficialGuests]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const submitPublicOfficial = () => {
    // setLoading(true);
    groupGuests.forEach(({ guest }) => {
      createGuestCustomInfo(
        {
          guestId: guest.guestId,
          body: {
            informationValue: selectedRowKeys.includes(guest.guestId),
            semanticName: SemanticNameEnum.PUBLIC_OFFICIAL,
          },
        },
        {
          onSuccess: () => {
            // Will execute only once, for the last mutation (Todo 3),
            // regardless which mutation resolves first

            notification.success({
              message: t('notification_public_official_update_success'),
            });
            // setLoading(false);
            onClose();
            queryClient.invalidateQueries(groupsKeys.guests(groupId));
          },
        }
      );
    });
  };

  return (
    <Modal
      title={t('public_official')}
      open={isOpened}
      footer={
        <div>
          <Button key="back" onClick={onClose}>
            {t('button_text_cancel')}
          </Button>

          <Button key="submit" type="primary" onClick={submitPublicOfficial}>
            {t('general_ok-btn')}
          </Button>
        </div>
      }
    >
      <Text>{t('guestlist_select_public_official')}</Text>
      <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
        <Table
          title={() => `${selectedRowKeys.length} ${t('selected')}`}
          rowSelection={rowSelection}
          pagination={false}
          columns={[
            {
              title: t('guestlist_name'),
              dataIndex: 'name',
              render: (_, { guest }: GuestItem) => {
                return (
                  <span>
                    {guest.firstName} {guest.lastName}
                  </span>
                );
              },
            },
          ]}
          dataSource={groupGuests.filter((item) => !item.isToAllocated)}
          rowKey={(record) => record.guest?.guestId}
        />
      </div>
    </Modal>
  );
}

export const isWebView = () => {
  const userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera;

  return (
    /wv|WebView/.test(userAgent) ||
    /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/.test(userAgent) ||
    /\bwv\b/.test(userAgent) ||
    /\bWebView\b/.test(userAgent)
  );
};

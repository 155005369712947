import { create } from 'zustand';

interface EmailConfirmationState {
  showReminder: boolean | undefined;
  setEmailReminder: (showReminder: boolean | undefined) => void;
}

export const useEmailConfirmationStore = create<EmailConfirmationState>(
  (set) => ({
    showReminder: false,
    setEmailReminder: (showReminder: boolean | undefined) =>
      set(() => ({ showReminder: showReminder })),
  })
);

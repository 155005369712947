import { useTranslation } from 'react-i18next';
import { Breadcrumb, Flex } from 'antd';
import styles from './detailMailCampaign.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MailCampaignDetail from './components/MailcampaignDetail';
import { useFetchMailcampaign } from '@seaters-app/data-access';

export default function mailcampaign_details() {
  const { t } = useTranslation();
  const { mailcampaignId = '' } = useParams();
  const { data: mailcampaign } = useFetchMailcampaign(mailcampaignId);
  const navigate = useNavigate();
  const edit = () => {
    navigate(`../edit/${mailcampaignId}`);
  };

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <div className={styles.headerWrap}>
        <Breadcrumb
          items={[
            {
              title: <Link to="..">{t('mailcampaign')}</Link>,
            },
            {
              title: (
                <span>
                  {t('main_breadcrumbs-detail')} {t('mailcampaign')}
                </span>
              ),
            },
          ]}
        />
        <h3 className={styles.title}>
          {t('main_breadcrumbs-create')} {t('mailcampaign')}
        </h3>
      </div>
      <MailCampaignDetail
        edit={mailcampaign?.state === 'draft'}
        editContent={edit}
        editDetail={edit}
      />
    </Flex>
  );
}

import { FanEntity, FanProfileFormEntity } from '@seaters-app/constants';
import dayjs from 'dayjs';

export const getFanFieldsToUpdate = (
  values: FanProfileFormEntity,

  fanProfile?: FanEntity
) => {
  const {
    email,
    countryCallingCode,
    localNumber,
    lastName,
    firstName,
    citizenship,
    company,
    country,
    city,
    state,
    postalCode,
    line1,
    line2,
    line3,
    bySms,
    byMail,
    allowDirectMarketingFromSeaters,
    allowDirectMarketingFromPartners,
    birthDate,
    language,
  } = values;
  return {
    ...fanProfile,
    email,
    mobilePhoneNumber:
      !countryCallingCode || !localNumber
        ? null
        : {
            countryCallingCode,
            localNumber,
          },
    lastName,
    firstName,
    notificationChannels: {
      bySms,
      byMail,
    },
    directMarketingSettings: {
      allowDirectMarketingFromSeaters,
      allowDirectMarketingFromPartners,
    },
    language,
    invoiceInfo: {
      address: {
        countryCode: country,
        zipCode: postalCode,
        city,
        line1,
        line2,
        line3,
        state,
      },
    },
    personalInfo: {
      citizenshipCountryCode: citizenship,
      company: company,
      birthDate: birthDate ? dayjs(birthDate).format('YYYY-MM-DD') : null,
      address: {
        country,
        countryCode: country,
        zipCode: postalCode,
        city,
        line1,
        line2,
        line3,
        state,
      },
    },
  };
};

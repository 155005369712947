import {
  AxiosAPIError,
  SignUpResultEntity,
  SignupValidator,
} from '@seaters-app/constants';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { signUp } from '../api';

export const useSignUp = (): UseMutationResult<
  SignUpResultEntity,
  AxiosAPIError<SignupValidator>,
  SignupValidator,
  unknown
> => {
  return useMutation((body: SignupValidator) => signUp(body));
};

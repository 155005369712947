import { z } from 'zod';
import { TranslationValidatorSchema } from '../translation.validator';
import { BadgeMoodEnum } from '../../../enums';
import { NotEmptyDefaultTranslationValidationSchema } from '../venue.validator';

export const BadgeFormValidatorSchema = z.object({
  name: NotEmptyDefaultTranslationValidationSchema,
  categoryId: z.string().optional(),
  displayedText: NotEmptyDefaultTranslationValidationSchema,
  description: z.record(z.string().min(1), z.string()).optional(),
  hidden: z.boolean(),
  displayedLogoUrl: z.any().optional().nullable(),
  displayedColor: z.string(),
  mood: z.nativeEnum(BadgeMoodEnum),
});

export type BadgeFormValidator = z.infer<typeof BadgeFormValidatorSchema>;

export const BadgeValidatorSchema = z.object({
  name: z.array(TranslationValidatorSchema),
  description: z.array(TranslationValidatorSchema),
  displayedText: z.array(TranslationValidatorSchema),
  categoryId: z.string().optional(),
  hidden: z.boolean(),
  displayedLogoUrl: z.any().optional().nullable(),
  displayedLogoImageId: z.string().optional(),
  displayedColor: z.string(),
  mood: z.nativeEnum(BadgeMoodEnum),
});

export type BadgeValidator = z.infer<typeof BadgeValidatorSchema>;

export const WishListBadgeInstanceValidatorSchema = z.object({
  badgeAttribute: z.string(),
  status: z.string(),
  badgeId: z.string(),
});

export type WishListBadgeInstanceValidator = z.infer<
  typeof WishListBadgeInstanceValidatorSchema
>;

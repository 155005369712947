import { EmailValidator, routes } from '@seaters-app/constants';
import { getSlugFromUrl, useResetPassword } from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Typography,
  notification,
  theme,
} from 'antd';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { NavLink } from 'react-router-dom';
import ResetPasswordConfirmationCard from './ResetPasswordConfirmationCard';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdatePassword from '../UpdatePassword/UpdatePassword';
const { Title, Text } = Typography;

function ResetPassword() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const slug = getSlugFromUrl();
  const resetToken = searchParams.get('token');
  const { token } = theme.useToken();
  const { pathname } = useLocation();

  const isAdminApp = pathname.includes('admin');

  const [emailToReset, setEmailToReset] = useState<string | null>(null);

  const { mutate: resetPassword, isLoading: resetPasswordIsLoading } =
    useResetPassword();

  const onFinish = async (values: EmailValidator) => {
    resetPassword(
      { email: values.email, slug: isAdminApp ? routes.admin : slug ?? '' },
      {
        onSuccess: () => {
          setEmailToReset(values.email);
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('api_email_invalid_email'),
          });
        },
      }
    );
  };

  return !resetToken ? (
    <Card className="publicForm" bordered={false}>
      <Row justify="center" align="middle">
        <Col span={24}>
          {!emailToReset ? (
            <Form
              name="basic"
              layout="vertical"
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              autoComplete="off"
              disabled={resetPasswordIsLoading}
            >
              <Space
                direction="vertical"
                style={{
                  marginBottom: 24,
                }}
              >
                <Title level={3}>{t('forgetpassword_text_title')}</Title>
                <Text>{t('forgetpassword_text_instruction')}</Text>
              </Space>

              <Form.Item
                label={t('input_label_email')}
                name="email"
                rules={[
                  { required: true, message: t('checkout_missingfield_email') },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={resetPasswordIsLoading}
                >
                  {t('button_text_send')}
                </Button>
              </Form.Item>
              <ConfigProvider
                theme={{
                  token: {
                    colorLink: token.colorPrimary,
                    colorLinkHover: token.colorPrimary,
                    colorLinkActive: token.colorPrimary,
                  },
                }}
              >
                <NavLink to={`../${routes.login}`}>
                  <Button size="small" type="link" style={{ width: '100%' }}>
                    {t('button_text_backto_login')}
                  </Button>
                </NavLink>
              </ConfigProvider>
            </Form>
          ) : (
            <ResetPasswordConfirmationCard email={emailToReset} />
          )}
        </Col>
      </Row>
    </Card>
  ) : (
    <UpdatePassword token={resetToken} />
  );
}

export default ResetPassword;

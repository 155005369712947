import React, { useState } from 'react';
import { useValidatePhoneCode } from '@seaters-app/data-access';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from 'antd';
import { CustomDivider } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface MobilePhoneVerificationModalProps {
  isModalShown: boolean;
  setIsModalShown: (v: boolean) => void;
  countryCode: string;
  phoneNumber: string;
}

export function MobilePhoneVerificationModal({
  isModalShown,
  setIsModalShown,
  countryCode,
  phoneNumber,
}: MobilePhoneVerificationModalProps) {
  const { t } = useTranslation();

  const { mutate: validateFanCode } = useValidatePhoneCode();
  const [mobileCode, setMobileCode] = useState('');
  const initialValues = { code: '' };
  const [form] = Form.useForm();

  const validate = (
    mobileCode: string,
    countryCode: string,
    phoneNumber: string
  ) => {
    validateFanCode(
      {
        code: mobileCode,
        mobile: {
          countryCallingCode: countryCode,
          localNumber: phoneNumber.slice(countryCode.length),
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_profile_phone_number_verified_success'),
          });
          form.resetFields();
          setIsModalShown(false);
        },
        onError: (err) => {
          form.resetFields();
          console.error(err);
          notification.error({
            message: t('notification_profile_phone_number_verified_error'),
            description: t(
              'notification_profile_phone_number_verified_error_message'
            ),
          });
        },
      }
    );
  };
  return (
    <Modal
      title={t('settings_mobile_phone_verification_title')}
      centered
      footer={null}
      open={isModalShown}
      onCancel={() => {
        setMobileCode('');
        setIsModalShown(false);
        form.resetFields();
      }}
      width={500}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Text>{t('settings_confirm-phone-modal_info_new')}</Text>
        <CustomDivider />
        <Row gutter={24}>
          <Col>
            <Form.Item name="code" label={t('confirmation_code_label')}>
              <Input
                onChange={(e) => {
                  setMobileCode(e.target.value);
                }}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="hidden">
              <Button
                type="primary"
                onClick={() => validate(mobileCode, countryCode, phoneNumber)}
              >
                {t('fan_profile_settings_verify')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

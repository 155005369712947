import { BadgeInstance } from '@seaters-app/constants';

export const getGroupedBadges = (
  badges: BadgeInstance[]
): {
  entities: BadgeInstance[];
  badgeId: string;
}[] => {
  // group badge instances by badge:
  // (Badge1 = {badge: X, attribute: VISIBLE} ,
  // Badge2 = {badge: X, attribute: JOINABLE} =>
  // GroupedBadge = {badgeId: X.id, entities: [Badge1, Badge2]})

  const badgesGrouped = badges?.reduce(
    (
      acc: Array<{
        entities: BadgeInstance[];
        badgeId: string;
      }>,
      curr: BadgeInstance
    ) => {
      const badge = {
        badgeId: curr.badge.id,
        entities: [curr],
      };
      const isNameExist = acc.find((item) => item.badgeId === badge.badgeId);
      if (!isNameExist) {
        acc.push(badge);
      } else {
        acc[acc.indexOf(isNameExist)].entities.push(badge.entities[0]);
      }
      return acc;
    },
    []
  );
  return badgesGrouped;
};

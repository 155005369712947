import React, { useEffect, useState } from 'react';
import { useWaitingLists } from './hooks/useWaitingLists';
import WaitingListCard from './components/WaitingListCard/WaitingListCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import Banner from './components/Banner/Banner';
import {
  Container,
  GalleryCardSkeleton,
  EmptyState,
  Button,
} from '@seaters-app/ui';
import {
  getSlugFromUrl,
  queryClient,
  useFetchFGBySlug,
  useFetchLook,
} from '@seaters-app/data-access';
import styles from './waitingLists.module.css';
import { ProtectedCodeModal } from '@seaters-app/ui-shared';
import {
  AccessMode,
  RequestAccessStatus,
  routes,
  waitingListsKeys,
} from '@seaters-app/constants';
import FiltersExplanationModal from './components/Filters/FiltersExplanationModal';
import Filters from './components/Filters/Filters';
import { useFilteredWaitingLists } from './hooks/useFilteredWaitingLists';
import { useTranslation } from 'react-i18next';
import { useFilters } from './hooks/useFilters';
import { Grid } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { SSOLoginTypes } from '../fan-groups/fgo-configuration/FGRegistrationConfig';
import { SSOWarning } from './components/SSOWarning/SSOWarning';
import { TermsAndConditionsModal } from './components/TermsAndConditonsModal/TermsAndConditionsModal';
import { isWebView } from '../../utils';
const { useBreakpoint } = Grid;

export function WaitingLists() {
  const { t } = useTranslation();
  const slug = getSlugFromUrl();
  const { xs } = useBreakpoint();

  const webView = isWebView();

  const [isSSOWarningModalShown, setSSOWarningModalShown] = useState(false);

  const [filtersInfoShown, setFiltersInfoShown] = useState(false);

  const {
    data: fanGroupData,
    isLoading: isFGDataLoading,
    isFetching: isFGDataFetching,
  } = useFetchLook(slug ?? '');

  const fanGroupCoverImage = fanGroupData?.coverImageUrl;

  const { allItems, loadMoreData, hasNextPage, isLoading } = useWaitingLists();

  const { filters, setSelectedFilters, selectedFilters } = useFilters();

  const [isFiltersExpanded, setFiltersExpanded] = useState(false);

  const switchExpand = () => {
    setFiltersExpanded(!isFiltersExpanded);
  };

  const {
    allFilteredItems,
    loadMoreFilteredData,
    hasNextFilteredPage,
    isLoadingFiltered,
  } = useFilteredWaitingLists({
    maxPageSize: 20,
    itemOffset: 0,
    ...selectedFilters,
  });

  useEffect(() => {
    if (selectedFilters) {
      loadMoreFilteredData(selectedFilters, true);
    }
  }, [selectedFilters]);

  const [isProtectedCodeModalShown, setProtectedCodeModalShown] =
    useState(false);

  const { data: fanGroupBySlugData, isRefetching } = useFetchFGBySlug(
    slug ?? '',
    false
  );

  const setProtectedModalCondition =
    fanGroupBySlugData &&
    (fanGroupBySlugData?.membership?.request?.status ===
      RequestAccessStatus.REJECTED ||
      (!fanGroupBySlugData.membership.request &&
        !fanGroupBySlugData.fanMember)) &&
    fanGroupBySlugData.accessMode === AccessMode.CODE_PROTECTED &&
    !isRefetching;

  useEffect(() => {
    if (setProtectedModalCondition) {
      setProtectedCodeModalShown(true);
    }
  }, [fanGroupBySlugData?.slug, isRefetching]);

  useEffect(() => {
    if (
      fanGroupBySlugData?.membership?.request?.status ===
        RequestAccessStatus.ACCEPTED &&
      isProtectedCodeModalShown
    ) {
      setProtectedCodeModalShown(false);
    }
  }, [fanGroupBySlugData?.membership?.request?.status, isRefetching]);

  useEffect(() => {
    if (fanGroupBySlugData?.fanMember) {
      queryClient.invalidateQueries(
        waitingListsKeys.filtered(fanGroupBySlugData.id, {
          maxPageSize: 20,
          itemOffset: 0,
          ...selectedFilters,
        })
      );
    }
  }, [fanGroupBySlugData?.fanMember]);

  useEffect(() => {
    if (
      !fanGroupBySlugData?.fanMember &&
      fanGroupData?.login.length === 1 &&
      SSOLoginTypes.includes(fanGroupData?.login[0].type)
    ) {
      setSSOWarningModalShown(true);
    }
  }, [fanGroupBySlugData, fanGroupData]);

  const isNotAllowedToSeeWLs =
    !fanGroupBySlugData?.fanMember &&
    fanGroupData?.login.length === 1 &&
    SSOLoginTypes.includes(fanGroupData?.login[0].type);

  const showAcceptTermsAndConditions =
    !webView &&
    fanGroupData?.login.length === 1 &&
    SSOLoginTypes.includes(fanGroupData?.login[0].type) &&
    !document.cookie.includes(`termsAndConditions-${slug}=true`) &&
    !isNotAllowedToSeeWLs;

  return (
    <div className={styles.waitingLists}>
      <Banner
        image={fanGroupCoverImage ?? ''}
        isLoading={isFGDataLoading || isFGDataFetching}
      />
      {xs && filters && (
        <Button
          style={{ marginTop: 16 }}
          type="text"
          onClick={switchExpand}
          icon={
            isFiltersExpanded ? (
              <UpOutlined rev={undefined} />
            ) : (
              <DownOutlined rev={undefined} />
            )
          }
        >
          {!isFiltersExpanded ? t('show_filters_btn') : t('hide_filters_btn')}
        </Button>
      )}
      {isNotAllowedToSeeWLs && !webView ? (
        <SSOWarning isSSOWarningModalShown={isSSOWarningModalShown} />
      ) : (
        <>
          {filters && (!xs || isFiltersExpanded) && (
            <Filters
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setFiltersInfoShown={setFiltersInfoShown}
              externalLink={fanGroupData?.properties?.externalLink}
            />
          )}
          <Container>
            {filters ? (
              <InfiniteScroll
                style={{ overflow: 'visible' }}
                dataLength={allFilteredItems?.length ?? 0}
                next={() => loadMoreFilteredData(selectedFilters)}
                hasMore={!!hasNextFilteredPage}
                loader={
                  <div className={styles.waitingListsContainer}>
                    {[...Array(4)].map(() => (
                      <GalleryCardSkeleton />
                    ))}
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                {isLoadingFiltered ? (
                  <div className={styles.waitingListsContainer}>
                    {[...Array(9)].map(() => (
                      <GalleryCardSkeleton />
                    ))}
                  </div>
                ) : allFilteredItems.length ? (
                  <div className={styles.waitingListsContainer}>
                    {allFilteredItems?.map((item) => (
                      <WaitingListCard
                        redirectPath={`../${routes.waitingList}/${item.waitingListId}`}
                        waitingList={item}
                      />
                    ))}
                  </div>
                ) : (
                  <EmptyState
                    description={
                      <p>
                        {t('wish_lists_empty_state_message')}
                        <br /> {t('come_back_later_empty_state_message')}
                      </p>
                    }
                  />
                )}
              </InfiniteScroll>
            ) : (
              <InfiniteScroll
                style={{ overflow: 'visible' }}
                dataLength={allItems?.length ?? 0}
                next={() => loadMoreData()}
                hasMore={!!hasNextPage}
                loader={
                  <div className={styles.waitingListsContainer}>
                    {[...Array(4)].map(() => (
                      <GalleryCardSkeleton />
                    ))}
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                {isLoading ? (
                  <div className={styles.waitingListsContainer}>
                    {[...Array(9)].map(() => (
                      <GalleryCardSkeleton />
                    ))}
                  </div>
                ) : allItems.length ? (
                  <div className={styles.waitingListsContainer}>
                    {allItems?.map((item) => (
                      <WaitingListCard
                        redirectPath={`../${routes.waitingList}/${item.waitingListId}`}
                        waitingList={item}
                      />
                    ))}
                  </div>
                ) : (
                  <EmptyState
                    description={
                      <p>
                        {t('wish_lists_empty_state_message')}
                        <br /> {t('come_back_later_empty_state_message')}
                      </p>
                    }
                  />
                )}
              </InfiniteScroll>
            )}
          </Container>
          {filters && (
            <FiltersExplanationModal
              filtersInfoShown={filtersInfoShown}
              setFiltersInfoShown={setFiltersInfoShown}
              filters={filters}
            />
          )}
          <ProtectedCodeModal
            isProtectedCodeModalShown={isProtectedCodeModalShown}
            setProtectedCodeModalShown={setProtectedCodeModalShown}
          />
        </>
      )}
      {showAcceptTermsAndConditions && (
        <TermsAndConditionsModal
          showAcceptTermsAndConditions={showAcceptTermsAndConditions}
        />
      )}
    </div>
  );
}

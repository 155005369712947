import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Job } from '@seaters-app/constants';
import { Space, Steps } from 'antd';
import { StepProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';

function JobSteps({ job }: { job: Job }) {
  const { t } = useTranslation();
  const successSteps: StepProps[] =
    job?.stepHistory.length > 1 || job?.stepHistory[0] !== job.latestStep
      ? job?.stepHistory.length > 1
        ? job?.stepHistory
            .slice(1, job?.stepHistory.length)
            .reverse()
            .map((step): StepProps => ({ status: 'finish', title: t(step) }))
        : job?.stepHistory
            .reverse()
            .map((step): StepProps => ({ status: 'finish', title: t(step) }))
      : [];

  const latestStep: StepProps[] =
    job?.latestStep !== 'none' && !(job?.status === 'running' && !job?.nextStep)
      ? [
          {
            title: t(job?.stepHistory[0] ?? job?.latestStep),
            status: job?.status === 'failed' ? 'error' : 'finish',
          },
        ]
      : [];

  const pendingStep: StepProps[] = !(
    !job?.nextStep && job?.status !== 'running'
  )
    ? [
        {
          icon: (job?.status === 'pending' || job?.status === 'running') && (
            <LoadingOutlined rev={undefined} />
          ),
          status: 'process',
          title: (
            <Space.Compact direction="vertical">
              {job?.nextStep ? t(job?.nextStep) : t(job?.latestStep)}{' '}
              <span>
                {job?.status === 'running' && `${job?.completionPercentage} %`}
              </span>
            </Space.Compact>
          ),
        },
      ]
    : [];

  const nextSteps: StepProps[] = !(!job?.nextStep && job?.status !== 'running')
    ? [{ title: <>...</>, status: 'wait' }]
    : [];

  const steps: StepProps[] = [
    ...successSteps,
    ...latestStep,
    ...pendingStep,
    ...nextSteps,
    {
      status:
        job?.status === 'failed'
          ? 'error'
          : !job?.nextStep && job?.status !== 'running'
          ? 'finish'
          : 'wait',
      icon:
        job?.status === 'failed' ? (
          <CloseOutlined rev={undefined} />
        ) : (
          <CheckOutlined rev={undefined} />
        ),
      title: <>{t('general_finish-btn')}</>,
    },
  ];

  return <Steps size="small" labelPlacement="vertical" items={steps} />;
}

export default JobSteps;

import { useFetchAdvancedSurveys } from '@seaters-app/data-access';
import { TablePaginationConfig } from 'antd';
import { usePagination } from '../../../../utils';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { UserRole } from '@seaters-app/constants';

export const useSurveys = (
  userRole: UserRole.FAN_GROUP_OWNER | UserRole.ADMIN
) => {
  const [query, setQuery] = useQueryParams({
    maxPageSize: withDefault(NumberParam, 10),
    itemOffset: withDefault(NumberParam, 0),
    search: withDefault(StringParam, ''),
  });

  const { maxPageSize, itemOffset, search } = query;
  const { data: surveys, isFetching: surveysLoading } = useFetchAdvancedSurveys(
    userRole,
    {
      itemOffset,
      maxPageSize,
      query: search,
    }
  );
  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      maxPageSize: pageSize,
      itemOffset: current && pageSize ? (current - 1) * pageSize : 0,
    });
  };

  const pagination = usePagination({
    pageSize: query.maxPageSize,
    current: !query.itemOffset ? 1 : query.itemOffset / query.maxPageSize + 1,
    totalSize: surveys?.totalSize,
  });

  return {
    surveys,
    surveysLoading,
    itemOffset,
    handleTableChange,
    setQuery,
    pagination,
    query,
  };
};

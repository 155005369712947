import {
  OLD_LOCAL_STORAGE_VERIFICATION,
  SESSION_STORAGE_VERIFICATION,
  VerificationType,
  VerificationTypeV2,
} from '@seaters-app/constants';
import { getVerificationToken } from '../utils';

export function getSessionData(): VerificationTypeV2 | null {
  let sessionData;

  const sessionStorageData = sessionStorage.getItem(
    SESSION_STORAGE_VERIFICATION
  );

  if (sessionStorageData) {
    sessionData = JSON.parse(sessionStorageData);
  }

  const localStorageData = localStorage.getItem(OLD_LOCAL_STORAGE_VERIFICATION);

  if (localStorageData) {
    sessionData = JSON.parse(localStorageData);
  }

  return sessionData;
}

export function setSessionData(data: VerificationTypeV2) {
  if (data) {
    sessionStorage.setItem(SESSION_STORAGE_VERIFICATION, JSON.stringify(data));
  } else {
    sessionStorage.removeItem(SESSION_STORAGE_VERIFICATION);
  }
}

export function getSessionToken(): string | null {
  const sessionData = getSessionData();

  if (!sessionData) return null;

  const token = getVerificationToken(sessionData);

  return token;
}

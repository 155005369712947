import { UseFormSetValue } from 'react-hook-form';

export const updateForm = (
  setValue: UseFormSetValue<any>,
  initialValues: any
) => {
  for (const [key, value] of Object.entries(initialValues)) {
    setValue(key, value);
  }
};

import { z } from 'zod';
import {
  QuestionStructureType,
  QuestionType,
  SurveyStatus,
} from './../../enums';
import { NotEmptyTranslationValidationSchema } from './translation.validator';
import {
  getTranslationValidation,
  getTranslationValidationOptional,
} from '../../helpers';

export const SurveyStructureQuestionValidatorSchema = z.array(
  z.object({
    name: z.string(),
    type: z.nativeEnum(QuestionStructureType),
    mandatory: z.boolean(),
  })
);

export type StructureQuestionValidator = z.infer<
  typeof SurveyStructureQuestionValidatorSchema
>;

export const SurveyQuestionValidatorSchema = z.object({
  type: z.nativeEnum(QuestionType),
  text: getTranslationValidation(),
  answerLabel: getTranslationValidation(),
  answerSemanticId: z.string(),
  structure: SurveyStructureQuestionValidatorSchema.optional().nullable(),
  id: z.string(),
  status: z.nativeEnum(SurveyStatus),
});

export type QuestionValidator = z.infer<typeof SurveyQuestionValidatorSchema>;

export const CreateSurveyQuestionFormValidatorSchema = z.object({
  type: z.nativeEnum(QuestionType),
  text: getTranslationValidation(),
  answerLabel: getTranslationValidation(),
  answerSemanticId: z.string(),
  structure: SurveyStructureQuestionValidatorSchema.optional().nullable(),
  questionChoices: z.array(
    z.object({ offeredAnswerTranslation: getTranslationValidation() })
  ),
  rangeAnswerMax: z.number().optional().nullable(),
  rangeAnswerMin: z.number().optional().nullable(),
  rangeAnswerMinText: getTranslationValidationOptional(),
  rangeAnswerMaxText: getTranslationValidationOptional(),
});

export type CreateSurveyQuestionFormValidator = z.infer<
  typeof CreateSurveyQuestionFormValidatorSchema
>;

export const CreateSurveyQuestionDataValidatorSchema = z.object({
  type: z.nativeEnum(QuestionType),
  text: getTranslationValidation(),
  answerLabel: getTranslationValidation(),
  answerSemanticId: z.string(),
  structure: SurveyStructureQuestionValidatorSchema.optional().nullable(),
});

export type CreateSurveyQuestionDataValidator = z.infer<
  typeof CreateSurveyQuestionDataValidatorSchema
>;

export const SurveyQuestionChoicesValidatorSchema = z.array(
  z.object({
    order: z.number(),
    offeredAnswerTranslation: getTranslationValidation(),
  })
);

export type QuestionChoicesValidator = z.infer<
  typeof SurveyQuestionChoicesValidatorSchema
>;

export const SurveyQuestionChoicesDataValidatorSchema = z.array(
  z.object({
    order: z.number(),
    offeredAnswerTranslation: z.array(
      z.object({ lang: z.string(), text: z.string() })
    ),
  })
);

export type QuestionChoicesDataValidator = z.infer<
  typeof SurveyQuestionChoicesDataValidatorSchema
>;

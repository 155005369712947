export function getDeviceType() {
  const userAgent = navigator.userAgent;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
  const isDesktop = !isMobile;

  let os = 'Unknown OS';
  if (/android/i.test(userAgent)) {
    os = 'Android';
  } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    os = 'iOS';
  } else if (/windows/i.test(userAgent)) {
    os = 'Windows';
  } else if (/macintosh/i.test(userAgent)) {
    os = 'MacOS';
  }

  return {
    isMobile,
    isDesktop,
    os,
  };
}

import { useFetchFGOVenues } from '@seaters-app/data-access';
import { PaginationProps } from 'antd';
import { useState } from 'react';

export const useFGOVenues = () => {
  const [query, setQuery] = useState({
    maxPageSize: 10,
    itemOffset: 0,
    search: undefined,
  });

  const { maxPageSize, itemOffset, search } = query;

  const { data: venuesList, isFetching: venuesLoading } = useFetchFGOVenues(
    { itemOffset, maxPageSize },
    { query: search }
  );

  const handlePaginationChange = (newPage: number, pageSize: number) => {
    setQuery({
      ...query,
      maxPageSize: pageSize,
      itemOffset: pageSize !== maxPageSize ? 0 : (newPage - 1) * maxPageSize,
    });
  };

  const currentPage = itemOffset ? itemOffset / maxPageSize + 1 : 1;

  const paginationProps: PaginationProps = {
    defaultCurrent: currentPage,
    current: currentPage,
    total: venuesList?.totalSize,
    onChange: handlePaginationChange,
    pageSize: maxPageSize,
  };

  return {
    venues: venuesList,
    venuesLoading,
    query,
    setQuery,
    paginationProps,
  };
};

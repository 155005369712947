import {
  UserListEntity,
  AxiosAPIError,
  UserValidator,
  profileKeys,
  FanEntity,
  FanValidator,
  PasswordValidator,
  PhoneValidator,
  EmailValidator,
  SESSION_STORAGE_VERIFICATION,
  VerifyCodeValidator,
} from '@seaters-app/constants';

import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  updateUser,
  fetchFan,
  updateFan,
  updateFanPassword,
  updateFanPhone,
  updateFanEmail,
  deleteFan,
  verifyPhoneBySms,
} from '../api/users';

export const useUserProfile = (enabled: boolean) =>
  useQuery<FanEntity, Error>(profileKeys.fan(), () => fetchFan(), {
    enabled: !!sessionStorage.getItem(SESSION_STORAGE_VERIFICATION) && enabled,
  });

export const useUpdateProfile = (
  userId: string
): UseMutationResult<
  UserListEntity,
  AxiosAPIError<UserValidator>,
  UserValidator,
  unknown
> => {
  return useMutation(profileKeys.mutation(), (body: UserValidator) =>
    updateUser(userId, body)
  );
};

export const useUpdateFanProfile = (): UseMutationResult<
  FanEntity,
  AxiosAPIError<FanValidator>,
  FanValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    profileKeys.mutation(),
    (body: FanValidator) => updateFan(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(profileKeys.all());
      },
    }
  );
};

export const useUpdateFanPassword = (): UseMutationResult<
  FanEntity,
  AxiosAPIError<PasswordValidator>,
  PasswordValidator,
  unknown
> => {
  return useMutation(profileKeys.mutation(), (body: PasswordValidator) =>
    updateFanPassword(body)
  );
};

export const useUpdateFanPhone = (): UseMutationResult<
  FanEntity,
  AxiosAPIError<PhoneValidator>,
  PhoneValidator,
  unknown
> => {
  return useMutation(profileKeys.mutation(), (body: PhoneValidator) =>
    updateFanPhone(body)
  );
};
export const useValidatePhoneCode = (): UseMutationResult<
  FanEntity,
  AxiosAPIError<VerifyCodeValidator>,
  VerifyCodeValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation((body: VerifyCodeValidator) => verifyPhoneBySms(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(profileKeys.fan());
    },
  });
};

export const useUpdateFanEmail = (): UseMutationResult<
  FanEntity,
  AxiosAPIError<EmailValidator>,
  EmailValidator,
  unknown
> => {
  return useMutation(profileKeys.mutation(), (body: EmailValidator) =>
    updateFanEmail(body)
  );
};

export const useDeleteFanProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteFan(), {
    onSuccess: () => {
      queryClient.invalidateQueries(profileKeys.all());
    },
  });
};

import {
  fetchTickets,
  getSlugFromUrl,
  useFetchLook,
} from '@seaters-app/data-access';
import {
  ListEntity,
  WaitingListEntity,
  waitingListTicketsKeys,
} from '@seaters-app/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

const defaultPageSize = 10;

export const useTickets = () => {
  const detectedSlug = getSlugFromUrl();

  const { data: lookData } = useFetchLook(detectedSlug || '');

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<ListEntity<WaitingListEntity>, Error>({
    enabled: !!lookData?.fanGroupId,
    queryKey: waitingListTicketsKeys.all(),
    queryFn: ({
      pageParam = {
        maxPageSize: defaultPageSize,
        itemOffset: 0,
      },
    }) => fetchTickets(lookData?.fanGroupId || '', pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.items.length < defaultPageSize) {
        return undefined;
      }
      return {
        itemOffset: lastPage.itemOffset + lastPage.maxPageSize,
        maxPageSize: lastPage.maxPageSize,
      };
    },
    keepPreviousData: true,
    // refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const allItems = data?.pages.flatMap((page) => page.items) || [];

  return {
    allItems,
    loadMoreData: fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  };
};

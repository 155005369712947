import Typography from 'antd/es/typography';
import Form, { FormInstance } from 'antd/es/form';
import { FanGroupPersonalizationFormType } from '@seaters-app/constants';
import {
  Footer,
  ColorPicker,
  FanGroupCreationFooter,
  ImageCropper,
} from '@seaters-app/ui-shared';
import { formSteps, useCurrentStep } from '../create/CreateFanGroup';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type FanGroupPersonalizationFormProps = {
  fanGroup: FanGroupPersonalizationFormType;
  onSubmit: (
    values: FanGroupPersonalizationFormType,
    form: FormInstance<any>
  ) => void;
  isSubmitting: boolean;
  isEdit?: boolean;
  setPersonalizationStepValues?: (v: FanGroupPersonalizationFormType) => void;
};

type ImageField =
  | 'profileImageUrl'
  | 'logoImageUrl'
  | 'coverImageUrl'
  | 'backgroundImageUrl';

export function FanGroupPersonalizationForm({
  fanGroup,
  onSubmit,
  isSubmitting,
  isEdit = true,
  setPersonalizationStepValues,
}: FanGroupPersonalizationFormProps) {
  const { next, prev } = useCurrentStep();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const color1 = Form.useWatch('color1', form);
  const profileImageUrl = Form.useWatch('profileImageUrl', {
    form,
    preserve: true,
  });
  const coverImageUrl = Form.useWatch('coverImageUrl', {
    form,
    preserve: true,
  });
  const backgroundImageUrl = Form.useWatch('backgroundImageUrl', {
    form,
    preserve: true,
  });

  const logoImageUrl = Form.useWatch('logoImageUrl', {
    form,
    preserve: true,
  });

  // Image fields change handler
  const handleImageChange = (type: ImageField) => (img: HTMLCanvasElement) => {
    form.setFieldValue(type, img);
  };

  // Image fields removal handler
  const removeImage = (type: ImageField) => () => {
    form.setFieldValue(type, '');
  };

  const handleNextStep = () => {
    if (setPersonalizationStepValues) {
      setPersonalizationStepValues({
        color1,
        profileImageUrl: profileImageUrl,
        logoImageUrl: logoImageUrl,
        coverImageUrl: coverImageUrl,
        backgroundImageUrl: backgroundImageUrl,
      });
    }
    if (next) {
      next();
    }
  };
  const handlePreviousStep = () => {
    if (setPersonalizationStepValues) {
      setPersonalizationStepValues({
        color1,
        profileImageUrl: profileImageUrl,
        logoImageUrl: logoImageUrl,
        coverImageUrl: coverImageUrl,
        backgroundImageUrl: backgroundImageUrl,
      });
    }
    if (prev) {
      prev();
    }
  };

  const handleSubmit = (values: FanGroupPersonalizationFormType) => {
    onSubmit(
      {
        ...values,
        profileImageUrl: profileImageUrl,
        coverImageUrl: coverImageUrl,
        backgroundImageUrl: backgroundImageUrl,
        logoImageUrl: logoImageUrl,
      },
      form
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      initialValues={fanGroup}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      disabled={isSubmitting}
    >
      <Form.Item
        label={<Text strong>{t('admin_fan-groups_profile-image')}</Text>}
        name="profileImageUrl"
      >
        <ImageCropper
          imageOrCanvas={profileImageUrl}
          handleRemoveImage={removeImage('profileImageUrl')}
          listType="picture-circle"
          handleImageChange={handleImageChange('profileImageUrl')}
          handleReset={() => {
            form.setFieldValue('profileImageUrl', '');
          }}
          cropperProps={{
            aspectRatio: 522 / 522,
          }}
          width={552}
          height={552}
          uploadText={''}
          sideContent={
            <>
              {t('img_required_formats_text')}
              <br />
              {t('fgc_graphics_profile-suggestion')}
            </>
          }
          size={'552 x 552'}
        />
      </Form.Item>
      <ColorPicker
        color={
          color1?.hex ??
          (!isEdit ? fanGroup?.color1 ?? '' : `${fanGroup?.color1}`)
        }
      />

      <Form.Item label={t('fgc_graphics_logo')} name="logoImageUrl">
        <ImageCropper
          beforeUpload={(file) => {
            console.log('beforeUpload', { file });
          }}
          imageOrCanvas={logoImageUrl}
          handleRemoveImage={removeImage('logoImageUrl')}
          handleImageChange={handleImageChange('logoImageUrl')}
          handleReset={() => {
            form.setFieldValue('logoImageUrl', '');
          }}
          cropperProps={{
            aspectRatio: 160 / 80,
          }}
          width={160}
          height={80}
          size="160 x 80"
        />
      </Form.Item>
      <Form.Item label={t('fgc_graphics_cover')} name="coverImageUrl">
        <ImageCropper
          imageOrCanvas={coverImageUrl}
          handleRemoveImage={removeImage('coverImageUrl')}
          handleImageChange={handleImageChange('coverImageUrl')}
          handleReset={() => {
            form.setFieldValue('coverImageUrl', '');
          }}
          cropperProps={{
            aspectRatio: 1000 / 240,
          }}
          width={1000}
          height={240}
          size="1000 x 240"
        />
      </Form.Item>

      <Form.Item label={t('fgc_graphics_bg')} name="backgroundImageUrl">
        <ImageCropper
          imageOrCanvas={backgroundImageUrl}
          handleRemoveImage={removeImage(backgroundImageUrl)}
          handleImageChange={handleImageChange('backgroundImageUrl')}
          handleReset={() => {
            form.setFieldValue('backgroundImageUrl', '');
          }}
          cropperProps={{
            aspectRatio: 1980 / 1080,
          }}
          width={1980}
          height={1080}
          size="1980 x 1080"
        />
      </Form.Item>

      {setPersonalizationStepValues ? (
        <FanGroupCreationFooter
          isDisabled={false}
          isLoading={false}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          currentStep={formSteps.indexOf('personalization')}
          formSteps={formSteps}
        ></FanGroupCreationFooter>
      ) : (
        <Footer onCancel={() => form.resetFields()} />
      )}
    </Form>
  );
}

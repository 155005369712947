import { SignUpFormEntity, UserRole, UserStatus } from '@seaters-app/constants';
import { useSearchParams } from 'react-router-dom';

const initialValues: SignUpFormEntity = {
  status: UserStatus.DRAFT,
  roles: [UserRole.FAN],
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termsAndConditions: false,
  allowDirectMarketingFromSeaters: false,
  allowDirectMarketingFromPartners: false,
};

export const useInitialValues = () => {
  const [searchParams] = useSearchParams();
  const accessCode = searchParams.get('accessCode') ?? '';
  const email = searchParams.get('email') ?? '';
  const firstName = searchParams.get('firstName') ?? '';
  const lastName = searchParams.get('lastName') ?? '';

  initialValues.accessCode = accessCode;
  initialValues.email = email;
  initialValues.firstName =
    firstName.charAt(0).toUpperCase() + firstName.slice(1);
  initialValues.lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

  return initialValues;
};

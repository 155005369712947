import { Avatar, ConfigProvider, Space, theme } from 'antd';
import { FanGroupOwnerFanGroupEntity, routes } from '@seaters-app/constants';
import {
  getSingleTranslation,
  useFanGroupStore,
} from '@seaters-app/data-access';

import styles from '../layout.module.css';
import { Button, baseColor900 } from '@seaters-app/ui';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

export const FanGroupsPopup = ({
  setIsOpen,
  fanGroups,
}: {
  setIsOpen: (v: boolean) => void;
  fanGroups?: FanGroupOwnerFanGroupEntity[];
}) => {
  const { token } = theme.useToken();
  const { fanGroup: currentFG, setFanGroup } = useFanGroupStore();
  const navigate = useNavigate();
  const sortedFanGroups = useMemo(() => {
    return fanGroups?.sort((a, b) => {
      if (
        getSingleTranslation(a.name).toLowerCase() <
        getSingleTranslation(b.name).toLowerCase()
      ) {
        return -1;
      }
      if (
        getSingleTranslation(a.name).toLowerCase() >
        getSingleTranslation(b.name).toLowerCase()
      ) {
        return 1;
      }
      return 0;
    });
  }, [fanGroups?.length]);

  const buttonStyle = {
    height: 'fit-content',
    width: '100%',
    textAlign: 'start',
    padding: 4,
  };

  const currentButtonStyle = {
    ...buttonStyle,
    border: `2px solid ${token.colorPrimary}`,
  };

  return (
    <div className={styles.fanGroups}>
      <ConfigProvider
        theme={{
          token: {
            colorTextHeading: token.colorTextBase,
          },
          components: {
            Typography: {
              colorTextDescription: 'red',
              colorTextLabel: 'red',
            },
          },
        }}
      >
        <Space size="small" direction="vertical">
          {sortedFanGroups?.map((fanGroup) => (
            <Button
              type="text"
              size="small"
              shape="default"
              color={baseColor900}
              disabled={fanGroup?.id === currentFG?.id}
              style={
                fanGroup?.id === currentFG?.id
                  ? currentButtonStyle
                  : buttonStyle
              }
              onClick={() => {
                setFanGroup(fanGroup);
                // localStorage.setItem(LOCAL_STORAGE_SLUG, fanGroup.slug);
                navigate(routes.wishLists);
                setIsOpen(false);
              }}
            >
              <Space style={{ width: '100%' }}>
                <Avatar src={fanGroup?.logoImageUrl} size="large" />
                <Space
                  direction="vertical"
                  size={0}
                  className={styles.fanGroupName}
                >
                  {fanGroup.name ? getSingleTranslation(fanGroup.name) : ''}
                  {`(${fanGroup.slug})`}
                </Space>
              </Space>
            </Button>
          ))}
        </Space>
      </ConfigProvider>
    </div>
  );
};

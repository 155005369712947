import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import { Table, Space, Collapse, Tooltip, Skeleton } from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  TeamOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import {
  useFetchHost,
  useFetchWaitingList,
  useFetchWaitingListInvitations,
  useGuestListStore,
  useIsCustomFlow,
  useReserveGuestListStore,
} from '@seaters-app/data-access';
import Column from 'antd/es/table/Column';
import { Button, CollapseButton } from '@seaters-app/ui';

import dayjs from 'dayjs';
import {
  GuestEntity,
  InvitationEntity,
  SemanticNameEnum,
  SESSION_STORAGE_TOTAL_TICKETS,
  WLPositionStatus,
} from '@seaters-app/constants';

import relativeTime from 'dayjs/plugin/relativeTime';
import Search from 'antd/es/input/Search';

import './styles.css';
import { SortOrder } from 'antd/es/table/interface';
import { ImportContactModal } from './ImportContactModal';
import { CreateContactModal } from './CreateContactModal';
import { EditContactModal } from './EditContactModal';
import { useFetchGroupGuests } from './hooks/useFetchGroupGuests';
import { useEditContact } from '../../utils/useEditContact';
import { useGuestInvitation } from '../InvitationList/helpers/guestInvitation';

dayjs.extend(relativeTime);

const { Panel } = Collapse;

export function ContactList({
  waitingListId,
  disabled,
}: {
  waitingListId: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const { data: host } = useFetchHost();

  const isCustom = useIsCustomFlow();

  // form modals
  const [isCreateContactModal, setIsCreateContactModal] = useState(false);

  const [isImportContactModal, setIsImportContactModal] = useState(false);

  const totalTickets = useGuestListStore((state) => state.totalTickets);

  const { data: waitingList } = useFetchWaitingList(waitingListId);

  const maxTickets =
    totalTickets ||
    Number(sessionStorage.getItem(SESSION_STORAGE_TOTAL_TICKETS)) ||
    0;

  const {
    isEditContactModal,
    setIsEditContactModal,
    showEditModal,
    guestToEdit,
    setGuestToEdit,
  } = useEditContact();

  const groupId = waitingList?.groupId ?? '';

  const {
    allItems,
    totalItems,
    loadMoreData,
    hasNextPage,
    handleSort,
    sort,
    isFetching,
    setPaginationParams,
    paginationParams,
  } = useFetchGroupGuests();

  const guests = useGuestListStore((state) => Object.values(state.guests));
  const guestList = guests.map((guest) => guest.guest);

  const totalGuestTickets = guests
    .map((el) => {
      if (el.isToAllocated) return 0;

      return el.tickets;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const nonAllocatedGuestsWithHost = guests.filter((el) => !el.isToAllocated);

  const nonAllocatedGuests = guests.filter(
    (el) =>
      !el.isToAllocated &&
      (totalGuestTickets === totalTickets || el.guest.guestId !== host?.userId)
  );
  const guestInvitation = useGuestInvitation();

  const nonAllocatedGuestsWithHostTickets = nonAllocatedGuestsWithHost
    .map((item) => {
      const invitation = guestInvitation(item.guest.guestId);

      if (
        invitation &&
        ((invitation.status === 'APPROVED' &&
          (!invitation.position ||
            invitation.position.seatsRequestStatus === 'DECLINED')) ||
          invitation.status === 'REJECTED')
      ) {
        return 0;
      }
      return item.tickets;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const nonAllocatedGuestsTickets = nonAllocatedGuests
    .map((item) => {
      const invitation = guestInvitation(item.guest.guestId);

      if (
        invitation &&
        ((invitation.status === 'APPROVED' &&
          (!invitation.position ||
            invitation.position.seatsRequestStatus === 'DECLINED')) ||
          invitation.status === 'REJECTED')
      ) {
        return 0;
      }
      return item.tickets;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const addGuest = useGuestListStore((state) => state.add);

  const addReserveGuest = useReserveGuestListStore((state) => state.add);

  const reserveGuestsKeys = useReserveGuestListStore((state) =>
    Object.keys(state.guests)
  );

  const showCreateModal = () => {
    setIsCreateContactModal(true);
  };

  const guestListIds = guestList.map((item) => item.guestId);

  const reserveGuestListIds = reserveGuestsKeys;

  const chosenGuestsNumber =
    nonAllocatedGuestsWithHost.length - 1 + reserveGuestListIds.length;

  const filteredContacts = allItems.filter(
    (contactItem) =>
      !guestListIds.concat(reserveGuestListIds).includes(contactItem.guestId)
  );

  const contactListTotal =
    totalItems - chosenGuestsNumber < 0 ? 0 : totalItems - chosenGuestsNumber;

  const getSortOrder = (columnName: string): SortOrder | undefined =>
    sort?.name === columnName ? sort.order : undefined;

  if (!waitingList) {
    return null;
  }
  const setTickets = useGuestListStore((state) => state.setTickets);
  const groupGuests = useGuestListStore((state) => Object.values(state.guests));

  const allocatedInvitation = groupGuests.find((guest) => guest.isToAllocated);

  const handleAddGuest = (guest: GuestEntity) => {
    if (
      totalTickets - nonAllocatedGuestsWithHostTickets > 0 ||
      (totalGuestTickets < maxTickets &&
        totalGuestTickets <= waitingList?.maxNumberOfSeatsPerHost)
    ) {
      addGuest(guest, 1);
      if (!!allocatedInvitation?.tickets) {
        setTickets(
          allocatedInvitation?.guest.guestId ?? 'to_allocated_id',
          allocatedInvitation?.tickets - 1
        );
      }
    } else {
      addReserveGuest(guest, 1);
    }
  };

  const PROD = import.meta.env.MODE === 'production';

  const disabledAddNewGuest =
    allocatedInvitation &&
    guestInvitation(allocatedInvitation.guest.guestId)?.position?.status ===
      WLPositionStatus.HAS_SEAT;

  return (
    <>
      <Collapse
        size="large"
        expandIcon={({ isActive }) => {
          if (isCustom) return null;
          else return <CollapseButton isActive={isActive} />;
        }}
        expandIconPosition="end"
        defaultActiveKey={isCustom ? '' : ['1']}
        collapsible={isCustom && PROD ? 'disabled' : 'header'}
        items={[
          {
            key: '1',
            label: `${t(
              'guestlist_contactlist_table_title'
            )} (${contactListTotal})`,
            children: (
              <Space direction="vertical" style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 15,
                  }}
                >
                  <Space>
                    <Button
                      icon={<PlusOutlined rev={undefined} />}
                      onClick={showCreateModal}
                      type="primary"
                    >
                      {t('guestlist_create_guest')}
                    </Button>
                    <Button
                      icon={<UploadOutlined rev={undefined} />}
                      onClick={() => setIsImportContactModal(true)}
                    >
                      {t('guestlist_import_guestlist')}
                    </Button>
                  </Space>
                  <Search
                    id="searchInput"
                    addonBefore={`${t('guests_total')}: ${contactListTotal}`}
                    placeholder={t('search_title') as string}
                    allowClear
                    onSearch={(search) =>
                      setPaginationParams({
                        ...paginationParams,
                        search,
                        page: 0,
                      })
                    }
                    style={{ maxWidth: 400 }}
                    size="middle"
                  />
                </div>

                <InfiniteScroll
                  dataLength={filteredContacts?.length ?? 0}
                  next={() => loadMoreData()}
                  hasMore={!!hasNextPage}
                  loader={<Skeleton />}
                  scrollableTarget="scrollableDiv"
                >
                  <Table
                    dataSource={filteredContacts}
                    pagination={false}
                    scroll={{
                      x: true,
                    }}
                  >
                    {!disabled && (
                      <Column
                        key="actions"
                        render={(_, guest: GuestEntity) => {
                          return (
                            <Button
                              style={{ paddingTop: 6 }}
                              onClick={() => handleAddGuest(guest)}
                              disabled={disabledAddNewGuest}
                              icon={<PlusOutlined rev={undefined} />}
                            />
                          );
                        }}
                      />
                    )}
                    <Column
                      title={t('guestlist_name')}
                      dataIndex="name"
                      key="name"
                      render={(_, guest: GuestEntity) => {
                        const isPublicOfficial =
                          guest.customInfos.find(
                            (customInfo) =>
                              customInfo.semanticName ===
                              SemanticNameEnum.PUBLIC_OFFICIAL
                          )?.informationValue === 'true';
                        return (
                          <span>
                            {guest.firstName} {guest.lastName}{' '}
                            {isPublicOfficial && (
                              <Tooltip title={t('public_official')}>
                                <TeamOutlined rev={undefined} />
                              </Tooltip>
                            )}
                          </span>
                        );
                      }}
                      sorter={true}
                      sortOrder={getSortOrder('lastName')}
                      onHeaderCell={() => {
                        return {
                          onClick: () => {
                            handleSort('lastName');
                          }, // click header
                        };
                      }}
                    />
                    <Column
                      title={t('guestlist_company')}
                      dataIndex="company"
                      key="company"
                      render={(_, guest: GuestEntity) => {
                        return (
                          <span>
                            {guest.customInfos.find(
                              (customInfo) =>
                                customInfo.semanticName ===
                                SemanticNameEnum.COMPANY
                            )?.informationValue || '–'}
                          </span>
                        );
                      }}
                      sorter={true}
                      sortOrder={getSortOrder('company')}
                      onHeaderCell={() => {
                        return {
                          onClick: () => {
                            handleSort('company');
                          }, // click header
                        };
                      }}
                    />
                    <Column
                      title={t('guestlist_last_rsvp')}
                      dataIndex="lastInvitation"
                      key="lastInvitation"
                      render={(_, guest: GuestEntity) => {
                        const date = guest.customInfos.find(
                          (customInfo) =>
                            customInfo.semanticName ===
                            SemanticNameEnum.LAST_RSVP
                        )?.informationValue;

                        return date ? (
                          <span>{dayjs(date).fromNow()}</span>
                        ) : null;
                      }}
                      sorter={true}
                      sortOrder={getSortOrder('lastRsvp')}
                      onHeaderCell={() => {
                        return {
                          onClick: () => {
                            handleSort('lastRsvp');
                          }, // click header
                        };
                      }}
                    />
                    <Column
                      title={t('guestlist_attendance')}
                      dataIndex="attendance"
                      key="attendance"
                      render={(_, guest: GuestEntity) => {
                        return (
                          <span>
                            {`${
                              guest.customInfos.find(
                                (customInfo) =>
                                  customInfo.semanticName ===
                                  SemanticNameEnum.ATTENDANCE
                              )?.informationValue || 0
                            }/${
                              guest.customInfos.find(
                                (customInfo) =>
                                  customInfo.semanticName ===
                                  SemanticNameEnum.RSVPS
                              )?.informationValue || 0
                            }`}
                          </span>
                        );
                      }}
                      sorter={true}
                      sortOrder={getSortOrder('attendance')}
                      onHeaderCell={() => {
                        return {
                          onClick: () => {
                            handleSort('attendance');
                          }, // click header
                        };
                      }}
                    />
                    <Column
                      title={t('guestlist_cost_contact')}
                      dataIndex="contactCost"
                      key="contactCost"
                      render={(_, guest: GuestEntity) => {
                        const cost =
                          guest.customInfos.find(
                            (customInfo) =>
                              customInfo.semanticName ===
                              SemanticNameEnum.CONTACT_COST
                          )?.informationValue || 0;
                        return cost ? (
                          <span>{Number(cost).toFixed(0)} €</span>
                        ) : (
                          '–'
                        );
                      }}
                      sorter={true}
                      sortOrder={getSortOrder('contactCost')}
                      onHeaderCell={() => {
                        return {
                          onClick: () => {
                            handleSort('contactCost');
                          }, // click header
                        };
                      }}
                    />
                    <Column
                      key="actions"
                      align="right"
                      render={(_, guest: GuestEntity) => {
                        return (
                          <Button
                            type="link"
                            icon={<EditOutlined rev={undefined} />}
                            onClick={() => showEditModal(guest)}
                          >
                            {t('edit_contact_button')}
                          </Button>
                        );
                      }}
                    />
                  </Table>
                </InfiniteScroll>
              </Space>
            ),
          },
        ]}
      />
      <CreateContactModal
        groupId={groupId}
        isCreateContactModal={isCreateContactModal}
        setIsCreateContactModal={setIsCreateContactModal}
      />
      {isEditContactModal && (
        <EditContactModal
          groupId={groupId}
          isEditContactModal={isEditContactModal}
          setIsEditContactModal={setIsEditContactModal}
          guestToEdit={guestToEdit}
          setGuestToEdit={setGuestToEdit}
        />
      )}

      <ImportContactModal
        open={isImportContactModal}
        onClose={() => setIsImportContactModal(false)}
        groupId={groupId}
      />
    </>
  );
}

import axios, { AxiosRequestConfig } from 'axios';

import {
  endpoints,
  SEATERS_API_BASE_URL,
  SEATERS_SUPPORT_API_BASE_URL,
  V2Prefix,
} from '@seaters-app/constants';
import { getSessionToken, setSessionData } from '../storage/sessionStorage';

const loginEndpoints = [
  endpoints.login,
  endpoints.login_v2,
  endpoints.refreshToken,
  `${V2Prefix}/${endpoints.authentication}/${endpoints.verifyOauth}`,
];
const isLoggedInEndpoint = (responseURL: string) =>
  loginEndpoints.find((endpoint) => responseURL.includes(endpoint));

const config: AxiosRequestConfig = { baseURL: SEATERS_API_BASE_URL };

export const api = axios.create(config);

export const setAuthToken = () => {
  axios.defaults.headers.common['Authorization'] =
    'SeatersBearer ' + getSessionToken();
};

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = getSessionToken();
    if (token && config.url !== 'v2/authentication/refresh-token/') {
      config.headers['Authorization'] = 'SeatersBearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor

api.interceptors.response.use(
  (response) => {
    if (
      response.status === 200 &&
      isLoggedInEndpoint(response.request.responseURL)
    ) {
      setSessionData(response.data);

      setAuthToken();

      return response.data;
    }

    return response.data;
  },
  (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      isLoggedInEndpoint(originalRequest.url)
    ) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      sessionStorage.clear();
      window.location.reload();

      // return axios
      //   .post('/auth/token', {
      //     refresh_token: refreshToken,
      //   })
      //   .then((res) => {
      //   });
    }
    return Promise.reject(error);
  }
);

import {
  DatePicker as AntdDatePicker,
  DatePickerProps,
  ConfigProvider,
} from 'antd';
import frFR from 'antd/locale/fr_FR';
import enGB from 'antd/locale/en_GB';
import nlNL from 'antd/locale/nl_NL';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const DatePicker: React.FC<DatePickerProps> = ({ ...rest }) => {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const locale = [frFR, enGB, nlNL].find((lng) =>
    lng.locale.includes(lang ?? 'en')
  );

  return (
    <ConfigProvider locale={locale}>
      <AntdDatePicker {...rest} />
    </ConfigProvider>
  );
};

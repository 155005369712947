import React, { useMemo } from 'react';
import AntLayout from 'antd/es/layout';
import { Flex, Popconfirm, Skeleton, notification, theme } from 'antd';
import styles from './footer.module.css';
import { Button } from '@seaters-app/ui';
import ReactDOM from 'react-dom';
import {
  SeatDistributionMode,
  SeatStatus,
  WLPositionStatus,
  WaitingListEntity,
  WaitingListStatusEnum,
  routes,
} from '@seaters-app/constants';
import { StarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getSlugFromUrl,
  useFanCancelWaitingList,
  useFetchLook,
} from '@seaters-app/data-access';
import { StyledText } from '../StyledText';

const { Footer: AntFooter } = AntLayout;

export function RequestTicketsFooter({
  openRequestTickets,
  waitingList,
  isLoading = false,
  editGuestList,
  setStartRefetching,
}: {
  openRequestTickets: () => void;
  waitingList?: WaitingListEntity;
  isLoading?: boolean;
  editGuestList?: boolean;
  setStartRefetching: (v: 'cancel') => void;
}) {
  const { token } = theme.useToken();
  const { t, ready, i18n } = useTranslation();
  const { language: lang } = i18n;
  const navigate = useNavigate();

  const slug = getSlugFromUrl();
  const { data: lookData } = useFetchLook(slug ?? '');

  const waitingListId = waitingList?.waitingListId ?? '';

  const { mutate: fanCancelWaitingList } =
    useFanCancelWaitingList(waitingListId);

  const rejectWaitingList = () => {
    fanCancelWaitingList(
      { waitingListId },
      {
        onSuccess: () => {
          setStartRefetching('cancel');
          notification.success({
            message: t('booking_cancellation_notification_success'),
          });
          // setIsActive(false);
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('booking_cancellation_notification_error'),
          });
        },
      }
    );
  };

  const title = useMemo(() => {
    if (waitingList)
      return editGuestList
        ? t('view_guestlist')
        : !waitingList.position
        ? t('queue_header_join-btn').toUpperCase()
        : waitingList?.position?.status === WLPositionStatus.WAITING_SEAT
        ? t('tickets_requested_button_text')
        : waitingList?.seat?.status === SeatStatus.ACCEPTED ||
          waitingList?.seat?.status === SeatStatus.RSVP_ACCEPTED
        ? t('download_tickets_button_text')
        : t('accept_tickets_button_text');
  }, [waitingList, ready]);

  const isAbleToLeaveWL =
    waitingList?.waitingListStatus === WaitingListStatusEnum.OPEN &&
    waitingList?.seatDistributionMode === SeatDistributionMode.VOUCHER &&
    waitingList?.freeWaitingList &&
    lookData?.properties?.directSaleModeTicketsLimit;

  return ReactDOM.createPortal(
    <AntFooter
      className={styles.requestTicketsFooter}
      style={{
        borderTop: `1px solid ${token.colorBorderSecondary}`,
      }}
    >
      {isLoading ? (
        <Skeleton.Button size="large" block />
      ) : (
        <Flex vertical gap={12}>
          <Button
            className={styles.requestTicketsButton}
            size="large"
            type="primary"
            onClick={
              editGuestList
                ? () => navigate(routes.guestlist)
                : openRequestTickets
            }
            icon={!waitingList?.position && <StarOutlined rev={undefined} />}
          >
            {title}
          </Button>
          {isAbleToLeaveWL && (
            <Popconfirm
              title={t('wishlist_leave_wishlist_modal_title')}
              onConfirm={rejectWaitingList}
              description={
                <StyledText
                  text={t('wishlist_leave_wishlist_modal_message', {
                    wishlistname:
                      waitingList?.translatedEventName ??
                      waitingList?.experienceName[lang] ??
                      waitingList?.eventName[lang],
                  })}
                />
              }
              cancelText={t('general_no')}
            >
              <Button type="link" color={token.colorError}>
                {t('leave_wl_with_tickets_button_text')}
              </Button>
            </Popconfirm>
          )}
        </Flex>
      )}
    </AntFooter>,
    document.getElementById('portalForFooter') as HTMLElement
  );
}

export default RequestTicketsFooter;

import {
  endpoints,
  FGOVenueFormValidator,
  ListEntity,
  PaginationQuery,
  V2Prefix,
  VenueCategoryEntity,
  VenueConfigBody,
  VenueConfigEntity,
  VenueEntity,
  VenuesQuery,
  VenueValidator,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchVenues = (
  params: VenuesQuery
): Promise<ListEntity<VenueEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.seatersAdmin}/${endpoints.venues}/${endpoints.search}`,
    {
      params,
    }
  );

export const fetchFGOVenues = (
  params: VenuesQuery,
  body
): Promise<ListEntity<VenueEntity>> =>
  api.put(
    `${endpoints.fanGroupOwner}/${endpoints.venuesSearch}?itemOffset=${params.itemOffset}&maxPageSize=${params.maxPageSize}`,
    body
  );

export const fetchVenue = (id: string): Promise<VenueEntity> =>
  api.get(`${endpoints.seatersAdmin}/${endpoints.venues}/${id}`);

export const createAdminVenue = (body: VenueValidator): Promise<VenueEntity> =>
  api.post(`${endpoints.seatersAdmin}/${endpoints.venues}`, body);

export const createFGOVenue = (
  body: FGOVenueFormValidator
): Promise<VenueEntity> =>
  api.post(`${endpoints.fanGroupOwner}/${endpoints.venues}`, body);

export const updateVenue = (
  id: string,
  body: VenueValidator
): Promise<VenueEntity> => {
  return api.put(`${endpoints.seatersAdmin}/${endpoints.venues}/${id}`, body);
};

export const updateVenueImage = (
  id: string,
  body: { fileId: string }
): Promise<VenueEntity> => {
  return api.put(
    `${endpoints.seatersAdmin}/${endpoints.venues}/${id}/${endpoints.venueImage}?fileId=${body.fileId}`
  );
};

export const deleteVenue = (venueId: string) =>
  api.delete(`${endpoints.seatersAdmin}/${endpoints.venues}/${venueId}`);

export const deleteVenueConfig = (venueConfigId: string) =>
  api.delete(`${endpoints.venueConfigurations}/${venueConfigId}`);

export const fetchVenueConfigs = (
  venueId: string,
  params: PaginationQuery
): Promise<ListEntity<VenueConfigEntity>> =>
  api.get(
    `${endpoints.seatersAdmin}/${endpoints.venues}/${venueId}/${endpoints.configs}`,
    { params }
  );

export const fetchVenueCategories = (
  venueId: string,
  params: PaginationQuery
): Promise<ListEntity<VenueCategoryEntity>> =>
  api.get(
    `${endpoints.seatersAdmin}/${endpoints.venues}/${venueId}/${endpoints.seatCategories}`,
    { params }
  );

export const createAdminVenueConfig = (
  venueId: string,
  body: VenueConfigBody
): Promise<VenueConfigEntity> =>
  api.post(
    `${endpoints.seatersAdmin}/${endpoints.venues}/${venueId}/${endpoints.configs}`,
    body
  );

export const fetchVenueConfig = (
  venueConfigId: string
): Promise<VenueConfigEntity> =>
  api.get(`${endpoints.venueConfigurations}/${venueConfigId}`);

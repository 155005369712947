import React from 'react';
import { Skeleton, Space, theme } from 'antd';

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: React.CSSProperties;
  color?: string;
}

export const Spinner = ({ style, color, ...props }: SpinnerProps) => {
  const { token } = theme.useToken();

  return (
    <Space
      direction="horizontal"
      style={{
        gap: '8px',
        marginTop: '10px auto 0',
        padding: '20px',
        width: '100%',
        justifyContent: 'center',
      }}
      {...props}
    >
      {[...Array(3)].map(() => (
        <Skeleton.Avatar
          active
          style={{
            width: 12,
            height: 12,
            backgroundColor: color || token.colorPrimary,
            opacity: 0.6,
          }}
        />
      ))}
    </Space>
  );
};

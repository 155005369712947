import {
  useAddFanCustomInfo,
  useFetchFanGroupCustomInfo,
  useFetchLook,
  useUserCustomInfo,
} from '@seaters-app/data-access';
import { Spin, Form, Row, Col, Select, Input, notification } from 'antd';
import { Footer } from '@seaters-app/ui-shared';
import {
  CustomProfileScopeEnum,
  FanCustomInfoValidationSchema,
  LOCAL_STORAGE_SLUG,
} from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;

type FanCustomInfoItem = {
  informationValue: string;
  semanticName: string;
};

export interface FanCustomInfoForm {
  fanCustomInfos: FanCustomInfoItem[];
}

export function FanCustomInfo({ fanId }: { fanId: string }) {
  const { t } = useTranslation();

  const slug = localStorage.getItem(LOCAL_STORAGE_SLUG);

  const { data: lookData } = useFetchLook(slug ?? '');

  const fanGroupId = lookData?.fanGroupId ?? '';

  const { data: customInfo, isLoading } = useUserCustomInfo(fanGroupId, fanId);

  const { data: fgCustomInfo } = useFetchFanGroupCustomInfo(fanGroupId, {
    size: 100,
    page: 0,
  });

  const fgCustomInfosOptions = fgCustomInfo?.content.map((customInfo) => ({
    label: customInfo.semanticName,
    value: customInfo.id,
    scope: customInfo.scope,
  }));

  const { mutate: addFanCustomInfo } = useAddFanCustomInfo(fanGroupId, fanId);

  const initialValues = customInfo
    ? {
        fanCustomInfos: customInfo.content.map((customInfo) => ({
          informationValue: customInfo.informationValue,
          semanticName: customInfo.fanGroupCustomInformation.semanticName,
        })),
      }
    : {
        fanCustomInfos: [],
      };

  const methods = useForm<FanCustomInfoForm>({
    mode: 'onBlur',
    resolver: zodResolver(FanCustomInfoValidationSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const { handleSubmit, control, setValue, getValues, reset } = methods;

  const {
    fields,
    remove: removeField,
    append: appendField,
  } = useFieldArray({
    control,
    name: 'fanCustomInfos',
  });

  const onSubmit = (values: FanCustomInfoForm) => {
    const { fanCustomInfos } = values;
    const fanCustomInfoToPost: Array<
      FanCustomInfoItem & {
        fgCustomInformationId?: string;
      }
    > = fanCustomInfos
      .filter((info) => {
        const oldItem = customInfo?.content?.find(
          (customInfo) =>
            customInfo.fanGroupCustomInformation.semanticName ===
            info.semanticName
        );

        if (!oldItem || oldItem.informationValue !== info.informationValue)
          return info;
      })
      .map((info) => {
        const customInfoId = fgCustomInfo?.content.find(
          (item) => item.semanticName === info.semanticName
        )?.id;

        return { ...info, fgCustomInformationId: customInfoId };
      });

    if (!fanCustomInfoToPost) return;

    fanCustomInfoToPost.forEach((customInfo) => {
      addFanCustomInfo(
        {
          body: {
            fgCustomInformationId: customInfo.fgCustomInformationId ?? '',
            informationValue: customInfo.informationValue,
            semanticName: customInfo.semanticName,
          },
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_custom_info_linked'),
            });
          },
          onError: (error) => {
            notification.error({
              message: t('notification_error_custom_info_linked'),
              description: error.message,
            });
          },
        }
      );
    });
  };

  return (
    <div style={{ height: '100vh' }}>
      {!isLoading ? (
        <Form
          name="basic"
          layout="vertical"
          size="middle"
          autoComplete="off"
          initialValues={initialValues}
          onFinish={handleSubmit(onSubmit)}
        >
          <>
            {fields.map((item, i) => {
              return (
                <Row
                  style={{ verticalAlign: 'baseline' }}
                  gutter={24}
                  key={item.id}
                >
                  <Col md={8}>
                    <Form.Item
                      label={t('semantic_name_filed_label')}
                      name={`fanCustomInfos.${i}.semanticName`}
                    >
                      <Controller
                        control={control}
                        name={`fanCustomInfos.${i}.semanticName`}
                        render={({ field }) => (
                          <Select
                            {...field}
                            onSelect={(_, option) => {
                              setValue(
                                `fanCustomInfos.${i}.semanticName`,
                                option.label
                              );
                            }}
                            disabled={
                              !!customInfo?.content.find(
                                (info) =>
                                  info.fanGroupCustomInformation
                                    .semanticName === field.value
                              )
                            }
                            options={fgCustomInfosOptions?.map((option) => ({
                              label: option.label,
                              disabled:
                                !!getValues().fanCustomInfos.find(
                                  (info) => info.semanticName === option.label
                                ) ||
                                option.scope !==
                                  CustomProfileScopeEnum.READ_ONLY,
                              value: option.value,
                              key: option.value,
                            }))}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item
                      label={t('matrix_header_information')}
                      name={`fanCustomInfos.${i}.informationValue`}
                    >
                      <Controller
                        control={control}
                        name={`fanCustomInfos.${i}.informationValue`}
                        render={({ field }) => {
                          const oldItem = customInfo?.content.find(
                            (info) =>
                              info.fanGroupCustomInformation.semanticName ===
                              item.semanticName
                          );

                          return (
                            <Input
                              {...field}
                              disabled={
                                oldItem &&
                                oldItem.fanGroupCustomInformation.scope !==
                                  CustomProfileScopeEnum.READ_ONLY
                              }
                            />
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!customInfo?.content.find(
                    (info) =>
                      info.fanGroupCustomInformation.semanticName ===
                      item.semanticName
                  ) && (
                    <Col span={4}>
                      <Form.Item label="hidden">
                        <MinusCircleOutlined
                          rev={undefined}
                          onClick={() => {
                            removeField(i);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  appendField({
                    informationValue: '',
                    semanticName: '',
                  })
                }
                block
                icon={<PlusOutlined rev={undefined} />}
              >
                {t('add_custom_info_btn_text')}
              </Button>
            </Form.Item>
          </>
          <Footer onCancel={reset} />
        </Form>
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Spin />
        </div>
      )}
    </div>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, notification } from 'antd';
import { Button } from '@seaters-app/ui';
import { AttendeeInfoStep, useAttendeeInfoStore } from '@seaters-app/ui-shared';
import {
  useReexportVoucher,
  useUpdateFanAttendeesInfo,
} from '@seaters-app/data-access';
import {
  AttendeesInfoFormValidator,
  WaitingListAcceptFormat,
  WaitingListEntity,
} from '@seaters-app/constants';

interface AdjustAttendeeListProps {
  waitingList: WaitingListEntity;
}

const AdjustAttendeeList: React.FC<AdjustAttendeeListProps> = ({
  waitingList,
}) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { attendees } = waitingList.position.attendeesInfo;

  const { mutate: updateFanAttendeesInfo } = useUpdateFanAttendeesInfo(
    waitingList.waitingListId
  );

  const { mutateAsync: reExportVoucher, isLoading: isLoadingReexport } =
    useReexportVoucher(waitingList.waitingListId);

  const { setAttendees, setCurrentAttendee } = useAttendeeInfoStore();

  const handleOpenModal = () => {
    setModalOpen(true);

    if (attendees.length) {
      setAttendees(attendees);
      setCurrentAttendee(attendees.length);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleAttendeeForm = (attendeeValues: AttendeesInfoFormValidator[]) => {
    updateFanAttendeesInfo(
      { body: { attendees: attendeeValues } },
      {
        onSuccess: () => {
          handleCloseModal();
          notification.success({
            message: t('attendee_info_update_success'),
          });
          reExportVoucher({
            format: WaitingListAcceptFormat.MULTIPAGE_PDF_FILE,
          });
        },
      }
    );
  };

  return (
    <div>
      <Button type="link" onClick={handleOpenModal}>
        {t('adjust_attendee_list_button')}
      </Button>
      <Modal
        open={modalOpen}
        style={{ maxWidth: 1284 }}
        title={t('checkout_title')}
        closeIcon={null}
        footer={false}
        width={'100%'}
        maskClosable
        onCancel={handleCloseModal}
      >
        <AttendeeInfoStep
          waitingList={waitingList}
          handleAttendeeForm={handleAttendeeForm}
          submitForm={t('button_text_submit')}
        />
      </Modal>
    </div>
  );
};

export default AdjustAttendeeList;

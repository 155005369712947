import React from 'react';
import { Flex, Grid, Skeleton, theme } from 'antd';
import { useParams } from 'react-router-dom';
import { Container, baseColor400 } from '@seaters-app/ui';
const { useBreakpoint } = Grid;

function Banner({
  image,
  isLoading = false,
}: {
  image: string;
  isLoading?: boolean;
}) {
  const { xs } = useBreakpoint();
  const { token } = theme.useToken();
  const { waitingListId = '' } = useParams();
  return (
    <div
      style={{
        width: '100%',
        padding: !xs ? '24px 0 0' : '0',
        backgroundColor: waitingListId ? baseColor400 : token.colorPrimary,
      }}
    >
      <Container
        paddingLeft={xs ? 0 : undefined}
        paddingRight={xs ? 0 : undefined}
      >
        {isLoading ? (
          <Skeleton.Button block active style={{ height: '300px' }} />
        ) : (
          <Flex>
            <img
              src={image}
              alt="banner"
              style={{
                borderTopLeftRadius: !xs ? '12px' : '',
                borderTopRightRadius: !xs ? '12px' : '',
                width: '100%',
              }}
            />
          </Flex>
        )}
      </Container>
    </div>
  );
}

export default Banner;

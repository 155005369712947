import { ReactElement } from 'react';
import AntLayout from 'antd/es/layout';
import { LeftOutlined } from '@ant-design/icons';
import { baseColor400 } from '@seaters-app/ui';
import { Flex, Space } from 'antd';
import { Button } from '@seaters-app/ui';
import styles from '../steps/steps.module.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const { Footer: AntFooter } = AntLayout;

interface FooterProps {
  children?: ReactElement;
  handlePrevStep?: () => void;
  saveAsDraft?: () => void;
  isDisabled?: boolean;
  isEdit?: boolean;
  isLoading?: boolean;
  submitButtonText?: string;
  cancelButton?: ReactElement;
}

function Footer({
  handlePrevStep,
  children,
  saveAsDraft,
  isLoading,
  submitButtonText = 'next_step_button_text',
  isEdit,
  cancelButton,
}: FooterProps) {
  const { t } = useTranslation();
  const { wishListId = '' } = useParams();
  return (
    <AntFooter
      style={{
        borderTop: `1px solid ${baseColor400}`,
      }}
      className={styles.footer}
    >
      <Flex justify="space-between" style={{ width: '100%' }}>
        <Space>
          {!isEdit ? (
            <Button
              size="middle"
              type="default"
              icon={<LeftOutlined rev={undefined} />}
              onClick={handlePrevStep}
              disabled={isLoading}
            >
              {t('prev_step_button_text')}
            </Button>
          ) : (
            cancelButton
          )}
          {children}
        </Space>
        <Space>
          {!isEdit && saveAsDraft && (
            <Button
              size="middle"
              type="default"
              onClick={saveAsDraft}
              disabled={isLoading}
            >
              {t('save_and_finish_later_button_text')}
            </Button>
          )}
          {wishListId && (
            <Button
              size="middle"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {t(isEdit ? 'general_save-btn' : submitButtonText)}
            </Button>
          )}
        </Space>
      </Flex>
    </AntFooter>
  );
}

export default Footer;

import {
  endpoints,
  ListEntity,
  PaginationQuery,
  PaymentSystem,
  seatersAdminPrefix,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchPaymentSystems = (
  params: PaginationQuery
): Promise<ListEntity<PaymentSystem>> =>
  api.get(`${seatersAdminPrefix}/${endpoints.paymentSystems}`, { params });

export const createPaymentSystem = (paymentSystem: PaymentSystem) =>
  api.post(`${seatersAdminPrefix}/${endpoints.paymentSystems}`, paymentSystem);

export const updatePaymentSystem = (
  paymentSystemId: string,
  paymentSystem: PaymentSystem
) =>
  api.put(
    `${seatersAdminPrefix}/${endpoints.paymentSystems}/${paymentSystemId}`,
    paymentSystem
  );

export const deletePaymentSystem = (systemId: string) =>
  api.delete(`${seatersAdminPrefix}/${endpoints.paymentSystems}/${systemId}`);

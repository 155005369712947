import { LookEntity } from '@seaters-app/constants';
import {
  alertColor,
  baseColor200,
  baseColor300,
  baseColor400,
  baseColor700,
  baseColor900,
  colorBgBaseDark,
  colorBgBaseLight,
  colorTextBaseDark,
  colorTextBaseLight,
  errorColor400,
  primaryColor,
  primaryColor200,
  primaryColor900,
  tertiaryColor700,
} from './colors';
import { getSlugFromUrl, queryClient } from '@seaters-app/data-access';

const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
  'users',
  'look',
]);

const primaryCustomColor = fanGroupData?.color;
const slug = getSlugFromUrl();

const fontFamily = 'Inter, sans-serif;';

const commonTheme = {
  fontFamily: fontFamily,
};

export const lightTheme = {
  token: {
    ...commonTheme,
    colorPrimary:
      primaryCustomColor && slug ? primaryCustomColor : primaryColor,
    colorBgBase: colorBgBaseLight,
    colorBgLayout: colorBgBaseLight,
    colorTextBase: colorTextBaseLight,
    colorError: errorColor400,
    colorWarning: alertColor,
    colorTextSecondary: baseColor700,
    colorBgTextActive: primaryColor200,
    colorLink: colorTextBaseLight,
    colorLinkHover: colorTextBaseLight,
    colorLinkActive: colorTextBaseLight,
    colorBorder: baseColor400,
    colorBorderSecondary: baseColor300,
    colorPrimaryBg: baseColor200,
    tertiaryColor700: tertiaryColor700,
  },
};

export const darkTheme = {
  token: {
    ...commonTheme,
    colorPrimary: primaryColor,
    colorBgBase: colorBgBaseDark,
    colorBgLayout: colorBgBaseDark,
    colorTextBase: colorTextBaseDark,
    colorError: errorColor400,
    colorWarning: alertColor,
    colorTextSecondary: primaryColor200,
    colorBgTextActive: primaryColor900,
    colorLink: colorTextBaseDark,
    colorLinkHover: colorTextBaseDark,
    colorLinkActive: colorTextBaseDark,
    colorBorder: baseColor700,
    colorBorderSecondary: baseColor700,
    colorPrimaryBg: baseColor900,
    tertiaryColor700: tertiaryColor700,
  },
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import { debounce } from 'lodash';
import { useWLOptions } from '../../surveys/[id]/hooks/useWLOptions';
import { useFGAdminOptions } from '../../surveys/[id]/hooks/useFGAdminOptions';
import { getSingleTranslation } from '@seaters-app/data-access';

interface BulkPositionsDistributionFormProps {}

const BulkPositionsDistributionForm: React.FC<
  BulkPositionsDistributionFormProps
> = () => {
  const [selectedFG, setSelectedFG] = useState('');

  const {
    waitingListsOptions,
    isLoading: isWishlistLoading,
    loadMoreData: loadMoreWLs,
  } = useWLOptions(selectedFG ?? '');

  const [openWishlistsDropdown, setOpenWishlistsDropdown] = useState(
    !!selectedFG
  );
  const [searchText, setSearchText] = useState('');

  const {
    allItems,
    loadMoreData: loadMoreFGs,
    fanGroupsFetching,
  } = useFGAdminOptions(
    {
      page: 0,
      size: 20,
      query: searchText,
    },
    false
  );
  const fanGroupsOptions = allItems?.map((item) => {
    return {
      value: item?.id,
      label: `${getSingleTranslation(item?.name)} (slug: ${item?.slug})`,
    };
  });
  const handleSearchFGs = debounce((searchValue) => {
    setSearchText(searchValue);
  }, 500);

  const handleScrollFGs = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      e.target.scrollTop + e.target.offsetHeight >
      e.target.scrollHeight - 5
    ) {
      loadMoreFGs();
    }
  };

  const handleScrollWLs = (e: any) => {
    if (
      e.target.scrollTop + e.target.offsetHeight >
      e.target.scrollHeight - 5
    ) {
      loadMoreWLs();
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedFG && !openWishlistsDropdown) {
      setOpenWishlistsDropdown(true);
    }
  }, [selectedFG]);
  return (
    <>
      <Form.Item label={t('admin_fan-groups')} name="fanGroupSlug">
        <Select
          optionFilterProp="label"
          placeholder={t('search_by_placeholder_text', {
            by: t('admin_fan-groups_slug'),
          })}
          allowClear
          style={{ width: '100%' }}
          onClear={loadMoreFGs}
          loading={fanGroupsFetching}
          onSelect={(value) => setSelectedFG(value)}
          notFoundContent={!fanGroupsOptions.length && t('not_found')}
          showSearch
          onSearch={handleSearchFGs}
          onPopupScroll={handleScrollFGs}
          options={fanGroupsOptions}
        />
      </Form.Item>
      <Form.Item
        label={t('wl_set_seat-distribution-mode')}
        name="distributionType"
      >
        <Select
          size="middle"
          style={{ width: '100%' }}
          options={[
            {
              label: t('distribution_type_with_seats'),
              value: 'WITH_SEATS',
            },
            {
              label: t('distribution_type_without_seats'),
              value: 'WITHOUT_SEATS',
            },
          ]}
        />
      </Form.Item>

      <Form.Item label={t('mwl_tab-wl')} name="waitinglistId">
        <Select
          open={openWishlistsDropdown}
          onDropdownVisibleChange={(visible) =>
            setOpenWishlistsDropdown(visible)
          }
          loading={isWishlistLoading && !!selectedFG}
          showSearch
          optionFilterProp="label"
          options={waitingListsOptions}
          onPopupScroll={handleScrollWLs}
        />
      </Form.Item>
    </>
  );
};

export default BulkPositionsDistributionForm;

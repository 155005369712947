import dayjs from 'dayjs';
import { Space, Tooltip, Typography } from 'antd';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  BillingMode,
  FanGroupOwnerWaitingListEntity,
  InvitationApprovalMode,
  InvitationMode,
  SeatDistributionMode,
  WaitingListStatusEnum,
} from '@seaters-app/constants';
import {
  baseColor200,
  baseColor600,
  baseColor900,
  errorColor,
  tertiaryColor700,
} from '@seaters-app/ui';
import imagePlaceholder from '../../../../public/imagePlaceholder.svg';

import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { getSingleTranslation } from '@seaters-app/data-access';
import { usePositionDistributionOptions } from '../create/helpers';

const { Text } = Typography;

function WishListCard({
  wishList,
}: {
  wishList: FanGroupOwnerWaitingListEntity;
}) {
  const { t } = useTranslation();
  const positionDistributionOptions = usePositionDistributionOptions();

  const WLImage =
    wishList.waitingListImageUrl ||
    wishList.event.eventImageUrl ||
    wishList.event.venueImageUrl;

  const showInfo =
    wishList?.status &&
    ![(WaitingListStatusEnum.DRAFT, WaitingListStatusEnum.SETUP)].includes(
      wishList?.status
    );

  const renderEventStatus = (status?: WaitingListStatusEnum) => {
    switch (status) {
      case WaitingListStatusEnum.OPEN:
        return (
          <div className={styles.status} style={{ color: tertiaryColor700 }}>
            {status}
          </div>
        );

      case WaitingListStatusEnum.CLOSED:
        return (
          <div className={styles.status} style={{ color: errorColor }}>
            {status}
          </div>
        );

      default:
        return (
          <div className={styles.status} style={{ color: baseColor600 }}>
            {status}
          </div>
        );
    }
  };

  return (
    <Link
      id={wishList?.waitingListId}
      to={wishList?.waitingListId || ''}
      className={styles.card}
    >
      <div
        className={styles.imageWrap}
        style={{ backgroundColor: baseColor200 }}
      >
        {WLImage ? (
          <img
            className={styles.image}
            alt={wishList?.event?.eventName}
            src={WLImage}
          />
        ) : (
          <img
            className={styles.imagePlaceholder}
            alt={wishList?.event?.eventName}
            src={imagePlaceholder}
          />
        )}
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.contentRow}>
          <div className={styles.contentColLeft}>
            <Text strong color={baseColor900}>
              {wishList.experienceName?.length
                ? getSingleTranslation(wishList.experienceName)
                : wishList.event.eventName}
            </Text>{' '}
            {wishList.event.attendeeRequiredFields ? (
              <Tooltip title={t('wl_event_requiredinfo-help')}>
                <InfoCircleOutlined rev={undefined} />
              </Tooltip>
            ) : null}
          </div>
          {showInfo && (
            <div className={styles.contentCol}>
              {wishList?.status === WaitingListStatusEnum.OPEN && (
                <Space className={styles.contentColSection}>
                  <p className={styles.numberText}>
                    {wishList?.confirmedPositionWaitingForSeats}
                  </p>
                  <p
                    className={styles.titleText}
                    style={{ color: baseColor600 }}
                  >
                    {t('general_confirm_text')} <br /> {t('wl_without_seats')}
                  </p>
                </Space>
              )}
              {wishList?.status === WaitingListStatusEnum.OPEN && (
                <Space className={styles.contentColSection}>
                  <p className={styles.numberText}>
                    {wishList?.actualDemandedSeats - wishList?.newDemandedSeats}
                  </p>
                  <p
                    className={styles.titleText}
                    style={{ color: baseColor600 }}
                  >
                    {t('wl_status-declined')}
                  </p>
                </Space>
              )}
              {wishList?.status === WaitingListStatusEnum.OPEN && (
                <Space className={styles.contentColSection}>
                  <p className={styles.numberText}>
                    {wishList?.invitationParameters &&
                    wishList?.invitationParameters.invitationMode ===
                      InvitationMode.ENABLED &&
                    wishList?.invitationParameters.approvalMode ===
                      InvitationApprovalMode.DEFAULT
                      ? wishList?.invitationSeats +
                        wishList?.invitationNonAllocatedSeats
                      : wishList?.newDemandedSeats}
                  </p>
                  <p
                    className={styles.titleText}
                    style={{ color: baseColor600 }}
                  >
                    <Trans i18nKey="new_demands_label" />
                  </p>
                </Space>
              )}

              <Space className={styles.contentColSection}>
                <p className={styles.numberText}>
                  {wishList?.seatDistributionMode ===
                  SeatDistributionMode.TICKET
                    ? `${wishList?.availableTicketsInTicketPools}/${wishList?.totalTicketsInTicketPools}`
                    : wishList?.seatDistributionMode ===
                        SeatDistributionMode.VOUCHER &&
                      `${
                        wishList?.totalTickets - wishList?.distributedTickets
                      }/${wishList?.totalTickets}`}
                </p>
                <p className={styles.titleText} style={{ color: baseColor600 }}>
                  {t('wl_availableseats-label')}
                </p>
              </Space>
            </div>
          )}
        </div>
        <div>
          {wishList?.billingMode === BillingMode.FREE
            ? t('admin_waiting-list_billing-modes_free')
            : `${wishList?.event.venueCurrency} ${wishList?.price}`}
          {/* TODO check  */}
        </div>
        <Space>
          <Text type="secondary">{wishList.name}</Text>
        </Space>
        <Space size="small">
          <Text type="secondary">
            {
              positionDistributionOptions.find(
                (el) => el.value === wishList.positionsDistributionMode
              )?.label
            }
          </Text>
        </Space>
        <Space size="small" style={{ color: baseColor600 }}>
          <CalendarOutlined rev={undefined} />
          {dayjs(wishList?.event.startDate).format('ddd D MMMM YYYY h:mm A')}
        </Space>
        <div className={styles.contentRow}>
          <Space
            size="small"
            style={{ color: baseColor600 }}
            className={styles.contentColLeft}
          >
            <EnvironmentOutlined rev={undefined} />
            <Text type="secondary">{wishList.event.venueName}</Text>
          </Space>

          <Space
            size="small"
            style={{ color: baseColor600 }}
            className={styles.contentCol}
          >
            <p>{renderEventStatus(wishList?.status)}</p>
          </Space>
        </div>
      </div>
    </Link>
  );
}

export default WishListCard;

import React, { useState } from 'react';
import { notification, Skeleton, Spin } from 'antd';
import {
  OperationParametersType,
  FormattedValuesWithTranslations,
  FanGroupGeneralValidatorSchema,
  FanGroupGeneralFormType,
  FanGroupOwnerFanGroupEntity,
} from '@seaters-app/constants';
import {
  useFanGroupStore,
  useUpdateFGOFanGroup,
} from '@seaters-app/data-access';
import { getTranslations } from '../../../utils/helpers/getTranslations';
import { prepareValues } from '../helpers/prepareValues';
import { getGeneralInitialValuesFGConfig } from '../helpers/getInitialValues';
import { FanGroupGeneralForm } from '../fanGroupForm/FanGroupGeneralForm';
import { useTranslation } from 'react-i18next';
import FGRegistrationConfig from './FGRegistrationConfig';

export function FGConfigurationGeneral() {
  const [spinning, setSpinning] = useState(false);
  const { t } = useTranslation();

  const { fanGroup } = useFanGroupStore();

  const { mutate: updateFanGroup, isLoading: isFanGroupUpdating } =
    useUpdateFGOFanGroup(fanGroup?.id ?? '');

  if (fanGroup === undefined) return null;

  const valuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<FanGroupOwnerFanGroupEntity>(
      ['welcomeText', 'description', 'name', 'shortName'],
      fanGroup
    );

  const operationParametersValuesWithTranslations: FormattedValuesWithTranslations | null =
    getTranslations<OperationParametersType>(
      [
        'optInPartnersTexts',
        'optInPlatformTexts',
        'optInTermsTexts',
        'optInRulesTexts',
        'termsAndConditions',
        'privacyPolicy',
        'rulesLinks',
        'legalNotice',
        'faqLinks',
        'helpLinks',
      ],
      {
        rulesLinks: fanGroup.rulesLinks,
        legalNotice: fanGroup.legalNotice,
        faqLinks: fanGroup.faqLinks,
        helpLinks: fanGroup.helpLinks,
        optInRulesTexts: fanGroup.optInRulesTexts,
        termsAndConditions: fanGroup.termsAndConditions,
        privacyPolicy: fanGroup.privacyPolicy,
        optInPlatformTexts: fanGroup.optInPlatformTexts,
        optInPartnersTexts: fanGroup.optInPartnersTexts,
        optInPlatformHidden: fanGroup.optInPlatformHidden,
        optInPartnersHidden: fanGroup.optInPartnersHidden,
        optInTermsTexts: fanGroup.optInTermsTexts,
        visibility: fanGroup.visibility,
        accessMode: fanGroup.accessMode,
      }
    );

  const onSubmit = (values: FanGroupGeneralFormType) => {
    setSpinning(true);
    const preparedValues = prepareValues(values);

    const parsed = FanGroupGeneralValidatorSchema.safeParse(preparedValues);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    const optInPartnersTexts = parsed.data.optInPartnersTexts;
    const optInPlatformTexts = parsed.data.optInPlatformTexts;
    const optInPartnersHidden = parsed.data.optInPartnersHidden;
    const optInPlatformHidden = parsed.data.optInPlatformHidden;
    const optInTermsTexts = parsed.data.optInTermsTexts;
    const termsAndConditions = parsed.data.termsAndConditions;
    const privacyPolicy = parsed.data.privacyPolicy;

    const dataToSend = {
      ...fanGroup,
      ...parsed.data,
      operationParameters: {
        ticketingSystemId: parsed.data.ticketingSystemId,
        optInPartnersTexts,
        optInPlatformTexts,
        optInPartnersHidden,
        optInPlatformHidden,
        optInTermsTexts,
        termsAndConditions,
        privacyPolicy,
      },
    };
    updateFanGroup(dataToSend, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_fg_updated'),
        });
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: t('notification_error_fg_updated'),
        });
      },
      onSettled: () => {
        setSpinning(false);
      },
    });
  };

  return fanGroup?.id ? (
    <>
      <Spin
        tip={t('please_wait_message_text')}
        fullscreen
        spinning={spinning}
      />
      <FanGroupGeneralForm
        initialValues={getGeneralInitialValuesFGConfig(
          fanGroup,
          valuesWithTranslations,
          operationParametersValuesWithTranslations
        )}
        onSubmit={onSubmit}
        isLoading={isFanGroupUpdating}
        isConfiguration
      />
      <FGRegistrationConfig />
    </>
  ) : (
    <Skeleton />
  );
}

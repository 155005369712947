import { UserRole } from '@seaters-app/constants';
import { useHasAccess } from './useHasAccess';

interface ProtectedComponentProps {
  children: JSX.Element;
  allowedRoles?: UserRole[];
}

export const ProtectedComponent = ({
  allowedRoles,
  children,
}: ProtectedComponentProps): JSX.Element | null => {
  const hasAccess = useHasAccess(allowedRoles);

  if (hasAccess) {
    return children;
  }
  return null;
};

import {
  InvitationForApproverEntity,
  ApproverRequestsStatus,
  InvitationForApprove,
  waitingListInvitationsToApproveKeys,
} from '@seaters-app/constants';
import { TagsOutlined, CloseOutlined } from '@ant-design/icons';
import ReactDOM from 'react-dom';
import {
  queryClient,
  useApproveInvitationsToApprove,
  useFetchWaitingListsInvitationsToApprove,
  useRejectInvitationsToApprove,
} from '@seaters-app/data-access';
import { Button, CustomDivider, baseColor100 } from '@seaters-app/ui';
import {
  Alert,
  AlertProps,
  ConfigProvider,
  Divider,
  Form,
  Grid,
  Input,
  Modal,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import AntLayout from 'antd/es/layout';

import { Key, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import TextArea from 'antd/es/input/TextArea';
import { SortOrder } from 'antd/es/table/interface';
const { Footer: AntFooter } = AntLayout;
const { useBreakpoint } = Grid;
enum SortDirectionMap {
  'ascend' = 'ASC',
  'descend' = 'DESC',
}
const { Text, Title } = Typography;

export const requestStatusToBadge: {
  [status in ApproverRequestsStatus]: AlertProps['type'];
} = {
  [ApproverRequestsStatus.APPROVED]: 'success',
  [ApproverRequestsStatus.PENDING]: 'warning',
  [ApproverRequestsStatus.REJECTED]: 'error',
  [ApproverRequestsStatus.CANCELLED]: 'error',
};

export function InvitationsTable() {
  const { token } = theme.useToken();
  const { xs } = useBreakpoint();
  const { waitingListId = '' } = useParams();

  const [sort, setSort] = useState<{
    name: string;
    order: SortOrder;
  } | null>(null);

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    size: 10,
    sort: '',
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [invitationToReject, setInvitationToReject] =
    useState<InvitationForApproverEntity | null>();

  const [invitationsToReject, setInvitationsToReject] = useState<
    InvitationForApproverEntity[] | null
  >();

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const allRejections = Form.useWatch(['all'], form);

  const { data: invitations, isLoading } =
    useFetchWaitingListsInvitationsToApprove(waitingListId, {
      ...paginationParams,
      toallocate: false,
    });

  const { mutateAsync: approveApproverRequest } =
    useApproveInvitationsToApprove(waitingListId, {
      ...paginationParams,
      toallocate: false,
    });
  const { mutateAsync: rejectApproverRequest } =
    useRejectInvitationsToApprove();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: InvitationForApproverEntity) => ({
      disabled: getApprovalDisabled(record) || getRejectionDisabled(record), // Column configuration not to be checked
    }),
  };

  const tableData = invitations;

  useEffect(() => {
    setPaginationParams({
      ...paginationParams,
      sort:
        sort && sort.order
          ? `${sort.name}:${SortDirectionMap[sort.order]}:nullsLast`
          : '',
    });
  }, [sort]);

  const pagination = {
    total: tableData?.totalElements,
    current: paginationParams.page + 1,
    pageSize: paginationParams.size,
    onChange: (page: number, size: number) => {
      setPaginationParams({
        ...paginationParams,
        page: page - 1,
        size: size,
      });
    },
  };

  const handleApproverRequestApprove = async (
    approverRequest: InvitationForApproverEntity
  ) => {
    await approveApproverRequest(
      {
        invitationId: approverRequest.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            waitingListInvitationsToApproveKeys.all()
          );
          if (selectedRowKeys.includes(approverRequest.id)) {
            setSelectedRowKeys(
              selectedRowKeys.filter((el) => el !== approverRequest.id)
            );
          }
        },
      }
    );
    setSelectedRowKeys([]);
  };

  const handleApproverRequestReject = async (
    approverRequest: InvitationForApproverEntity,
    rejectionReason: string
  ) => {
    await rejectApproverRequest(
      {
        invitationId: approverRequest.id,
        body: {
          rejectionReason,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            waitingListInvitationsToApproveKeys.all()
          );
          if (selectedRowKeys.includes(approverRequest.id)) {
            setSelectedRowKeys(
              selectedRowKeys.filter((el) => el !== approverRequest.id)
            );
          }
        },
      }
    );
    setSelectedRowKeys([]);
  };

  const getRequestById = (id: Key) =>
    invitations?.content.find((el) => el.id === id);

  const selectedSeats = selectedRowKeys.reduce(
    (accumulator: number, currentValue) => {
      return (
        accumulator + (getRequestById(currentValue)?.nbrOfRequestedSeats ?? 0)
      );
    },
    0
  );

  const getRejectionDisabled = (
    approverRequest: InvitationForApproverEntity
  ) => {
    return (
      approverRequest.status === InvitationForApprove.REJECTED ||
      approverRequest.status === InvitationForApprove.CANCELLED
    );
  };

  const getApprovalDisabled = (
    approverRequest: InvitationForApproverEntity
  ) => {
    return (
      approverRequest.status === InvitationForApprove.CANCELLED ||
      approverRequest.status === InvitationForApprove.REJECTED ||
      approverRequest.status === InvitationForApprove.APPROVED
    );
  };

  const approveSelected = async () => {
    selectedRowKeys.forEach((selectedRowKey) => {
      const request = getRequestById(selectedRowKey);

      if (request && !getApprovalDisabled(request)) {
        handleApproverRequestApprove(request);
      }
    });
    setSelectedRowKeys([]);
  };
  //TODO: remove when needed
  const rejectSelected = async () => {
    selectedRowKeys.forEach((selectedRowKey) => {
      const request = getRequestById(selectedRowKey);

      if (request && !getRejectionDisabled(request)) {
        handleApproverRequestReject(request, '/');
      }
    });
    setSelectedRowKeys([]);
  };

  //TODO: uncomment when needed

  // const rejectSelected = async () => {

  //   const filteredRows = selectedRowKeys.map((selectedRowKey) => {
  //     const request = getRequestById(selectedRowKey);

  //     return request;
  //   });
  //   setInvitationsToReject(filteredRows as InvitationForApproverEntity[]);

  //     setInvitationsToReject(null);
  //     setSelectedRowKeys([]);
  //   }
  // };

  const handleRejectionSelected = (
    rejectionReasons: {
      [id: string]: string;
    },
    allReasons?: string
  ) => {
    invitationsToReject?.forEach((invitation) => {
      handleApproverRequestReject(
        invitation,
        allReasons || rejectionReasons[invitation.id]
      );
    });
  };

  return invitations ? (
    <Space direction="vertical" style={{ width: '100%' }} size={0}>
      {selectedSeats > 0 && !xs && (
        <ConfigProvider
          theme={{
            token: {
              colorText: baseColor100,
            },
          }}
        >
          <Space size={24} className={styles.approveBlock}>
            <Space size={24}>
              <Space>
                <Text style={{ wordBreak: 'unset', fontSize: '12px' }}>
                  {t('selected_seats_label')}
                </Text>
                <Title level={4} ellipsis={{ rows: 1 }} style={{ margin: 0 }}>
                  {selectedSeats}
                </Title>
              </Space>
              <Button danger type="primary" onClick={rejectSelected}>
                {t('distribution_reject_requests', {
                  'selectedRowKeys.length': selectedRowKeys.length,
                })}
              </Button>
              <Button type="primary" onClick={approveSelected}>
                {t('distribution_distribute_requests', {
                  selectedSeats: selectedSeats,
                })}
              </Button>
            </Space>
          </Space>
        </ConfigProvider>
      )}

      <Table
        rowSelection={rowSelection}
        dataSource={tableData?.content}
        loading={isLoading}
        size="small"
        scroll={{
          x: true,
        }}
        pagination={{
          ...pagination,
          showSizeChanger: true,
        }}
        rowKey={(record) => record.id}
      >
        <Column
          title={`${t('guestlist_confirm_selection')} ${t('guestlist_status')}`}
          dataIndex="status"
          render={(value: ApproverRequestsStatus) => {
            return (
              <Alert
                style={{
                  width: 'fit-content',
                  fontSize: xs ? 12 : 'auto',
                  padding: xs ? 5 : 'auto',
                }}
                message={value}
                type={requestStatusToBadge[value]}
              />
            );
          }}
        />
        {/* <Column
          title="Invitation status"
          render={(_, record: InvitationForApproverEntity) => {
            const status = getInvitationStatus(record);

            if (status)
              return (
                <Alert
                  style={{
                    width: 'fit-content',
                  }}
                  message={status.toString().replaceAll('_', ' ')}
                  showIcon
                  type={statusToBadge[status].status}
                  icon={statusToBadge[status].icon}
                />
              );
          }}
        /> */}
        <Column
          title={t('guestlist_name')}
          dataIndex={['guest', 'firstName']}
          render={(_, record: InvitationForApproverEntity) => {
            return (
              <>
                {record.guest?.firstName} {record.guest?.lastName}
              </>
            );
          }}
        />
        <Column
          title={t('general_host')}
          dataIndex={['host', 'firstName']}
          render={(_, record: InvitationForApproverEntity) => {
            return (
              <>
                {record.host?.firstName} {record.host?.lastName}
              </>
            );
          }}
        />
        <Column title={t('general_seats')} dataIndex={'nbrOfRequestedSeats'} />
        <Column
          key="actions"
          align="right"
          render={(_, approverRequest: InvitationForApproverEntity) => {
            return (
              <Space size={0}>
                <Button
                  disabled={getRejectionDisabled(approverRequest)}
                  danger
                  icon={<CloseOutlined rev={undefined} />}
                  type="link"
                  onClick={() => {
                    // if (!approverRequest.rejectionReason) {
                    //   setInvitationToReject(approverRequest);
                    //   return;
                    // }

                    handleApproverRequestReject(
                      approverRequest,

                      '/'
                    );
                  }}
                >
                  {t('reject')}
                </Button>
                <Button
                  disabled={getApprovalDisabled(approverRequest)}
                  type="link"
                  icon={<TagsOutlined rev={undefined} />}
                  color={token.colorPrimary}
                  onClick={() => {
                    handleApproverRequestApprove(approverRequest);
                    setSelectedRowKeys([]);
                  }}
                >
                  {t('approve')}
                </Button>
              </Space>
            );
          }}
        />
      </Table>
      {selectedSeats > 0 &&
        xs &&
        ReactDOM.createPortal(
          <AntFooter
            style={{
              backgroundColor: token.colorTextBase,
              position: 'fixed',
              bottom: '0',
              right: '0',
              width: '100%',
              padding: '16px 5%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              zIndex: 10,
              borderTop: `1px solid ${token.colorBorder}`,
            }}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorText: baseColor100,
                },
              }}
            >
              <Space>
                <Text style={{ wordBreak: 'unset', fontSize: '12px' }}>
                  {t('selected_seats_label')}
                </Text>
                <Title level={4} ellipsis={{ rows: 1 }} style={{ margin: 0 }}>
                  {selectedSeats}
                </Title>
              </Space>
              <CustomDivider color={token.colorBorder} />

              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                <Button danger type="primary" onClick={rejectSelected}>
                  {t('distribution_reject_requests', {
                    'selectedRowKeys.length': selectedRowKeys.length,
                  })}
                </Button>
                <Button type="primary" onClick={approveSelected}>
                  {t('distribution_distribute_requests', {
                    selectedSeats: selectedSeats,
                  })}
                </Button>
              </Space>
            </ConfigProvider>
          </AntFooter>,
          document.getElementById('portalForFooter') as HTMLElement
        )}
      <Modal
        title={t('rejection_reason_modal_title')}
        open={false} //TODO: uncomment when needed
        onOk={form.submit}
        onCancel={() => setInvitationToReject(null)}
        okText={t('reject_button_text')}
      >
        <Form
          initialValues={{
            rejectionReason: '',
          }}
          form={form}
          layout="vertical"
          onFinish={(v) => {
            if (invitationToReject)
              handleApproverRequestReject(
                invitationToReject,
                v.rejectionReason
              );
            setInvitationToReject(null);
          }}
        >
          <Form.Item
            label={'Reason'}
            name="rejectionReason"
            rules={[
              { required: true, message: t('rejection_reason_modal_error') },
            ]}
          >
            <TextArea
              showCount
              maxLength={150}
              style={{ height: 120, resize: 'none' }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t('rejection_reason_modal_title')}
        open={false} //TODO: uncomment when needed
        onOk={form.submit}
        onCancel={() => setInvitationsToReject(null)}
        okText={t('reject_button_text')}
      >
        <Form
          initialValues={{
            all: null,
            rejectionReasons: invitationsToReject?.reduce(
              (a, v) => ({ ...a, [v.id]: v.rejectionReason }),
              {}
            ),
          }}
          form={form}
          layout="vertical"
          onFinish={(v) => {
            //TODO: uncomment when needed
            // handleRejectionSelected(v.rejectionReasons, v.all);
            // setInvitationsToReject(null);
            // setSelectedRowKeys([]);
          }}
        >
          <Form.Item label={t('apply_for_all')} name={'all'}>
            <Input />
          </Form.Item>
          <Divider />
          {invitationsToReject?.map((el) => {
            return (
              <Form.Item
                label={`${el.guest?.firstName} ${el.guest?.lastName}`}
                name={['rejectionReasons', el.id]}
                rules={[
                  {
                    required: !allRejections,
                    message: t('rejection_reason_modal_error'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            );
          })}
        </Form>
      </Modal>
    </Space>
  ) : null;
}

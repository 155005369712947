import {
  Typography,
  notification,
  Popconfirm,
  Spin,
  Skeleton,
  theme,
  Space,
  Tooltip,
  Flex,
  Breadcrumb,
} from 'antd';
import { DeleteOutlined, UnlockOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateUserHostRole,
  useCreateUserOwnership,
  useDeleteUser,
  useFetchUser,
  useUnlockUser,
  useUpdateUser,
} from '@seaters-app/data-access';
import './styles.css';
import {
  FanGroupEntity,
  UserFormEntity,
  UserListEntity,
  UserStatus,
} from '@seaters-app/constants';
import { Button, alertColor } from '@seaters-app/ui';
import { UserForm } from '../UserForm/UserForm';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export function User() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const { token } = theme.useToken();
  const { data: user } = useFetchUser(userId);

  const { mutateAsync: deleteUser } = useDeleteUser();

  const { mutate: updateUser, isLoading: isUserUpdating } =
    useUpdateUser(userId);

  const { mutate: createOwnership, isLoading: isOwnershipCreating } =
    useCreateUserOwnership();

  const { mutate: setHostRoleForUser, isLoading: isHostRoleSetting } =
    useCreateUserHostRole();

  const saveChanges = (
    values: UserFormEntity,
    ownershipFanGroupsList: FanGroupEntity[],
    setOwnershipFanGroupsList: (v: FanGroupEntity[]) => void,
    hostRoleFanGroupsList: FanGroupEntity[],
    setHostRoleFanGroupsList: (v: FanGroupEntity[]) => void
  ) => {
    const {
      email,
      countryCallingCode,
      localNumber,
      lastName,
      firstName,
      password,
      roles,
    } = values;
    if (user) {
      const userToUpdate: UserListEntity & { password?: string } = {
        ...user,
        email,
        mobilePhoneNumber: {
          countryCallingCode,
          localNumber,
        },
        roles,
        lastName,
        firstName,
        password,
      };
      if (!password) {
        delete userToUpdate.password;
      }
      if (!countryCallingCode || !localNumber) {
        userToUpdate.mobilePhoneNumber = null;
      }

      updateUser(userToUpdate, {
        onSuccess: async () => {
          notification.success({
            message: t('notification_ success_user_updated'),
          });

          await ownershipFanGroupsList.forEach((fanGroup: FanGroupEntity) =>
            createOwnership(
              {
                userId,
                fanGroupId: fanGroup.id,
              },
              {
                onSuccess: () => {
                  notification.success({
                    message: t('notification_success_ownership_created'),
                  });
                  setOwnershipFanGroupsList([]);
                },
                onError: (err) => {
                  console.error(err);
                  notification.error({
                    message: `${t('notification_error_ownership_created')}. ${
                      err?.response?.data.message
                        ? err?.response?.data.message
                        : ''
                    }`,
                  });
                },
              }
            )
          );

          await hostRoleFanGroupsList.forEach((fanGroup: FanGroupEntity) =>
            setHostRoleForUser(
              {
                userId,
                fanGroupId: fanGroup.id,
              },
              {
                onSuccess: () => {
                  notification.success({
                    message: t('notification_success_role_setted'),
                  });
                  setHostRoleFanGroupsList([]);
                },
                onError: (err) => {
                  console.error(err);
                  notification.error({
                    message: `${t('notification_error_role_setted')}. ${
                      err?.response?.data.message
                        ? err?.response?.data.message
                        : ''
                    }`,
                  });
                },
              }
            )
          );
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_user_updated'),
          });
        },
      });
    }
  };

  const { mutate: unlockUser } = useUnlockUser(userId);

  const handleUnlockUser = () => {
    unlockUser(userId, {
      onSuccess: async () => {
        notification.success({
          message: t('notification_success_user_uplocked'),
        });
      },
      onError: (err) => {
        console.error(err);
        notification.error({
          message: t('notification_error_user_uplocked'),
        });
      },
    });
  };

  const handleDeleteUser = (userId: string) =>
    deleteUser(
      { userId },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_user_removed'),
          });
          navigate('..');
        },
        onError: () => {
          notification.error({
            message: t('notification_error_user_removed'),
          });
        },
      }
    );

  return user ? (
    <>
      <Spin
        tip={t('please_wait_message_text')}
        fullscreen
        spinning={isUserUpdating || isOwnershipCreating}
      />
      <Flex
        vertical
        style={{
          padding: '24px 24px 80px',
        }}
        gap={12}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={'..'}>{t('admin_users')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{t('admin_tabs_details')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="subheader">
            <Title level={3}>
              {user?.firstName} {user?.lastName}
            </Title>
            <Space>
              {user?.status === UserStatus.LOCKED && (
                <Tooltip title={t('unlock_user_button_tooltip_text')}>
                  <Button
                    size="large"
                    type="text"
                    color={alertColor}
                    onClick={handleUnlockUser}
                    icon={<UnlockOutlined rev={undefined} />}
                  >
                    {t('unlock_user_button_text')}
                  </Button>
                </Tooltip>
              )}
              <Popconfirm
                title={t('delete_user_button_text')}
                description={t('delete_user_confirmation_text')}
                cancelText={t('general_no')}
                onConfirm={() => handleDeleteUser(userId)}
              >
                <Button
                  size="large"
                  type="text"
                  icon={<DeleteOutlined rev={undefined} />}
                  color={token.colorError}
                >
                  {t('remove_user_button_text')}
                </Button>
              </Popconfirm>
            </Space>
          </div>
          <UserForm
            saveChanges={saveChanges}
            initialValues={{
              ...user,
              localNumber: user?.mobilePhoneNumber?.localNumber ?? '',
              countryCallingCode:
                user?.mobilePhoneNumber?.countryCallingCode ?? '',
              password: '',
            }}
          />
        </div>
      </Flex>
    </>
  ) : (
    <Skeleton />
  );
}

import React from 'react';
import { Breadcrumb, Flex, notification, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  fetchAdminSurveys,
  useCreateAdminSurveyInstance,
} from '@seaters-app/data-access';
import { routes, SurveyInstanceBody } from '@seaters-app/constants';

import { getInitialValues } from './getInitialValues';
import SurveyInstanceDetails from './SurveyInstanceDetails';
const { Title } = Typography;

function SurveyInstanceCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate: createSurveyInstance, isLoading: isCreating } =
    useCreateAdminSurveyInstance();

  const initialValues = getInitialValues();

  const createSurvey = (dataToSend: SurveyInstanceBody) => {
    createSurveyInstance(dataToSend, {
      onSuccess: (res) => {
        notification.success({
          message: t('notification_success_survey_usage_created'),
        });
        navigate(`../${routes.usage}/${res.id}`);
      },
      onError: ({ response }) => {
        response?.data?.errors?.errorsTypes?.validation_errors.map(
          ({ error }) => {
            notification.error({
              message: t('notification_error_survey_usage_created'),
              description: t(error.errorCode),
            });
          }
        );
      },
    });
  };

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`../${routes.usage}`}>{t('survey_usage_label')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{t('survey_usage_creation_label')}</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Title level={3}>{t('survey_usage_creation_label')}</Title>
      <SurveyInstanceDetails
        initialValues={initialValues}
        onSubmit={createSurvey}
        surveysFetchFunction={fetchAdminSurveys}
      />
    </Flex>
  );
}

export default SurveyInstanceCreate;

import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  MailOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { SeatStatus } from '@seaters-app/constants';
import {
  baseColor600,
  errorColor,
  successColor,
  alertColor,
} from '@seaters-app/ui';
import { Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

export interface SeatStatusTagProps {
  status: SeatStatus;
}

const seatStatusToColor = {
  [SeatStatus.ACCEPTED]: {
    color: successColor,
    label: 'mwl_seats_table-status-confirmed',
    icon: <CheckCircleOutlined rev={undefined} />,
  },
  [SeatStatus.ARCHIVED]: {
    color: baseColor600,
    label: 'admin_event_status_archived',
    icon: <StopOutlined rev={undefined} />,
  },
  [SeatStatus.ASSIGNED]: {
    color: alertColor,
    label: 'mwl_seats_table-status-pending',
    icon: <MailOutlined />,
  },
  [SeatStatus.ASSIGNED_WITHOUT_SEATS]: {
    color: alertColor,
    label: 'assigned_without_seats_wl_status',
    icon: <MailOutlined rev={undefined} />,
  },
  [SeatStatus.REJECTED]: {
    color: errorColor,
    label: 'rejected_status_text',
    icon: <StopOutlined rev={undefined} />,
  },
  [SeatStatus.RSVP_ACCEPTED]: {
    color: successColor,
    label: 'confirmed_without_seats_wl_status',
    icon: <InfoCircleOutlined />,
  },
};
export function SeatStatusTag({ status }: SeatStatusTagProps) {
  const { t } = useTranslation();
  return (
    <Tag color={seatStatusToColor[status].color}>
      <Space size={5}>
        {seatStatusToColor[status].icon && seatStatusToColor[status].icon}
        {t(seatStatusToColor[status].label)}
      </Space>
    </Tag>
  );
}

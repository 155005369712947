import React, { useState } from 'react';
import {
  FanGroupAccessFormType,
  FanGroupAccessValidatorSchema,
  FanGroupEntity,
  FanGroupVisibility,
  fanGroupsKeys,
} from '@seaters-app/constants';
import { Skeleton, notification, Spin } from 'antd';
import { queryClient, useUpdateFanGroup } from '@seaters-app/data-access';
import { useParams } from 'react-router-dom';
import { prepareValues } from '../helpers/prepareValues';
import { FanGroupAccessForm } from '../../fanGroupForm/FanGroupAccessForm';
import {
  getTranslations,
  initialTranslations,
} from '../../../../utils/helpers/getTranslations';
import { useTranslation } from 'react-i18next';

export function Access() {
  const { t } = useTranslation();
  const [spinning, setSpinning] = useState(false);
  const { fanGroupId = '' } = useParams();

  const fanGroup = queryClient.getQueryData<FanGroupEntity>(
    fanGroupsKeys.detail(fanGroupId)
  );

  const { mutate: updateFanGroup } = useUpdateFanGroup(fanGroupId);
  const formValues = fanGroup
    ? {
        visibility:
          fanGroup?.operationParameters.visibility ===
          FanGroupVisibility.VISIBLE,
        accessMode: fanGroup.operationParameters.accessMode,
        protectionCodeExplanation:
          getTranslations(['protectionCodeExplanation'], fanGroup)
            ?.protectionCodeExplanation ?? initialTranslations,
      }
    : null;

  const onSubmit = (values: FanGroupAccessFormType) => {
    setSpinning(true);
    const preparedValues = {
      ...prepareValues(values),
      visibility: values.visibility,
    };

    const parsed = FanGroupAccessValidatorSchema.safeParse(preparedValues);

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }

    if (fanGroup) {
      const dataToSend = {
        ...fanGroup,
        ...fanGroup?.operationParameters,
        operationParameters: {
          ...fanGroup?.operationParameters,
          visibility: parsed.data.visibility
            ? FanGroupVisibility.VISIBLE
            : FanGroupVisibility.INVISIBLE,
          accessMode: parsed.data.accessMode,
        },
        protectionCodeExplanation: parsed.data.protectionCodeExplanation,
        visibility: parsed.data.visibility
          ? FanGroupVisibility.VISIBLE
          : FanGroupVisibility.INVISIBLE,
        accessMode: parsed.data.accessMode,
      };
      updateFanGroup(dataToSend, {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_fg_updated'),
          });
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_fg_updated'),
          });
        },
        onSettled: () => {
          setSpinning(false);
        },
      });
    }
  };

  return formValues ? (
    <Spin tip={t('please_wait_message_text')} spinning={spinning}>
      <FanGroupAccessForm fanGroup={formValues} onSubmit={onSubmit} />
    </Spin>
  ) : (
    <Skeleton />
  );
}

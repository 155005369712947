import { LookEntity, usersKeys, ReportType } from '@seaters-app/constants';
import {
  downloadFileByURL,
  queryClient,
  useGenerateApproverReport,
} from '@seaters-app/data-access';
import { useState } from 'react';

export const useExportReport = (): {
  isExporting: boolean;
  handleExport: () => void;
} => {
  const [reportLoading, setReportLoading] = useState(false);

  const lookEntity = queryClient.getQueryData<LookEntity>(usersKeys.look());

  const { mutate } = useGenerateApproverReport(lookEntity?.fanGroupId || '');

  const handleExportFGReport = () => {
    setReportLoading(true);

    mutate(
      { reportName: ReportType.BNP_HOSPITALITY_HOST_REPORT },
      {
        onSuccess: async (response) => {
          downloadFileByURL(response.url);
          setReportLoading(false);
        },
      }
    );
  };
  return {
    isExporting: reportLoading,
    handleExport: handleExportFGReport,
  };
};

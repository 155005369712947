import { z } from 'zod';

export const TicketingSystemValidatorSchema = z.object({
  name: z.string(),
  type: z.string(),
});

export type TicketingSystemValidator = z.infer<
  typeof TicketingSystemValidatorSchema
>;

export enum EventStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  REGULAR_SALES = 'REGULAR_SALES',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
}

export enum AttendeeRequiredFieldsType {
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  birthDate = 'birthDate',
  address = 'address',
  citizenshipCountryCode = 'citizenshipCountryCode',
  idNumber = 'idNumber',
  zipCode = 'zipCode',
  city = 'city',
  state = 'state',
  country = 'country',
  company = 'company',
  language = 'language',
}

import {
  ListEntity,
  ProtectionCodeEntity,
  fanGroupsKeys,
} from '@seaters-app/constants';
import { fetchFanGroupProtectionCodes } from '@seaters-app/data-access';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';

export const useProtectedCodesOptions = (fanGroupId: string) => {
  const [query, setQuery] = useState({
    size: 10,
    page: 0,
    query: '',
  });
  const { t } = useTranslation();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<ListEntity<ProtectionCodeEntity>, Error>({
    queryKey: fanGroupsKeys.codesList(fanGroupId, query),
    queryFn: ({
      pageParam = {
        maxPageSize: query.size,
        itemOffset: query.size * query.page,
        query: query.query,
      },
    }) => fetchFanGroupProtectionCodes(fanGroupId, pageParam),
    getNextPageParam: ({ totalSize, itemOffset, maxPageSize }) => {
      const nextOffset = itemOffset + maxPageSize;

      return totalSize - itemOffset > maxPageSize
        ? {
            itemOffset: nextOffset,
            maxPageSize: maxPageSize,
            query: query.query,
          }
        : undefined;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  const allItems = data?.pages?.flatMap((page) => page.items) || [];
  const codesOptions = allItems?.map((code: ProtectionCodeEntity) => {
    return {
      label: code.code,
      used: `(${t('admin_fan-group-protection-codes_times-used')}: ${
        code.timesUsed
      }/${code.maxTimesUsed})`,
      value: code.code,
      disabled: code.timesUsed === code.maxTimesUsed,
    };
  });

  return {
    codesQuery: query,
    setCodesQuery: setQuery,
    codesOptions,
    loadMoreData: handleFetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  };
};

import { baseColor400 } from '@seaters-app/ui';
import styles from './styles.module.css';
import { Skeleton } from 'antd';

function WishListCardSkeleton() {
  return (
    <div
      className={styles.card}
      style={{ borderBottom: `2px solid ${baseColor400}` }}
    >
      <div className={styles.imageWrap}>
        <Skeleton.Button block className={styles.image} />
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.contentRow}>
          <Skeleton.Input active size="small" block />

          <div className={styles.contentCol}>
            <Skeleton.Input active size="small" />
          </div>
        </div>
        <Skeleton.Input active size="small" />
        <Skeleton.Input active size="small" />

        <div className={styles.contentRow}>
          <Skeleton.Input active size="small" block />

          <div className={styles.contentCol}>
            <Skeleton.Input active size="small" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WishListCardSkeleton;

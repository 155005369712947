import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { Avatar, Typography, theme } from 'antd';
import { Button } from '@seaters-app/ui';
const { Title, Text } = Typography;

interface FanAttributeCardProps {
  title: string;
  id: string;
  unlinkFanAttribute: ({ id }: { id: string }) => void;
  facebookId?: string;
}

function FanAttributeCard({
  title,
  id,
  unlinkFanAttribute,
  facebookId,
}: FanAttributeCardProps) {
  const { token } = theme.useToken();
  return (
    <div
      style={{ border: `1px solid ${token.colorBorder}` }}
      key={id}
      className={styles.card}
    >
      <Avatar
        className={styles.avatar}
        size="large"
        src={`https://graph.facebook.com/${facebookId}/picture`}
      />
      <Text strong className={styles.title}>
        {title}
      </Text>
      <Button
        className={styles.close}
        color={token.colorError}
        type="text"
        icon={
          <CloseOutlined
            color={token.colorError}
            rev={undefined}
            onClick={() => unlinkFanAttribute({ id })}
          />
        }
      />
    </div>
  );
}

export default FanAttributeCard;

import { Col, Form, Row, Typography } from 'antd';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

import { Colorpicker as AntdColorPicker } from 'antd-colorpicker';
const { Text } = Typography;

type ColorPickerProps = {
  color: string;
};

export const ColorPicker = ({ color }: ColorPickerProps) => {
  const { t } = useTranslation();
  const fgColor = color?.hex ? color.hex : color;
  return (
    <Row gutter={24}>
      <Col xs={16} md={12} lg={8} xl={6} xxl={4}>
        <Form.Item
          label={<Text strong>{t('fg_color_label')}</Text>}
          name="color1"
        >
          <AntdColorPicker />
        </Form.Item>
      </Col>
      <Col xs={4} md={4} lg={4} xl={2} xxl={2} className={styles.centeredCol}>
        <Form.Item label="hidden">
          <div
            className={styles.colorPreview}
            style={{
              backgroundColor: fgColor?.includes('#') ? fgColor : `#${fgColor}`,
            }}
          ></div>
        </Form.Item>
      </Col>
      <Col xs={24} md={24} lg={8} xl={8} xxl={8} className={styles.centeredCol}>
        <Form.Item label="hidden">
          <div className={styles.tips}>
            {t('fgc_graphics_color-help')}
            <br />
            {t('fgc_graphics_color-suggestion')}
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
};

import { AnyZodObject, z } from 'zod';
import { TranslationValidatorSchema } from './translation.validator';
import {
  QuestionStructureType,
  SurveyExtensionPoint,
  SurveyInputMode,
  SurveyStatus,
} from '../../enums';
import { getTranslationValidation } from '../../helpers';

const StructuredAnswerItemValidatorSchema = z.object({
  name: z.string(),
  value: z.union([z.string().min(1), z.boolean()]),
});

export function generateRandomStructuredFieldsValidatorSchema(
  fields?: {
    name: string;
    isMandatory: boolean;
    type: QuestionStructureType;
  }[]
): AnyZodObject {
  let schema = z.object({});
  if (!fields) return schema;
  fields.forEach((field, i) => {
    const isFreeText = field.type === QuestionStructureType.FREE_TEXT;

    if (field.isMandatory) {
      schema = z.object({
        ...schema.shape,
        [field.name]: isFreeText
          ? z.string().min(1)
          : z.union([z.string().min(1), z.boolean()]),
      });
    } else {
      schema = z.object({
        ...schema.shape,
        [field.name]: isFreeText
          ? z.string().nullable().optional()
          : z.union([z.string(), z.boolean()]).nullable().optional(),
      });
    }
  });
  return schema;
}

export function generateSurveyAnswersValidatorSchema(
  fields?: {
    name: string;
    isMandatory: boolean;
    type: QuestionStructureType;
  }[]
): AnyZodObject {
  const structuredAnswerSchema =
    generateRandomStructuredFieldsValidatorSchema(fields);

  return z.object({
    answers: z.array(
      z.object({
        questionId: z.string(),
        surveyInstanceId: z.string().optional(),
        answers: z.array(
          z.object({
            offeredAnswerId: z
              .union([z.string(), z.array(z.string())])
              .nullable()
              .optional(),
            structuredAnswer: structuredAnswerSchema.optional(),
            answerText: z.string().nullable().optional(),
          })
        ),
      })
    ),
  });
}

export const SurveyAnswersValidatorSchema = z.object({
  answers: z.array(
    z.object({
      questionId: z.string(),
      surveyInstanceId: z.string().optional(),
      answers: z.array(
        z.object({
          offeredAnswerId: z
            .union([z.string(), z.array(z.string())])
            .nullable()
            .optional(),
          structuredAnswer: z
            .array(StructuredAnswerItemValidatorSchema)
            .optional(),
          answerText: z.string().nullable().optional(),
        })
      ),
    })
  ),
});

export type SurveyAnswersValidator = z.infer<
  typeof SurveyAnswersValidatorSchema
>;

export const SurveyValidatorSchema = z.object({
  name: z.string(),
  description: z.array(TranslationValidatorSchema),
  title: z.array(TranslationValidatorSchema),
  status: z.nativeEnum(SurveyStatus),
  surveyQuestions: z
    .array(
      z.object({
        mandatory: z.boolean(),
        enabled: z.boolean(),
        questionId: z.string(),
      })
    )
    .optional(),
});

export type SurveyValidator = z.infer<typeof SurveyValidatorSchema>;

export const SurveyFormValidatorSchema = z.object({
  name: z.string().min(1),
  description: getTranslationValidation(),
  title: getTranslationValidation(),
  status: z.nativeEnum(SurveyStatus),
});

export type SurveyFormValidator = z.infer<typeof SurveyFormValidatorSchema>;

export const SurveyInstanceValidatorSchema = z.object({
  surveyId: z.string().min(1),
  waitinglistId: z.string().optional(),
  fangroupId: z.string().optional().nullable(),
  extensionPoint: z.nativeEnum(SurveyExtensionPoint),
  inputMode: z.nativeEnum(SurveyInputMode),
});

export type SurveyInstanceValidator = z.infer<
  typeof SurveyInstanceValidatorSchema
>;

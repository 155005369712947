import { TransactionEntity, V2Prefix, endpoints } from '@seaters-app/constants';
import { api } from '../axios-instance';

// api.qa-seaters.com/api/fan/waiting-lists/327e9ce5-1e95-49b7-972f-ad4871e9a5a9/transaction
export const createTransaction = (
  wlId: string,
  body: { [key: string]: { [key: string]: string } }
): Promise<unknown> =>
  api.post(
    `${endpoints.fan}/${endpoints.waitingLists}/${wlId}/${endpoints.transaction}`,
    body
  );

// POST api/v2/fan-group-owner/waiting-lists/{listId}/position/{fanId}/transaction

export const createFGOTransaction = (
  wlId: string,
  fanId: string,
  body: { [key: string]: { [key: string]: string } }
): Promise<unknown> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${wlId}/${endpoints.position}/${fanId}/${endpoints.transaction}`,
    body
  );

// GET https://api.qa-seaters.com/api/fan/waiting-lists/listId/transaction/
export const fetchTransaction = (wlId: string): Promise<TransactionEntity> =>
  api.get(
    `${endpoints.fan}/${endpoints.waitingLists}/${wlId}/${endpoints.transaction}`
  );

// DELETE https://api.qa-seaters.com/api/fan/waiting-lists/listId/transaction/

export const deleteTransaction = (wlId: string) =>
  api.delete(
    `${endpoints.fan}/${endpoints.waitingLists}/${wlId}/${endpoints.transaction}`
  );

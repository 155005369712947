import { SeatEntity } from '@seaters-app/constants';
import {
  fetchFGOWaitingListDistributionFinished,
  retryCallback,
  sleep,
  useGiveSeats,
  useGiveSeatsParking,
} from '@seaters-app/data-access';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const useDistribution = (wishListId: string) => {
  const { t } = useTranslation();
  const { mutateAsync: giveSeats } = useGiveSeats(wishListId);

  const { mutateAsync: giveSeatsParking } = useGiveSeatsParking(wishListId);

  const handleDistribution = async (
    position: SeatEntity,
    withParking?: boolean
  ) => {
    const actionToUse = !withParking ? giveSeats : giveSeatsParking;
    await actionToUse(
      { positionId: position.fanId },
      {
        onError: ({ response }) => {
          notification.error({
            message: t(response?.data?.message || 'notification_error_message'),
          });
        },
      }
    ).then(async (data) => {
      const attempt = async () => {
        await sleep(1000);

        const { distributionFinished } =
          await fetchFGOWaitingListDistributionFinished(
            wishListId,
            data.nextDistributionNumber
          );

        if (distributionFinished) {
          return distributionFinished;
        } else {
          throw distributionFinished;
        }
      };

      const test = (value: boolean) => {
        if (value) {
          return value;
        } else {
          throw value;
        }
      };

      const processResult = (result: boolean) => {
        if (result) {
          // if (!isMultiple) {
          notification.success({
            message: t('notification_success_all_seats_distributed'),
          });
          // }
        }
      };

      const errorHandler = (error: any) => {
        console.error(error);
        notification.error({
          message: t('notification_error_all_seats_distributed'),
        });
      };

      const result = await retryCallback(
        attempt,
        test,
        processResult,
        errorHandler,
        10
      );

      return result;
    });
  };

  return { handleDistribution };
};

export default useDistribution;

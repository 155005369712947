import { Button, Dropdown, MenuProps, Space, Tooltip, Typography } from 'antd';
import {
  TranslationEntity,
  VenueConfiguration,
  VenueEntity,
} from '@seaters-app/constants';
import { baseColor200, baseColor900 } from '@seaters-app/ui';
import imagePlaceholder from '../../../../../../public/imagePlaceholder.svg';

import styles from './styles.module.css';
import { getSingleTranslation } from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export function VenueCard({
  venue,
  handleSelectVenue,
}: {
  venue: VenueEntity;
  handleSelectVenue: (
    venue: VenueEntity,
    venueConfiguration: VenueConfiguration
  ) => void;
}) {
  const { imageUrl } = venue;
  const { t } = useTranslation();
  const venueName = ['city', 'country']
    .map((el) =>
      getSingleTranslation(
        venue[el as keyof typeof venue] as TranslationEntity[]
      )
    )
    .filter((el) => el.length)
    .join(', ');

  const emptyConfigurations = !venue.configurations?.length;

  const configurations = venue.configurations?.map((el) => ({
    key: el.id,
    label: `${el.name} – ${el.seatCategories.map((el) => el.name).join(', ')}`,
  }));

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const venueConfig = venue.configurations?.find((el) => el.id === e.key);

    if (venueConfig) {
      handleSelectVenue(venue, venueConfig);
    }
  };

  const menuProps = {
    items: configurations,
    onClick: handleMenuClick,
  };

  return (
    <div className={styles.card}>
      <div
        className={styles.imageWrap}
        style={{ backgroundColor: baseColor200 }}
      >
        {imageUrl ? (
          <img className={styles.image} alt={venue.id} src={imageUrl} />
        ) : (
          <img
            className={styles.imagePlaceholder}
            alt={venue.id}
            src={imagePlaceholder}
          />
        )}
      </div>
      <Space direction="horizontal" className={styles.container}>
        <Space direction="vertical" size={2}>
          <Text strong style={{ color: baseColor900 }}>
            {getSingleTranslation(venue.name)}
          </Text>
          <Text style={{ color: baseColor900 }}>{venueName}</Text>
        </Space>
        <Tooltip
          title={
            emptyConfigurations
              ? 'There are no configurations for this venue. Please contact Seaters Support.'
              : null
          }
        >
          <Space direction="horizontal">
            {venue.configurations?.length === 1 ? (
              <Button
                onClick={() =>
                  handleSelectVenue(venue, venue.configurations[0])
                }
              >
                {t('select_button_text')}
              </Button>
            ) : (
              <Dropdown
                disabled={emptyConfigurations}
                menu={menuProps}
                placement="bottom"
                arrow={{ pointAtCenter: true }}
              >
                <Button>{t('select_button_text')}</Button>
              </Dropdown>
            )}
          </Space>
        </Tooltip>
      </Space>
    </div>
  );
}

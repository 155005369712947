import React, { ReactElement } from 'react';

import AntLayout from 'antd/es/layout';
import { Button, ConfigProvider, Space, theme } from 'antd';
import styles from './footer.module.css';
import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface FanGroupCreationFooterProps {
  children?: ReactElement;
  isDisabled?: boolean;
  isLoading?: boolean;
  handleNextStep?: () => void;
  handlePreviousStep?: () => void;
  currentStep: number;
  formSteps: string[];
}
const { Footer: AntFooter } = AntLayout;

export function FanGroupCreationFooter({
  isDisabled,
  isLoading,
  children,
  handleNextStep,
  handlePreviousStep,
  currentStep,
  formSteps,
}: FanGroupCreationFooterProps) {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === formSteps.length - 1;
  return (
    <AntFooter
      className={styles.footer}
      style={{
        borderTop: `1px solid ${token.colorBorderSecondary}`,
      }}
    >
      <Space className={children && styles.space}>
        {children}
        <div className={styles.controls}>
          {isFirstStep ? (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimaryHover: token.colorError,
                  colorTextBase: token.colorError,
                  colorBorder: token.colorError,
                },
              }}
            >
              <NavLink to="..">
                <Button
                  size="middle"
                  type="default"
                  icon={<CloseOutlined rev={undefined} />}
                >
                  {t('cancel_create_button_text')}
                </Button>
              </NavLink>
            </ConfigProvider>
          ) : (
            handlePreviousStep && (
              <Button
                size="middle"
                type="default"
                disabled={isDisabled}
                loading={isLoading}
                onClick={handlePreviousStep}
                icon={<LeftOutlined rev={undefined} />}
              >
                {t('prev_step_button_text')}
              </Button>
            )
          )}

          {!isLastStep && (
            <Button
              type="default"
              size="middle"
              htmlType="submit"
              disabled={isDisabled}
              loading={isLoading}
            >
              {t('save_and_finish_later_button_text')}
            </Button>
          )}

          <Button
            type="primary"
            disabled={isDisabled}
            loading={isLoading}
            htmlType={isLastStep ? 'submit' : 'button'}
            onClick={handleNextStep && handleNextStep}
          >
            {isLastStep ? t('general_finish-btn') : t('next_step_button_text')}
            <RightOutlined rev={undefined} />
          </Button>
        </div>
      </Space>
    </AntFooter>
  );
}

export default FanGroupCreationFooter;

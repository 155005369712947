import {
  PaginationQueryV2,
  ListEntityV2,
  ApproverRequestsEntity,
  V2Prefix,
  endpoints,
  ApproverRequestValidator,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

// GET URL: https://api.{{domain}}/api/v2/fan-group-owner/waiting-lists/{{wlId}}/approver-requests
export const fetchFGOWaitingListApproverRequests = (
  waitingListId: string,
  params: PaginationQueryV2
): Promise<ListEntityV2<ApproverRequestsEntity>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.approverRequests}`,
    { params }
  );

export const updateFGOWaitingListApproverRequest = (
  waitingListId: string,
  requestId: string,
  body: ApproverRequestValidator
): Promise<ApproverRequestsEntity> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.approverRequests}/${requestId}`,
    body
  );

export const approveFGOWaitingListApproverRequest = (
  waitingListId: string,
  requestId: string
): Promise<ApproverRequestsEntity> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.approverRequests}/${requestId}/${endpoints.approve}`
  );

export const rejectFGOWaitingListApproverRequest = (
  waitingListId: string,
  requestId: string
): Promise<ApproverRequestsEntity> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.approverRequests}/${requestId}/${endpoints.reject}`
  );

import { Tag } from 'antd';

import { WaitingListStatusEnum } from '@seaters-app/constants';
import {
  alertColor,
  baseColor600,
  errorColor,
  successColor,
} from '@seaters-app/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface WishListStatusTagProps {
  status: WaitingListStatusEnum;
  icon?: ReactNode;
  secondary?: boolean;
}

const wishListStatusToColor = {
  [WaitingListStatusEnum.ARCHIVED]: baseColor600,
  [WaitingListStatusEnum.CLOSED]: errorColor,
  [WaitingListStatusEnum.DRAFT]: baseColor600,
  [WaitingListStatusEnum.OPEN]: successColor,
  [WaitingListStatusEnum.PUBLISHED]: successColor,
  [WaitingListStatusEnum.SETUP]: alertColor,
};

const wishListStatusToColorSecondary = {
  [WaitingListStatusEnum.ARCHIVED]: 'error',
  [WaitingListStatusEnum.CLOSED]: 'error',
  [WaitingListStatusEnum.DRAFT]: 'default',
  [WaitingListStatusEnum.OPEN]: 'success',
  [WaitingListStatusEnum.PUBLISHED]: 'success',
  [WaitingListStatusEnum.SETUP]: 'warning',
};

const wishListStatusTranslations: { [key in WaitingListStatusEnum]: string } = {
  ARCHIVED: 'Archived',
  CLOSED: 'group_waitinglists_closed-label',
  DRAFT: 'Draft',
  OPEN: 'Open',
  PUBLISHED: 'group_waitinglists_comingsoon-label',
  SETUP: 'Setup',
};
export function WishListStatusTag({
  status,
  icon,
  secondary,
}: WishListStatusTagProps) {
  const { t } = useTranslation();
  return (
    <Tag
      icon={icon}
      color={
        !secondary
          ? wishListStatusToColor[status]
          : wishListStatusToColorSecondary[status]
      }
    >
      {t(wishListStatusTranslations[status])}
    </Tag>
  );
}

export default WishListStatusTag;
